<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button routerLink="/settings/tahunajaran/list" class="nav-link" [class.active]="isTahunAjaran" id="tahun-ajaran-tab" data-bs-toggle="tab" data-bs-target="#tahun-ajaran" type="button" role="tab" aria-controls="tahun-ajaran" aria-selected="false">Tahun Ajaran</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/settings/tahunajaran/semester" class="nav-link" [class.active]="isSemester" id="list-tab" data-bs-toggle="tab" data-bs-target="#semester" type="button" role="tab" aria-controls="semester" aria-selected="false">Semester</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/settings/tahunajaran/kepsek" class="nav-link" [class.active]="isKepsek" id="list-tab-kepsek" data-bs-toggle="tab" data-bs-target="#kepsek" type="button" role="tab" aria-controls="kepsek" aria-selected="false">Kepala Sekolah</button>
  </li>
</ul>

<router-outlet></router-outlet>