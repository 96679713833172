import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/helper/common.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-permission-add',
  templateUrl: './permission-add.component.html',
  styleUrls: ['./permission-add.component.css']
})
export class PermissionAddComponent implements OnInit {

form!: FormGroup;

  editable = [{
    value: "t",
    label: "Ya"
  },{
    value: "f",
    label: "Tidak"
  }]

  constructor(
    public permissionService: PermissionService, 
    public datePipe: DatePipe,
    private router: Router,
    private common: CommonService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      permission_name: new FormControl('', [Validators.required]),
      editable: new FormControl('', Validators.required),
      slug: new FormControl('')
    });
  }

  get f(){
    return this.form.controls;
  }

  submit() {
    let permission_name = this.form.value.permission_name;
    this.form.value.slug = this.common.slugify(permission_name);
    this.form.value.module = 'USER';
    this.form.value.description = 'USER '+ permission_name;
    this.permissionService.create(this.form.value).subscribe(()=>{
      console.log('Permission created successfully!');
      this.router.navigateByUrl('/permission');
    });
  }
}
