<div class="row">
  <div class="col-12 col-lg-2">
    <div class="img-wrapper">
      <img src="./assets/images/empty-image.png" class="w-100" alt="" />
    </div>
  </div>
  <div class="col-12 col-lg-10">
    <div class="row mt-2">
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header">Informasi Pendaftaran</div>
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-6 fw-bold">
                  <p>Tanggal Daftar</p>
                  <p>Nomor Registrasi</p>
                  <p>No. Dokumen</p>
                  <p>Nomor Induk</p>
                  <p>Admin Input</p>
                  <p>Tanggal Terakhir Update</p>
                </div>
                <div class="col-6 grey-text" *ngIf="selectedData != null else emptyRegData">
                  <p>{{ selectedData.reg_date }}</p>
                  <p>{{ selectedData.regnumber }}</p>
                  <p>{{ selectedData.id }}</p>
                  <p>{{ selectedData.no_induk }}</p>
                  <p>{{ selectedData.admin_input }}</p>
                  <p>{{ selectedData.updateAt }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header">Nomor Virtual Account</div>
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-6 fw-bold">
                  <p>SPP</p>
                  <p>Pangkal</p>
                  <p>Saku</p>
                  <p>Saku</p>
                </div>
                <div class="col-6 grey-text">
                  <p>-</p>
                  <p>-</p>
                  <p>-</p>
                  <p>-</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header">Biodata Santri</div>
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-6 fw-bold">
                  <p>Nama Lengkap</p>
                  <p>NIK</p>
                  <p>Tempat Tanggal Lahir</p>
                  <p>Jenis Kelamin</p>
                  <p>Anak Ke</p>
                  <p>Saudara Kandung</p>
                  <p>Lembaga Tujuan</p>
                  <p>Tingkatan</p>
                  <p>Ukuran Baju</p>
                  <p>Alamat</p>
                  <p>Desa/Kelurahan</p>
                  <p>Kecamatan</p>
                  <p>Kota/Kabupaten</p>
                  <p>Provinsi</p>
                  <p>Email</p>
                </div>
                <div class="col-6 grey-text" *ngIf="selectedData != null else emptyBioSiswa">
                  <p>{{ selectedData.nama_lengkap }}</p>
                  <p>{{ selectedData.nik }}</p>
                  <p>{{ selectedData.dob }}</p>
                  <p>{{ convertGenderStatus(selectedData.gender) }}</p>
                  <p>{{ selectedData.anak_ke }}</p>
                  <p>{{ selectedData.saudara_kandung_aktif }}</p>
                  <p>{{ selectedData.lembaga.nama_lembaga }}</p>
                  <p>{{ selectedData.tingkat.nama_tingkat }}</p>
                  <p>{{ selectedData.ukuran_baju }}</p>
                  <p>{{ selectedData.alamat }}</p>
                  <p>{{ selectedData.desa_kelurahan }}</p>
                  <p>{{ selectedData.kecamatan }}</p>
                  <p>{{ selectedData.kota_kab }}</p>
                  <p>{{ selectedData.provinsi }}</p>
                  <p>{{ selectedData.email }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header">Biodata Asal Sekolah</div>
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-6 fw-bold">
                  <p>Nama Sekolah Asal</p>
                  <p>Asal Sekolah</p>
                  <p>Alamat Sekolah</p>
                  <p>Tempat Ijazah</p>
                  <p>No. Ijazah</p>
                  <p>Tahun Lulus</p>
                  <p>NISN</p>
                  <p>NIPSN</p>
                </div>
                <div class="col-6 grey-text" *ngIf="selectedData != null else emptyAsalSekolahData">
                  <p>{{ selectedData.nama_sekolah_asal }}</p>
                  <p>{{ selectedData.asal_sekolah }}</p>
                  <p>-</p>
                  <p>-</p>
                  <p>{{ selectedData.no_ijazah }}</p>
                  <p>-</p>
                  <p>{{ selectedData.nisn }}</p>
                  <p>{{ selectedData.npsn }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header">Data Orang Tua</div>
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-4 fw-bold">
                  <p></p>
                  <p>Nama</p>
                  <p>No. KK</p>
                  <p>NIK</p>
                  <p>Tempat Lahir</p>
                  <p>Tanggal Lahir</p>
                  <p>Pendidikan</p>
                  <p>Pekerjaan</p>
                  <p>Alamat</p>
                  <p>Telepon (WA)</p>
                  <p>Status</p>
                  <p>Penghasilan</p>
                </div>
                <div class="col-4 grey-text" *ngIf="selectedData != null else emptyBioOrangTua">
                  <p>Ayah</p>
                  <p>{{ selectedData.nama_ayah }}</p>
                  <p>{{ selectedData.no_kk }}</p>
                  <p>{{ selectedData.nik_ayah }}</p>
                  <p>{{ selectedData.pob_ayah }}</p>
                  <p>{{ selectedData.dob_ayah }}</p>
                  <p>{{ selectedData.pendidikan_terkahir_ayah }}</p>
                  <p>{{ selectedData.pekerjaan_ayah }}</p>
                  <p>{{ selectedData.alamat_ayah }}</p>
                  <p>{{ selectedData.telp_ayah }}</p>
                  <p>{{ selectedData.status_hidup_ayah }}</p>
                  <p>{{ selectedData.penghasilan_perbulan_ayah }}</p>
                </div>
                <div class="col-4 grey-text" *ngIf="selectedData != null else emptyBioOrangTua">
                  <p>Ibu</p>
                  <p>{{ selectedData.nama_ibu }}</p>
                  <p>{{ selectedData.no_kk }}</p>
                  <p>{{ selectedData.nik_ibu }}</p>
                  <p>{{ selectedData.pob_ibu }}</p>
                  <p>{{ selectedData.dob_ibu }}</p>
                  <p>{{ selectedData.pendidikan_terkahir_ibu }}</p>
                  <p>{{ selectedData.pekerjaan_ibu }}</p>
                  <p>{{ selectedData.alamat_ibu }}</p>
                  <p>{{ selectedData.telp_ibu }}</p>
                  <p>{{ selectedData.status_hidup_ibu }}</p>
                  <p>{{ selectedData.penghasilan_perbulan_ibu }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header">Yang Menanggung Biaya</div>
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-6 fw-bold" *ngIf="selectedData != null else emptyPenanggungBiaya">
                  <p>Nama</p>
                  <p>Hubungan Keluarga</p>
                  <p>Pekerjaan</p>
                  <p>Alamat</p>
                </div>
                <div class="col-6 grey-text">
                  <p>{{ selectedData.nama_penanggung_biaya }}</p>
                  <p>{{ selectedData.hubungan_keluarga_penanggung_biaya }}</p>
                  <p>{{ selectedData.pekerjaan_penanggung_biaya }}</p>
                  <p>{{ selectedData.alamat_penanggung_biaya }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyRegData>
  <div class="col-6 grey-text">
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
  </div>
</ng-template>

<ng-template #emptyBioSiswa>
  <div class="col-6 grey-text">
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
  </div>
</ng-template>

<ng-template #emptyAsalSekolahData>
  <div class="col-6 grey-text">
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
  </div>
</ng-template>

<ng-template #emptyBioOrangTua>
  <div class="col-6 grey-text">
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
  </div>
</ng-template>

<ng-template #emptyPenanggungBiaya>
  <div class="col-6 grey-text">
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
  </div>
</ng-template>
