import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tahunajaran-nav',
  templateUrl: './tahunajaran-nav.component.html',
  styleUrls: ['./tahunajaran-nav.component.css']
})
export class TahunajaranNavComponent implements OnInit {

  isTahunAjaran = false;
  isSemester = false;
  isKepsek = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    console.log(this.router.url);
    switch (this.router.url) {
      case '/settings/tahunajaran/list':
        this.isTahunAjaran = true;
        this.isSemester = false;
        this.isKepsek = false;
        break;

      case '/settings/tahunajaran/semester':
        this.isTahunAjaran = false;
        this.isSemester = true;
        this.isKepsek = false;
        break;
      case '/settings/tahunajaran/kepsek':
        this.isTahunAjaran = false;
        this.isSemester = false;
        this.isKepsek = true;
        break;

      default:
        this.isTahunAjaran = false;
        this.isSemester = false;
        this.isKepsek = false;
        break;
    }
  }

}
