import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SiswaService } from 'src/app/services/siswa.service';

@Component({
  selector: 'app-siswa-detail',
  templateUrl: './siswa-detail.component.html',
  styleUrls: ['./siswa-detail.component.css']
})
export class SiswaDetailComponent implements OnInit {

  selectedSiswa: any = null;

  pageid = "detailSiswa";
  listTabs = [
    {
      id: "biodata",
      name: "Biodata"
    },
    {
      id: "kesehatan",
      name: "Kesehatan"
    },
    {
      id: "pelanggaran",
      name: "Pelanggaran"
    },
    {
      id: "kamar",
      name: "Kamar"
    },
    {
      id: "absen-kamar",
      name: "Absen Kamar"
    },
    {
      id: "izin",
      name: "Izin Siswa"
    },
    {
      id: "transaksi",
      name: "Transaksi Keuangan"
    },
    {
      id: "deposit",
      name: "Mutasi Deposit"
    },
    {
      id: "tagihan",
      name: "Tagihan"
    },
    {
      id: "kelas",
      name: "Kelas"
    },
    {
      id: "prestasi",
      name: "Prestasi"
    },
    {
      id: "catatan-guru",
      name: "Catatan Guru"
    },
    {
      id: "log",
      name: "log"
    },
    {
      id: "log-va",
      name: "log VA"
    }
  ]
  tabSelected = "biodata";

  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;

  listPelanggaran = [];
  listKamar = [];
  listAbsenKamar = [];
  listIzinSiswa = [];
  listDeposit = [];
  listKelas = [];
  listPrestasi = [];
  listCatatanGuru = [];
  listLog = [];
  listLogVa = [];

  constructor(
    private uiService: UiService,
    private siswaService: SiswaService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit(): void {
    //this.sharedDataService.currentMessage.subscribe(message => (this.selectedMessage= message)); //<= Always get current value!
    this.sharedDataService.currentSiswa.subscribe(data => {
      (this.selectedSiswa = data)
    })
  }

  closeButton() {
    this.uiService.hideFullScrrenContent();
    this.tabSelected = "biodata";
  }

  getCurrentSiswaById(siswaId: any) {

  }

  handlePageChange(event: number): void {
    this.page = event;
  }

  onClickTab(tab: any) {
    this.tabSelected = tab.id;
  }

}
