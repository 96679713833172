<div class="wrapper">
    <div class="content">
        <div class="item-wrapper">
            <div class="logo-2 mb-3">
                <img src="./assets/images/Logo-DN.png" alt="">
            </div>
            <div class="logo">
                <div class="top">
                    
                </div>
                <div class="middle">
                    Darunnajah System
                </div>
                <div class="bottom">
                    <img src="./assets/images/logo.png" alt="">
                </div>
            </div>
            
            
            <form *ngIf="!isLoggedIn" class="form-horizontal" autocomplete="off" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                <div class="mb-4">
                    <div class="row align-items-center">
                        <!-- <div class="col-3">
                            <label for="username" class="form-label">Username</label>
                        </div> -->
                        <div class="col-12">                            
                              <input type="text" name="username" class="form-control rounded" id="user-name" [(ngModel)]="form.username" #username="ngModel" placeholder="Your Username" required>
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="row align-items-center">
                        <!-- <div class="col-3">
                            <label for="password" class="form-label">Password</label>
                        </div> -->
                        <div class="col-12">                            
                            <input type="password" class="form-control" id="user-password" [(ngModel)]="form.password" name="password" #password="ngModel" placeholder="Enter Password" required>
                        </div>
                    </div>
                  </div>
                  
                  <button id="submit-login" type="submit" class="btn btn-login">Login</button>
            </form>
        </div>
    </div>
</div>