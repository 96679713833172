import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { RayonService } from 'src/app/services/rayon.service';
import { SdmService } from 'src/app/services/sdm.service';

@Component({
  selector: 'app-rayon-add',
  templateUrl: './rayon-add.component.html',
  styleUrls: ['./rayon-add.component.css']
})

export class RayonAddComponent implements OnInit {

  errorMessage = '';
  listMurobbi: any = [];
  searchMurobbiTerm = "";
  isShowLoaderMurobbi: Boolean = false;
  page = 1;
  pageSize = 50;

  form: FormGroup = new FormGroup({
    nama_rayon: new FormControl(''),
    id_murobbi: new FormControl(''),
    nama_murobbi: new FormControl(''),
  });
  submitted = false;

  constructor(private formBuilder: FormBuilder, 
    private uiService: UiService, 
    private rayonService: RayonService,
    private sdmService: SdmService,
    private router: Router) 
  { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.initForm();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru");
    }
  }

  onBack() {
    this.router.navigate(['/master/rayon/list']);
  }
  
  initForm() {
    this.form = this.formBuilder.group(
      {
        nama_rayon: ['', Validators.required],
        id_murobbi: ['', Validators.required],
        nama_murobbi: [''],
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    console.log(JSON.stringify(this.form.value, null, 2));
    
    if (this.form.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    this.rayonService.add(this.form.value).subscribe({
      next: resdata => {
        console.log(resdata);
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
      
        this.router.navigate(['/master/rayon/list']);

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
    
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  chooseMurobbi(data: any) {
    this.listMurobbi = [];
    console.log(data);
    this.form.controls['nama_murobbi'].setValue(data.userProfile.real_name);
    this.form.controls['id_murobbi'].setValue(data.userProfile.id_user);
  }

  searchMurobbi(): void {
    this.searchMurobbiTerm = this.form.value.penguji_name;

    this.listMurobbi = [];
    if (this.searchMurobbiTerm != "") {
      this.isShowLoaderMurobbi = true;
      const params = this.getRequestParams(this.searchMurobbiTerm, this.page, this.pageSize);

      console.log("PARAMS", params);
      
      this.sdmService.getAll(params).subscribe({
        next: resdata => {
          console.log(resdata);
          const { data, totalrows } = resdata;
  
          this.listMurobbi = data;
          this.isShowLoaderMurobbi = false;
        
          this.uiService.hideLoader();
  
        },
        error: err => {
          this.isShowLoaderMurobbi = false;
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

}
