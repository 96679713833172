<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="statistik"
    role="tabpanel"
    aria-labelledby="statistik-tab"
  >
    <div class="tabs-title">Wali Kelas</div>

    
    <div class="list-options">
      <div class="form-select-warapper">
        <form [formGroup]="formChangeTahunAjaran">
          <select formControlName="id_tahun_ajaran" (change)="changeTahunAjaran($event)" class="form-select" name="id_tahun_ajaran" id="id_tahun_ajaran">
            <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
          </select>
        </form>
      </div>
      
      <div class="search">
        <input
        type="text"
        class="form-control"
        placeholder="Search.."
        name="search-list"
        id="search-list"
        [(ngModel)]="searchTerm"
        />
        <div class="icon" (click)="search()">
          <img src="./assets/images/icon-search.png" alt="Search" />
        </div>
      </div>
      
      <button class="btn btn-blue-grey" (click)="openModalFormWalikelas(formWalikelasModal)">
        + Tambah Wali Kelas
      </button>
      <!-- <button type="button" class="btn btn-success">
        <img src="./assets/images/icon-excel.png" alt="Excel"> Excel
      </button>
      <button type="button" class="btn btn-danger">
        <img src="./assets/images/icon-pdf.png" alt="PDF"> PDF
      </button> -->
    </div>
    
    <div class="table-wrapper">
      <div class="table-options">
        <div class="show-table">
          Show
          <select
            class="form-control"
            name="show"
            id="show"
            (change)="handlePageSizeChange($event)"
          >
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }}
            </option>
          </select>
          entries
        </div>
      </div>
      
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th class="align-middle">No.</th>
              <th class="align-middle">Tahun Ajaran</th>
              <th class="align-middle">Nama</th>
              <th class="align-middle">Kelas</th>
              <th></th>
          </thead>
          <tbody>
            <tr *ngFor="let wali of listWaliKelas | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
              <td>{{ i + 1 + (page - 1) * pageSize }}</td>
              <td>{{ wali.TahunAjaran.name }}</td>
              <td>{{ wali.User.userProfile.real_name }}</td>
              <td><span *ngIf="wali.Kelas!==null">{{ wali.Kelas.nama_kelas }}</span></td>
              <td class="settings">
                <div class="dropend">
                    <a href="#" role="button" id="dropdownMenuLink"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="./assets/images/icon-setting.svg" alt="">
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li><a (click)="openEditModalFormWalikelas(formWalikelasModal, wali)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>                                        
                        <li><a (click)="confirmDelete(wali.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                    </ul>
                </div>
            </td>
            </tr>
            <tr *ngIf="listWaliKelas.length === 0" class="empty">
              <td colspan="6">Tidak terdapat data atau data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>
    
      <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
        <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
          <div></div>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center pagination-number">
              <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                  {{ page.label }}
                </a>
                <a class="page-link" *ngIf="p.getCurrent() === page.value">
                  {{ page.label }}
                </a>
              </li>
            </ul>
          </nav>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="p.isFirstPage()">
                <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                  <span>Previous</span>
                </a>
              </li>
              <li class="page-item" [class.disabled]="p.isLastPage()">
                <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                  <span>Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </pagination-template>
    
      <ng-template #emptyPagination>
        <div class="pagination-wrapper">
          <div>&nbsp;</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #formWalikelasModal let-modal>
  <div class="modal-body">
      <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
      <div class="modal-form">
          <h1 class="text-center" *ngIf="isNew">Tambah Form Wali Kelas</h1>
          <h1 class="text-center" *ngIf="!isNew">Edit Form Wali Kelas</h1>
          <form [formGroup]="formWalikelas" (ngSubmit)="onSubmitFormWalikelas(modal)">

            <div class="mb-3 mt-4 form-group row">
              <label for="input-id-1" class="col-sm-3 control-label">Tahun Pelajaran</label>
              <div class="col-sm-9">
                <select
                  formControlName="periode_id"
                  (change)="changeTahunAjaran($event)"
                  [ngClass]="{ 'is-invalid': submitted && f['periode_id'].errors }"
                  class="form-select">
                  <option value="" selected disabled>Pilih</option>
                  <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputNamaGuru" class="col-sm-3 col-form-label control-label">Guru</label>
              <div class="col-sm-9 autocomplete">
                <input
                  [ngClass]="{ 'is-invalid': submitted && f['proguser_id'].errors }" 
                  formControlName="guru_name" (keyup)="searchGuru()" type="text" class="form-control" 
                  autocapitalize="off" autocorrect="off" autocomplete="off" id="inputNamaGuru">

                <div id="myInputautocomplete-list-Guru" class="autocomplete-items">
                  <button *ngIf="isShowLoaderGuru" class="btn btn-primary" type="button" disabled>
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Searching...
                  </button>
                  <div (click)="chooseGuru(dataGuru)" *ngFor="let dataGuru of listGuru">
                    {{ dataGuru.userProfile.real_name }}
                  </div>

                </div>
              </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
              <label for="kelas" class="col-sm-3 control-label">Kelas</label>
              <div class="col-sm-9">
                <select
                    formControlName="kelas_id"
                    [ngClass]="{ 'is-invalid': submitted && f['kelas_id'].errors }"
                    class="form-select highlight"
                    (change)="changeKelas($event)">
                    <option value="" selected disabled>Pilih</option>
                    <option [value]="data.id" *ngFor="let data of listKelas">{{data.nama_kelas}}</option>
                </select>
              </div>
            </div>

            <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isNew">CREATE</button>
            <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="!isNew">Update</button>
          </form>
      </div>
  </div>
</ng-template>