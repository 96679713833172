<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
        <button routerLink="/keuangan/print-tagihan" class="nav-link" [class.active]="isPrintTagihan" id="input-guru-tab" data-bs-toggle="tab" data-bs-target="#inputGuru" type="button" role="tab" aria-controls="list" aria-selected="false">Print Tagihan</button>
    </li>
    <li class="nav-item" role="presentation">
        <button routerLink="/keuangan/print-tagihan/template" class="nav-link" [class.active]="isTemplate" id="catatan-siswa-tab" data-bs-toggle="tab" data-bs-target="#catatanSiswa" type="button" role="tab" aria-controls="nilai" aria-selected="false">Template</button>
    </li>
</ul>

<router-outlet></router-outlet>
  
