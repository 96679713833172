<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
      <div class="tabs-title">
          LIST GEDUNG
      </div>

      <div class="list-options">
        <button class="btn btn-blue-grey" (click)="openGedungModal(gedungModal)">
          + Tambah
        </button>
        
        <div class="search">
          <input
            type="text"
            class="form-control"
            placeholder="Nama Gedung.."
            name="search-list"
            id="search-list"
            [(ngModel)]="searchTerm"
          />
          <div class="icon" (click)="search()">
            <img src="./assets/images/icon-search.png" alt="Search" />
          </div>
        </div>
      </div>
      
      <div class="table-wrapper">
        <div class="table-options">
          <div class="show-table">
            Show
            <select
              class="form-control"
              name="show"
              id="show"
              (change)="handlePageSizeChange($event)"
            >
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
            entries
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama Gedung</th>          
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let gedung of listGedung
                    | paginate
                      : {
                          id: pageid,
                          itemsPerPage: pageSize,
                          currentPage: page,
                          totalItems: count
                        };
                  let i = index
                "
              >
                <td>{{ i + 1 + (page - 1) * pageSize }}</td>
                <td>{{ gedung.nama_gedung }}</td>
                <td></td>
                <td class="settings">
                    <div class="dropend">
                        <a href="#" role="button" id="dropdownMenuLink"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="./assets/images/icon-setting.svg" alt="">
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><a (click)="openModalEdit(gedungModal, gedung)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>                            
                            <li><a (click)="confirmDelete(gedung.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                        </ul>
                    </div>
                </td>
      
              </tr>
              <tr *ngIf="listGedung.length === 0" class="empty">
                <td colspan="16">Tidak terdapat data atau data tidak ditemukan.</td>
              </tr>
            </tbody>
          </table>
        </div>
      
        <pagination-template
          #p="paginationApi"
          [id]="pageid"
          (pageChange)="handlePageChange($event)"
        >
          <div
            *ngIf="isShowPagination; else emptyPagination"
            class="pagination-wrapper"
          >
            <div></div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center pagination-number">
                <li
                  class="page-item"
                  *ngFor="let page of p.pages"
                  [class.active]="p.getCurrent() === page.value"
                >
                  <a
                    class="page-link"
                    (click)="p.setCurrent(page.value)"
                    *ngIf="p.getCurrent() !== page.value"
                  >
                    {{ page.label }}
                  </a>
                  <a class="page-link" *ngIf="p.getCurrent() === page.value">
                    {{ page.label }}
                  </a>
                </li>
              </ul>
            </nav>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="p.isFirstPage()">
                  <a
                    class="page-link"
                    *ngIf="!p.isFirstPage()"
                    (click)="p.previous()"
                    aria-label="Previous"
                  >
                    <span>Previous</span>
                  </a>
                </li>
                <li class="page-item" [class.disabled]="p.isLastPage()">
                  <a
                    class="page-link"
                    *ngIf="!p.isLastPage()"
                    (click)="p.next()"
                    aria-label="Next"
                  >
                    <span>Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </pagination-template>
      
        <ng-template #emptyPagination>
          <div class="pagination-wrapper">
            <div>&nbsp;</div>
          </div>
        </ng-template>
      </div>
      
      
      <ng-template #gedungModal let-modal>
        <div class="modal-body">
            <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
            <div class="modal-form">
                <h1 class="text-center" *ngIf="isNew">Tambah Form Gedung</h1>
                <h1 class="text-center" *ngIf="!isNew">Edit Form Gedung</h1>
                <form [formGroup]="formAddGedung" (ngSubmit)="onSubmitGedung(modal)">            
      
                  <div class="row mb-3">
                    <label for="inputNamaSiswa" class="col-sm-3 col-form-label control-label">Nama Gedung</label>
                    <div class="col-sm-9">
                      <input formControlName="nama_gedung" type="text" class="form-control" 
                        [ngClass]="{ 'is-invalid': submitted && f['nama_gedung'].errors }"
                        autocorrect="off" autocomplete="off" id="inputNamaGedung">
                    </div>
                  </div>
      
                  <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isNew">CREATE</button>
                  <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="!isNew">Update</button>
                </form>
            </div>
        </div>
      </ng-template>
  </div>
</div>
