import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UiService } from 'src/app/helper/ui.service';
import { PdfService } from 'src/app/services/pdf.service';
import { TemplateSettingService } from 'src/app/services/template-setting.service';
import { ActivatedRoute } from '@angular/router';
import { PembayaranService } from 'src/app/services/pembayaran.service';
import { CommonService } from 'src/app/helper/common.service';
import { SiswakamarService } from 'src/app/services/siswakamar.service';


@Component({
  selector: 'app-printkamar',
  templateUrl: './printkamar.component.html',
  styleUrls: ['./printkamar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PrintkamarComponent implements OnInit {

  container = "";
  ctnPages: any[] = [];
  dynamicHTML: SafeHtml | undefined;
  errorMessage = "";
  currentPayload: any = null;
  rawDataTagihan: any[] = [];
  listSiswaPerKamar: any[] = [];
  listNomorUrut: any[] = [];

  constructor(
    private uiService: UiService,
    private sanitizer: DomSanitizer,
    private pdfService: PdfService,
    private commonService: CommonService,
    private siswaKamarService: SiswakamarService,
    private pembayaranService: PembayaranService,
    private route: ActivatedRoute,
    private templateSettingService: TemplateSettingService,
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        // hardcode for a while
        console.log(params); // { orderby: "price" }
        let result = params.hasOwnProperty('q');
        console.log(result);

        if (result === true) {          
          const base64Data = params['q'];
          const jsonString = atob(base64Data);

          // Step 2: Parse the UTF-8 string as JSON
          this.currentPayload = JSON.parse(jsonString);
          console.log("currentPayload", this.currentPayload);
          
          this.showDataKamar();
        }        
      }
    );

    // get template
    // get data tagihan
    // parse data tagihan to template


  }

  downloadPdf() {
    this.uiService.showLoader();
    this.pdfService.generatePdf().then((data) => {
      this.uiService.hideLoader();
      console.log(data)
    })
    .catch((reason) => console.log("Message:" + reason.message));
  }

  showDataKamar() {
    const kuota = this.currentPayload.kuota;
    this.uiService.showLoader();    

    this.siswaKamarService.getListSiswaKamar(this.currentPayload.id_periode, this.currentPayload.id_kamar).subscribe({
      next: resdata => {
        console.log("getListSiswaKamar", resdata);
        this.listSiswaPerKamar = resdata;

        
        let noUrutSiswa = [];
        for (let i=0; i<resdata.length; i++) {
          console.log("SISWA", i, resdata[i].Siswa.nama_lengkap, resdata[i].nomor_urut);
          noUrutSiswa[resdata[i].nomor_urut] = resdata[i].Siswa.nama_lengkap;
        }

        console.log("noUrutSiswa", noUrutSiswa);
        
        this.listNomorUrut = [];

        for (let n=1; n<=kuota; n++) {
          const dtN = noUrutSiswa[n] !== 'undefined' ? {no: n, nama: noUrutSiswa[n] } : {no: n, nama: ''};
          this.listNomorUrut.push(dtN);
        }

        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });  
  }

  ngAfterViewInit() {
    // this.pdfService.generatePdf();
    
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add('legal');
    }
  }

  simulation() {
    // this.rawDataTagihan
    let listDataTagihan: any[] = [];
    let listPerSiswaDataTagihan: any[] = [];
    let tData = "";
    let gHead = "";
    let objDataSiswa: any = {};
    let objDataTagihan: any = {};
    const listMonth = this.commonService.getListMonth();
    const currentYear = this.commonService.getCurrentYear();
    const today = this.commonService.getCurentDate();

    for (let i=0; i<this.rawDataTagihan.length; i++) {
      const bulanTagihan = this.rawDataTagihan[i].bulan_tagihan;
      const idxBulanTagihan = bulanTagihan - 1;
      const tahunTagihan = this.rawDataTagihan[i].tahun_tagihan;
      const groupTagihan = this.rawDataTagihan[i].group_biaya;
      const itemTagihan = listMonth[idxBulanTagihan] + " " + tahunTagihan;   
      const amount = this.rawDataTagihan[i].amount;
      const totalAmount = this.rawDataTagihan[i].total_amount;
      const namaSiswa = this.rawDataTagihan[i].nama_lengkap;
      const kelas = this.rawDataTagihan[i].nama_kelas;
      

      if (tData !== this.rawDataTagihan[i].idsiswa) {
        gHead = "";
        // simpan data
        if (tData !== "") {
          objDataSiswa['tagihan'] = listPerSiswaDataTagihan;
          listDataTagihan.push(objDataSiswa);
        }

        // console.log("LIST PER SISWA", this.rawDataTagihan[i].nama_lengkap, listPerSiswaDataTagihan);
        
        // reste persiswa tagihan
        listPerSiswaDataTagihan = [];
    
        if (gHead !== groupTagihan) {
          console.log("GROUP Head", objDataTagihan['group'], groupTagihan);
          // reset persiswa tagihan
          const headerListTagihan = {
            "group": groupTagihan,
            "item": groupTagihan,
            "amount":""
          }
  
          listPerSiswaDataTagihan.push(headerListTagihan);
          gHead = groupTagihan;
        }
        objDataTagihan = {
          "group": groupTagihan,
          "item": itemTagihan,
          "amount": amount
        }
        
        listPerSiswaDataTagihan.push(objDataTagihan)
        objDataSiswa = {
          "id_siswa":this.rawDataTagihan[i].idsiswa,
          "nama_siswa": namaSiswa,
          "kelas": kelas,
          "tahun": currentYear,
          "tanggal": today,
          "va": "8888-" + this.rawDataTagihan[i].no_induk,
          "bank": "BSI (451)",
          "total":totalAmount,
          "tagihan": listPerSiswaDataTagihan
        }
        tData = this.rawDataTagihan[i].idsiswa;
      } else {
        if (gHead !== groupTagihan) {
          console.log("GROUP", objDataTagihan['group'], groupTagihan);
          // reset persiswa tagihan
          const headerListTagihan = {
            "group": groupTagihan,
            "item": groupTagihan,
            "amount":""
          }
  
          listPerSiswaDataTagihan.push(headerListTagihan);
          gHead = groupTagihan;
        }

        objDataTagihan = {
          "group": groupTagihan,
          "item": itemTagihan,
          "amount": amount
        }

        listPerSiswaDataTagihan.push(objDataTagihan)
      }
      

    }
    if (listDataTagihan.length === 0 && listPerSiswaDataTagihan.length > 0) {
      listDataTagihan.push(objDataSiswa)
    }

    // variabel : #dataVA# , #dataTagihan#
    const tplTagihan = this.container;
    
    let tblRowVA = '<table class="invoice-table"> <thead> <tr> <th>Bank</th> <th>No. Rekening / VA</th> </tr> </thead> <tbody> <tr> <td>#siswaBank#</td> <td>#siswaVA#</td> </tr> </tbody> </table>';
    let tblRowTagihan = '<table class="invoice-table"> <thead> <tr> <th>Kewajiban Keuangan Yang Belum Di Bayar</th> <th>Jumlah</th> </tr> </thead> <tbody> #contentListTagihan# </tbody> </table> <!-- Additional invoice sections with page breaks as needed --> <div class="invoice-total"> <p>Total: #totalTagihan#</p> </div>';
    const tblRowHeadTagihan = '<tr> <td><b>#headTagihan#</b></td> <td></td> </tr>';
    const tblRowItemTagihan = '<tr> <td>#itemTagihan#</td> <td>#nilaiTagihan#</td> </tr>';

    console.log("FINAL DATA", listDataTagihan);
    
    for (let i=0; i<listDataTagihan.length; i++) {
      // parse tagihan
      const tagihanSiswa = listDataTagihan[i].tagihan;      
      
      let ctnTablTagihan = "";
      for (let n=0; n<tagihanSiswa.length; n++){
        console.log("ROW",i,n, tagihanSiswa[n].group, tagihanSiswa[n].item);
        
        if (tagihanSiswa[n].group === tagihanSiswa[n].item) {
          ctnTablTagihan += tblRowHeadTagihan.replace("#headTagihan#", tagihanSiswa[n].group);
        } else {
          ctnTablTagihan += tblRowItemTagihan.replace("#itemTagihan#", tagihanSiswa[n].item).replace("#nilaiTagihan#", tagihanSiswa[n].amount);
        }
      }      

      const ctnTblRowVA = tblRowVA.replace("#siswaBank#", listDataTagihan[i].bank).replace("#siswaVA#", listDataTagihan[i].va);
      const ctnTblRowTagihan = tblRowTagihan.replace("#contentListTagihan#", ctnTablTagihan).replace("#totalTagihan#", listDataTagihan[i].total) ;
      const page = tplTagihan.replace("#tahunDiNomorSurat#", listDataTagihan[i].tahun)
                      .replace("#namaSiswa#", listDataTagihan[i].nama_siswa)
                      .replace("#namaKelas#", listDataTagihan[i].kelas)
                      .replace("#tanggalSurat#", listDataTagihan[i].tanggal)
                      .replace("#dataVA#", ctnTblRowVA)
                      .replace("#dataTagihan#", ctnTblRowTagihan);
      this.dynamicHTML = this.sanitizer.bypassSecurityTrustHtml(page);
      this.ctnPages.push(this.dynamicHTML);
    }
  }

  printPage() {
    window.print();
  }

}
