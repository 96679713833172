<div id="myContent" class="overlayfullscreen">
    <!-- <a href="javascript:void(0)" class="closebtn" (click)="closeButton()">&times;</a> -->
    <div class="overlayfullscreen-content">
      
        <app-siswa-detail *ngIf="fullscreenComponent==='siswa'"></app-siswa-detail>
        <app-siswa-penempatan-kelas-detail *ngIf="fullscreenComponent==='penempatan-kelas'"></app-siswa-penempatan-kelas-detail>
        <app-jadwal-mengajar-per-kelas *ngIf="fullscreenComponent==='jadwal-mengajar-perkelas'"></app-jadwal-mengajar-per-kelas>
        <app-detail-jadwal-mengajar-per-guru *ngIf="fullscreenComponent==='jadwal-mengajar-perguru'"></app-detail-jadwal-mengajar-per-guru>
        <app-detail-absensi-harian-siswa *ngIf="fullscreenComponent==='detail-absensi-harian-siswa'"></app-detail-absensi-harian-siswa>
        <app-detail-absensi-kamar *ngIf="fullscreenComponent==='detail-absensi-kamar'"></app-detail-absensi-kamar>
    </div>
</div>  

<div class="wrapper">
    <!--Sidebar Menu-->
    <div class="sidebar" [ngClass]="{ 'hide': !sidebarToggleClass }">
        <!-- Sidebar Heading -->
        <div class="sidebar-header">
            <div class="logo">
                <div class="top">
                    Darunnajah
                </div>
                <div class="middle">
                    System
                </div>
                <div class="bottom">
                    <img src="./assets/images/logo.png" alt="">
                </div>
            </div>
        </div>
        <!-- End sidebar Heading -->
        
        <!-- sidebar menu items -->
        <app-sidebar-nav *ngIf="schoolType==='TMI'"></app-sidebar-nav>
        <app-sidebar-nav-tk *ngIf="schoolType==='TK'"></app-sidebar-nav-tk>
        <!-- end sidebar menu items -->

    </div>
    <!--End sidebar menu-->

    <!--menu mobile-->
    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header justify-content-end">
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="sidebar-header">
                <div class="logo">
                    <div class="top">
                        Darunnajah
                    </div>
                    <div class="middle">
                        System
                    </div>
                    <div class="bottom">
                        <img src="./assets/images/logo.png" alt="">
                    </div>
                </div>
            </div>
            
            <app-sidebar-nav *ngIf="schoolType==='TMI'"></app-sidebar-nav>
            <app-sidebar-nav-tk *ngIf="schoolType==='TK'"></app-sidebar-nav-tk>
            
        </div>
    </div>
    <!--end menu mobile-->

    <!-- Main content -->
    <div class="content" [ngClass]="{ 'expand': !contentToggleClass }">

        <!-- Top Navigation -->
        <div class="navigation">
            <div class="header">
                <div class="mobile-sidebar">
                    <a class="btn btn-green" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                        <img src="./assets/images/icon-hamburger.png" alt="">
                      </a>
                </div>
                <div class="header-left">
                    <div class="sidebar-collapse" (click)="hideShowSidebarMenu()">
                        <a class="btn btn-green">
                            <img src="./assets/images/icon-hamburger.png" alt="">
                        </a>
                    </div>
                    <div class="go-fs" (click)="fullScreenDetect()">
                        <a class="nav-link nav-link-expand">
                            <span id="maximizeFeather">
                                <i data-feather="maximize"></i>
                            </span>
                            <span id="minimizeFeather">
                                <i data-feather="minimize"></i>
                            </span>
                        </a>
                    </div>
                    <div>
                        <p>&nbsp;&nbsp;&nbsp;{{ instansi }}</p>
                    </div>
                    <!--
                        <div class="search-bar">
                            <input class="form-control" type="text" placeholder="Search..">
                            <div class="icon">
                                <img src="./assets/images/icon-search.png" alt="Search">
                            </div>
                        </div>
                    -->
                </div>
                <div class="dropdown">
                    <a class="user dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <img [src]="userPic" class="rounded-circle" (error)="handleMissingImage($event)" alt="" />
                        <div class="name">
                            {{ realname }}
                        </div>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li><a class="dropdown-item" href="#">Action</a></li>
                        <li><a class="dropdown-item" href="#">Another action</a></li>
                        <li><a class="dropdown-item" href (click)="logout()"><i class="feather icon-power"></i> Logout</a></li>
                    </ul>
                </div>

            </div>
            <div class="breadcrumb">
                <span><a href="">Dashboard</a> &nbsp;/</span>
                <span class="current">&nbsp;<a href="/"> Home</a></span>
            </div>
        </div>
        <!-- end top navigation -->

        <!-- yield content -->
        <div id="page-content">
            <router-outlet></router-outlet>
        </div>
        <!-- End yield content -->

    </div>
    <!-- End of Main content -->
</div>