import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-siswa-detail-kamar',
  templateUrl: './siswa-detail-kamar.component.html',
  styleUrls: ['./siswa-detail-kamar.component.css']
})
export class SiswaDetailKamarComponent implements OnInit {

  @Input() listData: any = [];

  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  pageId = "detail-kamar";

  constructor() { }

  ngOnInit(): void {
  }

  handlePageChange(event: number): void {
    this.page = event;
  }

}
