<div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="statistik"
      role="tabpanel"
      aria-labelledby="statistik-tab"
    >
      <div class="tabs-title">Izin Mengajar</div>
  
      
      <div *ngxPermissionsOnly="['SUPER_ADMIN','CAN_ADD_IZIN_MENGAJAR']" class="list-options">
        <button class="btn btn-blue-grey" (click)="openModalformIzinMengajar(formIzinMengajarModal)">
          + Tambah
        </button>
      </div>
      
      <div class="table-wrapper">
        <div class="table-options">
          <div class="show-table">
            Show
            <select
              class="form-control"
              name="show"
              id="show"
              (change)="handlePageSizeChange($event)"
            >
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
            entries
          </div>
        </div>
        
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th class="align-middle">No.</th>
                <th class="align-middle">Guru</th>
                <th class="align-middle">Pengganti</th>
                <th class="align-middle">Tanggal Izin</th>
                <th class="align-middle">Keterangan</th>
                <th class="align-middle">Kategori</th>
                <th class="align-middle">Status</th>
                <th class="align-middle" *ngxPermissionsOnly="['SUPER_ADMIN','APPROVE_IZIN_MENGAJAR']">Approval</th>
                <th></th>
            </thead>
            <tbody>
              <tr *ngFor="let guru of listIzinGuru | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
                <td>{{ i + 1 + (page - 1) * pageSize }}</td>
                <td>{{ guru.Guru.userProfile.real_name }}</td>
                <td><span *ngIf="guru.GuruPengganti!==null">{{ guru.GuruPengganti.userProfile.real_name }}</span></td>
                <td>{{ guru.start_date }}<span *ngIf="guru.start_date!=guru.end_date"> - {{ guru.end_date }}</span> </td>
                <td>{{ guru.keterangan }}</td>
                <td>{{ guru.kategori }}</td>
                <td>{{ guru.status | parseStatus : "Pending" }}</td>
                <td *ngxPermissionsOnly="['SUPER_ADMIN','APPROVE_IZIN_MENGAJAR']">
                    <div class="form-switch">
                        <input class="form-check-input" 
                            type="checkbox"
                            (change)="approvalCheck($event, guru)" 
                            [checked]="guru.status === 'PERMITTED'"
                            role="switch" id="flexSwitchCheckDefault">                
                    </div>
                </td>
                <td class="settings">
                  <div class="dropend">
                      <a href="#" role="button" id="dropdownMenuLink"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <img src="./assets/images/icon-setting.svg" alt="">
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li *ngxPermissionsOnly="['SUPER_ADMIN','CAN_ASSIGN_GURU_PENGGANTI']">
                          <a (click)="openAssignModalformIzinMengajar(formAssignGuruPenggantiModal,guru)" class="dropdown-item">
                            <img src="./assets/images/dropdown/icon-user.png" alt="" />
                            Assign Guru Pengganti
                          </a>
                        </li>
                        <li><a (click)="openEditModalformIzinMengajar(formIzinMengajarModal, guru)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>                          
                        <li><a (click)="confirmDelete(guru)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                      </ul>
                  </div>
              </td>
              </tr>
              <tr *ngIf="listIzinGuru.length === 0" class="empty">
                <td colspan="6">Tidak terdapat data atau data tidak ditemukan.</td>
              </tr>
            </tbody>
          </table>
        </div>
      
        <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
          <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
            <div></div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center pagination-number">
                <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                  <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                    {{ page.label }}
                  </a>
                  <a class="page-link" *ngIf="p.getCurrent() === page.value">
                    {{ page.label }}
                  </a>
                </li>
              </ul>
            </nav>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="p.isFirstPage()">
                  <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                    <span>Previous</span>
                  </a>
                </li>
                <li class="page-item" [class.disabled]="p.isLastPage()">
                  <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                    <span>Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </pagination-template>
      
        <ng-template #emptyPagination>
          <div class="pagination-wrapper">
            <div>&nbsp;</div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  
  <ng-template #formIzinMengajarModal let-modal>
    <div class="modal-body">
        <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
        <div class="modal-form">
            <h1 class="text-center" *ngIf="isNew">Tambah Form Izin Tidak Mengajar</h1>
            <h1 class="text-center" *ngIf="isUpdate">Edit Form Izin Tidak Mengajar</h1>
            <form [formGroup]="formIzinMengajar" (ngSubmit)="onSubmitformIzinMengajar(modal)">
  
              <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">Nama</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="guru_name"
                    placeholder="">
                </div>
              </div>
  
              <div class="mb-3 mt-4 form-group row">
                <label for="start_date" class="col-sm-3 control-label">Dari Tanggal</label>
                <div class="col-sm-9">
                    <input
                        type="text"
                        autocomplete="off"
                        id="start_date"
                        (blur)="changeStartDateField($event)"                                  
                        formControlName="start_date"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f['start_date'].errors }"
                        placeholder=""
                    />
                </div>
              </div>

              <div class="mb-3 mt-4 form-group row">
                <label for="end_date" class="col-sm-3 control-label">Sampai Tanggal</label>
                <div class="col-sm-9">
                    <input
                        type="text"
                        autocomplete="off"
                        id="end_date"
                        (blur)="changeEndDateField($event)"                                  
                        formControlName="end_date"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f['end_date'].errors }"
                        placeholder=""
                    />
                </div>
              </div>

              <div class="mb-3 mt-4 form-group row">
                <label for="kategori" class="col-sm-3 control-label">Kategori</label>
                <div class="col-sm-9">
                  <select
                      formControlName="kategori"
                      [ngClass]="{ 'is-invalid': submitted && f['kategori'].errors }"
                      class="form-select highlight"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="data" *ngFor="let data of listKategori">{{data}}</option>
                  </select>
                </div>
              </div>

              <div class="mb-3 mt-4 form-group row">
                <label for="Keterangan" class="col-sm-3 control-label">Keterangan</label>
                <div class="col-sm-9">
                    <textarea 
                        class="form-control" 
                        formControlName="keterangan"
                        rows="3"
                    >
                    </textarea>
                </div>
              </div>
  
              <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isNew">CREATE</button>
              <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isUpdate">Update</button>
            </form>
        </div>
    </div>
  </ng-template>

  <ng-template #formAssignGuruPenggantiModal let-modal>
    <div class="modal-body">
        <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
        <div class="modal-form">
            <h1 class="text-center">Form Assign Guru Pengganti</h1>
            
            <form [formGroup]="formIzinMengajar" (ngSubmit)="onSubmitformAssignIzinMengajar(modal)">
  
              <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">Nama</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="guru_name"
                    placeholder="">
                </div>
              </div>
  
              <div class="row mb-3">
                <label for="inputNamaGuruPengganti" class="col-sm-3 col-form-label control-label">Guru Pengganti</label>
                <div class="col-sm-9 autocomplete">
                  <input
                    [ngClass]="{ 'is-invalid': submitted && f['guru_pengganti_name'].errors }" 
                    formControlName="guru_pengganti_name" (keyup)="searchGuru()" type="text" class="form-control" 
                    autocapitalize="off" autocorrect="off" autocomplete="off" id="inputNamaGuruPengganti">
  
                  <div id="myInputautocomplete-list-Guru" class="autocomplete-items">
                    <button *ngIf="isShowLoaderGuru" class="btn btn-primary" type="button" disabled>
                      <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      Searching...
                    </button>
                    <div (click)="chooseGuru(dataGuru)" *ngFor="let dataGuru of listGuruPengganti">
                      {{ dataGuru.userProfile.real_name }}
                    </div>
  
                  </div>
                </div>
              </div>              
  
              <button type="submit" class="btn btn-primary mt-3 my-btn-save">SAVE</button>
              
            </form>
        </div>
    </div>
  </ng-template>