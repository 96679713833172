import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { PelajaranService } from 'src/app/services/pelajaran.service';


@Component({
  selector: 'app-pelajaran-edit',
  templateUrl: './pelajaran-edit.component.html',
  styleUrls: ['./pelajaran-edit.component.css']
})
export class PelajaranEditComponent implements OnInit {

  listsLembaga: any = [];
  listsTingkat: any = [];
  listRelasiNilai: any = [
    {
      key: "Nilai Al-Quran",
      val: "NILAI_QURAN"
    },
    {
      key: "Insya Yaumi",
      val: "INSYA_YAUMI"
    },
    {
      key: "Daily Composition",
      val: "DAILY_COMPOSITION"
    },
    {
      key: "Ibadah Amaliah",
      val: "IBADAH_AMALIAH"
    },
  ];
  errorMessage = '';
  tahunAjaranActive = "";
  id:string = "";

  form: FormGroup = new FormGroup({
    kode_studi: new FormControl(''),
    nama_studi: new FormControl(''),
    nama_studi_ar: new FormControl(''),
    nama_studi_en: new FormControl(''),
    is_nilai_harian: new FormControl(false),
    is_nilai_mid: new FormControl(false),
    is_nilai_semester: new FormControl(false),
    relasi_nilai: new FormControl(''),
  });
  submitted = false;

  constructor(private formBuilder: FormBuilder, 
    private uiService: UiService,
    private route: ActivatedRoute, 
    private pelajaranService: PelajaranService,
    private router: Router) 
  { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.initForm();
    this.getPelajaranEdit();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru");
    }
  }

  onBack() {
    this.router.navigate(['/master/pelajaran/list']);
  }
  
  initForm() {
    this.form = this.formBuilder.group(
      {
        kode_studi: ['', Validators.required],
        nama_studi: ['', Validators.required],
        nama_studi_ar: ['', Validators.required],
        nama_studi_en: ['', Validators.required],
        is_nilai_harian: [false],
        is_nilai_mid: [false],
        is_nilai_semester: [false],
        relasi_nilai: ['']
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    
    console.log(JSON.stringify(this.form.value, null, 2));

    if (this.form.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    this.pelajaranService.update(this.id, this.form.value).subscribe({
      next: resdata => {
        console.log(resdata);
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
      
        this.router.navigate(['/master/pelajaran/list']);

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });    
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

  getPelajaranEdit() {
    this.uiService.showLoader();

    this.pelajaranService.get(this.id).subscribe({
      next: resdata => {
        console.log('PELAJARAN EDIT', resdata);

        this.form.patchValue(resdata)
        
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

}
