import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UiService } from 'src/app/helper/ui.service';
import { GeneralService } from 'src/app/services/general.service';
import { SiswaService } from 'src/app/services/siswa.service';
import { BerkasService } from 'src/app/services/berkas.service';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-siswa-dokumen',
  templateUrl: './siswa-dokumen.component.html',
  styleUrls: ['./siswa-dokumen.component.css']
})
export class SiswaDokumenComponent implements OnInit {

  listUploadDocuments: any = [];
  listBerkas: any = [];
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;

  closeResult: string = '';

  constructor(private siswaService: SiswaService,
    private generalService: GeneralService,
    private berkasService: BerkasService,
    private uiService: UiService,
    private fileService: FileService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getAllBerkas();
    this.getAllSiswaDokumen();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getAllBerkas() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.berkasService.getAllBerkas(params).subscribe({
      next: resdata => {
        console.log('berkas', resdata);
        
        this.listBerkas = resdata.data;
        
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  downloadFileBerkas(berkasId: number, berkas: any) {
    console.log(berkas);

    const found = berkas.find((obj: { berkas_id: number; }) => {
      return obj.berkas_id === berkasId;
    });
    
    if (found) {
      console.log(found);
      const fUrl = this.fileService.getImageUrl("berkas/" + found.file_url);
      window.open(fUrl, '_blank');
    }

  }

  getAllSiswaDokumen() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.siswaService.getAllSiswaDokumen(params).subscribe({
      next: resdata => {
        console.log(resdata);
        const { data, totalrows } = resdata;

        this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listUploadDocuments = data;
        this.count = totalrows;

        console.log('COUNT', this.count);


        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }


  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    this.getAllSiswaDokumen();
  }

}
