import { Component, OnInit } from '@angular/core';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { KelasService } from 'src/app/services/kelas.service';
import { WalikelasService } from 'src/app/services/walikelas.service';
import { JadwalPelajaranService } from 'src/app/services/jadwal-pelajaran.service';
import { CommonService } from 'src/app/helper/common.service';
import { AbsensiService } from 'src/app/services/absensi.service';
import { IzinMengajarService } from 'src/app/services/izin-mengajar.service';

@Component({
  selector: 'app-pendidikan-input-guru',
  templateUrl: './pendidikan-input-guru.component.html',
  styleUrls: ['./pendidikan-input-guru.component.css']
})
export class PendidikanInputGuruComponent implements OnInit {

  listAbsensiSiswa: any = [];
  listKelas: any = [];
  listSemester: any = [];
  listGuruPengganti: any = [];
  currentPeriodeId: any = null;
  currentKelasId: any = null;
  currentcurrentJadwalPelajaranId: any = null;
  currentGuruId: any = null;
  currentGuruName = "";
  currentAbsensiGuruInputId: any = null;
  currentAngkatan = "";
  currentSemester = "";
  pageid = "absensi";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  errorMessage = "";
  currentDate = "";
  listDayPeriod: any = [];
  numOfDayCol = 0;
  submitted = false;
  today = "";
  currentJadwalPelajaranId: any = null;
  isAbsenCompleted: boolean = false;
  isAbsenReplacement: boolean = false;
  numOfSiswaActive:number = 0;
  saveGuruInputDate = "";
  

  formInputInitGuru: FormGroup = new FormGroup({
    tahun_ajaran: new FormControl(''),
    periode_id: new FormControl(''),
    id_semester: new FormControl(''),
    id: new FormControl(''),
    kelas_id: new FormControl(''),
    guru_id: new FormControl(''),
    guru_name: new FormControl(''),
    walikelas_name: new FormControl(''),
    proguser_id: new FormControl(''),
    tanggal: new FormControl(''),
    jam_pelajaran: new FormControl(''),
    mata_pelajaran: new FormControl(''),
    studi_id: new FormControl(''),
    judul_materi: new FormControl(''),    
  });

  formInputAbsensi: FormGroup = new FormGroup({
    dataFormAbsensiSiswaNya: new FormArray([]),
    materi: new FormControl(''),
    completed: new FormControl(''),
    is_replacement: new FormControl(''),
    guru_penginput_id: new FormControl(''),
    absensi_guru_input_id: new FormControl(''),
    total_siswa: new FormControl(''),
    jumlah_siswa_aktif: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private kelasService: KelasService,
    private tahunajaranService: TahunajaranService,
    private walikelasService: WalikelasService,
    private jadwalpelajaranService: JadwalPelajaranService,
    private commonService: CommonService,
    private absensiService: AbsensiService,
    private formBuilder: FormBuilder,
    private izinmengajarService: IzinMengajarService,
  ) { 
    this.changeBodyClass();

    this.initForm();

    this.currentDate = this.commonService.getCurentDate();
    this.formInputInitGuru.controls['tanggal'].setValue(this.currentDate);
    
    this.formInputInitGuru.controls['kelas_id'].disable();
    this.formInputInitGuru.controls['jam_pelajaran'].disable();
    this.formInputInitGuru.controls['tanggal'].disable();
    this.formInputInitGuru.controls['tahun_ajaran'].disable();
    this.formInputInitGuru.controls['walikelas_name'].disable();
    this.formInputInitGuru.controls['guru_name'].disable();
    this.formInputInitGuru.controls['mata_pelajaran'].disable();
  }

  ngOnInit(): void {    
    this.today = this.commonService.getCurrentDayName();
    console.log("HARI INI", this.today);
    //OVERRIDING FOR TESTING
    //this.today = "Saturday";
    
    this.listSemester = this.commonService.getListSemester();
    this.getCurrentTahunajaran();
    this.getKelas();
    
    const currentUser = localStorage.getItem('user')?.toString();
    const jsonUser = currentUser ? JSON.parse(currentUser) : "";
    console.log("CURRENT USER", jsonUser.proguser.userProfile.real_name);
    this.currentGuruId = jsonUser.proguser.id;
    this.currentGuruName = jsonUser.proguser.userProfile.real_name;

    this.checkGuruPengganti();

    this.formInputInitGuru.controls['id_semester'].disable();
    this.formInputInitGuru.controls['guru_id'].setValue(this.currentGuruId);
    this.formInputInitGuru.controls['guru_name'].setValue(this.currentGuruName);
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  checkGuruPengganti() {
    this.isAbsenReplacement = false;
    const today = this.commonService.convertDate();
    const payload = {
      guru_pengganti_id: this.currentGuruId,
      current_date: today
    }
    this.izinmengajarService.getGuruPengganti(payload).subscribe({
      next: resdata => {
        console.log("DATA Guru Izin", resdata);
        
        if (resdata.length > 0) {
          this.isAbsenReplacement = true;
          const guruAjar = {
            "name": this.currentGuruName,
            "id": this.currentGuruId
          }
          this.listGuruPengganti.push(guruAjar);
          for (let i=0; i<resdata.length; i++) {
            const guruAjar = {
              "name": resdata[i].real_name,
              "id": resdata[i].guru_id
            }
            this.listGuruPengganti.push(guruAjar);
          }

          console.log("LIST GURU PENGGANTI", this.listGuruPengganti);
          
        }

        this.uiService.hideLoader();
      },
      error: err => {
        console.log(err);
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    }); 
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {
    this.formInputInitGuru = this.formBuilder.group(
      {
        tahun_ajaran: ['', Validators.required],
        id: [''],
        periode_id: ['', Validators.required],
        guru_id: ['', Validators.required],
        guru_name: ['', Validators.required],
        id_semester: ['', Validators.required],
        kelas_id: ['', Validators.required],
        walikelas_name: [''],
        proguser_id: ['', Validators.required],
        studi_id: ['', Validators.required],
        jam_pelajaran: ['', Validators.required],
        mata_pelajaran: [''],
        tanggal: [''],
        judul_materi: [''],
      },
    );

    this.formInputAbsensi = this.formBuilder.group(
      {
        dataFormAbsensiSiswaNya: new FormArray([]),
        materi: [''],
        completed: [''],
        is_replacement: [''],
        guru_penginput_id: [''],
        absensi_guru_input_id: [''],
        total_siswa: [''],
        jumlah_siswa_aktif: [''],
      },
    );
  }

  get dataFormAbsensiSiswaNya(): FormArray {
    return this.formInputAbsensi.get('dataFormAbsensiSiswaNya') as FormArray;
  }

  getDayPeriod() {

    this.uiService.showLoader();

    const payload = {
      periode_id: this.currentPeriodeId,
      semester: this.currentSemester
    }

    console.log("PAYLOAD", payload);
    
    
    this.jadwalpelajaranService.getDayPeriod(payload).subscribe({
      next: resdata => {
        console.log("DAY PERIOD : ", resdata);
                
        this.listDayPeriod = resdata;
        this.numOfDayCol = resdata.length;

        this.uiService.hideLoader();      

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getJadwalByJamke() {

    this.uiService.showLoader();

    const payload = {
      periode_id: this.currentPeriodeId,
      semester: this.currentSemester,
      kelas_id: this.formInputInitGuru.value.kelas_id,
      jam_pelajaran: this.formInputInitGuru.value.jam_pelajaran,
      guru_id: this.formInputInitGuru.value.guru_id,
      day: this.today
    }

    console.log("PAYLOAD getJadwalByJamke", payload);
    
    
    this.jadwalpelajaranService.getJadwalByJamke(payload).subscribe({
      next: resdata => {
        console.log("DAY PERIOD JAMKE : ", resdata);
        
        if (resdata.length > 0) {
          this.currentJadwalPelajaranId = resdata[0].id;
          this.formInputInitGuru.controls['mata_pelajaran'].setValue(resdata[0].nama_studi);
          this.formInputInitGuru.controls['studi_id'].setValue(resdata[0].studi_id);

          // get guru input
          this.getGuruInput();
        } else {
          this.currentJadwalPelajaranId = null;
          this.formInputInitGuru.controls['mata_pelajaran'].setValue("");
          this.formInputInitGuru.controls['studi_id'].setValue("");
          this.formInputInitGuru.controls['judul_materi'].setValue("");
        }
        // this.listDayPeriod = resdata;
        // this.numOfDayCol = resdata.length;

        this.uiService.hideLoader();      

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getGuruInput() {
    const today = this.commonService.convertDate();
    const payload = {
      jadwal_pelajaran_id: this.currentJadwalPelajaranId,
      tanggal: today
    }

    console.log("GI PAYLOAD", payload);
    

    this.absensiService.getGuruInput(payload).subscribe({
      next: resdata => {
        console.log("Guru Input ", resdata);

        if (resdata === null) {
          this.saveGuruInput();
        } else {
          this.saveGuruInputDate = resdata.tanggal;
          this.formInputInitGuru.controls['judul_materi'].setValue(resdata.materi);
          this.currentAbsensiGuruInputId = resdata.id;
          this.isAbsenCompleted = resdata.completed;
        }
        
        this.getAbsensiSiswa();

        this.uiService.hideLoader();      

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  saveGuruInput() {
    const today = this.commonService.convertDate();
    const payload = {
      jadwal_pelajaran_id: this.currentJadwalPelajaranId,
      tanggal: today,
      materi: "",
      guru_penginput_id: this.currentGuruId 
    }

    console.log("PAYLOAD GURU INPUT", payload);
    

    this.absensiService.saveGuruInput(payload).subscribe({
      next: resdata => {
        console.log("Guru Input ", resdata);

        this.saveGuruInputDate = resdata.tanggal;

        this.currentAbsensiGuruInputId = resdata.id;
        
        this.uiService.hideLoader();      

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAbsensiSiswa() {

    const payload = {
      periode_id:  this.currentPeriodeId,
      kelas_id:  this.currentKelasId,
      absensi_guru_input_id: this.currentAbsensiGuruInputId
    }

    console.log("G ABSEN PAYLOAD,", payload);
    

    this.absensiService.getAbsensiHarianSiswa(payload).subscribe({
      next: resdata => {
        console.log("getAbsensiSiswa : ", resdata);
        console.log("dataFormAbsensiSiswaNya", this.dataFormAbsensiSiswaNya);
        

        this.listAbsensiSiswa = resdata;
        
        // reset dataFormAbsensiSiswaNya

        while (this.dataFormAbsensiSiswaNya.length) {
          this.dataFormAbsensiSiswaNya.removeAt(0);
        }

        this.numOfSiswaActive = 0;

        for (let i = 0; i < this.listAbsensiSiswa.length; i++) {
          // cek siswa aktif
          if (this.listAbsensiSiswa[i].status === "ACTIVE") {
            this.numOfSiswaActive++;
          }

          this.dataFormAbsensiSiswaNya.push(this.formBuilder.group({            
            'absensi_guru_input_id': this.listAbsensiSiswa[i] && this.listAbsensiSiswa[i].absensi_guru_input_id ? this.listAbsensiSiswa[i].absensi_guru_input_id : this.currentAbsensiGuruInputId,
            'siswa_id': this.listAbsensiSiswa[i] && this.listAbsensiSiswa[i].siswa_id ? this.listAbsensiSiswa[i].siswa_id : '',
            'absen': this.listAbsensiSiswa[i] && this.listAbsensiSiswa[i].absen ? this.listAbsensiSiswa[i].absen : '',
            'catatan_positif': this.listAbsensiSiswa[i] && this.listAbsensiSiswa[i].catatan_positif ? this.listAbsensiSiswa[i].catatan_positif : '',
            'catatan_negatif': this.listAbsensiSiswa[i] && this.listAbsensiSiswa[i].catatan_negatif ? this.listAbsensiSiswa[i].catatan_negatif : '',
            'id_catatan_siswa': this.listAbsensiSiswa[i] && this.listAbsensiSiswa[i].id_catatan_siswa ? this.listAbsensiSiswa[i].id_catatan_siswa : '',
            'siswa_status': this.listAbsensiSiswa[i] && this.listAbsensiSiswa[i].status ? this.listAbsensiSiswa[i].status : ''
          }));
        }

        console.log("DATA FORM ABSENSI SISWA", this.numOfSiswaActive, this.dataFormAbsensiSiswaNya);
        this.formInputAbsensi.controls['total_siswa'].setValue(this.listAbsensiSiswa.length);
        this.formInputAbsensi.controls['jumlah_siswa_aktif'].setValue(this.numOfSiswaActive);

        this.uiService.hideLoader();      

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata.Semester);        

        this.currentPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        this.formInputInitGuru.controls['tahun_ajaran'].setValue(this.currentAngkatan);

        for (let i=0; i<resdata.Semester.length; i++) {
          const periodeSemester = this.commonService.getCurrentDateBetweenDateRange(resdata.Semester[i].start_date, resdata.Semester[i].end_date);

          if (periodeSemester) {
            this.currentSemester = resdata.Semester[i].semester;
            this.formInputInitGuru.controls['id_semester'].setValue(this.currentSemester);
            this.formInputInitGuru.controls['kelas_id'].enable();
            
            this.getDayPeriod();          
            
            break;    
          }
        }

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onRadioClick() {
    console.log(this.formInputAbsensi.value.dataFormAbsensiSiswaNya.length);
    let countAbsenSiswa:number = 0;
    for (let i=0; i<this.formInputAbsensi.value.dataFormAbsensiSiswaNya.length; i++) {
      console.log(this.formInputAbsensi.value.dataFormAbsensiSiswaNya[i].absen);
      if (this.formInputAbsensi.value.dataFormAbsensiSiswaNya[i].absen !== "") countAbsenSiswa++;
    }
    console.log(countAbsenSiswa, this.numOfSiswaActive);
    

    this.isAbsenCompleted = this.numOfSiswaActive === countAbsenSiswa;
    
  }

  onSubmitformInputInitGuru() {
    // check input guru pengganti
    console.log("DIFF TANGGAL", this.saveGuruInputDate, this.currentDate);
    
    if (this.saveGuruInputDate === this.currentDate && this.isAbsenReplacement) {
      this.formInputAbsensi.controls['is_replacement'].setValue(this.isAbsenReplacement);  
    }
    this.formInputAbsensi.controls['materi'].setValue(this.formInputInitGuru.value.judul_materi)
    this.formInputAbsensi.controls['completed'].setValue(this.isAbsenCompleted);
    this.formInputAbsensi.controls['guru_penginput_id'].setValue(this.currentGuruId);
    this.formInputAbsensi.controls['absensi_guru_input_id'].setValue(this.currentAbsensiGuruInputId);

    console.log(JSON.stringify(this.formInputAbsensi.value, null, 2));
    console.log(this.formInputAbsensi.value.dataFormAbsensiSiswaNya.length);

    if (this.formInputAbsensi.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    this.absensiService.addAbsensiHarianSiswa(this.formInputAbsensi.value).subscribe({
      next: resdata => {
        console.log(resdata);

        this.uiService.hideLoader();
        this.uiService.showSuccess('');

        // reset from data
        this.formInputInitGuru.controls['kelas_id'].setValue('');
        this.formInputInitGuru.controls['jam_pelajaran'].setValue('');
        this.formInputInitGuru.controls['walikelas_name'].setValue('');
        this.formInputInitGuru.controls['mata_pelajaran'].setValue('');
        this.formInputInitGuru.controls['judul_materi'].setValue('');

        this.listAbsensiSiswa = [];
        this.currentAbsensiGuruInputId = null;
      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });    
  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeKelas(e: any) {

    this.listAbsensiSiswa = [];
    this.formInputInitGuru.controls['jam_pelajaran'].setValue("");
    this.currentJadwalPelajaranId = null;
    this.formInputInitGuru.controls['mata_pelajaran'].setValue("");
    this.formInputInitGuru.controls['studi_id'].setValue("");
    this.formInputInitGuru.controls['judul_materi'].setValue("");
    
    if (e.target.value !== "") {
      this.formInputInitGuru.controls['jam_pelajaran'].enable();
      const kelas_id = e.target.value;
      this.currentKelasId = kelas_id;
      this.getWalikelasByKelas(kelas_id);
    } else {
      this.formInputInitGuru.controls['jam_pelajaran'].disable();
    }

  }

  changeJamPelajaran(e: any) {
    this.isAbsenCompleted = false;
    this.listAbsensiSiswa = [];
    this.getJadwalByJamke();
  }

  changeGuruPengganti(e: any) {
    console.log(e.target.value, this.formInputInitGuru.value.jam_pelajaran);
    if (this.formInputInitGuru.value.jam_pelajaran !== "") {
      this.isAbsenCompleted = false;
      this.listAbsensiSiswa = [];
      this.getJadwalByJamke();
    }
  }

  changeSemester(e: any) {
    if (e.target.value !== "") {
      this.formInputInitGuru.controls['kelas_id'].enable();
      this.getDayPeriod();
    } else {
      this.listDayPeriod = [];
      this.formInputInitGuru.controls['jam_pelajaran'].setValue("");
      this.formInputInitGuru.controls['jam_pelajaran'].disable();
      this.formInputInitGuru.controls['kelas_id'].setValue("");
      this.formInputInitGuru.controls['kelas_id'].disable();
    }
  }

  getWalikelasByKelas(kelasid: any) {
    this.uiService.showLoader();

    this.walikelasService.getByPeriodeAndKelas(this.currentPeriodeId, kelasid).subscribe({
      next: resdata => {
        console.log("WALIKELAS", resdata);
        this.formInputInitGuru.controls['walikelas_name'].setValue(resdata.User.userProfile.real_name);
        
        // this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formInputInitGuru.controls;
  }


}
