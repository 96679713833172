

<div class="content-item">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="back-button" (click)="onBack()">
            <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
        </div>
        <div class="item-header" *ngIf="isAddMode">
            Form Add SDM
        </div>
        <div class="item-header" *ngIf="!isAddMode">
            Form Edit SDM
        </div>
        <div class="row">
            <div class="col-12 col-lg-3 mb-3 mb-lg-0">
                <div class="row">
                    <div class="col-6 col-lg-12">
                        <div class="card secondary">
                            <div class="card-header">
                                Photo
                            </div>
                            <input type='file' #photoUpload (click)="photoUpload.value=''" style="display: none;" (change)="uploadPhoto($event);" />
                            <div style="cursor: pointer;" class="card-body text-center" (click)="photoUpload.click()">
                                <img [src]="imgSrc || './assets/images/empty-image.png'" class="w-100" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-12">
                        <div class="card secondary">
                            <div class="card-header">
                                Tanda Tangan
                            </div>
                            <input type='file' #signUpload (click)="signUpload.value=''" style="display: none;" (change)="uploadSign($event);" />
                            <div style="cursor: pointer;" class="card-body text-center" (click)="signUpload.click()">
                                <img [src]="imgSignSrc || './assets/images/empty-image.png'" class="w-100" alt="">
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col-12 col-lg-9">
                <div class="card">
                    <div class="card-header">
                        DATA PRIBADI
                    </div>
                    <div class="card-body">
                        <!-- <form action=""> -->
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="nik" class="form-label">NIK KTP <span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">                                                
                                                <input
                                                    type="text"
                                                    formControlName="nik"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['nik'].errors }"
                                                    placeholder=""
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="name_ar" class="form-label">Nama Arab</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control" formControlName="name_ar" id="name_ar"
                                                    placeholder="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="nama-ijazah" class="form-label">Nama Sesuai
                                                    Ijazah</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control" name="nama-ijazah"
                                                    id="nama-ijazah" placeholder="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="gelar" class="form-label">Gelar Akademik <span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <input
                                                    type="text"
                                                    formControlName="gelar_akademik"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['gelar_akademik'].errors }"
                                                    placeholder=""
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="name-ktp" class="form-label">Nama Sesuai KTP <span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <input
                                                    type="text"
                                                    formControlName="real_name"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['real_name'].errors }"
                                                    placeholder=""
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="birthplace" class="form-label">Tempat Lahir <span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <input
                                                    type="text"
                                                    formControlName="pob"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['pob'].errors }"
                                                    placeholder=""
                                                  />                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="birthdate" class="form-label">Tanggal Lahir <span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <input
                                                    type="text"
                                                    formControlName="dob"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['dob'].errors }"
                                                    placeholder="ex: 2022-08-14"
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="name" class="form-label">Jenis Kelamin <span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <select
                                                    formControlName="gender"
                                                    [ngClass]="{ 'is-invalid': submitted && f['gender'].errors }"
                                                    class="form-select">
                                                    <option value="" selected disabled>Pilih</option>
                                                    <option value="L">Laki-laki</option>
                                                    <option value="P">Perempuan</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="hobby" class="form-label">Hobby</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control" name="hobby" id="hobby"
                                                    placeholder="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="name" class="form-label">Status Menikah <span class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <select 
                                                    formControlName="marital_status"
                                                    [ngClass]="{ 'is-invalid': submitted && f['marital_status'].errors }"
                                                     class="form-select">
                                                    <option value="" selected disabled>Pilih</option>
                                                    <option value="NOT_MARRIED">Belum Menikah</option>
                                                    <option value="MARRIED">Menikah</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="facebook" class="form-label">ID Facebook</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control" name="facebook"
                                                    id="facebook" placeholder="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="instagram" class="form-label">ID Instagram</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control" name="instagram"
                                                    id="instagram" placeholder="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="twitter" class="form-label">ID Twitter</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control" name="twitter"
                                                    id="twitter" placeholder="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="nik" class="form-label">No. Handphone 1 <span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <input
                                                    type="text"
                                                    formControlName="no_hp1"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['no_hp1'].errors }"
                                                    placeholder=""
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="name" class="form-label">No. Handphone
                                                    2</label>
                                            </div>
                                            <div class="col-8">
                                                <input
                                                    type="text"
                                                    formControlName="no_hp2"
                                                    class="form-control"
                                                    placeholder=""
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="nama-ijazah" class="form-label">Golongan
                                                    Darah</label>
                                            </div>
                                            <div class="col-8">
                                                <select 
                                                    formControlName="golongan_darah"
                                                    [ngClass]="{ 'is-invalid': submitted && f['golongan_darah'].errors }"
                                                     class="form-select">
                                                    <option value="" selected disabled>Pilih</option>
                                                    <option value="A">A</option>
                                                    <option value="B">B</option>
                                                    <option value="AB">AB</option>
                                                    <option value="O">O</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="gelar" class="form-label">Ayah</label>
                                            </div>
                                            <div class="col-8">
                                                <input
                                                    type="text"
                                                    formControlName="ayah"
                                                    class="form-control"
                                                    placeholder=""
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="name-ktp" class="form-label">Ibu</label>
                                            </div>
                                            <div class="col-8">
                                                <input
                                                    type="text"
                                                    formControlName="ibu"
                                                    class="form-control"
                                                    placeholder=""
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="address" class="form-label">Alamat (Jalan -
                                                    Kampung - Nomor Rumah)<span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <textarea type="text"
                                                    formControlName="alamat"
                                                    [ngClass]="{ 'is-invalid': submitted && f['alamat'].errors }"
                                                    class="form-control" name="address"
                                                    id="address" placeholder=""></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-4 text-end">
                                                <label for="rt" class="form-label">RT<span
                                                    class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <div class="row">
                                                    <div class="col-5">
                                                        <input
                                                            type="text"
                                                            formControlName="rt"
                                                            class="form-control"
                                                            [ngClass]="{ 'is-invalid': submitted && f['rt'].errors }"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div class="col-2 text-end">
                                                        <label for="rw" class="form-label">RW<span
                                                            class="required">*</span></label>
                                                    </div>
                                                    <div class="col-5">
                                                        <input
                                                            type="text"
                                                            formControlName="rw"
                                                            class="form-control"
                                                            [ngClass]="{ 'is-invalid': submitted && f['rw'].errors }"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="provinsi" class="form-label">Provinsi<span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <select 
                                                    formControlName="provinsi"
                                                    [ngClass]="{ 'is-invalid': submitted && f['provinsi'].errors }"
                                                    class="form-select"
                                                    (change)="changeKabupaten($event)">
                                                    <option value="" selected disabled>Pilih</option>
                                                    <option *ngFor="let data of listsProvinsi">{{data.provinsi}}</option>
                                                </select>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="kota" class="form-label">Kota<span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <select 
                                                    formControlName="kota_kab"
                                                    [ngClass]="{ 'is-invalid': submitted && f['kota_kab'].errors }"
                                                    class="form-select"
                                                    (change)="changeKecamatan($event)">
                                                    <option value="" selected disabled>Pilih</option>
                                                    <option *ngFor="let data of listsKabupaten">{{data.kabupaten}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="kecamatan" class="form-label">Kecamatan<span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <select 
                                                    formControlName="kecamatan"
                                                    [ngClass]="{ 'is-invalid': submitted && f['kecamatan'].errors }"
                                                    class="form-select"
                                                    (change)="changeKelurahan($event)">
                                                    <option value="" selected disabled>Pilih</option>
                                                    <option *ngFor="let data of listsKecamatan">{{data.kecamatan}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="desa_kelurahan" class="form-label">Desa / Kelurahan<span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <select 
                                                    formControlName="desa_kelurahan"
                                                    [ngClass]="{ 'is-invalid': submitted && f['desa_kelurahan'].errors }"
                                                    class="form-select"
                                                    >
                                                    <option value="" selected disabled>Pilih</option>
                                                    <option [ngValue]="data.kelurahan" *ngFor="let data of listsKelurahan">{{data.kelurahan}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!-- </form> -->
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        DATA SEKOLAH
                    </div>
                    <div class="card-body">
                        <!-- <form action=""> -->
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="nik" class="form-label">Kode Guru/NIP <span
                                                    class="required">*</span></label>
                                            </div>
                                            <div class="col-8">                                                
                                                <input
                                                    type="text"
                                                    formControlName="kode_guru_nip"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['kode_guru_nip'].errors }"
                                                    placeholder=""
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="name_ar" class="form-label">NUPTK</label>
                                            </div>
                                            <div class="col-8">
                                                <input
                                                    type="text"
                                                    formControlName="nuptk"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['nuptk'].errors }"
                                                    placeholder=""
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="nama-ijazah" class="form-label">E-mail <span
                                                    class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <input
                                                    type="text"
                                                    formControlName="email"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
                                                    placeholder=""
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="nama-ijazah" class="form-label">Username (Login username) <span
                                                    class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <input
                                                    type="text"
                                                    formControlName="username"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['username'].errors }"
                                                    placeholder=""
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="gelar" class="form-label">Tugas Utama <span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <select 
                                                    formControlName="roleId"
                                                    [ngClass]="{ 'is-invalid': submitted && f['roleId'].errors }"
                                                    class="form-select"
                                                    >
                                                    <option value="" selected disabled>Pilih</option>
                                                    <option *ngFor="let data of listsRoles" [value]="data.id">{{data.role_name}}</option>
                                                </select>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="name-ktp" class="form-label">Aktifitas di Luar Pondok Pesantren<span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <textarea type="text"
                                                    formControlName="aktifitas_luar_pondok"
                                                    [ngClass]="{ 'is-invalid': submitted && f['aktifitas_luar_pondok'].errors }"
                                                    class="form-control" name="address"
                                                    id="address" placeholder=""></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="nik" class="form-label">Rekomendasi Masuk Pondok Pesantren Darunnajah 1 Jakarta <span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="col-8">
                                                <textarea type="text"
                                                    formControlName="rekomendasi"
                                                    [ngClass]="{ 'is-invalid': submitted && f['rekomendasi'].errors }"
                                                    class="form-control" name="address"
                                                    id="address" placeholder="Sebutkan dan jelaskan"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="name" class="form-label">Status</label>
                                            </div>
                                            <div class="col-8">
                                                <select 
                                                    formControlName="status"
                                                    [ngClass]="{ 'is-invalid': submitted && f['status'].errors }"
                                                    class="form-select"
                                                    >
                                                    <option value="" selected disabled>Pilih</option>
                                                    <option value="AKTIF">Aktif</option>
                                                    <option value="NON_AKTIF">Non-Aktif</option>
                                                    <option value="CUTI">Cuti</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="nama-ijazah" class="form-label">Tahun Mulai Bertugas</label>
                                            </div>
                                            <div class="col-8">
                                                <input
                                                    type="text"
                                                    formControlName="tahun_bertugas"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['tahun_bertugas'].errors }"
                                                    placeholder=""
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-end">
                                                <label for="gelar" class="form-label">Tanggal Masuk</label>
                                            </div>
                                            <div class="col-8">                                                
                                                <input
                                                    type="text"
                                                    formControlName="tanggal_masuk"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f['tanggal_masuk'].errors }"
                                                    placeholder="ex: 2022-08-14"
                                                  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!-- </form> -->
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row align-items-center">
          <div class="col-6 col-lg-4 offset-lg-6">
              
          </div>
          <div class="col-6 col-lg-2 d-flex justify-content-end">
              <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>
        <div class="row">
          &nbsp;
        </div>
    </form>
</div>
