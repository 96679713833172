import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { JadwalPelajaranService } from 'src/app/services/jadwal-pelajaran.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { KelasService } from 'src/app/services/kelas.service';
import { CommonService } from 'src/app/helper/common.service';
import * as moment from 'moment';

@Component({
  selector: 'app-monitoring-siswa',
  templateUrl: './monitoring-siswa.component.html',
  styleUrls: ['./monitoring-siswa.component.css']
})
export class MonitoringSiswaComponent implements OnInit {

  currentPeriodeId: any = null;
  listTahunajaran: any = [];
  listTahun: any = [];
  listWaliKelas: any = [];
  listsLembaga: any = [];
  listKelas: any = [];
  listDateDays: any = [];
  listSemester: any = [];
  indexListKelas: any = [];
  listJadwalPelajaran: any = [];
  errorMessage = "";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  isShowLoader: Boolean = false;
  submitted: boolean = false;
  currentDay = "saturday";
  currentAbsensiPeriod = "";
  currentKelasId: any = null;

  listMonth: any = [];

  listDays = [
    {
      key: "saturday",
      val: "Saturday"
    },
    {
      key: "sunday",
      val: "Sunday"
    },
    {
      key: "monday",
      val: "Monday"
    },
    {
      key: "tuesday",
      val: "Tuesday"
    },
    {
      key: "wednesday",
      val: "Wednesday"
    },
    {
      key: "thursday",
      val: "Thursday"
    },
  ]

  formAction: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
    id_semester: new FormControl(''),
  });

  Object = Object;

  constructor(
    private tahunajaranService: TahunajaranService,
    private uiService: UiService,
    private sharedDataService: SharedDataService,
    private jadwalpelajaranService: JadwalPelajaranService,
    private kelasService: KelasService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,


  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.initForm();
    this.getAllKelas();
    this.getDataTahunAjaran(); 
    this.listSemester = this.commonService.getListSemester();

    for (let i=1; i<=31; i++) {
      this.listDateDays.push(i);
    }

    const curYear: number = moment().year();

    for (let y=curYear-10; y <= curYear; y++) {
      this.listTahun.push(y);
    }

    this.listMonth = this.commonService.getListMonth();

    this.currentAbsensiPeriod = "Bulan " + this.listMonth[moment().month()] + " " + moment().year();
  }

  async getDataTahunAjaran() {
    await this.getCurrentTahunajaran();
    await this.getAllTahunajaran();
  }

  initForm() {
    this.formAction = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        id_semester: ['', Validators.required],
      }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAction.controls;
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    this.currentPeriodeId = e.target.value;

    // this.getAllWalikelasByPeriode();
  }

  onSubmitfilterData() {
    this.submitted = true;
    console.log(JSON.stringify(this.formAction.value, null, 2));

    if (this.formAction.invalid) {
      return;
    }

    const searchObject = this.listDays.find((days) => days.key == this.currentDay) || null;

    this.currentAbsensiPeriod = searchObject?.val || "";

    this.getAllJadwalPelajaran();
  }

  showJadwalPerDay(day: any) {
    console.log("DAY", day);   
    this.currentDay = day; 
  }

  changeLembaga(e: any) {

  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        this.currentPeriodeId = resdata.id;

        this.formAction.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);

        this.uiService.hideLoader();

        this.getAllTahunajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formAction.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllJadwalPelajaran() {

    this.uiService.showLoader();

    const payload = {
      periode_id: this.formAction.value.id_tahun_ajaran,
      semester: this.formAction.value.id_semester,
      day: this.currentDay
    }
    
    this.jadwalpelajaranService.getListJadwalGuruByPeriodeSemesterAndDay(payload).subscribe({
      next: resdata => {
        console.log("JADWAL : ", resdata);
        
        // console.log("THIS KELAS", this.listKelas);
        
        const rowCols: any[][] = [];
        
        for (let i = 0; i<this.listKelas.length; i++) {
          let cols: any[] = [];
          this.indexListKelas.push(this.listKelas[i].nama_kelas);
          cols.push(this.listKelas[i].nama_kelas);
          for (let c=1; c<=9; c++) {
            const col = {
              pelajaran: "x",
              guru: "x"
            }
            cols.push(col);
            
            // ctnCol[c.toString()] = col;
            // container[this.listKelas[i].nama_kelas] = ctnCol;
            
          }
          // console.log(cols);
          rowCols.push(cols);
        }
        

        // rowCols.push(["1","2","3"]);
        // rowCols.push(["a","b","c"]);

        console.log("ROW COLS", rowCols);
        
        

        
        for (let s=0; s<resdata.length; s++) {
          const jadwal = resdata[s];
          
          if (jadwal.jamke !== null) {
            
            const ctnColData = {
              pelajaran: jadwal.nama_studi,
              guru: jadwal.real_name
            }

            const indexJamKe = jadwal.jamke - 1;
            const indexKelas = this.indexListKelas.indexOf(jadwal.nama_kelas);

            rowCols[indexKelas][indexJamKe] = ctnColData;
            console.log("JAM KE", jadwal.jamke, "KELAS ", jadwal.nama_kelas);
            
          }
          
        }

        this.listJadwalPelajaran = rowCols;

        console.log("FINAL", this.listJadwalPelajaran);
        
        
        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  showDetailJadwalPelajaranKelas() {
    
    //this.sharedDataService.changeDataObject(object);
    this.sharedDataService.changeComponent('jadwal-mengajar-perkelas');
    this.uiService.showFullScrrenContent();
  }

  getAllKelas() {
    this.uiService.showLoader();

    const params = this.getRequestParams("", this.page, this.pageSize);

    this.kelasService.getAllkelas(params).subscribe({
      next: resdata => {
        console.log('kelas', resdata);
        const { data, totalrows } = resdata;

        this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listKelas = data;
        this.count = totalrows;

        console.log('COUNT', this.count);

      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeKelas(e: any) {
    this.currentKelasId = e.target.value;
  }

}
