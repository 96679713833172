import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  isDaily = false;
  isMonthly = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.changeTab(this.router.url);
  }

  changeTab(url: String) {
    switch (url) {
      case '/news/daily':
        this.isDaily = true;
        this.isMonthly = false;
        return;
      case '/news/monthly':
        this.isDaily = false;
        this.isMonthly = true;
        return;
      default:
        this.isDaily = false;
        this.isMonthly = false;
        return;
    }
  }

}
