<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="statistik"
    role="tabpanel"
    aria-labelledby="statistik-tab"
  >
    <div class="tabs-title">Jadwal Pelajaran</div>

    
    <div class="list-options">
      <div class="form-select-warapper">
        <form [formGroup]="formAction">
          <select formControlName="id_tahun_ajaran" [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }" class="form-select">
            <option [value]="" selected disabled>Pilih TA</option>
            <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
          </select>
        </form>
      </div>

      <div class="form-select-warapper">
        <form [formGroup]="formAction">
          <select formControlName="id_semester" [ngClass]="{ 'is-invalid': submitted && f['id_semester'].errors }" class="form-select">
            <option value="" selected disabled>Pilih Semester</option>
            <option [value]="data.kode" *ngFor="let data of listSemester">{{data.name}}</option>
          </select>
        </form>
      </div>

      <button class="btn btn-blue-grey" (click)="onSubmitfilterData()">
        <img src="./assets/images/icon-filter.png">
        Filter Data
      </button>
      
    </div>

    <div class="table-wrapper">
      <!-- <div class="table-header">
          Rekap Pembayaran Formulir Per Lembaga
      </div> -->
      <div class="table-options mb-2 mt-4">
        <div class="btn-group m-b">
          <button *ngFor="let day of listDays" (click)="showJadwalPerDay(day.key)" type="button" [ngClass]="{ 'active': currentDay === day.key }" class="btn btn-outline-primary btn-sm">
              {{ day.val }}
          </button>
        </div>
        <div class="show-table">
        </div>
      </div>
      <div class="table-responsive">
          <table class="table">
              <thead class="bordered">
                  <tr class="up">
                      <th rowspan="2">Class</th>
                      <th [colSpan]="numOfDayCol">{{ currentDayValue }}</th>
                  </tr>
                  <tr class="below">
                      <th *ngFor="let period of listDayPeriod">{{ period.period }}</th>
                      
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let key of listJadwalPelajaran">
                      <td *ngFor="let key2 of key; let indexOfelement=index;" [ngClass]="{ 'bg-dark': key2.pelajaran === 'x' }">
                        <div *ngIf="indexOfelement === 0" (click)="showDetailJadwalPelajaranKelas()" class="show-pointer">
                          {{ key[indexOfelement] }}

                        </div>
                        <div *ngIf="indexOfelement !== 0 && key2.pelajaran === 'x'">
                          {{ key2.pelajaran }}
                        </div>
                        <div *ngIf="indexOfelement !== 0 && key2.pelajaran !== 'x'">
                          {{ key2.pelajaran }}<br /> {{ key2.guru }}
                        </div>
                      </td>                      
                  </tr>
                  
              </tbody>
          </table>

      </div>
    </div>
    
    
  </div>
</div>