import { Component, OnInit } from '@angular/core';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/helper/common.service';
import { ExcelService } from 'src/app/services/excel.service';
import { KelasService } from 'src/app/services/kelas.service';
import { SiswakelasService } from 'src/app/services/siswakelas.service';
import { NilaiHarianService } from 'src/app/services/nilai-harian.service';

@Component({
  selector: 'app-laporan-penginput',
  templateUrl: './laporan-penginput.component.html',
  styleUrls: ['./laporan-penginput.component.css']
})
export class LaporanPenginputComponent implements OnInit {

  listSemester: any = [];
  listTahunajaran: any = [];
  listNilaiAlquran: any = [];
  listDataLaporanNilai: any = [];
  listTotalSiswaKelas: any = [];
  listKelas: any = [];
  currentPeriodeId: any = null;
  dataToUpload: any = null;
  pageid = "upload-jadwal-mengajar";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  errorMessage = "";
  currentDate = "";
  submitted = false;
  listMonth: any = [];
  currentKelasId: any = null;
  currentSemester = "";
  listTugasKe: any = [];
  
  listDataLaporan = [
    "Nilai Al-Quran","Insya Yaumi","Daily Composition"
  ]

  formLaporanPenginput: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),    
    id_semester: new FormControl(''),
    tugas_ke_awal: new FormControl(''),
    tugas_ke_akhir: new FormControl(''),
    bulan: new FormControl(''),
    data_laporan: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private tahunajaranService: TahunajaranService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private kelasService: KelasService,
    private excelService: ExcelService,
    private siswakelasService: SiswakelasService,
    private nilaiharianService: NilaiHarianService,
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.listTugasKe = this.commonService.getListTugasKe();    
    this.getKelas();
    this.listMonth = this.commonService.getListMonthTahunAjaran();    
    this.getCurrentTahunajaran();
    this.listSemester = this.commonService.getListSemester();
    this.initForm();

    const currentMonth = this.commonService.getCurrentMonthName();
    
    this.formLaporanPenginput.controls['bulan'].setValue(currentMonth);
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {
    this.formLaporanPenginput = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        id_semester: ['', Validators.required],
        tugas_ke_awal: ['', Validators.required],
        tugas_ke_akhir: ['', Validators.required],
        bulan: ['', Validators.required],
        data_laporan: ['', Validators.required],
      },
    );
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formLaporanPenginput.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;
        
        for (let i=0; i<resdata.Semester.length; i++) {
          const periodeSemester = this.commonService.getCurrentDateBetweenDateRange(resdata.Semester[i].start_date, resdata.Semester[i].end_date);

          if (periodeSemester) {
            this.currentSemester = resdata.Semester[i].semester;
            this.formLaporanPenginput.controls['id_semester'].setValue(this.currentSemester);
            
            break;    
          }
        }

        this.uiService.hideLoader();

        this.getAllTahunajaran();
        this.getTotalSiswaByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }


  onSubmitformLaporanPenginput() {
    this.submitted = true;
    console.log(JSON.stringify(this.formLaporanPenginput.value, null, 2));

    if (this.formLaporanPenginput.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    const dataLaporan = this.formLaporanPenginput.controls['data_laporan'].value;
    
    switch (dataLaporan) {
      case "Nilai Al-Quran":
        const payload = {
          periode_id: this.currentPeriodeId,
          bulan: this.formLaporanPenginput.controls['bulan'].value
        }
        this.uiService.showLoader();

        this.nilaiharianService.getListLaporanNilaiQuranSiswa(payload).subscribe({
          next: resdata => {
            console.log('list siswa kelas', resdata);
            console.log('list total siswa kelas', this.listTotalSiswaKelas);

            // MIX kelas , walikelas, input, status
            let dataMix = [];
            let simpenKelas = [];
            let simpenTotalSiswaKelas = [];

            for (let i=0; i<this.listTotalSiswaKelas.length; i++) {
              simpenKelas[this.listTotalSiswaKelas[i].kelas_id] = i;
              simpenTotalSiswaKelas[this.listTotalSiswaKelas[i].kelas_id] = this.listTotalSiswaKelas[i].total_siswa;
              const row = {
                "NO": i+1,
                "KELAS": this.listTotalSiswaKelas[i].nama_kelas,
                "WALIKELAS": this.listTotalSiswaKelas[i].real_name,
                "INPUT": "0/"+ this.listTotalSiswaKelas[i].total_siswa + " siswa",
                "STATUS": "NULL"
              }
              
              dataMix.push(row);
            }

            // get walikelas , input and status
            // full isi
            const fullIsi = resdata.fullFill;
            const halfIsi = resdata.nullFill;

            for (let k=0; k<fullIsi.length; k++) {              
              // dataMix[simpenKelas[fullIsi[k].id_kelas]].WALIKELAS = fullIsi[k].real_name;
              dataMix[simpenKelas[fullIsi[k].id_kelas]].INPUT = fullIsi[k].count + "/" + simpenTotalSiswaKelas[fullIsi[k].id_kelas] + " siswa";
              dataMix[simpenKelas[fullIsi[k].id_kelas]].STATUS = fullIsi[k].count === simpenTotalSiswaKelas[fullIsi[k].id_kelas] ? "FULL" : "HALF"; 
            }

            for (let s=0; s<halfIsi.length; s++) {              
              // dataMix[simpenKelas[halfIsi[s].id_kelas]].WALIKELAS = halfIsi[s].real_name;
              dataMix[simpenKelas[halfIsi[s].id_kelas]].INPUT = halfIsi[s].count + "/" + simpenTotalSiswaKelas[halfIsi[s].id_kelas] + " siswa";
              dataMix[simpenKelas[halfIsi[s].id_kelas]].STATUS = "HALF"; 
            }
    
            this.listDataLaporanNilai = dataMix;

            console.log("MIX DATA", this.listDataLaporanNilai);
            
    
            this.uiService.hideLoader();
    
          },
          error: err => {
            this.errorMessage = err.error.message;
            console.log(this.errorMessage);
    
            this.uiService.hideLoader();
    
            this.uiService.showError(this.errorMessage);
          }
        });

        break;
      case "Insya Yaumi":
        const tugas_ke_awal = this.formLaporanPenginput.controls['tugas_ke_awal'].value;
        const tugas_ke_akhir = this.formLaporanPenginput.controls['tugas_ke_akhir'].value;
        let rangeTugas = [];
        rangeTugas.push(tugas_ke_awal);
        if (tugas_ke_awal !== tugas_ke_akhir) {
          rangeTugas.push(tugas_ke_akhir);
        }

        const payloadInsya = {
          periode_id: this.currentPeriodeId,
          semester: this.formLaporanPenginput.controls['id_semester'].value,
          tugas: rangeTugas
        }
        this.uiService.showLoader();

        this.nilaiharianService.getListLaporanNilaiInysaYaumiSiswa(payloadInsya).subscribe({
          next: resdata => {
            console.log('list siswa kelas', resdata);
            console.log('list total siswa kelas', this.listTotalSiswaKelas);

            // MIX kelas , walikelas, input, status
            let dataMix = [];
            let simpenKelas = [];
            let simpenTotalSiswaKelas = [];

            for (let i=0; i<this.listTotalSiswaKelas.length; i++) {
              simpenKelas[this.listTotalSiswaKelas[i].kelas_id] = i;
              simpenTotalSiswaKelas[this.listTotalSiswaKelas[i].kelas_id] = this.listTotalSiswaKelas[i].total_siswa;
              const row = {
                "NO": i+1,
                "KELAS": this.listTotalSiswaKelas[i].nama_kelas,
                "WALIKELAS": this.listTotalSiswaKelas[i].real_name,
                "INPUT": "0/"+ this.listTotalSiswaKelas[i].total_siswa + " siswa",
                "STATUS": "NULL"
              }
              
              dataMix.push(row);
            }

            // get walikelas , input and status
            // full isi
            const fullIsi = resdata.fullFill;
            const halfIsi = resdata.nullFill;

            console.log("FULFILL HARUSNYA 5", fullIsi);
            
            const numOfTugas = rangeTugas.length;

            for (let k=0; k<fullIsi.length; k++) {              
              const totalSiswa = simpenTotalSiswaKelas[fullIsi[k].id_kelas] * numOfTugas;
              console.log("idkelas", k, numOfTugas, fullIsi[k].id_kelas, fullIsi[k].count, totalSiswa);
              dataMix[simpenKelas[fullIsi[k].id_kelas]].INPUT = fullIsi[k].count + "/" + totalSiswa  + " siswa";
              dataMix[simpenKelas[fullIsi[k].id_kelas]].STATUS = fullIsi[k].count.toString() === totalSiswa.toString() ? "FULL" : "HALF"; 
            }

            // for (let s=0; s<halfIsi.length; s++) {              
            //   // dataMix[simpenKelas[halfIsi[s].id_kelas]].WALIKELAS = halfIsi[s].real_name;
            //   dataMix[simpenKelas[halfIsi[s].id_kelas]].INPUT = halfIsi[s].count + "/" + simpenTotalSiswaKelas[halfIsi[s].id_kelas] + " siswa";
            //   dataMix[simpenKelas[halfIsi[s].id_kelas]].STATUS = "HALF"; 
            // }
    
            this.listDataLaporanNilai = dataMix;

            console.log("MIX DATA", this.listDataLaporanNilai);
            
    
            this.uiService.hideLoader();
    
          },
          error: err => {
            this.errorMessage = err.error.message;
            console.log(this.errorMessage);
    
            this.uiService.hideLoader();
    
            this.uiService.showError(this.errorMessage);
          }
        });

        break;

      case "Daily Composition":
        const tugas_ke_awal_dc = this.formLaporanPenginput.controls['tugas_ke_awal'].value;
        const tugas_ke_akhir_dc = this.formLaporanPenginput.controls['tugas_ke_akhir'].value;
        let rangeTugasDc = [];
        rangeTugasDc.push(tugas_ke_awal_dc);
        if (tugas_ke_awal_dc !== tugas_ke_akhir_dc) {
          rangeTugasDc.push(tugas_ke_akhir_dc);
        }

        const payloadDc = {
          periode_id: this.currentPeriodeId,
          semester: this.formLaporanPenginput.controls['id_semester'].value,
          tugas: rangeTugasDc
        }
        this.uiService.showLoader();

        this.nilaiharianService.getListLaporanNilaiDailyCompositionSiswa(payloadDc).subscribe({
          next: resdata => {
            console.log('list siswa kelas', resdata);
            console.log('list total siswa kelas', this.listTotalSiswaKelas);

            // MIX kelas , walikelas, input, status
            let dataMix = [];
            let simpenKelas = [];
            let simpenTotalSiswaKelas = [];

            for (let i=0; i<this.listTotalSiswaKelas.length; i++) {
              simpenKelas[this.listTotalSiswaKelas[i].kelas_id] = i;
              simpenTotalSiswaKelas[this.listTotalSiswaKelas[i].kelas_id] = this.listTotalSiswaKelas[i].total_siswa;
              const row = {
                "NO": i+1,
                "KELAS": this.listTotalSiswaKelas[i].nama_kelas,
                "WALIKELAS": this.listTotalSiswaKelas[i].real_name,
                "INPUT": "0/"+ this.listTotalSiswaKelas[i].total_siswa + " siswa",
                "STATUS": "NULL"
              }
              
              dataMix.push(row);
            }

            // get walikelas , input and status
            // full isi
            const fullIsi = resdata.fullFill;

            console.log("FULFILL HARUSNYA 5", fullIsi);
            
            const numOfTugas = rangeTugasDc.length;

            for (let k=0; k<fullIsi.length; k++) {              
              const totalSiswa = simpenTotalSiswaKelas[fullIsi[k].id_kelas] * numOfTugas;
              console.log("idkelas", k, numOfTugas, fullIsi[k].id_kelas, fullIsi[k].count, totalSiswa);
              dataMix[simpenKelas[fullIsi[k].id_kelas]].INPUT = fullIsi[k].count + "/" + totalSiswa  + " siswa";
              dataMix[simpenKelas[fullIsi[k].id_kelas]].STATUS = fullIsi[k].count.toString() === totalSiswa.toString() ? "FULL" : "HALF"; 
            }

            this.listDataLaporanNilai = dataMix;

            console.log("MIX DATA", this.listDataLaporanNilai);
            
    
            this.uiService.hideLoader();
    
          },
          error: err => {
            this.errorMessage = err.error.message;
            console.log(this.errorMessage);
    
            this.uiService.hideLoader();
    
            this.uiService.showError(this.errorMessage);
          }
        });

        break;

      default:
        break;
    }
    
  }

  getTotalSiswaByPeriode() {
    this.siswakelasService.getTotalSiswakelasByPeriode(this.currentPeriodeId).subscribe({
      next: resdata => {
        console.log('list siswa kelas', resdata);

        this.listTotalSiswaKelas = resdata;

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formLaporanPenginput.controls;
  }

  changeKelas(e: any) {
    this.currentKelasId = e.target.value;
  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onDataLaporanChange(e: any) {
    const dataLaporan = e.target.value;

    if (dataLaporan === "Nilai Al-Quran") {
      this.setValidateNilaiQuran();
    } else {
      this.setValidatorInsya();
    }
  }

  setValidateNilaiQuran() {
    
    this.formLaporanPenginput.controls['id_semester'].clearValidators();
    this.formLaporanPenginput.controls['id_semester'].updateValueAndValidity();
    this.formLaporanPenginput.controls['tugas_ke_awal'].clearValidators();
    this.formLaporanPenginput.controls['tugas_ke_awal'].updateValueAndValidity();
    this.formLaporanPenginput.controls['tugas_ke_akhir'].clearValidators();
    this.formLaporanPenginput.controls['tugas_ke_akhir'].updateValueAndValidity();
    this.formLaporanPenginput.controls['bulan'].setValidators([Validators.required]);
    this.formLaporanPenginput.controls['bulan'].updateValueAndValidity();
  }

  setValidatorInsya() {
    this.formLaporanPenginput.controls['id_semester'].setValidators([Validators.required]);
    this.formLaporanPenginput.controls['id_semester'].updateValueAndValidity();
    this.formLaporanPenginput.controls['tugas_ke_awal'].setValidators([Validators.required]);
    this.formLaporanPenginput.controls['tugas_ke_awal'].updateValueAndValidity();
    this.formLaporanPenginput.controls['tugas_ke_akhir'].setValidators([Validators.required]);
    this.formLaporanPenginput.controls['tugas_ke_akhir'].updateValueAndValidity();
    this.formLaporanPenginput.controls['bulan'].clearValidators();
    this.formLaporanPenginput.controls['bulan'].updateValueAndValidity();
  }

  changeSemester(e: any) {
    this.currentSemester = e.target.value;
  }

}
