
<div class="tab-content" id="myTabContent">
  <div class="back-button" (click)="onBack()">
    <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
  </div>
    
  <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
      <div class="tabs-title">
        Assign Permission (Teacher)
      </div>

      <div class="list-options">
          
          
      </div>

      <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
        <div class="table-wrapper">
            
            <div class="table-responsive">
                <table class="table table-left table-borderless table-hover">
                    <thead>
                        <tr>
                            <th>Module</th>                          
                            <th>Feature</th>
                            <th></th>                            
                            
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        *ngFor="
                            let permission of listpermissions;
                            let i = index
                        "
                        >
                            
                            <td>{{ permission.module }}</td>
                            <td>{{ permission.permission_name }}</td>
                            <td><input type="checkbox" [value]="permission.id" [checked]="permission.RolePermission.length > 0" (change)="onCheckboxChange($event)" /></td>
                            
                            
                        </tr>
                        
                    </tbody>
                </table>
            </div>
            <div class="row align-items-center">
              <div class="col-6 col-lg-4 offset-lg-6">
                  
              </div>
              <div class="col-6 col-lg-2 d-flex justify-content-end">
                  <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </div>
            <div class="row">
              &nbsp;
            </div>
        </div>
      </form>

      
  </div>
  
</div>
