<div class="table-wrapper">
    <div class="table-header">
        <div>
            Penempatan Kelas Berdasarkan Tahun Ajaran
        </div>
        <div>
            <select class="form-select" name="" id="">
                <option>2020/2021</option>
                <option>2019/2020</option>
                <option>2018/2019</option>
            </select>
        </div>
    </div>
    <div class="table-options">
        <div class="show-table">
            Show<input type="number" class="form-control" name="show" id="show" value="10">entries
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th>Lembaga</th>
                    <th>Tingkatan</th>
                    <th>Aktif</th>
                    <th>Isysrof Tawwafuq</th>
                    <th>Isysrof Suluk</th>
                    <th>Tahfidz Al-Quran</th>
                    <th>Bimbel</th>
                    <th>Mutasi Keluar</th>
                    <th>Tahwil</th>
                    <th>Dikeluarkan</th>
                    <th>Alumni</th>
                    <th>Unclear Status</th>
                    <th>Jumlah</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>MTs</td>
                    <td>2-TMI</td>
                    <td>482</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>12</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>MTs</td>
                    <td>1-TMI</td>
                    <td>401</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>7</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>MTs</td>
                    <td>3-TMI</td>
                    <td>445</td>
                    <td>0</td>
                    <td>5</td>
                    <td>0</td>
                    <td>18</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr class="summary">
                    <td colspan="2">Jumlah</td>
                    <td>2324</td>
                    <td>0</td>
                    <td>7</td>
                    <td>0</td>
                    <td>0</td>
                    <td>106</td>
                    <td>5</td>
                    <td>0</td>
                    <td>0</td>
                    <td>94</td>
                    <td>2536</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="table-wrapper">
    <div class="table-header">
        <div>
            Jumlah Siswa Berdasarkan Tahun Ajaran Masuk
        </div>
    </div>
    <div class="table-options">
        <div class="show-table">
            Show<input type="number" class="form-control" name="show" id="show" value="10">entries
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th>Tahun Ajaran</th>
                    <th>PPSB</th>
                    <th>Belum Bayar DU</th>
                    <th>Aktif</th>
                    <th>Isysrof Tawwafuq</th>
                    <th>Isysrof Suluk</th>
                    <th>Tahfidz Al-Quran</th>
                    <th>Bimbel</th>
                    <th>Mutasi Keluar</th>
                    <th>Tahwil</th>
                    <th>Dikeluarkan</th>
                    <th>Alumni</th>
                    <th>Jumlah</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>2022-2023</td>
                    <td>341</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>2021-2022</td>
                    <td>725</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>22</td>
                    <td>0</td>
                    <td>0</td>
                    <td>22</td>
                </tr>
                <tr>
                    <td>2020-2021</td>
                    <td>1175</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>52</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>52</td>
                </tr>
                <tr>
                    <td>2019-2020</td>
                    <td>806</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>5</td>
                    <td>0</td>
                    <td>0</td>
                    <td>120</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>120</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="pagination-wrapper">
        <div></div>
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center pagination-number">
                <li class="page-item active"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">...</a></li>
                <li class="page-item"><a class="page-link" href="#">6</a></li>
            </ul>
        </nav>
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <li class="page-item disabled">
                    <a class="page-link" href="#" aria-label="Previous">
                        <span>Previous</span>
                    </a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                        <span>Next</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</div>

<div class="table-wrapper">
    <div class="table-responsive">
        <table class="table table-borderless">
            <thead class="bordered">
                <tr>
                    <th>Tahun Ajaran</th>
                    <th>Tanggal</th>
                    <th>Keterangan</th>
                    <th>Kamar</th>
                    <th>Absen</th>
                </tr>
            </thead>
            <tbody>
                <tr class="empty">
                    <td colspan="5">Tidak terdapat absen kamar untuk siswa ini.</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="pendaftaran">
    <div class="row">
        <div class="col-12 col-lg-2">
            <div class="img-wrapper">
                <img src="./assets/images/empty-image.png" class="w-100" alt="">
            </div>
        </div>
        <div class="col-12 col-lg-5">
            <div class="card">
                <div class="card-header">
                    Informasi Pendaftaran
                </div>
                <div class="card-body">
                    <div class="card-text">
                        <div class="row">
                            <div class="col-5 fw-bold">
                                <p>Tanggal Daftar</p>
                                <p>Nomor Registrasi</p>
                                <p>No. Dokumen</p>
                                <p>Nomor Induk</p>
                                <p>Admin Input</p>
                                <p>Tanggal Terakhir Update</p>
                            </div>
                            <div class="col-7 grey-text">
                                <p>2022-01-08  23:42:03</p>
                                <p>0000254368</p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p>0000-00-00</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-5">
            <div class="card">
                <div class="card-header">
                    Biodata Asal Sekolah
                </div>
                <div class="card-body">
                    <div class="card-text">
                        <div class="row">
                            <div class="col-5 fw-bold">
                                <p>Nama Asal Sekolah</p>
                                <p>Asal Sekolah</p>
                                <p>Alamat Sekolah</p>
                                <p>Tempat Ijazah</p>
                                <p>No. Ijazah</p>
                                <p>Tahun Lulus</p>
                                <p>NISN</p>
                                <p>NIPSN</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="history">
    <div class="card">
        <div class="card-header">
            Histori Transaksi Keuangan
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-lg-1 history-desc">
                    <div class="history-date">
                        20-11-2020
                    </div>
                    <div class="history-time">
                        19:07:01
                    </div>
                </div>
                <div class="col-12 col-lg-11 history-table">
                    <div class="table-wrapper">
                        <div class="table-responsive">
                            <table class="table table-borderless">
                                <thead>
                                    <tr class="up">
                                        <th colspan="2">
                                            VATRF 88548223335861201 8D OLIVIA JASMINE  |  (111.01) VASYS
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Keterangan</th>
                                        <th>Nominal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-start">IURAN BULANAN 2021-2022 DEC-2021 OLIVIA JASMINE</td>
                                        <td class="text-end">1,475,000</td>
                                    </tr>
                                    <tr>
                                        <td class="text-start">POTONGAN DANA MAKAN SEMESTER 1 2021-2022 DEC-2021 OLIVIA JASMINE</td>
                                        <td class="text-end">-80,000</td>
                                    </tr>
                                    <tr>
                                        <td class="text-start">TOTAL</td>
                                        <td class="text-end">1,385,000</td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-1 history-desc">
                    <div class="history-date">
                        20-11-2020
                    </div>
                    <div class="history-time">
                        19:07:01
                    </div>
                </div>
                <div class="col-12 col-lg-11 history-table">
                    <div class="table-wrapper">
                        <div class="table-responsive">
                            <table class="table table-borderless">
                                <thead>
                                    <tr class="up">
                                        <th colspan="2">
                                            VATRF 88548223335861201 8D OLIVIA JASMINE  |  (111.01) VASYS
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Keterangan</th>
                                        <th>Nominal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-start">IURAN BULANAN 2021-2022 DEC-2021 OLIVIA JASMINE</td>
                                        <td class="text-end">1,475,000</td>
                                    </tr>
                                    <tr>
                                        <td class="text-start">POTONGAN DANA MAKAN SEMESTER 1 2021-2022 DEC-2021 OLIVIA JASMINE</td>
                                        <td class="text-end">-80,000</td>
                                    </tr>
                                    <tr>
                                        <td class="text-start">TOTAL</td>
                                        <td class="text-end">1,385,000</td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
