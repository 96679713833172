import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class IzinMengajarService {

  constructor(private netService: NetworkService) { }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/izinmengajar');
  }

  getGuruPengganti(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/izinmengajargetgurupengganti');
  }

  update(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/izinmengajar/${id}`);
  }

  updateApproval(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/izinmengajarapproval/${id}`);
  }

  updateGuruPengganti(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/izinmengajargurupengganti/${id}`);
  }

  delete(id: any): Observable<any> {
    return this.netService.deleteRequest(`/v1/izinmengajar/${id}`);
  }

  getById(id: any): Observable<any> {
    return this.netService.getRequest(`/v1/proguser/${id}`);
  }

  getByPeriodeAndKelas(periode_id: any, kelas_id: any): Observable<any> {
    return this.netService.getRequest(`/v1/izinmengajar/getByPeriodeAndKelas/${periode_id}/${kelas_id}`);
  }

  getAll(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/izinmengajar', params);
  }
}
