import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showSiswaBerkas'
})
export class ShowSiswaBerkasPipe implements PipeTransform {

  transform(value: number, berkas: any): string {
    
    const isFound = berkas.some((element: any) => {
      if (element.berkas_id === value) {
        console.log("BERKAS XXX",value, element);
        return true;
      }

    
      return false;
    });

    return isFound ? '1' : '0';
  }

}
