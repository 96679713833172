<div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="guru-input"
      role="tabpanel"
      aria-labelledby="guru-input-tab"
    >
      <div class="tabs-title">Generate Tagihan</div>
  
      <div class="row">
        <div class="col-12 col-lg-6">
              <div class="card">
                <div class="card-header">
                    Sumber Data Siswa
                </div>
                <div class="card-body">
                    <form [formGroup]="formSumberDataSiswa">
                        <div class="row">
                            <div class="col-12 col-lg-12">
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="nik" class="form-label">Tahun Ajaran <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <select formControlName="id_tahun_ajaran" 
                                                (change)="changeTahunAjaran($event)"
                                                [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }" class="form-select">
                                                <option [value]="" selected disabled>Pilih TA</option>
                                                <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="name" class="form-label">Tipe <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-8">
                                        <select
                                            formControlName="tipe"
                                            [ngClass]="{ 'is-invalid': submitted && f['tipe'].errors }"
                                            class="form-select"
                                            (change)="changeTipe($event)">
                                            <option value="" selected>Pilih Tipe</option>
                                            <option [value]="data" *ngFor="let data of listTipe">{{data}}</option>
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!isShowPerorangan" class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="name" class="form-label">Lembaga <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <select
                                                formControlName="id_lembaga"
                                                [ngClass]="{ 'is-invalid': submitted && f['id_lembaga'].errors }"
                                                class="form-select"
                                                (change)="changeLembaga($event)">
                                                <option value="" selected>Semua</option>
                                                <option [value]="data.id" *ngFor="let data of listsLembaga">{{data.nama_lembaga}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>            
                                <div *ngIf="!isShowPerorangan" class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="name" class="form-label">Tingkatan <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <select
                                                formControlName="id_tingkat"
                                                [ngClass]="{ 'is-invalid': submitted && f['id_tingkat'].errors }"
                                                class="form-select"
                                                (change)="changeTingkat($event)">
                                                <option value="" selected>Semua</option>
                                                <option [value]="data.id" *ngFor="let data of listsTingkat">{{data.nama_tingkat}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> 
        
                                <div *ngIf="isShowPerorangan" class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="name" class="form-label">Nama</label>
                                        </div>
                                        <div class="col-8 autocomplete">
                                            <input formControlName="siswa_name" (keyup)="searchSiswa()" type="text" class="form-control" autocapitalize="off" autocorrect="off" autocomplete="off" id="inputNamaSiswa">
                        
                                            <div id="myInputautocomplete-list" class="autocomplete-items">
                                                <button *ngIf="isShowLoader" class="btn btn-primary" type="button" disabled>
                                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    Searching...
                                                </button>
                                                <div (click)="chooseSiswa(data)" *ngFor="let data of listSiswa">
                                                    <strong>{{ data.nama_lengkap }}</strong>
                                                    <!-- <p>No, Dokumen</p> -->
                                                    <p>No, Registrasi {{ data.regnumber }}</p>
                                                    <p>Tahun masuk {{ data.angkatan }} </p>
                                                </div>                        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isShowPerorangan" class="table-wrapper">
                                <div class="table-options">
                                    <div class="show-table">
                                    
                                    </div>
                                </div>
                                
                                <div class="table-responsive">
                                    <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                        <th>No.</th>
                                        <th>Nama</th>
                                        <th>Kelas</th>
                                        <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        <tr *ngFor="let siswa of listDataSiswaPerorangan; let i = index">
                                            <td>
                                                {{ i + 1 + (page - 1) * pageSize }}
                                            </td>
                                            <td>{{ siswa.nama_lengkap }}</td>
                                            <td>{{ siswa.no_induk }}</td>
                                            
                                            <td>
                                                <button (click)="removeListSiswaPerorangan(i, siswa.id)" type="button" class="btn-close btn-close-blue" aria-label="Close"></button>
                                            </td>
                                        
                                        </tr>
                                                    
                                        
                                        <tr *ngIf="listDataSiswaPerorangan.length === 0" class="empty">
                                            <td colspan="5">
                                            Tidak terdapat data atau data tidak ditemukan.
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                            
                                </div>                           
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card">
                <div class="card-header">
                    Sumber Data Tagihan
                </div>
                <div class="card-body">
                    <form [formGroup]="formSumberDataTagihan">
                        <div class="row">
                            <div class="col-12 col-lg-12">
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="nik" class="form-label">Tahun Ajaran <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <select formControlName="id_tahun_ajaran_tagihan" 
                                                (change)="changeTahunAjaranTagihan($event)"
                                                [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran_tagihan'].errors }" class="form-select">
                                                <option [value]="" selected disabled>Pilih TA</option>
                                                <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="name" class="form-label">Lembaga <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-8">
                                          <select
                                              formControlName="id_lembaga_tagihan"
                                              [ngClass]="{ 'is-invalid': submitted && f['id_lembaga_tagihan'].errors }"
                                              class="form-select"
                                              (change)="changeLembagaBiaya($event)">
                                              <option value="0" selected>Semua</option>
                                              <option [value]="data.id" *ngFor="let data of listsLembaga">{{data.nama_lembaga}}</option>
                                          </select>
                                        </div>
                                    </div>
                                </div>            
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="name" class="form-label">Paket Pembayaran <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-8">
                                          <select
                                              formControlName="id_paket_biaya"
                                              [ngClass]="{ 'is-invalid': submitted && f['id_paket_biaya'].errors }"
                                              class="form-select"
                                              (change)="changePaketPembayaran($event)">
                                              <option value="">PILIH</option>
                                                <option [value]="datapaket.id" *ngFor="let datapaket of listPaketPembayaran">{{datapaket.nama_paket}}</option>
                                          </select>
                                        </div>
                                    </div>
                                </div> 
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="name" class="form-label">Group Biaya <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <div class="checkbox" *ngFor="let databiayapaket of listBiayaPaketPembayaran">
                                                <label class="ui-checks ui-checks-md ng-binding">
                                                  <input 
                                                    type="radio" 
                                                    formControlName="selectedGroupBiaya" 
                                                    (change)="onChangeGroupBiaya($event)"
                                                    [value]="databiayapaket.group_biaya_id" />
                                                  <!-- <input type="checkbox" formArrayName="selectedGroupBiaya" [value]="databiayapaket.group_biaya_id" (change)="onCheckboxChange($event)" /> -->
                                                  {{ databiayapaket.name }} ({{ databiayapaket.total_group_biaya }})
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                                                     
                        </div>
                    </form>
                </div>
              </div>
        </div>
      </div>
        
      <div class="mt-4 list-options">
          <button (click)="onClickPreview()" type="button" class="btn btn-warning mt-2">
              Preview
          </button>
          <button *ngIf="listTagihanBulan.length > 0" (click)="onSubmitformInputTagihanBulanan()" class="btn btn-blue-grey mt-2" style="display:inline;">
              + Generate Tagihan Baru
          </button>
          <button *ngIf="listTagihanBulan.length > 0" (click)="onClickDeleteBulanTagihan()" type="button" class="btn btn-danger mt-2">
              <img src="./assets/images/dropdown/icon-delete.png" alt="Delete"> DELETE Tagihan
          </button>              
      </div>

      <form [formGroup]="formInputTagihanBulanan" (ngSubmit)="onSubmitformInputTagihanBulanan()">
        
        
        <div class="table-wrapper">
          <div class="table-options">
            <div class="show-table">
              
            </div>
          </div>
          
          <div class="table-responsive custom-scroll">
            <table class="table table-borderless">
              <thead>
                <tr class="up">
                  <th rowspan="3">No.</th>
                  <th rowspan="3">
                      <div style="width: 250px;">Nama</div>
                  </th>                
                  <th rowspan="3">Kelas</th>
                  <th colspan="12">Bulan Tagihan</th>
                </tr>
                <tr class="below" formArrayName="selectedBulanTagihan">
                    <th *ngFor="let data of selectedBulanTagihan.controls; let x = index">
                        <input type="checkbox" [value]="listBulanEfektif[x].blnNumber+'-'+listBulanEfektif[x].tahun" [formControlName]="x" (change)="onCheckboxChange($event)" /> 
                        {{ listBulanEfektif[x].bln }} {{ listBulanEfektif[x].tahun }} 
                    </th>                    
                    
                </tr>
                <tr class="below" formArrayName="selectedBulanTertagih">
                    <th *ngFor="let dataBlE of listBulanEfektif; let z = index">
                        <div style="width: 100px; height: fit-content;">
                            <select class="form-select-sm" (change)="onSelectBulanChange($event)" [formControlName]="z">
                                <option value="" selected>BULAN TAGIH</option>
                                <option [value]="dataBlE.blnNumber+'-'+dataBlE.tahun+'=='+databl.blnNumber+'-'+databl.tahun" *ngFor="let databl of listBulanEfektif">{{databl.bln}} {{ databl.tahun }}</option>
                            </select>
                        </div>                        
                    </th>                    
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let key of listTagihanBulan; let i = index">
                    <td>
                      {{ i + 1 + (page - 1) * pageSize }}
                    </td>
                    <td *ngFor="let key2 of key; let indexOfelement=index;">{{ key2.val }}</td>
                    
                  </tr>
                  
                  <tr *ngIf="listTagihanBulan.length === 0" class="empty">
                    <td colspan="15">
                      Tidak terdapat data atau data tidak ditemukan.
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
    
        </div>
      </form>
    </div>
  </div>