import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

type AOA = any[][];


@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  dataExcel: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    
    /* save to file */
    XLSX.writeFile(workbook, excelFileName);
  }


  public convertExcelFileToJson(fTarget: any) : FileReader {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(fTarget);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.dataExcel = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      // console.log(this.dataExcel);
    };
    reader.readAsBinaryString(target.files[0]);

    return reader;
  }

  // Another way to export excel file
  public downloadExcelFile() {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataExcel);
    console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    
    /* save to file */
    XLSX.writeFile(workbook, this.fileName);

    
  }
}
