<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="guru-input"
    role="tabpanel"
    aria-labelledby="guru-input-tab"
  >
    <div class="tabs-title">Laporan Penginput</div>

    
    <div class="col-12 col-lg-12">
      <div class="card">
          
          <div class="card-body">
              <form [formGroup]="formLaporanPenginput" (ngSubmit)="onSubmitformLaporanPenginput()">
                  <div class="row">
                      <div class="col-12 col-lg-12">
                          <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="nik" class="form-label">Tahun Ajaran <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-10">                            
                                    <select formControlName="id_tahun_ajaran" [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }" class="form-select">
                                      <option [value]="" selected disabled>Pilih TA</option>
                                      <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                                    </select>
                                  </div>
                              </div>
                          </div>
                          
                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">Data Laporan <span
                                            class="required">*</span></label>
                                </div>
                                <div class="col-10">
                                  <select
                                    (change)="onDataLaporanChange($event)" 
                                    formControlName="data_laporan" [ngClass]="{ 'is-invalid': submitted && f['id_semester'].errors }" class="form-select">
                                    <option value="" selected>Pilih</option>
                                    <option [value]="data" *ngFor="let data of listDataLaporan">{{ data }}</option>
                                  </select>
                                </div>
                            </div>
                          </div>
                          
                          <div class="mb-3" *ngIf="f['data_laporan'].value !== '' && f['data_laporan'].value !== 'Nilai Al-Quran'">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">Semester <span
                                            class="required">*</span></label>
                                </div>
                                <div class="col-10">
                                  <select formControlName="id_semester" (change)="changeSemester($event)" class="form-select" name="id_tahun_ajaran" id="id_tahun_ajaran">
                                    <option [value]="0">Pilih Semester</option>
                                    <option [value]="data.kode" *ngFor="let data of listSemester">{{data.name}}</option>
                                  </select>
                                </div>
                            </div>
                          </div>
                          
                          <div class="mb-3" [ngClass]="{ 'd-none': f['data_laporan'].value === '' || f['data_laporan'].value === 'Nilai Al-Quran' }">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">Tugas Ke <span
                                            class="required">*</span></label>
                                </div>
                                <div class="col-10">
                                  <select formControlName="tugas_ke_awal" (change)="changeKelas($event)" class="form-select w-sm inline" name="id_tahun_ajaran" id="id_tahun_ajaran">
                                    <option value="" selected>Pilih Tugas</option>
                                    <option [value]="tugas" *ngFor="let tugas of listTugasKe">{{ tugas }}</option>
                                  </select>
                                  <span class="inline">&nbsp;s.d.&nbsp;</span>
                                  <select formControlName="tugas_ke_akhir" (change)="changeKelas($event)" class="form-select w-sm inline" name="id_tahun_ajaran" id="id_tahun_ajaran">
                                    <option value="" selected>Pilih Tugas</option>
                                    <option [value]="tugas" *ngFor="let tugas of listTugasKe">{{ tugas }}</option>
                                  </select>
                                </div>
                            </div>
                          </div>

                          <div class="mb-3" [ngClass]="{ 'd-none': f['data_laporan'].value !== 'Nilai Al-Quran' }">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="name" class="form-label">Bulan <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-10">
                                    <select formControlName="bulan" [ngClass]="{ 'is-invalid': submitted && f['id_semester'].errors }" class="form-select">
                                      <option value="" selected>Pilih Bulan</option>
                                      <option [value]="month" *ngFor="let month of listMonth">{{ month }}</option>
                                    </select>
                                  </div>
                              </div>
                          </div>
                          
                          
                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">&nbsp;</label>
                                </div>
                                <div class="col-10">
                                  <button class="btn btn-blue-grey mt-2 inline">
                                    <img src="./assets/images/icon-preview.png" alt="Preview"> Tampil
                                  </button>
                                  <span class="inline"> &nbsp; &nbsp;</span>
                                  <button type="button" class="btn btn-success mt-2">
                                    <img src="./assets/images/icon-excel.png" alt="Excel"> Excel
                                  </button>
                                </div>
                            </div>
                          </div>                          
                      </div>                  
                  </div>
              </form>
          </div>
      </div>
    </div>

    <div class="row mt-4">
      &nbsp;
    </div>
    <div class="table-wrapper mt-4">
      <div class="table-options">
        <div class="show-table">
          <div>
            <label>Ket Warna : </label> &nbsp;
            <button type="button" class="btn btn-danger">Belum Input Nilai Siswa</button> &nbsp;
            <button type="button" class="btn btn-warning">Sebagian Nilai Siswa Telah Diinput</button> &nbsp;
            <button type="button" class="btn btn-primary">Semua Nilai Siswa Telah Diinput</button> &nbsp;
            <button type="button" class="btn btn-default" style="background:white">Input nilai siswa melibihi jumlah siswa</button>
          </div>
        </div>
      </div>
      <div class="table-responsive history-table">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th></th>
              <th>Kelas</th>
              <th>Wali Kelas</th>              
              <th>Input</th>              
            </tr>
          </thead>
          <tbody>
            <tr 
              [ngClass]="{ 'tr-red': siswa.STATUS === 'NULL', 'tr-yellow': siswa.STATUS === 'HALF', 'tr-blue': siswa.STATUS === 'FULL' }"              
              *ngFor="
                let siswa of listDataLaporanNilai
                  | paginate
                    : {
                        id: pageid,
                        itemsPerPage: pageSize,
                        currentPage: page,
                        totalItems: count
                      };
                let i = index
              "
            >
              <td>{{ i + 1 + (page - 1) * pageSize }}</td>
              <td>{{ siswa.KELAS }}</td>
              <td>{{ siswa.WALIKELAS }}</td>
              <td>{{ siswa.INPUT }}</td>
            </tr>
            <tr *ngIf="listDataLaporanNilai.length === 0" class="empty">
              <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
        <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
          <div></div>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center pagination-number">
              <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                <a
                  class="page-link"
                  (click)="p.setCurrent(page.value)"
                  *ngIf="p.getCurrent() !== page.value"
                >
                  {{ page.label }}
                </a>
                <a class="page-link" *ngIf="p.getCurrent() === page.value">
                  {{ page.label }}
                </a>
              </li>
            </ul>
          </nav>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="p.isFirstPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isFirstPage()"
                  (click)="p.previous()"
                  aria-label="Previous"
                >
                  <span>Previous</span>
                </a>
              </li>
              <li class="page-item" [class.disabled]="p.isLastPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isLastPage()"
                  (click)="p.next()"
                  aria-label="Next"
                >
                  <span>Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </pagination-template>

      <ng-template #emptyPagination>
        <div class="pagination-wrapper">
          <div>&nbsp;</div>
        </div>
      </ng-template>
    </div>

  </div>
</div>