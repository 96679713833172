<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
      <div class="tabs-title">
          LIST SEMESTER TAHUN AJARAN {{ currentTahunAjaran }}
      </div>
      <div class="list-options">
          
          
          
          <!-- <a routerLink="/settings/tahunajaran/add" class="btn btn-blue-grey">
              + Tambah Semester
          </a> -->
          
      </div>
      <div class="table-wrapper">
          <!-- <div class="table-options">
              <div class="show-table">
                  
              </div>
          </div> -->
          <div class="table-responsive">
              <table class="table table-borderless">
                  <thead>
                      <tr>
                          <th>No.</th>                          
                          <th>Semester</th>
                          <th>Semester (Arab)</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Start Input Date</th>
                          <th>End Input Date</th>
                          <th>Active</th>
                          <th> </th>
                      </tr>
                  </thead>
                  <tbody>                        
                      <tr
                      *ngFor="
                          let tahun of listSemester | paginate : {
                                  id: pageid,
                                  itemsPerPage: pageSize,
                                  currentPage: page,
                                  totalItems: count
                              };
                          let i = index
                      "
                      >
                          <td>{{ i + 1 + ((page - 1) * pageSize) }}</td>
                          <td>{{ tahun.semester }}</td>
                          <td>{{ tahun.semester_name }}</td>
                          <td>{{ tahun.start_date | date:'dd-MM-yyyy'}}</td>
                          <td>{{ tahun.end_date | date:'dd-MM-yyyy'}}</td>
                          <td>{{ tahun.start_input_nilai_date | date:'dd-MM-yyyy'}}</td>
                          <td>{{ tahun.end_input_nilai_date | date:'dd-MM-yyyy'}}</td>
                          <td>{{ tahun.active }}</td>
                          <td class="settings">
                              <div class="dropend">
                                  <a href="#" role="button" id="dropdownMenuLink"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      <img src="./assets/images/icon-setting.svg" alt="">
                                  </a>
                                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                      <li><a (click)="openEditModalFormSemester(formSemesterModal, tahun)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>                                        
                                      <!-- <li><a (click)="confirmDelete(tahun.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li> -->
                                  </ul>
                              </div>
                          </td>
                      </tr>
                      <tr *ngIf="listSemester.length === 0" class="empty">
                          <td colspan="5">Tidak terdapat data atau data tidak ditemukan.</td>
                      </tr>
                      
                  </tbody>
              </table>
          </div>
          

          <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
              <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
                  <div></div>
                  <nav aria-label="Page navigation">
                      <ul class="pagination justify-content-center pagination-number">
                          <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                              <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ page.label }}</a>
                              
                              <a class="page-link" *ngIf="p.getCurrent() === page.value">{{ page.label }}</a>

                          </li>
                      </ul>
                  </nav>
                  <nav aria-label="Page navigation">
                      <ul class="pagination justify-content-center">
                          <li class="page-item" [class.disabled]="p.isFirstPage()">
                              <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                                  <span>Previous</span>
                              </a>
                          </li>
                          <li class="page-item" [class.disabled]="p.isLastPage()">
                              <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                                  <span>Next</span>
                              </a>
                          </li>
                      </ul>
                  </nav>
              </div>
          </pagination-template>

          <ng-template #emptyPagination>
              <div class="pagination-wrapper">
                  <div>&nbsp;</div>
              </div>
          </ng-template>

      </div>

      
  </div>
  
</div>

<ng-template #formSemesterModal let-modal>
  <div class="modal-body">
      <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
      <div class="modal-form">
          <h1 class="text-center">Edit Form Semester</h1>
          <form [formGroup]="formSemester" (ngSubmit)="onSubmitformSemester(modal)">

            <div class="mb-3 mt-4 form-group row">
              <label for="input-id-1" class="col-sm-3 control-label">Tahun Pelajaran</label>
              <div class="col-sm-9">
                <input                  
                  formControlName="tahun_ajaran" type="text" class="form-control" 
                  autocapitalize="off" autocorrect="off" autocomplete="off">
              </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
              <label for="input-id-1" class="col-sm-3 control-label">Semester</label>
              <div class="col-sm-9">
                <input                  
                  formControlName="semester" type="text" class="form-control" 
                  autocapitalize="off" autocorrect="off" autocomplete="off">
              </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">Nama Semester</label>
                <div class="col-sm-9">
                  <input                  
                    formControlName="semester_name" type="text" class="form-control" 
                    autocapitalize="off" autocorrect="off" autocomplete="off">
                </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
              <label for="input-id-1" class="col-sm-3 control-label">Start Date</label>
              <div class="col-sm-9">
                <input
                            type="text"
                            autocomplete="off"
                            id="start_date"
                            (blur)="changeDateField($event, 'start_date')"                                  
                            formControlName="start_date"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['start_date'].errors }"
                            placeholder=""
                          />
              </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
              <label for="input-id-1" class="col-sm-3 control-label">End Date</label>
              <div class="col-sm-9">
                <input
                            type="text"
                            autocomplete="off"
                            id="end_date"
                            (blur)="changeDateField($event, 'end_date')"                                  
                            formControlName="end_date"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['end_date'].errors }"
                            placeholder=""
                          />
              </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">Tanggal Raport (Masehi)</label>
                <div class="col-sm-9">
                  <input                  
                    formControlName="masehi_report_date" type="text" class="form-control" 
                    autocapitalize="off" autocorrect="off" autocomplete="off">
                </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">Tanggal Raport (Hijriah)</label>
                <div class="col-sm-9">
                  <input                  
                    formControlName="hijriah_report_date" type="text" class="form-control" 
                    autocapitalize="off" autocorrect="off" autocomplete="off">
                </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
              <label for="input-id-1" class="col-sm-3 control-label">Status Input Nilai</label>
              <div class="col-sm-9">
                <select formControlName="status" class="form-control">
                  <option value="" selected>Pilih</option>
                  <option value="t">Aktif</option>
                  <option value="f">Tidak Aktif</option>
                </select>
              </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">Start Report Input Date</label>
                <div class="col-sm-9">
                  <input
                              type="text"
                              autocomplete="off"
                              id="start_input_nilai_date"
                              (blur)="changeDateField($event, 'start_input_nilai_date')"                                  
                              formControlName="start_input_nilai_date"
                              class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f['start_input_nilai_date'].errors }"
                              placeholder=""
                            />
                </div>
              </div>
  
              <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">End Report Input Date</label>
                <div class="col-sm-9">
                  <input
                              type="text"
                              autocomplete="off"
                              id="end_input_nilai_date"
                              (blur)="changeDateField($event, 'end_input_nilai_date')"                                  
                              formControlName="end_input_nilai_date"
                              class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f['end_input_nilai_date'].errors }"
                              placeholder=""
                            />
                </div>
              </div>

            
            <button type="submit" class="btn btn-primary mt-3 my-btn-save">Update</button>
          </form>
      </div>
  </div>
</ng-template>
