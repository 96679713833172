import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdm-izin-mengajar-laporan',
  templateUrl: './sdm-izin-mengajar-laporan.component.html',
  styleUrls: ['./sdm-izin-mengajar-laporan.component.css']
})
export class SdmIzinMengajarLaporanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
