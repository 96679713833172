import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable,ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  // https://levelup.gitconnected.com/5-ways-to-share-data-between-angular-components-d656a7eb7f96

  constructor() { }

  public editDataDetails: any = [];
  public subject = new Subject<any>();
  private messageSource = new  BehaviorSubject(this.editDataDetails);
  currentMessage = this.messageSource.asObservable();

  public siswa: any = null;
  private siswaDataSource = new BehaviorSubject(this.siswa);
  currentSiswa = this.siswaDataSource.asObservable();


  public componentToShow: any = null;
  private componentSource = new BehaviorSubject(this.componentToShow);
  currentComponent = this.componentSource.asObservable();

  public dataObject: any = {};
  private dataObjectSource = new BehaviorSubject(this.dataObject);
  currentDataObject = this.dataObjectSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  changeSiswa(data: any) {
    this.siswaDataSource.next(data);
  }

  changeComponent(component: any) {
    this.componentSource.next(component);
  }

  changeDataObject(data: any) {
    this.dataObjectSource.next(data);
  }

}
