import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class WalikelasService {

  constructor(private netService: NetworkService) { }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/walikelas');
  }

  update(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/walikelas/${id}`);
  }

  delete(id: any): Observable<any> {
    return this.netService.deleteRequest(`/v1/walikelas/${id}`);
  }

  getById(id: any): Observable<any> {
    return this.netService.getRequest(`/v1/proguser/${id}`);
  }

  getByPeriodeAndKelas(periode_id: any, kelas_id: any): Observable<any> {
    return this.netService.getRequest(`/v1/walikelas/getByPeriodeAndKelas/${periode_id}/${kelas_id}`);
  }

  getAll(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/walikelas/getByPeriode', params);
  }
}
