import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-absensi-kegiatan',
  templateUrl: './nav-absensi-kegiatan.component.html',
  styleUrls: ['./nav-absensi-kegiatan.component.css']
})
export class NavAbsensiKegiatanComponent implements OnInit {

  isInputAbsensiKegiatan = false
  isListAbsensi = false
  isLaporanPerSIswa = false
  isLaporanPerKamar = false
  isMonitoringAbsesiKamar = false
  isMonitoringPenginput = false

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.changeTab(this.router.url)
  }

  changeTab(url: String) {
    switch (url) {
      case '/absen-kegiatan/input':
        this.isInputAbsensiKegiatan = true
        this.isListAbsensi = false
        this.isLaporanPerSIswa = false
        this.isLaporanPerKamar = false
        this.isMonitoringAbsesiKamar = false
        this.isMonitoringPenginput = false
        return;
      case '/absen-kegiatan/list-absensi-kamar':
        this.isListAbsensi = true
        this.isInputAbsensiKegiatan = false
        this.isLaporanPerSIswa = false
        this.isLaporanPerKamar = false
        this.isMonitoringAbsesiKamar = false
        this.isMonitoringPenginput = false
        return;
      case '/absen-kegiatan/laporan-persiswa':
        this.isLaporanPerSIswa = true
        this.isInputAbsensiKegiatan = false
        this.isListAbsensi = false
        this.isLaporanPerKamar = false
        this.isMonitoringAbsesiKamar = false
        this.isMonitoringPenginput = false
        return;
      case '/absen-kegiatan/laporan-perkamar':
        this.isLaporanPerKamar = true
        this.isInputAbsensiKegiatan = false
        this.isListAbsensi = false
        this.isLaporanPerSIswa = false
        this.isMonitoringAbsesiKamar = false
        this.isMonitoringPenginput = false
        return;
      case '/absen-kegiatan/monitoring-absensi-kamar':
        this.isMonitoringAbsesiKamar = true
        this.isInputAbsensiKegiatan = false
        this.isListAbsensi = false
        this.isLaporanPerSIswa = false
        this.isLaporanPerKamar = false
        this.isMonitoringPenginput = false
        return;
      case '/absen-kegiatan/monitoring-penginput-absensi-kamar':
        this.isMonitoringPenginput = true
        this.isMonitoringAbsesiKamar = false
        this.isInputAbsensiKegiatan = false
        this.isListAbsensi = false
        this.isLaporanPerSIswa = false
        this.isLaporanPerKamar = false
        return;
      default:
        this.isInputAbsensiKegiatan = false
        this.isListAbsensi = false
        this.isLaporanPerSIswa = false
        this.isLaporanPerKamar = false
        this.isMonitoringAbsesiKamar = false
        this.isMonitoringPenginput = false
        return;
    }
  }

}
