import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { CommonService } from 'src/app/helper/common.service';
import { KepsekService } from 'src/app/services/kepsek.service';
import { SdmService } from 'src/app/services/sdm.service';


@Component({
  selector: 'app-kepsek',
  templateUrl: './kepsek.component.html',
  styleUrls: ['./kepsek.component.css']
})
export class KepsekComponent implements OnInit {

  listKepsek: any = [];
  listStaff: any = [];
  listTahunajaran: any = [];
  currentTahunAjaran = "";
  currentPeriodeId: any = null;
  errorMessage = '';
  currentId: any = null;
  buttonText = "Save";

  formChangeTahunAjaran: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
  });

  formKepsek: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
    // id_direktur: new FormControl(''),
    id_kepsek: new FormControl(''),
  });
  submitted = false;

  pageid = "proguser";

  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;

  closeResult: string = '';

  constructor(private tahunajaranService: TahunajaranService, 
    private uiService: UiService,
    private kepsekService: KepsekService,
    private sdmService: SdmService,
    private commonService: CommonService, 
    private modalService: NgbModal, 
    private formBuilder: FormBuilder) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.initForm();
    this.initDataTahunAjaran();

    this.formChangeTahunAjaran = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
      },
    );
  }

  initDataTahunAjaran() {
    this.getCurrentTahunajaran();    
    this.getAllStaff();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    this.currentPeriodeId = e.target.value;

    this.getAllKepsek();
  }

  initForm() {
    this.formKepsek = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        tipe: ['KEPSEK', Validators.required],
        id_user: ['', Validators.required],
      },
    );

  }

  /**
   * Write code on Method
   *
   * @return response()
   */
   openModal(content:any, id: any) {
    this.submitted = false;
    this.formKepsek.reset();
    this.formKepsek.controls['id'].setValue(id);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  } 
     
  /**
   * Write code on Method
   *
   * @return response()
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formChangeTahunAjaran.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllStaff() {
    const params = {};
    this.sdmService.getAll(params).subscribe({
      next: resdata => {
        console.log(resdata);
        const { data, totalrows } = resdata;

        this.listStaff = data;      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        this.currentPeriodeId = resdata.id;
        this.currentTahunAjaran = resdata.name;        

        this.uiService.hideLoader();

        this.getAllTahunajaran();

        this.getAllKepsek();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllKepsek() {
    this.uiService.showLoader();

    // const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    const payload = {
      periode_id: this.currentPeriodeId
    }
    console.log("currentPeriodeId", this.currentPeriodeId);
    

    this.kepsekService.getByPeriod(this.currentPeriodeId).subscribe({
      next: resdata => {
        console.log('kepsek', resdata);
        this.listKepsek = resdata;
        
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.getAllKepsek();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getAllKepsek();
  }

  search(): void {
    this.page = 1;
    this.getAllKepsek();
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/empty-image.png';
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formKepsek.controls;
  }

  confirmDelete(id: String) {
    
    this.uiService.showConfirmDelete(this, id);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.kepsekService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getAllKepsek();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  openAddModalformKepsek(content:any) {
    this.buttonText = "Save";
    this.formKepsek.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);    
    
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  openEditModalformKepsek(content:any, kepsek: any) {
    console.log("kepsek", kepsek);
    this.buttonText = "Update";
    
    // this.submitted = false;
    // this.formKepsek.reset();
    this.currentId = kepsek.id;
    this.formKepsek.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
    this.formKepsek.controls['id_user'].setValue(kepsek.id_user == null ? '': kepsek.id_user);
    
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  onSubmitformKepsek(modal: any): void {
    console.log(JSON.stringify(this.formKepsek.value, null, 2));
    
    this.submitted = true;
    
    if (this.formKepsek.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }
    
    modal.dismiss('Cross click');

    this.uiService.showLoader();

    if (this.buttonText === "Save") {
      this.kepsekService.add(this.formKepsek.value).subscribe({
        next: resdata => {
          console.log(resdata);
          
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          this.getAllKepsek();
    
        },
        error: err => {
          console.log(err);
          
          this.submitted = false;
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    } else {
      this.kepsekService.update(this.currentId, this.formKepsek.value).subscribe({
        next: resdata => {
          console.log(resdata);
          
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          this.getAllKepsek();
    
        },
        error: err => {
          console.log(err);
          
          this.submitted = false;
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

}
