import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class SiswaService {

  constructor(private netService: NetworkService) { }

  getAllSiswaBaru(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/siswa/getSiswaBaru', params);
  }

  getAllSiswaBaruPenentuanKelas(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/siswa/getAllSiswaBaruPenentuanKelas', params);
  }

  getAllSiswaTahunAjaranAktif(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/siswa/getAllSiswaTahunAjaranAktif', params);
  }

  getAllSiswaBaruDouble(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/siswa/getSiswaBaruDouble', params);
  }

  getAllSiswaDokumen(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/siswa/getSiswaDokumen', params);
  }

  getRekapLokasiUjian(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/siswa/getRekapLokasiUjian', params);
  }

  getRekapTanggalUjian(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/siswa/getRekapTanggalUjian', params);
  }

  getAllBukuInduk(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/siswa/getBukuInduk', params);
  }

  addNewStudent(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/siswa/register');
  }

  getById(id: any): Observable<any> {
    return this.netService.getRequest(`/v1/siswa/${id}`);
  }

  getSebaranKota(periode: any): Observable<any> {
    return this.netService.getRequest(`/v1/getSebaranKotaByPeriod/${periode}`);
  }

  delete(id: any): Observable<any> {
    return this.netService.deleteRequest(`/v1/siswa/${id}`);
  }

  changeStaffPassword(payload: any): Observable<any> {
    return this.netService.putRequest(payload, '/v1/siswa/changePassword');
  }

  updateSiswa(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/siswa/${id}`);
  }

  changeTahapanPendaftaran(payload: any): Observable<any> {
    return this.netService.putRequest(payload, '/v1/siswa/updateRegStep');
  }

}
