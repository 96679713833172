import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from 'src/app/helper/ui.service';
import { PermissionService } from 'src/app/services/permission.service';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-role-permission',
  templateUrl: './role-permission.component.html',
  styleUrls: ['./role-permission.component.css']
})
export class RolePermissionComponent implements OnInit {
  form: FormGroup;
  roleId: String = "";
  listpermissions: any = [];
  
  errorMessage = '';

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private uiService: UiService,
    private permissionService: PermissionService
  ) {
    this.form = this.fb.group({
      checkArray: this.fb.array([])
    })
  }

  ngOnInit(): void {
    this.roleId = this.route.snapshot.params['roleId'];
    this.getRolePermissionByRole();
  }

  getRolePermissionByRole() {
    this.uiService.showLoader();

    this.permissionService.getByRole("", this.roleId).subscribe({
      next: resdata => {
        console.log(resdata);
        
        this.listpermissions = resdata;
        
        this.addCheckboxes();
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  private addCheckboxes() {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
    this.listpermissions.forEach((items: any) => {
      if (items.RolePermission.length > 0)
        checkArray.push(new FormControl(`${items.id}`))
    });
  }

  onCheckboxChange(e: any) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  onBack() {
    this.router.navigate(['/settings/role/list']);
  }

  onSubmit() {
    console.log(this.form.value.checkArray);
    const payload = {
      "role_id": this.roleId,
      "permissions": this.form.value.checkArray
    }

    this.uiService.showLoader();
    
    this.permissionService.assignPermission(payload).subscribe({
      next: resdata => {
        console.log(resdata);
      
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

}
