import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Validation from 'src/app/helper/validation';
import { SiswaService } from 'src/app/services/siswa.service';
import { GeneralService } from 'src/app/services/general.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { TingkatService } from 'src/app/services/tingkat.service';
import { LembagaService } from 'src/app/services/lembaga.service';
import { SiswakelasService } from 'src/app/services/siswakelas.service';
import { ExcelService } from 'src/app/services/excel.service';
import { KelasService } from 'src/app/services/kelas.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { LogService } from 'src/app/services/log.service';
import { SdmService } from 'src/app/services/sdm.service';
import { GedungService } from 'src/app/services/gedung.service';
import { RayonService } from 'src/app/services/rayon.service';
import { KamarService } from 'src/app/services/kamar.service';
import { SiswakamarService } from 'src/app/services/siswakamar.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-listkamar',
  templateUrl: './listkamar.component.html',
  styleUrls: ['./listkamar.component.css']
})
export class ListkamarComponent implements OnInit {

  activePeriodeId: any = null;
  currentPeriodeId: any = null;
  currentPpsbPeriodeId: any = null;
  currentAngkatan = "";
  listGedung: any = [];
  listRayon: any = [];
  listRayonModify: any = [];
  listKamarModify: any = [];
  currentModule = "";
  listStaff: any = [];
  listSiswa: any = [];
  listNomorUrut: any = [];
  listNomorUrutModify: any = [];
  currentUseer = "";
  listPeriodeKamar: any = [];
  listSiswaPerKamar: any = [];
  listSiswaPerKamarModify: any = [];
  listTahunajaran: any = [];
  listKelas: any = [];
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  isShowLoader: Boolean = false;
  searchPenempatanKelas = "";
  closeResult: string = '';
  currentDataKamar: any = {};
  searchSiswaTerm = "";
  sisaKamar = 0;
  currentIdKamar: any = null;
  currentKamarModify: any = null;

  formChangeTahunAjaran: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
  });

  formKamarSiswa: FormGroup = new FormGroup({    
    siswa_name: new FormControl(''),
    id_siswa: new FormControl(''),    
    id_tahun_ajaran: new FormControl(''),        
    id_kamar: new FormControl(''),
    nomor_urut: new FormControl(''),
  });

  formPenentuanKamar: FormGroup = new FormGroup({
    id: new FormControl(''),
    nama: new FormControl(''),
    id_gedung: new FormControl(''),
    id_rayon: new FormControl(''),
    id_kamar: new FormControl(''),
    id_siswa: new FormControl(''),
    id_tahun_ajaran: new FormControl(''),
    nomor_urut: new FormControl(''),
  });

  formAction: FormGroup = new FormGroup({
    id_gedung: new FormControl(''),
    id_rayon: new FormControl(''),
  });

  constructor(private siswaService: SiswaService,
    private generalService: GeneralService,
    private uiService: UiService,
    private modalService: NgbModal,
    private tingkatService: TingkatService,
    private lembagaService: LembagaService,
    private tahunajaranService: TahunajaranService,
    private siswakelasService: SiswakelasService,
    private excelService: ExcelService,
    private kelasService: KelasService,
    private sharedDataService: SharedDataService,
    private gedungService: GedungService,
    private rayonService: RayonService,
    private logService: LogService,
    private sdmService: SdmService,
    private kamarService: KamarService,
    private router: Router,
    private siswaKamarService: SiswakamarService,
    private formBuilder: FormBuilder) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getAllTahunajaran();
    this.getAllGedung();

    this.formAction = this.formBuilder.group(
      {
        id_gedung: [''],
        id_rayon: [''],
      },
    );

    this.formChangeTahunAjaran = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
      },
    );

    this.formKamarSiswa = this.formBuilder.group(
      {        
        siswa_name: [''],
        id_tahun_ajaran: ['', Validators.required],
        id_siswa: ['', Validators.required],
        id_kamar: ['', Validators.required],
        nomor_urut: ['', Validators.required],
      },
    );

    this.formPenentuanKamar = this.formBuilder.group(
      {
        id: [''],
        nama: [''],
        id_tahun_ajaran: ['', Validators.required],
        id_gedung: ['', Validators.required],
        id_rayon: ['', Validators.required],
        id_kamar: ['', Validators.required],
        id_siswa: ['', Validators.required],
        nomor_urut: [''],
      }
    );

  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAction.controls;
  }

  get k(): { [key: string]: AbstractControl } {
    return this.formPenentuanKamar.controls;
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  changeModule(e: any) {
    console.log(e.target.value);
    this.currentModule = e.target.value;         
  }

  changeGedung(e: any) {
    console.log(e.target.value);
    this.formAction.controls['id_rayon'].setValue('');
    if (e.target.value !== "") {
      this.getAllRayon(e.target.value);
    } else {
      this.listRayon = [];
    }
  }

  changeGedungModify(e: any) {
    console.log(e.target.value);
    this.formPenentuanKamar.controls['id_rayon'].setValue('');
    this.formPenentuanKamar.controls['id_kamar'].setValue('');
    this.formPenentuanKamar.controls['nomor_urut'].setValue('');
    this.listNomorUrutModify = [];
    this.listKamarModify = [];
    if (e.target.value !== "") {
      this.getAllRayonModify(e.target.value);
    } else {
      this.listRayonModify = [];
    }
  }

  getCurrentPeriodeDataKamar(idkamar: any) {
    this.uiService.showLoader();

    const payload = {
      id_periode: this.currentPeriodeId,
      id_kamar: idkamar
    }

    this.kamarService.getPeriodeKamarByKamar(payload).subscribe({
      next: resdata => {
        console.log('DATA KAMAR', resdata);
        this.getListSiswaKamarModify(resdata);
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllRayonModify(idgedung: any) {
    this.uiService.showLoader();

    this.rayonService.getByGedung(idgedung).subscribe({
      next: resdata => {
        console.log('RAYON DATA', resdata);
                
        this.listRayonModify = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  resetFilter() {
    this.formChangeTahunAjaran.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
    this.formAction.controls['id_gedung'].setValue('');
    this.formAction.controls['id_rayon'].setValue('');
    this.getListPeriodeKamar();
  }

  getListPeriodeKamar() {    
    const id_periode = this.formChangeTahunAjaran.value.id_tahun_ajaran;
    const payload = {
      id_gedung: this.formAction.value.id_gedung,
      id_rayon: this.formAction.value.id_rayon
    }

    console.log(id_periode, payload);
    

    this.kamarService.getByPeriode(payload, id_periode).subscribe({
      next: resdata => {
        console.log('getListPeriodeKamar', resdata);

        this.listPeriodeKamar = resdata.data;

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }  

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        this.formChangeTahunAjaran.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);

        this.getListPeriodeKamar();

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        this.getCurrentTahunajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllGedung() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    console.log(params);
    

    this.gedungService.getAll(params).subscribe({
      next: resdata => {
        console.log('Gedung', resdata);
        const { data, totalrows } = resdata;

        //this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listGedung = data;
        this.count = totalrows;

        console.log('COUNT', this.count);

      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllRayon(idgedung: any) {
    this.uiService.showLoader();

    this.rayonService.getByGedung(idgedung).subscribe({
      next: resdata => {
        console.log('RAYON DATA', resdata);
                
        this.listRayon = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  openModalFormDataSiswaKamar(content: any, data: any) {
    console.log(data);
    this.formKamarSiswa.controls['id_kamar'].setValue(data.id_kamar);
    this.currentDataKamar = data;
    this.sisaKamar = data.sisa;

    this.resetFormSIswaKamar();

    this.getListSiswaKamar(data);   

    this.modalService.open(content, {size: 'xl',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  resetFormSIswaKamar() {
    this.formKamarSiswa.controls['nomor_urut'].setValue('');
    this.formKamarSiswa.controls['siswa_name'].setValue('');
  }

  getListSiswaKamar(data: any) {
    console.log("LIST DATA", data);
    const kuota = data.kuota;
    this.uiService.showLoader();    

    this.siswaKamarService.getListSiswaKamar(this.currentPeriodeId, data.id_kamar).subscribe({
      next: resdata => {
        console.log("getListSiswaKamar", resdata);
        this.listSiswaPerKamar = resdata;

        const jumlahSiswaKamar = resdata.length;
        const sisa = kuota - jumlahSiswaKamar;
        this.sisaKamar = sisa;
        let noUrutSiswa = [];
        for (let i=0; i<resdata.length; i++) {
          console.log("SISWA", i, resdata[i].Siswa.nama_lengkap, resdata[i].nomor_urut);
          noUrutSiswa[resdata[i].nomor_urut] = resdata[i].Siswa.nama_lengkap;
        }

        console.log("noUrutSiswa", noUrutSiswa);
        
        this.listNomorUrut = [];

        for (let n=1; n<=data.kuota; n++) {
          const dtN = noUrutSiswa[n] !== 'undefined' ? {no: n, nama: noUrutSiswa[n] } : {no: n, nama: ''};
          this.listNomorUrut.push(dtN);
        }

        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  confirmDelete(id: any) {

  }

  onSubmitFormKamarSiswa(modal: any) {

  }

  searchSiswa(): void {
    this.searchSiswaTerm = this.formKamarSiswa.value.siswa_name;

    this.listSiswa = [];
    if (this.searchSiswaTerm != "") {
      this.isShowLoader = true;
      const params = this.getRequestParams(this.searchSiswaTerm, this.page, this.pageSize);
      params['angkatan'] = this.currentAngkatan;

      console.log("PARAMS", params);
      
  
      this.siswaService.getAllSiswaBaru(params).subscribe({
        next: resdata => {
          console.log(resdata);
          const { data, totalrows } = resdata;
  
          this.isShowPagination == totalrows > this.pageSize ? true : false;
          this.listSiswa = data;
          this.count = totalrows;
  
          console.log("SISWA", this.listSiswa);
  
          console.log('COUNT', this.count);
  
  
          this.isShowLoader = false;
          //this.uiService.hideLoader();
  
        },
        error: err => {
          this.isShowLoader = false;
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);
  
          //this.uiService.hideLoader();
  
          //this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  chooseSiswa(siswa: any) {
    console.log(siswa);
    
    this.listSiswa = [];
    this.formKamarSiswa.controls['siswa_name'].setValue(siswa.nama_lengkap);
    this.formKamarSiswa.controls['id_siswa'].setValue(siswa.id);
  }

  onSubmitformInputDataKamar() {
    const currentIdTahunAjaran = this.formChangeTahunAjaran.value.id_tahun_ajaran;
    this.formKamarSiswa.controls['id_tahun_ajaran'].setValue(currentIdTahunAjaran);
    console.log(JSON.stringify(this.formKamarSiswa.value, null, 2));
    
    if (this.formKamarSiswa.invalid) {
      return;
    }

    this.uiService.showLoader();

    this.siswaKamarService.add(this.formKamarSiswa.value).subscribe({
      next: resdata => {
        console.log(resdata);

        this.resetFormSIswaKamar();

        this.getListSiswaKamar(this.currentDataKamar);
        this.getListPeriodeKamar();
        this.uiService.hideLoader();
        this.uiService.showSuccess('');

      },
      error: err => {
        console.log(err);

        this.errorMessage = err.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  confirmDeleteSiswaKamar(id: any) {    
    this.uiService.showConfirmCustom(this, this.executeEmptyStudentRoom, id, " Ya !");
  }

  executeEmptyStudentRoom(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.siswaKamarService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getListSiswaKamar($SELF.currentDataKamar);
          $SELF.getListPeriodeKamar();   
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  changeRayonModify(e: any) {
    console.log(e.target.value);
    const rayonId = e.target.value;
    this.formPenentuanKamar.controls['id_kamar'].setValue('');    
    this.formPenentuanKamar.controls['nomor_urut'].setValue('');
    this.listNomorUrutModify = [];
    this.listKamarModify = [];
    this.getListKamarByRayon(rayonId);
  }

  getListKamarByRayon(rayonId: any) {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    params['id_rayon'] = rayonId;

    this.kamarService.getByRayon(params).subscribe({
      next: resdata => {
        console.log(resdata);
        const { data, totalrows } = resdata;

        this.listKamarModify = data;

        if (this.currentIdKamar !== null) {          
          this.getListSiswaKamarModify(this.currentKamarModify);
        }
        
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getListSiswaKamarModify(data: any) {
    console.log("LIST DATA", data);
    const kuota = data.kuota;
    this.uiService.showLoader();    

    this.siswaKamarService.getListSiswaKamar(this.currentPeriodeId, data.id_kamar).subscribe({
      next: resdata => {
        console.log("getListSiswaKamar", resdata);
        this.listSiswaPerKamarModify = resdata;

        const jumlahSiswaKamar = resdata.length;
        const sisa = kuota - jumlahSiswaKamar;
        this.sisaKamar = sisa;
        let noUrutSiswa = [];
        for (let i=0; i<resdata.length; i++) {
          console.log("SISWA", i, resdata[i].Siswa.nama_lengkap, resdata[i].nomor_urut);
          noUrutSiswa[resdata[i].nomor_urut] = resdata[i].Siswa.nama_lengkap;
        }

        console.log("noUrutSiswa", noUrutSiswa);
        
        this.listNomorUrutModify = [];

        for (let n=1; n<=data.kuota; n++) {
          const dtN = noUrutSiswa[n] !== 'undefined' ? {no: n, nama: noUrutSiswa[n] } : {no: n, nama: ''};
          this.listNomorUrutModify.push(dtN);
        }

        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeKamar(e: any) {
    const idKamar = e.target.value;
    // this.currentIdKamar = idKamar;
    // this._setNomorUrut();
    this.getCurrentPeriodeDataKamar(idKamar);
  }

  onSubmitPenentuanKamar(modal: any): void {
    const curPeriodId = this.formChangeTahunAjaran.value.id_tahun_ajaran;
    this.formPenentuanKamar.controls['id_tahun_ajaran'].setValue(curPeriodId);
    this.submitted = true;
    console.log(JSON.stringify(this.formPenentuanKamar.value, null, 2));
    console.log(this.formPenentuanKamar.value.id);
    

    if (this.formPenentuanKamar.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    if (this.formPenentuanKamar.value.id !== '' && this.formPenentuanKamar.value.id !== null) {
      this.siswaKamarService.update(this.formPenentuanKamar.value.id, this.formPenentuanKamar.value).subscribe({
        next: resdata => {
          console.log(resdata);
          modal.dismiss('Cross click');
          this.uiService.hideLoader();

          this.getListSiswaKamar(this.currentDataKamar);
          this.getListPeriodeKamar();   

          this.uiService.showSuccess('');
  
        },
        error: err => {
          console.log(err);
  
          this.errorMessage = err.message;
          console.log(this.errorMessage);
  
          this.uiService.hideLoader();
  
          this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  openModalPenentuanKamar(content:any, kamar: any) {
    console.log('FROM MODAL',kamar);
    this.currentKamarModify = kamar;
    this.currentKamarModify = kamar.id_kamar;

    // if (siswa.status !== "ACTIVE") {
    //   this.uiService.showError("Belum registrasi ulang/ Konfirmasi");

    //   return;
    // } 

    // get list rayon 
    this.getAllRayonModify(kamar.Kamar.RayonKamar.id_gedung);
    // get list kamar
    this.getListKamarByRayon(kamar.Kamar.RayonKamar.id);
    // get nomor urut
    this.getListSiswaKamarModify(this.currentDataKamar);

    this.submitted = false;
    this.formPenentuanKamar.reset();
    // this.currentIdKamar = null;
    // this.listKamar = [];
    this.listNomorUrut = [];
    this.formPenentuanKamar.controls['id'].setValue(kamar.id);
    this.formPenentuanKamar.controls['id_siswa'].setValue(kamar.Siswa.id);
    this.formPenentuanKamar.controls['nama'].setValue(kamar.Siswa.nama_lengkap);
    this.formPenentuanKamar.controls['id_gedung'].setValue(kamar.Kamar.RayonKamar.id_gedung);
    this.formPenentuanKamar.controls['id_rayon'].setValue(kamar.Kamar.RayonKamar.id);
    this.formPenentuanKamar.controls['id_kamar'].setValue(kamar.id_kamar);
    this.formPenentuanKamar.controls['nomor_urut'].setValue(kamar.nomor_urut);
    this.formPenentuanKamar.controls['id_tahun_ajaran'].setValue(this.currentPpsbPeriodeId);
    
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  printKamar(kamar: any) {
    console.log(kamar);
    const query = btoa(JSON.stringify(kamar));
    const routeUrl = this.router.serializeUrl(this.router.createUrlTree(['/pengasuhan/printkamar']));
    const fullUrl = window.location.origin + routeUrl + "?q=" + query;
    window.open(fullUrl, '_blank');
  }

}
