import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { TingkatService } from 'src/app/services/tingkat.service';
import { LembagaService } from 'src/app/services/lembaga.service';
import { KelasService } from 'src/app/services/kelas.service';



@Component({
  selector: 'app-kelas-edit',
  templateUrl: './kelas-edit.component.html',
  styleUrls: ['./kelas-edit.component.css']
})

export class KelasEditComponent implements OnInit {

  listsLembaga: any = [];
  listsTingkat: any = [];
  kelasData: any = null;
  errorMessage = '';
  tahunAjaranActive = "";
  id:string = "";

  form: FormGroup = new FormGroup({
    id_lembaga: new FormControl(''),
    id_tingkat: new FormControl(''),
    kode_kelas: new FormControl(''),
    nama_kelas: new FormControl(''),
    nama_kelas_ar: new FormControl(''),
  });
  submitted = false;

  constructor(private formBuilder: FormBuilder, 
    private uiService: UiService,
    private route: ActivatedRoute, 
    private tingkatService: TingkatService,
    private lembagaService: LembagaService,
    private kelasService: KelasService,
    private router: Router) 
  { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.initForm();
    this.getLembaga();
    this.getKelasEdit();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru");
    }
  }

  onBack() {
    this.router.navigate(['/master/kelas/list']);
  }

  getKelasEdit() {
    this.uiService.showLoader();

    this.kelasService.get(this.id).subscribe({
      next: resdata => {
        console.log('KELAS EDIT', resdata, resdata.tingkatkelas.id_lembaga);
        this.kelasData = resdata;
        
        this.getTingkatByLembaga(resdata.tingkatkelas.id_lembaga, false);

        this.form.patchValue(resdata)

        this.form.controls['id_lembaga'].setValue(resdata.tingkatkelas.id_lembaga);
        
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getLembaga() {
    this.uiService.showLoader();

    this.lembagaService.getAllLembaga('').subscribe({
      next: resdata => {
        console.log('lembaga', resdata);
        this.listsLembaga = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeLembaga(e: any) {
    console.log(e.target.value);
    const lembagaId = e.target.value;
    this.uiService.showLoader();

    this.getTingkatByLembaga(lembagaId);
  }

  getTingkatByLembaga(lembagaId: any, resetTingkat: boolean = true) {
    this.tingkatService.getTingkatByLembagaId(lembagaId).subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsTingkat = resdata;

        if (resetTingkat) {
          this.form.controls['id_tingkat'].setValue('');
        }
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }
  
  initForm() {
    this.form = this.formBuilder.group(
      {
        id_lembaga: ['', Validators.required],
        id_tingkat: ['', Validators.required],
        kode_kelas: ['', Validators.required],
        nama_kelas: ['', Validators.required],
        nama_kelas_ar: ['', Validators.required],
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    
    if (this.form.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    this.kelasService.update(this.id, this.form.value).subscribe({
      next: resdata => {
        console.log(resdata);
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
      
        this.router.navigate(['/master/kelas/list']);

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });

    console.log(JSON.stringify(this.form.value, null, 2));
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

}
