import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SiswaService } from 'src/app/services/siswa.service';
import { UiService } from 'src/app/helper/ui.service';

@Component({
  selector: 'app-siswa-buku-induk',
  templateUrl: './siswa-buku-induk.component.html',
  styleUrls: ['./siswa-buku-induk.component.css']
})
export class SiswaBukuIndukComponent implements OnInit {

  listBukuInduk: any = [];
  errorMessage = '';

  pageid = "proguser";

  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;

  closeResult: string = '';

  constructor(private siswaService: SiswaService,
    private uiService: UiService) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getAllBukuInduk();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  search(): void {
    this.page = 1;
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/empty-image.png';
  }

  getAllBukuInduk() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.siswaService.getAllBukuInduk(params).subscribe({
      next: resdata => {
        console.log(resdata);
        const { data, totalrows } = resdata;

        this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listBukuInduk = data;
        this.count = totalrows;

        console.log('COUNT', this.count);


        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  confirmDelete(id: String) {

    this.uiService.showConfirmDelete(this, id);
  }

}
