import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';


@Injectable({
  providedIn: 'root'
})
export class GedungService {

  constructor(private netService: NetworkService) { }

  getAll(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/gedung', params);
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/gedung');
  }

  get(id: string): Observable<any> {
    return this.netService.getRequest(`/v1/gedung/${id}`);
  }

  update(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/gedung/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/gedung/${id}`);
  }
}
