import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';


@Injectable({
  providedIn: 'root'
})
export class TahunajaranService {

  constructor(private netService: NetworkService) { }

  getAll(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/tahunajaran', params);
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/tahunajaran');
  }

  get(id: string): Observable<any> {
    return this.netService.getRequest(`/v1/tahunajaran/${id}`);
  }

  getAktivePeriode(): Observable<any> {
    return this.netService.getRequest(`/v1/tahunajaran/active`);
  }

  getAktivePpsb(): Observable<any> {
    return this.netService.getRequest(`/v1/tahunajaran/getOpenRegister`);
  }

  update(id: string, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/tahunajaran/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/tahunajaran/${id}`);
  }

  getSemesterList(payload: any): Observable<any> {    
    return this.netService.postRequest(payload, '/v1/semester/getByPeriode');    
  }

  initSemesterList(payload: any): Observable<any> {    
    return this.netService.postRequest(payload, '/v1/semester/init');    
  }

  updateSemester(id: string, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/semester/${id}`);
  }
}
