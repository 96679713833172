import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class GelombangPendafataranService {

  constructor(private netService: NetworkService) { }

  getAllGelombangPendaftaranByPeriod(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/regperiode', params);
  }

  getByTahunAjaran(tahunAjaran: string): Observable<any> {
    return this.netService.getRequest(`/v1/getregbytahun/${tahunAjaran}`);
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/regperiode');
  }

  getById(id: any): Observable<any> {
    return this.netService.getRequest(`/v1/regperiode/${id}`);
  }

  updateGelombang(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/regperiode/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/regperiode/${id}`);
  }

}
