import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/helper/common.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbsensiService } from 'src/app/services/absensi.service';
import { KamarService } from 'src/app/services/kamar.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';

@Component({
  selector: 'app-monitoring-absen-kamar',
  templateUrl: './monitoring-absen-kamar.component.html',
  styleUrls: ['./monitoring-absen-kamar.component.css']
})
export class MonitoringAbsenKamarComponent implements OnInit {

  listMonitoringAbsensi: any = [];
  listLaporanPerKategori: any = [];
  listTahunajaran: any = [];
  listKamar: any = [];
  selectedKamar: any = [];
  listTanggalJamKe: any[] = [];
  currentSemester = "";
  errorMessage = '';
  submitted = false;
  pageid = 'proguser';
  searchTerm = '';
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  closeResult: string = '';
  listMonth: any = [];
  listTglBulan: any = [];
  listSelectedTglBulan: any = [];
  currentBulan: any = null;
  currentTahun = "";
  
  listKategoriKamar = [
    "PUTRA",
    "PUTRI"
  ]
  listInputAbsen = [
    "Ya",
    "Tidak"
  ]
  listJenisLaporan = [
    "Laporan Perkelas",
    "Laporan Per-kategori Absen"
  ]
  
  currentAngkatan = "";
  currentPeriodeId: any = null;
  isLaporanPerKelas: Boolean = true;

  formLaporanAbsensi: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
    bulan: new FormControl(''),
    tgl: new FormControl(''),
    kategori_kamar: new FormControl(''),  
    input_absensi: new FormControl(''),
    start_date: new FormControl(''),
    end_date: new FormControl(''),    
  });

  Object: any;

  constructor(
    private uiService: UiService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,    
    private kamarService: KamarService,
    private tahunajaranService: TahunajaranService,
    private absensiService: AbsensiService,
  ) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    const today = this.commonService.convertDate();
    this.listMonth = this.commonService.getListMonthTahunAjaranWithNumber();
    this.listTglBulan = this.commonService.getListTanggalBulan();
    this.listSelectedTglBulan = this.listTglBulan;
    this.getCurrentTahunajaran();
    this.initForm();
    
    // default value
    this.formLaporanAbsensi.controls['start_date'].setValue(today);
    this.formLaporanAbsensi.controls['end_date'].setValue(today);

    const currentMonth = this.commonService.getCurrentMonth();
    this.currentBulan = currentMonth;
    console.log("BULAN", this.currentBulan);
    
    this.formLaporanAbsensi.controls['bulan'].setValue(currentMonth);
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add('siswa-baru-list');
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    console.log('handle PAGE', this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  // please be sscos

  search(): void {
    this.page = 1;
  }

  initForm() {
    this.formLaporanAbsensi = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        bulan: ['', Validators.required],
        tgl: [''],       
        kategori_kamar: ['', Validators.required],
        input_absensi: [''],
        start_date: [''],
        end_date: [''],
      },
    );
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        this.currentPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        this.getAllTahunajaran();
                
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = "";

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formLaporanAbsensi.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getListKamar() {
    this.uiService.showLoader();

    const params:any = [];

    params['kategori'] = this.formLaporanAbsensi.value.kategori_kamar;

    this.kamarService.getByKategori(params).subscribe({
      next: resdata => {
        console.log("KAMAR", resdata);
        
        this.listKamar = resdata;

        this.selectedKamar = [];

        for (let i = 0; i < this.listKamar.length; i++) {
          this.selectedKamar.push(this.listKamar[i].id);      
        }
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeKategoriKamar(e: any) {
    console.log(e.target.value);

    this.getListKamar();   

  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    this.currentPeriodeId = e.target.value;
  }

  changeBulan(e: any) {
    // this.formInputHarianAlQuran.controls['id_kelas'].enable();
    // this.formInputHarianAlQuran.controls['id_kelas'].setValue("");
    // this.listNilaiAlquran = [];
    // this.currentBulan = e.target.value;
  }

  changeTanggal(e: any) {
    console.log(e.target.value);
    if (e.target.value === "") {
      this.listSelectedTglBulan = this.listTglBulan;
    } else {
      this.listSelectedTglBulan = [];
      this.listSelectedTglBulan.push(e.target.value);
    }
    console.log(this.listSelectedTglBulan);
    
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formLaporanAbsensi.controls;
  }

  changeStartDateField(e: any) {
    console.log(e.target.value);
    const birthdate = e.target.value;
    this.formLaporanAbsensi.controls['start_date'].setValue(birthdate);
  }

  changeEndDateField(e: any) {
    console.log(e.target.value);
    const birthdate = e.target.value;
    this.formLaporanAbsensi.controls['end_date'].setValue(birthdate);
  }

  onSubmit() {    
    
    console.log(JSON.stringify(this.formLaporanAbsensi.value, null, 2));
    if (this.formLaporanAbsensi.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    const payload = {       
      id_periode: this.currentPeriodeId,
      bulan: this.formLaporanAbsensi.value.bulan,
      kamar: this.selectedKamar,
      start_date: this.formLaporanAbsensi.value.start_date,
      end_date: this.formLaporanAbsensi.value.end_date,      
    }
    this.absensiService.getListAbsensiKamarByMonth(payload).subscribe({
      next: resdata => {
        console.log('list absen', resdata.data);

        this.listMonitoringAbsensi = resdata.data;


        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
    
  }

  showPdf(data: any) {

  }

}
