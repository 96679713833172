<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/input-harian-al-quran" class="nav-link" [class.active]="isInputHarianAlQuran" id="catatan-siswa-tab" 
      data-bs-toggle="tab" data-bs-target="#catatanSiswa" type="button" role="tab" aria-controls="nilai" aria-selected="false">Input Nilai Al-Qur'an</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/input-insya-yaumi" class="nav-link" [class.active]="isInputInsyaYaumi" id="list-input-tab" data-bs-toggle="tab" 
      data-bs-target="#listInput" type="button" role="tab" aria-controls="deleted" aria-selected="false">Insya Yaumi</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/input-daily-composition" class="nav-link" [class.active]="isInputDailyComposition" id="list-input-tab" data-bs-toggle="tab" 
      data-bs-target="#listInput" type="button" role="tab" aria-controls="deleted" aria-selected="false">Daily Composition</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/input-ibadah-amaliah" class="nav-link" [class.active]="isInputIbdadahAmaliah" id="list-input-tab" data-bs-toggle="tab" 
      data-bs-target="#listInput" type="button" role="tab" aria-controls="deleted" aria-selected="false">Ibadah Amaliah</button>
  </li>
  <!-- <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/laporan-nilai-al-quran" class="nav-link" [class.active]="isLaporanNilaiAlquran" id="list-input-tab" data-bs-toggle="tab" 
      data-bs-target="#listInput" type="button" role="tab" aria-controls="deleted" aria-selected="false">Laporan Nilai Al-Quran</button>
  </li> -->
  <li *ngxPermissionsOnly="['SUPER_ADMIN', 'VIEW_LAPORAN_NILAI']" class="nav-item" role="presentation">
    <button routerLink="/pendidikan/laporan-insya-daily" class="nav-link" [class.active]="isLaporanInsyaDaily" id="list-input-tab" data-bs-toggle="tab" 
      data-bs-target="#listInput" type="button" role="tab" aria-controls="deleted" aria-selected="false">Laporan Nilai</button>
  </li>
  <li *ngxPermissionsOnly="['SUPER_ADMIN', 'VIEW_LAPORAN_PENGINPUT']" class="nav-item" role="presentation">
    <button routerLink="/pendidikan/laporan-penginput" class="nav-link" [class.active]="isLaporanPenginput" id="list-input-tab" data-bs-toggle="tab" 
      data-bs-target="#listInput" type="button" role="tab" aria-controls="deleted" aria-selected="false">Laporan Penginput</button>
  </li>
</ul>

<router-outlet></router-outlet>