import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-input-nilai-harian-nav',
  templateUrl: './input-nilai-harian-nav.component.html',
  styleUrls: ['./input-nilai-harian-nav.component.css']
})
export class InputNilaiHarianNavComponent implements OnInit {

  isInputHarianAlQuran = false
  isInputInsyaYaumi = false
  isInputDailyComposition = false
  isInputIbdadahAmaliah = false
  isLaporanNilaiAlquran = false
  isLaporanInsyaDaily = false
  isLaporanPenginput = false

  
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.changeTab(this.router.url)
  }

  changeTab(url: String) {
    switch (url) {
      case '/pendidikan/input-harian-al-quran':
        this.isInputHarianAlQuran = true
        this.isInputInsyaYaumi = false
        this.isInputDailyComposition = false
        this.isInputIbdadahAmaliah = false
        this.isLaporanNilaiAlquran = false
        this.isLaporanInsyaDaily = false
        this.isLaporanPenginput = false
        return;
      case '/pendidikan/input-insya-yaumi':
        this.isInputHarianAlQuran = false
        this.isInputInsyaYaumi = true
        this.isInputDailyComposition = false
        this.isInputIbdadahAmaliah = false
        this.isLaporanNilaiAlquran = false
        this.isLaporanInsyaDaily = false
        this.isLaporanPenginput = false
        return;
      case '/pendidikan/input-daily-composition':
        this.isInputHarianAlQuran = false
        this.isInputInsyaYaumi = false
        this.isInputDailyComposition = true
        this.isInputIbdadahAmaliah = false
        this.isLaporanNilaiAlquran = false
        this.isLaporanInsyaDaily = false
        this.isLaporanPenginput = false
        return;
      case '/pendidikan/input-ibadah-amaliah':
        this.isInputHarianAlQuran = false
        this.isInputInsyaYaumi = false
        this.isInputDailyComposition = false
        this.isInputIbdadahAmaliah = true
        this.isLaporanNilaiAlquran = false
        this.isLaporanInsyaDaily = false
        this.isLaporanPenginput = false
        return;
      case '/pendidikan/laporan-nilai-al-quran':
        this.isInputHarianAlQuran = false
        this.isInputInsyaYaumi = false
        this.isInputDailyComposition = false
        this.isInputIbdadahAmaliah = false
        this.isLaporanNilaiAlquran = true
        this.isLaporanInsyaDaily = false
        this.isLaporanPenginput = false
        return;
      case '/pendidikan/laporan-insya-daily':
        this.isInputHarianAlQuran = false
        this.isInputInsyaYaumi = false
        this.isInputDailyComposition = false
        this.isInputIbdadahAmaliah = false
        this.isLaporanNilaiAlquran = false
        this.isLaporanInsyaDaily = true
        this.isLaporanPenginput = false
        return;
      case '/pendidikan/laporan-penginput':
        this.isInputHarianAlQuran = false
        this.isInputInsyaYaumi = false
        this.isInputDailyComposition = false
        this.isInputIbdadahAmaliah = false
        this.isLaporanNilaiAlquran = false
        this.isLaporanInsyaDaily = false
        this.isLaporanPenginput = true
        return;
      default:
        this.isInputHarianAlQuran = false
        this.isInputInsyaYaumi = false
        this.isInputDailyComposition = false
        this.isInputIbdadahAmaliah = false
        this.isLaporanNilaiAlquran = false
        this.isLaporanInsyaDaily = false
        this.isLaporanPenginput = false
        return;
    }
  }

}
