import { Component, OnInit } from '@angular/core';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/helper/common.service';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-jadwal-mengajar-rekap-input',
  templateUrl: './jadwal-mengajar-rekap-input.component.html',
  styleUrls: ['./jadwal-mengajar-rekap-input.component.css']
})
export class JadwalMengajarRekapInputComponent implements OnInit {

  listSemester: any = [];
  listTahunajaran: any = [];
  listRekapInput: any = [];
  currentPeriodeId: any = null;
  dataToUpload: any = null;
  pageid = "upload-jadwal-mengajar";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  errorMessage = "";
  currentDate = "";
  submitted = false;
  currentSemester = "";
  
  formUploadJadwal: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),    
    id_semester: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private tahunajaranService: TahunajaranService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private excelService: ExcelService,
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {    
    this.getCurrentTahunajaran();
    this.listSemester = this.commonService.getListSemester();
    this.initForm();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {
    this.formUploadJadwal = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        id_semester: ['', Validators.required],
      },
    );
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formUploadJadwal.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;        

        this.uiService.hideLoader();

        for (let i=0; i<resdata.Semester.length; i++) {
          const periodeSemester = this.commonService.getCurrentDateBetweenDateRange(resdata.Semester[i].start_date, resdata.Semester[i].end_date);

          if (periodeSemester) {
            this.currentSemester = resdata.Semester[i].semester;
            this.formUploadJadwal.controls['id_semester'].setValue(this.currentSemester);

            break;    
          }
        }

        this.getAllTahunajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }


  onSubmitformUploadJadwal() {
    this.submitted = true;
    console.log(JSON.stringify(this.formUploadJadwal.value, null, 2));

    if (this.formUploadJadwal.invalid) {
      return;
    }

    if (this.dataToUpload === null) {
      this.uiService.showError("Please upload file first");
    }

    console.log(this.dataToUpload);
    
  }

  onFileChange(evt: any) {
    const fdata: FileReader = this.excelService.convertExcelFileToJson(evt.target);

    fdata.onloadend = (e: any) => {
      // console.log("END OF ROAD", this.excelService.dataExcel);
      const fileUpload: any[] = this.excelService.dataExcel;
      const finalFileUpload = fileUpload.slice(1);

      if (finalFileUpload.length > 0) {
        let dataToUpload = [];
        for (const k in finalFileUpload) {
          const v = finalFileUpload[k];
          console.log(k, v);
          // 1. get periode_id
          // 2. get kelas_id
          // 3. get siswaid
          const column = [v[4],v[1],v[5]];
          dataToUpload.push(column)
        }
        console.log("DATA TO UPLOAD", dataToUpload);

        this.dataToUpload = dataToUpload;
        
        // const payload = {
        //   data: dataToUpload,
        //   idperiode: this.currentPeriodeId
        // }
        
        // this.siswakelasService.uploadSiswakelas(payload).subscribe({
        //   next: resdata => {
        //     console.log('RESPONSE', resdata);
    
        //     this.refreshListKelas();
            
        //     this.uiService.hideLoader();
    
        //   },
        //   error: err => {
        //     this.errorMessage = err.error.message;
        //     console.log(this.errorMessage);
    
        //     this.uiService.hideLoader();
    
        //     this.uiService.showError(this.errorMessage);
        //   }
        // });
      } 
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formUploadJadwal.controls;
  }

}
