import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LembagaService } from 'src/app/services/lembaga.service';

@Component({
  selector: 'app-lembaga-view',
  templateUrl: './lembaga-view.component.html',
  styleUrls: ['./lembaga-view.component.css']
})
export class LembagaViewComponent implements OnInit {

  public lembagaFormGroup!: FormGroup;

  id!: string;
  lembaga!: any;

  constructor(
    public lembagaService: LembagaService,
    private route: ActivatedRoute,
    private router: Router,
    public fb: FormBuilder,
    public datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.lembagaService.get(this.id).subscribe(
      data => {
        console.log(data);
        this.lembaga = data;
      },
      error => {
        console.log(error);
      });
    this.lembagaForm();
  }

  lembagaForm(){
    this.lembagaFormGroup = this.fb.group({
      nama_lembaga: new FormControl('', [Validators.required, Validators.minLength(5)]),
      urut: new FormControl('', [Validators.required]),
    });
  }

  get f(){
    return this.lembagaFormGroup.controls;
  }

  get nama_lembaga() {
    return this.f['nama_lembaga'];
  }
  get urut() {
    return this.f['urut'];
  }

  updateLembaga() {
    this.lembagaService.update(this.id, this.lembagaFormGroup.value).subscribe(() => {
      console.log('Lembaga updated successfully!');
      this.router.navigateByUrl('/lembaga');
    });
  }

  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, 'd - MM - Y');
  }

}