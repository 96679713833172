<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/jadwal-mengajar-main" class="nav-link" [class.active]="isMain" id="input-guru-tab" data-bs-toggle="tab" 
      data-bs-target="#inputGuru" type="button" role="tab" aria-controls="list" aria-selected="false">Main</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/jadwal-mengajar-upload" class="nav-link" [class.active]="isUpload" id="catatan-siswa-tab" 
      data-bs-toggle="tab" data-bs-target="#catatanSiswa" type="button" role="tab" aria-controls="nilai" aria-selected="false">Upload</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/jadwal-mengajar-jadwal-guru" class="nav-link" [class.active]="isJadwalGuru" id="list-input-tab" data-bs-toggle="tab" 
      data-bs-target="#listInput" type="button" role="tab" aria-controls="deleted" aria-selected="false">Jadwal Guru</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/jadwal-mengajar-rekap-input" class="nav-link" [class.active]="isRekapInput" id="laporan-tab" data-bs-toggle="tab" 
      data-bs-target="#laporan" type="button" role="tab" aria-controls="deleted" aria-selected="false">Rekap Input</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/jadwal-mengajar-libur-mengajar" class="nav-link" [class.active]="isLiburMengajar" id="monitoring-tab" data-bs-toggle="tab" 
      data-bs-target="#monitoring" type="button" role="tab" aria-controls="deleted" aria-selected="false">Libur Mengajar</button>
  </li>
</ul>

<router-outlet></router-outlet>
