<div class="contentfull">
  <a href="javascript:void(0)" class="closebtn" (click)="closeButton()">&times;</a>
  
  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="statistik"
      role="tabpanel"
      aria-labelledby="statistik-tab"
    >
  
      
      <div class="list-options">
        <hr />
        <!-- <button type="button" class="btn btn-warning" (click)="closeButton()">
          Close
        </button> -->
      </div>

      <div style="text-align: center;">

        <h3>JADWAL GURU MENGAJAR</h3>
        <h3>Nama : <b>{{ currentNamaGuru }}</b></h3>

      </div>
      
      <div class="table-wrapper">
        <div class="table-options">
  
        </div>
        <div class="table-responsive">
          <table class="table">
              <thead class="bordered">
                  <tr class="below">
                      <th>Jam</th>
                      <th *ngFor="let period of listDayPeriod">{{ period.period }}</th>
                  </tr>
                  
              </thead>
              <tbody>
                  <tr *ngFor="let key of listJadwalPerGuru">
                      <td *ngFor="let key2 of key; let indexOfelement=index;" [ngClass]="{ 'bg-dark': key2.pelajaran === 'x' }">
                        <div *ngIf="indexOfelement === 0">
                          {{ key[indexOfelement] }}

                        </div>
                        <div *ngIf="indexOfelement !== 0 && key2.pelajaran === 'x'">
                          {{ key2.pelajaran }}
                        </div>
                        <div *ngIf="indexOfelement !== 0 && key2.pelajaran !== 'x'">
                          {{ key2.kelas }}<br /> {{ key2.pelajaran }}
                        </div>
                      </td>                      
                  </tr>
                  
              </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</div>