<app-siswa-penempatan-kelas></app-siswa-penempatan-kelas>

<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="statistik"
    role="tabpanel"
    aria-labelledby="statistik-tab"
  >
    <div class="tabs-title">Statistik</div>

    
    <div class="list-options">
      <div class="label">
        Tahun Pelajaran &nbsp;
      </div>
      <div class="form-select-warapper">
        <form [formGroup]="formChangeTahunAjaran">
          <select formControlName="id_tahun_ajaran" (change)="changeTahunAjaran($event)" class="form-select" name="id_tahun_ajaran" id="id_tahun_ajaran">
            <option [value]="data.name" *ngFor="let data of listTahunajaran">{{data.name}}</option>
          </select>
        </form>
      </div>
      
      <!-- <div class="search">
        <input
        type="text"
        class="form-control"
        placeholder="Search.."
        name="search-list"
        id="search-list"
        [(ngModel)]="searchTerm"
        />
        <div class="icon" (click)="search()">
          <img src="./assets/images/icon-search.png" alt="Search" />
        </div>
      </div> -->
      
      <!-- <button class="btn btn-blue-grey" routerLink="/siswa/add">
        Display
      </button> -->
      <!-- <button type="button" class="btn btn-success">
        <img src="./assets/images/icon-excel.png" alt="Excel"> Excel
      </button>
      <button type="button" class="btn btn-danger">
        <img src="./assets/images/icon-pdf.png" alt="PDF"> PDF
      </button> -->
    </div>
    
    <div class="table-wrapper">
      <div class="table-options">
        <div class="show-table">
          <h2>Sebaran kab-kota untuk siswa aktif yang terdaftar di penempatan kelas tahun pelajaran {{ currentAngkatan }}</h2>
          <!-- <select
            class="form-control"
            name="show"
            id="show"
            (change)="handlePageSizeChange($event)"
          >
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }}
            </option>
          </select>
          entries -->
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th>No.</th>
              <th>Kab/Kota</th>
              <th>Jumlah</th>
              <th>Persentase</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let siswa of listStatistikSebaranKota | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
              <td>{{ i + 1 + (page - 1) * pageSize }}</td>              
              <td>{{ siswa.kota_kab }}</td>
              <td>{{ siswa.total }}</td>
              <td>{{ totalSebaranKota | displayPresentase : siswa.total }}</td>
            </tr>
            <tr *ngIf="listStatistikSebaranKota.length === 0" class="empty">
              <td colspan="4">
                Tidak terdapat data atau data tidak ditemukan.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
        <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
          <div></div>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center pagination-number">
              <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                <a
                  class="page-link"
                  (click)="p.setCurrent(page.value)"
                  *ngIf="p.getCurrent() !== page.value"
                >
                  {{ page.label }}
                </a>
                <a class="page-link" *ngIf="p.getCurrent() === page.value">
                  {{ page.label }}
                </a>
              </li>
            </ul>
          </nav>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="p.isFirstPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isFirstPage()"
                  (click)="p.previous()"
                  aria-label="Previous"
                >
                  <span>Previous</span>
                </a>
              </li>
              <li class="page-item" [class.disabled]="p.isLastPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isLastPage()"
                  (click)="p.next()"
                  aria-label="Next"
                >
                  <span>Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </pagination-template>

      <ng-template #emptyPagination>
        <div class="pagination-wrapper">
          <div>&nbsp;</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

