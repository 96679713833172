<div class="sidebar-body">
    <ul>
      <a href="/">
        <li class="active">
          <img src="./assets/images/icon-home.png" alt="" />
          Home
        </li>
      </a>
      <a
        *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'ADMINISTER_SISWA_BARU',
          'ADMINISTER_HASIL_UJIAN_PPSB',
          'ADMINISTER_BUKU_INDUK',
          'ADMINISTER_TAHUN_AJARAN_AKTIF'
        ]"
        data-bs-toggle="collapse"
        class="collapse-menu"
        href="#collapseExample"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        <li>
          <div class="d-flex">
            <img src="./assets/images/icon-student.png" alt="" />
            Data Siswa
          </div>
          <div class="icon-collapse">
            <img src="./assets/images/chevron-down.svg" alt="" />
          </div>
        </li>
      </a>
      <div class="collapse submenu" id="collapseExample">
        <ul>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_SISWA_BARU']"
            [routerLink]="'/siswa'"
          >
            <li>Siswa Baru</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_HASIL_UJIAN_PPSB']"
            [routerLink]="'/ppsb'"
          >
            <li>Hasil Ujian PPSB</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_BUKU_INDUK']"
            [routerLink]="'/siswa/buku-induk'"
          >
            <li>Buku Induk</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_TAHUN_AJARAN_AKTIF']"
            [routerLink]="'/siswa/tahun-ajaran'"
          >
            <li>Tahun Ajaran Aktif</li>
          </a>
        </ul>
      </div>
  
      <a
        *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'ADMINISTER_PENEMPATAN_KELAS',
          'ADMINISTER_ABSENSI_SISWA',
          'ADMINISTER_JADWAL_MENGAJAR',
          'ADMINISTER_IZIN_MENGAJAR',
          'ADMINISTER_INPUT_HARIAN_BULANAN',
          'ADMINISTER_RAPORT'
        ]"
        data-bs-toggle="collapse"
        class="collapse-menu"
        href="#managePendidikan"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        <li>
          <div class="d-flex">
            <img src="./assets/images/icon-school.png" alt="" />
            Pendidikan
          </div>
          <div class="icon-collapse">
            <img src="./assets/images/chevron-down.svg" alt="" />
          </div>
        </li>
      </a>
  
      <div class="collapse submenu sub-submenu" id="managePendidikan">
        <ul>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_PENEMPATAN_KELAS']"
            [routerLink]="'/pendidikan/penempatan-kelas'"
          >
            <li>Penempatan Kelas</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_WALI_KELAS']"
            [routerLink]="'/sdm/walikelas'"
          >
            <li>Wali Kelas</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_JADWAL_MENGAJAR']"
            [routerLink]="'/pendidikan/jadwal-mengajar'"
          >
            <li>Jadwal Mengajar</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_ABSENSI_SISWA']"
            [routerLink]="'/pendidikan/input-guru'"
          >
            <li>Absensi Siswa</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_IZIN_MENGAJAR']"
            [routerLink]="'/pendidikan/izin-mengajar'"
          >
            <li>Izin Mengajar</li>
          </a>                
          
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_INPUT_NILAI']"
            data-bs-toggle="collapse"
            class="collapse-menu"
            href="#manageNilai"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <li>
              <div class="d-flex">Input Nilai</div>
              <div class="icon-collapse">
                <img src="./assets/images/chevron-down.svg" alt="" />
              </div>
            </li>
          </a>
          <div class="collapse sub-submenu" id="manageNilai">
            <ul>
              <a
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'ADMINISTER_INPUT_HARIAN_BULANAN'
                ]"
                [routerLink]="'/pendidikan/input-nilai-harian'"
              >
                <li>Nilai Harian/Bulanan</li>
              </a>
              <a
                *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_INPUT_HARIAN_BULANAN']"
                [routerLink]="'./pendidikan/input-nilai-raport-harian'"
              >
                <li>Nilai Harian</li>
              </a>                        
              <a
                *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_INPUT_HARIAN_BULANAN']"
                [routerLink]="'./pendidikan/input-nilai-raport-akhir'"
              >
                <li>Nilai Akhir</li>
              </a>
              <a
                *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_INPUT_HARIAN_BULANAN']"
                [routerLink]="'./pendidikan/input-nilai-raport-non-nilai'"
              >
                <li>Raport Non Nilai</li>
              </a>
            </ul>
          </div>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_RAPORT']"
            data-bs-toggle="collapse"
            class="collapse-menu"
            href="#manageRapor"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <li>
              <div class="d-flex">Rapor</div>
              <div class="icon-collapse">
                <img src="./assets/images/chevron-down.svg" alt="" />
              </div>
            </li>
          </a>
          <div class="collapse sub-submenu" id="manageRapor">
            <ul>
              <a
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'ADMINISTER_INPUT_HARIAN_BULANAN'
                ]"
                [routerLink]="'/'"
              >
                <li>Cetak Raport</li>
              </a>                        
              <a
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'ADMINISTER_INPUT_HARIAN_BULANAN'
                ]"
                [routerLink]="'/'"
              >
                <li>Ledger Nilai</li>
              </a>
              <a
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'ADMINISTER_INPUT_HARIAN_BULANAN'
                ]"
                [routerLink]="'/'"
              >
                <li>Report Input</li>
              </a>
              <a
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'ADMINISTER_INPUT_HARIAN_BULANAN'
                ]"
                [routerLink]="'/'"
              >
                <li>Report Input Global</li>
              </a>
              <a
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'ADMINISTER_INPUT_HARIAN_BULANAN'
                ]"
                [routerLink]="'/'"
              >
                <li>Ledger Kenaikan Kelas</li>
              </a>
              <a
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'ADMINISTER_INPUT_HARIAN_BULANAN'
                ]"
                [routerLink]="'/'"
              >
                <li>Ledger Kenaikan Kelas Per Matapelajaran</li>
              </a>                        
            </ul>
          </div>
        </ul>
      </div>
  
      <a
        *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_SETUP_BIAYA']"
        data-bs-toggle="collapse"
        class="collapse-menu"
        href="#collapseKeuangan"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        <li>
          <div class="d-flex">
            <img src="./assets/images/icon-money.png" alt="" />
            Keuangan
          </div>
          <div class="icon-collapse">
            <img src="./assets/images/chevron-down.svg" alt="" />
          </div>
        </li>
      </a>
      <div class="collapse submenu sub-submenu" id="collapseKeuangan">
        <ul>
          <!-- <a [routerLink]="'./keuangan/biaya'" routerLinkActive="active">
                      <li>List Biaya</li>
                  </a> -->
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_SETUP_BIAYA']"
            data-bs-toggle="collapse"
            class="collapse-menu"
            href="#manageBiaya"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <li>
              <div class="d-flex">Setup Biaya</div>
              <div class="icon-collapse">
                <img src="./assets/images/chevron-down.svg" alt="" />
              </div>
            </li>
          </a>
          <div class="collapse sub-submenu" id="manageBiaya">
            <ul>
              <a
                *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_SETUP_BIAYA']"
                [routerLink]="'./keuangan/group-biaya'"
              >
                <li>Group Biaya</li>
              </a>
              <a
                *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_SETUP_BIAYA']"
                [routerLink]="'./keuangan/item-biaya'"
              >
                <li>Item Biaya</li>
              </a>
              <a
                *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_SETUP_BIAYA']"
                [routerLink]="'./keuangan/paket-pembayaran'"
              >
                <li>Paket Pembayaran</li>
              </a>
              <a
                *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_SETUP_BIAYA']"
                [routerLink]="'./keuangan/setup-tahunan'"
              >
                <li>Setup Tahunan</li>
              </a>
            </ul>
          </div>
          <a          
            [routerLink]="'/keuangan/generate-tagihan'"
          >
            <li *ngxPermissionsOnly="['SUPER_ADMIN']">Generate</li>
          </a>
          <a
            *ngIf="isLocalEnv"          
            [routerLink]="'/keuangan/simulasi-pembayaran'"
          >
            <li *ngxPermissionsOnly="['SUPER_ADMIN']">Simulasi Pembayaran</li>
          </a>
        </ul>
      </div>
      <a
        *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'ADMINSITER_LIST_SDM',
          'ADMINISTER_CATATAN_GURU',
          'ADMINISTER_WALI_KELAS',
          'ADMINISTER_STRUKTUR'
        ]"
        data-bs-toggle="collapse"
        class="collapse-menu"
        href="#collapseSdm"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        <li>
          <div class="d-flex">
            <img src="./assets/images/icon-sdm.png" alt="" />
            SDM
          </div>
          <div class="icon-collapse">
            <img src="./assets/images/chevron-down.svg" alt="" />
          </div>
        </li>
      </a>
      <div class="collapse submenu" id="collapseSdm">
        <ul>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINSITER_LIST_SDM']"
            [routerLink]="'./sdm'"
            routerLinkActive="active"
          >
            <li>List SDM</li>
          </a>
          <!-- <a *ngxPermissionsOnly="['SUPER_ADMIN']" [routerLink]="'/sdm/absensi'">
                      <li>Absensi Insidentil</li>
                  </a> -->
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_CATATAN_GURU']"
            [routerLink]="'/sdm/catatan-guru'"
          >
            <li>Catatan Guru</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_STRUKTUR']"
            [routerLink]="'/sdm/struktur'"
          >
            <li>Struktur</li>
          </a>
        </ul>
      </div>
  
      <a
        *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'ADMINISTER_PENGASUHAN_KAMAR',
          'ADMINISTER_ABSEN_KAMAR',
          'ADMINISTER_IZIN_SANTRI',
          'ADMINISTER_PELANGGARAN',
          'ADMINISTER_PRESTASI'
        ]"
        data-bs-toggle="collapse"
        class="collapse-menu"
        href="#collapsePengasuhan"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        <li>
          <div class="d-flex">
            <img src="./assets/images/icon-nurse.png" alt="" />
            Pengasuhan
          </div>
          <div class="icon-collapse">
            <img src="./assets/images/chevron-down.svg" alt="" />
          </div>
        </li>
      </a>
      <div class="collapse submenu sub-submenu" id="collapsePengasuhan">
        <ul>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_PENGASUHAN_KAMAR']"
            [routerLink]="'./master/lembaga'"
            routerLinkActive="active"
          >
            <li>Kamar</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_ABSEN_KAMAR']"
            [routerLink]="'./master/tingkatan'"
          >
            <li>Absen Kamar</li>
          </a>
  
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_IZIN_SANTRI']"
            data-bs-toggle="collapse"
            class="collapse-menu"
            href="#izinSantri"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <li>
              <div class="d-flex">Izin Santri</div>
              <div class="icon-collapse">
                <img src="./assets/images/chevron-down.svg" alt="" />
              </div>
            </li>
          </a>
          <div class="collapse sub-submenu" id="izinSantri">
            <ul>
              <a
                *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_IZIN_SANTRI']"
                [routerLink]="'./keuangan/group-biaya'"
              >
                <li>List Izin Santri</li>
              </a>
              <a
                *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_IZIN_SANTRI']"
                [routerLink]="'./keuangan/item-biaya'"
              >
                <li>Scan Barcode</li>
              </a>
              <a
                *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_IZIN_SANTRI']"
                [routerLink]="'./keuangan/paket-pembayaran'"
              >
                <li>Laporan Perkelas</li>
              </a>
            </ul>
          </div>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_PELANGGARAN']"
            [routerLink]="'./master/pelajaran'"
          >
            <li>Pelanggaran</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_PRESTASI']"
            [routerLink]="'./master/gedung'"
          >
            <li>Prestasi</li>
          </a>
        </ul>
      </div>
  
      <a
        *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'ADMINISTER_LEMBAGA_PENDIDIKAN',
          'ADMINISTER_TINGAKATAN',
          'ADMINISTER_KELAS',
          'ADMINISTER_PELAJARAN',
          'ADMINISTER_GEDUNG',
          'ADMINISTER_RAYON',
          'ADMINISTER_MASTER_KAMAR',
          ''
        ]"
        data-bs-toggle="collapse"
        class="collapse-menu"
        href="#collapseMaster"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        <li>
          <div class="d-flex">
            <img src="./assets/images/icon-files.png" alt="" />
            Master
          </div>
          <div class="icon-collapse">
            <img src="./assets/images/chevron-down.svg" alt="" />
          </div>
        </li>
      </a>
      <div class="collapse submenu" id="collapseMaster">
        <ul>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_LEMBAGA_PENDIDIKAN']"
            [routerLink]="'./master/lembaga'"
            routerLinkActive="active"
          >
            <li>Lembaga Pendidikan</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_TINGAKATAN']"
            [routerLink]="'./master/tingkatan'"
          >
            <li>Tingkatan</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_KELAS']"
            [routerLink]="'./master/kelas'"
          >
            <li>Kelas</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_PELAJARAN']"
            [routerLink]="'./master/pelajaran'"
          >
            <li>Pelajaran</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_GEDUNG']"
            [routerLink]="'./master/gedung'"
          >
            <li>Gedung</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_RAYON']"
            [routerLink]="'./master/rayon'"
          >
            <li>Rayon</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_MASTER_KAMAR']"
            [routerLink]="'./master/kamar'"
          >
            <li>Kamar</li>
          </a>
        </ul>
      </div>
  
      <a
        *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_BRT']"
        data-bs-toggle="collapse"
        class="collapse-menu"
        href="#collapseBRT"
        role="button"
        aria-expanded="false"
        aria-controls="collapseBRT"
      >
        <li>
          <div class="d-flex">
            <img src="./assets/images/icon-files.png" alt="" />
            BRT
          </div>
          <div class="icon-collapse">
            <img src="./assets/images/chevron-down.svg" alt="" />
          </div>
        </li>
      </a>
  
      <div class="collapse submenu" id="collapseBRT">
        <ul>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_BRT']"
            [routerLink]="'/brt/input'"
            routerLinkActive="active"
          >
            <li>Input</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_BRT']"
            [routerLink]="'/brt/list'"
          >
            <li>List</li>
          </a>
          <a
            *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_BRT']"
            [routerLink]="'/brt/alat'"
          >
            <li>Alat</li>
          </a>
        </ul>
      </div>
  
      <a
        *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_ABSEN_KEGIATAN']"
        href=""
      >
        <li>
          <img src="./assets/images/icon-clipboard.png" alt="" />
          Absen Kegiatan
        </li>
      </a>
  
      <a
        *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_NEWS']"
        [routerLink]="'/news'"
      >
        <li>
          <img src="./assets/images/icon-news.png" alt="" />
          News
        </li>
      </a>
      <!-- <a *ngxPermissionsOnly="['SUPER_ADMIN']" href="">
  
              <li>
                  <img src="./assets/images/icon-exams.png" alt="">
                  Ujian Online
              </li>
          </a> -->
      <!-- <a *ngxPermissionsOnly="['SUPER_ADMIN']" href="">
              <li>
                  <img src="./assets/images/icon-classroom.png" alt="">
                  Google Classroom
              </li>
          </a> -->
      <a
        *ngxPermissionsOnly="['SUPER_ADMIN', 'ADMINISTER_TUGAS_HARIAN_MINGGUAN']"
        href=""
      >
        <li>
          <img src="./assets/images/icon-task.png" alt="" />
          <a [routerLink]="'/tugas'"> Tugas Harian/Mingguan </a>
        </li>
      </a>
  
      <a
        *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'ADMINISTER_SETTING_TAHUN_AJARAN',
          'ADMINISTER_SETTING_PENDAFTARAN',
          'ADMINISTER_SETTING_ROLE_PERMISSION'
        ]"
        data-bs-toggle="collapse"
        class="collapse-menu"
        href="#manageSetting"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        <li>
          <div class="d-flex">
            <img src="./assets/images/icon-settings.png" alt="" />
            System Settings
          </div>
          <div class="icon-collapse">
            <img src="./assets/images/chevron-down.svg" alt="" />
          </div>
        </li>
      </a>
      <div class="collapse submenu sub-submenu" id="manageSetting">
        <ul>
          <a
            *ngxPermissionsOnly="[
              'SUPER_ADMIN',
              'ADMINISTER_SETTING_TAHUN_AJARAN'
            ]"
            [routerLink]="'./settings/tahunajaran'"
          >
            <li>Tahun Ajaran</li>
          </a>
          <a
            *ngxPermissionsOnly="[
              'SUPER_ADMIN',
              'ADMINISTER_SETTING_PENDAFTARAN'
            ]"
            data-bs-toggle="collapse"
            class="collapse-menu"
            href="#managePendaftaranr"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <li>
              <div class="d-flex">Pendafataran</div>
              <div class="icon-collapse">
                <img src="./assets/images/chevron-down.svg" alt="" />
              </div>
            </li>
          </a>
          <div class="collapse sub-submenu" id="managePendaftaranr">
            <ul>
              <a
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'ADMINISTER_SETTING_PENDAFTARAN'
                ]"
                [routerLink]="'./settings/gelombangpendaftaran'"
              >
                <li>Gelombang</li>
              </a>
              <a
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'ADMINISTER_SETTING_PENDAFTARAN'
                ]"
                [routerLink]="'./settings/contentregis'"
              >
                <li>Content</li>
              </a>
              <a
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'ADMINISTER_SETTING_PENDAFTARAN'
                ]"
                [routerLink]="'./settings/berkas'"
              >
                <li>Berkas</li>
              </a>
              <a
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'ADMINISTER_SETTING_PENDAFTARAN'
                ]"
                [routerLink]="'./settings/lokasiujian'"
              >
                <li>Lokasi Ujian</li>
              </a>
              <a
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'ADMINISTER_SETTING_PENDAFTARAN'
                ]"
                [routerLink]="'./settings/matauji'"
              >
                <li>Materi Tes</li>
              </a>
              <a
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'ADMINISTER_SETTING_PENDAFTARAN'
                ]"
                [routerLink]="'./settings/dokumen'"
              >
                <li>Dokumen</li>
              </a>
            </ul>
          </div>
  
          <a
            *ngxPermissionsOnly="[
              'SUPER_ADMIN',
              'ADMINISTER_SETTING_INSYA_DAILY'
            ]"
            [routerLink]="'./settings/insyadaily'"
            routerLinkActive="active"
          >
            <li>Insya/Daily</li>
          </a>
  
          <a
            *ngxPermissionsOnly="[
              'SUPER_ADMIN',
              'ADMINISTER_SETTING_ROLE_PERMISSION'
            ]"
            [routerLink]="'./settings/role'"
            routerLinkActive="active"
          >
            <li>Roles Permissions</li>
          </a>
        </ul>
      </div>
    </ul>
  </div>  