<div class="tabs-title">Monitoring</div>

<div class="col-12 col-lg-12">
  <div class="card">
      
      <div class="card-body">
          <form [formGroup]="formMonitoringAbsensi">
              <div class="row">
                  <div class="col-12 col-lg-12">
                    
                    <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="name" class="form-label">Tanggal</label>
                            </div>
                            <div class="col-10">
                              <input
                                type="text"
                                autocomplete="off"
                                id="start_date"
                                (blur)="changeStartDateField($event)"                                  
                                formControlName="tanggal"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f['tanggal'].errors }"
                                placeholder=""
                              />
                            </div>
                        </div>
                    </div>
                    
                    <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="nik" class="form-label">Jenjang<span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-10">                                
                              <select (change)="changeTingkat($event)"
                                formControlName="id_tingkat"
                                [ngClass]="{ 'is-invalid': submitted && f['id_tingkat'].errors }" 
                                class="form-select" name="id_tingkat" id="id_tingkat">
                                <option value="">Pilih Tingkat</option>
                                <option [value]="data.id" *ngFor="let data of listsTingkat">{{data.nama_tingkat}}</option>
                              </select>
                            </div>
                        </div>
                    </div>
                                    
                  </div>
                  
              </div>
          </form>
      </div>
  </div>
</div>

<div class="list-options mt-4">
  <button class="btn btn-blue-grey" (click)="onSubmit()">
    + Tampil
  </button>
</div>

<div class="table-wrapper">
  <!-- <div class="table-header">
      Rekap Pembayaran Formulir Per Lembaga
  </div> -->
  <div class="table-options mb-2 mt-4">
    
    <div class="show-table">

    </div>
  </div>
  <div class="table-responsive">
      <table class="table">
          <thead class="bordered">
              <tr class="up">
                  <th rowspan="2">Kelas</th>
                  <th [colSpan]="numOfJamke">Jam Pelajaran Ke-</th>
              </tr>
              <tr class="below">
                  <th *ngFor="let jamke of listJamke">{{ jamke }}</th>
                  
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let key of listMonitoring">
                  <td *ngFor="let key2 of key; let indexOfelement=index;" 
                    [ngClass]="{ 'alert-danger': key2.completed === null || key2.completed === false, 'alert-blue': key2.is_replacement === true || key2.completed === true }">
                    <div *ngIf="indexOfelement === 0">
                      {{ key[indexOfelement] }}

                    </div>
                    
                    <div *ngIf="indexOfelement !== 0">
                      {{ key2.guru }}
                      <span *ngIf="key2.pengganti !== '' && key2.pengganti !== null && (key2.is_replacement === null || key2.is_replacement === true)"><br />{{ key2.pengganti }} (P)</span>
                      <span *ngIf="key2.keterangan !== '' && key2.keterangan !== null"><br /> Izin : {{ key2.keterangan }}</span>
                    </div>
                  </td>                      
              </tr>
              
          </tbody>
      </table>

  </div>
</div>
