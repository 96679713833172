import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { JadwalPelajaranService } from 'src/app/services/jadwal-pelajaran.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { KelasService } from 'src/app/services/kelas.service';
import { CommonService } from 'src/app/helper/common.service';

@Component({
  selector: 'app-jadwal-mengajar-jadwal-guru',
  templateUrl: './jadwal-mengajar-jadwal-guru.component.html',
  styleUrls: ['./jadwal-mengajar-jadwal-guru.component.css']
})
export class JadwalMengajarJadwalGuruComponent implements OnInit {

  currentPeriodeId: any = null;
  currentSemester = "";
  listTahunajaran: any = [];
  listJadwalGuru: any = [];
  listsLembaga: any = [];
  listKelas: any = [];
  listSemester: any = [];
  indexListKelas: any = [];
  listJadwalPelajaran: any = [];
  errorMessage = "";
  searchTerm = "";
  pageid = "jadwal-guru";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  isShowLoader: Boolean = false;
  submitted: boolean = false;

  formAction: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
    id_semester: new FormControl(''),
  });

  Object = Object;

  constructor(
    private tahunajaranService: TahunajaranService,
    private uiService: UiService,
    private sharedDataService: SharedDataService,
    private jadwalpelajaranService: JadwalPelajaranService,
    private kelasService: KelasService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,


  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.initForm();
    this.getAllKelas();
    this.getDataTahunAjaran(); 
    this.listSemester = this.commonService.getListSemester();
  }

  async getDataTahunAjaran() {
    await this.getCurrentTahunajaran();
    await this.getAllTahunajaran();
  }

  initForm() {
    this.formAction = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        id_semester: ['', Validators.required],
      }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAction.controls;
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    this.currentPeriodeId = e.target.value;

    this.getAllJadwalPelajaran();
  }

  onSubmitfilterData() {
    this.submitted = true;
    console.log(JSON.stringify(this.formAction.value, null, 2));

    if (this.formAction.invalid) {
      return;
    }

    this.getAllJadwalPelajaran();
  }

  changeSemester(e: any) {
    this.currentSemester = e.target.value;

    this.getAllJadwalPelajaran();
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        this.currentPeriodeId = resdata.id;

        this.formAction.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);

        for (let i=0; i<resdata.Semester.length; i++) {
          const periodeSemester = this.commonService.getCurrentDateBetweenDateRange(resdata.Semester[i].start_date, resdata.Semester[i].end_date);

          if (periodeSemester) {
            this.currentSemester = resdata.Semester[i].semester;
            this.formAction.controls['id_semester'].setValue(this.currentSemester);
            
            this.getAllJadwalPelajaran();

            break;    
          }
        }        

        this.uiService.hideLoader();

        this.getAllTahunajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formAction.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllJadwalPelajaran() {

    this.uiService.showLoader();

    const payload = {
      periode_id: this.formAction.value.id_tahun_ajaran,
      semester: this.formAction.value.id_semester
    }

    console.log("PAYLOAD JDWAL", payload);
    
    
    this.jadwalpelajaranService.getListJadwalGuruByPeriodeSemester(payload).subscribe({
      next: resdata => {
        console.log("JADWAL : ", resdata);                        
        
        this.listJadwalGuru = resdata;
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  showDetailJadwalPelajaranKelas(object: any) {
    
    this.sharedDataService.changeDataObject(object);
    this.sharedDataService.changeComponent('jadwal-mengajar-perguru');
    this.uiService.showFullScrrenContent();
  }

  getAllKelas() {
    this.uiService.showLoader();

    const params = this.getRequestParams("", this.page, this.pageSize);

    this.kelasService.getAllkelas(params).subscribe({
      next: resdata => {
        console.log('kelas', resdata);
        const { data, totalrows } = resdata;

        this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listKelas = data;
        this.count = totalrows;

        console.log('COUNT', this.count);

      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  showDetailPenempatanKelas() {

  }

  search() {

  }

}
