import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WINDOW } from '../window.providers';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

let CORE_API: String = "";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  /*
  Instead of hardcoding we can use another environment file such as envirenoment.test.ts
  inside folder src/environment , the code is below :
    
    export const environment = {
      production: false,
      apiUrl: 'YOUR API URL',
      domain: YOUR_DOMAIN
    };

  and then in angular.json add theese line of codes inside configurations section:
    "test": {
      "buildOptimizer": false,
      "optimization": false,
      "vendorChunk": true,
      "extractLicenses": false,
      "sourceMap": true,
      "namedChunks": true,
      "fileReplacements": [
        {
          "replace": "src/environments/environment.ts",
          "with": "src/environments/environment.test.ts"
        }
      ],
    }

  run locally with this command:
    ng serve --host=dns.local-darunnajah --configuration=local
  
  */

  private API_URI = environment.apiUrl;
  private GENERAL_API_URL = environment.apiUrl + "/generalApi";

  constructor(@Inject(WINDOW) private window: Window, private http: HttpClient) { }

  private _getDomain(url: String) : String {

    const urlSplit = url.split(".");
    const subDomain = urlSplit.length > 0 ? urlSplit[0] : "";
    // avoid error in development env
    // we aleays use -dev suffix for development
    const finalSubDomain = subDomain.replace("-dev", "");
    const suffix = finalSubDomain.substring(3) !== "" ? finalSubDomain.substring(3) : "1";

    console.log(url, subDomain, suffix);
    

    let domain = environment.domain != "" ? environment.domain : "dn_" + suffix;

    return domain;
  }

  getCurrentDomain(): String {
    const hostname = this.window.location.hostname;
    const domain = this._getDomain(hostname);

    return domain;
  }

  setUrlApi(): void {
    const hostname = this.window.location.hostname;
    const domain = this._getDomain(hostname);

    let CURRENT_END_URL_API: string = this.API_URI + "/" + domain + "/coreApi";
    localStorage.setItem("CORE_API_URL", CURRENT_END_URL_API);
  }

  getUrlApi(): any {
    return localStorage.getItem("CORE_API_URL");
  }

  postRequest(payload: any, url: string) {
    CORE_API = this.getUrlApi();
    return this.http.post(CORE_API + url, payload, httpOptions);
  }

  fileRequest(formData: FormData, url: string) {
    CORE_API = this.getUrlApi();
    //return this.http.post(CORE_API + url, payload, httpOptions);
    const req = new HttpRequest('POST', CORE_API + url, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  putRequest(payload: any, url: string) {
    CORE_API = this.getUrlApi();
    return this.http.put(CORE_API + url, payload, httpOptions);
  }

  deleteRequest(url: string) {
    CORE_API = this.getUrlApi();
    console.log('DELETE', CORE_API + url);
    return this.http.delete(CORE_API + url, httpOptions);
  }

  getRequest(url: string) {
    CORE_API = this.getUrlApi();
    return this.http.get(CORE_API + url, httpOptions);
  }

  getAllRequest(url: string, params: any) {
    CORE_API = this.getUrlApi();
    return this.http.get(CORE_API + url, { 'params': params, headers: httpOptions.headers, withCredentials: false } );
  }

  postGeneralRequest(payload: any, url: string) {
    return this.http.post(this.GENERAL_API_URL + url, payload, httpOptions);
  }

  putGeneralRequest(payload: any, url: string) {
    return this.http.put(this.GENERAL_API_URL + url, payload, httpOptions);
  }

  getGeneralRequest(url: string, params: any) {
    return this.http.get(this.GENERAL_API_URL + url, { 'params': params, headers: httpOptions.headers, observe: 'body', withCredentials: false });
  }
}
