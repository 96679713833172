<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="content-item mb-5">
      <div class="back-button" [routerLink]="['/settings/role/list/']">
        <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
      </div>
      <div class="item-header">
        ADD Role
      </div>
      
      <div class="card">
        <div class="card-header">
          DATA ROLE
        </div>
        <div class="card-body">
            
                <div class="row">                    
                    <div class="col-12 col-lg-7">
                        <div class="row">
                          <div class="col-12 mb-3">
                            <label for="saudara" class="form-label">Nama Role</label>
                            <input
                              type="text"
                              formControlName="role_name"
                              class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f['role_name'].errors }"
                              placeholder=""
                            />
                          </div>
                        </div>
                    </div>
                </div>
            
        </div>
      </div>
    
      <div class="row align-items-center">
          <div class="col-6 col-lg-4 offset-lg-6">
              
          </div>
          <div class="col-6 col-lg-2 d-flex justify-content-end">
              <button type="submit" class="btn btn-primary">Save</button>
          </div>
      </div>
    </div>
  </form>
  