import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

declare var feather: any;

@Component({
  selector: 'app-main-app',
  templateUrl: './main-app.component.html',
  styleUrls: ['./main-app.component.css']
})
export class MainAppComponent implements OnInit {

  sidebarToggleClass: boolean = true;
  contentToggleClass: boolean = true;
  isFullScreen: boolean = false;
  fullscreenComponent: any = null;

  IDDLE_TIMEOUT: number = 600; // 10 minutes

  realname: String = "";
  userPic: String = "";
  instansi: any = "";
  schoolType: any = "";

  constructor(private uiService: UiService,
    private sharedDataService: SharedDataService, 
    private bnIdle: BnNgIdleService, private router: Router, private auth: AuthService) { 
    this.changeBodyClass();
    this.showRealNameAndPic();
  }

  ngOnInit(): void {
    this.auth.sessionLoginExpiration();
    feather.replace();

    this.uiService.showHideFeatherIcon();
    this.uiService.triggerOnScreenResize();
    this.uiService.displayWindowSize();

    this.instansi = localStorage.getItem('instansi')?.toString();
    this.schoolType = localStorage.getItem('schoolType')?.toString();
    //this.isFullScreen = this.uiService.isFullScreen();
    this.bnIdle.startWatching(this.IDDLE_TIMEOUT).subscribe((isTimedOut: boolean) => {
      if (isTimedOut && this.auth.isUserLoggedIn()) {
        console.log('session expired');
        this.logout();
        window.location.reload();
      } 
    });

    this.sharedDataService.currentComponent.subscribe(data => {
      (this.fullscreenComponent = data)
    })
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.remove("login");
    }
  }  

  hideShowSidebarMenu() {
    this.sidebarToggleClass = !this.sidebarToggleClass;
    this.contentToggleClass = !this.contentToggleClass;
  }

  logout(): void {
    localStorage.clear();
    //window.location.reload();
  }

  fullScreenDetect() {
    this.uiService.toggleFullscreen();
  }

  showRealNameAndPic() {
    const currentUser = localStorage.getItem('user')?.toString();
    const jsonUser = currentUser ? JSON.parse(currentUser) : ""; 

    console.log(currentUser);
    this.realname = jsonUser ? jsonUser.proguser.userProfile.real_name : "";
    this.userPic = jsonUser ? jsonUser.proguser.userProfile.photo : "";

    console.log("USERPIC", this.userPic);

  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/user.png';
  }

  closeButton() {
    this.uiService.hideFullScrrenContent();
  }

}
