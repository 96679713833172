import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { GeneralService } from 'src/app/services/general.service';
import { SiswaService } from 'src/app/services/siswa.service';
import { HttpParams } from '@angular/common/http';
import { TingkatService } from 'src/app/services/tingkat.service';
import { LembagaService } from 'src/app/services/lembaga.service';
import { TahunAjaranService } from 'src/app/services/tahun-ajaran.service';
import { LokasiujianService } from 'src/app/services/lokasiujian.service';
import { CommonService } from 'src/app/helper/common.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { GelombangPendafataranService } from 'src/app/services/gelombang-pendafataran.service';

declare var Datepicker: any;

@Component({
  selector: 'app-siswa-add-edit',
  templateUrl: './siswa-add-edit.component.html',
  styleUrls: ['./siswa-add-edit.component.css']
})
export class SiswaAddEditComponent implements OnInit {

  showFullForm: Boolean = false;
  listsLembaga: any = [];
  listsTingkat: any = [];
  listTahunLulus: any = [];
  listsTahunAjaran: any = [];
  listLokasiUjian: any = [];
  listTanggalUjian: any = [];
  listsProvinsi: any = [];
  listsKabupaten: any = [];
  listsKecamatan: any = [];
  listsKelurahan: any = [];
  listsRoles: any = [];
  errorMessage = '';
  tahunAjaranActive = "";
  tanggalUjian:any = null;
  isAddMode: Boolean = false;
  id: string = '';
  siswaEditData: any;

  form: FormGroup = new FormGroup({
    regnumber: new FormControl(''),
    nama_lengkap: new FormControl(''),
    nama_arab: new FormControl(''),
    gender: new FormControl(''),
    nama_ayah: new FormControl(''),
    nama_ibu: new FormControl(''),
    tahun_masuk: new FormControl(''),
    lembaga_tujuan: new FormControl(''),
    tingkat_id: new FormControl(''),
    username: new FormControl(''),
    password: new FormControl(''),
    nik: new FormControl(''),
    ukuran_baju: new FormControl(''),
    pob: new FormControl(''),
    dob: new FormControl(''),
    alamat: new FormControl(''),
    provinsi: new FormControl(''),
    kota_kab: new FormControl(''),
    kecamatan: new FormControl(''),
    desa_kelurahan: new FormControl(''),
    rt: new FormControl(''),
    rw: new FormControl(''),
    telepon: new FormControl(''),
    admin_input: new FormControl(''),

    nisn: new FormControl(''),
    npsn: new FormControl(''),
    nss: new FormControl(''),
    no_ijazah: new FormControl(''),
    tempat_ijazah: new FormControl(''),
    tahun_lulus: new FormControl(''),
    alamat_sekolah_asal: new FormControl(''),
    anak_ke: new FormControl(''),
    jumlah_anak: new FormControl(''),
    riwayat_penyakit: new FormControl(''),
    berat_badan: new FormControl(''),
    tinggi_badan: new FormControl(''),
    golongan_darah: new FormControl(''),
    hoby: new FormControl(''),
    email: new FormControl(''),
    saudara_kandung_aktif: new FormControl(''),
    sumber_info_sekolah: new FormControl(''),
    inputTglLulus: new FormControl(''),
    inputBulanLulus: new FormControl(''),
    inputTahunLulus: new FormControl(''),
    asal_sekolah: new FormControl(''),
    nama_sekolah_asal: new FormControl(''),
    tanggal_lulus: new FormControl(''),
    // Ayah
    no_kk: new FormControl(''),
    nik_ayah: new FormControl(''),
    pekerjaan_ayah: new FormControl(''),
    status_ayah: new FormControl(''),
    pob_ayah: new FormControl(''),
    dob_ayah: new FormControl(''),
    inputTglAyah: new FormControl(''),
    inputBulanAyah: new FormControl(''),
    inputTahunAyah: new FormControl(''),
    gelar_akademik_ayah: new FormControl(''),
    status_hidup_ayah: new FormControl(''), //DataTypes.ENUM("HIDUP","MENINGGAL"),
    pendidikan_terkahir_ayah: new FormControl(''),
    penghasilan_perbulan_ayah: new FormControl(''),
    telp_ayah: new FormControl(''),
    alamat_ayah: new FormControl(''),
    //Ibu
    nik_ibu: new FormControl(''),
    status_ibu: new FormControl(''),
    pob_ibu: new FormControl(''),
    dob_ibu: new FormControl(''),
    inputTglIbu: new FormControl(''),
    inputBulanIbu: new FormControl(''),
    inputTahunIbu: new FormControl(''),
    gelar_akademik_ibu: new FormControl(''),
    status_hidup_ibu: new FormControl(''), //DataTypes.ENUM("HIDUP","MENINGGAL"),
    pendidikan_terkahir_ibu: new FormControl(''),
    pekerjaan_ibu: new FormControl(''),
    penghasilan_perbulan_ibu: new FormControl(''),
    telp_ibu: new FormControl(''),
    alamat_ibu: new FormControl(''),

    //ALamat
    lokasi_ujian: new FormControl(''),
    tanggal_ujian: new FormControl(''),

    // penanggung
    yang_menanggung_biaya: new FormControl(''),
    nama_penanggung_biaya: new FormControl(''),
    hubungan_keluarga_penanggung_biaya: new FormControl(''),
    telepon_penanggung_biaya: new FormControl(''),
    pekerjaan_penanggung_biaya: new FormControl(''),
    alamat_penanggung_biaya: new FormControl(''),
    kewarganegaraan: new FormControl(''),
    negara: new FormControl('')
  });
  submitted = false;

  constructor(private formBuilder: FormBuilder, 
    private uiService: UiService,
    private commonService: CommonService,
    private tahunajaranService: TahunajaranService,
    private lokasiUjianService: LokasiujianService, 
    private siswaService: SiswaService,
    private tingkatService: TingkatService,
    private lembagaService: LembagaService,
    private tahunAjaranService: TahunAjaranService,
    private gelombangPendaftaranService: GelombangPendafataranService,
    private route: ActivatedRoute,
    private router: Router,
    private generalService: GeneralService) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['siswaId'];
    this.isAddMode = !this.id;

    this.getAllLokasiUjian();
    
    
    if (!this.isAddMode) {
      this.getSiswaEdit();
    } else {
      this.getProvinsi();
    }
    this.getLembaga();
    this.getTahunAjaran();
    this.getTahunAjaranActive();
    this.configureDatePicker();
    
    
    for (let i=1970; i<2050; i++) {
      this.listTahunLulus.push(i.toString());
    }
    
    console.log("TAHUN LULUS", this.listTahunLulus);
    
    this.initForm();

  }

  configureDatePicker() {
    const elem = document.getElementById('birtdhdate');
    const datepicker = new Datepicker(elem, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    }); 
  }

  changeDobField(e: any) {
    console.log(e.target.value);
    const birthdate = e.target.value;
    this.form.controls['dob'].setValue(birthdate);
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru");
    }
  }

  onBack() {
    this.router.navigate(['/siswa/list']);
  }

  onShowFullFormChanged(e: any) {
    if (e.target.checked) {
      this.showFullForm = true;
    } else{
      this.showFullForm = false;
    }
  }

  getPpsbTahunajaran(ta: string) {
    this.uiService.showLoader();

    this.gelombangPendaftaranService.getByTahunAjaran(ta).subscribe({
      next: resdata => {
        console.log('current gelombang', resdata);
        // parse list tanggal ujian

        this.listTanggalUjian = [];

        const currentSiswaTanggalUjian = this.commonService.convertDate(this.siswaEditData.tanggal_ujian);

        for (let i=0; i<resdata.length; i++) {
          const fmtDate = this.commonService.convertDate(resdata[i].ujian_tulis_date);
          this.listTanggalUjian.push(fmtDate);
        }

        if (!this.listTanggalUjian.includes(currentSiswaTanggalUjian)) {
          this.listTanggalUjian.push(currentSiswaTanggalUjian);
        }
        // this.tanggalUjian = this.commonService.convertDate(resdata.ujian_tulis_date);;

        this.form.controls['tanggal_ujian'].setValue(currentSiswaTanggalUjian);
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getLembaga() {
    this.uiService.showLoader();

    this.lembagaService.getAllLembaga('').subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsLembaga = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getTingkat() {
    this.uiService.showLoader();

    this.generalService.getAllProvinsi().subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsTingkat = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getTahunAjaran() {
    this.uiService.showLoader();

    this.tahunAjaranService.getAllTahunAjaran('').subscribe({
      next: resdata => {
        console.log('TAHUN AJARAN', resdata);
        this.listsTahunAjaran = resdata.data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getSiswaEdit() {
    this.uiService.showLoader();

    this.siswaService.getById(this.id).subscribe({
      next: resdata => {
        console.log('SISWA EDIT', resdata);
        this.siswaEditData = resdata;
        
        const lembagaId = resdata.lembaga_tujuan;
        const tingkatId = resdata.tingkat_id;
        const provinsi = resdata.provinsi;
        const kabupaten = resdata.kota_kab;
        const kecamatan = resdata.kecamatan;
        const kelurahan = resdata.desa_kelurahan;

        this.getPpsbTahunajaran(resdata.angkatan);
        this.getProvinsi();
        this.getTingkatLembaga(lembagaId, tingkatId);
        // this.getKabupatenProvinsi(provinsi, kabupaten);
        // this.getKecamatanKabupatenProvonsi(provinsi, kabupaten, kecamatan);
        // this.getKelurahanKecamatanKabupatenProvinsi(provinsi, kabupaten, kecamatan, kelurahan);
        resdata.tahun_lulus = resdata.tahun_lulus === null ? "" : resdata.tahun_lulus;
        this.form.patchValue(resdata)

        // parse DOB
        const dob = this.commonService.convertDate(resdata.dob);
        this.form.controls['dob'].setValue(dob);
        const dobAyah = this.commonService.convertDate(resdata.dob_ayah);
        this.form.controls['dob_ayah'].setValue(dobAyah);
        const dobIbu = this.commonService.convertDate(resdata.dob_ibu);
        this.form.controls['dob_ibu'].setValue(dobIbu);
        
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getTahunAjaranActive() {
    this.uiService.showLoader();

    this.tahunAjaranService.getTahunAjaranActive().subscribe({
      next: resdata => {
        console.log(resdata);
        this.tahunAjaranActive = resdata !== null ? resdata.name : "";
        this.form.controls['tahun_masuk'].setValue(this.tahunAjaranActive);
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllLokasiUjian() {
    this.uiService.showLoader();

    const params = {};

    this.lokasiUjianService.getAll(params).subscribe({
      next: resdata => {
        console.log('lokasi ujian', resdata);
        
        this.listLokasiUjian = resdata.data;
        
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getProvinsi() {
    this.uiService.showLoader();

    this.generalService.getAllProvinsi().subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsProvinsi = resdata;
        
        if (!this.isAddMode) {
          const provinsi = this.siswaEditData.provinsi;
          const kabupaten = this.siswaEditData.kota_kab;
          
          this.form.controls['provinsi'].setValue(provinsi);
          this.getKabupatenProvinsi(provinsi, kabupaten);
        }

        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeLembaga(e: any) {
    console.log(e.target.value);
    const lembagaId = e.target.value;
    this.getTingkatLembaga(lembagaId);
  }
  
  private getTingkatLembaga(lembagaId: any, curentTingkat: any = null) {
    this.uiService.showLoader();
  
    this.tingkatService.getTingkatByLembagaId(lembagaId).subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsTingkat = resdata;
        if (curentTingkat !== null) {
          this.form.controls['tingkat_id'].setValue(curentTingkat);
        } else {
          this.form.controls['tingkat_id'].setValue('');
        }
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeKabupaten(e: any) {
    console.log(e.target.value);
    this.getKabupatenProvinsi(e.target.value);
  }
  
  private getKabupatenProvinsi(provinsi: any, curentKabupaten: any = null) {
    let params = new HttpParams();
    params = params.append("provinsi", provinsi);
    
    this.uiService.showLoader();
    
    this.generalService.getKabupatenByProvinsi(params).subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsKecamatan = [];
        this.listsKelurahan = [];
        this.listsKabupaten = resdata;
        console.log('value', this.form.value.kota_kab);
        if (curentKabupaten !== null) {
          this.form.controls['kota_kab'].setValue(curentKabupaten);
          this.getKecamatanKabupatenProvonsi(provinsi, curentKabupaten, this.siswaEditData.kecamatan);
        } else {
          this.form.controls['kota_kab'].setValue('');
        }
        this.form.controls['kecamatan'].setValue('');
        this.form.controls['desa_kelurahan'].setValue('');
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeKecamatan(e: any) {
    console.log(e.target.value);
    const provinsi = this.form.value.provinsi;
    const kabupaten = e.target.value;

    this.getKecamatanKabupatenProvonsi(provinsi, kabupaten);
  }
  
  private getKecamatanKabupatenProvonsi(provinsi: any, kabupaten: any, kecamatan: any = null) {
    this.uiService.showLoader();
  
    let params = new HttpParams();
    
    params = params.append("provinsi", provinsi);
    params = params.append("kabupaten", kabupaten);
  
    this.generalService.getKecamatanByKabupatenProvinsi(params).subscribe({
      next: resdata => {
        console.log('kecamatan', resdata);
        this.listsKelurahan = [];
        if (kecamatan !== null) {
          this.form.controls['kecamatan'].setValue(kecamatan);
          this.getKelurahanKecamatanKabupatenProvinsi(provinsi, kabupaten, kecamatan, this.siswaEditData.desa_kelurahan);
        } else {
          this.form.controls['kecamatan'].setValue('');
        }
        this.form.controls['desa_kelurahan'].setValue('');
        this.listsKecamatan = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });

  }

  changeKelurahan(e: any) {
    console.log(e.target.value);
    const provinsi = this.form.value.provinsi;
    const kabupaten = this.form.value.kota_kab;
    const kecamatan = e.target.value;

    this.getKelurahanKecamatanKabupatenProvinsi(provinsi,kabupaten,kecamatan);
  }
  
  private getKelurahanKecamatanKabupatenProvinsi(provinsi: any, kabupaten: any, kecamatan: any, kelurahan: any = null) {
    this.uiService.showLoader();
  
    let params = new HttpParams();
  
    params = params.append("provinsi", provinsi);
    params = params.append("kabupaten", kabupaten);
    params = params.append("kecamatan", kecamatan);
  
    this.generalService.getKelurahanByKecamatanKabupatenProvinsi(params).subscribe({
      next: resdata => {
        this.listsKelurahan = resdata;
        console.log('kelurahan', resdata);
        if (kelurahan !== null) {
          this.form.controls['desa_kelurahan'].setValue(kelurahan);
        } else {
          this.form.controls['desa_kelurahan'].setValue('');
        }
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }
  
  initForm() {
    this.form = this.formBuilder.group(
      {
        regnumber: [''],
        nama_lengkap: ['', Validators.required],
        nama_arab: [''],
        gender: ['', Validators.required],
        nama_ayah: ['', Validators.required],
        nama_ibu: ['', Validators.required],
        tahun_masuk: ['', Validators.required],
        lembaga_tujuan: ['', Validators.required],
        tingkat_id: ['', Validators.required],
        username: [''],
        password: [''],
        nik: [''],
        ukuran_baju: ['', Validators.required],
        pob: ['', Validators.required],
        dob: ['', Validators.required],
        alamat: ['', Validators.required],
        provinsi: ['', Validators.required],
        kota_kab: ['', Validators.required],
        kecamatan: ['', Validators.required],
        desa_kelurahan: ['', Validators.required],
        rt: ['', Validators.required],
        rw: ['', Validators.required],
        telepon: ['', Validators.required],
        admin_input: [''],
        nisn: ['', [Validators.required]],
        npsn: ['', Validators.required],
        nss: [''],
        no_ijazah: [''],
        tempat_ijazah: [''],
        tahun_lulus: [''],
        alamat_sekolah_asal: [''],
        anak_ke: ['', Validators.required],
        jumlah_anak: ['', Validators.required],
        riwayat_penyakit: [''],
        berat_badan: [''],
        tinggi_badan: [''],
        golongan_darah: ['', Validators.required],
        hoby: [''],
        email: ['', Validators.required],
        saudara_kandung_aktif: [''],
        sumber_info_sekolah: [''],
        inputTglLulus: [''],
        inputBulanLulus: [''],
        inputTahunLulus: [''],
        asal_sekolah: ['', Validators.required],
        nama_sekolah_asal: ['', Validators.required],
        tanggal_lulus: [''], // :TODO tanggal_lulus: ['', Validators.required],
        // Ayah
        no_kk: ['', Validators.required],
        nik_ayah: ['', Validators.required],
        pekerjaan_ayah: ['', Validators.required],
        status_ayah: ['', Validators.required],
        pob_ayah: ['', Validators.required],
        inputTglAyah: [''],
        inputBulanAyah: [''],
        inputTahunAyah: [''],
        dob_ayah: [''], // TODO dob_ayah: ['', Validators.required],
        gelar_akademik_ayah: ['', Validators.required],
        status_hidup_ayah: ['', Validators.required], //DataTypes.ENUM("HIDUP","MENINGGAL"),
        pendidikan_terkahir_ayah: ['', Validators.required],
        penghasilan_perbulan_ayah: ['', Validators.required],
        telp_ayah: ['', Validators.required],
        alamat_ayah: ['', Validators.required],
        //Ibu
        nik_ibu: ['', Validators.required],
        status_ibu: ['', Validators.required],
        pob_ibu: ['', Validators.required],
        dob_ibu: [''], // TODO dob_ibu: ['', Validators.required],
        inputTglIbu: [''],
        inputBulanIbu: [''],
        inputTahunIbu: [''],
        gelar_akademik_ibu: ['', Validators.required],
        status_hidup_ibu: ['', Validators.required], //DataTypes.ENUM("HIDUP","MENINGGAL"),
        pendidikan_terkahir_ibu: ['', Validators.required],
        pekerjaan_ibu: ['', Validators.required],
        penghasilan_perbulan_ibu: ['', Validators.required],
        telp_ibu: ['', Validators.required],
        alamat_ibu: ['', Validators.required],
        // Alamat
        lokasi_ujian: [''],
        tanggal_ujian: [''],

        // penanggung
        yang_menanggung_biaya: ['', Validators.required],
        nama_penanggung_biaya: ['', Validators.required],
        hubungan_keluarga_penanggung_biaya: ['', Validators.required],
        telepon_penanggung_biaya: ['', Validators.required],
        pekerjaan_penanggung_biaya: ['', Validators.required],
        alamat_penanggung_biaya: ['', Validators.required],
        kewarganegaraan: [''],
        negara: ['']
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    
    console.log(JSON.stringify(this.form.value, null, 2));
    
    if (this.form.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.form.controls['admin_input'].setValue(true);    

    if (this.isAddMode) {
      this.createSiswa();
    } else {
      this.updateSiswa();
    }

  }

  private createSiswa() {
    this.uiService.showLoader();
    this.siswaService.addNewStudent(this.form.value).subscribe({
      next: resdata => {
        console.log(resdata);
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
      
        this.router.navigate(['/siswa/list']);

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  private updateSiswa() {
    this.uiService.showLoader();
    this.siswaService.updateSiswa(this.id, this.form.value).subscribe({
      next: resdata => {
        console.log(resdata);
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
      
        this.router.navigate(['/siswa/list']);

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

}
