import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-classroom-nav',
  templateUrl: './classroom-nav.component.html',
  styleUrls: ['./classroom-nav.component.css']
})
export class ClassroomNavComponent implements OnInit {

  isMonitoringSiswa = false
  isMonitoringGuru = false
  
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.changeTab(this.router.url)
  }

  changeTab(url: String) {
    switch (url) {
      case '/pendidikan/monitoring-siswa':
        this.isMonitoringSiswa = true
        this.isMonitoringGuru = false
        return;
      case '/pendidikan/monitoring-guru':
        this.isMonitoringGuru = true
        this.isMonitoringSiswa = false
        return;
      default:
        this.isMonitoringSiswa = false
        this.isMonitoringGuru = false
        return;
    }
  }

}
