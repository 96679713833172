import { Component, OnInit } from '@angular/core';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/helper/common.service';
import { ExcelService } from 'src/app/services/excel.service';
import { KelasService } from 'src/app/services/kelas.service';
import { NilaiHarianService } from 'src/app/services/nilai-harian.service';

@Component({
  selector: 'app-laporan-insya-daily',
  templateUrl: './laporan-insya-daily.component.html',
  styleUrls: ['./laporan-insya-daily.component.css']
})
export class LaporanInsyaDailyComponent implements OnInit {

  listSemester: any = [];
  listTahunajaran: any = [];
  listNilaiAlquran: any = [];
  listKelas: any = [];
  listTugasKe: any = [];
  currentPeriodeId: any = null;
  dataToUpload: any = null;
  pageid = "upload-jadwal-mengajar";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  errorMessage = "";
  currentDate = "";
  submitted = false;
  listMonth: any = [];
  currentKelasId: any = null;
  currentSemester = "";
  listJenisLaporan = [
    "Laporan Nilai Al-Quran",
    "Laporan Insya/Daily"
  ]
  currentJenisLaporan = "Laporan Nilai Al-Quran"
  
  formDownloadLaporan: FormGroup = new FormGroup({
    jenis_laporan: new FormControl(''),
    id_tahun_ajaran: new FormControl(''),    
    id_semester: new FormControl(''),
    id_kelas: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private tahunajaranService: TahunajaranService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private kelasService: KelasService,
    private excelService: ExcelService,
    private nilaiharianService: NilaiHarianService,
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getKelas();
    this.listMonth = this.commonService.getListMonth();
    this.listTugasKe = this.commonService.getListTugasKe();    
    this.getCurrentTahunajaran();
    this.listSemester = this.commonService.getListSemester();
    this.initForm();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {
    this.formDownloadLaporan = this.formBuilder.group(
      {
        jenis_laporan: ['Laporan Nilai Al-Quran', Validators.required],
        id_tahun_ajaran: ['', Validators.required],
        id_semester: ['', Validators.required],
        id_kelas: ['', Validators.required],
      },
    );
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formDownloadLaporan.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;
        
        for (let i=0; i<resdata.Semester.length; i++) {
          const periodeSemester = this.commonService.getCurrentDateBetweenDateRange(resdata.Semester[i].start_date, resdata.Semester[i].end_date);

          if (periodeSemester) {
            this.currentSemester = resdata.Semester[i].semester;
            this.formDownloadLaporan.controls['id_semester'].setValue(this.currentSemester);
            // this.formInputNilaiDailyComposition.controls['kelas_id'].enable();
            
            // this.getDayPeriod();          
            
            break;    
          }
        }

        this.uiService.hideLoader();

        this.getAllTahunajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }


  onSubmitformDownloadLaporan() {
    this.submitted = true;
    console.log(JSON.stringify(this.formDownloadLaporan.value, null, 2));

    if (this.formDownloadLaporan.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    if (this.formDownloadLaporan.value.jenis_laporan === "Laporan Insya/Daily") {
      this.generateLaporanInsyaDaily();
    } else if (this.formDownloadLaporan.value.jenis_laporan === "Laporan Nilai Al-Quran") {
      this.generateLaporanNilaiQuran();
    }
  }

  generateLaporanNilaiQuran() {
    this.uiService.showLoader();

    this.nilaiharianService.getLaporanNilaiQuran(this.formDownloadLaporan.value).subscribe({
      next: resdata => {
        console.log("REPORT", resdata);

        if (resdata !== null) {
          // get num of siswa
          const totalSiswa = resdata[0].NilaiQuranSiswa.length;
          console.log("jmlah siswa", totalSiswa);

          // 2d array is row x coll = totalSiswa x 15
          let rowCols = [];
          for (let i=0; i<totalSiswa; i++) {
            rowCols[i] = new Array(15)
          }

          console.log("2D array", rowCols);
          
          
          const listBulan = this.commonService.getListMonthTahunAjaran();


          for (const n in resdata) {
            const idxBln = listBulan.indexOf(resdata[n].bulan) + 3;
            // console.log("data ke", n, "bulan ke", idxBln, resdata[n]);

            
            for (const r in resdata[n].NilaiQuranSiswa) {
              console.log("data ke", n, r, resdata[n].NilaiQuranSiswa[r]);
              
              rowCols[parseInt(r)][idxBln] = parseInt(resdata[n].NilaiQuranSiswa[r].nilai) === 0 ? resdata[n].NilaiQuranSiswa[r].nilai + ", " + resdata[n].NilaiQuranSiswa[r].keterangan : resdata[n].NilaiQuranSiswa[r].nilai

              // parsing NO, NO_INDUK, NAMA
              if (parseInt(n) === 0) {
                rowCols[parseInt(r)][0] = parseInt(r) + 1;
                rowCols[parseInt(r)][1] = resdata[n].NilaiQuranSiswa[r].Siswa.no_induk;
                rowCols[parseInt(r)][2] = resdata[n].NilaiQuranSiswa[r].Siswa.nama_lengkap;
              }
            }            

          }
          console.log("NEW 2D array", rowCols);

          console.log("nilai 0, 7", rowCols[0][7]);
          
          let data = [];

          for (let i=0; i<totalSiswa; i++) {
            const row = {
              NO: rowCols[i][0],
              NO_INDUK: rowCols[i][1],
              NAMA: rowCols[i][2],              
              Juli: rowCols[i][3],
              Agustus: rowCols[i][4],
              September: rowCols[i][5],
              Oktober: rowCols[i][6],
              November: rowCols[i][7],
              Desember: rowCols[i][8],
              Januari: rowCols[i][9],
              Februari: rowCols[i][10],
              Maret: rowCols[i][11],
              April: rowCols[i][12],
              Mei: rowCols[i][13],
              Juni: rowCols[i][14]
            }

            data.push(row);
          }

          this.excelService.exportAsExcelFile(data, "Laporan NIlai Quran.xlsx");

        }
        
        // this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  generateLaporanInsyaDaily() {
    this.uiService.showLoader();

    this.nilaiharianService.getLaporanNilaiInsya(this.formDownloadLaporan.value).subscribe({
      next: resdata => {
        console.log("REPORT", resdata);

        if (resdata !== null) {
          // get num of siswa
          const totalSiswa = resdata[0].NilaiInsyaYaumiSiswa.length;
          console.log("jmlah siswa", totalSiswa);

          // 2d array is row x coll = totalSiswa x jumlah tugas + col total nilai + col jumlah tugas + col rata2
          // jumlah kol tugas = 8
          //  colum = No, Name, tugas ke (8), tonil, jumlah tugas, rata2 = 13 
          let rowCols: any = [];
          
          for (let i=0; i<totalSiswa+1; i++) {
            rowCols[i] = new Array(13)
          }
          
          // rowCols[0][0] = "NO";
          // rowCols[0][1] = "STUDENT NAME";

          // for (let s=0; s<8; s++) {
          //   rowCols[0][s+2] = s+1;
          // }
          // rowCols[0][10] = "TOTAL NILAI";
          // rowCols[0][11] = "JUMLAH TUGAS";
          // rowCols[0][12] = "RATA-RATA";

          // jumlah tugas
          const jumlahTugas = resdata.length;


          console.log("2D array", rowCols);

          let tugasKe = 0;
          for (const n in resdata) {
            console.log("TUGAS KE", resdata[n].tugas_ke);
            
            tugasKe = resdata[n].tugas_ke;
            
            for (const r in resdata[n].NilaiInsyaYaumiSiswa) {      
              const stIdx = parseInt(r);
              const nilaiSiswaTugasKe = parseFloat(resdata[n].NilaiInsyaYaumiSiswa[r].nilai);
              
              rowCols[stIdx][tugasKe+1] = nilaiSiswaTugasKe;
              
              console.log("NILAI TUGAS", rowCols[stIdx][10]);
              

              rowCols[stIdx][10] = rowCols[stIdx][10] === undefined ? nilaiSiswaTugasKe : rowCols[stIdx][10] + nilaiSiswaTugasKe;
              rowCols[stIdx][11] = jumlahTugas;
              // console.log("data ke", n, r, resdata[n].NilaiInsyaYaumiSiswa[r]);
              
              // rowCols[parseInt(r)][idxBln] = parseInt(resdata[n].NilaiInsyaYaumiSiswa[r].nilai) === 0 ? resdata[n].NilaiInsyaYaumiSiswa[r].nilai + ", " + resdata[n].NilaiInsyaYaumiSiswa[r].keterangan : resdata[n].NilaiInsyaYaumiSiswa[r].nilai

              // parsing NO, NO_INDUK, NAMA
              if (parseInt(n) === 0) {
                rowCols[stIdx][0] = parseInt(r) + 1;
                // rowCols[parseInt(stIdx)][1] = resdata[n].NilaiInsyaYaumiSiswa[r].Siswa.no_induk;
                rowCols[stIdx][1] = resdata[n].NilaiInsyaYaumiSiswa[r].Siswa.nama_lengkap;
              }
            }            
          }

          // hitung rata2
          for (const c in resdata[0].NilaiInsyaYaumiSiswa) {
            const stIdx = parseInt(c);
            rowCols[stIdx][12] = rowCols[stIdx][10] / rowCols[stIdx][11]; 
          }

          console.log("NEW 2D array", rowCols);

          // console.log("nilai 0, 7", rowCols[0][7]);
          
          let data = [];

          for (let i=0; i<totalSiswa; i++) {
            const row = {
              "NO": rowCols[i][0],
              "STUDENT NAME": rowCols[i][1],
              "Tugas-1": rowCols[i][2],              
              "Tugas-2": rowCols[i][3],
              "Tugas-3": rowCols[i][4],
              "Tugas-4": rowCols[i][5],
              "Tugas-5": rowCols[i][6],
              "Tugas-6": rowCols[i][7],
              "Tugas-7": rowCols[i][8],
              "Tugas-8": rowCols[i][9],
              "TOTAL NILAI": rowCols[i][10],
              "JUMLAH TUGAS": rowCols[i][11],
              "RATA-RATA": rowCols[i][12]
            }

            data.push(row);
          }

          console.log("FINAL DATA", data);
          

          this.excelService.exportAsExcelFile(data, "Laporan Nilai Insya.xlsx");

        }
        
        // this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onFileChange(evt: any) {
    const fdata: FileReader = this.excelService.convertExcelFileToJson(evt.target);

    fdata.onloadend = (e: any) => {
      // console.log("END OF ROAD", this.excelService.dataExcel);
      const fileUpload: any[] = this.excelService.dataExcel;
      const finalFileUpload = fileUpload.slice(1);

      if (finalFileUpload.length > 0) {
        let dataToUpload = [];
        for (const k in finalFileUpload) {
          const v = finalFileUpload[k];
          console.log(k, v);
          // 1. get periode_id
          // 2. get kelas_id
          // 3. get siswaid
          const column = [v[4],v[1],v[5]];
          dataToUpload.push(column)
        }
        console.log("DATA TO UPLOAD", dataToUpload);

        this.dataToUpload = dataToUpload;
        
        // const payload = {
        //   data: dataToUpload,
        //   idperiode: this.currentPeriodeId
        // }
        
        // this.siswakelasService.uploadSiswakelas(payload).subscribe({
        //   next: resdata => {
        //     console.log('RESPONSE', resdata);
    
        //     this.refreshListKelas();
            
        //     this.uiService.hideLoader();
    
        //   },
        //   error: err => {
        //     this.errorMessage = err.error.message;
        //     console.log(this.errorMessage);
    
        //     this.uiService.hideLoader();
    
        //     this.uiService.showError(this.errorMessage);
        //   }
        // });
      } 
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formDownloadLaporan.controls;
  }

  changeKelas(e: any) {
    this.currentKelasId = e.target.value;
  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeSemester(e: any) {
    this.currentSemester = e.target.value;
  }

  changeJenisLaporan(e: any) {
    console.log(e.target.value);
    
    this.currentJenisLaporan = e.target.value;
    if (this.currentJenisLaporan === "Laporan Nilai Al-Quran") {
      this.formDownloadLaporan.controls['id_semester'].clearValidators();
      this.formDownloadLaporan.controls['id_semester'].updateValueAndValidity();
    } else {
      this.formDownloadLaporan.controls['id_semester'].setValidators([Validators.required]);
      this.formDownloadLaporan.controls['id_semester'].updateValueAndValidity();
    }

  }

}
