import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-absensi-kamar',
  templateUrl: './nav-absensi-kamar.component.html',
  styleUrls: ['./nav-absensi-kamar.component.css']
})
export class NavAbsensiKamarComponent implements OnInit {

  isInputAbsensiKamar = false
  isListAbsensi = false
  isLaporanPerSIswa = false
  isLaporanPerKamar = false
  isMonitoringAbsesiKamar = false
  isMonitoringPenginput = false

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.changeTab(this.router.url)
  }

  changeTab(url: String) {
    switch (url) {
      case '/pengasuhan/absensi-kamar':
        this.isInputAbsensiKamar = true
        this.isListAbsensi = false
        this.isLaporanPerSIswa = false
        this.isLaporanPerKamar = false
        this.isMonitoringAbsesiKamar = false
        this.isMonitoringPenginput = false
        return;
      case '/pengasuhan/list-absensi-kamar':
        this.isListAbsensi = true
        this.isInputAbsensiKamar = false
        this.isLaporanPerSIswa = false
        this.isLaporanPerKamar = false
        this.isMonitoringAbsesiKamar = false
        this.isMonitoringPenginput = false
        return;
      case '/pengasuhan/laporan-persiswa':
        this.isLaporanPerSIswa = true
        this.isInputAbsensiKamar = false
        this.isListAbsensi = false
        this.isLaporanPerKamar = false
        this.isMonitoringAbsesiKamar = false
        this.isMonitoringPenginput = false
        return;
      case '/pengasuhan/laporan-perkamar':
        this.isLaporanPerKamar = true
        this.isInputAbsensiKamar = false
        this.isListAbsensi = false
        this.isLaporanPerSIswa = false
        this.isMonitoringAbsesiKamar = false
        this.isMonitoringPenginput = false
        return;
      case '/pengasuhan/monitoring-absensi-kamar':
        this.isMonitoringAbsesiKamar = true
        this.isInputAbsensiKamar = false
        this.isListAbsensi = false
        this.isLaporanPerSIswa = false
        this.isLaporanPerKamar = false
        this.isMonitoringPenginput = false
        return;
      case '/pengasuhan/monitoring-penginput-absensi-kamar':
        this.isMonitoringPenginput = true
        this.isMonitoringAbsesiKamar = false
        this.isInputAbsensiKamar = false
        this.isListAbsensi = false
        this.isLaporanPerSIswa = false
        this.isLaporanPerKamar = false
        return;
      default:
        this.isInputAbsensiKamar = false
        this.isListAbsensi = false
        this.isLaporanPerSIswa = false
        this.isLaporanPerKamar = false
        this.isMonitoringAbsesiKamar = false
        this.isMonitoringPenginput = false
        return;
    }
  }

}
