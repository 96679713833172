<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="content-item mb-5">
    <div class="back-button" (click)="onBack()">
      <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
    </div>
    <div class="item-header">
      Edit Gelombang Pendaftaran
    </div>
    
    <div class="card">
      <div class="card-header">
        DATA Gelombang Pendafataran
      </div>
      <div class="card-body">
          
              <div class="row">
            
                  <div class="col-12 col-lg-6">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="saudara" class="form-label">Gelombang</label>
                          <input
                            type="text"
                            formControlName="title"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['title'].errors }"
                            placeholder="ex: Gelombang I"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-3">
                          <label for="start_date" class="form-label">Start Date</label>
                          <input
                            type="text"
                            autocomplete="off"
                            id="start_date"
                            (blur)="changeStartDateField($event)"                                  
                            formControlName="start_date"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['start_date'].errors }"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-3">
                          <label for="end_date" class="form-label">End Date</label>
                          <input
                            type="text"
                            autocomplete="off"
                            id="end_date"
                            (blur)="changeEndDateField($event)"                                  
                            formControlName="end_date"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['end_date'].errors }"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-3">
                          <label for="ujian_tulis_date" class="form-label">Ujian Tulis</label>
                          <input
                            type="text"
                            autocomplete="off"
                            id="ujian_tulis_date"
                            (blur)="changeDateField($event, 'ujian_tulis_date')"                                  
                            formControlName="ujian_tulis_date"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['ujian_tulis_date'].errors }"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-3">
                          <label for="ujian_lisan_date" class="form-label">Ujian Lisan</label>
                          <input
                            type="text"
                            autocomplete="off"
                            id="ujian_lisan_date"
                            (blur)="changeDateField($event, 'ujian_lisan_date')"                                  
                            formControlName="ujian_lisan_date"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['ujian_lisan_date'].errors }"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-3">
                          <label for="pengumuman_kelulusan_date" class="form-label">Pengumuman Kelulusan</label>
                          <input
                            type="text"
                            autocomplete="off"
                            id="pengumuman_kelulusan_date"
                            (blur)="changeDateField($event, 'pengumuman_kelulusan_date')"                                  
                            formControlName="pengumuman_kelulusan_date"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['pengumuman_kelulusan_date'].errors }"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-3">
                      
                          <input type="checkbox" formControlName="active" /> Active
                        </div>
                      </div>
                  </div>
              </div>
          
      </div>
    </div>
  
    <div class="row align-items-center">
        <div class="col-6 col-lg-4 offset-lg-6">
            
        </div>
        <div class="col-6 col-lg-2 d-flex justify-content-end">
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </div>
  </div>
</form>
