<ng-template #posisiDaftarModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Generate Posisi Daftar</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3 mt-4 px-2">
        <div class="form-group row">
          <label for="posisiDaftar" class="col-sm-3 col-form-label">Posisi Daftar</label>
          <div class="col-sm-9">
            <select id="posisiDaftar" class="form-control">
              <option value="">Ujian Masuk</option>
              <option value="">Biaya Pangkal dan Info Kelulusan</option>
              <option value="">Informasi</option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.close('Update click')">Update</button>
  </div>
</ng-template>


<ng-template #hasilTestModal let-modal>
  <div class="modal-body">
      <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
      <div class="modal-form">
          <h1 class="text-center" *ngIf="isNew">Tambah Form Hasil Test</h1>
          <h1 class="text-center" *ngIf="!isNew">Edit Form Hasil Test</h1>
          <form [formGroup]="formAddNilaiSiswa" (ngSubmit)="onSubmitHasilPpsb(modal)">

            <div class="mb-3 mt-4 form-group row">
              <label for="input-id-1" class="col-sm-3 control-label">Tahun Pelajaran</label>
              <div class="col-sm-9">
                <select
                  formControlName="id_tahun_ajaran"
                  (change)="changeTahunAjaran($event)"
                  [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }"
                  class="form-select">
                  <option value="" selected disabled>Pilih</option>
                  <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputNamaSiswa" class="col-sm-3 col-form-label control-label">Nama</label>
              <div class="col-sm-9 autocomplete">
                <input formControlName="siswa_name" (keyup)="searchSiswa()" type="text" class="form-control" autocapitalize="off" autocorrect="off" autocomplete="off" id="inputNamaSiswa">

                <div id="myInputautocomplete-list" class="autocomplete-items">
                  <button *ngIf="isShowLoader" class="btn btn-primary" type="button" disabled>
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Searching...
                  </button>
                  <div (click)="chooseSiswa(data)" *ngFor="let data of listSiswa">
                    <strong>{{ data.nama_lengkap }}</strong>
                    <!-- <p>No, Dokumen</p> -->
                    <p>No, Registrasi {{ data.regnumber }}</p>
                    <p>Tahun masuk {{ data.angkatan }} </p>
                  </div>

                </div>
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputNamaPenguji" class="col-sm-3 col-form-label control-label">Penguji</label>
              <div class="col-sm-9 autocomplete">
                <input formControlName="penguji_name" (keyup)="searchPenguji()" type="text" class="form-control" autocapitalize="off" autocorrect="off" autocomplete="off" id="inputNamaPenguji">

                <div id="myInputautocomplete-list-penguji" class="autocomplete-items">
                  <button *ngIf="isShowLoaderPenguji" class="btn btn-primary" type="button" disabled>
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Searching...
                  </button>
                  <div (click)="choosePenguji(datapenguji)" *ngFor="let datapenguji of listPenguji">
                    {{ datapenguji.userProfile.real_name }}
                  </div>

                </div>
              </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
              <label for="lembaga" class="col-sm-3 control-label">Lembaga</label>
              <div class="col-sm-9">
                <select
                    formControlName="id_lembaga"
                    [ngClass]="{ 'is-invalid': submitted && f['id_lembaga'].errors }"
                    class="form-select highlight"
                    (change)="changeLembaga($event)">
                    <option value="" selected disabled>Pilih</option>
                    <option [value]="data.id" *ngFor="let data of listsLembaga">{{data.nama_lembaga}}</option>
                </select>
              </div>
            </div>
            <div class="mb-3 mt-4 form-group row">
                <label for="tingkatan" class="col-sm-3 control-label">Tingkatan</label>
                <div class="col-sm-9">
                  <select
                      formControlName="id_tingkat"
                      [ngClass]="{ 'is-invalid': submitted && f['id_tingkat'].errors }"
                      class="form-select highlight">
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="data.id" *ngFor="let data of listsTingkat">{{data.nama_tingkat}}</option>
                  </select>
                </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
              <label for="input-id-1" class="col-sm-3 control-label">Gelombang</label>
              <div class="col-sm-9">
                <select (change)="changeGelombang($event)" class="form-select" formControlName="id_gelombang" id="gelombang_pendaftaran">
                  <option value="">PILIH</option>
                  <option [value]="gelombang.id" *ngFor="let gelombang of listGelombang">{{ gelombang.Periode.name }}- {{ gelombang.title }}</option>
                </select>
              </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
              <label for="input-id-1" class="col-sm-3 control-label">Status Kelulusan</label>
              <div class="col-sm-9">
                <select class="form-control"
                  formControlName="status_kelulusan"
                  [ngClass]="{ 'is-invalid': submitted && f['status_kelulusan'].errors }"
                  (change)="changeStatusLulus($event)">
                  <option value="">PILIH</option>
                  <option value="lulus_murni">Lulus Murni</option>
                  <option value="lulus_bersyarat">Lulus Bersyarat</option>
                  <option value="lulus_cabang">Lulus di Cabang</option>
                  <option value="lulus_tidak_mendapat_tempat">Lulus Tidak Mendapat Tempat</option>
                  <option value="lulus_cadangan">Lulus Cadangan</option>
                  <option value="tidak_lulus">Tidak Lulus</option>
                  <option value="menunggu_ujian">Menunggu Ujian</option>
                </select>
              </div>
            </div>            

            <div
              *ngIf="f['status_kelulusan'].value == 'lulus_murni' || f['status_kelulusan'].value == 'lulus_bersyarat'" 
              class="mb-3 mt-4 form-group row">
              <label for="input-id-1" class="col-sm-3 control-label">Paket Pembayaran</label>
              <div class="col-sm-4">
                <select formControlName="id_lembaga_biaya" (change)="changeLembagaBiaya($event)" class="form-select" name="lembaga_biaya" id="lembaga_biaya">
                  <option value="">LEMBAGA</option>
                  <option value="0">Untuk Semua Lembaga</option>
                  <option [value]="data.id" *ngFor="let data of listsLembaga">{{data.nama_lembaga}}</option>
                </select>
              </div>
              <div class="col-sm-5">
                <select formControlName="id_paket_biaya" (change)="changePaketPembayaran($event)" class="form-select" name="paket_pembayaran" id="paket_pembayaran">
                  <option value="">PILIH</option>
                  <option [value]="datapaket.id" *ngFor="let datapaket of listPaketPembayaran">{{datapaket.nama_paket}}</option>
                </select>
              </div>
            </div>

            <div
            *ngIf="f['status_kelulusan'].value == 'lulus_murni' || f['status_kelulusan'].value == 'lulus_bersyarat'"  
              class="row mb-3 form-group">
              <label for="inputNamaSiswa" class="col-sm-3 col-form-label control-label">Group Biaya</label>
              <div class="col-sm-9 mt-3">
                <div class="checkbox" *ngFor="let databiayapaket of listBiayaPaketPembayaran">
                  <label class="ui-checks ui-checks-md ng-binding">
                    <input type="checkbox" formArrayName="selectedPaketBiaya" [value]="databiayapaket.group_biaya_id" (change)="onCheckboxChange($event)" />
                    {{ databiayapaket.name }} ({{ databiayapaket.total_group_biaya }})
                  </label>
                </div>

              </div>
            </div>            

            <div class="mb-3 mt-4 form-group row">
              <label for="input-id-1" class="col-sm-3 control-label">Mata Pelajaran</label>
              <div class="col-sm-9">
                <table class="table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Penilaian</th>
                      <th>Nilai</th>
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container formArrayName="nilaiSiswaNya">
                      <tr [formGroupName]="i" *ngFor="let nilaiUji of listNilaiMataUji; let i=index" class="ng-scope">
                        <td class="ng-binding">{{ i+1 }}</td>
                        <td class="ng-binding">{{ nilaiUji.nama_studi }}</td>
                        <td>
                          <input formControlName="id_matauji" type="text" class="form-control d-none">
                          <input formControlName="id_matapel_uji" type="text" class="form-control d-none">
                          <input formControlName="nilai" type="number" class="form-control w-xxs ng-pristine ng-untouched ng-valid">
                        </td>
                        <td><input formControlName="keterangan" type="text" class="form-control ng-pristine ng-untouched ng-valid"></td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
              <label for="input-id-1" class="col-sm-3 control-label">Catatan Penguji</label>
              <div class="col-sm-9">
                <textarea type="text"
                  formControlName="catatan_penguji"
                  [ngClass]="{ 'is-invalid': submitted && f['catatan_penguji'].errors }"
                  class="form-control" name="catatan_penguji"
                  id="catatan_penguji" placeholder=""></textarea>
              </div>
            </div>

              <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isNew">CREATE</button>
              <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="!isNew">Update</button>
          </form>
      </div>
  </div>
</ng-template>


<ng-template #hasilTestModalxxx let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">FORM HASIL TEST</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3 mt-4 px-2">
        <div class="form-group row">
          <label for="tahunPelajaran" class="col-sm-3 col-form-label">Tahun Pelajaran</label>
          <div class="col-sm-9">
            <select id="tahunPelajaran" class="form-control">
              <option value="">Ujian Masuk</option>
              <option value="">Biaya Pangkal dan Info Kelulusan</option>
              <option value="">Informasi</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="nama" class="col-sm-3 col-form-label">Nama</label>
          <div class="col-sm-9">
            <input type="text" id="nama" class="form-control" placeholder="cari siswa">
          </div>
        </div>
        <div class="form-group row">
          <label for="penguji" class="col-sm-3 col-form-label">Penguji</label>
          <div class="col-sm-9">
            <input type="text" id="penguji" class="form-control" placeholder="cari penguji">
          </div>
        </div>
        <div class="form-group row">
          <label for="statusKelulusan" class="col-sm-3 col-form-label">Status Kelulusan</label>
          <div class="col-sm-9">
            <select id="statusKelulusan" class="form-control">
              <option value="">LULUS</option>
              <option value="">TIDAK LULUS</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="lembaga" class="col-sm-3 col-form-label">Lembaga</label>
          <div class="col-sm-9">
            <select id="lembaga" class="form-control">
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('Update click')">Close</button>
    <button type="button" class="btn btn-success" (click)="modal.close('Update click')">Create</button>
  </div>
</ng-template>

<div class="list-options">
  <button class="btn btn-blue-grey" (click)="openHasilTestModal(hasilTestModal)">
    + Tambah
  </button>
  <button class="btn btn-success" (click)="exportToExcel()">
    Excel
  </button>
  <!-- <button class="btn btn-warning" (click)="openPosisiDaftarModal(posisiDaftarModal)">
    Generate Posisi Daftar
  </button> -->
  <div class="search">
    <input
      type="text"
      class="form-control"
      placeholder="Nama Siswa.."
      name="search-list"
      id="search-list"
      [(ngModel)]="searchTerm"
    />
    <div class="icon" (click)="search()">
      <img src="./assets/images/icon-search.png" alt="Search" />
    </div>
  </div>
</div>

<div class="table-wrapper">
  <div class="table-options">
    <div class="show-table">
      Show
      <select
        class="form-control"
        name="show"
        id="show"
        (change)="handlePageSizeChange($event)"
      >
        <option *ngFor="let size of pageSizes" [ngValue]="size">
          {{ size }}
        </option>
      </select>
      entries
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>No.</th>
          <th></th>
          <th>No. Registrasi</th>
          <th>No. Induk</th>
          <th>Nama</th>
          <th>No. VA</th>
          <th>Tagihan</th>
          <th>Pembayaran</th>
          <th>Sisa Tagihan</th>
          <th>Paket Pembayaran</th>         
          <th>Penguji</th>
          <th>Status Kelulusan</th>
          <th>Tingkatan</th>
          <th>User Input</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let siswa of listHasilPpsb
              | paginate
                : {
                    id: pageid,
                    itemsPerPage: pageSize,
                    currentPage: page,
                    totalItems: count
                  };
            let i = index
          "
        >
          <td>{{ i + 1 + (page - 1) * pageSize }}</td>
          <td class="settings">
            <!-- <div class="dropend"> -->
              <a
                href="#"
                role="button"
                id="dropdownMenuLink{{ i }}"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="./assets/images/icon-setting.svg" alt="" />
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <a (click)="openEditHasilTestModal(hasilTestModal, siswa)" class="dropdown-item">
                    <img src="./assets/images/dropdown/icon-edit.png" alt=""/>
                    Edit
                  </a>
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <a (click)="generateVA(siswa)" class="dropdown-item">
                    <img src="./assets/images/dropdown/icon-create.png" alt="" />
                    Generate VA
                  </a>
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <a class="dropdown-item" href="#">
                    <img src="./assets/images/dropdown/icon-payment.png" alt=""/>
                    Bayar Tunai
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    <img src="./assets/images/dropdown/icon-create.png" alt="" />
                    Create Tagihan
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    <img src="./assets/images/dropdown/icon-detail.png" alt="" />
                    Detail Tagihan
                  </a>
                </li>
                
                <li><hr class="dropdown-divider" /></li>
                <!-- <li>
                  <a (click)="openModalChangeStepPendaftaran(tahapanModal, siswa)" class="dropdown-item">
                    <img src="./assets/images/dropdown/icon-move.png" alt="" />
                    Ubah Tahapan Pendaftaran
                  </a>
                </li> -->
                
                <li>
                  <a class="dropdown-item" href="#">
                    <img src="./assets/images/dropdown/icon-print.png" alt="" />
                    Print Kwitansi Pangkal
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    <img src="./assets/images/dropdown/icon-print.png" alt="" />
                    Print Surat Keputusan
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    <img src="./assets/images/dropdown/icon-print.png" alt="" />
                    Print Kartu Kamar
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    <img src="./assets/images/dropdown/icon-print.png" alt="" />
                    Print Daftar Ulang
                  </a>
                </li>
                <li><hr class="dropdown-divider" /></li>
                <!-- <li>
                  <a (click)="openModal(mymodal, siswa.registrant_id)" class="dropdown-item">
                    <img src="./assets/images/dropdown/icon-lock.png" alt="" />
                    Reset Password
                  </a>
                </li> -->
                
                <li>
                  <a (click)="confirmDelete(siswa.id)" class="dropdown-item">
                    <img src="./assets/images/dropdown/icon-delete.png" alt="" />
                    Hapus
                  </a>
                </li>
              </ul>
            <!-- </div> -->
          </td>
          <td>{{ siswa.SiswaHasilPpsb.regnumber }}</td>
          <td>{{ siswa.SiswaHasilPpsb.no_induk }}</td>
          <td>{{ siswa.SiswaHasilPpsb.nama_lengkap }}</td>
          <td><div *ngIf="siswa.TagihanHasilPpsb !== null && siswa.TagihanHasilPpsb.vatagihan !== null">{{ siswa.TagihanHasilPpsb.vatagihan.prefix }}-{{ siswa.TagihanHasilPpsb.vatagihan.nomor }}</div></td>
          <td><div *ngIf="siswa.TagihanHasilPpsb !== null">{{ siswa.TagihanHasilPpsb.nilai_tagihan }}</div></td>
          <td><div *ngIf="siswa.TagihanHasilPpsb !== null">{{ siswa.TagihanHasilPpsb.jumlah_pembayaran }}</div></td>
          <td><div *ngIf="siswa.TagihanHasilPpsb !== null">{{ siswa.TagihanHasilPpsb.sisa_tagihan }}</div></td>
          <td><div *ngIf="siswa.TagihanHasilPpsb !== null">{{ siswa.PaketPembayaranHasilPpsb.nama_paket }}</div></td>    
          <td>{{ siswa.PengujiHasilPpsb.userProfile.real_name }}</td>
          <td>{{siswa.status_kelulusan}}</td>
          <td>{{ siswa.Tingkat.nama_tingkat }}</td>
          <td>{{ siswa.UserInputHasilPpsb.userProfile.real_name }}</td>
        </tr>
        <tr *ngIf="listHasilPpsb.length === 0" class="empty">
          <td colspan="16">Tidak terdapat data atau data tidak ditemukan.</td>
        </tr>
      </tbody>
    </table>
  </div>

  <pagination-template
    #p="paginationApi"
    [id]="pageid"
    (pageChange)="handlePageChange($event)"
  >
    <div
      *ngIf="isShowPagination; else emptyPagination"
      class="pagination-wrapper"
    >
      <div></div>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center pagination-number">
          <li
            class="page-item"
            *ngFor="let page of p.pages"
            [class.active]="p.getCurrent() === page.value"
          >
            <a
              class="page-link"
              (click)="p.setCurrent(page.value)"
              *ngIf="p.getCurrent() !== page.value"
            >
              {{ page.label }}
            </a>
            <a class="page-link" *ngIf="p.getCurrent() === page.value">
              {{ page.label }}
            </a>
          </li>
        </ul>
      </nav>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="p.isFirstPage()">
            <a
              class="page-link"
              *ngIf="!p.isFirstPage()"
              (click)="p.previous()"
              aria-label="Previous"
            >
              <span>Previous</span>
            </a>
          </li>
          <li class="page-item" [class.disabled]="p.isLastPage()">
            <a
              class="page-link"
              *ngIf="!p.isLastPage()"
              (click)="p.next()"
              aria-label="Next"
            >
              <span>Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </pagination-template>

  <ng-template #emptyPagination>
    <div class="pagination-wrapper">
      <div>&nbsp;</div>
    </div>
  </ng-template>
</div>
