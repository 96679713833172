import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';


@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private netService: NetworkService) { 
    // 
  }
  
  isUserLoggedIn() {
    return !!localStorage.getItem('token')
  }

  login(username: string, password: string): Observable<any> {
    const payload = {
      username,
      password
    }

    return this.netService.postRequest(payload, '/v1/proguser/login');
  }

  register(username: string, email: string, password: string): Observable<any> {
    const payload = {
      username,
      email,
      password
    }

    return this.netService.postRequest(payload, '/v1/signup');
  }

  sessionLoginExpiration() {
    const hours = 2; // Set 2 hours for timeout
    const now: any = new Date().getTime();
    const setTime: any = localStorage.getItem('setTime');
    if (setTime == null) {
      localStorage.setItem('setTime', now)
    }
    else {
      console.log("CEK WAKTU");
      if(now - setTime > hours*60*60*1000) {     // 60*60*100 = 1 hour
          localStorage.clear();
          window.location.reload();
      }
    }
  }

}
