import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Validation from 'src/app/helper/validation';
import { SiswaService } from 'src/app/services/siswa.service';
import { GeneralService } from 'src/app/services/general.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { KelasService } from 'src/app/services/kelas.service';
import { SiswakelasService } from 'src/app/services/siswakelas.service';
import { RayonService } from 'src/app/services/rayon.service';
import { KamarService } from 'src/app/services/kamar.service';
import { SiswakamarService } from 'src/app/services/siswakamar.service';

@Component({
  selector: 'app-siswa-penetuankelas',
  templateUrl: './siswa-penetuankelas.component.html',
  styleUrls: ['./siswa-penetuankelas.component.css']
})
export class SiswaPenetuankelasComponent implements OnInit {

  currentPeriodeId: any = null;
  currentPpsbPeriodeId: any = null;
  listRayon: any = []; // Asrama = Rayon
  listKamar: any = [];
  listNomorUrut: any = [];
  currentAngkatan = "";
  listTahunajaran: any = [];
  listnewstudents: any = [];
  listKelas: any = [];
  listTahapanPendaftaran: any = ["REGISTRATION","REGISTRATION_FEE","FILL_REGISTRATION_DATA","DOCUMENT_VALIDATION","ENTRANCE_EXAMINATION","ENTRY_TUITION_FEE","INFORMATION","ACCEPTED"];
  errorMessage = '';
  currentIdKamar: any = null;

  formChangeTahunAjaran: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
  });

  formPenentuanKelas: FormGroup = new FormGroup({
    id: new FormControl(''),
    nama: new FormControl(''),
    kelas_id: new FormControl(''),
    siswa_id: new FormControl(''),
    periode_id: new FormControl(''),
  });

  formPenentuanKamar: FormGroup = new FormGroup({
    id: new FormControl(''),
    nama: new FormControl(''),
    id_rayon: new FormControl(''),
    id_kamar: new FormControl(''),
    id_siswa: new FormControl(''),
    id_tahun_ajaran: new FormControl(''),
    nomor_urut: new FormControl(''),
  });

  formChangeTahapanPendaftaran: FormGroup = new FormGroup({
    id: new FormControl(''),
    nama_lengkap: new FormControl(''),
    current_status: new FormControl(''),
  });
  submitted = false;

  pageid = "proguser";

  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;


  closeResult: string = '';

  constructor(private siswaService: SiswaService,
    private siswaKelasService: SiswakelasService,
    private rayonService: RayonService,
    private uiService: UiService,
    private modalService: NgbModal,
    private kelasService: KelasService,
    private kamarService: KamarService,
    private siswaKamarService: SiswakamarService,
    private tahunajaranService: TahunajaranService,
    private formBuilder: FormBuilder) {
    this.changeBodyClass();
  }

  async ngOnInit() {
    this.getAllKelas();
    this.getAllRayon();
    await this.getPpsbTahunajaran();

    this.initForm();
  }

  initForm() {
    this.formPenentuanKelas = this.formBuilder.group(
      {
        id: [''],
        nama: [''],
        periode_id: ['', Validators.required],
        kelas_id: ['', Validators.required],
        siswa_id: ['', Validators.required],
      }
    );

    this.formPenentuanKamar = this.formBuilder.group(
      {
        id: [''],
        nama: [''],
        id_tahun_ajaran: ['', Validators.required],
        id_rayon: ['', Validators.required],
        id_kamar: ['', Validators.required],
        id_siswa: ['', Validators.required],
        nomor_urut: [''],
      }
    );

    this.formChangeTahapanPendaftaran = this.formBuilder.group(
      {
        id: [''],
        nama_lengkap: ['', Validators.required],
        current_status: ['', Validators.required],
      }
    );
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  openModalPenentuanKelas(content:any, siswa: any) {
    console.log('FROM MODAL',siswa);

    // if (siswa.status !== "ACTIVE") {
    //   this.uiService.showError("Belum registrasi ulang/ Konfirmasi");

    //   return;
    // } 
    
    this.submitted = false;
    this.formPenentuanKelas.reset();
    this.formPenentuanKelas.controls['nama'].setValue(siswa.nama_lengkap);
    this.formPenentuanKelas.controls['siswa_id'].setValue(siswa.id);
    this.formPenentuanKelas.controls['periode_id'].setValue(this.currentPpsbPeriodeId);

    if (siswa.siswaKelas !== null) {
      this.formPenentuanKelas.controls['id'].setValue(siswa.siswaKelas.id);
    }
    
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalPenentuanKamar(content:any, siswa: any) {
    console.log('FROM MODAL',siswa);

    // if (siswa.status !== "ACTIVE") {
    //   this.uiService.showError("Belum registrasi ulang/ Konfirmasi");

    //   return;
    // } 
    
    this.submitted = false;
    this.formPenentuanKamar.reset();
    this.currentIdKamar = null;
    this.listKamar = [];
    this.listNomorUrut = [];
    this.formPenentuanKamar.controls['id'].setValue('');
    this.formPenentuanKamar.controls['nama'].setValue(siswa.nama_lengkap);
    this.formPenentuanKamar.controls['id_rayon'].setValue('');
    this.formPenentuanKamar.controls['id_kamar'].setValue('');
    this.formPenentuanKamar.controls['nomor_urut'].setValue('');
    this.formPenentuanKamar.controls['id_siswa'].setValue(siswa.id);
    this.formPenentuanKamar.controls['id_tahun_ajaran'].setValue(this.currentPpsbPeriodeId);

    if (siswa.siswaKamar !== null) {
      this.formPenentuanKamar.controls['id'].setValue(siswa.siswaKamar.id);
      this.formPenentuanKamar.controls['id_rayon'].setValue(siswa.siswaKamar.Kamar.id_rayon);
      this.currentIdKamar = siswa.siswaKamar.Kamar.id;      
      this._getListKamarByRayon(siswa.siswaKamar.Kamar.id_rayon);      
      this.formPenentuanKamar.controls['nomor_urut'].setValue(siswa.siswaKamar.nomor_urut);
    }
    
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalChangeStepPendaftaran(content:any, student: any) {
    console.log(student);
    this.submitted = false;
    this.formChangeTahapanPendaftaran.reset();
    this.formChangeTahapanPendaftaran.controls['id'].setValue(student.id);
    this.formChangeTahapanPendaftaran.controls['nama_lengkap'].setValue(student.nama_lengkap);
    this.formChangeTahapanPendaftaran.controls['current_status'].setValue(student.current_status);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  async getPpsbTahunajaran() {
    this.uiService.showLoader();

    await this.tahunajaranService.getAktivePpsb().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPpsbPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;
      
        this.uiService.hideLoader();

        this.getAllSiswaBaruPenentuanKelas();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllRayon() {
    this.uiService.showLoader();

    const params = this.getRequestParams("", this.page, this.pageSize);

    this.rayonService.getAll(params).subscribe({
      next: resdata => {
        console.log('Rayon', resdata);
        const { data, totalrows } = resdata;

        //this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listRayon = data;
        this.count = totalrows;

        console.log('COUNT', this.count);

      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getKamarByRayon(id_rayon: any) {
    this.uiService.showLoader();

    const params = this.getRequestParams("", this.page, this.pageSize);

    this.rayonService.getAll(params).subscribe({
      next: resdata => {
        console.log('Rayon', resdata);
        const { data, totalrows } = resdata;

        //this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listKamar = data;
        this.count = totalrows;

        console.log('COUNT', this.count);

      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllKelas() {
    this.uiService.showLoader();

    const params = this.getRequestParams("", this.page, this.pageSize);

    this.kelasService.getAllkelas(params).subscribe({
      next: resdata => {
        console.log('kelas', resdata);
        const { data, totalrows } = resdata;

        this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listKelas = data;
        this.count = totalrows;

        console.log('COUNT', this.count);

      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllSiswaBaruPenentuanKelas() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    params['angkatan'] = this.currentAngkatan;
    params['periode_id'] = this.currentPpsbPeriodeId;

    this.siswaService.getAllSiswaBaruPenentuanKelas(params).subscribe({
      next: resdata => {
        console.log("SISWA PENENTUAN KELAS",resdata);
        const { data, totalrows } = resdata;

        this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listnewstudents = data;
        this.count = totalrows;

        console.log('COUNT', this.count);


        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.getAllSiswaBaruPenentuanKelas();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getAllSiswaBaruPenentuanKelas();
  }

  search(): void {
    this.page = 1;
    this.getAllSiswaBaruPenentuanKelas();
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/empty-image.png';
  }


  get h(): { [key: string]: AbstractControl } {
    return this.formChangeTahunAjaran.controls;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formPenentuanKelas.controls;
  }

  get g(): { [key: string]: AbstractControl } {
    return this.formChangeTahapanPendaftaran.controls;
  }

  get k(): { [key: string]: AbstractControl } {
    return this.formPenentuanKamar.controls;
  }

  onSubmitPenentuanKelas(): void {
    this.submitted = true;
    console.log(JSON.stringify(this.formPenentuanKelas.value, null, 2));
    console.log(this.formPenentuanKelas.value.id);
    

    if (this.formPenentuanKelas.invalid) {
      return;
    }

    this.modalService.dismissAll();

    this.uiService.showLoader();

    if (this.formPenentuanKelas.value.id !== '' && this.formPenentuanKelas.value.id !== null) {
      this.siswaKelasService.update(this.formPenentuanKelas.value.id, this.formPenentuanKelas.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
  
          this.getAllSiswaBaruPenentuanKelas();
  
        },
        error: err => {
          console.log(err);
  
          this.errorMessage = err.message;
          console.log(this.errorMessage);
  
          this.uiService.hideLoader();
  
          this.uiService.showError(this.errorMessage);
        }
      });
    } else {
      this.siswaKelasService.add(this.formPenentuanKelas.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
  
          this.getAllSiswaBaruPenentuanKelas();
  
        },
        error: err => {
          console.log(err);
  
          this.errorMessage = err.message;
          console.log(this.errorMessage);
  
          this.uiService.hideLoader();
  
          this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  onSubmitPenentuanKamar(): void {
    this.submitted = true;
    console.log(JSON.stringify(this.formPenentuanKamar.value, null, 2));
    console.log(this.formPenentuanKamar.value.id);
    

    if (this.formPenentuanKamar.invalid) {
      return;
    }

    this.modalService.dismissAll();

    this.uiService.showLoader();

    if (this.formPenentuanKamar.value.id !== '' && this.formPenentuanKamar.value.id !== null) {
      this.siswaKamarService.update(this.formPenentuanKamar.value.id, this.formPenentuanKamar.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
  
          this.getAllSiswaBaruPenentuanKelas();
  
        },
        error: err => {
          console.log(err);
  
          this.errorMessage = err.message;
          console.log(this.errorMessage);
  
          this.uiService.hideLoader();
  
          this.uiService.showError(this.errorMessage);
        }
      });
    } else {
      this.siswaKamarService.add(this.formPenentuanKamar.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
  
          this.getAllSiswaBaruPenentuanKelas();
  
        },
        error: err => {
          console.log(err);
  
          this.errorMessage = err.message;
          console.log(this.errorMessage);
  
          this.uiService.hideLoader();
  
          this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  onSubmitChangeTahapanPendaftaran(): void {
    this.submitted = true;

    if (this.formChangeTahapanPendaftaran.invalid) {
      return;
    }

    this.modalService.dismissAll();

    this.uiService.showLoader();

    const payload = {
      id: this.formChangeTahapanPendaftaran.value['id'],
      step: this.formChangeTahapanPendaftaran.value['current_status'],
    }

    this.siswaService.changeTahapanPendaftaran(payload).subscribe({
      next: resdata => {
        console.log(resdata);
        this.getAllSiswaBaruPenentuanKelas();

        this.uiService.hideLoader();
        this.uiService.showSuccess('');

      },
      error: err => {
        console.log(err);

        this.errorMessage = err.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });

    console.log(payload, JSON.stringify(this.formChangeTahapanPendaftaran.value, null, 2));
  }

  confirmDelete(id: String) {

    this.uiService.showConfirmDelete(this, id);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.siswaService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getAllSiswaBaruPenentuanKelas();

          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);

          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);

          $SELF.uiService.hideLoader();

          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  changeRayon(e: any) {
    console.log(e.target.value);
    const rayonId = e.target.value;
    this.formPenentuanKamar.controls['id_kamar'].setValue('');
    this._getListKamarByRayon(rayonId);
  }

  private _getListKamarByRayon(rayonId: any) {    
    this.getListKamarByRayon(rayonId);
  }

  changeKamar(e: any, listKamar: any) {
    const idKamar = e.target.value;
    this.currentIdKamar = idKamar;
    this._setNomorUrut();
  }

  private _setNomorUrut() {
    const found = this.listKamar.find((obj: { id: number; }) => {
      return obj.id === parseInt(this.currentIdKamar);
    });

    this.listNomorUrut = [];
    const currentKuota = found ? found.kuota : 0;    

    if (currentKuota > 0 && this.currentIdKamar !== null) {
      this.formPenentuanKamar.controls['id_kamar'].setValue(this.currentIdKamar);
    }

    // get nomor urut tersisi
    if (currentKuota > 0) {
      this.uiService.showLoader();

      this.siswaKamarService.getListSiswaKamar(this.currentPpsbPeriodeId, this.currentIdKamar).subscribe({
        next: resdata => {
          console.log("getListSiswaKamar", resdata);
          
          let noUrutSiswa = [];
          for (let i=0; i<resdata.length; i++) {
            console.log("SISWA", i, resdata[i].Siswa.nama_lengkap, resdata[i].nomor_urut);
            noUrutSiswa[resdata[i].nomor_urut] = resdata[i].Siswa.nama_lengkap;
          }

          console.log("noUrutSiswa", noUrutSiswa);
          
          
          for (let n=1; n<=currentKuota; n++) {
            const dtN = noUrutSiswa[n] !== 'undefined' ? {no: n, nama: noUrutSiswa[n] } : {no: n, nama: ''};
            this.listNomorUrut.push(dtN);
          }

          this.uiService.hideLoader();
        },
        error: err => {
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);

          this.uiService.hideLoader();

          this.uiService.showError(this.errorMessage);
        }
      });
    }

    console.log("Nomor urut", this.listNomorUrut);
    

    // this.formPenentuanKamar.controls['nomor_urut'].setValue(found.kuota);
  }

  getListKamarByRayon(rayonId: any) {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    params['id_rayon'] = rayonId;

    this.kamarService.getByRayon(params).subscribe({
      next: resdata => {
        console.log(resdata);
        const { data, totalrows } = resdata;

        this.listKamar = data;

        if (this.currentIdKamar !== null) {          
          this._setNomorUrut();
        }
        
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

}
