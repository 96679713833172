<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
        <a [routerLink]="['/master/lembaga/']">
            <button class="nav-link " id="statistik-tab" data-bs-toggle="tab" data-bs-target=""
                type="button" role="tab" aria-selected="true">
                List
            </button>
        </a>
    </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link active" id="lembaga-view-tab" data-bs-toggle="tab" data-bs-target="#lembaga-view"
            type="button" role="tab" aria-controls="lembaga-view" aria-selected="false">
            View
        </button>
    </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link" id="lembaga-edit-tab" data-bs-toggle="tab" data-bs-target="#lembaga-edit"
            type="button" role="tab" aria-controls="lembaga-edit" aria-selected="false">
            Edit
        </button>
    </li>
</ul>
<div class="tab-content" id="lembaga">
    <div class="tab-pane fade show active" id="lembaga-view" role="tabpanel" aria-labelledby="lembaga-view-tab">
        <div class="tabs-title">
            Detail {{ lembaga.nama_lembaga ? lembaga.nama_lembaga : "" }}
        </div>
        <div class="content-item mb-5">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-lg-12">
                            <div class="row">
                                <div class="col-6 col-lg-5 mb-3">
                                    <label for="name" class="form-label">Nama</label>
                                </div>
                                <div class="col-6 col-lg-5 mb-3">
                                    <strong>{{ lembaga.nama_lembaga}}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-12">
                            <div class="row">
                                <div class="col-6 col-lg-5 mb-3">
                                    <label for="name" class="form-label">No. Urut</label>
                                </div>
                                <div class="col-6 col-lg-5 mb-3">
                                    <strong>{{ lembaga.urut }}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-12">
                            <div class="row">
                                <div class="col-6 col-lg-5 mb-3">
                                    <label for="name" class="form-label">Tanggal Dibuat</label>
                                </div>
                                <div class="col-6 col-lg-5 mb-3">
                                    <strong>{{ transformDate(lembaga.createdAt) }}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-12">
                            <div class="row">
                                <div class="col-6 col-lg-5 mb-3">
                                    <label for="name" class="form-label">Tanggal Diubah</label>
                                </div>
                                <div class="col-6 col-lg-5 mb-3">
                                    <strong>{{ transformDate(lembaga.updatedAt) }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tab-pane fade" id="lembaga-edit" role="tabpanel" aria-labelledby="lembaga-edit-tab">
        <div class="tabs-title">
            Edit {{ lembaga.nama_lembaga ? lembaga.nama_lembaga : "" }}
        </div>
        <div class="content-item mb-5">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="lembagaFormGroup" (ngSubmit)="updateLembaga()" novalidate>
                        <div class="row">
                            <div class="col-lg-5 col-md-12 col-sm-12">
                              <div class="row">
                                <div class="col-md-12 mb-3">
                                  <label>Nama</label>
                                  <input
                                    formControlName="nama_lembaga"
                                    type="text"
                                    class="form-control highlight"
                                    required
                                  />
                                  <!-- errors-->
                                  <p *ngIf="nama_lembaga?.touched && nama_lembaga?.invalid" class="error">
                                    <sup>*</sup>Please enter atleast nama lembaga
                                  </p>
                                  <p *ngIf="nama_lembaga?.errors" class="error">
                                    <sup>*</sup>Name shouldn't be less than 1 words
                                  </p>
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label>Urutan</label>
                                    <input 
                                    type="number"
                                    class="form-control highlight"
                                    [ngModel]="urut"
                                    required
                                    />
                                    <!-- errors-->
                                    <p *ngIf="urut?.touched && urut?.invalid" class="error">
                                        <sup>*</sup>Please enter no. urut
                                    </p>
                                </div>
                              </div>
                              <div class="form-group text-right">
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                >
                                  Edit Lembaga
                                </button>
                              </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>