import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';


@Injectable({
  providedIn: 'root'
})
export class SiswakelasService {

  constructor(private netService: NetworkService) { }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/siswakelas');
  }

  getSiswakelasByPeriode(periode_id: any): Observable<any> {
    return this.netService.getRequest(`/v1/getSiswakelasByPeriode/${periode_id}`);
  }

  getTotalSiswakelasByPeriode(periode_id: any): Observable<any> {
    return this.netService.getRequest(`/v1/getTotalSiswakelasByPeriode/${periode_id}`);
  }

  getSiswakelasByPeriodeAndLembaga(periode_id: any, lembaga_id: any): Observable<any> {
    return this.netService.getRequest(`/v1/getSiswakelasByPeriodeAndLembaga/${periode_id}/${lembaga_id}`);
  }

  getSiswakelasByPeriodeAndTingkat(periode_id: any, tingkat_id: any): Observable<any> {
    return this.netService.getRequest(`/v1/getSiswakelasByPeriodeAndTingkat/${periode_id}/${tingkat_id}`);
  }

  update(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/siswakelas/${id}`);
  }

  uploadSiswakelas(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/siswakelas/uploadDataKelas');
  }

  uploadPeringkat(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/siswakelas/uploadDataPeringkat');
  }

  getSiswakelasByKelas(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/siswakelas/getByKelas');
  }

  getSiswakelasBySiswa(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/siswakelas/getBySiswa');
  }

  getPeringkatKelas(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/siswakelas/getPeringkat');
  }

}
