<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
      <div class="tabs-title">
          LIST DATA RAYON
      </div>

      <ng-template #formRayonModal let-modal>
        <div class="modal-body">
            <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
            <div class="modal-form">
                <h1 class="text-center" *ngIf="isNew">Tambah Form Rayon</h1>
                <h1 class="text-center" *ngIf="!isNew">Edit Form Rayon</h1>
                <form [formGroup]="formRayon" (ngSubmit)="onSubmitRayon(modal)">
      
                  <div class="mb-3 mt-4 form-group row">
                    <label for="input-id-1" class="col-sm-3 control-label">Gedung</label>
                    <div class="col-sm-9">
                      <select
                        formControlName="id_gedung"
                        [ngClass]="{ 'is-invalid': submitted && f['id_gedung'].errors }"
                        class="form-select">
                        <option value="" selected disabled>Pilih</option>
                        <option [value]="data.id" *ngFor="let data of listGedung">{{data.nama_gedung}}</option>
                      </select>
                    </div>
                  </div>
      
                  <div class="row mb-3">
                    <label for="inputNamaSiswa" class="col-sm-3 col-form-label control-label">Nama Rayon</label>
                    <div class="col-sm-9">
                      <input formControlName="nama_rayon" type="text" class="form-control" 
                        [ngClass]="{ 'is-invalid': submitted && f['nama_rayon'].errors }"
                        autocorrect="off" autocomplete="off" id="inputNamaKamar">
                    </div>
                  </div>
      
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label for="saudara" class="form-label">Murobbi</label>
                      <div class="col-sm-9 autocomplete">
                        <input formControlName="nama_murobbi" (keyup)="searchMurobbi()" type="text" class="form-control" autocapitalize="off" autocorrect="off" autocomplete="off" id="MurobbiPenguji">
  
                        <div id="myInputautocomplete-list-penguji" class="autocomplete-items">
                          <button *ngIf="isShowLoaderMurobbi" class="btn btn-primary" type="button" disabled>
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Searching...
                          </button>
                          <div (click)="chooseMurobbi(data)" *ngFor="let data of listMurobbi">
                            {{ data.userProfile.real_name }}
                          </div>
  
                        </div>

                      </div>
                    </div>
                  </div>  
    
                  <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isNew">CREATE</button>
                  <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="!isNew">Update</button>
                </form>
            </div>
        </div>
      </ng-template>
      
      <div class="list-options">
          
          <div class="search">
              <input type="text" class="form-control" placeholder="Search.." name="search-list" id="search-list" [(ngModel)]="searchTerm">
              <div class="icon" (click)="search()">
                  <img src="./assets/images/icon-search.png" alt="Search">
              </div>
          </div>
                    
          <button class="btn btn-blue-grey" (click)="openFormModal(formRayonModal)">
            + Tambah RAYON
          </button>
          
      </div>
      <div class="table-wrapper">
          <div class="table-options">
              <div class="show-table">
                  Show <select class="form-control" name="show" id="show" (change)="handlePageSizeChange($event)">
                      <option *ngFor="let size of pageSizes" [ngValue]="size">
                        {{ size }}
                      </option>
                  </select> entries
                  <!-- Show<input type="number" class="form-control" name="show" id="show" value="10">entries -->
              </div>
          </div>
          <div class="table-responsive">
              <table class="table table-borderless">
                  <thead>
                      <tr>
                          <th>No.</th>
                          <th>Nama Rayon</th>
                          <th>Murobbi</th>
                          <th> </th>
                          
                      </tr>
                  </thead>
                  <tbody>                        
                      <tr
                      *ngFor="
                          let rayon of listRayon | paginate : {
                                  id: pageid,
                                  itemsPerPage: pageSize,
                                  currentPage: page,
                                  totalItems: count
                              };
                          let i = index
                      "
                      >
                          <td>{{ i + 1 + ((page - 1) * pageSize) }}</td>
                          <td>{{ rayon.nama_rayon }}</td>
                          <td>{{ rayon.ProguserRayon.userProfile.real_name }}</td>
                          <td class="settings">
                              <div class="dropend">
                                  <a href="#" role="button" id="dropdownMenuLink"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      <img src="./assets/images/icon-setting.svg" alt="">
                                  </a>
                                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                      <li><a (click)="openEditFormModal(formRayonModal, rayon)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>                                        
                                      <li><a (click)="confirmDelete(rayon.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                                  </ul>
                              </div>
                          </td>
                      </tr>
                      <tr *ngIf="listRayon.length === 0" class="empty">
                          <td colspan="5">Tidak terdapat data atau data tidak ditemukan.</td>
                      </tr>
                      
                  </tbody>
              </table>
          </div>
          

          <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
              <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
                  <div></div>
                  <nav aria-label="Page navigation">
                      <ul class="pagination justify-content-center pagination-number">
                          <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                              <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ page.label }}</a>
                              
                              <a class="page-link" *ngIf="p.getCurrent() === page.value">{{ page.label }}</a>

                          </li>
                      </ul>
                  </nav>
                  <nav aria-label="Page navigation">
                      <ul class="pagination justify-content-center">
                          <li class="page-item" [class.disabled]="p.isFirstPage()">
                              <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                                  <span>Previous</span>
                              </a>
                          </li>
                          <li class="page-item" [class.disabled]="p.isLastPage()">
                              <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                                  <span>Next</span>
                              </a>
                          </li>
                      </ul>
                  </nav>
              </div>
          </pagination-template>

          <ng-template #emptyPagination>
              <div class="pagination-wrapper">
                  <div>&nbsp;</div>
              </div>
          </ng-template>

      </div>

      
  </div>
  
</div>
