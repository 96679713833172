import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Validation from 'src/app/helper/validation';
import { SiswaService } from 'src/app/services/siswa.service';
import { GeneralService } from 'src/app/services/general.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { TingkatService } from 'src/app/services/tingkat.service';
import { LembagaService } from 'src/app/services/lembaga.service';
import { SiswakelasService } from 'src/app/services/siswakelas.service';
import { ExcelService } from 'src/app/services/excel.service';
import { KelasService } from 'src/app/services/kelas.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { LogService } from 'src/app/services/log.service';
import { SdmService } from 'src/app/services/sdm.service';

@Component({
  selector: 'app-log-activity',
  templateUrl: './log-activity.component.html',
  styleUrls: ['./log-activity.component.css']
})
export class LogActivityComponent implements OnInit {

  activePeriodeId: any = null;
  currentPeriodeId: any = null;
  currentPpsbPeriodeId: any = null;
  currentAngkatan = "";
  listModule: any = [];
  currentModule = "";
  listStaff: any = [];
  currentUseer = "";
  listLogActivity: any = [];
  listTahunajaran: any = [];
  listKelas: any = [];
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  isShowLoader: Boolean = false;
  searchPenempatanKelas = "";

  formAction: FormGroup = new FormGroup({
    module: new FormControl(''),
    id_user: new FormControl(''),
  });

  constructor(private siswaService: SiswaService,
    private generalService: GeneralService,
    private uiService: UiService,
    private modalService: NgbModal,
    private tingkatService: TingkatService,
    private lembagaService: LembagaService,
    private tahunajaranService: TahunajaranService,
    private siswakelasService: SiswakelasService,
    private excelService: ExcelService,
    private kelasService: KelasService,
    private sharedDataService: SharedDataService,
    private logService: LogService,
    private sdmService: SdmService,
    private formBuilder: FormBuilder) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.listModule = [
      "semester",
      "keuangan",
      "absensi",
      "nilai"
    ]   
    
    this.getAllUser();
    this.getLogActivity();

    this.formAction = this.formBuilder.group(
      {
        module: [''],
        id_user: [''],
      },
    );

  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAction.controls;
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  changeModule(e: any) {
    console.log(e.target.value);
    this.currentModule = e.target.value;         
  }

  changeUser(e: any) {
    console.log(e.target.value);
    this.currentUseer = e.target.value;             
  }

  resetFilter() {
    this.currentModule = "";
    this.currentUseer = "";
    this.formAction.controls['module'].setValue('');
    this.formAction.controls['id_user'].setValue('');
    this.getLogActivity();
  }

  getAllUser() {
    const params = {};
    this.sdmService.getAll(params).subscribe({
      next: resdata => {
        console.log(resdata);
        const { data, totalrows } = resdata;

        this.listStaff = data;      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getLogActivity() {
    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    params['module'] = this.currentModule;
    params['id_user'] = this.currentUseer;
    
    this.logService.getAll(params).subscribe({
      next: resdata => {
        console.log('list log activity', resdata);

        this.listLogActivity = resdata;

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }  

}
