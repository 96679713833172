import { Component, OnInit } from '@angular/core';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { KelasService } from 'src/app/services/kelas.service';
import { WalikelasService } from 'src/app/services/walikelas.service';
import { JadwalPelajaranService } from 'src/app/services/jadwal-pelajaran.service';
import { CommonService } from 'src/app/helper/common.service';
import { NilaiRaportService } from 'src/app/services/nilai-raport.service';
import { LembagaService } from 'src/app/services/lembaga.service';
import { TingkatService } from 'src/app/services/tingkat.service';
import { PaketPembayaranService } from 'src/app/services/paket-pembayaran.service';
import { SiswaService } from 'src/app/services/siswa.service';
import { PembayaranService } from 'src/app/services/pembayaran.service';

@Component({
  selector: 'app-generate',
  templateUrl: './generate.component.html',
  styleUrls: ['./generate.component.css']
})
export class GenerateComponent implements OnInit {

  listTahunajaran: any = [];
  listTagihanBulan: any = [];
  listBulanEfektif: any = [];
  listDataSiswaPerorangan: any = [];
  tampungDataSiswaPerorangan: any = [];
  selectedBulanTagihanValue: any = [];
  listKelas: any = [];
  listsLembaga: any = [];
  listsTingkat: any = [];
  listMataPelajaran: any = [];
  listPaketPembayaran: any = [];
  listBiayaPaketPembayaran: any = [];
  listSemester: any = [];
  currentPeriodeId: any = null;
  currentPeriodeTagihanId: any = null;
  currentKelasId: any = null;
  currentStudiId: any = null;
  currentcurrentJadwalPelajaranId: any = null;
  currentGuruId: any = null;
  currentGuruName = "";
  currentAbsensiGuruInputId: any = null;
  currentAngkatan = "";
  currentAngkatanTagihan = "";
  currentSemester = "";
  pageid = "absensi";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  errorMessage = "";
  currentDate = "";
  listDayPeriod: any = [];
  numOfDayCol = 0;
  submitted = false;
  today = "";
  currentJadwalPelajaranId: any = null;
  isAbsenCompleted: boolean = false;
  numOfSiswaActive:number = 0;
  saveGuruInputDate = "";
  isOpenInputNilai: boolean = false;
  listTipe = [
    "Group","Perorangan"
  ]
  currentLembagaId = "";
  currentTingkatId = "";
  isShowLoader: Boolean = false;
  listSiswa: any = [];
  searchSiswaTerm = "";
  isShowPerorangan: Boolean = false;
  idlembagaPembayaran: number = 0;
  idpaket: number = 0;
  groupBiayaName = ""
  totalGroupBiaya = 0
  
  formSumberDataSiswa: FormGroup = new FormGroup({
    tahun_ajaran: new FormControl(''),
    id_tahun_ajaran: new FormControl(''),
    tipe: new FormControl(''),
    id_lembaga: new FormControl(''),
    id_tingkat: new FormControl(''),
    siswa_name: new FormControl(''),
    id_siswa: new FormControl(''),
    dataFormSiswaPerorangan: new FormArray([]),
  });

  formSumberDataTagihan: FormGroup = new FormGroup({
    tahun_ajaran_tagihan: new FormControl(''),
    id_tahun_ajaran_tagihan: new FormControl(''),
    id_lembaga_tagihan: new FormControl(''),
    id_paket_biaya: new FormControl(''),
    // selectedGroupBiaya: new FormArray([]),
    selectedGroupBiaya: new FormControl(''),
  });

  formInputInitMatapelajaran: FormGroup = new FormGroup({
    periode_id: new FormControl(''),
    id_semester: new FormControl(''),
    id: new FormControl(''),
    kelas_id: new FormControl(''),
    guru_id: new FormControl(''),
    guru_name: new FormControl(''),
    walikelas_name: new FormControl(''),
    proguser_id: new FormControl(''),
    studi_id: new FormControl(''),  
  });

  formInputTagihanBulanan: FormGroup = new FormGroup({
    selectedBulanTagihan: new FormArray([]),
    selectedBulanTertagih: new FormArray([]),
    id_tahun_ajaran: new FormControl(''),
    semester: new FormControl(''),
    tipe: new FormControl(''),
    id_studi: new FormControl(''),
    guru_penginput_id: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private kelasService: KelasService,
    private tahunajaranService: TahunajaranService,
    private walikelasService: WalikelasService,
    private jadwalpelajaranService: JadwalPelajaranService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private nilairaportService: NilaiRaportService,
    private lembagaService: LembagaService,
    private tingkatService: TingkatService,
    private paketPembayaranService: PaketPembayaranService,
    private pembayaranService: PembayaranService,
    private siswaService: SiswaService,
  ) { 
    this.changeBodyClass();

    this.initForm();

    this.currentDate = this.commonService.getCurentDate();
    
    this.formInputInitMatapelajaran.controls['kelas_id'].disable();
    // this.formSumberDataSiswa.controls['tahun_ajaran'].disable();
    this.formSumberDataSiswa.controls['id_tingkat'].disable();
    this.formInputInitMatapelajaran.controls['walikelas_name'].disable();
    this.formInputInitMatapelajaran.controls['guru_name'].disable();
  }

  ngOnInit(): void {    
    this.today = this.commonService.getCurrentDayName();
    console.log("HARI INI", this.today);
    //OVERRIDING FOR TESTING
    //this.today = "Saturday";
    
    this.listSemester = this.commonService.getListSemester();
    this.getCurrentTahunajaran();
    this.getKelas();
    this.getLembaga();
    this.getAllPaketPembayaran();
    
    const currentUser = localStorage.getItem('user')?.toString();
    const jsonUser = currentUser ? JSON.parse(currentUser) : "";
    console.log("CURRENT USER", jsonUser.proguser.userProfile.real_name);
    this.currentGuruId = jsonUser.proguser.id;
    this.currentGuruName = jsonUser.proguser.userProfile.real_name;

    this.formInputInitMatapelajaran.controls['id_semester'].disable();
    this.formInputInitMatapelajaran.controls['guru_id'].setValue(this.currentGuruId);
    this.formInputInitMatapelajaran.controls['guru_name'].setValue(this.currentGuruName);
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {

    this.formSumberDataSiswa = this.formBuilder.group(
      {
        tahun_ajaran: ['', Validators.required],
        id_tahun_ajaran: ['', Validators.required],
        tipe: ['Group', Validators.required],
        id_lembaga: [''],
        id_tingkat: [''],
        siswa_name: [''],
        id_siswa: [''],
        dataFormSiswaPerorangan: new FormArray([]),
      },
    );
    this.formSumberDataTagihan = this.formBuilder.group(
      {
        tahun_ajaran_tagihan: ['', Validators.required],
        id_tahun_ajaran_tagihan: ['', Validators.required],
        id_lembaga_tagihan: ['0', Validators.required],
        id_paket_biaya: ['', Validators.required],
        // selectedGroupBiaya: new FormArray([]),
        selectedGroupBiaya: ['', Validators.required],
      },
    );

    this.formInputTagihanBulanan = this.formBuilder.group(
      {
        selectedBulanTagihan: new FormArray([]),
        selectedBulanTertagih: new FormArray([]),
        id_tahun_ajaran: ['', Validators.required],
        semester: ['', Validators.required],
        tipe: ['HARIAN', Validators.required],
        id_studi: ['', Validators.required],
        guru_penginput_id: [''],
      },
    );
  }

  get selectedBulanTagihan(): FormArray {
    return this.formInputTagihanBulanan.get('selectedBulanTagihan') as FormArray;
  }

  get selectedBulanTertagih(): FormArray {
    return this.formInputTagihanBulanan.get('selectedBulanTertagih') as FormArray;
  }

  get dataFormSiswaPerorangan(): FormArray {
    return this.formInputTagihanBulanan.get('dataFormSiswaPerorangan') as FormArray;
  }
  
  getLembaga() {
    this.uiService.showLoader();

    this.lembagaService.getAllLembaga('').subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsLembaga = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  private getTingkatLembaga() {
    this.currentTingkatId = "";
    this.formSumberDataSiswa.controls['id_tingkat'].setValue('');
    if (this.currentLembagaId === "") {
      this.formSumberDataSiswa.controls['id_tingkat'].disable();
      return;
    }
    this.uiService.showLoader();
  
    this.formSumberDataSiswa.controls['id_tingkat'].enable();

    this.tingkatService.getTingkatByLembagaId(this.currentLembagaId).subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsTingkat = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getListMataPelajaran() {
    this.uiService.showLoader();

    const payload = {
      periode_id: this.currentPeriodeId,
      semester: this.currentSemester,
      guru_id: this.currentGuruId
    }

    console.log("PAYLOAD getJadwalPelajaran", payload);
    
    
    this.jadwalpelajaranService.getListMataPelajaranByPeriodeSemesterAndGuru(payload).subscribe({
      next: resdata => {
        console.log("JADWAL PELAJARAN : ", resdata);
        
        if (resdata.length > 0) {
          this.listMataPelajaran = resdata;
        }
        
        this.uiService.hideLoader();      

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formSumberDataSiswa.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        this.formSumberDataTagihan.controls['id_tahun_ajaran_tagihan'].setValue(this.currentPeriodeTagihanId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value, e.target.options[e.target.selectedIndex].innerHTML);
    this.currentAngkatan = e.target.options[e.target.selectedIndex].innerHTML;
    this.currentPeriodeId = e.target.value;
    this.generateListBulanTahun();
  }

  changeTahunAjaranTagihan(e: any) {
    console.log(e.target.value, e.target.options[e.target.selectedIndex].innerHTML);
    this.currentAngkatanTagihan = e.target.options[e.target.selectedIndex].innerHTML;
    this.currentPeriodeTagihanId = e.target.value;

    this.formSumberDataTagihan.controls['id_lembaga_tagihan'].setValue('0');
    this.formSumberDataTagihan.controls['id_paket_biaya'].setValue('');
    this.listBiayaPaketPembayaran = [];
    this.idlembagaPembayaran = 0;
    this.getAllPaketPembayaran();
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata.Semester);        

        this.currentPeriodeId = resdata.id;
        this.currentPeriodeTagihanId = resdata.id;
        this.currentAngkatan = resdata.name;
        this.currentAngkatanTagihan = resdata.name;

        this.uiService.hideLoader();

        this.generateListBulanTahun();
        this.getAllTahunajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onSubmitformInputTagihanBulanan() {
    this.uiService.showConfirmCustom(this, this.executeTagihanBulanan, "", "Generate!");
  }

  executeTagihanBulanan(isConfirmed: Boolean, id: String, $SELF: any) {
    if (isConfirmed) {
      console.log(JSON.stringify($SELF.formInputTagihanBulanan.value, null, 2));
      console.log("SELECTED BULAN", $SELF.formInputTagihanBulanan.value.selectedBulanTagihan);
      console.log("SELECTED VAL", $SELF.selectedBulanTagihanValue);
  
      const payload = {
        tipe: $SELF.formSumberDataSiswa.value.tipe,
        id_group_biaya: $SELF.formSumberDataTagihan.value.selectedGroupBiaya,
        total_tagihan: $SELF.totalGroupBiaya,
        group_biaya_name: $SELF.groupBiayaName,
        list_siswa: $SELF.listDataSiswaPerorangan,
        id_lembaga: $SELF.currentLembagaId,
        id_tingkat: $SELF.currentTingkatId,
        id_tahun_ajaran: $SELF.currentPeriodeId,
        list_bulan: $SELF.selectedBulanTagihanValue,
        list_bulan_tertagih: $SELF.formInputTagihanBulanan.value.selectedBulanTertagih
      }
      console.log(payload);
      
      $SELF.uiService.showLoader();
      $SELF.pembayaranService.generateTagihanBulanan(payload).subscribe({
        next: (resdata: any) => {
          console.log("TAGIHAN", resdata);
                    
          $SELF.uiService.hideLoader();
          $SELF.onClickPreview();
        },
        error: (err: { error: { message: any; }; }) => {
          $SELF.errorMessage = err;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });
    }
  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changePaketPembayaran(e: any) {
    console.log(e.target.value);
    this.idpaket = e.target.value;
    this.formSumberDataTagihan.controls['selectedGroupBiaya'].setValue('');
    this.formSumberDataTagihan.controls['id_paket_biaya'].setValue(this.idpaket);
    this.listTagihanBulan = [];
    this.getAllBiayaPaketPembayaran();
    
  }

  changeKelas(e: any) {

    this.listTagihanBulan = [];
    this.currentJadwalPelajaranId = null;
    this.formInputInitMatapelajaran.controls['studi_id'].setValue("");
    
    if (e.target.value !== "") {
      const kelas_id = e.target.value;
      this.currentKelasId = kelas_id;
      this.getWalikelasByKelas(kelas_id);

      // get mata pelajran 
      this.getListMataPelajaran(); // TODO: get by guru
    }
  }

  changeSemester(e: any) {
    if (e.target.value !== "") {
      this.formInputInitMatapelajaran.controls['kelas_id'].enable();
    } else {
      this.listDayPeriod = [];
      this.formInputInitMatapelajaran.controls['kelas_id'].setValue("");
      this.formInputInitMatapelajaran.controls['kelas_id'].disable();
    }
  }

  changeTipe(e: any) {
    if (e.target.value === "Group") {
      this.tampungDataSiswaPerorangan = [];
      this.listDataSiswaPerorangan = [];
      this.isShowPerorangan = false;
    } else {
      this.isShowPerorangan = true;
    }
    this.resetFormBulanTagihan();
    this.listTagihanBulan = [];
  }

  changeLembagaBiaya(e: any) {
    console.log(e.target.value);
    const lembagaBiayaId = e.target.value;
    this.idlembagaPembayaran = lembagaBiayaId;
    // this.formAddNilaiSiswa.controls['id_lembaga_biaya'].setValue(lembagaBiayaId);
    this.formSumberDataTagihan.controls['id_paket_biaya'].setValue('');
    this.listBiayaPaketPembayaran = [];
    this.getAllPaketPembayaran();
    
  }

  changeLembaga(e: any) {
    console.log("lembagaId", e.target.value);
    
    this.currentLembagaId = e.target.value;
    this.currentTingkatId = "";
    this.getTingkatLembaga();
  }

  changeTingkat(e: any) {
    console.log(e.target.value);
    this.currentTingkatId = e.target.value;

    // this.displayPlotKelas();    
  }

  chooseSiswa(siswa: any) {
    this.listSiswa = [];
    console.log(siswa);
    // this.formSumberDataSiswa.controls['id_siswa'].setValue(siswa.id);
    this.formSumberDataSiswa.controls['siswa_name'].setValue('');
    if (!this.tampungDataSiswaPerorangan.includes(siswa.id)) {
      this.tampungDataSiswaPerorangan.push(siswa.id);
      this.listDataSiswaPerorangan.push(siswa);
    }
  }

  removeListSiswaPerorangan(idx: any, siswa_id: any) {
    const index = this.tampungDataSiswaPerorangan.indexOf(siswa_id);
    this.tampungDataSiswaPerorangan.splice(index, 1);
    this.listDataSiswaPerorangan.splice(idx, 1);
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  searchSiswa(): void {
    this.searchSiswaTerm = this.formSumberDataSiswa.value.siswa_name;

    this.listSiswa = [];
    if (this.searchSiswaTerm != "") {
      this.isShowLoader = true;
      const params = this.getRequestParams(this.searchSiswaTerm, this.page, this.pageSize);
      params['angkatan'] = this.currentAngkatan;

      console.log("PARAMS", params);
      
  
      this.siswaService.getAllSiswaBaru(params).subscribe({
        next: resdata => {
          console.log(resdata);
          const { data, totalrows } = resdata;
  
          this.isShowPagination == totalrows > this.pageSize ? true : false;
          this.listSiswa = data;
          this.count = totalrows;
  
          console.log("SISWA", this.listSiswa);
  
          console.log('COUNT', this.count);
  
  
          this.isShowLoader = false;
          //this.uiService.hideLoader();
  
        },
        error: err => {
          this.isShowLoader = false;
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);
  
          //this.uiService.hideLoader();
  
          //this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  getWalikelasByKelas(kelasid: any) {
    this.uiService.showLoader();

    this.walikelasService.getByPeriodeAndKelas(this.currentPeriodeId, kelasid).subscribe({
      next: resdata => {
        console.log("WALIKELAS", resdata);
        this.formInputInitMatapelajaran.controls['walikelas_name'].setValue(resdata.User.userProfile.real_name);
        
        // this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formInputInitMatapelajaran.controls;
  }

  getAllPaketPembayaran() {
    this.paketPembayaranService.getByIdLembaga(this.idlembagaPembayaran).subscribe({
      next: resdata => {
        console.log("getAllPaketPembayaran", resdata.data);
        
        this.listPaketPembayaran = resdata.data;
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllBiayaPaketPembayaran() {
    this.paketPembayaranService.getPaketpembayaranbylembagaandpaketpembayaranandperiode(this.idlembagaPembayaran, this.idpaket, this.currentPeriodeTagihanId).subscribe({
      next: resdata => {
        console.log("getPaketpembayaranbylembagaandperiode", this.currentPeriodeId, resdata);
        
        this.listBiayaPaketPembayaran = resdata;
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onCheckboxChange(event: any) {
    
    // const selectedBulanTagihan: FormArray = this.formInputTagihanBulanan.get('selectedBulanTagihan') as FormArray;
    
    if (event.target.checked) {
      this.selectedBulanTagihanValue.push(event.target.value);      
    } else {
      const index = this.selectedBulanTagihanValue
      .findIndex((x: { value: any; }) => {
        console.log("x val", x);
        
        return x === event.target.value
      });
      console.log("UNCHECK IDX", index);
      
      this.selectedBulanTagihanValue.splice(index, 1);
    }
    
  }

  onSelectBulanChange(event: any) {
    console.log(event.target.value);

    // const selectedBulanTertagih: FormArray = this.formInputTagihanBulanan.get('selectedBulanTertagih') as FormArray;
    // const index = selectedBulanTertagih.controls
    //   .findIndex(x => {
    //     const xVal = x.value;
    //     const splXVal = xVal.split("==");
    //     const divXVal = splXVal[0];

    //     const xTargetVal = event.target.value;
    //     const splXTargetVal = xTargetVal.split("==");
    //     const divXTargetVal = splXTargetVal[0];

    //     console.log("DIV", divXVal, divXTargetVal);
        
    //     return divXVal === divXTargetVal
    //   });

    // console.log("IDX NYA", index);
    
    
    // if (index > -1) {
    //   selectedBulanTertagih.removeAt(index);
    // }
    // selectedBulanTertagih.push(new FormControl(event.target.value));
    
  }

  generateListBulanTahun() {    
    this.listBulanEfektif = this.commonService.getListMonthEfective(this.currentAngkatan);

    this.resetFormBulanTagihan();
  }

  resetFormBulanTagihan() {
    const selectedBulanTagihan: FormArray = this.formInputTagihanBulanan.get('selectedBulanTagihan') as FormArray;
    
    if (selectedBulanTagihan.length > 0) {
      console.log("ADA ISI", selectedBulanTagihan); 
      selectedBulanTagihan.clear();
    }

    for (let i = 0; i < this.listBulanEfektif.length; i++) {
      selectedBulanTagihan.push(new FormControl(false));      
    }
    
    this.selectedBulanTagihanValue = [];

    const selectedBulanTertagih: FormArray = this.formInputTagihanBulanan.get('selectedBulanTertagih') as FormArray;
    if (selectedBulanTertagih.length > 0) {
      console.log("ADA ISIx", selectedBulanTertagih); 
      selectedBulanTertagih.clear();
    }

    for (let i = 0; i < this.listBulanEfektif.length; i++) {
      selectedBulanTertagih.push(new FormControl(''));      
    }
  }

  onClickDeleteBulanTagihan() {
    this.uiService.showConfirmDelete(this, '');
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {
      const payload = {
        tipe: this.formSumberDataSiswa.value.tipe,
        id_group_biaya: this.formSumberDataTagihan.value.selectedGroupBiaya,
        total_tagihan: this.totalGroupBiaya,
        group_biaya_name: this.groupBiayaName,
        list_siswa: this.listDataSiswaPerorangan,
        id_lembaga: this.currentLembagaId,
        id_tingkat: this.currentTingkatId,
        id_tahun_ajaran: this.currentPeriodeId,
        list_bulan: this.selectedBulanTagihanValue
      }
  
      console.log("PLOAD", payload);

      this.uiService.showLoader();
      this.pembayaranService.deleteTagihanBulanan(payload).subscribe({
        next: resdata => {
          console.log("getAllTagihanBUlan", resdata);
                    
          this.uiService.hideLoader();
          this.onClickPreview();
        },
        error: err => {
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  onClickPreview() {
    this.resetFormBulanTagihan();

    if (this.formSumberDataTagihan.value.selectedGroupBiaya === "") {
      this.uiService.showError('Group biaya belum dipilih');
      return;
    }
    console.log(this.currentAngkatan, this.formSumberDataTagihan.value.selectedGroupBiaya);
    console.log(this.formSumberDataSiswa.value);

    const splTahun = this.currentAngkatan.split("-");
    const thAwal = splTahun[0];
    const thAkhir = splTahun[1];
    const payload = {
      tipe: this.formSumberDataSiswa.value.tipe,
      id_periode: this.currentPeriodeId,
      list_siswa: this.listDataSiswaPerorangan,
      id_lembaga: this.formSumberDataSiswa.value.id_lembaga,
      id_tingkat: this.formSumberDataSiswa.value.id_tingkat,
      id_group_biaya: this.formSumberDataTagihan.value.selectedGroupBiaya,
      tahun_awal: thAwal,
      tahun_akhir: thAkhir
    }
    console.log(payload);
    
    this.uiService.showLoader();
    this.pembayaranService.getAllTagihanBulanan(payload).subscribe({
      next: resdata => {
        console.log("getAllTagihanBUlan", resdata.data['juli']);
                    
        const rowCols: any[][] = [];

        for (let i=0; i<resdata.data['juli'].length; i++) {
          let cols: any[] = [];
          cols.push(
            {
              val: resdata.data['juli'][i].nama_lengkap
            }                
          );
          cols.push(
            {
              val: resdata.data['juli'][i].nama_kelas
            }                
          );           

          for (let c=0; c<this.listBulanEfektif.length; c++) {
            const sBulan = this.listBulanEfektif[c].bulan;
            const nilTagihan = resdata.data[sBulan][i].amount ? resdata.data[sBulan][i].amount : 0;
            const col = {
              idsiswa: "x",
              val: nilTagihan
            }
            cols.push(col);
          }
          rowCols.push(cols);
        }
        
        console.log("rpwCols", rowCols);
        this.listTagihanBulan = rowCols;

                  
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
    
    
  }

  onChangeGroupBiaya(e: any) {
    console.log("RADIO CHECKED");
    
      const groupBiayaId = this.formSumberDataTagihan.value.selectedGroupBiaya;

      for (let i=0; i<this.listBiayaPaketPembayaran.length;i++) {
        if (this.listBiayaPaketPembayaran[i].group_biaya_id === groupBiayaId) {
          
          this.groupBiayaName = this.listBiayaPaketPembayaran[i].name;
          this.totalGroupBiaya = this.listBiayaPaketPembayaran[i].total_group_biaya;
          console.log("MATCH", this.listBiayaPaketPembayaran[i], this.groupBiayaName, this.totalGroupBiaya);
        }
      }

      this.listTagihanBulan = [];
      const selectedBulanTagihan: FormArray = this.formInputTagihanBulanan.get('selectedBulanTagihan') as FormArray;
      
      for (let i = 0; i < selectedBulanTagihan.length; i++) {
        selectedBulanTagihan.at(i).patchValue(false); // Set checkbox value to false
      }

      const selectedBulanTertagih: FormArray = this.formInputTagihanBulanan.get('selectedBulanTertagih') as FormArray;
      for (let i = 0; i < selectedBulanTertagih.length; i++) {
        selectedBulanTertagih.at(i).patchValue('');
      }
      
      this.selectedBulanTagihanValue = [];
  }

}
