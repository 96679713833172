import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rayon-view',
  templateUrl: './rayon-view.component.html',
  styleUrls: ['./rayon-view.component.css']
})
export class RayonViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
