<div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="guru-input"
      role="tabpanel"
      aria-labelledby="guru-input-tab"
    >
      <div class="tabs-title">Input Raport Non Nilai</div>
  
      
      <div class="col-12 col-lg-12">
        <div class="card">
            
            <div class="card-body">
                <form [formGroup]="formInputInitMatapelajaran">
                    <div class="row">
                        <div class="col-12 col-lg-12">
                            <div class="mb-3">
                                <div class="row align-items-center">
                                    <div class="col-2 text-end">
                                        <label for="nik" class="form-label">Tahun Ajaran <span
                                                class="required">*</span></label>
                                    </div>
                                    <div class="col-10">
                                        <input type="text" 
                                          formControlName="tahun_ajaran"
                                          readonly
                                          class="form-control btn-secondary" placeholder="">
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="name" class="form-label">Semester <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-10">
                                    <select
                                        formControlName="id_semester"
                                        [ngClass]="{ 'is-invalid': submitted && f['id_semester'].errors }"
                                        class="form-select"
                                        (change)="changeSemester($event)">
                                        <option value="" selected>Pilih Semester</option>
                                        <option [value]="data.kode" *ngFor="let data of listSemester">{{data.name}}</option>
                                    </select>
                                  </div>
                              </div>
                          </div>
                            <div class="mb-3">
                                <div class="row align-items-center">
                                    <div class="col-2 text-end">
                                        <label for="name" class="form-label">Kelas <span
                                                class="required">*</span></label>
                                    </div>
                                    <div class="col-10">
                                      <select
                                          formControlName="kelas_id"
                                          [ngClass]="{ 'is-invalid': submitted && f['kelas_id'].errors }"
                                          class="form-select"
                                          (change)="changeKelas($event)">
                                          <option value="" selected>Pilih</option>
                                          <option [value]="data.id" *ngFor="let data of listKelas">{{data.nama_kelas}}</option>
                                      </select>
                                    </div>
                                </div>
                            </div>            
                            
                            <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="name" class="form-label">Wali Kelas</label>
                                  </div>
                                  <div class="col-10">
                                      <input type="text" class="form-control" formControlName="walikelas_name"
                                          placeholder="">
                                  </div>
                              </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </form>
            </div>
        </div>
      </div>
      
      <form [formGroup]="formInputRaportNonNilai" (ngSubmit)="onSubmitformInputRaportNonNilai()">
        <div class="mt-4 list-options">
                
          <!-- <div class="search">
            <input
            type="text"
            class="form-control"
            placeholder="Search.."
            name="search-list"
            id="search-list"
            [(ngModel)]="searchTerm"
            />
            <div class="icon" (click)="search()">
              <img src="./assets/images/icon-search.png" alt="Search" />
            </div>
          </div> -->
  
          <button class="btn btn-blue-grey mt-2">
            Simpan
          </button>
          
        </div>
        
        <div class="table-wrapper">
          <div class="table-options">
            <div class="show-table">
              
            </div>
          </div>
          
            <div class="table-responsive">
              <table class="table table-borderless">
                <thead class="bordered">
                  <tr class="up">
                    <th rowspan="2">No.</th>
                    <th rowspan="2">No, Induk</th>
                    <th rowspan="2">Nama</th>
                    <th colspan="4" class="w-25">حصص الغياب</th>
                    <th colspan="3">الملاحظة</th>
                    <th colspan="3">الشخصية</th>
                  </tr>
                  <tr class="below">
                    <th>دون عذر</th>
                    <th>لعذر صحي</th>
                    <th>لعذر عائلي</th>
                    <th>لمهام</th>
                    <th>الجدية فى المذاكرة</th>
                    <th>مراعة الصحة</th>
                    <th>مراعة المواظبة والتبكير</th>
                    <th>السلوك</th>
                    <th>النظافة</th>
                    <th>المواظبة</th>
                  </tr>
                </thead>
                <tbody>
                    <ng-container formArrayName="dataFormNilaiNonNilai">
                      <tr [formGroupName]="i" *ngFor="let siswa of listNilaiRaportNonNilai; let i = index">
                        <td>                  
                          <input formControlName="siswa_id" type="text" class="form-control d-none">
                          <input formControlName="siswa_status" type="text" class="form-control d-none">
                          {{ i + 1 + (page - 1) * pageSize }}
                        </td>
                        <td>{{ siswa.no_induk }}</td>
                        <td>{{ siswa.nama_lengkap }}</td>
                        
                        <td>
                          <input [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}" type="number" class="form-control" formControlName="alpha">
                        </td>
                        <td>
                          <input [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}" type="number" class="form-control" formControlName="sakit">
                        </td>
                        <td>
                          <input [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}" type="number" class="form-control" formControlName="izin_pribadi">
                        </td>
                        <td>
                          <input [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}" type="number" class="form-control" formControlName="izin_tugas">
                        </td>
                        <td>
                          <select
                              formControlName="keseriusan"                    
                              class="form-select"
                              [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}"
                          >
                              <option value="" selected></option>
                              <option [value]="data" *ngFor="let data of listCatatanSiswa">{{data}}</option>
                          </select>
                        </td>
                        <td>
                          <select
                              formControlName="kesehatan"                    
                              class="form-select"
                              [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}"
                          >
                              <option value="" selected></option>
                              <option [value]="data" *ngFor="let data of listCatatanSiswa">{{data}}</option>
                          </select>                     
                        </td>
                        <td>
                          <select
                              formControlName="ketekunan"                    
                              class="form-select"
                              [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}"
                          >
                              <option value="" selected></option>
                              <option [value]="data" *ngFor="let data of listCatatanSiswa">{{data}}</option>
                          </select>                        
                        </td>
                        <td>
                          <select
                              formControlName="perilaku"                    
                              class="form-select"
                              [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}"
                          >
                              <option value="" selected></option>
                              <option [value]="data" *ngFor="let data of listAttitude">{{data}}</option>
                          </select>                          
                        </td>
                        <td>
                          <select
                              formControlName="kebersihan"                    
                              class="form-select"
                              [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}"
                          >
                              <option value="" selected></option>
                              <option [value]="data" *ngFor="let data of listAttitude">{{data}}</option>
                          </select>                          
                        </td>
                        <td>
                          <select
                              formControlName="kehadiran"                    
                              class="form-select"
                              [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}"
                          >
                              <option value="" selected></option>
                              <option [value]="data" *ngFor="let data of listAttitude">{{data}}</option>
                          </select>                      
                        </td>
                      </tr>
  
                    </ng-container>            
                    
                    <tr *ngIf="listNilaiRaportNonNilai.length === 0" class="empty">
                      <td colspan="13">
                        Tidak terdapat data atau data tidak ditemukan.
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
    
        </div>
      </form>
    </div>
  </div>