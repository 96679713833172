<div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="guru-input"
      role="tabpanel"
      aria-labelledby="guru-input-tab"
    >
      <div class="tabs-title">Simulasi Pembayaran VA</div>
  
      
      <div class="col-12 col-lg-12">
        <div class="card">
            
            <div class="card-body">
                <form *ngIf="!readyToPay" [formGroup]="formVirtualAccount" (ngSubmit)="onSubmitformVa()">
                    <div class="row">
                        <div class="col-12 col-lg-12">
                            <div class="mb-3">
                                <div class="row align-items-center">
                                    <div class="col-3 text-end">
                                        <label for="nik" class="form-label">Virtual Account Number <span
                                                class="required">*</span></label>
                                    </div>
                                    <div class="col-9">                            
                                      <input type="text" formControlName="virtual_account" [ngClass]="{ 'is-invalid': submitted && f['virtual_account'].errors }" class="form-control" value="2023-01-01">
                                    </div>
                                </div>
                            </div>                  
                        
                            
                            
                            <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-3 text-end">
                                      <label for="name" class="form-label">&nbsp;</label>
                                  </div>
                                  <div class="col-9">
                                    <button class="btn btn-blue-grey mt-2" style="display:inline; margin-right: 4px;">
                                      Inquiry
                                    </button>                              
                                  </div>
                              </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </form>

                <form *ngIf="readyToPay" [formGroup]="formPayVirtualAccount" (ngSubmit)="onSubmitformPayVa()">
                    <div class="row">
                        <div class="col-12 col-lg-12">
                            
                            
                            <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-3 text-end">
                                      <label for="nik" class="form-label">Amount to pay <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-9">                            
                                    <input type="text" class="form-control">
                                  </div>
                              </div>
                          </div>
  
                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-3 text-end">
                                    <label for="nik" class="form-label">On behalf of <span
                                            class="required">*</span></label>
                                </div>
                                <div class="col-9">                            
                                  <input type="text" class="form-control">
                                </div>
                            </div>
                        </div>
  
                            <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-3 text-end">
                                      <label for="name" class="form-label">&nbsp;</label>
                                  </div>
                                  <div class="col-9">
                                    <button class="btn btn-blue-grey mt-2" style="display:inline; margin-right: 4px;">
                                      Pay
                                    </button>                              
                                  </div>
                              </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </form>
            </div>
        </div>
      </div>
  

    </div>
  </div>