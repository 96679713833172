import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SiswakelasService } from 'src/app/services/siswakelas.service';

@Component({
  selector: 'app-siswa-penempatan-kelas-detail',
  templateUrl: './siswa-penempatan-kelas-detail.component.html',
  styleUrls: ['./siswa-penempatan-kelas-detail.component.css']
})
export class SiswaPenempatanKelasDetailComponent implements OnInit {

  listPenempatanKelas: any = [];
  listTahunajaran: any = [];
  listKelas: any = [];
  currentPeriodeId: any = null;
  currentKelasId: any = null;
  sharedDataObject: any = null;
  errorMessage = '';

  formAction: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
    id_kelas: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private sharedDataService: SharedDataService,
    private siswakelasService: SiswakelasService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {

    this.formAction = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        id_kelas: ['0'],
      },
    );

    this.sharedDataService.currentDataObject.subscribe(data => {
      (this.sharedDataObject = data)
      console.log("SHARED OBJECT", data);
      this.currentPeriodeId = data.idperiod;
      this.currentKelasId = data.idkelas;
      this.listTahunajaran = data.listTahunAjaran;
      this.listKelas = data.listKelas;

      this.formAction.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
      this.formAction.controls['id_kelas'].setValue(this.currentKelasId);

      this.getAllPenempatanKelas();
    })    
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/empty-image.png';
  }

  getAllPenempatanKelas() {
    const payload = {
      periode_id: this.currentPeriodeId,
      kelas_id: this.currentKelasId
    }
    this.siswakelasService.getSiswakelasByKelas(payload).subscribe({
      next: resdata => {
        console.log('list siswa kelas', resdata);

        this.listPenempatanKelas = resdata;

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  updatePenempatanKelas() {

  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    this.currentPeriodeId = e.target.value;
    console.log("currentAngkatan", this.currentPeriodeId);    

    // this.refreshListKelas();
  }

  changeKelas(e: any) {

  }

  closeButton() {
    this.uiService.hideFullScrrenContent();
  }

}
