<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="statistik"
    role="tabpanel"
    aria-labelledby="statistik-tab"
  >
    <div class="tabs-title">DATA SISWA TAHUN AJARAN AKTIF</div>

    
    <div class="list-options">
      <div class="form-select-warapper">
        <form [formGroup]="formChangeTahunAjaran">
          <select formControlName="id_tahun_ajaran" (change)="changeTahunAjaran($event)" class="form-select" name="id_tahun_ajaran" id="id_tahun_ajaran">
            <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
          </select>
        </form>
      </div>
      
      <div class="search">
        <input
        type="text"
        class="form-control"
        placeholder="Search.."
        name="search-list"
        id="search-list"
        [(ngModel)]="searchTerm"
        />
        <div class="icon" (click)="search()">
          <img src="./assets/images/icon-search.png" alt="Search" />
        </div>
      </div>
      
      
    </div>
    
    <div class="table-wrapper">
      <div class="table-options">
        <div class="show-table">
          Show
          <select
            class="form-control"
            name="show"
            id="show"
            (change)="handlePageSizeChange($event)"
          >
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }}
            </option>
          </select>
          entries
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th>No.</th>
              <th></th>
              <th>Photo</th>
              <th>No. Induk</th>
              <th>Nama</th>
              <th>Asal Daerah</th>
              <th>Nama (Arab)</th>
              <th>Angkatan</th>
              <th>JK</th>
              <th>Kelas</th>
              <th>Ayah</th>
              <th>Ibu</th>
              <th>Status</th>
              <th>Kamar</th>
              
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let siswa of listnewstudents | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
              <td>{{ i + 1 + (page - 1) * pageSize }}</td>
              <td class="settings">
                <!-- <div class="dropend"> -->
                  <a
                    href="#"
                    role="button"
                    id="dropdownMenuLink{{ i }}"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="./assets/images/icon-setting.svg" alt="" />
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    
                    <li>
                      <a (click)="openModal(mymodal, siswa.registrant_id)" class="dropdown-item">
                        <img src="./assets/images/dropdown/icon-lock.png" alt="" />
                        Reset Password
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <img src="./assets/images/dropdown/icon-user.png" alt="" />
                        Reset Username
                      </a>
                    </li>

                    <li><hr class="dropdown-divider" /></li>
                    
                    <li>
                      <a (click)="goToLoginSantri(siswa.id)" class="dropdown-item">
                        <img src="./assets/images/dropdown/icon-delete.png" alt="" />
                        Login Santri
                      </a>
                    </li>
                  </ul>
                <!-- </div> -->
              </td>
              <td><img width="40px" [src]="siswa.photo" class="img-thumbnail" (error)="handleMissingImage($event)" /></td>
              <td>{{ siswa.no_induk }}</td>
              <td>{{ siswa.nama_lengkap }}</td>
              <td></td>
              <td>{{ siswa.nama_arab }}</td>
              <td>{{ siswa.angkatan }}</td>
              <td>{{ siswa.gender }}</td>
              <td>{{ siswa.siswaKelas !== null ? siswa.siswaKelas.Kelas.nama_kelas : '' }}</td>
              <td>{{ siswa.nama_ayah }}</td>
              <td>{{ siswa.nama_ibu }}</td>
              <td>{{ siswa.status }}</td>
              <td></td>
              
            </tr>
            <tr *ngIf="listnewstudents.length === 0" class="empty">
              <td colspan="14">
                Tidak terdapat data atau data tidak ditemukan.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
        <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
          <div></div>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center pagination-number">
              <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                <a
                  class="page-link"
                  (click)="p.setCurrent(page.value)"
                  *ngIf="p.getCurrent() !== page.value"
                >
                  {{ page.label }}
                </a>
                <a class="page-link" *ngIf="p.getCurrent() === page.value">
                  {{ page.label }}
                </a>
              </li>
            </ul>
          </nav>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="p.isFirstPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isFirstPage()"
                  (click)="p.previous()"
                  aria-label="Previous"
                >
                  <span>Previous</span>
                </a>
              </li>
              <li class="page-item" [class.disabled]="p.isLastPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isLastPage()"
                  (click)="p.next()"
                  aria-label="Next"
                >
                  <span>Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </pagination-template>

      <ng-template #emptyPagination>
        <div class="pagination-wrapper">
          <div>&nbsp;</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #mymodal let-modal>
  <div class="modal-body">
    <!-- <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button> -->
    <button
      type="button"
      class="my-btn-close btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
    <div class="modal-form">
      <h1 class="text-center">Change Password</h1>
      <form [formGroup]="formChangePassword" (ngSubmit)="onSubmitChangePassword()">
        <div class="mb-3 mt-4">
          <label for="exampleInputEmail1" class="form-label">Password</label>
          <input
            type="password"
            formControlName="password"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
          />
          <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
            <div *ngIf="f['password'].errors['required']">
              Password is required
            </div>
            <div *ngIf="f['password'].errors['minlength']">
              Password must be at least 6 characters
            </div>
            <div *ngIf="f['password'].errors['maxlength']">
              Username must not exceed 40 characters
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Confirm Password</label>
          <input
            type="password"
            formControlName="confirmPassword"
            class="form-control"
            [ngClass]="{'is-invalid': submitted && f['confirmPassword'].errors}"
          />
          <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
            <div *ngIf="f['confirmPassword'].errors['required']">
              Confirm Password is required
            </div>
            <div *ngIf="f['confirmPassword'].errors['matching']">
              Confirm Password does not match
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary mt-3 my-btn-save">
          SAVE
        </button>
        <!-- <p>Not a member? <a href="#">Signup now</a></p> -->
      </form>
    </div>
  </div>

  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div> -->
</ng-template>

<ng-template #tahapanModal let-modal>
  <div class="modal-body">
    <!-- <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button> -->
    <button
      type="button"
      class="my-btn-close btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
    <div class="modal-form">
      <h1 class="text-center">Ubah Tahapan Pendaftaran</h1>
      <form
        [formGroup]="formChangeTahapanPendaftaran"
        (ngSubmit)="onSubmitChangeTahapanPendaftaran()"
      >
        <div class="mb-3 mt-4">
          <label for="exampleInputEmail1" class="form-label">Nama</label>
          <input
            readonly
            [disabled]="true"
            formControlName="nama_lengkap"
            type="text"
            class="form-control"
          />
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Tahapan Pendafatarn</label>
          <select
            formControlName="current_status"
            [ngClass]="{'is-invalid': submitted && g['current_status'].errors}"
            class="form-select"
          >
            <option value="" selected disabled>Pilih</option>
            <option [value]="data" *ngFor="let data of listTahapanPendaftaran">
              {{ data }}
            </option>
          </select>
          <div *ngIf="submitted && g['current_status'].errors" class="invalid-feedback">
            <div *ngIf="g['current_status'].errors['required']">
              Item Biaya is required
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary mt-3 my-btn-save">
          SAVE
        </button>
        <!-- <p>Not a member? <a href="#">Signup now</a></p> -->
      </form>
    </div>
  </div>

  <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div> -->
</ng-template>