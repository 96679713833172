<div class="list-options mt-4">
  <div class="search">
    <input
      type="text"
      class="form-control"
      placeholder="Berita..."
      name="search-list"
      id="search-list"
      [(ngModel)]="searchTerm"
    />
    <div class="icon" (click)="search()">
      <img src="./assets/images/icon-search.png" alt="Search" />
    </div>
  </div>
</div>

<div class="table-wrapper">
  <div class="table-options">
    <div class="show-table">
      Show
      <select
        class="form-control"
        name="show"
        id="show"
        (change)="handlePageSizeChange($event)"
      >
        <option *ngFor="let size of pageSizes" [ngValue]="size">
          {{ size }}
        </option>
      </select>
      entries
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th rowspan="2" class="align-middle">Nama / Bulan</th>
          <th colspan="13" class="align-middle">Total Berita</th>
        </tr>
        <tr>
          <td>JAN</td>
          <td>FEB</td>
          <td>MAR</td>
          <td>APR</td>
          <td>MEI</td>
          <td>JUN</td>
          <td>JUL</td>
          <td>AGT</td>
          <td>SEP</td>
          <td>OKT</td>
          <td>NOV</td>
          <td>DES</td>
          <td>Total</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let siswa of listMontlhyNews | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
          <td>{{ i + 1 + (page - 1) * pageSize }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr *ngIf="listMontlhyNews.length === 0" class="empty">
          <td colspan="14">Tidak terdapat data atau data tidak ditemukan.</td>
        </tr>
      </tbody>
    </table>
  </div>

  <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
    <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
      <div></div>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center pagination-number">
          <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
            <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
              {{ page.label }}
            </a>
            <a class="page-link" *ngIf="p.getCurrent() === page.value">
              {{ page.label }}
            </a>
          </li>
        </ul>
      </nav>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="p.isFirstPage()">
            <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
              <span>Previous</span>
            </a>
          </li>
          <li class="page-item" [class.disabled]="p.isLastPage()">
            <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
              <span>Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </pagination-template>

  <ng-template #emptyPagination>
    <div class="pagination-wrapper">
      <div>&nbsp;</div>
    </div>
  </ng-template>
</div>
