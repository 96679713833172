<div class="tabs-title">Laporan Per Siswa</div>

<div class="col-12 col-lg-12">
  <div class="card">
      
      <div class="card-body">
          <form [formGroup]="formLaporanAbsensi">
              <div class="row">
                  <div class="col-12 col-lg-12">
                    
                    <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="name" class="form-label">Tahun Ajaran</label>
                            </div>
                            <div class="col-10">
                                <select
                                    (change)="changeTahunAjaran($event)" 
                                    formControlName="id_tahun_ajaran" [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }" 
                                    class="form-select">
                                    <option [value]="" selected disabled>Pilih TA</option>
                                    <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                                </select>
                            </div>                            
                        </div>
                    </div>                    
                    
                    <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="name" class="form-label">Bulan</label>
                            </div>
                            <div class="col-10">
                                <select (change)="changeBulan($event)" formControlName="bulan" [ngClass]="{ 'is-invalid': submitted && f['bulan'].errors }" class="form-select">
                                    <option value="" selected>Pilih Bulan</option>
                                    <option [value]="month" *ngFor="let month of listMonth">{{ month }}</option>
                                </select>
                            </div>                            
                        </div>
                    </div>

                    <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="name" class="form-label">Kategori Kamar <span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-10">
                              <select
                                  formControlName="kategori_kamar"
                                  [ngClass]="{ 'is-invalid': submitted && f['kategori_kamar'].errors }"
                                  class="form-select"
                                  (change)="changeKategoriKamar($event)">
                                  <option value="">-Pilih Kategori Kamar-</option>
                                  <option [value]="jenis" *ngFor="let jenis of listKategoriKamar">{{jenis}}</option>
                              </select>
                            </div>
                        </div>
                      </div>
                                                          
                  </div>
                  
              </div>
          </form>
      </div>
  </div>
</div>

<div class="list-options mt-4">
  <!-- <button class="btn btn-blue-grey" (click)="onSubmit()">
    + Tampil
  </button>
  <button type="button" class="btn btn-danger">
    <img src="./assets/images/icon-pdf.png" alt="PDF"> Print PDF
  </button> -->
  <!-- <div class="search">
    <input
      type="text"
      class="form-control"
      placeholder="Nama Siswa.."
      name="search-list"
      id="search-list"
      [(ngModel)]="searchTerm"
    />
    <div class="icon" (click)="search()">
      <img src="./assets/images/icon-search.png" alt="Search" />
    </div>
  </div> -->
</div>

<div class="table-wrapper">
  <div class="table-options">
    <!-- <div class="show-table">
      Show
      <select
        class="form-control"
        name="show"
        id="show"
        (change)="handlePageSizeChange($event)"
      >
        <option *ngFor="let size of pageSizes" [ngValue]="size">
          {{ size }}
        </option>
      </select>
      entries
    </div> -->
  </div>
  <div class="table-responsive" *ngIf="isLaporanPerKelas">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th rowspan="2" class="align-middle">No.</th>
          <th rowspan="2" class="align-middle">Asrama/Rayon</th>
          <th rowspan="2" class="align-middle">Kamar</th>          
          <th rowspan="2" class="align-middle"></th>
        </tr>        
      </thead>
      <tbody>
        <tr *ngFor="let siswa of listKamar | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
          <td>{{ i + 1 + (page - 1) * pageSize }}</td>          
          <td>{{ siswa.RayonKamar.nama_rayon }}</td>
          <td>{{ siswa.nama_kamar }}</td>
          <td>
            <div (click)="showPdf(siswa)" class="btn-kotak-custom tagihan secondary">
              <img src="./assets/images/icon-pdf.png" alt="PDF"> PDF
            </div>
          </td>
         
        </tr>
        <tr *ngIf="listKamar.length === 0" class="empty">
          <td colspan="4">Tidak terdapat data atau data tidak ditemukan.</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="table-responsive" *ngIf="!isLaporanPerKelas">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th rowspan="2" class="align-middle">No.</th>
          <th rowspan="2" class="align-middle">No.Induk</th>
          <th rowspan="2" class="align-middle">Kelas</th>
          <th rowspan="2" class="align-middle">Nama</th>
          <th rowspan="2" class="align-middle">Status</th>
          <th rowspan="2" class="align-middle">Jumlah</th>
          <th rowspan="2" class="align-middle">Tanggal &amp; Jam Pelajaran</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let siswa of listLaporanPerKategori | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
          <td>{{ i + 1 + (page - 1) * pageSize }}</td>
          <td>{{ siswa.no_induk }}</td>
          <td>{{ siswa.nama_kelas }}</td>
          <td>{{ siswa.nama_lengkap }}</td>
          <td>{{ siswa.status }}</td>
          <td>{{ siswa.total }}</td>
          <td>{{ siswa.tanggalJamke }}</td>
          <td></td>
        </tr>
        <tr *ngIf="listLaporanPerKategori.length === 0" class="empty">
          <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
        </tr>
      </tbody>
    </table>
  </div>

  <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
    <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
      <div></div>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center pagination-number">
          <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
            <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
              {{ page.label }}
            </a>
            <a class="page-link" *ngIf="p.getCurrent() === page.value">
              {{ page.label }}
            </a>
          </li>
        </ul>
      </nav>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="p.isFirstPage()">
            <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
              <span>Previous</span>
            </a>
          </li>
          <li class="page-item" [class.disabled]="p.isLastPage()">
            <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
              <span>Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </pagination-template>

  <ng-template #emptyPagination>
    <div class="pagination-wrapper">
      <div>&nbsp;</div>
    </div>
  </ng-template>
</div>
