import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/helper/common.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbsensiService } from 'src/app/services/absensi.service';
import { KelasService } from 'src/app/services/kelas.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';

declare var Datepicker: any;

@Component({
  selector: 'app-pendidikan-laporan',
  templateUrl: './pendidikan-laporan.component.html',
  styleUrls: ['./pendidikan-laporan.component.css'],
})
export class PendidikanLaporanComponent implements OnInit {
  listLaporanPerKelas: any = [];
  listLaporanPerKategori: any = [];
  listLaporanPerKategoriJamke: any = [];
  listSemester: any = [];
  listTahunajaran: any = [];
  listKelas: any = [];
  listTanggalJamKe: any[] = [];
  currentSemester = "";
  errorMessage = '';
  submitted = false;
  pageid = 'proguser';
  searchTerm = '';
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  closeResult: string = '';
  listJenisLaporan = [
    "Laporan Perkelas",
    "Laporan Per-kategori Absen"
  ]
  listKategori = [
    {
      "key":"A",
      "value": "Alpha",
    },
    {
      "key":"S",
      "value": "Sakit",
    },
    {
      "key":"IP",
      "value": "Izin Pribadi",
    },
    {
      "key":"IT",
      "value": "Izin Tugas",
    },
  ]
  currentAngkatan = "";
  currentPeriodeId: any = null;
  isLaporanPerKelas: Boolean = true;

  formLaporanAbsensi: FormGroup = new FormGroup({
    jenis_laporan: new FormControl(''),
    kategori: new FormControl(''),
    id_tahun_ajaran: new FormControl(''),
    id_semester: new FormControl(''),
    kelas_id: new FormControl(''),
    start_date: new FormControl(''),
    end_date: new FormControl(''),    
  });

  constructor(
    private uiService: UiService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private kelasService: KelasService,
    private tahunajaranService: TahunajaranService,
    private absensiService: AbsensiService,
  ) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    const today = this.commonService.convertDate();
    this.initForm();
    this.configureDatePicker();
    this.listSemester = this.commonService.getListSemester();
    this.getCurrentTahunajaran();
    this.getKelas();
    
    // default value
    this.formLaporanAbsensi.controls['start_date'].setValue(today);
    this.formLaporanAbsensi.controls['end_date'].setValue(today);
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add('siswa-baru-list');
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    console.log('handle PAGE', this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  search(): void {
    this.page = 1;
  }

  initForm() {
    this.formLaporanAbsensi = this.formBuilder.group(
      {
        jenis_laporan: ['Laporan Perkelas', Validators.required],
        kategori: ['A', Validators.required],
        id_tahun_ajaran: ['', Validators.required],
        id_semester: ['', Validators.required],
        kelas_id: ['', Validators.required],
        start_date: ['', Validators.required],
        end_date: ['', Validators.required],
      },
    );
  }

  changeSemester(e: any) {
    if (e.target.value !== "") {
      this.formLaporanAbsensi.controls['kelas_id'].enable();
      // this.getDayPeriod();
    } else {
      // this.listDayPeriod = [];
      this.formLaporanAbsensi.controls['jam_pelajaran'].setValue("");
      this.formLaporanAbsensi.controls['jam_pelajaran'].disable();
      this.formLaporanAbsensi.controls['kelas_id'].setValue("");
      this.formLaporanAbsensi.controls['kelas_id'].disable();
    }
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        for (let i=0; i<resdata.Semester.length; i++) {
          const periodeSemester = this.commonService.getCurrentDateBetweenDateRange(resdata.Semester[i].start_date, resdata.Semester[i].end_date);

          if (periodeSemester) {
            this.currentSemester = resdata.Semester[i].semester;
            this.formLaporanAbsensi.controls['id_semester'].setValue(this.currentSemester);
            
            break;    
          }
        }

        this.getAllTahunajaran();

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = {}

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        // console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formLaporanAbsensi.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeKelas(e: any) {

    // this.listAbsensiSiswa = [];
    // this.formInputInitGuru.controls['jam_pelajaran'].setValue("");
    // this.currentJadwalPelajaranId = null;
    // this.formInputInitGuru.controls['mata_pelajaran'].setValue("");
    // this.formInputInitGuru.controls['studi_id'].setValue("");
    // this.formInputInitGuru.controls['judul_materi'].setValue("");
    
    // if (e.target.value !== "") {
    //   this.formInputInitGuru.controls['jam_pelajaran'].enable();
    //   const kelas_id = e.target.value;
    //   this.currentKelasId = kelas_id;
    //   this.getWalikelasByKelas(kelas_id);
    // } else {
    //   this.formInputInitGuru.controls['jam_pelajaran'].disable();
    // }

  }

  changeJenisLaporan(e: any) {
    console.log(e.target.value);
    
    this.isLaporanPerKelas = e.target.value === "Laporan Perkelas";
    if (!this.isLaporanPerKelas) {
      this.formLaporanAbsensi.controls['kelas_id'].clearValidators();
      this.formLaporanAbsensi.controls['kelas_id'].updateValueAndValidity();
    } else {
      this.formLaporanAbsensi.controls['kelas_id'].setValidators([Validators.required]);
      this.formLaporanAbsensi.controls['kelas_id'].updateValueAndValidity();
    }

  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    // this.currentAngkatan = e.target.value;

    // this.getSebaranKota();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formLaporanAbsensi.controls;
  }

  configureDatePicker() {
    const elem_start_date = document.getElementById('start_date');
    const datepicker_start_date = new Datepicker(elem_start_date, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    });
    
    const elem_end_date = document.getElementById('end_date');
    const datepicker_end_date = new Datepicker(elem_end_date, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    });
  }

  changeStartDateField(e: any) {
    console.log(e.target.value);
    const birthdate = e.target.value;
    this.formLaporanAbsensi.controls['start_date'].setValue(birthdate);
  }

  changeEndDateField(e: any) {
    console.log(e.target.value);
    const birthdate = e.target.value;
    this.formLaporanAbsensi.controls['end_date'].setValue(birthdate);
  }

  onSubmit() {
    if (this.isLaporanPerKelas) {
      this.laporanPerKelas();
    } else {
      this.laporanPerKategori();
    }
  }

  laporanPerKelas() {
    console.log(JSON.stringify(this.formLaporanAbsensi.value, null, 2));
    if (this.formLaporanAbsensi.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    const payload = {
      periode_id: this.formLaporanAbsensi.value.id_tahun_ajaran,
      kelas_id: this.formLaporanAbsensi.value.kelas_id,
      start_date: this.formLaporanAbsensi.value.start_date,
      end_date: this.formLaporanAbsensi.value.end_date,
    }
    this.absensiService.getListAbsensiByDateRange(payload).subscribe({
      next: resdata => {
        console.log('list absen', resdata);

        this.listLaporanPerKelas = resdata;


        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });

  }

  laporanPerKategori() {
    console.log(JSON.stringify(this.formLaporanAbsensi.value, null, 2));
    if (this.formLaporanAbsensi.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    const payload = {
      periode_id: this.formLaporanAbsensi.value.id_tahun_ajaran,
      kategori: this.formLaporanAbsensi.value.kategori,
      start_date: this.formLaporanAbsensi.value.start_date,
      end_date: this.formLaporanAbsensi.value.end_date,
    }
    this.absensiService.getListAbsensiPerKategori(payload).subscribe({
      next: resdata => {
        console.log('list absen', resdata);

        for (let t=0; t<resdata.length; t++) {
          resdata[t].tanggalJamke = [];
          this.listTanggalJamKe[resdata[t].id] = [];
        }

        this.listLaporanPerKategori = resdata;

        this.laporanPerKategoriJamke();

        // this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });

  }

  laporanPerKategoriJamke() {

    this.uiService.showLoader();

    const payload = {
      periode_id: this.formLaporanAbsensi.value.id_tahun_ajaran,
      kategori: this.formLaporanAbsensi.value.kategori,
      start_date: this.formLaporanAbsensi.value.start_date,
      end_date: this.formLaporanAbsensi.value.end_date,
    }
    this.absensiService.getListAbsensiPerKategoriJamke(payload).subscribe({
      next: resdata => {
        console.log('list absen jamke', resdata);
        const tglJamke: any[][] = [];
        for (let x=0; x<resdata.length; x++) {
          this.listTanggalJamKe[resdata[x].id].push(resdata[x].tanggal + "/" + resdata[x].jamke);
        }
        // this.listLaporanPerKategoriJamke = resdata;
        console.log("listTglJamke", this.listTanggalJamKe);
        
        // finnaly set tanggal jamke
        for (let c=0; c<this.listLaporanPerKategori.length; c++) {
          this.listLaporanPerKategori[c].tanggalJamke = this.listTanggalJamKe[this.listLaporanPerKategori[c].id];
        }

        console.log("FINAL DATA", this.listLaporanPerKategori);
        

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });

  }
}
