import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-simulasi',
  templateUrl: './simulasi.component.html',
  styleUrls: ['./simulasi.component.css']
})
export class SimulasiComponent implements OnInit {

  submitted = false;
  submittedPay = false;
  readyToPay = false;

  formVirtualAccount: FormGroup = new FormGroup({
    virtual_account: new FormControl(''),    
  });

  formPayVirtualAccount: FormGroup = new FormGroup({
    virtual_account: new FormControl(''),
    id_virtual_account: new FormControl(''),
    amount: new FormControl(''),
    status: new FormControl(''),    
  });

  constructor(
    private formBuilder: FormBuilder,
  ) { 
    this.changeBodyClass();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.formPayVirtualAccount = this.formBuilder.group(
      {
        virtual_account: ['', Validators.required],
        id_virtual_account: ['', Validators.required],
        amount: ['', Validators.required],
        status: ['completed', Validators.required],
      },
    );

    this.formVirtualAccount = this.formBuilder.group(
      {
        virtual_account: ['', Validators.required],
      },
    );
  }

  onSubmitformVa() {
    this.submitted = true;
    console.log(JSON.stringify(this.formVirtualAccount.value, null, 2));

    if (this.formVirtualAccount.invalid) {
      return;
    }

    
  }

  onSubmitformPayVa() {
    this.submittedPay = true;
    console.log(JSON.stringify(this.formPayVirtualAccount.value, null, 2));

    if (this.formPayVirtualAccount.invalid) {
      return;
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formVirtualAccount.controls;
  }

  get g(): { [key: string]: AbstractControl } {
    return this.formPayVirtualAccount.controls;
  }

}
