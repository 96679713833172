import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import { AbsensiService } from 'src/app/services/absensi.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-list-absen-kamar',
  templateUrl: './list-absen-kamar.component.html',
  styleUrls: ['./list-absen-kamar.component.css']
})
export class ListAbsenKamarComponent implements OnInit {

  listInput: any = [];
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  closeResult: string = '';

  constructor(
    private uiService: UiService,
    private absensiService: AbsensiService,
    private sharedDataService: SharedDataService,
  ) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getListGuruInput();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add('siswa-baru-list');
    }
  }

  getListGuruInput() {
    const payload = {
      // jadwal_pelajaran_id: this.currentJadwalPelajaranId,
      // tanggal: today
    }

    console.log("GI PAYLOAD", payload);
    

    this.absensiService.getListGuruInputKamar(payload).subscribe({
      next: resdata => {
        console.log("List Guru Input ", resdata);

        this.listInput = resdata;        

        this.uiService.hideLoader();      

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  showDetailAbsensiHarianSiswa(object: any, absensi: String) {
    object.absen = absensi;
    this.sharedDataService.changeDataObject(object);
    this.sharedDataService.changeComponent('detail-absensi-kamar');
    this.uiService.showFullScrrenContent();
  }

  handlePageChange(event: number): void {
    this.page = event;
    console.log('handle PAGE', this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  search(): void {
    this.page = 1;
  }

}
