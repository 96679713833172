
<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="lembaga-list" role="tabpanel" aria-labelledby="list-tab">
        <div class="tabs-title">
            LIST DATA LEMBAGA
        </div>
        <div class="list-options">
            
            <div class="search">
                <input type="text" class="form-control" placeholder="Search.." name="search-list" id="search-list" [(ngModel)]="nama">
                <div class="icon" (click)="search()">
                    <img src="./assets/images/icon-search.png" alt="Search">
                </div>
            </div>
            
            <a (click)="openModal(addLembaga)" class="btn btn-blue-grey">
                + Lembaga
            </a>
        </div>
        <div class="table-wrapper">
            <div class="table-options">
                <div class="show-table">
                    Show <select class="form-control" name="show" id="show" (change)="handlePageSizeChange($event)">
                        <option *ngFor="let size of pageSizes" [ngValue]="size">
                          {{ size }}
                        </option>
                    </select> entries
                    <!-- Show<input type="number" class="form-control" name="show" id="show" value="10">entries -->
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Urutan</th>
                            <th>Nama</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngFor="
                    let lembaga of listLembaga | paginate : {
                            id: pageid,
                            itemsPerPage: pageSize,
                            currentPage: page,
                            totalItems: count
                        };
                    let i = index
                    ">
                        <tr>
                            <td>{{ i + 1 + ((page - 1) * pageSize) }}</td>
                            <td>{{ lembaga.urut }}</td>
                            <td>{{ lembaga.nama_lembaga }}</td>
                            <td></td>
                            <td class="settings">
                                <div class="dropend">
                                    <a href="#" role="button" id="dropdownMenuLink"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="./assets/images/icon-setting.svg" alt="">
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li><a (click)="openModalEdit(addLembaga, lembaga)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>                            
                                        <li><a (click)="confirmDelete(lembaga.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                                    </ul>
                                </div>
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
                <div class="pagination-wrapper">
                    <div></div>
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center pagination-number">
                            <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                                <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ page.label }}</a>
                                
                                <a class="page-link" *ngIf="p.getCurrent() === page.value">{{ page.label }}</a>

                            </li>
                        </ul>
                    </nav>
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" [class.disabled]="p.isFirstPage()">
                                <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                                    <span>Previous</span>
                                </a>
                            </li>
                            <li class="page-item" [class.disabled]="p.isLastPage()">
                                <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                                    <span>Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </pagination-template>

        </div>

        
    </div>
    
</div>


<ng-template #addLembaga let-modal>
    <div class="modal-body">
        <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
        <div class="modal-form">
            <h1 class="text-center" *ngIf="isNew">Tambah Lembaga</h1>
            <h1 class="text-center" *ngIf="!isNew">Edit Lembaga</h1>
            <form [formGroup]="formAddLembaga" (ngSubmit)="onSubmitLembaga()">
                <div class="mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label">Nama Lembaga</label>
                    <input
                        type="text"
                        formControlName="nama_lembaga"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f['nama_lembaga'].errors }"
                    />
                    <div *ngIf="submitted && f['nama_lembaga'].errors" class="invalid-feedback">
                        <div *ngIf="f['nama_lembaga'].errors['required']">Nama Lembaga is required</div>
                        <div *ngIf="f['nama_lembaga'].errors['minlength']">
                        Nama Lembaga must be at least 6 characters
                        </div>
                        <div *ngIf="f['nama_lembaga'].errors['maxlength']">
                        Nama Lembaga must not exceed 40 characters
                        </div>
                    </div>
                </div>
                <div class="mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label">Urutan</label>
                    <input
                        type="number"
                        formControlName="urut"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f['urut'].errors }"
                    />
                    <div *ngIf="submitted && f['urut'].errors" class="invalid-feedback">
                        <div *ngIf="f['urut'].errors['required']">Nama Lembaga is required</div>
                    </div>
                </div>
                <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isNew">Add</button>
                <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="!isNew">Update</button>
            </form>
        </div>
    </div>
</ng-template>