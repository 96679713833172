import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';


@Component({
  selector: 'app-jadwal-mengajar-per-kelas',
  templateUrl: './jadwal-mengajar-per-kelas.component.html',
  styleUrls: ['./jadwal-mengajar-per-kelas.component.css']
})
export class JadwalMengajarPerKelasComponent implements OnInit {

  listJadwalMengajarPerKElas: any = [];

  constructor(
    private uiService: UiService,
  ) { }

  ngOnInit(): void {
  }

  closeButton() {
    this.uiService.hideFullScrrenContent();
  }

  printPage() {
    window.print();
  }

}
