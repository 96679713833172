<div class="tab-content" id="myTabContent">
    <div *ngIf="backUrl !== ''" class="back-button" (click)="onBack()">
        <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
    </div>
  <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
      <div class="tabs-title">
          LIST DATA content
      </div>
      <div class="list-options">
        
      </div>
      <div class="table-wrapper">
          
          <div class="table-responsive">
              <table class="table table-borderless">
                  <thead>
                      <tr>
                          <th>No.</th>
                          <th>Content</th>
                          <th> </th>
                      </tr>
                  </thead>
                  <tbody>                        
                      <tr
                      *ngFor="
                          let content of listContent 
                          let i = index
                      "
                      >
                          <td>{{ i + 1 }}</td>
                          <td>{{ content.name }}</td>

                          <td class="settings">
                            <a routerLink="/settings/contentregis/edit/{{content.type}}" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a>
                          </td>
                      </tr>
                      <tr *ngIf="listContent.length === 0" class="empty">
                          <td colspan="5">Tidak terdapat data atau data tidak ditemukan.</td>
                      </tr>
                      
                  </tbody>
              </table>
          </div>

      </div>

      
  </div>
  
</div>
