<app-nav-tab></app-nav-tab>

<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="statistik"
    role="tabpanel"
    aria-labelledby="statistik-tab"
  >
    <div class="tabs-title">Penentuan Kelas/Kamar Siswa Baru</div>

    
    <div class="list-options">
      
      <div class="search">
        <input
        type="text"
        class="form-control"
        placeholder="Search.."
        name="search-list"
        id="search-list"
        [(ngModel)]="searchTerm"
        />
        <div class="icon" (click)="search()">
          <img src="./assets/images/icon-search.png" alt="Search" />
        </div>
      </div>
      
    </div>
    
    <div class="table-wrapper">
      <div class="table-options">
        <div class="show-table">
          Show
          <select
            class="form-control"
            name="show"
            id="show"
            (change)="handlePageSizeChange($event)"
          >
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }}
            </option>
          </select>
          entries
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th>No.</th>
              <th>No. Induk</th>
              <th>Photo</th>
              <th>Nama</th>
              <th>Angkatan</th>
              <th>Lembaga</th>
              <th>Tingkatan</th>
              <th>Jenis Kelamin</th>
              <th>Kelas</th>
              <th>Kamar</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let siswa of listnewstudents | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
              <td>{{ i + 1 + (page - 1) * pageSize }}</td>
              <td>{{ siswa.no_induk }}</td>
              <td></td>
              <td>{{ siswa.nama_lengkap }}</td>
              <td>{{ siswa.angkatan }}</td>
              <td>{{ siswa.lembaga.nama_lembaga }}</td>
              <td>{{ siswa.tingkat.nama_tingkat }}</td>
              <td>{{ siswa.gender }}</td>
              <td>{{ siswa.siswaKelas !== null ? siswa.siswaKelas.Kelas.nama_kelas : '' }}</td>
              <td>{{ siswa.siswaKamar !== null ? siswa.siswaKamar.Kamar.nama_kamar : '' }}</td>
              <td class="settings">
                <!-- <div class="dropend"> -->
                  <a
                    href="#"
                    role="button"
                    id="dropdownMenuLink{{ i }}"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="./assets/images/icon-setting.svg" alt="" />
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">                                        
                    <li>
                      <a (click)="openModalPenentuanKelas(addPenentuanKelas, siswa)" class="dropdown-item">
                        <img src="./assets/images/dropdown/icon-create.png" alt="" />
                        Penentuan Kelas
                      </a>
                    </li>

                    <li>
                      <a (click)="openModalPenentuanKamar(addPenentuanKamar, siswa)" class="dropdown-item">
                        <img src="./assets/images/dropdown/icon-create.png" alt="" />
                        Penentuan Kamar
                      </a>
                    </li>
          
                    <li>
                      <a class="dropdown-item" href="#">
                        <img src="./assets/images/dropdown/icon-user.png" alt="" />
                        Generate No. Induk
                      </a>
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li>
                      <a (click)="confirmDelete(siswa.id)" class="dropdown-item">
                        <img src="./assets/images/dropdown/icon-delete.png" alt="" />
                        Hapus
                      </a>
                    </li>
                  </ul>
                <!-- </div> -->
              </td>
              
            </tr>
            <tr *ngIf="listnewstudents.length === 0" class="empty">
              <td colspan="11">
                Tidak terdapat data atau data tidak ditemukan.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
        <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
          <div></div>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center pagination-number">
              <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                <a
                  class="page-link"
                  (click)="p.setCurrent(page.value)"
                  *ngIf="p.getCurrent() !== page.value"
                >
                  {{ page.label }}
                </a>
                <a class="page-link" *ngIf="p.getCurrent() === page.value">
                  {{ page.label }}
                </a>
              </li>
            </ul>
          </nav>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="p.isFirstPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isFirstPage()"
                  (click)="p.previous()"
                  aria-label="Previous"
                >
                  <span>Previous</span>
                </a>
              </li>
              <li class="page-item" [class.disabled]="p.isLastPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isLastPage()"
                  (click)="p.next()"
                  aria-label="Next"
                >
                  <span>Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </pagination-template>

      <ng-template #emptyPagination>
        <div class="pagination-wrapper">
          <div>&nbsp;</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #addPenentuanKelas let-modal>
  <div class="modal-body">
      <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
      <div class="modal-form">
          <h1 class="text-center">Penentuan Kelas</h1>
          
          <form [formGroup]="formPenentuanKelas" (ngSubmit)="onSubmitPenentuanKelas()">
              <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">Nama</label>
                  <input
                      formControlName="nama"
                      class="form-control"
                      type="text" readonly
                  />

              </div>
              <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">Kelas</label>
                  <select 
                      formControlName="kelas_id"
                      [ngClass]="{ 'is-invalid': submitted && f['kelas_id'].errors }"
                      class="form-select">
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="data.id" *ngFor="let data of listKelas">{{data.nama_kelas}}</option>
                  </select>
                  <div *ngIf="submitted && f['kelas_id'].errors" class="invalid-feedback">
                      <div *ngIf="f['kelas_id'].errors['required']">Kelas is required</div>
                  </div>
              </div>
              <button type="submit" class="btn btn-primary mt-3 my-btn-save">Save</button>
              
          </form>
      </div>
  </div>
</ng-template>

<ng-template #addPenentuanKamar let-modal>
  <div class="modal-body">
      <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
      <div class="modal-form">
          <h1 class="text-center">Penentuan Kamar</h1>
          
          <form [formGroup]="formPenentuanKamar" (ngSubmit)="onSubmitPenentuanKamar()">
              <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">Nama</label>
                  <input
                      formControlName="nama"
                      class="form-control"
                      type="text" readonly
                  />

              </div>
              <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">Asrama</label>
                  <select
                      (change)="changeRayon($event)"
                      formControlName="id_rayon"
                      [ngClass]="{ 'is-invalid': submitted && k['id_rayon'].errors }"
                      class="form-select">
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="data.id" *ngFor="let data of listRayon">{{data.nama_rayon}}</option>
                  </select>
                  <div *ngIf="submitted && k['id_rayon'].errors" class="invalid-feedback">
                      <div *ngIf="k['id_rayon'].errors['required']">Kelas is required</div>
                  </div>
              </div>
              <div class="mb-3 mt-4">
                <label for="exampleInputEmail1" class="form-label">Kamar</label>
                <select
                    (change)="changeKamar($event, listKamar)" 
                    formControlName="id_kamar"
                    [ngClass]="{ 'is-invalid': submitted && k['id_kamar'].errors }"
                    class="form-select">
                    <option value="" selected disabled>Pilih</option>
                    <option [value]="data.id" *ngFor="let data of listKamar">{{data.nama_kamar}}</option>
                </select>
                <div *ngIf="submitted && k['id_kamar'].errors" class="invalid-feedback">
                    <div *ngIf="k['id_kamar'].errors['required']">Kelas is required</div>
                </div>
              </div>
              <div class="mb-3 mt-4">
                <label for="exampleInputEmail1" class="form-label">Nomor Urut</label>
                <select 
                    formControlName="nomor_urut"
                    [ngClass]="{ 'is-invalid': submitted && k['nomor_urut'].errors }"
                    class="form-select">
                    <option value="" selected disabled>Pilih</option>
                    <option [value]="data.no" *ngFor="let data of listNomorUrut">{{data.no}} - {{ data.nama }}</option>
                </select>
                <div *ngIf="submitted && k['nomor_urut'].errors" class="invalid-feedback">
                    <div *ngIf="k['nomor_urut'].errors['required']">Kelas is required</div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary mt-3 my-btn-save">Save</button>
              
          </form>
      </div>
  </div>
</ng-template>

<ng-template #tahapanModal let-modal>
  <div class="modal-body">
    <!-- <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button> -->
    <button
      type="button"
      class="my-btn-close btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
    <div class="modal-form">
      <h1 class="text-center">Ubah Tahapan Pendaftaran</h1>
      <form
        [formGroup]="formChangeTahapanPendaftaran"
        (ngSubmit)="onSubmitChangeTahapanPendaftaran()"
      >
        <div class="mb-3 mt-4">
          <label for="exampleInputEmail1" class="form-label">Nama</label>
          <input
            readonly
            [disabled]="true"
            formControlName="nama_lengkap"
            type="text"
            class="form-control"
          />
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Tahapan Pendafatarn</label>
          <select
            formControlName="current_status"
            [ngClass]="{'is-invalid': submitted && g['current_status'].errors}"
            class="form-select"
          >
            <option value="" selected disabled>Pilih</option>
            <option [value]="data" *ngFor="let data of listTahapanPendaftaran">
              {{ data }}
            </option>
          </select>
          <div *ngIf="submitted && g['current_status'].errors" class="invalid-feedback">
            <div *ngIf="g['current_status'].errors['required']">
              Item Biaya is required
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary mt-3 my-btn-save">
          SAVE
        </button>
        <!-- <p>Not a member? <a href="#">Signup now</a></p> -->
      </form>
    </div>
  </div>

  <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div> -->
</ng-template>
