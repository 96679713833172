import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SiswaService } from 'src/app/services/siswa.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { SiswakelasService } from 'src/app/services/siswakelas.service';
import { ExcelService } from 'src/app/services/excel.service';
import { CommonService } from 'src/app/helper/common.service';
import { TingkatService } from 'src/app/services/tingkat.service';
import { SettingInsyaDailyService } from 'src/app/services/setting-insya-daily.service';


@Component({
  selector: 'app-insya-daily',
  templateUrl: './insya-daily.component.html',
  styleUrls: ['./insya-daily.component.css']
})
export class InsyaDailyComponent implements OnInit {

  listSetttingInsyaDaily: any = [];
  listTahunajaran: any = [];
  listSemester: any = [];
  listsTingkat: any = [];
  currentPeriodeId: any = null;
  currentAngkatan = "";
  currentSemester = "";
  currentKelasId: any = null;
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  isShowLoader: Boolean = false;
  searchPeringkatKelas = "";
  closeResult: string = '';
  listTipe = [
    "Insya",
    "Daily",
    "Ibadah Amaliah"
  ]
  listJumlahTugas = [1,2,3,4,5,6,7,8,9,10,11,12]
  currentId = ""

  formAction: FormGroup = new FormGroup({
    tipe: new FormControl(''),
    id_tahun_ajaran: new FormControl(''),
    semester: new FormControl(''),
    id_tingkat: new FormControl(''),
    jumlah_tugas: new FormControl(''),
  });

  constructor(private siswaService: SiswaService,
    private siswakelasService: SiswakelasService,
    private uiService: UiService,
    private modalService: NgbModal,
    private tahunajaranService: TahunajaranService,
    private excelService: ExcelService,
    private commonService: CommonService,
    private tingkatService: TingkatService,
    private settingInsyaDailyService: SettingInsyaDailyService,
    private formBuilder: FormBuilder) {
    this.changeBodyClass();
    
  }

  ngOnInit(): void {

    this.getListTingkat();
    this.getCurrentTahunajaran();
    this.listSemester = this.commonService.getListSemester();
    this.initForm();
  }

  initForm() {
    this.formAction = this.formBuilder.group(
      {
        tipe: ['', Validators.required],
        id_tahun_ajaran: ['', Validators.required],
        semester: ['', Validators.required],
        id_tingkat: ['', Validators.required],
        jumlah_tugas: ['', Validators.required],
      },
    );
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAction.controls;
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    this.currentPeriodeId = e.target.value;

    this.getListSettingInsyaDaily();
  }

  changeSemester(e: any) {
    this.currentSemester = e.target.value;

    this.getListSettingInsyaDaily();
  }

  changeKelas(e: any) {
    this.currentKelasId = e.target.value;
  }

  onSubmitAddFormAction(modal: any): void {
    this.submitted = true;
    console.log(JSON.stringify(this.formAction.value, null, 2));

    if (this.formAction.invalid) {
      return;
    }

    this.modalService.dismissAll();

    this.uiService.showLoader();

    this.settingInsyaDailyService.add(this.formAction.value).subscribe({
      next: resdata => {
        console.log(resdata);

        this.uiService.hideLoader();
        this.uiService.showSuccess('');

        this.getListSettingInsyaDaily();

      },
      error: err => {
        console.log(err);

        this.errorMessage = err.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });

  }

  onSubmitEditFormAction(modal: any): void {
    this.submitted = true;
    console.log(JSON.stringify(this.formAction.value, null, 2));

    if (this.formAction.invalid) {
      return;
    }

    this.modalService.dismissAll();

    this.uiService.showLoader();

    this.settingInsyaDailyService.update(this.currentId, this.formAction.value).subscribe({
      next: resdata => {
        console.log(resdata);

        this.uiService.hideLoader();
        this.uiService.showSuccess('');

        this.getListSettingInsyaDaily();

      },
      error: err => {
        console.log(err);

        this.errorMessage = err.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });

  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        this.currentPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        for (let i=0; i<resdata.Semester.length; i++) {
          const periodeSemester = this.commonService.getCurrentDateBetweenDateRange(resdata.Semester[i].start_date, resdata.Semester[i].end_date);

          if (periodeSemester) {
            this.currentSemester = resdata.Semester[i].semester;
            this.formAction.controls['semester'].setValue(this.currentSemester);
            
            // this.getAllJadwalPelajaran();

            break;    
          }
        }        

        this.uiService.hideLoader();

        this.getAllTahunajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formAction.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        this.getListSettingInsyaDaily();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getListSettingInsyaDaily(tahunAjaran: string = "", semester: string = "") {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    params['id_tahun_ajaran'] = tahunAjaran === "" ? this.currentPeriodeId : tahunAjaran;
    params['semester'] = semester === "" ? this.currentSemester : semester;

    this.settingInsyaDailyService.getAllsettinginsyadaily(params).subscribe({
      next: resdata => {
        console.log('list setting', resdata);
        
        const { data, totalrows } = resdata;

        this.listSetttingInsyaDaily = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onFileChange(evt: any) {
    const fdata: FileReader = this.excelService.convertExcelFileToJson(evt.target);

    fdata.onloadend = (e: any) => {
      // console.log("END OF ROAD", this.excelService.dataExcel);
      const fileUpload: any[] = this.excelService.dataExcel;
      const finalFileUpload = fileUpload.slice(1);

      if (finalFileUpload.length > 0) {
        let dataToUpload = [];
        for (const k in finalFileUpload) {
          const v = finalFileUpload[k];
          console.log(k, v);
          // 1. get periode_id
          // 2. get kelas_id
          // 3. get siswaid
          if (v.length === 0) {
            break;
          }
          const column = [v[1],v[4],v[5],v[6],v[7]];
          dataToUpload.push(column)
        }
        console.log("DATA TO UPLOAD", dataToUpload);
        
        const payload = {
          data: dataToUpload
        }
        
        this.siswakelasService.uploadPeringkat(payload).subscribe({
          next: resdata => {
            console.log('RESPONSE', resdata);
            
            this.uiService.hideLoader();
            this.uiService.showSuccess("Upload Success")
    
          },
          error: err => {
            this.errorMessage = err.error.message;
            console.log(this.errorMessage);
    
            this.uiService.hideLoader();
    
            this.uiService.showError(this.errorMessage);
          }
        });
      } 
    }
  }

  confirmDelete(id: String) {
    
    this.uiService.showConfirmDelete(this, id);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  openAddModalFormInsyaDaily(content:any) {
    this.resetForm();
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openEditModalFormInsyaDaily(content:any, data: any) {
    console.log("SETTINGS", data);
    this.currentId = data.id;

    this.formAction.patchValue(data);
    
    this.submitted = false;
    
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getListTingkat() {
    
    this.tingkatService.getAllTingkat({}).subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsTingkat = resdata;
        this.formAction.controls['id_tingkat'].setValue('');
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  resetForm() {
    this.submitted = false;
    this.formAction.reset();
    this.initForm();
    this.formAction.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
    this.formAction.controls['semester'].setValue(this.currentSemester);
  }

}
