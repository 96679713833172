import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { GelombangPendafataranService } from 'src/app/services/gelombang-pendafataran.service';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-gelombang-pendaftaran-list',
  templateUrl: './gelombang-pendaftaran-list.component.html',
  styleUrls: ['./gelombang-pendaftaran-list.component.css']
})
export class GelombangPendaftaranListComponent implements OnInit {

  listGelombang: any = [];
  currentPeriode: any;
  errorMessage = '';
  backUrl: string = "";

  formGelombangPendaftaran: FormGroup = new FormGroup({
    id: new FormControl(''),
  });
  submitted = false;

  pageid = "proguser";

  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;

  closeResult: string = '';

  constructor(
    private tahunajaranService: TahunajaranService, 
    private gelombangPendaftaranService: GelombangPendafataranService, 
    private uiService: UiService, 
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getCurrentPeriode();
    this.getAllGelombangPendaftaran();

    this.route.queryParams
      .subscribe(params => {
        // hardcode for a while
        this.backUrl = '/siswa/statistik';
        console.log(params); // { orderby: "price" }
        let result = params.hasOwnProperty('backUrl');
        console.log(result);

        if (result === true) {
          this.backUrl = params['backUrl'];
          console.log(this.backUrl); // price
        }        
      }
    );
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
   openModal(content:any, id: any) {
    this.submitted = false;
    this.formGelombangPendaftaran.reset();
    this.formGelombangPendaftaran.controls['id'].setValue(id);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  onBack() {
    this.router.navigate([this.backUrl]);
  }
     
  /**
   * Write code on Method
   *
   * @return response()
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getCurrentPeriode() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePpsb().subscribe({
      next: resdata => {
        console.log('periode', resdata);
        
        this.currentPeriode = resdata;
        
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllGelombangPendaftaran() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.gelombangPendaftaranService.getAllGelombangPendaftaranByPeriod(params).subscribe({
      next: resdata => {
        console.log('gelombang', resdata);
        
        this.listGelombang = resdata;
        
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }


  get f(): { [key: string]: AbstractControl } {
    return this.formGelombangPendaftaran.controls;
  }

  confirmDelete(id: String) {    
    this.uiService.showConfirmDelete(this, id);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.gelombangPendaftaranService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getAllGelombangPendaftaran();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.error.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, 'd - MM - Y');
  }

}
