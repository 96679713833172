<div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="guru-input"
      role="tabpanel"
      aria-labelledby="guru-input-tab"
    >
      <div class="tabs-title">Print Tagihan</div>
  
      <div class="row">
        <div class="col-12 col-lg-5">
              <div class="card">
                <div class="card-header">
                    Sumber Data Siswa
                </div>
                <div class="card-body">
                    <form [formGroup]="formSumberDataSiswa">
                        <div class="row">
                            <div class="col-12 col-lg-12">
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="nik" class="form-label">Tahun Ajaran <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <select formControlName="id_tahun_ajaran" 
                                                (change)="changeTahunAjaran($event)"
                                                [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }" class="form-select">
                                                <option [value]="" selected disabled>Pilih TA</option>
                                                <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="name" class="form-label">Tipe <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-8">
                                        <select
                                            formControlName="tipe"
                                            [ngClass]="{ 'is-invalid': submitted && f['tipe'].errors }"
                                            class="form-select"
                                            (change)="changeTipe($event)">
                                            <option value="" selected>Pilih Tipe</option>
                                            <option [value]="data" *ngFor="let data of listTipe">{{data}}</option>
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!isShowPerorangan" class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="name" class="form-label">Lembaga <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <select
                                                formControlName="id_lembaga"
                                                [ngClass]="{ 'is-invalid': submitted && f['id_lembaga'].errors }"
                                                class="form-select"
                                                (change)="changeLembaga($event)">
                                                <option value="" selected>Semua</option>
                                                <option [value]="data.id" *ngFor="let data of listsLembaga">{{data.nama_lembaga}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>            
                                <div *ngIf="!isShowPerorangan" class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="name" class="form-label">Tingkatan <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-8">
                                            <select
                                                formControlName="id_tingkat"
                                                [ngClass]="{ 'is-invalid': submitted && f['id_tingkat'].errors }"
                                                class="form-select"
                                                (change)="changeTingkat($event)">
                                                <option value="" selected>Semua</option>
                                                <option [value]="data.id" *ngFor="let data of listsTingkat">{{data.nama_tingkat}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> 
        
                                <div *ngIf="isShowPerorangan" class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-4 text-end">
                                            <label for="name" class="form-label">Nama</label>
                                        </div>
                                        <div class="col-8 autocomplete">
                                            <input formControlName="siswa_name" (keyup)="searchSiswa()" type="text" class="form-control" autocapitalize="off" autocorrect="off" autocomplete="off" id="inputNamaSiswa">
                        
                                            <div id="myInputautocomplete-list" class="autocomplete-items">
                                                <button *ngIf="isShowLoader" class="btn btn-primary" type="button" disabled>
                                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    Searching...
                                                </button>
                                                <div (click)="chooseSiswa(data)" *ngFor="let data of listSiswa">
                                                    <strong>{{ data.nama_lengkap }}</strong>
                                                    <!-- <p>No, Dokumen</p> -->
                                                    <p>No, Registrasi {{ data.regnumber }}</p>
                                                    <p>Tahun masuk {{ data.angkatan }} </p>
                                                </div>                        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isShowPerorangan" class="table-wrapper">
                                <div class="table-options">
                                    <div class="show-table">
                                    
                                    </div>
                                </div>
                                
                                <div class="table-responsive">
                                    <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                        <th>No.</th>
                                        <th>Nama</th>
                                        <th>Kelas</th>
                                        <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        <tr *ngFor="let siswa of listDataSiswaPerorangan; let i = index">
                                            <td>
                                                {{ i + 1 + (page - 1) * pageSize }}
                                            </td>
                                            <td>{{ siswa.nama_lengkap }}</td>
                                            <td>{{ siswa.no_induk }}</td>
                                            
                                            <td>
                                                <button (click)="removeListSiswaPerorangan(i, siswa.id)" type="button" class="btn-close btn-close-blue" aria-label="Close"></button>
                                            </td>
                                        
                                        </tr>
                                                    
                                        
                                        <tr *ngIf="listDataSiswaPerorangan.length === 0" class="empty">
                                            <td colspan="5">
                                            Tidak terdapat data atau data tidak ditemukan.
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                            
                                </div>                           
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-7">
            <div class="card">
                <div class="card-header">
                    Data Tagihan
                </div>
                <div class="card-body">
                    <form [formGroup]="formSumberDataTagihan">
                        <div class="row">
                            <div class="col-12 col-lg-4">
                                <div style="border: 1px solid #d0d2d5;padding: 5px 15px;height: 196px;overflow-y: auto;">
                                    <div>Group Biaya :
                                        <input type="checkbox" formControlName="selectAllCheckbox" (change)="selectAllGroupBiaya($event)">
                                        All
                                    </div>
                                    
                                    <ng-container formArrayName="selectedGroupBiaya">
                                        <div class="checkbox ng-scope" *ngFor="let data of selectedGroupBiaya.controls; let i=index">
                                            <label class="ng-binding">
                                                <input type="checkbox" [value]="listGroupBiaya[i].id" [formControlName]="i" (change)="onCheckboxChange($event)">
                                                {{ listGroupBiaya[i].name }}
                                            </label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-12 col-lg-8">
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-3 text-end">
                                            <label for="mulai" class="form-label">Mulai</label>
                                        </div>
                                        <div class="col-9">
                                            <input type="checkbox" formControlName="from_beginning_tagihan" (change)="onCheckBoxFromStart($event)" />
                                            &nbsp;<label for="dari_awal" class="form-label">Dari awal memiliki tagihan</label>
                                        </div>                                    
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-3 text-end">
                                            <label for="nik" class="form-label">&nbsp;</label>
                                        </div>
                                        <div class="col-5">
                                            <select formControlName="from_bulan_tagihan"                                                 
                                                [ngClass]="{ 'is-invalid': submitted && f['from_bulan_tagihan'].errors }" class="form-select">                                                
                                                <option [value]="idx+1" *ngFor="let data of listMonth; let idx = index">{{data}}</option>
                                            </select>
                                        </div>
                                        <div class="col-4">
                                            <select formControlName="from_tahun_tagihan"                                                 
                                                [ngClass]="{ 'is-invalid': submitted && f['from_tahun_tagihan'].errors }" class="form-select">                                                
                                                <option [value]="data" *ngFor="let data of listYear">{{data}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-3 text-end">
                                            <label for="nik" class="form-label">Sampai</label>
                                        </div>
                                        <div class="col-5">
                                            <select formControlName="end_bulan_tagihan"                                                 
                                                [ngClass]="{ 'is-invalid': submitted && f['end_bulan_tagihan'].errors }" class="form-select">                                                
                                                <option [value]="idx+1" *ngFor="let data of listMonth; let idx = index">{{data}}</option>
                                            </select>
                                        </div>
                                        <div class="col-4">
                                            <select formControlName="end_tahun_tagihan"                                                 
                                                [ngClass]="{ 'is-invalid': submitted && f['end_tahun_tagihan'].errors }" class="form-select">                                                
                                                <option [value]="data" *ngFor="let data of listYear">{{data}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                                                     
                        </div>
                    </form>
                </div>
              </div>
        </div>
      </div>
        
      <div class="mt-4 list-options">
          <button (click)="onClickPreview()" type="button" class="btn btn-warning mt-2">
              Preview
          </button>
          <button *ngIf="listDataTagihan.length > 0" (click)="onSubmitformInputTagihanBulanan()" class="btn btn-blue-grey mt-2" style="display:inline;">
              Print Tagihan
          </button>                        
      </div>

      <div class="table-wrapper">
        <div class="table-options">
          <div class="show-table">
            Show
            <select
              class="form-control"
              name="show"
              id="show"
              (change)="handlePageSizeChange($event)"
            >
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
            entries
          </div>
        </div>
        
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th class="align-middle">No.</th>
                <th class="align-middle">Nama</th>
                <th class="align-middle">Kelas</th>
                <th class="align-middle">Tagihan</th>
                <th></th>
            </thead>
            <tbody>
              <tr *ngFor="let tagihan of listDataTagihan | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
                <td>{{ i + 1 + (page - 1) * pageSize }}</td>
                <td>{{ tagihan.nama_lengkap }}</td>
                <td>{{ tagihan.nama_kelas }}</td>
                <td>{{ tagihan.amount }}</td>
              </tr>
              <tr *ngIf="listDataTagihan.length === 0" class="empty">
                <td colspan="6">Tidak terdapat data atau data tidak ditemukan.</td>
              </tr>
            </tbody>
          </table>
        </div>
      
        <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
          <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
            <div></div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center pagination-number">
                <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                  <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                    {{ page.label }}
                  </a>
                  <a class="page-link" *ngIf="p.getCurrent() === page.value">
                    {{ page.label }}
                  </a>
                </li>
              </ul>
            </nav>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="p.isFirstPage()">
                  <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                    <span>Previous</span>
                  </a>
                </li>
                <li class="page-item" [class.disabled]="p.isLastPage()">
                  <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                    <span>Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </pagination-template>
      
        <ng-template #emptyPagination>
          <div class="pagination-wrapper">
            <div>&nbsp;</div>
          </div>
        </ng-template>
      </div>

    </div>
  </div>