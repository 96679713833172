<div class="list-options">
  <button class="btn btn-blue-grey" (click)="openModalformCatatanSiswa(formCatatanSiswaModal)">
    + Tambah
  </button>
  <div class="search">
    <input
      type="text"
      class="form-control"
      placeholder="Nama Siswa.."
      name="search-list"
      id="search-list"
      [(ngModel)]="searchTerm"
    />
    <div class="icon" (click)="search()">
      <img src="./assets/images/icon-search.png" alt="Search" />
    </div>
  </div>
</div>

<div class="table-wrapper">
  <div class="table-options">
    <div class="show-table">
      Show
      <select
        class="form-control"
        name="show"
        id="show"
        (change)="handlePageSizeChange($event)"
      >
        <option *ngFor="let size of pageSizes" [ngValue]="size">
          {{ size }}
        </option>
      </select>
      entries
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>No.</th>
          <th>Siswa</th>
          <th>Tanggal</th>
          <th>Positif</th>
          <th>Negatif</th>
          <th>Nama Guru</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let siswa of listCatatanSiswa | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
          <td>{{ i + 1 + (page - 1) * pageSize }}</td>
          <td>{{ siswa.Siswa.nama_lengkap }}</td>
          <td>{{ siswa.tanggal }}</td>
          <td>{{ siswa.catatan_positif }}</td>
          <td>{{ siswa.catatan_negatif }}</td>
          <td>{{ siswa.Proguser.userProfile.real_name }}</td>
          <td></td>
        </tr>
        <tr *ngIf="listCatatanSiswa.length === 0" class="empty">
          <td colspan="7">Tidak terdapat data atau data tidak ditemukan.</td>
        </tr>
      </tbody>
    </table>
  </div>

  <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
    <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
      <div></div>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center pagination-number">
          <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
            <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
              {{ page.label }}
            </a>
            <a class="page-link" *ngIf="p.getCurrent() === page.value">
              {{ page.label }}
            </a>
          </li>
        </ul>
      </nav>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="p.isFirstPage()">
            <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
              <span>Previous</span>
            </a>
          </li>
          <li class="page-item" [class.disabled]="p.isLastPage()">
            <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
              <span>Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </pagination-template>

  <ng-template #emptyPagination>
    <div class="pagination-wrapper">
      <div>&nbsp;</div>
    </div>
  </ng-template>
</div>

<ng-template #formCatatanSiswaModal let-modal>
  <div class="modal-body">
      <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
      <div class="modal-form">
          <h1 class="text-center" *ngIf="isNew">Tambah Form Catatan Siswa</h1>
          <h1 class="text-center" *ngIf="!isNew">Edit Form Catatan Siswa</h1>
          <form [formGroup]="formCatatanSiswa" (ngSubmit)="onSubmitformCatatanSiswa(modal)">

            <div class="row mb-3">
              <label for="inputNamaSiswa" class="col-sm-3 col-form-label control-label">Nama</label>
              <div class="col-sm-9 autocomplete">
                <input formControlName="siswa_name" (keyup)="searchSiswa()" type="text"
                    [ngClass]="{ 'is-invalid': submitted && f['siswa_name'].errors }"
                    class="form-control" autocapitalize="off" 
                    autocorrect="off" autocomplete="off" 
                    id="inputNamaSiswa">

                <div id="myInputautocomplete-list" class="autocomplete-items">
                  <button *ngIf="isShowLoader" class="btn btn-primary" type="button" disabled>
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Searching...
                  </button>
                  <div (click)="chooseSiswa(data)" *ngFor="let data of listSiswa">
                    <strong>{{ data.nama_lengkap }}</strong>
                    <!-- <p>No, Dokumen</p> -->
                    <p>No, Registrasi {{ data.regnumber }}</p>
                    <p>Tahun masuk {{ data.angkatan }} </p>
                  </div>

                </div>
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputNoInduk" class="col-sm-3 col-form-label control-label">No. Induk</label>
              <div class="col-sm-9 autocomplete">
                <input formControlName="no_induk" type="text" class="form-control" id="inputNoInduk">          
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputKelas" class="col-sm-3 col-form-label control-label">Kelas</label>
              <div class="col-sm-9 autocomplete">
                <input formControlName="kelas" type="text" class="form-control" id="inputKelas">          
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputKamar" class="col-sm-3 col-form-label control-label">Kamar</label>
              <div class="col-sm-9 autocomplete">
                <input formControlName="kamar" type="text" class="form-control" id="inputKamar">          
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputTanggal" class="col-sm-3 col-form-label control-label">Tanggal</label>
              <div class="col-sm-9 autocomplete">
                <input
                            type="text"
                            autocomplete="off"
                            id="inputTanggal"
                            (blur)="changeDateField($event, 'tanggal')"                                  
                            formControlName="tanggal"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['tanggal'].errors }"
                            placeholder=""
                          />      
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputCatPositif" class="col-sm-3 col-form-label control-label">Catatan Positif</label>
              <div class="col-sm-9 autocomplete">
                <input formControlName="catatan_positif" type="text" class="form-control bg-primary text-white" id="inputCatPositif">          
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputCatNegatif" class="col-sm-3 col-form-label control-label">Catatan Negatif</label>
              <div class="col-sm-9 autocomplete">
                <input formControlName="catatan_negatif" type="text" class="form-control bg-danger text-white" id="inputCatNegatif">          
              </div>
            </div>

            <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isNew">CREATE</button>
            <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="!isNew">Update</button>
          </form>
      </div>
  </div>
</ng-template>
