import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sdm-izin-mengajar',
  templateUrl: './sdm-izin-mengajar.component.html',
  styleUrls: ['./sdm-izin-mengajar.component.css']
})
export class SdmIzinMengajarComponent implements OnInit {

  isInput = false
  isLaporan = false

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    console.log(this.router.url);
    switch (this.router.url) {
      case '/sdm/izin-mengajar/input':
        this.isInput = true;
        this.isLaporan = false;
        break;

      case '/sdm/izin-mengajar/laporan':
        this.isInput = false;
        this.isLaporan = true;
        break;

      default:
        this.isInput = false;
        this.isLaporan = false;
        break;
    }
  }

}
