import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TemplateSettingService } from 'src/app/services/template-setting.service';


@Component({
  selector: 'app-template-print-tagihan',
  templateUrl: './template-print-tagihan.component.html',
  styleUrls: ['./template-print-tagihan.component.css']
})
export class TemplatePrintTagihanComponent implements OnInit {

  errorMessage = '';
  id = "";
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '52rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  form: FormGroup = new FormGroup({
    content: new FormControl(''),
  });
  submitted = false;

  constructor(private formBuilder: FormBuilder, 
    private uiService: UiService, 
    private templateSettingService: TemplateSettingService,
  ) 
  { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.initForm();
    this.getTemplateTagihan();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru");
    }
  }
 
  initForm() {
    this.form = this.formBuilder.group(
      {
        content: ['', Validators.required],
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  getTemplateTagihan() {
    this.uiService.showLoader();

    this.templateSettingService.getPrintTagihanTemplate().subscribe({
      next: resdata => {
        console.log('TEMPLATE', resdata);        
        this.id = resdata.id;
        this.form.controls['content'].setValue(resdata.content);
        this.uiService.hideLoader();
        
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onSubmit(): void {
    this.submitted = true;

    console.log(JSON.stringify(this.form.value, null, 2));
    
    if (this.form.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    this.templateSettingService.update(this.id, this.form.value).subscribe({
      next: resdata => {
        console.log(resdata);
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('');

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });    
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

}
