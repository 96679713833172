import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { UiService } from 'src/app/helper/ui.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { RekapService } from 'src/app/services/rekap.service';

@Component({
  selector: 'app-siswa-statistik',
  templateUrl: './siswa-statistik.component.html',
  styleUrls: ['./siswa-statistik.component.css']
})
export class SiswaStatistikComponent implements OnInit {

  formSendWhatsapp: FormGroup = new FormGroup({
    tahapan: new FormControl(''),
    messages: new FormControl(''),
  });

  listRekapPembayaranFormulir: any[] = [];
  listRekapProses: any[] = [];
  listTahapanProses: any[] = [];  
  submitted = false;
  closeResult: string = '';
  currentPeriode: any;
  currentAngkatan = "";
  errorMessage = '';
  totalPembayaranFormulir = '0';
  totalProses = 0;

  // Doughnut
  doughnutChartLabels: string[] = [];
  doughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] = [
      // { 
      //   data: [70, 30],
      //   backgroundColor: ['#16556F', '#75BBC8'],
      //   rotation: 250
      // },
    ];

  doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: true,
  };

  // Doughnut tahapan proses
  tpDoughnutChartLabels: string[] = [];
  tpDoughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] = [
      // { 
      //   data: [40, 30,10,5,5,20],
      //   backgroundColor: ['#DE0685', '#FD8FCF', '#ED0F00', '#FCCFCC', '#D0FC0D', '#F000C5D'],
      //   rotation: 250
      // },
    ];

  tpDoughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: true
  };

  public barChartData: ChartConfiguration<'bar'>['data'] = {
    // labels: ["Belum Pilih Lembaga", "Mts", "Aliyah"],
    labels: ["Mts", "Aliyah"],
    datasets: [
      {
        label: "belum ikut ujian",
               data: [10, 40, 30],
               backgroundColor: ['#0094D8', '#0094D8', '#0094D8'],
               borderColor: ['#0094D8', '#0094D8', '#0094D8'],
               borderWidth: 2,
      },
      {
        label: "Lulus Murni",
               data: [20, 30, 70],               
               backgroundColor: ['#EB008A', '#EB008A', '#EB008A'],
               borderColor: ['#EB008A', '#EB008A', '#EB008A'],
               borderWidth: 2,
      },
      {
        label: "Lulus Bersyarat",
               data: [30, 40, 10],
               backgroundColor: ['#EFB7BA', '#EFB7BA', '#EFB7BA'],
               borderColor: ['#EFB7BA', '#EFB7BA', '#EFB7BA'],
               borderWidth: 2,
      },
      {
        label: "Lulus di Pondok Cabang",
               data: [50, 10, 30],
               backgroundColor: ['#6474E5', '#6474E5', '#6474E5'],
               borderColor: ['#6474E5', '#6474E5', '#6474E5'],
               borderWidth: 2,
      },
      {
        label: "Tidak Lulus",
               data: [10, 30, 50],
               backgroundColor: ['#C6FAD2', '#C6FAD2', '#C6FAD2'],
               borderColor: ['#C6FAD2', '#C6FAD2', '#C6FAD2'],
               borderWidth: 2,
      },
    ]
  };

  barChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  barChartLegend = false;

  constructor(
    private modalService: NgbModal,
    private uiService: UiService,
    private rekapService: RekapService,
    private tahunajaranService: TahunajaranService,
  ) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getCurrentPeriode();

    
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  onSubmitFormSendWhatsapp() {

  }

  getCurrentPeriode() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePpsb().subscribe({
      next: resdata => {
        console.log('periode', resdata);
        
        this.currentPeriode = resdata;
        this.currentAngkatan = resdata.name;
        
        this.provideAllRekap();
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formSendWhatsapp.controls;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  openModalWhatsapp(content:any, text: any) {
    console.log('FROM MODAL',text);
    
    this.formSendWhatsapp.controls['tahapan'].setValue(text);
    this.formSendWhatsapp.controls['tahapan'].disable();
    
    this.submitted = false;
    
    
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }    

  provideAllRekap() {
    // 1. pembayaran formulir per lembaga
    this.pembayaranFormulirPerLembaga();

    // 2. tahapan proses
    this.tahapanProses();

    // 3. status kelulusan
    this.statusKelulusan();
  }

  tahapanProses() {
    this.uiService.showLoader();

    const payload = {
      angkatan: this.currentPeriode.name
    }
    this.rekapService.getRekapTahapanProses(payload).subscribe({
      next: resdata => {
        console.log('processxxx', resdata);

        const bgColor = ['#DE0685', '#FD8FCF', "#75bbc8","#c0c0c0"];
        const borderColor = ['#DE0685', '#FD8FCF', "#75bbc8","#c0c0c0"];
        const prosesName = ['Validasi Dokumen','Ujian Masuk', 'Isi Data', 'Bayar Pendaftaran']


        if (resdata.length > 0) {
          this.totalProses = parseInt(resdata[0].docval) 
                        + parseInt(resdata[0].exam)
                        + parseInt(resdata[0].filldata)
                        + parseInt(resdata[0].regpayment);
                          
          let dataValues = [];
          let dataBgColors = [];
          let dataPersen = [];

          dataPersen[0] = (parseInt(resdata[0].docval) / this.totalProses) * 100;
          dataPersen[1] = (parseInt(resdata[0].exam) / this.totalProses) * 100;
          dataPersen[2] = (parseInt(resdata[0].filldata) / this.totalProses) * 100;
          dataPersen[3] = (parseInt(resdata[0].regpayment) / this.totalProses) * 100;

          for (let n=0; n<4; n++) {
            const ctnData = {
              persen :  dataPersen[n],
              bgColor: bgColor[n],
              proses: prosesName[n]
            }
            this.listRekapProses.push(ctnData);
            
            dataValues.push(dataPersen[n]);
            dataBgColors.push(bgColor[n]);

            this.tpDoughnutChartLabels.push(prosesName[n]);
          }

          console.log("FIN DATA", dataValues, dataBgColors);
          

          this.tpDoughnutChartDatasets = [
            { 
              data: dataValues,
              backgroundColor: dataBgColors,
              rotation: 250
            },
          ]
        }        
      
        this.uiService.hideLoader();        

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  pembayaranFormulirPerLembaga() {
    this.uiService.showLoader();

    const payload = {
      angkatan: this.currentPeriode.name
    }
    this.rekapService.getRekapPembayaranFormulirByLembaga(payload).subscribe({
      next: resdata => {
        console.log('FOrmulir', resdata);

        const bgColor = [
          "#16556f", "#75bbc8"
        ]

        const borderColor = ["#75bbc8","#c0c0c0"];


        let totalFormulir = 0;
        for (let i=0; i<resdata.length; i++) {
          totalFormulir = totalFormulir + parseInt(resdata[i].total);
        }

        let dataValues = [];
        let dataBgColors = [];

        for (let n=0; n<resdata.length; n++) {
          resdata[n]['persen'] = (parseInt(resdata[n].total) / totalFormulir) * 100;
          resdata[n]['bgColor'] = bgColor[n];
          resdata[n]['borderColor'] = borderColor[n];

          dataValues.push(resdata[n]['persen']);
          dataBgColors.push(bgColor[n]);
          
          this.doughnutChartLabels.push(resdata[n].nama_lembaga);
        }
        
        this.listRekapPembayaranFormulir = resdata;

        this.totalPembayaranFormulir = totalFormulir.toString();
      
        this.uiService.hideLoader();

        this.doughnutChartDatasets = [
          { 
            data: dataValues,
            backgroundColor: bgColor,
            rotation: 250
          },
        ]

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  statusKelulusan() {
    this.uiService.showLoader();

    const payload = {
      id_tahun_ajaran: this.currentPeriode.id
    }
    console.log(payload);
    
    this.rekapService.getRekapStatusKelulusan(payload).subscribe({
      next: resdata => {
        console.log('kelulusan', resdata);

        // const bgColor = ['#DE0685', '#FD8FCF', "#75bbc8","#c0c0c0"];
        // const borderColor = ['#DE0685', '#FD8FCF', "#75bbc8","#c0c0c0"];
        // const prosesName = ['Validasi Dokumen','Ujian Masuk', 'Isi Data', 'Bayar Pendaftaran']


        // if (resdata.length > 0) {
        //   this.totalProses = parseInt(resdata[0].docval) 
        //                 + parseInt(resdata[0].exam)
        //                 + parseInt(resdata[0].filldata)
        //                 + parseInt(resdata[0].regpayment);
                          
        //   let dataValues = [];
        //   let dataBgColors = [];
        //   let dataPersen = [];

        //   dataPersen[0] = (parseInt(resdata[0].docval) / this.totalProses) * 100;
        //   dataPersen[1] = (parseInt(resdata[0].exam) / this.totalProses) * 100;
        //   dataPersen[2] = (parseInt(resdata[0].filldata) / this.totalProses) * 100;
        //   dataPersen[3] = (parseInt(resdata[0].regpayment) / this.totalProses) * 100;

        //   for (let n=0; n<4; n++) {
        //     const ctnData = {
        //       persen :  dataPersen[n],
        //       bgColor: bgColor[n],
        //       proses: prosesName[n]
        //     }
        //     this.listRekapProses.push(ctnData);
            
        //     dataValues.push(dataPersen[n]);
        //     dataBgColors.push(bgColor[n]);
        //   }

        //   console.log("FIN DATA", dataValues, dataBgColors);
          

        //   this.tpDoughnutChartDatasets = [
        //     { 
        //       data: dataValues,
        //       backgroundColor: dataBgColors,
        //       rotation: 250
        //     },
        //   ]
        // }        
      
        this.uiService.hideLoader();        

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }  
  
}
