import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { CommonService } from 'src/app/helper/common.service';
import { LogService } from 'src/app/services/log.service';

declare var Datepicker: any;

@Component({
  selector: 'app-semester',
  templateUrl: './semester.component.html',
  styleUrls: ['./semester.component.css']
})
export class SemesterComponent implements OnInit {

  listSemester: any = [];
  currentTahunAjaran = "";
  currentPeriodeId: any = null;
  errorMessage = '';
  idSemester: any = null;
  module = "semester";

  formSemester: FormGroup = new FormGroup({
    tahun_ajaran: new FormControl(''),
    periode_id: new FormControl(''),
    semester: new FormControl(''),
    semester_name: new FormControl(''),
    start_date: new FormControl(''),
    end_date: new FormControl(''),
    status: new FormControl('f'),
    masehi_report_date: new FormControl('f'),
    hijriah_report_date: new FormControl('f'),
    start_input_nilai_date: new FormControl('f'),
    end_input_nilai_date: new FormControl('f'),
  });
  submitted = false;

  pageid = "proguser";

  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;

  closeResult: string = '';

  constructor(private tahunajaranService: TahunajaranService, 
    private uiService: UiService,
    private commonService: CommonService, 
    private logService: LogService,
    private modalService: NgbModal, 
    private formBuilder: FormBuilder) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.initForm();
    this.getCurrentTahunajaran();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  initForm() {
    this.formSemester = this.formBuilder.group(
      {
        tahun_ajaran: ['', Validators.required],
        periode_id: ['', Validators.required],
        semester: ['', Validators.required],
        semester_name: [''],
        start_date: ['', Validators.required],
        end_date: ['', Validators.required],
        status: ['', Validators.required],
        masehi_report_date: [''],
        hijriah_report_date: [''],
        start_input_nilai_date: [''],
        end_input_nilai_date: [''],
      },
    );

    this.formSemester.controls['tahun_ajaran'].disable();
    this.formSemester.controls['semester'].disable();
  }

  configureDatePicker() {
    console.log("DATEFIKER");
    
    const dateConfig = {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    }
    const elem_start_date = document.getElementById('start_date');
    const elem_end_date = document.getElementById('end_date');
    const elem_start_input_nilai_date = document.getElementById('start_input_nilai_date');
    const elem_end_input_nilai_date = document.getElementById('end_input_nilai_date');
    
    const datepicker_start_date = new Datepicker(elem_start_date, dateConfig);
    const datepicker_end_date = new Datepicker(elem_end_date, dateConfig);
    const datepicker_start_input_nilai_date = new Datepicker(elem_start_input_nilai_date, dateConfig);
    const datepicker_end_input_nilai_date = new Datepicker(elem_end_input_nilai_date, dateConfig);
  }

  changeDateField(e: any, field: any) {
    

    console.log(e.target.value);
    const choosenDate = e.target.value;
    this.formSemester.controls[field].setValue(choosenDate);
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
   openModal(content:any, id: any) {
    this.submitted = false;
    this.formSemester.reset();
    this.formSemester.controls['id'].setValue(id);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  } 
     
  /**
   * Write code on Method
   *
   * @return response()
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        this.currentPeriodeId = resdata.id;
        this.currentTahunAjaran = resdata.name;        

        this.uiService.hideLoader();

        this.getAllSemester();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllSemester() {
    this.uiService.showLoader();

    // const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    const payload = {
      periode_id: this.currentPeriodeId
    }

    this.tahunajaranService.getSemesterList(payload).subscribe({
      next: resdata => {
        console.log('semester', resdata);
        this.listSemester = resdata;
        
        if (this.listSemester.length === 0) {
          this.initSemester();
        }
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  initSemester() {
    this.uiService.showLoader();

    // const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    const payload = {
      periode_id: this.currentPeriodeId
    }

    this.tahunajaranService.initSemesterList(payload).subscribe({
      next: resdata => {        
        this.uiService.hideLoader();
        this.getAllSemester();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.getAllSemester();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getAllSemester();
  }

  search(): void {
    this.page = 1;
    this.getAllSemester();
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/empty-image.png';
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formSemester.controls;
  }

  confirmDelete(id: String) {
    
    this.uiService.showConfirmDelete(this, id);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.tahunajaranService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getAllSemester();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  openEditModalFormSemester(content:any, semester: any) {
    console.log("SEMESTR", semester);

    // this.configureDatePicker();
    console.log(this.commonService.convertDate(semester.start_date));
    console.log(this.commonService.convertDate(semester.end_date));
    
    
    this.submitted = false;
    this.formSemester.reset();
    this.idSemester = semester.id;
    this.formSemester.controls['periode_id'].setValue(this.currentPeriodeId);
    this.formSemester.controls['tahun_ajaran'].setValue(semester.Periode.name);
    this.formSemester.controls['semester'].setValue(semester.semester);
    this.formSemester.controls['semester_name'].setValue(semester.semester_name);
    this.formSemester.controls['start_date'].setValue(this.commonService.convertDate(semester.start_date));
    this.formSemester.controls['end_date'].setValue(this.commonService.convertDate(semester.end_date));
    this.formSemester.controls['masehi_report_date'].setValue(semester.masehi_report_date);
    this.formSemester.controls['hijriah_report_date'].setValue(semester.hijriah_report_date);
    this.formSemester.controls['start_input_nilai_date'].setValue(this.commonService.convertDate(semester.start_input_nilai_date));
    this.formSemester.controls['end_input_nilai_date'].setValue(this.commonService.convertDate(semester.end_input_nilai_date));
    this.formSemester.controls['status'].setValue(semester.active ? "t" : "f");
    // this.formSemester.controls['guru_name'].setValue(wali.User.userProfile.real_name);
    // this.formSemester.controls['proguser_id'].setValue(wali.User.id);

    // if (wali.Kelas !== null) {
    //   this.formSemester.controls['kelas_id'].setValue(wali.Kelas.id);    
    // }
    // this.formSemester.controls['periode_id'].setValue(wali.periode_id);
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      // this.configureDatePicker();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    const isOpenModal = this.modalService.hasOpenModals();
    console.log("MODAL", isOpenModal);

    if (isOpenModal) {
      this.configureDatePicker();
    }
  }

  onSubmitformSemester(modal: any): void {
    console.log(JSON.stringify(this.formSemester.value, null, 2));
    const currentUser = this.commonService.getUserDetail();
    console.log("CURRENT USER", currentUser);

    this.submitted = true;
    
    if (this.formSemester.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }
    
    modal.dismiss('Cross click');

    this.uiService.showLoader();

    this.tahunajaranService.updateSemester(this.idSemester, this.formSemester.value).subscribe({
      next: resdata => {
        console.log(resdata);
        const currentUser = this.commonService.getUserDetail();
        console.log("CURRENT USER", currentUser);
        const message = currentUser.proguser.userProfile.real_name + 
                " memperbaharui semester " + this.formSemester.get('semester')?.value;
        
        const logPayload = {
          log_level: "INFO",
          module: this.module,
          message: message,
          id_user: currentUser.iduser,
          ip_address: "",
          user_agent: ""
        }
        console.log("logPayload", logPayload);
        
        this.logService.add(logPayload).subscribe();
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
      
        this.getAllSemester();
  
      },
      error: err => {
        console.log(err);
        
        this.submitted = false;
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

}
