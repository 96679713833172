import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class SiswakamarService {

  constructor(private netService: NetworkService) { }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/siswakamar');
  }

  update(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/siswakamar/${id}`);
  }

  getListPenempatanKamar(payload: any): Observable<any> {
    return this.netService.postRequest(payload, `/v1/siswakamarbygedungasramakamar`);
  }

  getListSiswaKamar(id_periode: any, id: string): Observable<any> {
    return this.netService.getRequest(`/v1/siswakamarbyperiodandkamar/${id_periode}/${id}`);
  }
  
  delete(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/siswakamar/${id}`);
  }

}
