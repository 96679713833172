import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoleInterface } from 'src/app/modules/role/role-interface';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-role-view',
  templateUrl: './role-view.component.html',
  styleUrls: ['./role-view.component.css']
})
export class RoleViewComponent implements OnInit {

  id!: number;
  role!: RoleInterface;

  constructor(
    public roleService: RoleService,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    // this.id = this.route.snapshot.params['roleId'];
    // this.roleService.get(this.id).subscribe(
    //   data => {
    //     this.role = data;
    //   },
    //   error => {
    //     console.log(error);
    //   });
  }

  dateTransform(date: string | number | Date) {
    return this.datePipe.transform(date, 'd - MM - Y');
  }

}
