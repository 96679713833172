<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button routerLink="/tugas/list" class="nav-link" [class.active]="isList" id="list-tab" data-bs-toggle="tab" data-bs-target="#list" type="button" role="tab" aria-controls="list" aria-selected="false">List</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/tugas/monitoring" class="nav-link" [class.active]="isMonitoring" id="monitoring-tab" data-bs-toggle="tab" data-bs-target="#monitoring" type="button" role="tab" aria-controls="monitoring" aria-selected="false">Monitoring Bulanan</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane" [class.active]="isList" id="list" role="tabpanel" aria-labelledby="list-tab">
    <div class="tabs-title">List Tugas Harian/Mingguan</div>
  </div>
  <div class="tab-pane" [class.active]="isMonitoring" id="monitoring" role="tabpanel" aria-labelledby="monitoring-tab">
    <div class="tabs-title">Monitoring Bulanan</div>
  </div>
</div>
<router-outlet></router-outlet>
