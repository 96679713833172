import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { NilaiPpsbService } from 'src/app/services/nilai-ppsb.service';

@Component({
  selector: 'app-ppsb-nilai',
  templateUrl: './ppsb-nilai.component.html',
  styleUrls: ['./ppsb-nilai.component.css']
})
export class PpsbNilaiComponent implements OnInit {

  listNilaiSiswa: any = [];
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  currentPeriodeId: any = null;
  currentAngkatan = "";
  currentPeriode: any;

  closeResult: string = '';

  constructor(
    private tahunajaranService: TahunajaranService,
    private uiService: UiService,
    private nilaiPpsbService: NilaiPpsbService,
  ) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getCurrentPeriode();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getCurrentPeriode() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePpsb().subscribe({
      next: resdata => {
        console.log('periode', resdata);
        
        this.currentPeriode = resdata;
        this.currentPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        this.getAllNilaiPpsb();
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllNilaiPpsb() {
    console.log('getAllNilaiPpsb');
    
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);
    params['idperiode'] = this.currentPeriodeId;

    this.nilaiPpsbService.getNilaiPpsb(params).subscribe({
      next: resdata => {
        console.log("NILAI PPSB", resdata);
        this.listNilaiSiswa = resdata.data;

        
        this.uiService.hideLoader();
      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

}
