import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class SdmService {

  constructor(private netService: NetworkService) { }

  login(username: string, password: string): Observable<any> {
    const payload = {
      username,
      password
    }

    return this.netService.postRequest(payload, '/v1/proguser/login');
  }

  getAll(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/proguser', params);
  }

  getRoles(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/rolestaff', params);
  }

  addStaff(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/proguser/register');
  }

  changeStaffPassword(payload: any): Observable<any> {
    return this.netService.putRequest(payload, '/v1/proguser/changePassword');
  }

  changeStaffPhoto(payload: any): Observable<any> {
    return this.netService.putRequest(payload, '/v1/proguser/changePhoto');
  }

  changeSignfPhoto(payload: any): Observable<any> {
    return this.netService.putRequest(payload, '/v1/proguser/changeSign');
  }

  getById(id: any): Observable<any> {
    return this.netService.getRequest(`/v1/proguser/${id}`);
  }

  // create(data: any): Observable<any> {
  //   return this.http.post(baseUrl, data);
  // }

  update(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/proguser/${id}`);
  }

  delete(id: any): Observable<any> {
    return this.netService.deleteRequest(`/v1/proguser/${id}`);
  }

  // deleteAll(): Observable<any> {
  //   return this.http.delete(baseUrl);
  // }

  // findByTitle(title: any): Observable<Tutorial[]> {
  //   return this.http.get<Tutorial[]>(`${baseUrl}?title=${title}`);
  // }
}
