import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sdm-struktur',
  templateUrl: './sdm-struktur.component.html',
  styleUrls: ['./sdm-struktur.component.css']
})
export class SdmStrukturComponent implements OnInit {

  isList = false
  isSetup = false

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    console.log(this.router.url);
    switch (this.router.url) {
      case '/sdm/struktur/list':
        this.isList = true;
        this.isSetup = false;
        break;

      case '/sdm/struktur/setup':
        this.isList = false;
        this.isSetup = true;
        break;

      default:
        this.isList = false;
        this.isSetup = false;
        break;
    }
  }

}
