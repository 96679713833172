import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ContentRegisService } from 'src/app/services/content-regis.service';


@Component({
  selector: 'app-content-edit',
  templateUrl: './content-edit.component.html',
  styleUrls: ['./content-edit.component.css']
})
export class ContentEditComponent implements OnInit {

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '80rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  errorMessage = '';
  type: string = '';
  ctnName: string = '';
  contentData: any;
  isNew: boolean = false;

  listContent = [
    {
      "type":"TAHAP_1",
      "name":"Tahap 1 Awal Login",
      "status": true
    },
    {
      "type":"TAHAP_2",
      "name":"Tahap 2 Informasi Pembayaran",
      "status": true
    },
    {
      "type":"TAHAP_4",
      "name":"Tahapan 4 Validasi Dokumen",
      "status": true
    },
    {
      "type":"TAHAP_5",
      "name":"Tahap 5 Info Kartu Ujian",
      "status": true
    },
    {
      "type":"TAHAP_6A",
      "name":"Tahap 6.A Surat Keputusan",
      "status": true
    },
    {
      "type":"TAHAP_7",
      "name":"Tahap 7 Informasi",
      "status": true
    },
    {
      "type":"TAHAP_8",
      "name":"Tahap 8 Informasi",
      "status": true
    },
    {
      "type":"TAHAP_9",
      "name":"Tahapn Informasi Pembayaran",
      "status": true
    },
  ];

  form: FormGroup = new FormGroup({
    name: new FormControl(''),
    type: new FormControl(''),
    body: new FormControl(''),
  });
  submitted = false;

  constructor(private formBuilder: FormBuilder, 
    private route: ActivatedRoute,
    private uiService: UiService, 
    private contentRefisService: ContentRegisService,
    private router: Router) 
  { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.type = this.route.snapshot.params['type'];
    this.getContentRegis();
    
    const first = this.listContent.find((obj) => {
      return obj.type === this.type;
    });
    
    this.ctnName = first?.name || "";
  
    this.initForm();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru");
    }
  }

  onBack() {
    this.router.navigate(['/settings/contentregis/list']);
  }

  getContentRegis() {
    this.uiService.showLoader();

    this.contentRefisService.getByType(this.type).subscribe({
      next: resdata => {
        console.log('CONTENT EDIT', resdata);
        this.contentData = resdata;

        if (resdata === null) {
          this.isNew = true;
        } else {
          this.form.patchValue(resdata)
        }
        
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }
  
  initForm() {
    this.form = this.formBuilder.group(
      {
        name: [this.ctnName, Validators.required],
        type: [this.type, Validators.required],
        body: ['', Validators.required],
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      console.log(JSON.stringify(this.form.value, null, 2));
      return;
    }

    this.uiService.showLoader();

    if (this.isNew) {
      this.contentRefisService.add(this.form.value).subscribe({
        next: resdata => {
          console.log(resdata);
          
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          this.router.navigate(['/settings/contentregis/list']);
  
        },
        error: err => {
          console.log(err);
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    } else {
      this.contentRefisService.update(this.contentData.id, this.form.value).subscribe({
        next: resdata => {
          console.log(resdata);
          
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          this.router.navigate(['/settings/contentregis/list']);
  
        },
        error: err => {
          console.log(err);
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }

    console.log(JSON.stringify(this.form.value, null, 2));
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

}
