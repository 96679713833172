import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { SiswaService } from 'src/app/services/siswa.service';

@Component({
  selector: 'app-siswa-penempatan-kelas-statistik',
  templateUrl: './siswa-penempatan-kelas-statistik.component.html',
  styleUrls: ['./siswa-penempatan-kelas-statistik.component.css']
})
export class SiswaPenempatanKelasStatistikComponent implements OnInit {

  currentPeriodeId: any = null;
  currentAngkatan = "";
  totalSebaranKota = 0;
  listStatistikSebaranKota: any = [];
  listTahunajaran: any = [];
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  isShowLoader: Boolean = false;
  searchStatistikPenempatanKelas = "";

  formChangeTahunAjaran: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
  });

  closeResult: string = '';

  constructor(
    private uiService: UiService,
    private tahunajaranService: TahunajaranService,
    private formBuilder: FormBuilder,
    private siswaService: SiswaService,
  ) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.initDataTahunAjaran();

    this.formChangeTahunAjaran = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
      },
    );
  }

  async initDataTahunAjaran() {
    await this.getCurrentTahunajaran();
    await this.getAllTahunajaran();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    this.currentAngkatan = e.target.value;

    this.getSebaranKota();
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getSebaranKota() {
    this.uiService.showLoader();

    this.siswaService.getSebaranKota(this.currentAngkatan).subscribe({
      next: resdata => {
        console.log('LIST SEBARAN KOTA', resdata);
        this.listStatistikSebaranKota = resdata.data;
        this.totalSebaranKota = resdata.alltotal;
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        this.getSebaranKota();

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formChangeTahunAjaran.controls['id_tahun_ajaran'].setValue(this.currentAngkatan);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

}
