<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="guru-input"
    role="tabpanel"
    aria-labelledby="guru-input-tab"
  >
    <div class="tabs-title">Guru Input</div>

    
    <div class="col-12 col-lg-12">
      <div class="card">
          
          <div class="card-body">
              <form [formGroup]="formInputInitGuru">
                  <div class="row">
                      <div class="col-12 col-lg-12">
                          <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="nik" class="form-label">Tahun Ajaran <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-10">
                                      <input type="text" 
                                        formControlName="tahun_ajaran"
                                        readonly
                                        class="form-control btn-secondary" placeholder="">
                                  </div>
                              </div>
                          </div>
                          <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="name" class="form-label">Tanggal</label>
                                  </div>
                                  <div class="col-10">
                                      <input type="text" class="form-control" formControlName="tanggal"
                                          placeholder="">
                                  </div>
                              </div>
                          </div>
                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">Semester <span
                                            class="required">*</span></label>
                                </div>
                                <div class="col-10">
                                  <select
                                      formControlName="id_semester"
                                      [ngClass]="{ 'is-invalid': submitted && f['id_semester'].errors }"
                                      class="form-select"
                                      (change)="changeSemester($event)">
                                      <option value="" selected>Pilih Semester</option>
                                      <option [value]="data.kode" *ngFor="let data of listSemester">{{data.name}}</option>
                                  </select>
                                </div>
                            </div>
                        </div>
                          <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="name" class="form-label">Kelas <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-10">
                                    <select
                                        formControlName="kelas_id"
                                        [ngClass]="{ 'is-invalid': submitted && f['kelas_id'].errors }"
                                        class="form-select"
                                        (change)="changeKelas($event)">
                                        <option value="" selected>Pilih</option>
                                        <option [value]="data.id" *ngFor="let data of listKelas">{{data.nama_kelas}}</option>
                                    </select>
                                  </div>
                              </div>
                          </div>            
                          
                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">Wali Kelas</label>
                                </div>
                                <div class="col-10">
                                    <input type="text" class="form-control" formControlName="walikelas_name"
                                        placeholder="">
                                </div>
                            </div>
                          </div>
                          <div class="mb-3" *ngIf="!isAbsenReplacement">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="instagram" class="form-label">Guru</label>
                                  </div>
                                  <div class="col-10">
                                      <input type="text" class="form-control" formControlName="guru_name"
                                         placeholder="">
                                  </div>
                              </div>
                          </div>
                          <div class="mb-3" *ngIf="isAbsenReplacement">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">Guru/Pengganti</label>
                                </div>
                                <div class="col-10">
                                  <select
                                      formControlName="guru_id"
                                      [ngClass]="{ 'is-invalid': submitted && f['guru_id'].errors }"
                                      class="form-select"
                                      (change)="changeGuruPengganti($event)">
                                      <option value="" selected disabled>Pilih</option>
                                      <option [value]="data.id" *ngFor="let data of listGuruPengganti">{{data.name}}</option>
                                  </select>
                                </div>
                            </div>
                        </div>
                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">Jam Pelajaran</label>
                                </div>
                                <div class="col-10">
                                  <select
                                      formControlName="jam_pelajaran"
                                      [ngClass]="{ 'is-invalid': submitted && f['jam_pelajaran'].errors }"
                                      class="form-select"
                                      (change)="changeJamPelajaran($event)">
                                      <option value="" selected disabled>Pilih</option>
                                      <option [value]="data.period" *ngFor="let data of listDayPeriod">Jam Pelajaran {{data.period}}</option>
                                  </select>
                                </div>
                            </div>
                        </div>
                          <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="twitter" class="form-label">Mata Pelajaran</label>
                                  </div>
                                  <div class="col-10">
                                      <input type="text" class="form-control" formControlName="mata_pelajaran"
                                          placeholder="">
                                  </div>
                              </div>
                          </div>
                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="twitter" class="form-label">Judul Materi</label>
                                </div>
                                <div class="col-10">
                                    <input type="text" class="form-control" formControlName="judul_materi"
                                        placeholder="">
                                </div>
                            </div>
                        </div>
                      </div>
                      
                  </div>
              </form>
          </div>
      </div>
    </div>

    <div class="mt-4 list-options">
            
      <div class="search">
        <input
        type="text"
        class="form-control"
        placeholder="Search.."
        name="search-list"
        id="search-list"
        [(ngModel)]="searchTerm"
        />
        <div class="icon" (click)="search()">
          <img src="./assets/images/icon-search.png" alt="Search" />
        </div>
      </div>
      
    </div>
    
    <div class="table-wrapper">
      <div class="table-options">
        <div class="show-table">
          
        </div>
      </div>
      <form [formGroup]="formInputAbsensi" (ngSubmit)="onSubmitformInputInitGuru()">
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama</th>
                <th>Absensi</th>
                <th>Catatan Positif</th>
                <th>Catatan Negatif</th>
              </tr>
            </thead>
            <tbody>
                <ng-container formArrayName="dataFormAbsensiSiswaNya">
                  <tr [formGroupName]="i" *ngFor="let siswa of listAbsensiSiswa; let i = index">
                    <td>
                      <input formControlName="absensi_guru_input_id" type="text" class="form-control d-none">
                      <input formControlName="siswa_id" type="text" class="form-control d-none">
                      <input formControlName="siswa_status" type="text" class="form-control d-none">
                      {{ i + 1 + (page - 1) * pageSize }}
                    </td>
                    <td>{{ siswa.nama_lengkap }}</td>
                    <td>
                      <div class="radio-toolbar" [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}">
                        <input type="radio" id="radioH{{i}}" value="H" (change)="onRadioClick()" formControlName="absen">
                        <label for="radioH{{i}}">H</label>
                    
                        <input type="radio" id="radioA{{i}}" value="A" (change)="onRadioClick()" formControlName="absen">
                        <label for="radioA{{i}}">A</label>
                    
                        <input type="radio" id="radioS{{i}}" value="S" (change)="onRadioClick()" formControlName="absen">
                        <label for="radioS{{i}}">S</label>
                        
                        <input type="radio" id="radioIp{{i}}" value="IP" (change)="onRadioClick()" formControlName="absen">
                        <label for="radioIp{{i}}">IP</label>
                        
                        <input type="radio" id="radioIt{{i}}" value="IT" (change)="onRadioClick()" formControlName="absen">
                        <label for="radioIt{{i}}">IT</label>
                      </div>
                      <div *ngIf="siswa.status !== 'ACTIVE'">
                        
                        {{ siswa.status }}
                      </div>
                      
                    </td>
                    <td>
                      <input [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}" type="text" class="form-control bg-primary text-white" formControlName="catatan_positif">
                    </td>
                    <td>
                      <input [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}" type="text" class="form-control bg-danger text-white" formControlName="catatan_negatif">
                    </td>
                  </tr>
                </ng-container>            
                
                <tr *ngIf="listAbsensiSiswa.length === 0" class="empty">
                  <td colspan="5">
                    Tidak terdapat data atau data tidak ditemukan.
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
      
        <div *ngIf="listAbsensiSiswa.length !== 0">
          <button [disabled]="!isAbsenCompleted" class="btn btn-blue-grey mt-2">
            Simpan
          </button>
        </div>
      </form>

      <div class="mt-4">
        <ul>
          <li>H : Hadir</li>
          <li>A : Alpha tanpa keterangan</li>
          <li>S : Sakit</li>
          <li>IP : Izin Pribadi (Pulang, acara keluarga, dll)</li>
          <li>IT : Izin Tugas (Piket Rayon, lomba, ditugaskan dari Pesantren)</li>
          <li class="ng-binding">Data siswa pada penempatan kelas tahun ajaran 2021-2022 sudah ada. </li>
          <li>Data urutan siswa di triwulan ini ada.</li>
          </ul>
      </div>
    </div>
  </div>
</div>