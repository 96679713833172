import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SiswaService } from 'src/app/services/siswa.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { KelasService } from 'src/app/services/kelas.service';
import { SiswakelasService } from 'src/app/services/siswakelas.service';
import { ExcelService } from 'src/app/services/excel.service';
import { CommonService } from 'src/app/helper/common.service';

@Component({
  selector: 'app-siswa-penempatan-kelas-peringkat',
  templateUrl: './siswa-penempatan-kelas-peringkat.component.html',
  styleUrls: ['./siswa-penempatan-kelas-peringkat.component.css']
})
export class SiswaPenempatanKelasPeringkatComponent implements OnInit {

  listPeringkatKelas: any = [];
  listTahunajaran: any = [];
  listKelas: any = [];
  listSemester: any = [];
  currentPeriodeId: any = null;
  currentAngkatan = "";
  currentSemester = "";
  currentKelasId: any = null;
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  isShowLoader: Boolean = false;
  searchPeringkatKelas = "";

  formAction: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
    semester: new FormControl(''),
    id_kelas: new FormControl(''),
  });

  constructor(private siswaService: SiswaService,
    private siswakelasService: SiswakelasService,
    private uiService: UiService,
    private modalService: NgbModal,
    private kelasService: KelasService,
    private tahunajaranService: TahunajaranService,
    private excelService: ExcelService,
    private commonService: CommonService,
    private formBuilder: FormBuilder) {
    this.changeBodyClass();
    this.formAction = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        semester: ['0'],
        id_kelas: ['0'],
      },
    );
  }

  ngOnInit(): void {

    this.getKelas();
    this.getCurrentTahunajaran();
    this.listSemester = this.commonService.getListSemester();

  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAction.controls;
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    this.currentPeriodeId = e.target.value;
  }

  changeSemester(e: any) {
    this.currentSemester = e.target.value;
  }

  changeKelas(e: any) {
    this.currentKelasId = e.target.value;
  }

  onSubmitFormAction(): void {
    this.submitted = true;
    console.log(JSON.stringify(this.formAction.value, null, 2));

    if (this.formAction.invalid) {
      return;
    }

    this.modalService.dismissAll();

    this.uiService.showLoader();

    this.siswakelasService.getPeringkatKelas(this.formAction.value).subscribe({
      next: resdata => {
        console.log(resdata);

        this.listPeringkatKelas = resdata;

        this.uiService.hideLoader();
        // this.uiService.showSuccess('');

      },
      error: err => {
        console.log(err);

        this.errorMessage = err.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });

  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        this.currentPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        this.uiService.hideLoader();

        this.getAllTahunajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formAction.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onFileChange(evt: any) {
    const fdata: FileReader = this.excelService.convertExcelFileToJson(evt.target);

    fdata.onloadend = (e: any) => {
      // console.log("END OF ROAD", this.excelService.dataExcel);
      const fileUpload: any[] = this.excelService.dataExcel;
      const finalFileUpload = fileUpload.slice(1);

      if (finalFileUpload.length > 0) {
        let dataToUpload = [];
        for (const k in finalFileUpload) {
          const v = finalFileUpload[k];
          console.log(k, v);
          // 1. get periode_id
          // 2. get kelas_id
          // 3. get siswaid
          if (v.length === 0) {
            break;
          }
          const column = [v[1],v[4],v[5],v[6],v[7]];
          dataToUpload.push(column)
        }
        console.log("DATA TO UPLOAD", dataToUpload);
        
        const payload = {
          data: dataToUpload
        }
        
        this.siswakelasService.uploadPeringkat(payload).subscribe({
          next: resdata => {
            console.log('RESPONSE', resdata);
    
            // this.refreshListKelas();
            
            this.uiService.hideLoader();
            this.uiService.showSuccess("Upload Success")
    
          },
          error: err => {
            this.errorMessage = err.error.message;
            console.log(this.errorMessage);
    
            this.uiService.hideLoader();
    
            this.uiService.showError(this.errorMessage);
          }
        });
      } 
    }
  }

  downloadSampleFormat(): void {
    let data = [];

    for (const k in this.listKelas) {
      const v = this.listKelas[k];
      const row = {
        NO: parseInt(k)+1,
        NO_INDUK: "2.23.xxx",
        NAMA: "",
        KELAS: v.nama_kelas,
        KODE_KELAS: v.id,
        TAHUN_PELAJARAN: this.currentAngkatan,
        SEMESTER: "MS1/S1/MS2/S2 (Note: MS = Mid Semester, S= Semester)",
        NO_URUT: parseInt(k)+1
      }

      data.push(row);
    }

    this.excelService.exportAsExcelFile(data, "Format Upload Peringkat Kelas.xlsx");
  }

}
