<div class="tabs-title">LAPORAN IZIN SANTRI PER-KELAS</div>

<div class="col-12 col-lg-12">
  <div class="card">
      
      <div class="card-body">
          <form [formGroup]="formMonitoringAbsensi">
              <div class="row">
                  <div class="col-12 col-lg-12">
                    <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="nik" class="form-label">Tahun Ajaran <span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-10">                            
                              <select formControlName="id_tahun_ajaran" [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }" class="form-select">
                                <option [value]="" selected disabled>Pilih TA</option>
                                <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                              </select>
                            </div>
                        </div>
                    </div> 

                    <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="name" class="form-label">Tanggal Berangkat</label>
                            </div>
                            <div class="col-10">
                              <input
                                type="text"
                                autocomplete="off"
                                id="start_date"
                                (blur)="changeStartDateField($event)"                                  
                                formControlName="tanggal"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f['tanggal'].errors }"
                                placeholder=""
                              />
                            </div>
                        </div>
                    </div>                                    
                                    
                  </div>
                  
              </div>
          </form>
      </div>
  </div>
</div>

<div class="list-options mt-4">
  <button class="btn btn-blue-grey" (click)="onSubmit()">
    + Tampil
  </button>
</div>


