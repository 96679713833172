import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.css']
})
export class ContentListComponent implements OnInit {

  listContent: any = [];
  backUrl: string = "";
  
  constructor(
    private uiService: UiService,
    private route: ActivatedRoute,
    private router: Router) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getAllContentType();

    this.route.queryParams
      .subscribe(params => {
        // hardcode for a while
        this.backUrl = '/siswa/statistik';
        console.log(params); // { orderby: "price" }
        let result = params.hasOwnProperty('backUrl');
        console.log(result);

        if (result === true) {
          this.backUrl = params['backUrl'];
          console.log(this.backUrl); // price
        }        
      }
    );
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  onBack() {
    this.router.navigate([this.backUrl]);
  }

  getAllContentType() {
    
    this.listContent = [
      {
        "type":"TAHAP_1",
        "name":"Tahap 1 Awal Login",
        "status": true
      },
      {
        "type":"TAHAP_2",
        "name":"Tahap 2 Informasi Pembayaran",
        "status": true
      },
      {
        "type":"TAHAP_4",
        "name":"Tahapan 4 Validasi Dokumen",
        "status": true
      },
      {
        "type":"TAHAP_5",
        "name":"Tahap 5 Info Kartu Ujian",
        "status": true
      },
      {
        "type":"TAHAP_6A",
        "name":"Tahap 6.A Surat Keputusan",
        "status": true
      },
      {
        "type":"TAHAP_7",
        "name":"Tahap 7 Informasi",
        "status": true
      },
      {
        "type":"TAHAP_8",
        "name":"Tahap 8 Informasi",
        "status": true
      },
      {
        "type":"TAHAP_9",
        "name":"Tahapan Informasi Pembayaran",
        "status": true
      },
    ];

  }

}
