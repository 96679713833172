import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { SiswaService } from 'src/app/services/siswa.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { TingkatService } from 'src/app/services/tingkat.service';
import { LembagaService } from 'src/app/services/lembaga.service';
import { PaketPembayaranService } from 'src/app/services/paket-pembayaran.service';
import { GroupBiayaService } from 'src/app/services/group-biaya.service';
import { SdmService } from 'src/app/services/sdm.service';
import { GelombangPendafataranService } from 'src/app/services/gelombang-pendafataran.service';
import { MataujiaService } from 'src/app/services/mataujia.service';
import { NilaiPpsbService } from 'src/app/services/nilai-ppsb.service';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-ppsb-list',
  templateUrl: './ppsb-list.component.html',
  styleUrls: ['./ppsb-list.component.css']
})
export class PpsbListComponent implements OnInit {

  listSiswa: any = [];
  listPenguji: any = [];
  listsLembaga: any = [];
  listsTingkat: any = [];
  listGroupBiaya: any = [];
  listBiayaPaketPembayaran: any = [];
  listPaketPembayaran: any = [];
  listGelombang: any = [];
  listMataUji: any = [];
  listNilaiMataUji: any = [];
  listHasilPpsb: any = [];
  currentPeriodeId: any = null;
  currentAngkatan = "";
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  isShowLoader: Boolean = false;
  isShowLoaderPenguji: Boolean = false;
  searchSiswaTerm = "";
  searchPengujiTerm = "";
  listTahunajaran: any = [];
  currentPeriode: any;
  idlembaga: number = 0;
  idlembagaPembayaran: number = 0;
  idpaket: number = 0;
  idNilaiPpsb = 0;

  closeResult: string = '';

  formAddNilaiSiswa: FormGroup = new FormGroup({
    siswa_name: new FormControl(''),
    penguji_name: new FormControl(''),
    id_siswa: new FormControl(''),
    id_tahun_ajaran: new FormControl(''),
    id_penguji: new FormControl(''),
    id_user_input: new FormControl(''),
    status_kelulusan: new FormControl(''),
    id_lembaga: new FormControl(''),    
    id_tingkat: new FormControl(''),
    id_lembaga_biaya: new FormControl(''),
    id_paket_biaya: new FormControl(''),
    id_tagihan: new FormControl(''),
    id_gelombang: new FormControl(''),
    catatan_penguji: new FormControl(''),
    selectedPaketBiaya: new FormArray([]),
    nilaiSiswaNya: new FormArray([]),
  });

  isNew = true;

  constructor(
    private formBuilder: FormBuilder,
    private tahunajaranService: TahunajaranService,
    private uiService: UiService,
    private siswaService: SiswaService,
    private tingkatService: TingkatService,
    private lembagaService: LembagaService,
    private paketPembayaranService: PaketPembayaranService,
    private groupBiayaService: GroupBiayaService,
    private sdmService: SdmService,
    private gelombangPendaftaranService: GelombangPendafataranService,
    private mataujiService: MataujiaService,
    private nilaiPpsbService: NilaiPpsbService,
    private excelService: ExcelService,
    private modalService: NgbModal
  ) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getAllTahunajaran();
    this.initForm();
    this.getCurrentPeriode();    
    this.getLembaga();
    this.getAllGroupBiaya();
    this.getAllGelombangPendaftaran();    
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {
    this.formAddNilaiSiswa = this.formBuilder.group(
      {
        siswa_name: ['', Validators.required],
        penguji_name: ['', Validators.required],
        id_siswa: ['', Validators.required],
        id_tahun_ajaran: ['', Validators.required],
        id_penguji: ['', Validators.required],
        id_user_input: [''],
        status_kelulusan: ['', Validators.required],
        id_lembaga: ['', Validators.required],    
        id_tingkat: ['', Validators.required],
        id_lembaga_biaya: ['', Validators.required],
        id_paket_biaya: ['', Validators.required],
        id_tagihan: [''],
        id_gelombang: ['', Validators.required],
        catatan_penguji: [''],
        selectedPaketBiaya: new FormArray([]),
        nilaiSiswaNya: new FormArray([]),
      },
    );
  }

  get nilaiSiswaNya(): FormArray {
    return this.formAddNilaiSiswa.get('nilaiSiswaNya') as FormArray;
  }

  onSubmitHasilPpsb(modal: any): void {

    console.log(JSON.stringify(this.formAddNilaiSiswa.value, null, 2));
    
    this.submitted = true;
    
    if (this.formAddNilaiSiswa.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }
    
    // set user input
    const currentUser = localStorage.getItem('user')?.toString();
    const jsonUser = currentUser ? JSON.parse(currentUser) : ""; 

    // jsonUser.iduser
    this.formAddNilaiSiswa.controls['id_user_input'].setValue(jsonUser.iduser);
    
    modal.dismiss('Cross click');

    this.uiService.showLoader();

    if (this.isNew) {
      this.nilaiPpsbService.add(this.formAddNilaiSiswa.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.resetForm();

          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          //this.router.navigate(['/ppsb/list']);
          this.getAllHasilPpsb();
  
        },
        error: err => {
          console.log(err);

          this.resetForm();
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });    
    } else {
      this.nilaiPpsbService.update(this.idNilaiPpsb, this.formAddNilaiSiswa.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.formAddNilaiSiswa.reset();
          this.submitted = false;
          this.initForm();
          this.formAddNilaiSiswa.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
          this.listBiayaPaketPembayaran = [];
          
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          //this.router.navigate(['/ppsb/list']);
          this.getAllHasilPpsb();
  
        },
        error: err => {
          console.log(err);
          // this.formAddNilaiSiswa.reset();
          this.submitted = false;
          // this.initForm();
          // this.formAddNilaiSiswa.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
          // this.listBiayaPaketPembayaran = [];
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }

  }

  resetForm() {
    this.formAddNilaiSiswa.reset();
    this.submitted = false;
    this.initForm();
    this.formAddNilaiSiswa.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
    this.listBiayaPaketPembayaran = [];
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  chooseSiswa(siswa: any) {
    this.listSiswa = [];
    console.log(siswa);
    this.formAddNilaiSiswa.controls['id_siswa'].setValue(siswa.id);
    this.formAddNilaiSiswa.controls['siswa_name'].setValue(siswa.nama_lengkap);    
  }

  searchSiswa(): void {
    this.searchSiswaTerm = this.formAddNilaiSiswa.value.siswa_name;

    this.listSiswa = [];
    if (this.searchSiswaTerm != "") {
      this.isShowLoader = true;
      const params = this.getRequestParams(this.searchSiswaTerm, this.page, this.pageSize);
      params['angkatan'] = this.currentAngkatan;

      console.log("PARAMS", params);
      
  
      this.siswaService.getAllSiswaBaru(params).subscribe({
        next: resdata => {
          console.log(resdata);
          const { data, totalrows } = resdata;
  
          this.isShowPagination == totalrows > this.pageSize ? true : false;
          this.listSiswa = data;
          this.count = totalrows;
  
          console.log("SISWA", this.listSiswa);
  
          console.log('COUNT', this.count);
  
  
          this.isShowLoader = false;
          //this.uiService.hideLoader();
  
        },
        error: err => {
          this.isShowLoader = false;
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);
  
          //this.uiService.hideLoader();
  
          //this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  choosePenguji(data: any) {
    this.listPenguji = [];
    console.log(data);
    this.formAddNilaiSiswa.controls['penguji_name'].setValue(data.userProfile.real_name);
    this.formAddNilaiSiswa.controls['id_penguji'].setValue(data.userProfile.id_user);
  }

  searchPenguji(): void {
    this.searchPengujiTerm = this.formAddNilaiSiswa.value.penguji_name;

    this.listPenguji = [];
    if (this.searchPengujiTerm != "") {
      this.isShowLoaderPenguji = true;
      const params = this.getRequestParams(this.searchPengujiTerm, this.page, this.pageSize);

      console.log("PARAMS", params);
      
      this.sdmService.getAll(params).subscribe({
        next: resdata => {
          console.log(resdata);
          const { data, totalrows } = resdata;
  
          this.isShowPagination == totalrows > this.pageSize ? true : false;
          this.listPenguji = data;
          this.count = totalrows;
  
          console.log('COUNT', this.count);
          this.isShowLoaderPenguji = false;
        
          this.uiService.hideLoader();
  
        },
        error: err => {
          this.isShowLoaderPenguji = false;
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value, e.target.options[e.target.selectedIndex].innerHTML);
    this.currentAngkatan = e.target.options[e.target.selectedIndex].innerHTML;
  }

  getCurrentPeriode() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePpsb().subscribe({
      next: resdata => {
        console.log('periode', resdata);
        
        this.currentPeriode = resdata;
        this.currentPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        this.getAllHasilPpsb();
        
        this.formAddNilaiSiswa.controls['id_tahun_ajaran'].setValue(resdata.id);
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {
    this.uiService.showLoader();

    const params = this.getRequestParams("", this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log('kelas', resdata);
        const { data, totalrows } = resdata;

        //this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listTahunajaran = data;
        this.count = totalrows;

        console.log('COUNT', this.count);

      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.formAddNilaiSiswa.controls;
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  openPosisiDaftarModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  openHasilTestModal(content: any) {
    this.isNew = true;

    this.resetForm();
    
    this.getAllNilaiMataUji('0');
    
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' });
  }

  openEditHasilTestModal(content: any, nSiswa: any) {
    console.log("SISWA",nSiswa);
    this.isNew = false;
    
    this.idNilaiPpsb = nSiswa.id;
    this.formAddNilaiSiswa.patchValue(nSiswa);
    this.formAddNilaiSiswa.controls['siswa_name'].setValue(nSiswa.SiswaHasilPpsb.nama_lengkap);
    this.formAddNilaiSiswa.controls['penguji_name'].setValue(nSiswa.PengujiHasilPpsb.userProfile.real_name);
    //this.formAddNilaiSiswa.controls['id_lembaga'].setValue(siswa.lembaga.id);
    this.idlembagaPembayaran = nSiswa.id_lembaga_biaya;
    this.idpaket = nSiswa.id_paket_biaya;
    this.currentPeriodeId =  nSiswa.id_tahun_ajaran;

    this.getTingkatLembaga(nSiswa.id_lembaga, nSiswa.id_tingkat);

    this.getAllPaketPembayaran();
    this.getAllBiayaPaketPembayaran();
    
    this.submitted = false;
    
    this.getAllNilaiMataUji(this.idNilaiPpsb);
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' });
  }

  changeStatusLulus(e: any) {
    console.log(e.target.value);

    if (e.target.value === "lulus_murni" || e.target.value === "lulus_bersyarat") {
      this.formAddNilaiSiswa.controls['id_lembaga_biaya'].setValidators([Validators.required]);
      this.formAddNilaiSiswa.controls['id_lembaga_biaya'].updateValueAndValidity();
      this.formAddNilaiSiswa.controls['id_paket_biaya'].setValidators([Validators.required]);
      this.formAddNilaiSiswa.controls['id_paket_biaya'].updateValueAndValidity();      
    } else {
      this.formAddNilaiSiswa.controls['id_lembaga_biaya'].clearValidators();
      this.formAddNilaiSiswa.controls['id_lembaga_biaya'].updateValueAndValidity();
      this.formAddNilaiSiswa.controls['id_paket_biaya'].clearValidators();
      this.formAddNilaiSiswa.controls['id_paket_biaya'].updateValueAndValidity();
    }
  }

  changeLembaga(e: any) {
    console.log(e.target.value);
    const lembagaId = e.target.value;
    this.formAddNilaiSiswa.controls['id_lembaga'].setValue(lembagaId);
    this.getTingkatLembaga(lembagaId);
  }

  changeLembagaBiaya(e: any) {
    console.log(e.target.value);
    const lembagaBiayaId = e.target.value;
    this.idlembagaPembayaran = lembagaBiayaId;
    this.formAddNilaiSiswa.controls['id_lembaga_biaya'].setValue(lembagaBiayaId);
    this.getAllPaketPembayaran();
    
  }

  changePaketPembayaran(e: any) {
    console.log(e.target.value);
    this.idpaket = e.target.value;
    this.formAddNilaiSiswa.controls['id_paket_biaya'].setValue(this.idpaket);
    
    this.getAllBiayaPaketPembayaran();
    
  }

  changeGelombang(e: any) {
    console.log(e.target.value);
    const idGelombang = e.target.value;
    this.formAddNilaiSiswa.controls['id_gelombang'].setValue(idGelombang);
  }

  getLembaga() {
    this.uiService.showLoader();

    this.lembagaService.getAllLembaga('').subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsLembaga = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllGroupBiaya() {
    this.uiService.showLoader();

    this.groupBiayaService.getAll('').subscribe({
      next: resdata => {
        console.log("getAllGroupBiaya", resdata);
        // const { data, totalrows } = resdata;
        const totalrows = 20;
        this.listGroupBiaya = resdata.data;
        
        this.count = totalrows;
      
        this.uiService.hideLoader();

        // this.getAllPaketPembayaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllPaketPembayaran() {
    this.paketPembayaranService.getByIdLembaga(this.idlembagaPembayaran).subscribe({
      next: resdata => {
        console.log("getAllPaketPembayaran", resdata.data);
        // const { data, totalrows } = resdata;
        // this.listBiayaPaketPembayaran = [];

        this.listPaketPembayaran = resdata.data;
                
        // for (var index in this.listPaketPembayaran) {
        //   let json: any = [];
        //   let totalGroup: any = [];

        //   json['paket'] = this.listPaketPembayaran[index].nama_paket;
          
        //   for (var index2 in this.listGroupBiaya) {
        //     totalGroup.push(
        //       {
        //         'idpaket': this.listPaketPembayaran[index].id,
        //         'idgroup': this.listGroupBiaya[index2].id,
        //         'group_name': this.listGroupBiaya[index2].name,
        //         'total': 0
        //       }
        //     )     
        //   }
          
        //   json['groups'] = totalGroup;
        //   json['total_paket'] = 0;

        //   this.listBiayaPaketPembayaran.push(json);
          
        // }
      
        this.uiService.hideLoader();

        // this.getAllBiayaPaketPembayaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllBiayaPaketPembayaran() {
    console.log("LEMBAGA ID : ", this.idlembaga);
    this.paketPembayaranService.getPaketpembayaranbylembagaandpaketpembayaranandperiode(this.idlembagaPembayaran, this.idpaket, this.currentPeriodeId).subscribe({
      next: resdata => {
        console.log("getPaketpembayaranbylembagaandperiode", this.currentPeriodeId, resdata);
        
        this.listBiayaPaketPembayaran = resdata;

        // for (var index in resdata) {
        //   console.log("NILAI ", index);
        //   console.log(resdata[index].nama_paket);
        //   //this.listBiayaPaketPembayaran[resdata[index].name] = 0;
        //   //this.listBiayaPaketPembayaran[resdata[index].nama_paket][resdata[index].name] = resdata[index].total_group_biaya;
        //   for (var index2 in this.listBiayaPaketPembayaran) {
        //     if (resdata[index].nama_paket === this.listBiayaPaketPembayaran[index2].paket) {
        //       this.listBiayaPaketPembayaran[index2].total_paket = resdata[index].total_biaya;
        //       for (var index3 in this.listBiayaPaketPembayaran[index2].groups) {
        //         if (resdata[index].name === this.listBiayaPaketPembayaran[index2].groups[index3].group_name) {
        //           console.log("YEAS", resdata[index].nama_paket, this.listBiayaPaketPembayaran[index2].groups[index3].total, resdata[index].total_group_biaya)
        //           this.listBiayaPaketPembayaran[index2].groups[index3].total = resdata[index].total_group_biaya;
        //         }
        //       } 
              
        //     }
        //   }
        // }
        // console.log("listBiayaPaketPembayaran", this.listBiayaPaketPembayaran);
        //this.listBiayaPaketPembayaran = resdata;
        
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getTingkatLembaga(lembagaId: any, curentTingkat: any = null) {
    this.uiService.showLoader();
  
    this.tingkatService.getTingkatByLembagaId(lembagaId).subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsTingkat = resdata;
        if (curentTingkat !== null) {
          this.formAddNilaiSiswa.controls['id_tingkat'].setValue(curentTingkat);
        } else {
          this.formAddNilaiSiswa.controls['id_tingkat'].setValue('');
        }
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllGelombangPendaftaran() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.gelombangPendaftaranService.getAllGelombangPendaftaranByPeriod(params).subscribe({
      next: resdata => {
        console.log('gelombang', resdata);
        
        this.listGelombang = resdata;
        
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onCheckboxChange(event: any) {
    
    const selectedPaketBiaya: FormArray = this.formAddNilaiSiswa.get('selectedPaketBiaya') as FormArray;
    
    if (event.target.checked) {
      selectedPaketBiaya.push(new FormControl(event.target.value));
    } else {
      const index = selectedPaketBiaya.controls
      .findIndex(x => x.value === event.target.value);
      selectedPaketBiaya.removeAt(index);
    }
  }

  getAllMataUji() {
    this.uiService.showLoader();

    this.mataujiService.getAll('').subscribe({
      next: resdata => {
        console.log('mata ujian', resdata);
        
        this.listMataUji = resdata.data;
        
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllNilaiMataUji(idHasil: any) {
    this.uiService.showLoader();

    this.nilaiPpsbService.getNilaiById(idHasil).subscribe({
      next: resdata => {
        console.log('getAllNilaiMataUji', resdata);
        console.log('nilaiSiswaNya', this.nilaiSiswaNya);
        this.listNilaiMataUji = resdata;

        // reset nilaiSiswaNya

        while (this.nilaiSiswaNya.length) {
          this.nilaiSiswaNya.removeAt(0);
        }

        for (let i = 0; i < this.listNilaiMataUji.length; i++) {
          this.nilaiSiswaNya.push(this.formBuilder.group({            
            'id_matauji': this.listNilaiMataUji[i] && this.listNilaiMataUji[i].id_matauji ? this.listNilaiMataUji[i].id_matauji : '',
            'id_matapel_uji': this.listNilaiMataUji[i] && this.listNilaiMataUji[i].id_matapel_uji ? this.listNilaiMataUji[i].id_matapel_uji : '',
            'nilai': this.listNilaiMataUji[i] && this.listNilaiMataUji[i].nilai ? this.listNilaiMataUji[i].nilai : '',
            'keterangan': this.listNilaiMataUji[i] && this.listNilaiMataUji[i].keterangan ? this.listNilaiMataUji[i].keterangan : ''
          }));
        }
        this.uiService.hideLoader();
      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllHasilPpsb() {
    this.uiService.showLoader();

    this.nilaiPpsbService.getHasilPpsb(this.currentPeriodeId).subscribe({
      next: resdata => {
        console.log("HASIL PPSB", resdata);
        this.listHasilPpsb = resdata.data;

        
        this.uiService.hideLoader();
      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  exportToExcel() {
    this.uiService.showLoader();

    this.nilaiPpsbService.getHasilPpsb(this.currentPeriodeId).subscribe({
      next: resdata => {
        console.log("HASIL PPSB", resdata);
        
        let data = [];

        for (const k in resdata.data) {
          const v = resdata.data[k];
          const row = {
            no: k+1,
            regnumber: v.SiswaHasilPpsb.regnumber,
            no_induk: v.SiswaHasilPpsb.no_induk,
            nama: v.SiswaHasilPpsb.nama_lengkap,
            "no VA": v.TagihanHasilPpsb !== null ? v.TagihanHasilPpsb.vatagihan !== null ? v.TagihanHasilPpsb.vatagihan.prefix + " " + v.TagihanHasilPpsb.vatagihan.nomor : "" : "",
            Tagihan: v.TagihanHasilPpsb !== null ? v.TagihanHasilPpsb.nilai_tagihan : "",
            Pembayaran: v.TagihanHasilPpsb !== null ? v.TagihanHasilPpsb.jumlah_pembayaran : "",
            "Sisa Tagihan": v.TagihanHasilPpsb !== null ? v.TagihanHasilPpsb.sisa_tagihan : "",
            "Paket Pembayaran": v.TagihanHasilPpsb !== null ? v.TagihanHasilPpsb.nama_paket : "",
            Kamar: "",
            Penguji: v.PengujiHasilPpsb.userProfile.real_name,
            "Status Kelulusan": v.status_kelulusan,
            "Tingkatan": "",
            "Posisi Daftar": "",
            "DU":"",
            "User Input": v.UserInputHasilPpsb.userProfile.real_name
          }

          data.push(row);
        }

        this.excelService.exportAsExcelFile(data, 'hasil-test.xlsx');

        this.uiService.hideLoader();
      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  confirmDelete(siswaId: any) {
    this.uiService.showConfirmDelete(this, siswaId);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.nilaiPpsbService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getAllHasilPpsb();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  generateVA(siswa: any) {
    console.log("VA", siswa);
    
    if (siswa.TagihanHasilPpsb !== null && siswa.SiswaHasilPpsb.no_induk !== null) {
      this.uiService.showLoader();
      
      const payload = {
        no_induk: siswa.SiswaHasilPpsb.no_induk,
        nilai_tagihan: siswa.TagihanHasilPpsb.nilai_tagihan,
        id_tagihan: siswa.id_tagihan,
      }
  
      console.log("PAYLOAD", payload);
  
      this.nilaiPpsbService.generateVa(payload).subscribe({
        next: resdata => {
          console.log("VA pangkal", resdata);
          
          this.getAllHasilPpsb();
          
          this.uiService.hideLoader();
        },
        error: err => {
          console.log(err);
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });

    } else {
      this.uiService.showError("Belum ada tagihan.");
    }
  }
}
