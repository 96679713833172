<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      [ngClass]="statisticActive"
      id="statistik-tab"
      data-bs-toggle="tab"
      data-bs-target="#statistik"
      type="button"
      role="tab"
      aria-controls="hostatistikme"
      [attr.aria-selected]="statisticButtonSelected"
      routerLink="/siswa/statistik"
    >
      Statistik Pendaftaran
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      [ngClass]="listActive"
      id="list-tab"
      data-bs-toggle="tab"
      data-bs-target="#list"
      type="button"
      role="tab"
      aria-controls="list"
      [attr.aria-selected]="listButtonSelected"
      routerLink="/siswa/list"
    >
      List
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      [ngClass]="pembayaranActive"
      id="pembayaran-tab"
      data-bs-toggle="tab"
      data-bs-target="#pembayaran"
      type="button"
      role="tab"
      aria-controls="pembayaran"
      [attr.aria-selected]="pembayaranButtonSelected"
      routerLink="/siswa/pembayaran"
    >
      Pembayaran Registrasi
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      [ngClass]="penentuanKelasActive"
      id="penentuan-kelas-tab"
      data-bs-toggle="tab"
      data-bs-target="#penentuankelas"
      type="button"
      role="tab"
      aria-controls="penentuankelas"
      [attr.aria-selected]="penentuanKelasButtonSelected"
      routerLink="/siswa/penentuankelas"
    >
      Penentuan Kelas/Kamar
    </button>
  </li>
  <li class="nav-item dropdown">
    <a
      class="nav-link dropdown-toggle"
      [ngClass]="rekapActive"
      data-bs-toggle="dropdown"
      href="#"
      role="button"
      aria-expanded="false"
    >
      Rekap
    </a>
    <ul class="dropdown-menu">
      <li><a class="dropdown-item" href="#" routerLink="/siswa/upload-dokumen">Berkas</a></li>
      <li><a class="dropdown-item" href="#" routerLink="/siswa/duplicate-name">Double Nama</a></li>
      <li><a class="dropdown-item" href="#" routerLink="/siswa/tanggal-ujian">Tanggal Ujian</a></li>
      <li><a class="dropdown-item" href="#" routerLink="/siswa/lokasi-ujian">Lokasi Ujian</a></li>
    </ul>
  </li>
  <li *ngxPermissionsOnly="['SUPER_ADMIN','ADMINISTER_SETTING_PENDAFTARAN']" class="nav-item" role="presentation">
    <a
      class="nav-link dropdown-toggle"
      data-bs-toggle="dropdown"
      href="#"
      role="button"
      aria-expanded="false"
    >
      Setup
    </a>
    <ul class="dropdown-menu">
      <li><a class="dropdown-item" href="#" [routerLink]="['/settings/gelombangpendaftaran/list']" [queryParams]="{backUrl: 'siswa/statistik'}">Gelombang</a></li>
      <li><a class="dropdown-item" href="#" [routerLink]="['/settings/contentregis/list']" [queryParams]="{backUrl: 'siswa/statistik'}">Content</a></li>
      <li><a class="dropdown-item" href="#" [routerLink]="['/settings/berkas/list']" [queryParams]="{backUrl: 'siswa/statistik'}">Berkas</a></li>
      <li><a class="dropdown-item" href="#" [routerLink]="['/settings/lokasiujian/list']" [queryParams]="{backUrl: 'siswa/statistik'}">Lokasi Ujian</a></li>
      <li><a class="dropdown-item" href="#" [routerLink]="['/settings/matauji/list']" [queryParams]="{backUrl: 'siswa/statistik'}">Maeri Tes</a></li>
    </ul>
  </li>
</ul>