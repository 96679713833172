<div class="tab-content" id="myTabContent">
    <div *ngIf="backUrl !== ''" class="back-button" (click)="onBack()">
        <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
    </div>
  <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
      <div class="tabs-title">
          LIST DATA GELOMBANG PENDAFTARAN Tahun {{ currentPeriode.name }}
      </div>
      <div class="list-options">
          
  
          
          <a routerLink="/settings/gelombangpendaftaran/add" class="btn btn-blue-grey">
              + Tambah Gelombang Pendaftaran
          </a>
          
      </div>
      <div class="table-wrapper">
          
          <div class="table-responsive">
              <table class="table table-borderless">
                  <thead>
                      <tr>
                          <th>No.</th>
                          <th>Tahun Ajaran</th>
                          <th>Gelombang</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Ujian Tulis</th>
                          <th>Ujian Lisan</th>
                          <th>Pengumuman Kelulusan</th>
                          <th>Status</th>
                          <th> </th>
                      </tr>
                  </thead>
                  <tbody>                        
                      <tr
                      *ngFor="
                          let gelombang of listGelombang 
                          let i = index
                      "
                      >
                          <td>{{ i + 1 + ((page - 1) * pageSize) }}</td>
                          <td>{{ gelombang.Periode.name }}</td>
                          <td>{{ gelombang.title }}</td>
                          <td>{{ transformDate(gelombang.start_date) }}</td>
                          <td>{{ transformDate(gelombang.end_date) }}</td>
                          <td>{{ transformDate(gelombang.ujian_tulis_date) }}</td>
                          <td>{{ transformDate(gelombang.ujian_lisan_date) }}</td>
                          <td>{{ transformDate(gelombang.pengumuman_kelulusan_date) }}</td>
                            <td>
                                <span class="tagihan" *ngIf="gelombang.active; else gelombangNotActive">Active</span>
                                <ng-template #gelombangNotActive>
                                    Not Active
                                </ng-template>
                            </td>
                          <td class="settings">
                              <div class="dropend">
                                  <a href="#" role="button" id="dropdownMenuLink"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      <img src="./assets/images/icon-setting.svg" alt="">
                                  </a>
                                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                      <li><a routerLink="/settings/gelombangpendaftaran/edit/{{gelombang.id}}" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>                                        
                                      <li><a (click)="confirmDelete(gelombang.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                                  </ul>
                              </div>
                          </td>
                      </tr>
                      <tr *ngIf="listGelombang.length === 0" class="empty">
                          <td colspan="10">Tidak terdapat data atau data tidak ditemukan.</td>
                      </tr>
                      
                  </tbody>
              </table>
          </div>

      </div>

      
  </div>
  
</div>
