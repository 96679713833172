<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/penempatan-kelas/statistik" class="nav-link" [class.active]="isStatistik" id="statistik-tab" data-bs-toggle="tab" data-bs-target="#statistik" type="button" role="tab" aria-controls="statistik" aria-selected="false">Statistik</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/penempatan-kelas/list" class="nav-link" [class.active]="isList" id="list-tab" data-bs-toggle="tab" data-bs-target="#list" type="button" role="tab" aria-controls="list" aria-selected="false">Kelas</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/penempatan-kelas/peringkat" class="nav-link" [class.active]="isPeringkat" id="peringkat-tab" data-bs-toggle="tab" data-bs-target="#peringkat" type="button" role="tab" aria-controls="peringkat" aria-selected="false">Peringkat Per-Semester</button>
  </li>
</ul>
