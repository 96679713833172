import { Component, OnInit } from '@angular/core';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/helper/common.service';
import { JadwalPelajaranService } from 'src/app/services/jadwal-pelajaran.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jadwal-mengajar-upload',
  templateUrl: './jadwal-mengajar-upload.component.html',
  styleUrls: ['./jadwal-mengajar-upload.component.css']
})
export class JadwalMengajarUploadComponent implements OnInit {

  listSemester: any = [];
  currentSemester = "";
  listTahunajaran: any = [];
  currentPeriodeId: any = null;
  dataToUpload: any = null;
  pageid = "upload-jadwal-mengajar";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  errorMessage = "";
  currentDate = "";
  submitted = false;
  choosenFile: any = null;
  
  formUploadJadwal: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),    
    id_semester: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private tahunajaranService: TahunajaranService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private jadwalpelajaranService: JadwalPelajaranService,
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {    
    this.getCurrentTahunajaran();
    this.listSemester = this.commonService.getListSemester();
    this.initForm();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {
    this.formUploadJadwal = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        id_semester: ['', Validators.required],
      },
    );
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formUploadJadwal.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;
        
        for (let i=0; i<resdata.Semester.length; i++) {
          const periodeSemester = this.commonService.getCurrentDateBetweenDateRange(resdata.Semester[i].start_date, resdata.Semester[i].end_date);

          if (periodeSemester) {
            this.currentSemester = resdata.Semester[i].semester;
            this.formUploadJadwal.controls['id_semester'].setValue(this.currentSemester);
            
            break;    
          }
        }

        this.uiService.hideLoader();

        this.getAllTahunajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }


  onSubmitformUploadJadwal() {
    this.submitted = true;
    console.log(JSON.stringify(this.formUploadJadwal.value, null, 2));

    if (this.dataToUpload?.length && this.dataToUpload !== null) {
      if (this.formUploadJadwal.invalid) {
        return;
      }

      this.uiService.showLoader();
      const tahun_ajaran_id = this.formUploadJadwal.value.id_tahun_ajaran;
      const semester = this.formUploadJadwal.value.id_semester;
      this.jadwalpelajaranService.upload(this.dataToUpload[0], tahun_ajaran_id, semester).subscribe({
        next: (event: any) => {
          this.uiService.hideLoader();
          
          if (event.type === HttpEventType.UploadProgress) {
            // progress
          } else if (event instanceof HttpResponse) {
            this.uiService.hideLoader();
            this.uiService.showSuccess(event.body.message);
            
            this.choosenFile.target.value = null;
      
          }
        },
        error: (err: any) => {
          console.log(err);
          this.uiService.hideLoader();
  
          if (err.error && err.error.message) {
            this.uiService.showError(err.error.message);
          } else {
            this.uiService.showError('Could not upload the file!');
          }
        }
      });
    } else {
      this.uiService.showError("Please upload file first");
    }
    
  }

  onFileChange(e: Event): void {
    this.choosenFile = e;
    const file = (e.target as HTMLInputElement).files;
    this.dataToUpload = file;
    console.log(this.dataToUpload);
    
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formUploadJadwal.controls;
  }

}
