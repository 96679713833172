<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button routerLink="/pengasuhan/absensi-kamar" class="nav-link" [class.active]="isInputAbsensiKamar" id="input-guru-tab" data-bs-toggle="tab" data-bs-target="#inputGuru" type="button" role="tab" aria-controls="list" aria-selected="false">Input</button>
    </li>
    <li class="nav-item" role="presentation">
      <button routerLink="/pengasuhan/list-absensi-kamar" class="nav-link" [class.active]="isListAbsensi" id="catatan-siswa-tab" data-bs-toggle="tab" data-bs-target="#catatanSiswa" type="button" role="tab" aria-controls="nilai" aria-selected="false">List</button>
    </li>
    <li class="nav-item" role="presentation">
      <button routerLink="/pengasuhan/laporan-persiswa" class="nav-link" [class.active]="isLaporanPerSIswa" id="list-input-tab" data-bs-toggle="tab" data-bs-target="#listInput" type="button" role="tab" aria-controls="deleted" aria-selected="false">Laporan Per-Siswa</button>
    </li>
    <li class="nav-item" role="presentation" *ngxPermissionsOnly="['SUPER_ADMIN','VIEW_MONITORING_ABSENSI']">
      <button routerLink="/pengasuhan/laporan-perkamar" class="nav-link" [class.active]="isLaporanPerKamar" id="laporan-tab" data-bs-toggle="tab" data-bs-target="#laporan" type="button" role="tab" aria-controls="deleted" aria-selected="false">Laporan Per-Kamar</button>
    </li>
    <li class="nav-item" role="presentation" *ngxPermissionsOnly="['SUPER_ADMIN','VIEW_MONITORING_ABSENSI']">
      <button routerLink="/pengasuhan/monitoring-absensi-kamar" class="nav-link" [class.active]="isMonitoringAbsesiKamar" id="monitoring-tab" data-bs-toggle="tab" data-bs-target="#isMonitoringAbsesiKamar" type="button" role="tab" aria-controls="deleted" aria-selected="false">Monitoring Absen Kamar</button>
    </li>
    <li class="nav-item" role="presentation" *ngxPermissionsOnly="['SUPER_ADMIN','VIEW_MONITORING_ABSENSI']">
      <button routerLink="/pengasuhan/monitoring-penginput-absensi-kamar" class="nav-link" [class.active]="isMonitoringPenginput" id="monitoring-tab" data-bs-toggle="tab" data-bs-target="#isMonitoringPenginput" type="button" role="tab" aria-controls="deleted" aria-selected="false">Monitoring Penginput</button>
    </li>
</ul>

<!-- <div class="tab-content" id="myTabContent">
    <div class="tab-pane" [class.active]="isListAbsensi" id="catatanSiswa" role="tabpanel" aria-labelledby="catatan-siswa-tab">
        <div class="tabs-title">Catatan Siswa</div>
    </div>
</div> -->

<router-outlet></router-outlet>
  