<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="guru-input"
    role="tabpanel"
    aria-labelledby="guru-input-tab"
  >
    <div class="tabs-title">Input Nilai Raport Semester</div>

    
    <div class="col-12 col-lg-12">
      <div class="card">
          
          <div class="card-body">
              <form [formGroup]="formInputInitMatapelajaran">
                  <div class="row">
                      <div class="col-12 col-lg-12">
                          <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="nik" class="form-label">Tahun Ajaran <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-10">
                                      <input type="text" 
                                        formControlName="tahun_ajaran"
                                        readonly
                                        class="form-control btn-secondary" placeholder="">
                                  </div>
                              </div>
                          </div>
                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">Semester <span
                                            class="required">*</span></label>
                                </div>
                                <div class="col-10">
                                  <select
                                      formControlName="id_semester"
                                      [ngClass]="{ 'is-invalid': submitted && f['id_semester'].errors }"
                                      class="form-select"
                                      (change)="changeSemester($event)">
                                      <option value="" selected>Pilih Semester</option>
                                      <option [value]="data.kode" *ngFor="let data of listSemester">{{data.name}}</option>
                                  </select>
                                </div>
                            </div>
                        </div>
                          <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="name" class="form-label">Kelas <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-10">
                                    <select
                                        formControlName="kelas_id"
                                        [ngClass]="{ 'is-invalid': submitted && f['kelas_id'].errors }"
                                        class="form-select"
                                        (change)="changeKelas($event)">
                                        <option value="" selected>Pilih</option>
                                        <option [value]="data.id" *ngFor="let data of listKelas">{{data.nama_kelas}}</option>
                                    </select>
                                  </div>
                              </div>
                          </div>            
                          
                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">Wali Kelas</label>
                                </div>
                                <div class="col-10">
                                    <input type="text" class="form-control" formControlName="walikelas_name"
                                        placeholder="">
                                </div>
                            </div>
                          </div>
                          <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="instagram" class="form-label">Guru</label>
                                  </div>
                                  <div class="col-10">
                                      <input type="text" class="form-control" formControlName="guru_name"
                                         placeholder="">
                                  </div>
                              </div>
                          </div>
                          
                          
                          <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="twitter" class="form-label">Mata Pelajaran</label>
                                  </div>
                                  <div class="col-10">
                                      <select
                                        formControlName="studi_id"
                                        [ngClass]="{ 'is-invalid': submitted && f['studi_id'].errors }"
                                        class="form-select"
                                        (change)="changeStudi($event)">
                                        <option value="" selected>Pilih</option>
                                        <option [value]="data.studi_id" *ngFor="let data of listMataPelajaran">{{data.nama_studi}}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      
                  </div>
              </form>
          </div>
      </div>
    </div>

    <form [formGroup]="formInputRaportHarian" (ngSubmit)="onSubmitformInputRaportSemester()">
      <div class="mt-4 list-options">
        <button class="btn btn-blue-grey mt-2" style="display:inline;">
          Simpan
        </button>              
      </div>
      
      <div class="table-wrapper">
        <div class="table-options">
          <div class="show-table">
            
          </div>
        </div>
        
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th>No.</th>
                <th>No, Induk</th>
                <th>Nama</th>
                <th>Nilai</th>
              </tr>
            </thead>
            <tbody>
                <ng-container formArrayName="dataFormNilaiHarian">
                  <tr [formGroupName]="i" *ngFor="let siswa of listNilaiRaportSemester; let i = index">
                    <td> 
                      <input formControlName="siswa_id" type="text" class="form-control d-none">
                      <input formControlName="siswa_status" type="text" class="form-control d-none">
                      {{ i + 1 + (page - 1) * pageSize }}
                    </td>
                    <td>{{ siswa.no_induk }}</td>
                    <td>{{ siswa.nama_lengkap }}</td>
                    
                    <td>
                      <input [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}" type="number" class="form-control" formControlName="nilai">
                      <!-- <div class="form-outline w-25">
                        <input [ngClass]="{'d-none': siswa.status !== 'ACTIVE'}" type="text" class="form-control" formControlName="nilai">
                      </div> -->
                    </td>
                    
                  </tr>
                </ng-container>            
                
                <tr *ngIf="listNilaiRaportSemester.length === 0" class="empty">
                  <td colspan="5">
                    Tidak terdapat data atau data tidak ditemukan.
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
  
      </div>
    </form>
  </div>
</div>