import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';


@Injectable({
  providedIn: 'root'
})
export class LembagaService {

  constructor(private netService: NetworkService) { }

  getAllLembaga(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/lembaga', params);
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/lembaga');
  }

  get(id: string): Observable<any> {
    return this.netService.getRequest(`/v1/lembaga/${id}`);
  }

  update(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/lembaga/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/lembaga/${id}`);
  }
}
