import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SdmService } from 'src/app/services/sdm.service';
import { KelasService } from 'src/app/services/kelas.service';
import { WalikelasService } from 'src/app/services/walikelas.service';

@Component({
  selector: 'app-sdm-walikelas',
  templateUrl: './sdm-walikelas.component.html',
  styleUrls: ['./sdm-walikelas.component.css']
})
export class SdmWalikelasComponent implements OnInit {
  listWaliKelas: any = [];
  listTahunajaran: any = [];
  listTahapanPendaftaran: any = [];
  listGuru: any = [];
  listKelas: any = [];
  currentPeriodeId: any = null;
  currentPpsbPeriodeId: any = null;
  currentAngkatan = "";
  errorMessage = '';
  submitted = false;
  pageid = 'proguser';
  searchTerm = '';
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  closeResult: string = '';
  isNew = true;
  isShowLoaderGuru: Boolean = false;
  searchGuruTerm = "";
  idWalikelas = "";

  formChangeTahunAjaran: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
  });

  formWalikelas: FormGroup = new FormGroup({
    periode_id: new FormControl(''),
    id: new FormControl(''),
    kelas_id: new FormControl(''),
    guru_name: new FormControl(''),
    proguser_id: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private sdmService: SdmService,
    private kelasService: KelasService,
    private walikelasService: WalikelasService,
    private tahunajaranService: TahunajaranService,
  ) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.initForm();
    this.getKelas();
    this.getCurrentTahunajaran();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add('siswa-baru-list');
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    console.log('handle PAGE', this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  search(): void {
    this.page = 1;
  }

  initForm() {
    this.formWalikelas = this.formBuilder.group(
      {
        id: [''],
        periode_id: ['', Validators.required],
        guru_name: ['', Validators.required],
        kelas_id: ['', Validators.required],
        proguser_id: ['', Validators.required],
      },
    );
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;

        this.uiService.hideLoader();

        this.getAllWalikelasByPeriode();

        this.getAllTahunajaran();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formChangeTahunAjaran.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllSiswaBaru();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    this.currentPeriodeId = e.target.value;

    this.getAllWalikelasByPeriode();
  }

  // get f(): { [key: string]: AbstractControl } {
  //   return this.formWalikelas.controls;
  // }

  get f(): { [key: string]: AbstractControl } {
    return this.formWalikelas.controls;
  }

  onSubmitFormWalikelas(modal: any): void {
    console.log(JSON.stringify(this.formWalikelas.value, null, 2));
    
    this.submitted = true;
    
    if (this.formWalikelas.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }
    
    modal.dismiss('Cross click');

    this.uiService.showLoader();

    if (this.isNew) {
      this.walikelasService.add(this.formWalikelas.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.resetForm();

          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          //this.router.navigate(['/ppsb/list']);
          this.getAllWalikelasByPeriode();
  
        },
        error: err => {
          console.log(err);

          this.resetForm();
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });    
    } else {
      this.walikelasService.update(this.idWalikelas, this.formWalikelas.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.resetForm();
          
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          //this.router.navigate(['/ppsb/list']);
          this.getAllWalikelasByPeriode();
  
        },
        error: err => {
          console.log(err);
          // this.formWalikelas.reset();
          this.submitted = false;
          // this.initForm();
          // this.formWalikelas.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
          // this.listBiayaPaketPembayaran = [];
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  getAllWalikelasByPeriode() {
    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);
    params['periode_id'] = this.currentPeriodeId;

    this.walikelasService.getAll(params).subscribe({
      next: resdata => {
        console.log("DATA WALIKELAS", resdata);
        const { data, totalrows } = resdata;
        this.count = totalrows;
        this.listWaliKelas = data;

        this.uiService.hideLoader();
      },
      error: err => {
        console.log(err);
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    }); 
  }

  editWalikelas(wali: any) {

  }

  confirmDelete(id: String) {
    
    this.uiService.showConfirmDelete(this, id);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.walikelasService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getAllWalikelasByPeriode();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  resetForm() {
    this.formWalikelas.reset();
    this.submitted = false;
    this.initForm();
    this.formWalikelas.controls['periode_id'].setValue(this.currentPeriodeId);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  openModalFormWalikelas(content:any) {
    this.isNew = true;
    this.submitted = false;
    this.formWalikelas.reset();
    // this.formWalikelas.controls['id'].setValue(student.id);
    // this.formWalikelas.controls['nama_lengkap'].setValue(student.nama_lengkap);
    // this.formWalikelas.controls['current_status'].setValue(student.current_status);
    this.formWalikelas.controls['periode_id'].setValue(this.currentPeriodeId);
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openEditModalFormWalikelas(content:any, wali: any) {
    console.log("WALI", wali);
    
    this.isNew = false;
    this.submitted = false;
    this.formWalikelas.reset();
    this.idWalikelas = wali.id;
    this.formWalikelas.controls['id'].setValue(wali.id);
    this.formWalikelas.controls['guru_name'].setValue(wali.User.userProfile.real_name);
    this.formWalikelas.controls['proguser_id'].setValue(wali.User.id);

    if (wali.Kelas !== null) {
      this.formWalikelas.controls['kelas_id'].setValue(wali.Kelas.id);    
    }
    this.formWalikelas.controls['periode_id'].setValue(wali.periode_id);
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  chooseGuru(data: any) {
    this.listGuru = [];
    console.log(data);
    this.formWalikelas.controls['guru_name'].setValue(data.userProfile.real_name);
    this.formWalikelas.controls['proguser_id'].setValue(data.userProfile.id_user);
  }

  searchGuru(): void {
    this.searchGuruTerm = this.formWalikelas.value.guru_name;

    this.listGuru = [];
    if (this.searchGuruTerm != "") {  
      this.isShowLoaderGuru = true;
      const params = this.getRequestParams(this.searchGuruTerm, this.page, this.pageSize);

      console.log("PARAMS", params);
      
      this.sdmService.getAll(params).subscribe({
        next: resdata => {
          console.log(resdata);
          const { data, totalrows } = resdata;
  
          this.isShowPagination == totalrows > this.pageSize ? true : false;
          this.listGuru = data;
          this.count = totalrows;
  
          console.log('COUNT', this.count);
          this.isShowLoaderGuru = false;
        
          this.uiService.hideLoader();
  
        },
        error: err => {
          this.isShowLoaderGuru = false;
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  changeKelas(e: any) {

  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

}
