import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class JadwalPelajaranService {

  constructor(private netService: NetworkService) { }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/kamar');
  }

  getListJadwalGuruByGuruPeriodeSemester(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/jadwalpelajaran/getByPeriodeSemesterAndGuru');
  }

  getListMataPelajaranByPeriodeSemesterAndGuru(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/jadwalpelajaran/getListMataPelajaranByPeriodeSemesterAndGuru');
  }

  getListJadwalGuruByPeriodeSemesterAndDay(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/jadwalpelajaran/getByPeriodeSemesterAndDay');
  }

  getListJadwalGuruByPeriodeSemester(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/jadwalpelajaran/getByPeriodeSemester');
  }

  getDayPeriod(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/jadwalpelajaran/getDayPeriod');
  }

  getJadwalByJamke(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/jadwalpelajaran/getSingleDataByPeriodOfLesson');
  }

  upload(file: File, tahun_ajaran_id: string, semester: string): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    return this.netService.fileRequest(formData, `/v1/uploadjadwalpelajaran?tahun=${tahun_ajaran_id}&semester=${semester}`);

  }

}
