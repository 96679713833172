import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class AbsensiService {

  constructor(private netService: NetworkService) { }

  getAbsensiHarianSiswa(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/absensiharian');
  }

  getAbsensiHarianKamar(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/absensikamar');
  }

  getMonitoringAbsensiHarianSiswa(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getmonitoringabsensiharian');
  }

  getAbsensiHarianSiswaByAbsen(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/absensiharianbyabsen');
  }

  getAbsensiKamarSiswaByAbsen(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/absensikamarbyabsen');
  }

  addAbsensiHarianSiswa(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/absensihariansiswa');
  }

  addAbsensiHarianKamar(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/absensihariankamar');
  }

  getGuruInput(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getabsensiguruinput');
  }

  getGuruInputKamar(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getabsensiguruinputkamar');
  }

  getListGuruInput(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/listinputabsensihariansiswa');
  }

  getListGuruInputKamar(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/listinputabsensihariankamar');
  }

  getListAbsensiPerKategori(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/listinputabsensihariansiswaperkategori');
  }

  getListAbsensiPerKategoriJamke(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/listinputabsensihariansiswaperkategorijamke');
  }

  getListAbsensiByDateRange(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/listinputabsensihariansiswabydaterange');
  }

  getListAbsensiKamarByDateRange(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/listinputabsensihariankamarbydaterange');
  }

  getListAbsensiKamarByMonth(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/listinputabsensihariankamarbymonth');
  }

  getListAbsensiKamarPenginput(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/listinputabsensihariankamarpenginput');
  }

  saveGuruInput(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/absensiguruinput');
  }

  saveGuruInputKamar(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/absensiguruinputkamar');
  }
  
}
