<div class="row">
  <div class="col-12 col-lg-5">
    <div class="card">
      <div class="card-header">Riwayat Kesehatan</div>
      <div class="card-body">
        <div class="card-text">
          <div class="row">
            <div class="col-6 fw-bold">
              <p>Golongan Darah</p>
              <p>Penyakit Yang Pernah Diderita</p>
              <p>Penyakit Yang Sedang Diderita</p>
              <p>Tinggi Badan</p>
              <p>Berat Badan</p>
            </div>
            <div class="col-6" *ngIf="selectedData != null else emptyData">
              <p>{{ selectedData.golongan_darah }}</p>
              <p>{{ selectedData.riwayat_penyakit }}</p>
              <p>{{ selectedData.riwayat_penyakit }}</p>
              <p>{{ selectedData.tinggi_badan }}</p>
              <p>{{ selectedData.berat_badan }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyData>
  <div class="col-6">
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
    <p>-</p>
  </div>
</ng-template>
