import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';


@Injectable({
  providedIn: 'root'
})
export class NilaiPpsbService {

  constructor(private netService: NetworkService) { }

  getAll(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/nilaippsb', params);
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/nilaippsb');
  }

  generateVa(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/generatevadanapangkal');
  }

  getById(id: any): Observable<any> {
    return this.netService.getRequest(`/v1/nilaippsb/${id}`);
  }

  getNilaiById(id: any): Observable<any> {
    return this.netService.getRequest(`/v1/nilaippsb/${id}`);
  }

  getHasilPpsb(idperiode: any): Observable<any> {
    return this.netService.getRequest(`/v1/listhasilppsb/${idperiode}`);
  }

  getNilaiPpsb(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/listnilaippsb', params);
  }

  update(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/nilaippsb/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/hasilppsb/${id}`);
  }
}
