<app-siswa-penempatan-kelas></app-siswa-penempatan-kelas>

<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="statistik"
    role="tabpanel"
    aria-labelledby="statistik-tab"
  >
    <div class="tabs-title">Penempatan Kelas</div>

    
    <div class="list-options">
      <div class="form-select-warapper">
        <form [formGroup]="formAction">
          <select formControlName="id_tahun_ajaran" (change)="changeTahunAjaran($event)" class="form-select" name="id_tahun_ajaran" id="id_tahun_ajaran">
            <option [value]="" selected disabled>Pilih TA</option>
            <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
          </select>
        </form>
      </div>

      <div class="form-select-warapper">
        <form [formGroup]="formAction">
          <select formControlName="id_lembaga" (change)="changeLembaga($event)" class="form-select" name="id_lembaga" id="id_lembaga">
            <option [value]="0">Pilih Lembaga</option>
            <option [value]="data.id" *ngFor="let data of listsLembaga">{{data.nama_lembaga}}</option>
          </select>
        </form>
      </div>

      <div class="form-select-warapper">
        <form [formGroup]="formAction">
          <select (change)="changeTingkat($event)" class="form-select" name="id_tingkat" id="id_tingkat">
            <option value="">Pilih Tingkat</option>
            <option [value]="data.id" *ngFor="let data of listsTingkat">{{data.nama_tingkat}}</option>
          </select>
        </form>
      </div>
      
      <!-- <button class="btn btn-blue-grey" (click)="displayPlotKelas()">
        Display
      </button> -->
      <input #excelUpload type="file" (click)="excelUpload.value=''" (change)="onFileChange($event)" style="display: none;" multiple="false" />
      <button type="button" class="btn btn-success" (click)="excelUpload.click()">
        <img src="./assets/images/icon-excel.png" alt="Excel"> Upload Excel
      </button>
      <button type="button" class="btn btn-warning" (click)="downloadSampleFormat()">
        <img src="./assets/images/icon-excel.png" alt="Excel"> Sampel Format Excel
      </button>
      <button type="button" class="btn btn-danger">
        <img src="./assets/images/icon-excel.png" alt="Excel"> Report
      </button>
      <!-- <button type="button" class="btn btn-danger">
        <img src="./assets/images/icon-pdf.png" alt="PDF"> PDF
      </button> -->
    </div>
    
    <div class="table-wrapper">
      <div class="table-options">
        <div class="show-table">
          Show
          <select
            class="form-control"
            name="show"
            id="show"
            (change)="handlePageSizeChange($event)"
          >
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }}
            </option>
          </select>
          entries
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th></th>
              <th>Kelas</th>
              <th>Belum Bayar DU</th>
              <th>Aktif</th>
              <th>Keluar</th>
              <th>Alumni</th>
              <th>Tafawwuk</th>
              <th>Suluk</th>
              <th>Tahfidz</th>
              <th>Bimbel</th>
              <th>Tahwil</th>
              <th>Dikeluarkan</th>
              <th>Unclear Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let siswa of listPenempatanKelas
                  | paginate
                    : {
                        id: pageid,
                        itemsPerPage: pageSize,
                        currentPage: page,
                        totalItems: count
                      };
                let i = index
              "
            >
              <td>{{ i + 1 + (page - 1) * pageSize }}</td>
              <td><a href="javascript:void(0)" (click)="showDetailPenempatanKelas(siswa)">{{ siswa.nama_kelas }}</a></td>
              <td>{{ siswa.du }}</td>
              <td>{{ siswa.aktif }}</td>
              <td>0</td>
              <td>{{ siswa.alumni }}</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>{{ siswa.do }}</td>
              <td>{{ siswa.other }}</td>
            </tr>
            <tr *ngIf="listPenempatanKelas.length === 0" class="empty">
              <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
        <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
          <div></div>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center pagination-number">
              <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                <a
                  class="page-link"
                  (click)="p.setCurrent(page.value)"
                  *ngIf="p.getCurrent() !== page.value"
                >
                  {{ page.label }}
                </a>
                <a class="page-link" *ngIf="p.getCurrent() === page.value">
                  {{ page.label }}
                </a>
              </li>
            </ul>
          </nav>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="p.isFirstPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isFirstPage()"
                  (click)="p.previous()"
                  aria-label="Previous"
                >
                  <span>Previous</span>
                </a>
              </li>
              <li class="page-item" [class.disabled]="p.isLastPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isLastPage()"
                  (click)="p.next()"
                  aria-label="Next"
                >
                  <span>Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </pagination-template>

      <ng-template #emptyPagination>
        <div class="pagination-wrapper">
          <div>&nbsp;</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>