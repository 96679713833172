<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="guru-input" role="tabpanel" aria-labelledby="guru-input-tab">
        <div class="tabs-title">Scan Barcode</div>


        <div class="col-12 col-lg-12">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="formInputInitGuru">
                        <div class="row">
                            <div class="col-12 col-lg-12">
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-12">
                                            <input type="text" class="form-control" formControlName="scan_barcode"
                                                placeholder="SCAN BARCODE HERE">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="row mt-2 mb-2">

        </div>

        <div class="col-12 col-lg-12">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="formInputInitGuru">
                        <div class="row">
                            <div class="col-12 col-lg-12">
                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>