import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { GroupBiayaService } from 'src/app/services/group-biaya.service';
import { ChartMasterService } from 'src/app/services/chart-master.service';
import { ItemBiayaService } from 'src/app/services/item-biaya.service';
import { ItemGroupBiayaService } from 'src/app/services/item-group-biaya.service';

@Component({
  selector: 'app-group-biaya',
  templateUrl: './group-biaya.component.html',
  styleUrls: ['./group-biaya.component.css']
})
export class GroupBiayaComponent implements OnInit {

  listGroupBiaya: any = [];
  listItemBiaya: any = [];
  listChartMaster: any = [];
  groupBiayaId: any = null;
  errorMessage = '';

  formAddGroupBiaya: FormGroup = new FormGroup({
    name: new FormControl(''),
    id_akun: new FormControl(''),
  });
  submitted = false;
  isNew = true;
  editLembagaId = null;

  formAddItemGroupBiaya: FormGroup = new FormGroup({
    id_group_biaya: new FormControl(''),
    id_item_biaya: new FormControl(''),
    group_name: new FormControl(''),
  });

  pageid = "lembaga";

  nama = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];

  closeResult: string = '';

  constructor(
    private itemGroupBiayaService: ItemGroupBiayaService,
    private itemBiayaService: ItemBiayaService,
    private groupBiayaService: GroupBiayaService, 
    private chartMasterService: ChartMasterService,
    private uiService: UiService, 
    private datePipe: DatePipe,
    private modalService: NgbModal, 
    private formBuilder: FormBuilder
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getAllChartMaster();
    this.getAllGroupBiaya();
    this.getAllItemBiaya();

    this.formAddGroupBiaya = this.formBuilder.group(
      {
        name: [
          '',
          [
            Validators.required,
          ]
        ],
        id_akun: ['', Validators.required],
      }
    );

    this.formAddItemGroupBiaya = this.formBuilder.group(
      {
        id_group_biaya: [
          '',
          [
            Validators.required,
          ]
        ],
        id_item_biaya: ['', Validators.required],
        group_name: ['', Validators.required],
      }
    );
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  openModal(content:any) {
    this.isNew = true;
    this.submitted = false;
    this.formAddGroupBiaya.reset();
    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  openModalEdit(content:any, lembaga: any) {
    console.log(lembaga);
    this.editLembagaId = lembaga.id;
    this.isNew = false;
    this.formAddGroupBiaya.patchValue(lembaga)
    this.submitted = false;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getGroupBiayaById(groupBiayaId: any) {
    this.groupBiayaId = groupBiayaId;
    this.groupBiayaService.get(groupBiayaId).subscribe({
      next: resdata => {
        console.log("getDetailGroupBiaya", resdata);
        this.listGroupBiaya = resdata;
      
        this.uiService.hideLoader();
  
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  openModalItemBiaya(content:any, groupBiaya: any) {
    this.submitted = false;
    this.formAddItemGroupBiaya.reset();
    this.formAddItemGroupBiaya.controls['group_name'].setValue(groupBiaya.name);
    this.formAddItemGroupBiaya.controls['id_group_biaya'].setValue(groupBiaya.id);

    this.getGroupBiayaById(groupBiaya.id);

    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
     
  /**
   * Write code on Method
   *
   * @return response()
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getRequestParams(searchNama: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`nama`] = searchNama ? searchNama : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getAllItemBiaya() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.nama, this.page, this.pageSize);

    this.itemBiayaService.getAll(params).subscribe({
      next: resdata => {
        console.log(resdata);
        // const { data, totalrows } = resdata;
        const totalrows = 20;
        this.listItemBiaya = resdata;
        this.count = totalrows;
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllGroupBiaya() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.nama, this.page, this.pageSize);

    this.groupBiayaService.getAll(params).subscribe({
      next: resdata => {
        console.log("getAllGroupBiaya", resdata);
        // const { data, totalrows } = resdata;
        const totalrows = 20;
        this.listGroupBiaya = resdata;
        this.count = totalrows;
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllChartMaster() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.nama, this.page, this.pageSize);

    this.chartMasterService.getAll(params).subscribe({
      next: resdata => {
        console.log(resdata);
        // const { data, totalrows } = resdata;
        const totalrows = 20;
        this.listChartMaster = resdata;
        this.count = totalrows;
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.getAllGroupBiaya();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getAllGroupBiaya();
  }

  search(): void {
    this.page = 1;
    this.getAllGroupBiaya();
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/empty-image.png';
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAddGroupBiaya.controls;
  }

  get g(): { [key: string]: AbstractControl } {
    return this.formAddItemGroupBiaya.controls;
  }

  onSubmitGroupBiaya(): void {
    this.submitted = true;
    
    
    if (this.formAddGroupBiaya.invalid) {
      return;
    }
    
    this.modalService.dismissAll();
    this.uiService.showLoader();


    if (this.isNew) {
      this.groupBiayaService.add(this.formAddGroupBiaya.value).subscribe({
        next: resdata => {
          this.submitted = false;
          this.formAddGroupBiaya.reset();
          console.log(resdata);
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
          this.getAllGroupBiaya();
        },
        error: err => {
          console.log(err);
          this.submitted = false;
          this.formAddGroupBiaya.reset();
          
          this.errorMessage = err.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    } else {
      this.groupBiayaService.update(this.editLembagaId, this.formAddGroupBiaya.value).subscribe({
        next: resdata => {
          this.submitted = false;
          this.formAddGroupBiaya.reset();
          console.log(resdata);
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
          this.getAllGroupBiaya();
        },
        error: err => {
          console.log(err);
          this.submitted = false;
          this.formAddGroupBiaya.reset();
          
          this.errorMessage = err.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }


    console.log(JSON.stringify(this.formAddGroupBiaya.value, null, 2));
  }

  onSubmitItemGroupBiaya(): void {
    this.submitted = true;
    
    
    if (this.formAddItemGroupBiaya.invalid) {
      return;
    }
        
    this.uiService.showLoader();


    this.itemGroupBiayaService.add(this.formAddItemGroupBiaya.value).subscribe({
      next: resdata => {
        this.submitted = false;
        //this.formAddItemGroupBiaya.reset();
        console.log(resdata);
        this.formAddItemGroupBiaya.controls['id_item_biaya'].setValue('');
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
        this.getGroupBiayaById(this.groupBiayaId);
      },
      error: err => {
        console.log(err);
        this.submitted = false;
        this.formAddGroupBiaya.reset();
        this.modalService.dismissAll();
        
        this.errorMessage = err.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });

    console.log(JSON.stringify(this.formAddItemGroupBiaya.value, null, 2));
  }

  confirmDelete(id: String) {
    this.uiService.showConfirmDelete(this, id);
  }

  confirmDeleteItemGroup(id: any, groupId: any) {
    this.uiService.showConfirmDeleteSpec(this, this.deleteItem, id, groupId);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    // if (isDelete) {

    //   console.log('DELETE THIS ID : ', isDelete, id);

    //   $SELF.uiService.showLoader();

    //   $SELF.lembagaService.delete(id).subscribe({
    //     next: (resdata: any) => {
    //       console.log(resdata);

    //       $SELF.getAllGroupBiaya();
          
    //       $SELF.uiService.hideLoader();
    //       $SELF.uiService.showSuccess('');

    //     },
    //     error: (err: any) => {
    //       console.log(err);
          
    //       $SELF.errorMessage = err.message;
    //       console.log($SELF.errorMessage);
          
    //       $SELF.uiService.hideLoader();
          
    //       $SELF.uiService.showError($SELF.errorMessage);
    //     }
    //   });

    // }
  }

  deleteItem(isDelete: Boolean, id: String, idGroup: string, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.itemGroupBiayaService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getGroupBiayaById(idGroup);
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, 'd - MM - Y');
  }

}
