import { Component, OnInit } from '@angular/core';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/helper/common.service';
import { ExcelService } from 'src/app/services/excel.service';
import { FileService } from 'src/app/services/file.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-brt-input',
  templateUrl: './brt-input.component.html',
  styleUrls: ['./brt-input.component.css']
})
export class BrtInputComponent implements OnInit {

  listSemester: any = [];
  listTahunajaran: any = [];
  listRekapInput: any = [];
  listKategoriAlat: any = [];
  listAlat: any = [];
  listPriority: any = [];
  listImgSrc: any = [];
  currentPeriodeId: any = null;
  dataToUpload: any = null;
  pageid = "upload-jadwal-mengajar";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  errorMessage = "";
  currentDate = "";
  submitted = false;
  currentSemester = "";
  progress: number = 0;
  domainUrlImage = ""
  currentDeletedImage = ""
  
  formInputBrt: FormGroup = new FormGroup({
    id_kategori_alat: new FormControl(''),    
    id_semester: new FormControl(''),
    tanggal: new FormControl(''),
    ruangan: new FormControl(''),
    kelas: new FormControl(''),
    detail: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private tahunajaranService: TahunajaranService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private fileService: FileService,
    private excelService: ExcelService,
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.domainUrlImage = this.fileService.getDomainImageUrl() + "/brt/";        
    this.listSemester = this.commonService.getListSemester();
    this.initForm();
    this.listKategoriAlat = [
      "Asrama dan Lingkunagn",
      "Kelas",
      "Kantor"
    ]
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {
    this.formInputBrt = this.formBuilder.group(
      {
        id_kategori_alat: ['', Validators.required],
        id_semester: ['', Validators.required],
        tanggal: [''],
        ruangan: ['', Validators.required],
        kelas: ['', Validators.required],
        detail: ['', Validators.required],
      },
    );

    this.currentDate = this.commonService.getCurentDate();
    this.formInputBrt.controls['tanggal'].setValue(this.currentDate);
    this.formInputBrt.controls['tanggal'].disable();
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  onSubmitformInputBrt() {
    this.submitted = true;
    console.log(JSON.stringify(this.formInputBrt.value, null, 2));

    if (this.formInputBrt.invalid) {
      return;
    }

    if (this.dataToUpload === null) {
      this.uiService.showError("Please upload file first");
    }

    console.log(this.dataToUpload);
    
  }

  onFileChange(evt: any) {
    const fdata: FileReader = this.excelService.convertExcelFileToJson(evt.target);

    fdata.onloadend = (e: any) => {
      // console.log("END OF ROAD", this.excelService.dataExcel);
      const fileUpload: any[] = this.excelService.dataExcel;
      const finalFileUpload = fileUpload.slice(1);

      if (finalFileUpload.length > 0) {
        let dataToUpload = [];
        for (const k in finalFileUpload) {
          const v = finalFileUpload[k];
          console.log(k, v);
          // 1. get periode_id
          // 2. get kelas_id
          // 3. get siswaid
          const column = [v[4],v[1],v[5]];
          dataToUpload.push(column)
        }
        console.log("DATA TO UPLOAD", dataToUpload);

        this.dataToUpload = dataToUpload;
        
        // const payload = {
        //   data: dataToUpload,
        //   idperiode: this.currentPeriodeId
        // }
        
        // this.siswakelasService.uploadSiswakelas(payload).subscribe({
        //   next: resdata => {
        //     console.log('RESPONSE', resdata);
    
        //     this.refreshListKelas();
            
        //     this.uiService.hideLoader();
    
        //   },
        //   error: err => {
        //     this.errorMessage = err.error.message;
        //     console.log(this.errorMessage);
    
        //     this.uiService.hideLoader();
    
        //     this.uiService.showError(this.errorMessage);
        //   }
        // });
      } 
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formInputBrt.controls;
  }

  uploadPhoto(e: Event): void {
    this.progress = 0;
    const file = (e.target as HTMLInputElement).files;
    if (file?.length && file !== null) {
      this.uiService.showLoader();
      this.fileService.upload(file[0], 'brt').subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            // progress
            this.progress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.uiService.hideLoader();
            this.uiService.showSuccess(event.body.message);
            // this.currentSaveFileName = event.body.filename;
            console.log("FNAME", event.body.filename);
            console.log("INFO", event.body);
            
            this.listImgSrc.push(event.body.filename);
            console.log("img src", this.listImgSrc);
          }
        },
        error: (err: any) => {
          console.log(err);
          this.uiService.hideLoader();
  
          if (err.error && err.error.message) {
            this.uiService.showError(err.error.message);
          } else {
            this.uiService.showError('Could not upload the file!');
          }
        }
      });
    }
  }

  removePhoto(image: any) {
    // this.currentDeletedImage = image;
    this.fileService.removePhotoBrt(image).subscribe({
      next: (event: any) => {
        console.log("EVENT", this.currentDeletedImage, event);
        
        const index = this.listImgSrc
        .findIndex((x: { value: any; }) => {
          console.log("x val", x);
          
          return x === image
        });
        console.log("UNCHECK IDX", index);
        
        this.listImgSrc.splice(index, 1);
      },
      error: (err: any) => {
        console.log('ERR', err);
      }
    });
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/empty-image.png';
  }

}
