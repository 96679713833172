import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import { SiswaService } from 'src/app/services/siswa.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';

@Component({
  selector: 'app-siswa-duplicate-name',
  templateUrl: './siswa-duplicate-name.component.html',
  styleUrls: ['./siswa-duplicate-name.component.css']
})
export class SiswaDuplicateNameComponent implements OnInit {

  listDuplicateNames: any = [];
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;

  closeResult: string = '';
  currentAngkatan = "";

  constructor(private uiService: UiService,
    private tahunajaranService: TahunajaranService,
    private siswaService: SiswaService) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getPpsbTahunajaran();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  getAllSiswaBaruDouble() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    params['angkatan'] = this.currentAngkatan;

    this.siswaService.getAllSiswaBaruDouble(params).subscribe({
      next: resdata => {
        console.log(resdata);

        this.listDuplicateNames = resdata;

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getPpsbTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePpsb().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        this.currentAngkatan = resdata.name;
      
        this.uiService.hideLoader();

        this.getAllSiswaBaruDouble();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

}
