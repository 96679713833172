import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tugas',
  templateUrl: './tugas.component.html',
  styleUrls: ['./tugas.component.css']
})
export class TugasComponent implements OnInit {

  isList = false
  isMonitoring = false

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    switch (this.router.url) {
      case '/tugas/list':
        this.isList = true;
        this.isMonitoring = false;
        break;

      case '/tugas/monitoring':
        this.isList = false;
        this.isMonitoring = true;
        break;

      default:
        this.isList = false;
        this.isMonitoring = false;
        break;
    }
  }

}
