import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BerkasService {

  constructor(private netService: NetworkService) { }

  getAllBerkas(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/berkas', params);
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/berkas');
  }

  getById(id: any): Observable<any> {
    return this.netService.getRequest(`/v1/berkas/${id}`);
  }

  update(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/berkas/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/berkas/${id}`);
  }

  
}
