<div class="contentfull">
  <!-- <a href="javascript:void(0)" class="closebtn" (click)="closeButton()">&times;</a> -->
  
  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="statistik"
      role="tabpanel"
      aria-labelledby="statistik-tab"
    >
  
      
      <div class="list-options">
    
        <button class="btn btn-blue-grey" (click)="printPage()">
          <img src="assets/images/icon-print.png" alt="Print"> Print
        </button>

        <button type="button" class="btn btn-warning" (click)="closeButton()">
          Close
        </button>
      </div>

      <div>

        <h1>Jadwal Pelajaran 1 Tahun Ajaran 2022-2023</h1>
        <h1>Kelas 1 A</h1>

      </div>
      
      <div class="table-wrapper">
        <div class="table-options">
  
        </div>
        <div class="table-responsive">
          <!-- <table class="table table-borderless">
            <thead>
              <tr>
                <th></th>
                <th>No</th>
                <th>Foto</th>
                <th>NISN</th>
                <th>No. Induk</th>
                <th>Nama</th>
                <th>Tahun Masuk</th>
                <th>Status</th>        
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let siswa of listJadwalMengajarPerKElas
                  let i = index
                "
              >
                <td><input type="checkbox"></td>
                <td>{{ i + 1 }}</td>
                
                <td>{{ siswa.Siswa.nisn }}</td>
                <td>{{ siswa.Siswa.no_induk }}</td>
                <td>{{ siswa.Siswa.nama_lengkap }}</td>
                <td>{{ siswa.Siswa.angkatan }}</td>
                <td>{{ siswa.Siswa.status }}</td>
                <td></td>
              </tr>
              <tr *ngIf="listJadwalMengajarPerKElas.length === 0" class="empty">
                <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
              </tr>
            </tbody>
          </table> -->
        </div>
  
  
        <ng-template #emptyPagination>
          <div class="pagination-wrapper">
            <div>&nbsp;</div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>