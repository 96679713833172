<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button routerLink="/absen-kegiatan/input" class="nav-link" [class.active]="isInputAbsensiKegiatan" id="input-guru-tab" data-bs-toggle="tab" data-bs-target="#inputGuru" type="button" role="tab" aria-controls="list" aria-selected="false">Input</button>
    </li>
    <li class="nav-item" role="presentation">
      <button routerLink="/absen-kegiatan/list-absensi-kamar" class="nav-link" [class.active]="isListAbsensi" id="catatan-siswa-tab" data-bs-toggle="tab" data-bs-target="#catatanSiswa" type="button" role="tab" aria-controls="nilai" aria-selected="false">Input Via RFID</button>
    </li>
    <li class="nav-item" role="presentation">
      <button routerLink="/absen-kegiatan/laporan-persiswa" class="nav-link" [class.active]="isLaporanPerSIswa" id="list-input-tab" data-bs-toggle="tab" data-bs-target="#listInput" type="button" role="tab" aria-controls="deleted" aria-selected="false">Jenis Absen</button>
    </li>
    <li class="nav-item" role="presentation" *ngxPermissionsOnly="['SUPER_ADMIN','VIEW_MONITORING_ABSENSI']">
      <button routerLink="/absen-kegiatan/laporan-perkamar" class="nav-link" [class.active]="isLaporanPerKamar" id="laporan-tab" data-bs-toggle="tab" data-bs-target="#laporan" type="button" role="tab" aria-controls="deleted" aria-selected="false">Kelompok Guru/Siswa</button>
    </li>
    <li class="nav-item" role="presentation" *ngxPermissionsOnly="['SUPER_ADMIN','VIEW_MONITORING_ABSENSI']">
      <button routerLink="/absen-kegiatan/monitoring-absensi-kamar" class="nav-link" [class.active]="isMonitoringAbsesiKamar" id="monitoring-tab" data-bs-toggle="tab" data-bs-target="#isMonitoringAbsesiKamar" type="button" role="tab" aria-controls="deleted" aria-selected="false">List Absen Siswa</button>
    </li>
    <li class="nav-item" role="presentation" *ngxPermissionsOnly="['SUPER_ADMIN','VIEW_MONITORING_ABSENSI']">
      <button routerLink="/absen-kegiatan/monitoring-penginput-absensi-kamar" class="nav-link" [class.active]="isMonitoringPenginput" id="monitoring-tab" data-bs-toggle="tab" data-bs-target="#isMonitoringPenginput" type="button" role="tab" aria-controls="deleted" aria-selected="false">List Absen Guru</button>
    </li>
    <li class="nav-item" role="presentation" *ngxPermissionsOnly="['SUPER_ADMIN','VIEW_MONITORING_ABSENSI']">
        <button routerLink="/absen-kegiatan/monitoring-penginput-absensi-kamar" class="nav-link" [class.active]="isMonitoringPenginput" id="monitoring-tab" data-bs-toggle="tab" data-bs-target="#isMonitoringPenginput" type="button" role="tab" aria-controls="deleted" aria-selected="false">Laporan</button>
    </li>
</ul>


<router-outlet></router-outlet>
  