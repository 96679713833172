<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="content-item mb-5">
    <div class="back-button" (click)="onBack()">
      <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
    </div>
    <div class="item-header">
      Edit Mata Pelajaran
    </div>
    
    <div class="card">
      <div class="card-header">
        DATA Mata Pelajaran
      </div>
      <div class="card-body">
          
              <div class="row">
            
                  <div class="col-12 col-lg-6">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="saudara" class="form-label">Mata Pelajaran</label>
                          <input
                            type="text"
                            formControlName="nama_studi"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['nama_studi'].errors }"
                            placeholder="ex: Matematika"
                          />
                        </div>
                      </div>
                      
                      
                  </div>
              </div>
          
      </div>
    </div>
  
    <div class="row align-items-center">
        <div class="col-6 col-lg-4 offset-lg-6">
            
        </div>
        <div class="col-6 col-lg-2 d-flex justify-content-end">
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </div>
  </div>
</form>
