import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { JadwalPelajaranService } from 'src/app/services/jadwal-pelajaran.service';
import { CommonService } from 'src/app/helper/common.service';
import { AbsensiService } from 'src/app/services/absensi.service';


@Component({
  selector: 'app-detail-absensi-harian-siswa',
  templateUrl: './detail-absensi-harian-siswa.component.html',
  styleUrls: ['./detail-absensi-harian-siswa.component.css']
})
export class DetailAbsensiHarianSiswaComponent implements OnInit {

  sharedDataObject: any = null;
  listDetailAbsen: any = [];
  currentPeriodeId: any = null;
  currentGuruId: any = null;
  currentNamaGuru: any = null;
  currentSemester: any = null;
  errorMessage = "";
  listDayPeriod: any = [];
  numOfDayCol = 0;
  tanggal = "";
  kelas = "";
  jamPelajaran = "";
  materi = "";
  id = "";
  absen = "";

  constructor(
    private uiService: UiService,
    private jadwalpelajaranService: JadwalPelajaranService,
    private commonService: CommonService,
    private sharedDataService: SharedDataService,
    private absensiService: AbsensiService,
  ) { }

  ngOnInit(): void {
    this.sharedDataService.currentDataObject.subscribe(data => {
      (this.sharedDataObject = data)
      console.log("SHARED OBJECT", data);
      
      this.tanggal = data.tanggal;
      this.kelas = data.nama_kelas;
      this.jamPelajaran = data.jamke;
      this.materi = data.materi;
      this.currentNamaGuru = data.real_name;
      this.id = data.id;
      this.absen = data.absen;

      this.getDayPeriod();
      this.getAllDetailAbsensi();
    })
  }

  getAllDetailAbsensi() {
    const payload = {
      absensi_guru_input_id: this.id,
      absen: this.absen
    }
    this.absensiService.getAbsensiHarianSiswaByAbsen(payload).subscribe({
      next: resdata => {
        console.log('list absen', resdata);

        // this.listDetailAbsen = resdata;

        this.listDetailAbsen = resdata;


        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getDayPeriod() {

    this.uiService.showLoader();

    const payload = {
      periode_id: this.currentPeriodeId,
      semester: this.currentSemester
    }

    console.log("PAYLOAD", payload);
    
    
    this.jadwalpelajaranService.getDayPeriod(payload).subscribe({
      next: resdata => {
        console.log("DAY PERIOD : ", resdata);
                
        this.listDayPeriod = resdata;
        this.numOfDayCol = resdata.length;

        this.uiService.hideLoader();      

        // this.getAllJadwalPelajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  closeButton() {
    this.uiService.hideFullScrrenContent();
  }

}
