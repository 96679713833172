import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { GelombangPendafataranService } from 'src/app/services/gelombang-pendafataran.service';

declare var Datepicker: any;

@Component({
  selector: 'app-gelombang-pendaftaran-add',
  templateUrl: './gelombang-pendaftaran-add.component.html',
  styleUrls: ['./gelombang-pendaftaran-add.component.css']
})
export class GelombangPendaftaranAddComponent implements OnInit {

  errorMessage = '';
  currentPeriode: any;

  form: FormGroup = new FormGroup({
    id_periode: new FormControl(''),
    title: new FormControl(''),
    start_date: new FormControl(''),
    end_date: new FormControl(''),
    ujian_tulis_date: new FormControl(''),
    ujian_lisan_date: new FormControl(''),
    pengumuman_kelulusan_date: new FormControl(''),
    active: new FormControl(false),
  });
  submitted = false;

  constructor(private formBuilder: FormBuilder, 
    private uiService: UiService, 
    private tahunajaranService: TahunajaranService,
    private gelombangPendaftaranService: GelombangPendafataranService,
    private router: Router) 
  { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getCurrentPeriode();
    this.initForm();
    this.configureDatePicker();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru");
    }
  }

  onBack() {
    this.router.navigate(['/settings/gelombangpendaftaran/list']);
  }

  getCurrentPeriode() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePpsb().subscribe({
      next: resdata => {
        console.log('periode', resdata);
        
        this.currentPeriode = resdata;
        
        this.form.controls['id_periode'].setValue(resdata.id);
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  configureDatePicker() {
    const elem_start_date = document.getElementById('start_date');
    const datepicker_start_date = new Datepicker(elem_start_date, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    });
    
    const elem_end_date = document.getElementById('end_date');
    const datepicker_end_date = new Datepicker(elem_end_date, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    });

    const elem_ujian_tulis_date = document.getElementById('ujian_tulis_date');
    const datepicker_ujian_tulis_date = new Datepicker(elem_ujian_tulis_date, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    });

    const elem_ujian_lisan_date = document.getElementById('ujian_lisan_date');
    const datepicker_ujian_lisan_date = new Datepicker(elem_ujian_lisan_date, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    });

    const elem_pengumuman_kelulusan_date = document.getElementById('pengumuman_kelulusan_date');
    const datepicker_pengumuman_kelulusan_date = new Datepicker(elem_pengumuman_kelulusan_date, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    });
  }

  changeDateField(e: any, field: any) {
    console.log(e.target.value);
    const choosenDate = e.target.value;
    this.form.controls[field].setValue(choosenDate);
  }

  changeStartDateField(e: any) {
    console.log(e.target.value);
    const birthdate = e.target.value;
    this.form.controls['start_date'].setValue(birthdate);
  }

  changeEndDateField(e: any) {
    console.log(e.target.value);
    const birthdate = e.target.value;
    this.form.controls['end_date'].setValue(birthdate);
  }
  
  initForm() {
    this.form = this.formBuilder.group(
      {
        id_periode: ['', Validators.required],
        title: ['', Validators.required],
        start_date: ['', Validators.required],
        end_date: ['', Validators.required],
        ujian_tulis_date: ['', Validators.required],
        ujian_lisan_date: ['', Validators.required],
        pengumuman_kelulusan_date: ['', Validators.required],
        active: [false],
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    this.gelombangPendaftaranService.add(this.form.value).subscribe({
      next: resdata => {
        console.log(resdata);
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
      
        this.router.navigate(['/settings/gelombangpendaftaran/list']);

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });

    console.log(JSON.stringify(this.form.value, null, 2));
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

}
