<div class="contentfull">
  <!-- <a href="javascript:void(0)" class="closebtn" (click)="closeButton()">&times;</a> -->
  
  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="statistik"
      role="tabpanel"
      aria-labelledby="statistik-tab"
    >
      <div class="tabs-title">Penempatan Kelas</div>
  
      
      <div class="list-options">
        <div class="form-select-warapper">
          <form [formGroup]="formAction">
            <select formControlName="id_tahun_ajaran" (change)="changeTahunAjaran($event)" class="form-select" name="id_tahun_ajaran" id="id_tahun_ajaran">
              <option [value]="" selected disabled>Pilih TA</option>
              <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
            </select>
          </form>
        </div>
  
        <div class="form-select-warapper">
          <form [formGroup]="formAction">
            <select formControlName="id_kelas" (change)="changeKelas($event)" class="form-select" name="id_kelas" id="id_kelas">
              <option [value]="0">Pilih Kelas</option>
              <option [value]="data.id" *ngFor="let data of listKelas">{{data.nama_kelas}}</option>
            </select>
          </form>
        </div>
        
        <button class="btn btn-blue-grey" (click)="updatePenempatanKelas()">
          Update
        </button>
        
        <button type="button" class="btn btn-warning" (click)="closeButton()">
          Close
        </button>
      </div>
      
      <div class="table-wrapper">
        <div class="table-options">
  
        </div>
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th></th>
                <th>No</th>
                <th>Foto</th>
                <th>NISN</th>
                <th>No. Induk</th>
                <th>Nama</th>
                <th>Tahun Masuk</th>
                <th>Status</th>        
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let siswa of listPenempatanKelas
                  let i = index
                "
              >
                <td><input type="checkbox"></td>
                <td>{{ i + 1 }}</td>
                <td><img width="40px" [src]="siswa.Siswa.photo" class="img-thumbnail" (error)="handleMissingImage($event)" /></td>
                <td>{{ siswa.Siswa.nisn }}</td>
                <td>{{ siswa.Siswa.no_induk }}</td>
                <td>{{ siswa.Siswa.nama_lengkap }}</td>
                <td>{{ siswa.Siswa.angkatan }}</td>
                <td>{{ siswa.Siswa.status }}</td>
                <td></td>
              </tr>
              <tr *ngIf="listPenempatanKelas.length === 0" class="empty">
                <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
              </tr>
            </tbody>
          </table>
        </div>
  
  
        <ng-template #emptyPagination>
          <div class="pagination-wrapper">
            <div>&nbsp;</div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>