import { Component, OnInit } from '@angular/core';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { KelasService } from 'src/app/services/kelas.service';
import { WalikelasService } from 'src/app/services/walikelas.service';
import { JadwalPelajaranService } from 'src/app/services/jadwal-pelajaran.service';
import { CommonService } from 'src/app/helper/common.service';
import { NilaiRaportService } from 'src/app/services/nilai-raport.service';

@Component({
  selector: 'app-input-nilai-raport-akhir',
  templateUrl: './input-nilai-raport-akhir.component.html',
  styleUrls: ['./input-nilai-raport-akhir.component.css']
})
export class InputNilaiRaportAkhirComponent implements OnInit {

  listNilaiRaportSemester: any = [];
  listKelas: any = [];
  listMataPelajaran: any = [];
  listSemester: any = [];
  currentPeriodeId: any = null;
  currentKelasId: any = null;
  currentStudiId: any = null;
  currentcurrentJadwalPelajaranId: any = null;
  currentGuruId: any = null;
  currentGuruName = "";
  currentAbsensiGuruInputId: any = null;
  currentAngkatan = "";
  currentSemester = "";
  pageid = "absensi";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  errorMessage = "";
  currentDate = "";
  listDayPeriod: any = [];
  numOfDayCol = 0;
  submitted = false;
  today = "";
  currentJadwalPelajaranId: any = null;
  isAbsenCompleted: boolean = false;
  numOfSiswaActive:number = 0;
  saveGuruInputDate = "";
  isOpenInputNilai: boolean = false;
  

  formInputInitMatapelajaran: FormGroup = new FormGroup({
    tahun_ajaran: new FormControl(''),
    periode_id: new FormControl(''),
    id_semester: new FormControl(''),
    id: new FormControl(''),
    kelas_id: new FormControl(''),
    guru_id: new FormControl(''),
    guru_name: new FormControl(''),
    walikelas_name: new FormControl(''),
    proguser_id: new FormControl(''),
    studi_id: new FormControl(''),  
  });

  formInputRaportHarian: FormGroup = new FormGroup({
    dataFormNilaiHarian: new FormArray([]),
    id_tahun_ajaran: new FormControl(''),
    semester: new FormControl(''),
    tipe: new FormControl(''),
    id_studi: new FormControl(''),
    guru_penginput_id: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private kelasService: KelasService,
    private tahunajaranService: TahunajaranService,
    private walikelasService: WalikelasService,
    private jadwalpelajaranService: JadwalPelajaranService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private nilairaportService: NilaiRaportService,
  ) { 
    this.changeBodyClass();

    this.initForm();

    this.currentDate = this.commonService.getCurentDate();
    
    this.formInputInitMatapelajaran.controls['kelas_id'].disable();
    this.formInputInitMatapelajaran.controls['tahun_ajaran'].disable();
    this.formInputInitMatapelajaran.controls['walikelas_name'].disable();
    this.formInputInitMatapelajaran.controls['guru_name'].disable();
  }

  ngOnInit(): void {    
    this.today = this.commonService.getCurrentDayName();
    console.log("HARI INI", this.today);
    
    this.listSemester = this.commonService.getListSemester();
    this.getCurrentTahunajaran();
    this.getKelas();
    
    const currentUser = localStorage.getItem('user')?.toString();
    const jsonUser = currentUser ? JSON.parse(currentUser) : "";
    console.log("CURRENT USER", jsonUser.proguser.userProfile.real_name);
    this.currentGuruId = jsonUser.proguser.id;
    this.currentGuruName = jsonUser.proguser.userProfile.real_name;

    this.formInputInitMatapelajaran.controls['id_semester'].disable();
    this.formInputInitMatapelajaran.controls['guru_id'].setValue(this.currentGuruId);
    this.formInputInitMatapelajaran.controls['guru_name'].setValue(this.currentGuruName);
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {

    this.formInputRaportHarian = this.formBuilder.group(
      {
        dataFormNilaiHarian: new FormArray([]),
        id_tahun_ajaran: ['', Validators.required],
        semester: ['', Validators.required],
        tipe: ['AKHIR', Validators.required],
        id_studi: ['', Validators.required],
        guru_penginput_id: [''],
      },
    );
  }

  get dataFormNilaiHarian(): FormArray {
    return this.formInputRaportHarian.get('dataFormNilaiHarian') as FormArray;
  }
  
  getListMataPelajaran() {
    this.uiService.showLoader();

    const payload = {
      periode_id: this.currentPeriodeId,
      semester: this.currentSemester,
      guru_id: this.currentGuruId
    }

    console.log("PAYLOAD getJadwalPelajaran", payload);
    
    
    this.jadwalpelajaranService.getListMataPelajaranByPeriodeSemesterAndGuru(payload).subscribe({
      next: resdata => {
        console.log("JADWAL PELAJARAN : ", resdata);
        
        if (resdata.length > 0) {
          this.listMataPelajaran = resdata;
        }
        
        this.uiService.hideLoader();      

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata.Semester);        

        this.currentPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        this.formInputInitMatapelajaran.controls['tahun_ajaran'].setValue(this.currentAngkatan);
        this.formInputRaportHarian.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);

        for (let i=0; i<resdata.Semester.length; i++) {
          // check active semester for input
          if (resdata.Semester[i].active === true) {
            const periodeSemester = this.commonService.getCurrentDateBetweenDateRange(resdata.Semester[i].start_input_nilai_date, resdata.Semester[i].end_input_nilai_date);
  
            if (periodeSemester) {
              this.currentSemester = resdata.Semester[i].semester;
              this.formInputInitMatapelajaran.controls['id_semester'].setValue(this.currentSemester);
              this.formInputInitMatapelajaran.controls['kelas_id'].enable();
              this.formInputRaportHarian.controls['semester'].setValue(this.currentSemester);
              
              this.isOpenInputNilai = true;         
              
              break;    
            }
            
            break;
          }
        }

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onSubmitformInputRaportSemester() {
    // check input guru pengganti
    this.formInputRaportHarian.controls['guru_penginput_id'].setValue(this.currentGuruId);

    console.log(JSON.stringify(this.formInputRaportHarian.value, null, 2));
    console.log(this.formInputRaportHarian.value.dataFormNilaiHarian.length);

    if (this.formInputRaportHarian.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    this.nilairaportService.add(this.formInputRaportHarian.value).subscribe({
      next: resdata => {
        console.log(resdata);

        this.uiService.hideLoader();
        this.uiService.showSuccess('');

        // reset from data
        this.formInputInitMatapelajaran.controls['kelas_id'].setValue('');
        this.formInputInitMatapelajaran.controls['studi_id'].setValue('');
        this.formInputInitMatapelajaran.controls['walikelas_name'].setValue('');

        this.listMataPelajaran = [];
        this.listNilaiRaportSemester = [];
        this.currentAbsensiGuruInputId = null;
      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });    
  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeKelas(e: any) {

    this.listNilaiRaportSemester = [];
    this.currentJadwalPelajaranId = null;
    this.formInputInitMatapelajaran.controls['studi_id'].setValue("");
    
    if (e.target.value !== "") {
      const kelas_id = e.target.value;
      this.currentKelasId = kelas_id;
      this.getWalikelasByKelas(kelas_id);

      // get mata pelajran 
      this.getListMataPelajaran(); // TODO: get by guru
    }
  }

  changeStudi(e: any) {
    this.currentStudiId = e.target.value;
    console.log(this.currentStudiId, this.currentPeriodeId, this.currentKelasId, this.currentSemester);

    this.formInputRaportHarian.controls['id_studi'].setValue(this.currentStudiId);
    this.getListInputNilaiRaportSemester();
  }

  getListInputNilaiRaportSemester() {
    const payloadLNH = {
      periode_id: this.currentPeriodeId,
      semester: this.currentSemester,
      kelas_id: this.currentKelasId,
      id_studi: this.currentStudiId
    }
    this.nilairaportService.getInputRaportSemester(payloadLNH).subscribe({
      next: resdata => {
        console.log("LNH", resdata);
  
        this.listNilaiRaportSemester = resdata;

        // reset form array
        this.dataFormNilaiHarian.clear();

        for (let i = 0; i < this.listNilaiRaportSemester.length; i++) {        
          this.dataFormNilaiHarian.push(this.formBuilder.group({            
            // 'nilai_quran_id': this.listNilaiRaportSemester[i] && this.listNilaiRaportSemester[i].nilai_quran_id ? this.listNilaiRaportSemester[i].nilai_quran_id : this.nilaiQuran.id,
            'siswa_id': this.listNilaiRaportSemester[i] && this.listNilaiRaportSemester[i].siswa_id ? this.listNilaiRaportSemester[i].siswa_id : '',
            'nilai': [this.listNilaiRaportSemester[i] && this.listNilaiRaportSemester[i].markvalue ? this.listNilaiRaportSemester[i].markvalue : '0', Validators.required],      
            'siswa_status': this.listNilaiRaportSemester[i] && this.listNilaiRaportSemester[i].status ? this.listNilaiRaportSemester[i].status : '',
          }));
        }
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeSemester(e: any) {
    if (e.target.value !== "") {
      this.formInputInitMatapelajaran.controls['kelas_id'].enable();
    } else {
      this.listDayPeriod = [];
      this.formInputInitMatapelajaran.controls['kelas_id'].setValue("");
      this.formInputInitMatapelajaran.controls['kelas_id'].disable();
    }
  }

  getWalikelasByKelas(kelasid: any) {
    this.uiService.showLoader();

    this.walikelasService.getByPeriodeAndKelas(this.currentPeriodeId, kelasid).subscribe({
      next: resdata => {
        console.log("WALIKELAS", resdata);
        this.formInputInitMatapelajaran.controls['walikelas_name'].setValue(resdata.User.userProfile.real_name);
        
        // this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formInputInitMatapelajaran.controls;
  }

}
