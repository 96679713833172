import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pendidikan-main',
  templateUrl: './pendidikan-main.component.html',
  styleUrls: ['./pendidikan-main.component.css']
})
export class PendidikanMainComponent implements OnInit {

  isInputGuru = false
  isCatatanSiswa = false
  isListInput = false
  isLaporan = false
  isMonitoring = false

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.changeTab(this.router.url)
  }

  changeTab(url: String) {
    switch (url) {
      case '/pendidikan/input-guru':
        this.isInputGuru = true
        this.isCatatanSiswa = false
        this.isListInput = false
        this.isLaporan = false
        this.isMonitoring = false
        return;
      case '/pendidikan/catatan-siswa':
        this.isCatatanSiswa = true
        this.isInputGuru = false
        this.isListInput = false
        this.isLaporan = false
        this.isMonitoring = false
        return;
      case '/pendidikan/list-input':
        this.isListInput = true
        this.isInputGuru = false
        this.isCatatanSiswa = false
        this.isLaporan = false
        this.isMonitoring = false
        return;
      case '/pendidikan/laporan':
        this.isLaporan = true
        this.isInputGuru = false
        this.isCatatanSiswa = false
        this.isListInput = false
        this.isMonitoring = false
        return;
      case '/pendidikan/monitoring':
        this.isMonitoring = true
        this.isInputGuru = false
        this.isCatatanSiswa = false
        this.isListInput = false
        this.isLaporan = false
        return;
      default:
        this.isInputGuru = false
        this.isCatatanSiswa = false
        this.isListInput = false
        this.isLaporan = false
        this.isMonitoring = false
        return;
    }
  }

}
