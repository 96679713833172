<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="content-item mb-5">
    <div class="back-button" (click)="onBack()">
      <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
    </div>
    <div class="item-header">
      EDIT Kategori Berkas
    </div>
    
    <div class="card">
      <div class="card-header">
        DATA Kategori Berkas
      </div>
      <div class="card-body">
          
              <div class="row">
            
                  <div class="col-12 col-lg-6">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="saudara" class="form-label">Nama Berkas</label>
                          <input
                            type="text"
                            formControlName="nama_dokumen"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['nama_dokumen'].errors }"
                            placeholder="ex: Fotokopi KTP"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-3">
                          <label for="start_date" class="form-label">Jumlah</label>
                          <input
                            type="number"
                            id="jumlah"
                            formControlName="jumlah"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['jumlah'].errors }"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-3">
                          <label for="start_date" class="form-label">No Urut</label>
                          <input
                            type="number"
                            id="urut"
                            formControlName="urut"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['urut'].errors }"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-3">
                      
                          <input type="checkbox" formControlName="mandatory" /> Mandatory
                        </div>
                      </div>
                      
                  </div>
              </div>
          
      </div>
    </div>
  
    <div class="row align-items-center">
        <div class="col-6 col-lg-4 offset-lg-6">
            
        </div>
        <div class="col-6 col-lg-2 d-flex justify-content-end">
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </div>
  </div>
</form>
