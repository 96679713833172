import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { NetworkService } from '../helper/network.service';
import { UiService } from '../helper/ui.service';
import { GeneralService } from '../services/general.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  instansi = "";
  schoolType = "";

  constructor(
    private authService: AuthService, 
    private uiService: UiService, 
    private generalService: GeneralService,
    private netService: NetworkService, 
    private permissionService: NgxPermissionsService,
    private router: Router) { 
    this.changeBodyClass();
    this.getCurrentInstansi();
  }

  ngOnInit(): void {
    if (this.authService.isUserLoggedIn()) {
      this.router.navigate(['dashboard']);
    }
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("login");
    }
  }

  getCurrentInstansi() {
    this.uiService.showLoader();

    const currentDomain = this.netService.getCurrentDomain();

    const payload = {
      "kode": currentDomain.toLowerCase()
    }

    console.log("PAYLOAD", payload);
    

    this.generalService.getCurrentInstansi(payload).subscribe({
      next: resdata => {
        console.log("DOMAIN", resdata);
        this.instansi = resdata.nama_instansi;
        this.schoolType = resdata.tipe_instansi;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onSubmit(): void {
    const { username, password } = this.form;

    this.uiService.showLoader();    

    this.authService.login(username, password).subscribe({
      next: data => {
        console.log("LOGIN DATA", data);

        const dataUser = atob(data.user);
        localStorage.setItem('user', dataUser);
        localStorage.setItem('token', data.token);
        localStorage.setItem('instansi', this.instansi);
        localStorage.setItem('schoolType', this.schoolType);
        this.isLoginFailed = false;
        this.isLoggedIn = true;

        // set permission
        //const perm = ["ADMIN", "EDITOR"]; example of permissions
        let permissions = new Array();
        
        const jsonDataUser = JSON.parse(dataUser);
        const userPerms = jsonDataUser.proguser.Role.RolePermission;

        permissions.push(jsonDataUser.proguser.Role.slug);

        for (const k in userPerms) {
          console.log("k is : ", k, userPerms[k]);
          permissions.push(userPerms[k].Permission.slug)          
        }

        console.log("USER PERMS : ", permissions);

        this.permissionService.loadPermissions(permissions);
        
        // this.roles = this.tokenStorage.getUser().roles;
        //this.reloadPage();
        
        this.uiService.hideLoader();

        this.router.navigate(['dashboard']);
        //window.location.reload();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        this.isLoginFailed = true;

        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });

  }

}
