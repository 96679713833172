<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="guru-input"
    role="tabpanel"
    aria-labelledby="guru-input-tab"
  >
    <div class="tabs-title">Libur Mengajar</div>

    <div class="list-options">
      <button class="btn btn-blue-grey" (click)="openModalformLiburMengajar(formLiburMengajarModal)">
        + Tambah
      </button>
    </div>

    <div class="table-wrapper">
      <div class="table-options">
        <div class="show-table">
          Show
          <select
            class="form-control"
            name="show"
            id="show"
            (change)="handlePageSizeChange($event)"
          >
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }}
            </option>
          </select>
          entries
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th></th>
              <th>Tanggal</th>
              <th>Keterangan</th>
              <th></th>      
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let siswa of listLiburMengajar
                  | paginate
                    : {
                        id: pageid,
                        itemsPerPage: pageSize,
                        currentPage: page,
                        totalItems: count
                      };
                let i = index
              "
            >
              <td>{{ i + 1 + (page - 1) * pageSize }}</td>
              <td>{{ siswa.tanggal }}</td>
              <td>{{ siswa.keterangan }}</td>
              <td class="settings">
                <div class="dropend">
                    <a href="#" role="button" id="dropdownMenuLink"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="./assets/images/icon-setting.svg" alt="">
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li><a (click)="editFormLiburMengajar(formLiburMengajarModal, siswa)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>                          
                        <li><a (click)="confirmDelete(siswa)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                    </ul>
                </div>
              </td>
            </tr>
            <tr *ngIf="listLiburMengajar.length === 0" class="empty">
              <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
        <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
          <div></div>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center pagination-number">
              <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                <a
                  class="page-link"
                  (click)="p.setCurrent(page.value)"
                  *ngIf="p.getCurrent() !== page.value"
                >
                  {{ page.label }}
                </a>
                <a class="page-link" *ngIf="p.getCurrent() === page.value">
                  {{ page.label }}
                </a>
              </li>
            </ul>
          </nav>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="p.isFirstPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isFirstPage()"
                  (click)="p.previous()"
                  aria-label="Previous"
                >
                  <span>Previous</span>
                </a>
              </li>
              <li class="page-item" [class.disabled]="p.isLastPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isLastPage()"
                  (click)="p.next()"
                  aria-label="Next"
                >
                  <span>Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </pagination-template>

      <ng-template #emptyPagination>
        <div class="pagination-wrapper">
          <div>&nbsp;</div>
        </div>
      </ng-template>
    </div>

  </div>
</div>

<ng-template #formLiburMengajarModal let-modal>
  <div class="modal-body">
      <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
      <div class="modal-form">
          <h1 class="text-center" *ngIf="isNew">Tambah Form Libur Mengajar</h1>
          <h1 class="text-center" *ngIf="isUpdate">Edit Form Libur Mengajar</h1>
          <form [formGroup]="formLiburMengajar" (ngSubmit)="onSubmitLiburMengajar(modal)">

            <div class="mb-3 mt-4 form-group row">
              <label for="start_date" class="col-sm-3 control-label">Tanggal</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  autocomplete="off"
                  id="tanggal"
                  (blur)="changeDateField($event, 'tanggal')"                                  
                  formControlName="tanggal"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f['tanggal'].errors }"
                  placeholder=""
                />
              </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
              <label for="kategori" class="col-sm-3 control-label">Kelas</label>
              <div class="col-sm-9">
                <select
                    formControlName="kelas_id"
                    [ngClass]="{ 'is-invalid': submitted && f['kelas_id'].errors }"
                    class="form-select"
                    multiple
                    (change)="changeKelas($event)">
                    <option value="" selected>Pilih</option>
                    <option [value]="data.id" *ngFor="let data of listKelas">{{data.nama_kelas}}</option>
                </select>
              </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
              <label for="kategori" class="col-sm-3 control-label">Jam Ke</label>
              <div class="col-sm-9">
                <select 
                  formControlName="jamke" 
                  multiple
                  [ngClass]="{ 'is-invalid': submitted && f['jamke'].errors }" class="form-select">
                  <option [value]="" selected disabled>Pilih</option>
                  <option [value]="data" *ngFor="let data of listJamke">{{data}}</option>
                </select>
              </div>
            </div>

            <div class="mb-3 mt-4 form-group row">
              <label for="Keterangan" class="col-sm-3 control-label">Keterangan</label>
              <div class="col-sm-9">
                  <textarea 
                      class="form-control" 
                      [ngClass]="{ 'is-invalid': submitted && f['keterangan'].errors }"
                      formControlName="keterangan"
                      rows="3"
                  >
                  </textarea>
              </div>
            </div>

            <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isNew">CREATE</button>
            <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isUpdate">Update</button>
          </form>
      </div>
  </div>
</ng-template>