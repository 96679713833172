import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UiService } from 'src/app/helper/ui.service';
import { SiswaService } from 'src/app/services/siswa.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { SiswakelasService } from 'src/app/services/siswakelas.service';
import { CatatanSiswaService } from 'src/app/services/catatan-siswa.service';
import { CommonService } from 'src/app/helper/common.service';

declare var Datepicker: any;

@Component({
  selector: 'app-pendidikan-catatan-siswa',
  templateUrl: './pendidikan-catatan-siswa.component.html',
  styleUrls: ['./pendidikan-catatan-siswa.component.css'],
})
export class PendidikanCatatanSiswaComponent implements OnInit {

  listCatatanSiswa: any = [];
  listSiswa: any = [];
  currentPeriode: any;
  currentPeriodeId: any = null;
  currentGuruId: any = null;
  currentSemester: any = null;
  listGuru: any = [];
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  closeResult: string = '';
  isNew = true;
  isShowLoader: Boolean = false;
  searchSiswaTerm = "";
  currentAngkatan = "";


  formChangeTahunAjaran: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
  });

  formCatatanSiswa: FormGroup = new FormGroup({
    periode_id: new FormControl(''),
    semester: new FormControl(''),
    id: new FormControl(''),
    kelas_id: new FormControl(''),
    guru_name: new FormControl(''),
    id_siswa: new FormControl(''),
    siswa_name: new FormControl(''),
    no_induk: new FormControl(''),
    guru_id: new FormControl(''),
    kelas: new FormControl(''),
    kamar: new FormControl(''),
    tanggal: new FormControl(''),
    catatan_positif: new FormControl(''),
    catatan_negatif: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private formBuilder: FormBuilder,
    private siswaService: SiswaService,
    private siwakelasService: SiswakelasService,
    private commonService: CommonService,
    private catatansiswaService: CatatanSiswaService,
    private tahunajaranService: TahunajaranService,
    private modalService: NgbModal,
  ) {
    this.changeBodyClass();    
  }

  ngOnInit(): void {
    this.initForm();
    this.getCurrentTahunajaran();
    
    this.getAllCatatanSiswa();

    const currentUser = localStorage.getItem('user')?.toString();
    const jsonUser = currentUser ? JSON.parse(currentUser) : "";
    console.log("CURRENT USER", jsonUser.proguser.userProfile.real_name);

    this.currentGuruId = jsonUser.proguser.id;    
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add('siswa-baru-list');
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    this.getAllCatatanSiswa();
  }

  initForm() {
    this.formCatatanSiswa = this.formBuilder.group(
      {
        periode_id: ['', Validators.required],
        semester: ['', Validators.required],
        id: [''],
        kelas_id: ['', Validators.required],
        guru_name: [''],
        id_siswa: ['', Validators.required],
        siswa_name: ['', Validators.required],
        no_induk: [''],
        guru_id: ['', Validators.required],
        kelas: [''],
        kamar: [''],
        tanggal: ['', Validators.required],
        catatan_positif: [''],
        catatan_negatif: [''],
      },
    );

    this.formCatatanSiswa.controls['no_induk'].disable();
    this.formCatatanSiswa.controls['kelas'].disable();
    this.formCatatanSiswa.controls['kamar'].disable();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formCatatanSiswa.controls;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  openModalformCatatanSiswa(content:any) {
    this.isNew = true;
    this.submitted = false;
    this.formCatatanSiswa.reset();
    // this.formCatatanSiswa.controls['id'].setValue(student.id);
    // this.formCatatanSiswa.controls['nama_lengkap'].setValue(student.nama_lengkap);
    // this.formCatatanSiswa.controls['current_status'].setValue(student.current_status);
    this.formCatatanSiswa.controls['periode_id'].setValue(this.currentPeriodeId);
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    const isOpenModal = this.modalService.hasOpenModals();
    console.log("MODAL", isOpenModal);

    if (isOpenModal) {
      this.configureDatePicker();
    }
  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    this.currentPeriodeId = e.target.value;

    // this.getAllWalikelasByPeriode();
  }

  chooseSiswa(siswa: any) {
    this.listSiswa = [];
    console.log(siswa);
    this.formCatatanSiswa.controls['id_siswa'].setValue(siswa.id);
    this.formCatatanSiswa.controls['no_induk'].setValue(siswa.no_induk);
    this.formCatatanSiswa.controls['siswa_name'].setValue(siswa.nama_lengkap);
    
    // 1.get kelas
    this.getKelasBySiswa(siswa.id);

    // 2. get kamar
  }

  getKelasBySiswa(siswaId: string) {

    const payload = {
      periode_id: this.currentPeriodeId,
      siswa_id: siswaId
    }

    this.siwakelasService.getSiswakelasBySiswa(payload).subscribe({
      next: resdata => {
        console.log(resdata);

        if (resdata !== null) {
          this.formCatatanSiswa.controls['kelas'].setValue(resdata.Kelas.nama_kelas);
          this.formCatatanSiswa.controls['kelas_id'].setValue(resdata.Kelas.id);
        } else {
          this.formCatatanSiswa.controls['kelas'].setValue("");
          this.formCatatanSiswa.controls['kelas_id'].setValue("");
        }
        this.isShowLoader = false;
        

      },
      error: err => {
        this.isShowLoader = false;
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
      }
    });
  }

  getAllCatatanSiswa() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.catatansiswaService.getAll(params).subscribe({
      next: resdata => {
        console.log('catatan siswa', resdata);
        
        this.listCatatanSiswa = resdata.data;
        
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  searchSiswa(): void {
    this.searchSiswaTerm = this.formCatatanSiswa.value.siswa_name;

    this.listSiswa = [];
    if (this.searchSiswaTerm != "") {
      this.isShowLoader = true;
      const params = this.getRequestParams(this.searchSiswaTerm, this.page, this.pageSize);
      params['angkatan'] = this.currentAngkatan;

      console.log("PARAMS", params);
      
  
      this.siswaService.getAllSiswaBaru(params).subscribe({
        next: resdata => {
          console.log(resdata);
          const { data, totalrows } = resdata;
  
          this.isShowPagination == totalrows > this.pageSize ? true : false;
          this.listSiswa = data;
          this.count = totalrows;
  
          console.log("SISWA", this.listSiswa);
  
          console.log('COUNT', this.count);
  
  
          this.isShowLoader = false;
          //this.uiService.hideLoader();
  
        },
        error: err => {
          this.isShowLoader = false;
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);
  
          //this.uiService.hideLoader();
  
          //this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  configureDatePicker() {
    console.log("DATEFIKER");
    
    const elem_input_tanggal = document.getElementById('inputTanggal');
    const datepicker_input_tanggal = new Datepicker(elem_input_tanggal, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    });
  }

  changeDateField(e: any, field: any) {
    console.log(e.target.value);
    const choosenDate = e.target.value;
    this.formCatatanSiswa.controls[field].setValue(choosenDate);
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata.Semester);        

        this.currentPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        this.formCatatanSiswa.controls['periode_id'].setValue(this.currentPeriodeId);

        for (let i=0; i<resdata.Semester.length; i++) {
          const periodeSemester = this.commonService.getCurrentDateBetweenDateRange(resdata.Semester[i].start_date, resdata.Semester[i].end_date);

          if (periodeSemester) {
            this.currentSemester = resdata.Semester[i].semester;
            
            break;    
          }
        }

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onSubmitformCatatanSiswa(modal: any): void {
    this.formCatatanSiswa.controls['guru_id'].setValue(this.currentGuruId);
    this.formCatatanSiswa.controls['semester'].setValue(this.currentSemester);

    console.log(JSON.stringify(this.formCatatanSiswa.value, null, 2));
    

    this.submitted = true;
    
    if (this.formCatatanSiswa.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }
    
    modal.dismiss('Cross click');

    // this.uiService.showLoader();
    
    if (this.isNew) {
      this.catatansiswaService.add(this.formCatatanSiswa.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.resetForm();

          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          this.getAllCatatanSiswa();
  
        },
        error: err => {
          console.log(err);

          this.resetForm();
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });    
    } else {
      // this.walikelasService.update(this.idWalikelas, this.formCatatanSiswa.value).subscribe({
      //   next: resdata => {
      //     console.log(resdata);
  
      //     this.resetForm();
          
      //     this.uiService.hideLoader();
      //     this.uiService.showSuccess('');
        
      //     //this.router.navigate(['/ppsb/list']);
      //     this.getAllWalikelasByPeriode();
  
      //   },
      //   error: err => {
      //     console.log(err);
      //     // this.formCatatanSiswa.reset();
      //     this.submitted = false;
      //     // this.initForm();
      //     // this.formCatatanSiswa.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
      //     // this.listBiayaPaketPembayaran = [];
          
      //     this.errorMessage = err.error.message;
      //     console.log("MESSAGE ERRt", this.errorMessage);
          
      //     this.uiService.hideLoader();
          
      //     this.uiService.showError(this.errorMessage);
      //   }
      // });
    }
  }

  resetForm() {
    this.formCatatanSiswa.reset();
    this.submitted = false;
    this.initForm();
    this.formCatatanSiswa.controls['periode_id'].setValue(this.currentPeriodeId);
    this.formCatatanSiswa.controls['semester'].setValue(this.currentSemester);
  }
}
