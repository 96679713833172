<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button routerLink="/sdm/struktur/list" class="nav-link" [class.active]="isList" id="list-tab" data-bs-toggle="tab" data-bs-target="#list" type="button" role="tab" aria-controls="list" aria-selected="false">List</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/sdm/struktur/setup" class="nav-link" [class.active]="isSetup" id="nilai-tab" data-bs-toggle="tab" data-bs-target="#nilai" type="button" role="tab" aria-controls="nilai" aria-selected="false">Setup Jabatan</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane" [class.active]="isList" id="list" role="tabpanel" aria-labelledby="list-tab">
    <div class="tabs-title">List Struktur</div>
  </div>
  <div class="tab-pane" [class.active]="isSetup" id="nilai" role="tabpanel" aria-labelledby="nilai-tab">
    <div class="tabs-title">Setup Jabatan</div>
  </div>
</div>
<router-outlet></router-outlet>
