import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class NilaiHarianService {

  constructor(private netService: NetworkService) { }

  getLaporanNilaiQuran(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getLaporanNilaiQuran');
  }

  getLaporanNilaiInsya(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getLaporanNilaiInsya');
  }

  addNilaiQuran(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/saveNilaiQuran');
  }

  addNilaiInsya(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/saveNilaiInsya');
  }
  
  addNilaiDailiComposition(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/saveNilaiDailyComposition');
  }

  addNilaiIbadahAmaliah(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/saveNilaiIbadahAmaliah');
  }

  getInputHarianQuran(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getNilaiQuran');
  }

  getInputHarianInsya(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getNilaiInsya');
  }

  getInputHarianDailyComposition(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getNilaiDailyComposition');
  }

  getInputHarianIbadahAmaliah(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getNilaiIbadahAmaliah');
  }

  getListInputHarianQuran(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getListNilaiQuran');
  }

  getListLaporanNilaiQuranSiswa(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getListLaporanNilaiQuranSiswa');
  }  

  getListLaporanNilaiInysaYaumiSiswa(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getListLaporanNilaiInysaYaumiSiswa');
  }

  getListLaporanNilaiDailyCompositionSiswa(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getListLaporanNilaiDailyCompositionSiswa');
  }

  getListInputHarianInsya(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getListNilaiInsya');
  }

  getListInputHarianDailyComposition(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getListNilaiDailyComposition');
  }

  getListInputHarianIbadahAmaliah(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getListNilaiIbadahaAmaliah');
  }

  saveGuruInput(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/absensiguruinput');
  }
}
