<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="guru-input"
    role="tabpanel"
    aria-labelledby="guru-input-tab"
  >
    <div class="tabs-title">Laporan Nilai</div>

    
    <div class="col-12 col-lg-12">
      <div class="card">
          
          <div class="card-body">
              <form [formGroup]="formDownloadLaporan" (ngSubmit)="onSubmitformDownloadLaporan()">
                  <div class="row">
                      <div class="col-12 col-lg-12">
                        <div class="mb-3">
                          <div class="row align-items-center">
                              <div class="col-2 text-end">
                                  <label for="name" class="form-label">Jenis Laporan <span
                                          class="required">*</span></label>
                              </div>
                              <div class="col-10">
                                <select
                                    formControlName="jenis_laporan"
                                    [ngClass]="{ 'is-invalid': submitted && f['jenis_laporan'].errors }"
                                    class="form-select"
                                    (change)="changeJenisLaporan($event)">
                                    <option [value]="jenis" *ngFor="let jenis of listJenisLaporan">{{jenis}}</option>
                                </select>
                              </div>
                          </div>
                        </div>

                          <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="nik" class="form-label">Tahun Ajaran <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-10">                            
                                    <select formControlName="id_tahun_ajaran" [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }" class="form-select">
                                      <option [value]="" selected disabled>Pilih TA</option>
                                      <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                                    </select>
                                  </div>
                              </div>
                          </div>   
                          <div class="mb-3" [hidden]="currentJenisLaporan === 'Laporan Nilai Al-Quran'">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">Semester <span
                                            class="required">*</span></label>
                                </div>
                                <div class="col-10">
                                  <select formControlName="id_semester" (change)="changeSemester($event)" class="form-select" name="id_tahun_ajaran" id="id_tahun_ajaran">
                                    <option [value]="0">Pilih Semester</option>
                                    <option [value]="data.kode" *ngFor="let data of listSemester">{{data.name}}</option>
                                  </select>
                                </div>
                            </div>
                          </div>               
                          
                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">Kelas <span
                                            class="required">*</span></label>
                                </div>
                                <div class="col-10">
                                  <select formControlName="id_kelas" (change)="changeKelas($event)" class="form-select" name="id_tahun_ajaran" id="id_tahun_ajaran">
                                    <option value="" selected>Pilih Kelas</option>
                                    <option [value]="data.id" *ngFor="let data of listKelas">{{data.nama_kelas}}</option>
                                  </select>
                                </div>
                            </div>
                          </div>
                                              
                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">&nbsp;</label>
                                </div>
                                <div class="col-10">                                  
                                  <button class="btn btn-success" style="display:inline; margin-right: 4px;">
                                    <img src="./assets/images/icon-excel.png" alt="Excel">
                                    Excel
                                  </button>
                                </div>
                            </div>
                          </div>                          
                      </div>                  
                  </div>
              </form>
          </div>
      </div>
    </div>

   
  </div>
</div>