import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-izin-santri',
  templateUrl: './nav-izin-santri.component.html',
  styleUrls: ['./nav-izin-santri.component.css']
})
export class NavIzinSantriComponent implements OnInit {

  isDataIzinSantri = false;
  isGenerate = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    console.log(this.router.url);
    switch (this.router.url) {
      case '/pengasuhan/list-izin-santri':
        this.isDataIzinSantri = true;
        this.isGenerate = false;        
        break;

      case '/pengasuhan/generate-surat-jalan':
        this.isDataIzinSantri = false;
        this.isGenerate = true;        
        break;      

      default:
        this.isDataIzinSantri = false;
        this.isGenerate = false;        
        break;
    }
  }

}
