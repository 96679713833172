import { Injectable } from '@angular/core';
//import Swal from 'sweetalert2';

declare var $: any;
declare var Swal: any;

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor() { }

  showLoader() {
    var spanner = document.getElementById('loader-spanner');
    var overlay = document.getElementById('loader-overlay');

    overlay?.classList.add('show');
    spanner?.classList.add('show');    
  }

  hideLoader() {
    var spanner = document.getElementById('loader-spanner');
    var overlay = document.getElementById('loader-overlay');

    overlay?.classList.remove('show');
    spanner?.classList.remove('show');
  }

  showFullScrrenContent() {
    var myContent = document.getElementById('myContent');

    myContent?.classList.add('showcontent');
  }

  hideFullScrrenContent() {
    var myContent = document.getElementById('myContent');

    myContent?.classList.remove('showcontent');
  }

  showError(message: String) {
    Swal.fire({
      title: "Error!",
      text: " ".concat(message.toString()),
      type: "error",
      confirmButtonClass: "btn btn-danger",
      buttonsStyling: false
    });
  }

  showSuccess(message: String) {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 1500
    })
  }

  showConfirm(callfunc: any, id: String, txtButton: String) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: txtButton
    }).then((result: { isConfirmed: any; }) => {
      callfunc.afterConfirm(result.isConfirmed, id, callfunc);
    })
  }

  showConfirmCustom(callfunc: any, callfunc2: any, id: String, txtButton: String) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: txtButton
    }).then((result: { isConfirmed: any; }) => {
      callfunc2(result.isConfirmed, id, callfunc);
    })
  }

  showConfirmDelete(callfunc: any, id: String) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result: { isConfirmed: any; }) => {
      callfunc.delete(result.isConfirmed, id, callfunc);
    })
  }

  showConfirmDeleteSpec(callfunc: any, callfunc2: any, id: String, id2:string) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result: { isConfirmed: any; }) => {
      callfunc2(result.isConfirmed, id, id2, callfunc);
    })
  }

  // Add Children Class
  addHasSub() {
    $('.navigation').find('li').has('ul').addClass('has-sub');
  }

  displayWindowSize(){
    // Get width and height of the window excluding scrollbars
    var w = document.documentElement.clientWidth;
    var h = document.documentElement.clientHeight;
    
    console.log("Width: " + w + ", " + "Height: " + h);
  }

  triggerOnScreenResize() {
    // Attaching the event listener function to window's resize event
    window.addEventListener("resize", this.displayWindowSize);
  }

  toggleFullscreen() {
    let bodyContent = document.getElementById("page-content");
    
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
      if (!document.fullscreenElement) {
        document.body.requestFullscreen().then(e => {
          this.showHideFeatherIcon();
          bodyContent?.classList.add("full");
          console.log('full', this.isFullScreen());
        })
        .catch(err => {
          alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
        });
  
      } else {
        bodyContent?.classList.remove("full");
        document.exitFullscreen().then(e => {
          this.showHideFeatherIcon();
          console.log('no full', this.isFullScreen());
        });
      }
    }
  }

  isFullScreen(): boolean {
    return document.fullscreenElement ? true : false;
  }

  showHideFeatherIcon() {
    let maximizeIcon = document.getElementById("maximizeFeather");
    let minimizeIcon = document.getElementById("minimizeFeather");
    
    if (this.isFullScreen()) {
      maximizeIcon?.classList.add('d-none') 
      minimizeIcon?.classList.remove('d-none') 
    } else {
      maximizeIcon?.classList.remove('d-none') 
      minimizeIcon?.classList.add('d-none');
    }
      
  }
  
}
