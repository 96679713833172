<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/monitoring-siswa" class="nav-link" [class.active]="isMonitoringSiswa" id="catatan-siswa-tab" 
      data-bs-toggle="tab" data-bs-target="#catatanSiswa" type="button" role="tab" aria-controls="nilai" aria-selected="false">Monitoring Siswa</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/monitoring-guru" class="nav-link" [class.active]="isMonitoringGuru" id="list-input-tab" data-bs-toggle="tab" 
      data-bs-target="#listInput" type="button" role="tab" aria-controls="deleted" aria-selected="false">Monitoring Guru</button>
  </li>
</ul>

<router-outlet></router-outlet>