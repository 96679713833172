<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button routerLink="/ppsb/list" class="nav-link" [class.active]="isList" id="list-tab" data-bs-toggle="tab" data-bs-target="#list" type="button" role="tab" aria-controls="list" aria-selected="false">List Siswa</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/ppsb/nilai" class="nav-link" [class.active]="isNilai" id="nilai-tab" data-bs-toggle="tab" data-bs-target="#nilai" type="button" role="tab" aria-controls="nilai" aria-selected="false">Nilai</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/ppsb/deleted" class="nav-link" [class.active]="isDeleted" id="deleted-tab" data-bs-toggle="tab" data-bs-target="#deleted" type="button" role="tab" aria-controls="deleted" aria-selected="false">Siswa Terhapus</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane" [class.active]="isList" id="list" role="tabpanel" aria-labelledby="list-tab">
    <div class="tabs-title">Hasil Test Siswa Baru Tahun Ajaran 2023-2024</div>
  </div>
  <div class="tab-pane" [class.active]="isNilai" id="nilai" role="tabpanel" aria-labelledby="nilai-tab">
    <div class="tabs-title">List Nilai Ujian PSB</div>
  </div>
  <div class="tab-pane" [class.active]="isDeleted" id="deleted" role="tabpanel" aria-labelledby="deleted-tab">
    <div class="tabs-title">List Siswa Terhapus 2023-2024</div>
  </div>
</div>
<router-outlet></router-outlet>
