import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { JadwalPelajaranService } from 'src/app/services/jadwal-pelajaran.service';
import { CommonService } from 'src/app/helper/common.service';

@Component({
  selector: 'app-detail-jadwal-mengajar-per-guru',
  templateUrl: './detail-jadwal-mengajar-per-guru.component.html',
  styleUrls: ['./detail-jadwal-mengajar-per-guru.component.css']
})
export class DetailJadwalMengajarPerGuruComponent implements OnInit {

  sharedDataObject: any = null;
  listJadwalPerGuru: any = [];
  currentPeriodeId: any = null;
  currentGuruId: any = null;
  currentNamaGuru: any = null;
  currentSemester: any = null;
  errorMessage = "";
  listDayPeriod: any = [];
  numOfDayCol = 0;

  constructor(
    private uiService: UiService,
    private jadwalpelajaranService: JadwalPelajaranService,
    private commonService: CommonService,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit(): void {
    this.sharedDataService.currentDataObject.subscribe(data => {
      (this.sharedDataObject = data)
      console.log("SHARED OBJECT", data);
      this.currentPeriodeId = data.id_tahun_ajaran;
      this.currentSemester = data.semester;
      this.currentGuruId = data.guru_id;
      this.currentNamaGuru = data.real_name;

      this.getDayPeriod();
      this.getAllJadwalGuru();
    })
  }

  getAllJadwalGuru() {
    const payload = {
      periode_id: this.currentPeriodeId,
      semester: this.currentSemester,
      guru_id: this.currentGuruId
    }
    this.jadwalpelajaranService.getListJadwalGuruByGuruPeriodeSemester(payload).subscribe({
      next: resdata => {
        console.log('list jadwal guru', resdata);

        // this.listJadwalPerGuru = resdata;

        const listDays = this.commonService.getListDays();

        const rowCols: any[][] = [];
        
        for (let i = 0; i<listDays.length; i++) {
          let cols: any[] = [];
          
          cols.push(listDays[i].val);
          for (let c=1; c<=this.numOfDayCol; c++) {
            const col = {
              pelajaran: "x",
              kelas: "x"
            }
            cols.push(col); 
          }
          rowCols.push(cols);
        }
        
        console.log("ROW COLS", rowCols);
                
        for (let s=0; s<resdata.length; s++) {
          const jadwal = resdata[s];

          console.log("jadwal", jadwal);
          
          
          if (jadwal.jamke !== null) {
            
            const ctnColData = {
              pelajaran: jadwal.nama_studi,
              kelas: jadwal.nama_kelas
            }

            const indexJamKe = jadwal.jamke;
            // const indexPelajaran = listDays.indexOf(jadwal.day);
            const indexPelajaran = listDays.findIndex(object => {
              return object.key === jadwal.day;
            });

            console.log("jDay", indexJamKe, indexPelajaran,jadwal.day);
            

            rowCols[indexPelajaran][indexJamKe] = ctnColData;
            console.log("JAM KE", jadwal.jamke, "KELAS ", jadwal.nama_kelas);
            
          }
          
        }

        this.listJadwalPerGuru = rowCols;

        console.log("FINAL", this.listJadwalPerGuru);


        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getDayPeriod() {

    this.uiService.showLoader();

    const payload = {
      periode_id: this.currentPeriodeId,
      semester: this.currentSemester
    }

    console.log("PAYLOAD", payload);
    
    
    this.jadwalpelajaranService.getDayPeriod(payload).subscribe({
      next: resdata => {
        console.log("DAY PERIOD : ", resdata);
                
        this.listDayPeriod = resdata;
        this.numOfDayCol = resdata.length;

        this.uiService.hideLoader();      

        // this.getAllJadwalPelajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  closeButton() {
    this.uiService.hideFullScrrenContent();
  }

}
