<div class="tabs-title">Laporan Per Siswa</div>

<div class="col-12 col-lg-12">
  <div class="card">
      
      <div class="card-body">
          <form [formGroup]="formLaporanAbsensi">
              <div class="row">
                  <div class="col-12 col-lg-12">
                    
                    <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="name" class="form-label">Tahun Ajaran</label>
                            </div>
                            <div class="col-10">
                                <select
                                    (change)="changeTahunAjaran($event)" 
                                    formControlName="id_tahun_ajaran" [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }" 
                                    class="form-select">
                                    <option [value]="" selected disabled>Pilih TA</option>
                                    <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                                </select>
                            </div>                            
                        </div>
                    </div>                    
                    
                    <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="name" class="form-label">Bulan</label>
                            </div>
                            <div class="col-10">
                                <select (change)="changeBulan($event)" formControlName="bulan" [ngClass]="{ 'is-invalid': submitted && f['bulan'].errors }" class="form-select">
                                    <option value="" selected>Pilih Bulan</option>
                                    <option [value]="month.blnNumber" *ngFor="let month of listMonth">{{ month.bulan }}</option>
                                </select>
                            </div>                            
                        </div>
                    </div>

                    <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="name" class="form-label">Tanggal</label>
                            </div>
                            <div class="col-10">
                                <select
                                    (change)="changeTanggal($event)" 
                                    formControlName="tgl" [ngClass]="{ 'is-invalid': submitted && f['tgl'].errors }" class="form-select">
                                    <option value="" selected>All</option>
                                    <option [value]="tgl" *ngFor="let tgl of listTglBulan">{{ tgl }}</option>
                                </select>
                            </div>                            
                        </div>
                    </div>
                    <!-- <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="name" class="form-label">&nbsp;</label>
                            </div>
                            <div class="col-10">
                                &nbsp; Kosongkan tanggal jika akan menampilkan data satu bulan
                            </div>                            
                        </div>
                    </div> -->

                    <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="name" class="form-label">Urutan Persentase<span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-10">
                              <select
                                  formControlName="urutan_persentase"
                                  [ngClass]="{ 'is-invalid': submitted && f['urutan_persentase'].errors }"
                                  class="form-select"
                                >                                  
                                  <option [value]="kat" *ngFor="let kat of listKategoriUrutan">{{kat}}</option>
                              </select>
                            </div>
                        </div>
                    </div>
                                                          
                  </div>
                  
              </div>
          </form>
      </div>
  </div>
</div>

<div class="list-options mt-4">
  <button class="btn btn-blue-grey" (click)="onSubmit()">
    + Tampil
  </button>
  <button type="button" class="btn btn-danger">
    <img src="./assets/images/icon-pdf.png" alt="PDF"> Print PDF
  </button>
  
</div>

<div class="table-wrapper" *ngIf="listMonitoringAbsensi.length > 0">
  <div class="mb-5 table-options" style="text-align: center;">
    <h3>MONITORING PENGINPUT</h3>
    <h3>BULAN SEPTEMBER 2023</h3>    
  </div>
  <div class="table-responsive">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th class="align-middle">No.</th>
          <th class="align-middle">Musrif/ah</th>
          <th class="align-middle">Jumlah Hari</th>
          <th class="align-middle">Jumlah Kamar</th>
          <th class="align-middle">Jumlah Harus Input</th>
          <th class="align-middle">Jumlah Input</th>
          <th class="align-middle">Persentase</th>          
        </tr>        
      </thead>
      <tbody>
        <tr *ngFor="let siswa of listMonitoringAbsensi | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
          <td>{{ i + 1 + (page - 1) * pageSize }}</td>          
          <td>
            {{ siswa.real_name }}
          </td>
          <td>{{ siswa.jumlah_hari }}</td>
          <td>{{ siswa.jumlah_kamar }}</td>
          <td>{{ siswa.jumlah_harus_input }}</td>
          <td>{{ siswa.total_input }}</td>
          <td>{{ siswa.presentase }}</td>
        </tr>
        <tr *ngIf="listMonitoringAbsensi.length === 0" class="empty">
          <td colspan="35">Tidak terdapat data atau data tidak ditemukan.</td>
        </tr>
      </tbody>
    </table>
  </div>

  <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
    <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
      <div></div>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center pagination-number">
          <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
            <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
              {{ page.label }}
            </a>
            <a class="page-link" *ngIf="p.getCurrent() === page.value">
              {{ page.label }}
            </a>
          </li>
        </ul>
      </nav>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="p.isFirstPage()">
            <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
              <span>Previous</span>
            </a>
          </li>
          <li class="page-item" [class.disabled]="p.isLastPage()">
            <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
              <span>Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </pagination-template>

  <ng-template #emptyPagination>
    <div class="pagination-wrapper">
      <div>&nbsp;</div>
    </div>
  </ng-template>
</div>
