<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button routerLink="/news/daily" class="nav-link" [class.active]="isDaily" id="news-daily-tab" data-bs-toggle="tab" data-bs-target="#newsDaily" type="button" role="tab" aria-controls="daily" aria-selected="false">Rekap Harian</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/news/monthly" class="nav-link" [class.active]="isMonthly" id="news-monthly-tab" data-bs-toggle="tab" data-bs-target="#newsMonthly" type="button" role="tab" aria-controls="monthly" aria-selected="false">Rekap Bulanan</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
</div>
<router-outlet></router-outlet>
