<div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="statistik"
      role="tabpanel"
      aria-labelledby="statistik-tab"
    >
      <div class="tabs-title">Data Prestasi</div>
  
      
      <div class="list-options">                                      
        <button class="btn btn-blue-grey" (click)="getlistIzinSantri()">
            Tambah
        </button>
  
        <button class="btn btn-success" style="display:inline; margin-right: 4px;">
            <img src="./assets/images/icon-excel.png" alt="Excel">
            Download
        </button>
        
      </div>
      
      <div class="table-wrapper">
        <div class="table-options">
          <div class="show-table">
            Show
            <select
              class="form-control"
              name="show"
              id="show"
              (change)="handlePageSizeChange($event)"
            >
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
            entries
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th>No.</th>
                <th>Siswa</th>
                <th>Kelas</th>
                <th>Kamar</th>
                <th>Tanggal</th>
                <th>Inside</th>
                <th>Outside</th> 
                <th>Point</th>                
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let kamar of listIzinSantri
                    | paginate
                      : {
                          id: pageid,
                          itemsPerPage: pageSize,
                          currentPage: page,
                          totalItems: count
                        };
                  let i = index
                "
              >
                <td>{{ i + 1 + (page - 1) * pageSize }}</td>              
                <td>{{ kamar.nama_gedung }}</td>
                <td>{{ kamar.nama_rayon }}</td>              
                <td>{{ kamar.nama_kamar }}</td>
                <td>{{ kamar.kuota }}</td>
                <td>{{ kamar.total_isi }}</td>              
                <td>{{ kamar.sisa }}</td>
                <td class="settings">
                    <div class="dropend">
                        <a href="#" role="button" id="dropdownMenuLink"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="./assets/images/icon-setting.svg" alt="">
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><a (click)="openModalFormDataSiswaKamar(formDataSiswaKamar, kamar)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Atur Data Kamar</a></li>
                            <li><a (click)="printKamar(kamar)" class="dropdown-item"><img src="./assets/images/dropdown/icon-print.png" alt="">Print Kamar</a></li>
                            <li><a class="dropdown-item"><img src="./assets/images/dropdown/icon-print.png" alt="">Format Absen Offline</a></li>
                            <li><a (click)="confirmDelete(kamar.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                        </ul>
                    </div>
                </td>
              </tr>
              <tr *ngIf="listIzinSantri.length === 0" class="empty">
                <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
          <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
            <div></div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center pagination-number">
                <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                  <a
                    class="page-link"
                    (click)="p.setCurrent(page.value)"
                    *ngIf="p.getCurrent() !== page.value"
                  >
                    {{ page.label }}
                  </a>
                  <a class="page-link" *ngIf="p.getCurrent() === page.value">
                    {{ page.label }}
                  </a>
                </li>
              </ul>
            </nav>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="p.isFirstPage()">
                  <a
                    class="page-link"
                    *ngIf="!p.isFirstPage()"
                    (click)="p.previous()"
                    aria-label="Previous"
                  >
                    <span>Previous</span>
                  </a>
                </li>
                <li class="page-item" [class.disabled]="p.isLastPage()">
                  <a
                    class="page-link"
                    *ngIf="!p.isLastPage()"
                    (click)="p.next()"
                    aria-label="Next"
                  >
                    <span>Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </pagination-template>
  
        <ng-template #emptyPagination>
          <div class="pagination-wrapper">
            <div>&nbsp;</div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template #formDataSiswaKamar let-modal>
    <div class="modal-body">
        <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
        <div class="modal-form">
            <h1 class="text-center">Form Siswa Per Kamar</h1>            
            <h3 class="text-center">Kamar : <b>{{ currentDataKamar.nama_kamar }}</b></h3>
            <p class="text-center">Kuota : <b>{{ currentDataKamar.kuota }}</b> &nbsp;&nbsp; Sisa : <b>{{ sisaKamar }}</b></p>
            <br />

            <form [formGroup]="formKamarSiswa" (ngSubmit)="onSubmitformInputDataKamar()">
            <div class="list-options">
                    
                
                <div class="label">
                    Input Siswa &nbsp;
                </div>  
                <div class="form-select-warapper">
                    <select formControlName="nomor_urut" class="form-select">
                      <option value="">-NO URUT-</option>
                      <option [value]="urut.no" *ngFor="let urut of listNomorUrut">{{ urut.no }} - {{ urut.nama }}</option>
                    </select>                  
                </div>                                  

                <div class="search autocomplete">
                    <input formControlName="siswa_name" placeholder="cari siswa" (keyup)="searchSiswa()" type="text" class="form-control" autocapitalize="off" autocorrect="off" autocomplete="off" id="inputNamaSiswa">

                    <div id="myInputautocomplete-list" class="autocomplete-items">
                        <button *ngIf="isShowLoader" class="btn btn-primary" type="button" disabled>
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Searching...
                        </button>
                        <div (click)="chooseSiswa(data)" *ngFor="let data of listSiswa">
                            <strong>{{ data.nama_lengkap }}</strong>                                
                            <p>No, Registrasi {{ data.regnumber }}</p>
                            <p>Tahun masuk {{ data.angkatan }} </p>
                        </div>                        
                    </div>
                </div>

                <button class="btn btn-blue-grey">Simpan</button>                                
            </div>
            </form>            

            <div class="table-wrapper">
                <div class="table-responsive">
                    <table class="table table-borderless">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>No Induk</th>
                          <th>Nama</th>
                          <th>Kelas                          
                          <th></th>             
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let kamar of listSiswaPerKamar
                              | paginate
                                : {
                                    id: pageid,
                                    itemsPerPage: pageSize,
                                    currentPage: page,
                                    totalItems: count
                                  };
                            let i = index
                          "
                        >
                          <!-- <td>{{ i + 1 + (page - 1) * pageSize }}</td>               -->
                          <td>{{ kamar.nomor_urut }}</td>
                          <td>{{ kamar.Siswa.no_induk }}</td>              
                          <td>{{ kamar.Siswa.nama_lengkap }}</td>
                          <td>{{ kamar.Siswa.siswaKelas ? kamar.Siswa.siswaKelas.Kelas.nama_kelas : '' }}</td>                          
                          <td class="settings">
                              <div class="dropend">
                                  <a href="#" role="button" id="dropdownMenuLink"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      <img src="./assets/images/icon-setting.svg" alt="">
                                  </a>
                                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                      <li><a (click)="confirmDeleteSiswaKamar(kamar.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Kosongkan</a></li>
                                      <li><a (click)="openModalPenentuanKamar(addPenentuanKamar, kamar)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Pindah</a></li>                                      
                                  </ul>
                              </div>
                          </td>
                        </tr>
                        <tr *ngIf="listSiswaPerKamar.length === 0" class="empty">
                          <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
  </ng-template>

  <ng-template #addPenentuanKamar let-modal>
    <div class="modal-body">
        <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
        <div class="modal-form">
            <h1 class="text-center">Penentuan Kamar</h1>
            
            <form [formGroup]="formPenentuanKamar" (ngSubmit)="onSubmitPenentuanKamar(modal)">
                <div class="mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label">Nama</label>
                    <input
                        formControlName="nama"
                        class="form-control"
                        type="text" readonly
                    />
  
                </div>
                <div class="mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label">Gedung</label>
                    <select
                        (change)="changeGedungModify($event)"
                        formControlName="id_gedung"
                        [ngClass]="{ 'is-invalid': submitted && k['id_gedung'].errors }"
                        class="form-select">
                        <option value="">Semua</option>
                        <option [value]="gedung.id" *ngFor="let gedung of listGedung">{{ gedung.nama_gedung }}</option>
                    </select>
                    <div *ngIf="submitted && k['id_gedung'].errors" class="invalid-feedback">
                        <div *ngIf="k['id_gedung'].errors['required']">Gedung is required</div>
                    </div>
                </div>
                <div class="mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label">Asrama</label>
                    <select
                        (change)="changeRayonModify($event)"
                        formControlName="id_rayon"
                        [ngClass]="{ 'is-invalid': submitted && k['id_rayon'].errors }"
                        class="form-select">
                        <option value="" selected disabled>Pilih</option>
                        <option [value]="data.id" *ngFor="let data of listRayonModify">{{data.nama_rayon}}</option>
                    </select>
                    <div *ngIf="submitted && k['id_rayon'].errors" class="invalid-feedback">
                        <div *ngIf="k['id_rayon'].errors['required']">Kelas is required</div>
                    </div>
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">Kamar</label>
                  <select
                      (change)="changeKamar($event)" 
                      formControlName="id_kamar"
                      [ngClass]="{ 'is-invalid': submitted && k['id_kamar'].errors }"
                      class="form-select">
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="data.id" *ngFor="let data of listKamarModify">{{data.nama_kamar}}</option>
                  </select>
                  <div *ngIf="submitted && k['id_kamar'].errors" class="invalid-feedback">
                      <div *ngIf="k['id_kamar'].errors['required']">Kelas is required</div>
                  </div>
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">Nomor Urut</label>
                  <select 
                      formControlName="nomor_urut"
                      [ngClass]="{ 'is-invalid': submitted && k['nomor_urut'].errors }"
                      class="form-select">
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="data.no" *ngFor="let data of listNomorUrutModify">{{data.no}} - {{ data.nama }}</option>
                  </select>
                  <div *ngIf="submitted && k['nomor_urut'].errors" class="invalid-feedback">
                      <div *ngIf="k['nomor_urut'].errors['required']">Kelas is required</div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary mt-3 my-btn-save">Save</button>
                
            </form>
        </div>
    </div>
  </ng-template>