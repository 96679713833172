import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/helper/common.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbsensiService } from 'src/app/services/absensi.service';
import { KamarService } from 'src/app/services/kamar.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';


@Component({
  selector: 'app-laporan-perkamar',
  templateUrl: './laporan-perkamar.component.html',
  styleUrls: ['./laporan-perkamar.component.css']
})
export class LaporanPerkamarComponent implements OnInit {

  listLaporanPerKelas: any = [];
  listLaporanPerKategori: any = [];
  selectedKamarValue: any = [];  
  listTahunajaran: any = [];
  listKamar: any = [];
  listTanggalJamKe: any[] = [];
  currentSemester = "";
  errorMessage = '';
  submitted = false;
  pageid = 'proguser';
  searchTerm = '';
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  closeResult: string = '';
  listMonth: any = [];
  currentBulan: any = null;
  listUrutan = [
    "Nama","H","A","S","IP","IT"
  ]
  listKategoriUrutan = [
    "Terkecil",
    "Terbesar"
  ]
  listKategoriKamar = [
    "PUTRA",
    "PUTRI"
  ]
  listJenisLaporan = [
    "Laporan Perkelas",
    "Laporan Per-kategori Absen"
  ]
  
  currentAngkatan = "";
  currentPeriodeId: any = null;
  isLaporanPerKelas: Boolean = true;

  formLaporanAbsensi: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
    bulan: new FormControl(''),
    pilihAllKamarCheckbox: new FormControl(''),
    selectedKamar: new FormArray([]),
    urutan: new FormControl(''),
    kategori_urutan: new FormControl(''),
    kategori_kamar: new FormControl(''),        
    start_date: new FormControl(''),
    end_date: new FormControl(''),    
  });

  constructor(
    private uiService: UiService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,    
    private kamarService: KamarService,
    private tahunajaranService: TahunajaranService,
    private absensiService: AbsensiService,
  ) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    const today = this.commonService.convertDate();
    this.listMonth = this.commonService.getListMonthTahunAjaran();
    this.getCurrentTahunajaran();
    this.initForm();
    
    // default value
    this.formLaporanAbsensi.controls['start_date'].setValue(today);
    this.formLaporanAbsensi.controls['end_date'].setValue(today);

    const currentMonth = this.commonService.getCurrentMonthName();
    this.currentBulan = currentMonth;
    console.log("BULAN", this.currentBulan);
    
    this.formLaporanAbsensi.controls['bulan'].setValue(currentMonth);
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add('siswa-baru-list');
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    console.log('handle PAGE', this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  search(): void {
    this.page = 1;
  }

  initForm() {
    this.formLaporanAbsensi = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        bulan: ['', Validators.required],
        pilihAllKamarCheckbox: [false],
        selectedKamar: new FormArray([]),
        urutan: ['Nama', Validators.required],
        kategori_urutan: ['Terkecil', Validators.required],
        kategori_kamar: ['', Validators.required],        
        start_date: ['', Validators.required],
        end_date: ['', Validators.required],
      },
    );
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        this.currentPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        this.getAllTahunajaran();
                
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = "";

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formLaporanAbsensi.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getListKamar() {
    this.uiService.showLoader();

    const params:any = [];

    params['kategori'] = this.formLaporanAbsensi.value.kategori_kamar;

    this.kamarService.getByKategori(params).subscribe({
      next: resdata => {
        console.log("KAMAR", resdata);
        
        this.listKamar = resdata;

        this.resetFormKamar();
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeKategoriKamar(e: any) {
    console.log(e.target.value);

    this.getListKamar();   

  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    // this.currentAngkatan = e.target.value;

    // this.getSebaranKota();
  }

  changeBulan(e: any) {
    // this.formInputHarianAlQuran.controls['id_kelas'].enable();
    // this.formInputHarianAlQuran.controls['id_kelas'].setValue("");
    // this.listNilaiAlquran = [];
    // this.currentBulan = e.target.value;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formLaporanAbsensi.controls;
  }

  changeStartDateField(e: any) {
    console.log(e.target.value);
    const birthdate = e.target.value;
    this.formLaporanAbsensi.controls['start_date'].setValue(birthdate);
  }

  changeEndDateField(e: any) {
    console.log(e.target.value);
    const birthdate = e.target.value;
    this.formLaporanAbsensi.controls['end_date'].setValue(birthdate);
  }

  onSubmit() {
    console.log(this.selectedKamarValue);
    
    console.log(JSON.stringify(this.formLaporanAbsensi.value, null, 2));
    if (this.formLaporanAbsensi.invalid || this.selectedKamarValue.length === 0) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    const payload = {       
      id_periode: this.currentPeriodeId,
      start_date: this.formLaporanAbsensi.value.start_date,
      end_date: this.formLaporanAbsensi.value.end_date,
      kamar: this.selectedKamarValue
    }
    this.absensiService.getListAbsensiKamarByDateRange(payload).subscribe({
      next: resdata => {
        console.log('list absen', resdata);

        this.listLaporanPerKelas = resdata;


        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
    
  }

  get selectedKamar(): FormArray {
    return this.formLaporanAbsensi.get('selectedKamar') as FormArray;
  }

  resetFormKamar() {
    this.formLaporanAbsensi.controls['pilihAllKamarCheckbox'].setValue(false);
    const selectedKamar: FormArray = this.formLaporanAbsensi.get('selectedKamar') as FormArray;
    
    if (selectedKamar.length > 0) {
      console.log("ADA ISI", selectedKamar); 
      selectedKamar.clear();
    }

    for (let i = 0; i < this.listKamar.length; i++) {
      selectedKamar.push(new FormControl(false));      
    }
    
    this.selectedKamarValue = [];


    // for (let i = 0; i < this.listBulanEfektif.length; i++) {
    //   selectedBulanTertagih.push(new FormControl(''));      
    // }
  }

  onCheckboxPilihAllKamarChange(event: any) {
    
    // const selectedBulanTagihan: FormArray = this.formInputTagihanBulanan.get('selectedBulanTagihan') as FormArray;
    
    if (event.target.checked) {
      this.pilihAllKamar();
    } else {
      this.resetFormKamar();
    }
    
  }

  pilihAllKamar() {
    const selectedKamar: FormArray = this.formLaporanAbsensi.get('selectedKamar') as FormArray;
    
    if (selectedKamar.length > 0) {
      console.log("ADA ISI", selectedKamar, this.listKamar); 
      selectedKamar.clear();
    }
    
    this.selectedKamarValue = [];
    
    for (let i = 0; i < this.listKamar.length; i++) {
      selectedKamar.push(new FormControl(true));      
      this.selectedKamarValue.push(this.listKamar[i].id);
    }
    
  }

  onCheckboxChange(event: any) {
    
    // const selectedBulanTagihan: FormArray = this.formInputTagihanBulanan.get('selectedBulanTagihan') as FormArray;
    
    if (event.target.checked) {
      this.selectedKamarValue.push(event.target.value);      
    } else {
      this.formLaporanAbsensi.controls['pilihAllKamarCheckbox'].setValue(false);
      const index = this.selectedKamarValue
      .findIndex((x: { value: any; }) => {
        console.log("x val", x);
        
        return x === event.target.value
      });
      console.log("UNCHECK IDX", index);
      
      this.selectedKamarValue.splice(index, 1);
    }
    
  }

  showPdf(data: any) {

  }

}
