import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jadwal-mengajar',
  templateUrl: './jadwal-mengajar.component.html',
  styleUrls: ['./jadwal-mengajar.component.css']
})
export class JadwalMengajarComponent implements OnInit {

  isMain = false
  isUpload = false
  isJadwalGuru = false
  isRekapInput = false
  isLiburMengajar = false

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.changeTab(this.router.url)
  }

  changeTab(url: String) {
    switch (url) {
      case '/pendidikan/jadwal-mengajar-main':
        this.isMain = true
        this.isUpload = false
        this.isJadwalGuru = false
        this.isRekapInput = false
        this.isLiburMengajar = false
        return;
      case '/pendidikan/jadwal-mengajar-upload':
        this.isMain = false
        this.isUpload = true
        this.isJadwalGuru = false
        this.isRekapInput = false
        this.isLiburMengajar = false
        return;
      case '/pendidikan/jadwal-mengajar-jadwal-guru':
        this.isMain = false
        this.isJadwalGuru = true
        this.isUpload = false
        this.isRekapInput = false
        this.isLiburMengajar = false
        return;
      case '/pendidikan/jadwal-mengajar-rekap-input':
        this.isMain = false
        this.isRekapInput = true
        this.isUpload = false
        this.isJadwalGuru = false
        this.isLiburMengajar = false
        return;
      case '/pendidikan/jadwal-mengajar-libur-mengajar':
        this.isMain = false
        this.isLiburMengajar = true
        this.isUpload = false
        this.isJadwalGuru = false
        this.isRekapInput = false
        return;
      default:
        this.isMain = false
        this.isUpload = false
        this.isJadwalGuru = false
        this.isRekapInput = false
        this.isLiburMengajar = false
        return;
    }
  }
}
