<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="statistik"
    role="tabpanel"
    aria-labelledby="statistik-tab"
  >
    <div class="tabs-title">Dokumen</div>

    
    <div class="list-options">
      
      <button class="btn btn-blue-grey" (click)="openModalformUnggahDokumen(formUploadDokumenModal)">
        + Tambah Dokumen
      </button>
      
    </div>
    
    <div class="table-wrapper">
      <div class="table-options">
        <div class="show-table">
          Show
          <select
            class="form-control"
            name="show"
            id="show"
            (change)="handlePageSizeChange($event)"
          >
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }}
            </option>
          </select>
          entries
        </div>
      </div>
      
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th class="align-middle">No.</th>
              <th class="align-middle">Descripsi</th> 
              <th class="align-middle">Url</th>              
              <th></th>
          </thead>
          <tbody>
            <tr *ngFor="let dok of listDokumen | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
              <td>{{ i + 1 + (page - 1) * pageSize }}</td>
              <td>{{ dok.deskripsi }}</td>              
              <td><input type="text" style="cursor: pointer;" (click)="highlightText($event)" value="{{ urlPath }}{{ dok.path }}"></td>
              <td class="settings">
                <div class="dropend">
                    <a href="#" role="button" id="dropdownMenuLink"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="./assets/images/icon-setting.svg" alt="">
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li><a (click)="openEditModalformUnggahDokumen(formUploadDokumenModal, dok)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>                                        
                        <li><a (click)="confirmDelete(dok)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                    </ul>
                </div>
            </td>
            </tr>
            <tr *ngIf="listDokumen.length === 0" class="empty">
              <td colspan="6">Tidak terdapat data atau data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>
    
      <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
        <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
          <div></div>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center pagination-number">
              <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                  {{ page.label }}
                </a>
                <a class="page-link" *ngIf="p.getCurrent() === page.value">
                  {{ page.label }}
                </a>
              </li>
            </ul>
          </nav>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="p.isFirstPage()">
                <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                  <span>Previous</span>
                </a>
              </li>
              <li class="page-item" [class.disabled]="p.isLastPage()">
                <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                  <span>Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </pagination-template>
    
      <ng-template #emptyPagination>
        <div class="pagination-wrapper">
          <div>&nbsp;</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #formUploadDokumenModal let-modal>
  <div class="modal-body">
      <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
      <div class="modal-form">
          <h1 class="text-center" *ngIf="isNew">Tambah Dokumen</h1>
          <h1 class="text-center" *ngIf="!isNew">Edit Dokumen</h1>
          <form [formGroup]="formUnggahDokumen" (ngSubmit)="onSubmitformUnggahDokumen(modal)">

            <div class="row mb-3">
              <label for="inputDeskripsi" class="col-sm-3 col-form-label control-label">Deskripsi</label>
              <div class="col-sm-9 autocomplete">
                <input
                  [ngClass]="{ 'is-invalid': submitted && f['deskripsi'].errors }" 
                  formControlName="deskripsi" type="text" class="form-control" 
                  autocapitalize="off" autocorrect="off" autocomplete="off" id="inputDeskripsi">    
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputNamaGuru" class="col-sm-3 col-form-label control-label">Unggah</label>
              <div class="col-sm-9 autocomplete">
                <input type='file' (change)="uploadDokumen($event);" />    
              </div>
            </div>

            <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isNew">CREATE</button>
            <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="!isNew">Update</button>
          </form>
      </div>
  </div>
</ng-template>