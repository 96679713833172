import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { SdmService } from 'src/app/services/sdm.service';
import { UiService } from 'src/app/helper/ui.service';
import { GedungService } from 'src/app/services/gedung.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-gedung-list',
  templateUrl: './gedung-list.component.html',
  styleUrls: ['./gedung-list.component.css']
})
export class GedungListComponent implements OnInit {

  isNew = true;
  errorMessage = '';
  submitted = false;
  isShowLoader: Boolean = false;
  isShowLoaderPenguji: Boolean = false;
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  pageid = "Gedung";
  editGedungId = null;

  listGedung: any = [];
  listNilaiMataUji: any = [];
  listHasilPpsb: any = [];
  listMusyrifah: any = [];
  listSiswa: any = [];
  listGelombang: any = [];

  formAddGedung: FormGroup = new FormGroup({
    nama_gedung: new FormControl(''),
    active: new FormArray([]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private sdmService: SdmService,
    private gedungService: GedungService,
    private router: Router,
    private uiService: UiService
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {    
    this.initForm();
    this.getAllGedung();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {
    this.formAddGedung = this.formBuilder.group(
      {
        nama_gedung: ['', Validators.required],
        active: ['t'],
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAddGedung.controls;
  }

  openGedungModal(content: any) {
    this.isNew = true;
    this.submitted = false;
    this.formAddGedung.reset();
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' });
  }

  search(): void {
    this.page = 1;
    this.getAllGedung();
  }

  openModalEdit(content:any, data: any) {
    console.log(data);
    this.editGedungId = data.id;
    this.isNew = false;
    this.formAddGedung.patchValue(data)
    this.submitted = false;
    this.modalService.open(content, {size: 'xl', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  confirmDelete(id: String) {
    this.uiService.showConfirmDelete(this, id);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.gedungService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getAllGedung();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  getAllGedung() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    console.log(params);
    

    this.gedungService.getAll(params).subscribe({
      next: resdata => {
        console.log('Gedung', resdata);
        const { data, totalrows } = resdata;

        //this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listGedung = data;
        this.count = totalrows;

        console.log('COUNT', this.count);

      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onSubmitGedung(modal: any): void {

    console.log(JSON.stringify(this.formAddGedung.value, null, 2));
    
    this.submitted = true;
    
    if (this.formAddGedung.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }
    
    modal.dismiss('Cross click');

    this.uiService.showLoader();

    if (this.isNew) {
      this.gedungService.add(this.formAddGedung.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.formAddGedung.reset();
          this.submitted = false;
          this.initForm();

          this.getAllGedung();
          
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        },
        error: err => {
          console.log(err);
          this.formAddGedung.reset();
          this.submitted = false;
          this.initForm();
          
          this.getAllGedung();

          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });    
    } else {
      this.gedungService.update(this.editGedungId, this.formAddGedung.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.formAddGedung.reset();
          this.submitted = false;
          this.initForm();

          this.getAllGedung();
          
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          this.router.navigate(['/master/gedung']);
  
        },
        error: err => {
          console.log(err);
          this.formAddGedung.reset();
          this.submitted = false;
          this.initForm();

          this.getAllGedung();
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }

  }

  chooseMusyrifah(data: any) {
    this.listMusyrifah = [];
    console.log(data);
    this.formAddGedung.controls['musyrifah_name'].setValue(data.userProfile.real_name);
    this.formAddGedung.controls['id_musyrifah'].setValue(data.userProfile.id_user);
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  changeStatus(e: any) {
    console.log(e.target.value);
    this.formAddGedung.controls['active'].setValue(e.target.value);
  }

  changeKategori(e: any) {
    console.log(e.target.value);
    this.formAddGedung.controls['kategori'].setValue(e.target.value);
  }

  changeTempatTidur(e: any) {
    console.log(e.target.value);
    this.formAddGedung.controls['tempat_tidur'].setValue(e.target.value);
  }

}
