import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LembagaService } from 'src/app/services/lembaga.service';
import { DatePipe } from '@angular/common';
import { BiayaTahunanService } from 'src/app/services/biaya-tahunan.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { GroupBiayaService } from 'src/app/services/group-biaya.service';
import { PaketPembayaranService } from 'src/app/services/paket-pembayaran.service';

@Component({
  selector: 'app-setup-tahunan',
  templateUrl: './setup-tahunan.component.html',
  styleUrls: ['./setup-tahunan.component.css']
})

export class SetupTahunanComponent implements OnInit {

  listsNomorAkun: any = [];
  listPaketBiaya: any = [];
  errorMessage = '';
  listTahunajaran: any = [];
  currentPeriodeId: any = null;
  listGroupBiaya: any = [];
  listPaketPembayaran: any = [];
  listBiayaPaketPembayaran: any = [];
  listLembaga: any = [];
  idlembaga: number = 0;
  listItemBiaya: any = [];
  listItemBiayaTahunan: any = [];
  idPaketPembayaran: number = 0;
  currentPaket: string = "";
  currentGroupBiaya: string = "";
  currentIdGroupBiaya: number = 0;

  formAddSetupTahunan: FormGroup = new FormGroup({
    id_biaya_tahunan: new FormControl(''),
    id_group_biaya: new FormControl(''),
    id_item_biaya: new FormControl(''),
    nilai_biaya: new FormControl(''),
  });
  submitted = false;
  isNew = true;
  editLembagaId = null;

  pageid = "lembaga";

  nama = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];

  closeResult: string = '';

  constructor(
    private tahunajaranService: TahunajaranService,
    private lembagaService: LembagaService,
    private biayaTahunanService: BiayaTahunanService,
    private groupBiayaService: GroupBiayaService, 
    private paketPembayaranService: PaketPembayaranService,
    private uiService: UiService, 
    private datePipe: DatePipe,
    private modalService: NgbModal, 
    private formBuilder: FormBuilder
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getCurrentTahunajaran();
    this.getAllGroupBiaya();
    this.getAllTahunajaran();
    this.getAllLembaga();

    this.formAddSetupTahunan = this.formBuilder.group(
      {
        id_biaya_tahunan: ['', Validators.required],
        id_group_biaya: ['', Validators.required],
        id_item_biaya: ['', Validators.required],
        nilai_biaya: ['', Validators.required],
      }
    );
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  onChangeLembaga(e: any) {
    console.log(e.target.value);
    this.idlembaga = e.target.value;
    this.getAllGroupBiaya();
    //this.selectedPeriode = periode;
    //this.getListBiayaByPeriode(periode);
  }

  onChangeTahunAjaran(e: any) {
    console.log(e.target.value);
    const periode = e.target.value;
    this.currentPeriodeId = periode;
    this.getAllGroupBiaya();
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllGroupBiaya() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.nama, this.page, this.pageSize);

    this.groupBiayaService.getAll('').subscribe({
      next: resdata => {
        console.log("getAllGroupBiaya", resdata);
        // const { data, totalrows } = resdata;
        const totalrows = 20;
        this.listGroupBiaya = resdata.data;
        
        this.count = totalrows;
      
        this.uiService.hideLoader();

        this.getAllPaketPembayaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllPaketPembayaran() {
    this.paketPembayaranService.getByIdLembaga(this.idlembaga).subscribe({
      next: resdata => {
        console.log("getAllPaketPembayaran", resdata.data);
        // const { data, totalrows } = resdata;
        this.listBiayaPaketPembayaran = [];

        this.listPaketPembayaran = resdata.data;
                
        for (var index in this.listPaketPembayaran) {
          let json: any = [];
          let totalGroup: any = [];

          json['paket'] = this.listPaketPembayaran[index].nama_paket;
          
          for (var index2 in this.listGroupBiaya) {
            totalGroup.push(
              {
                'idpaket': this.listPaketPembayaran[index].id,
                'idgroup': this.listGroupBiaya[index2].id,
                'group_name': this.listGroupBiaya[index2].name,
                'total': 0
              }
            )     
          }
          
          json['groups'] = totalGroup;
          json['total_paket'] = 0;

          this.listBiayaPaketPembayaran.push(json);
          
        }
      
        this.uiService.hideLoader();

        this.getAllBiayaPaketPembayaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllBiayaPaketPembayaran() {
    console.log("LEMBAGA ID : ", this.idlembaga);
    this.paketPembayaranService.getPaketpembayaranbylembagaandperiode(this.idlembaga, this.currentPeriodeId).subscribe({
      next: resdata => {
        console.log("getPaketpembayaranbylembagaandperiode", this.currentPeriodeId, resdata);
        
        for (var index in resdata) {
          console.log("NILAI ", index);
          console.log(resdata[index].nama_paket);
          //this.listBiayaPaketPembayaran[resdata[index].name] = 0;
          //this.listBiayaPaketPembayaran[resdata[index].nama_paket][resdata[index].name] = resdata[index].total_group_biaya;
          for (var index2 in this.listBiayaPaketPembayaran) {
            if (resdata[index].nama_paket === this.listBiayaPaketPembayaran[index2].paket) {
              this.listBiayaPaketPembayaran[index2].total_paket = resdata[index].total_biaya;
              for (var index3 in this.listBiayaPaketPembayaran[index2].groups) {
                if (resdata[index].name === this.listBiayaPaketPembayaran[index2].groups[index3].group_name) {
                  console.log("YEAS", resdata[index].nama_paket, this.listBiayaPaketPembayaran[index2].groups[index3].total, resdata[index].total_group_biaya)
                  this.listBiayaPaketPembayaran[index2].groups[index3].total = resdata[index].total_group_biaya;
                }
              } 
              
            }
          }
        }
        console.log("listBiayaPaketPembayaran", this.listBiayaPaketPembayaran);
        //this.listBiayaPaketPembayaran = resdata;
        
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  editGroupBiaya(groupbiaya: any) {
    console.log(groupbiaya);
  }

  getAllTahunajaran() {
    this.uiService.showLoader();

    const params = this.getRequestParams("", this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log('kelas', resdata);
        const { data, totalrows } = resdata;

        //this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listTahunajaran = data;
        this.count = totalrows;

        console.log('COUNT', this.count);

      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  private getGroupBiayaById(groupBiayaId: any) {
    this.groupBiayaService.get(groupBiayaId).subscribe({
      next: resdata => {
        console.log("getDetailGroupBiaya", resdata);
        this.listItemBiaya = resdata;
        this.formAddSetupTahunan.controls['id_group_biaya'].setValue(groupBiayaId);
      
        this.uiService.hideLoader();
  
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  openModal(content:any, groupbiaya: any) {
    console.log("groupbiaya", groupbiaya);        
    
    this.currentGroupBiaya = groupbiaya.group_name;
    this.currentIdGroupBiaya = groupbiaya.idgroup;
    this.getPaketPembayaranById(groupbiaya.idpaket);

    this.getGroupBiayaById(groupbiaya.idgroup);

    this.isNew = true;
    this.submitted = false;
    this.formAddSetupTahunan.reset();
    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getPaketPembayaranById(idpaket: any) {

    this.paketPembayaranService.getBiayaPendaftaran(this.idlembaga, this.currentPeriodeId).subscribe({
      next: resdata => {
        console.log("biaya registrasi", resdata);

        this.uiService.hideLoader();

      },
      error: err => {
        
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);
          
          this.uiService.showError(this.errorMessage);
        
        this.uiService.hideLoader();
      }
    });

    this.paketPembayaranService.getByIdLembagaAndPeriod(idpaket, this.idlembaga, this.currentPeriodeId).subscribe({
      next: resdata => {
        console.log("getpembeayaran tahunan", resdata);
        // const { data, totalrows } = resdata;

        if (resdata.PaketBiayaTahunan.length === 0) {
          console.log("BELOM ADA");
          this.addBiayaTahunan(idpaket);
        } else {
          this.idPaketPembayaran = resdata.PaketBiayaTahunan[0].id;          
          this.currentPaket = resdata.nama_paket;
          
          this.getItemPaketPembayaran(this.idPaketPembayaran, this.currentIdGroupBiaya);
        }
      
        this.uiService.hideLoader();

      },
      error: err => {
        if (err.status === 404) {
          console.log("BELOM ADA 404");
          this.addBiayaTahunan(idpaket);
        } else {
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);
          
          this.uiService.showError(this.errorMessage);
        }
        this.uiService.hideLoader();
      }
    });
  }

  getItemPaketPembayaran(idpaket: any, groupid: any) {
    this.paketPembayaranService.getBiayaTahunanById(idpaket, groupid).subscribe({
      next: resdata => {
        console.log("get item pembayaran tahunan", resdata);
        this.formAddSetupTahunan.controls['id_biaya_tahunan'].setValue(resdata.id);
        // const { data, totalrows } = resdata;
        this.listItemBiayaTahunan = resdata;
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        this.listItemBiayaTahunan = [];
        this.formAddSetupTahunan.controls['id_biaya_tahunan'].setValue(idpaket);
        this.uiService.hideLoader();
        // hide this error message
        // this.uiService.showError(this.errorMessage);
      }
    });
  }


  addBiayaTahunan(idpaket: any) {
    const payload = {
      'id_periode': this.currentPeriodeId,
      'id_paket_pembayaran': idpaket,
      'id_lembaga': this.idlembaga,
      'total_biaya': 0
    }

    this.paketPembayaranService.addBiayaTahunan(payload).subscribe({
      next: resdata => {
        console.log("getpembeayaran tahunan", resdata);
        this.idPaketPembayaran = resdata.id;
        // const { data, totalrows } = resdata;
      
        this.uiService.hideLoader();
        this.getPaketPembayaranById(idpaket);
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }
  
  openModalEdit(content:any, lembaga: any) {
    console.log(lembaga);
    this.editLembagaId = lembaga.id;
    this.isNew = false;
    this.formAddSetupTahunan.patchValue(lembaga)
    this.submitted = false;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
     
  /**
   * Write code on Method
   *
   * @return response()
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getRequestParams(searchNama: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`nama`] = searchNama ? searchNama : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getAllLembaga() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.nama, this.page, this.pageSize);

    this.lembagaService.getAllLembaga(params).subscribe({
      next: resdata => {
        console.log("LEMBAGA", resdata);
        // const { data, totalrows } = resdata;
        const totalrows = 20;
        this.listLembaga = resdata;
        this.count = totalrows;
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllBiayaTahunan(periodId: number) {
    this.uiService.showLoader();

    this.biayaTahunanService.getByLembagaAndPeriode(0, periodId).subscribe({
      next: resdata => {
        console.log(resdata);
        // const { data, totalrows } = resdata;
        const totalrows = 20;
        this.listPaketBiaya = resdata;
        this.count = totalrows;
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.getAllBiayaTahunan(event);
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    //this.getAllBiayaTahunan();
  }

  
  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/empty-image.png';
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAddSetupTahunan.controls;
  }

  get g(): { [key: string]: AbstractControl } {
    //return this.formAddItemGroupBiaya.controls;
    return this.formAddSetupTahunan.controls;
  }

  onSubmitTahunan(): void {
    this.submitted = true;
    
    console.log(JSON.stringify(this.formAddSetupTahunan.value, null, 2));
    
    if (this.formAddSetupTahunan.invalid) {
      return;
    }
    
    //this.modalService.dismissAll();
    this.uiService.showLoader();


    this.paketPembayaranService.addItemBiayaTahunan(this.formAddSetupTahunan.value).subscribe({
      next: resdata => {
        this.submitted = false;
        this.formAddSetupTahunan.reset();
        console.log(resdata);
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
        this.getItemPaketPembayaran(this.idPaketPembayaran, this.currentIdGroupBiaya);
        this.getAllGroupBiaya();
        this.getGroupBiayaById(this.currentIdGroupBiaya);
      },
      error: err => {
        console.log(err);
        this.submitted = false;
        this.formAddSetupTahunan.reset();
        
        this.errorMessage = err.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });


    console.log(JSON.stringify(this.formAddSetupTahunan.value, null, 2));
  }

  confirmDelete(id: String) {
    this.uiService.showConfirmDelete(this, id);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    // if (isDelete) {

    //   console.log('DELETE THIS ID : ', isDelete, id);

    //   $SELF.uiService.showLoader();

    //   $SELF.lembagaService.delete(id).subscribe({
    //     next: (resdata: any) => {
    //       console.log(resdata);

    //       $SELF.getAllBiayaTahunan();
          
    //       $SELF.uiService.hideLoader();
    //       $SELF.uiService.showSuccess('');

    //     },
    //     error: (err: any) => {
    //       console.log(err);
          
    //       $SELF.errorMessage = err.message;
    //       console.log($SELF.errorMessage);
          
    //       $SELF.uiService.hideLoader();
          
    //       $SELF.uiService.showError($SELF.errorMessage);
    //     }
    //   });

    // }
  }

  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, 'd - MM - Y');
  }


  deleteItem(isDelete: Boolean, id: String, idPaket: string, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.paketPembayaranService.deleteItemBiayaTahunan(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getItemPaketPembayaran(idPaket, $SELF.currentIdGroupBiaya);
          $SELF.getAllGroupBiaya();
          $SELF.getGroupBiayaById($SELF.currentIdGroupBiaya);
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  confirmDeleteItemPembayaran(id: any, idPaket: any) {
    this.uiService.showConfirmDeleteSpec(this, this.deleteItem, id, idPaket);
  }

}
