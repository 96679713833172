<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/input-guru" class="nav-link" [class.active]="isInputGuru" id="input-guru-tab" data-bs-toggle="tab" data-bs-target="#inputGuru" type="button" role="tab" aria-controls="list" aria-selected="false">Guru Input</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/catatan-siswa" class="nav-link" [class.active]="isCatatanSiswa" id="catatan-siswa-tab" data-bs-toggle="tab" data-bs-target="#catatanSiswa" type="button" role="tab" aria-controls="nilai" aria-selected="false">Catatan Siswa</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/pendidikan/list-input" class="nav-link" [class.active]="isListInput" id="list-input-tab" data-bs-toggle="tab" data-bs-target="#listInput" type="button" role="tab" aria-controls="deleted" aria-selected="false">List Input</button>
  </li>
  <li class="nav-item" role="presentation" *ngxPermissionsOnly="['SUPER_ADMIN','VIEW_MONITORING_ABSENSI']">
    <button routerLink="/pendidikan/laporan" class="nav-link" [class.active]="isLaporan" id="laporan-tab" data-bs-toggle="tab" data-bs-target="#laporan" type="button" role="tab" aria-controls="deleted" aria-selected="false">Laporan</button>
  </li>
  <li class="nav-item" role="presentation" *ngxPermissionsOnly="['SUPER_ADMIN','VIEW_MONITORING_ABSENSI']">
    <button routerLink="/pendidikan/monitoring" class="nav-link" [class.active]="isMonitoring" id="monitoring-tab" data-bs-toggle="tab" data-bs-target="#monitoring" type="button" role="tab" aria-controls="deleted" aria-selected="false">Monitoring</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane" [class.active]="isCatatanSiswa" id="catatanSiswa" role="tabpanel" aria-labelledby="catatan-siswa-tab">
    <div class="tabs-title">Catatan Siswa</div>
  </div>
</div>
<router-outlet></router-outlet>
