import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tingakatan-delete',
  templateUrl: './tingakatan-delete.component.html',
  styleUrls: ['./tingakatan-delete.component.css']
})
export class TingakatanDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
