<app-nav-tab></app-nav-tab>

<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade"
    id="statistik"
    role="tabpanel"
    aria-labelledby="statistik-tab"
  >
    <div class="tabs-title">Statistik Pendaftaran</div>
  </div>
  <div
    class="tab-pane fade show active"
    id="statistik"
    role="tabpanel"
    aria-labelledby="statistik-tab"
  >
    <div class="tabs-title">Statistik Pendaftaran {{ currentAngkatan }}</div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="table-wrapper">
          <div class="table-header">
            Rekap Pembayaran Formulir Berdasarkan Lembaga
          </div>
          <div class="card">
            <div class="card-body">
              <!-- <canvas id="lembaga" width="300" height="150"></canvas> -->
              <canvas
                baseChart
                [labels]="doughnutChartLabels"
                [datasets]="doughnutChartDatasets"
                [options]="doughnutChartOptions"
                [legend]="false"
                [type]="'doughnut'"
              >
              </canvas>
              <div class="chart-total"><b>{{ totalPembayaranFormulir }}</b> Total</div>
              <div class="chart-desc">
                <div class="chart-data" *ngFor="let data of listRekapPembayaranFormulir; let i = index">
                  <div class="circle"
                      [style.background]="data.bgColor"
                      [style.border-color]="data.borderColor" 
                  ></div>
                  <div class="data">{{ data.persen }}%</div>
                  <!-- <div class="name">{{ data.nama_lembaga }}</div> -->
                </div>
                <!-- <div class="chart-data">
                  <div class="circle" style="background-color: #75bbc8; border-color: #c0c0c0"></div>
                  <div class="data">30%</div>
                  <div class="name">Lembaga B</div>
                </div> -->
              </div>
              <!-- <script>
                      const ctxLembaga = document.getElementById('lembaga').getContext('2d');
                      const chartLembaga = new Chart(ctxLembaga, {
                          type: 'doughnut',
                          data: {
                              datasets: [
                                  {
                                      data: [70, 30],
                                      backgroundColor: ['#16556F', '#75BBC8'],
                                      rotation: 250
                                  },
                              ]
                          },
                      });
                  </script> -->
            </div>
          </div>
        </div>
        
      </div>

      <div class="col-12 col-lg-6">
        
        <div class="table-wrapper">
          <div class="table-header">Tahapan Proses</div>
          <div class="card">
            <div class="card-body">
              <!-- <canvas id="Proses" width="300" height="150"></canvas> -->
              <canvas
                baseChart
                [labels]="tpDoughnutChartLabels"
                [datasets]="tpDoughnutChartDatasets"
                [options]="tpDoughnutChartOptions"
                [legend]="false"
                [type]="'doughnut'"
              >
              </canvas>
              <div class="chart-total"><b>{{ totalProses }}</b> Total</div>
              <div class="chart-desc">
                <!-- <div class="chart-data">
                  <div class="circle" style="background-color: #de0685; border-color: #de0685"></div>
                  <div class="data">70%</div>
                  <div class="name">Proses A</div>
                </div>
                <div class="chart-data">
                  <div class="circle" style="background-color: #fd8fcf; border-color: #fd8fcf"></div>
                  <div class="data">30%</div>
                  <div class="name">Proses B</div>
                </div> -->
                <div class="chart-data" *ngFor="let data of listRekapProses; let i = index">
                  <div class="circle"
                      [style.background]="data.bgColor"
                      [style.border-color]="data.borderColor" 
                  ></div>
                  <div class="data">{{ data.persen }}%</div>
                  <!-- <div class="name">{{ data.proses }}</div> -->
                </div>
              </div>
              <!-- <script>
                      const ctxProses = document.getElementById('Proses').getContext('2d');
                      const chartProses = new Chart(ctxProses, {
                          type: 'doughnut',
                          data: {
                              datasets: [
                                  {
                                      data: [70, 30],
                                      backgroundColor: ['#DE0685', '#FD8FCF'],
                                      rotation: 250
                                  },
                              ]
                          },
                      });
                  </script> -->
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row charts">
      
      <div class="col-12 col-lg-12">
        <div class="table-wrapper">
          <div class="table-header">Status Kelulusan</div>
          <div class="card">
            <div class="card-body">
              <canvas
                id="kelulusan"
                style="position: relative; height: 50vh; width: 50vw"
                baseChart
                [type]="'bar'"
                [data]="barChartData"
                [options]="barChartOptions"
                [legend]="barChartLegend"
              >
              </canvas>
            </div>
          </div>
        </div>
        <div class="table-wrapper">
          <div class="table-header">Rekap Pembayaran Formulir Per Lembaga</div>
          <div class="table-responsive">
            <table class="table">
              <thead class="bordered">
                <tr class="up">
                  <th rowspan="2">LEMBAGA</th>
                  <th colspan="3">PUTRA</th>
                  <th colspan="3">PUTRI</th>
                  <th colspan="2">PUTRA/PUTRI</th>
                  <th rowspan="2">TOTAL</th>
                </tr>
                <tr class="below">
                  <th>Bayar</th>
                  <th>Tidak</th>
                  <th>Jumlah</th>
                  <th>Bayar</th>
                  <th>Tidak</th>
                  <th>Jumlah</th>
                  <th>Bayar</th>
                  <th>Tidak</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>MTs</td>
                  <td>99</td>
                  <td>54</td>
                  <td>153</td>
                  <td>99</td>
                  <td>54</td>
                  <td>153</td>
                  <td>99</td>
                  <td>54</td>
                  <td>306</td>
                </tr>
                <tr>
                  <td>Aliyah</td>
                  <td>23</td>
                  <td>41</td>
                  <td>64</td>
                  <td>23</td>
                  <td>41</td>
                  <td>64</td>
                  <td>23</td>
                  <td>61</td>
                  <td>128</td>
                </tr>
                <tr>
                  <td>Jumlah Pendaftar</td>
                  <td>122</td>
                  <td>95</td>
                  <td>227</td>
                  <td>122</td>
                  <td>95</td>
                  <td>227</td>
                  <td>122</td>
                  <td>95</td>
                  <td>434</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="table-wrapper">
          <div class="table-header">Rekap Pembayaran Formulir Per Tingkatan</div>
          <div class="table-responsive">
            <table class="table">
              <thead class="bordered">
                <tr class="up">
                  <th rowspan="2">TINGKATAN</th>
                  <th colspan="3">PUTRA</th>
                  <th colspan="3">PUTRI</th>
                  <th colspan="2">PUTRA/PUTRI</th>
                  <th rowspan="2">TOTAL</th>
                </tr>
                <tr class="below">
                  <th>Bayar</th>
                  <th>Tidak</th>
                  <th>Jumlah</th>
                  <th>Bayar</th>
                  <th>Tidak</th>
                  <th>Jumlah</th>
                  <th>Bayar</th>
                  <th>Tidak</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1 - TMI</td>
                  <td>99</td>
                  <td>54</td>
                  <td>153</td>
                  <td>99</td>
                  <td>54</td>
                  <td>153</td>
                  <td>99</td>
                  <td>54</td>
                  <td>306</td>
                </tr>
                <tr>
                  <td>2 - TMI</td>
                  <td>23</td>
                  <td>41</td>
                  <td>64</td>
                  <td>23</td>
                  <td>41</td>
                  <td>64</td>
                  <td>23</td>
                  <td>61</td>
                  <td>128</td>
                </tr>
                <tr>
                  <td>3 - TMI</td>
                  <td>99</td>
                  <td>54</td>
                  <td>153</td>
                  <td>99</td>
                  <td>54</td>
                  <td>153</td>
                  <td>99</td>
                  <td>54</td>
                  <td>306</td>
                </tr>
                <tr>
                  <td>4 - TMI</td>
                  <td>23</td>
                  <td>41</td>
                  <td>64</td>
                  <td>23</td>
                  <td>41</td>
                  <td>64</td>
                  <td>23</td>
                  <td>61</td>
                  <td>128</td>
                </tr>
                <tr>
                  <td>5 - TMI</td>
                  <td>99</td>
                  <td>54</td>
                  <td>153</td>
                  <td>99</td>
                  <td>54</td>
                  <td>153</td>
                  <td>99</td>
                  <td>54</td>
                  <td>306</td>
                </tr>
                <tr>
                  <td>6 - TMI</td>
                  <td>23</td>
                  <td>41</td>
                  <td>64</td>
                  <td>23</td>
                  <td>41</td>
                  <td>64</td>
                  <td>23</td>
                  <td>61</td>
                  <td>128</td>
                </tr>
                
                <!-- <tr>
                  <td>Jumlah Pendaftar</td>
                  <td>122</td>
                  <td>95</td>
                  <td>227</td>
                  <td>122</td>
                  <td>95</td>
                  <td>227</td>
                  <td>122</td>
                  <td>95</td>
                  <td>434</td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </div>
        <div class="table-wrapper">
          <div class="table-header">Tahapan Proses</div>
          <div class="table-responsive">
            <table class="table">
              <thead class="bordered">
                <tr class="up">
                  <th>Posisi Proses</th>
                  <th>Jumlah</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-uppercase">Isi Data Pendaftaran</td>
                  <td>22</td>
                  <td>
                    <button (click)="openModalWhatsapp(sendWhatsapp, 'Isi Data Pendaftaran')" type="button" class="btn btn-success btn-sm">
                      <img src="./assets/images/icon-send.png" alt="Whatsapp"> Whatsapp
                    </button>
                  </td>
                </tr>
                <tr>
                  <td class="text-uppercase">Bayar Biaya Pendaftaran</td>
                  <td>52</td>
                  <td>
                    <button (click)="openModalWhatsapp(sendWhatsapp, 'Bayar Biaya Pendaftaran')" type="button" class="btn btn-success btn-sm">
                      <img src="./assets/images/icon-send.png" alt="Whatsapp"> Whatsapp
                    </button>
                  </td>
                </tr>
                <tr>
                  <td class="text-uppercase">Validasi Dokumen</td>
                  <td>120</td>
                  <td>
                    <button (click)="openModalWhatsapp(sendWhatsapp, 'Validasi Dokumen')" type="button" class="btn btn-success btn-sm">
                      <img src="./assets/images/icon-send.png" alt="Whatsapp"> Whatsapp
                    </button>
                  </td>
                </tr>
                <tr>
                  <td class="text-uppercase">Ujian Masuk</td>
                  <td>146</td>
                  <td>
                    <button (click)="openModalWhatsapp(sendWhatsapp, 'Ujian Masuk')" type="button" class="btn btn-success btn-sm">
                      <img src="./assets/images/icon-send.png" alt="Whatsapp"> Whatsapp
                    </button>
                  </td>
                </tr>
                <tr>
                  <td class="text-uppercase">Surat Keputusan Dan Biaya Santri Baru </td>
                  <td>291</td>
                  <td>
                    <button (click)="openModalWhatsapp(sendWhatsapp, 'Surat Keputusan Dan Biaya Santri Baru')" type="button" class="btn btn-success btn-sm">
                      <img src="./assets/images/icon-send.png" alt="Whatsapp"> Whatsapp
                    </button>
                  </td>
                </tr>
                <tr>
                  <td class="text-uppercase">Informasi Santri Baru</td>
                  <td>146</td>
                  <td>
                    <button (click)="openModalWhatsapp(sendWhatsapp, 'Informasi Santri Baru')" type="button" class="btn btn-success btn-sm">
                      <img src="./assets/images/icon-send.png" alt="Whatsapp"> Whatsapp
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div class="pagination-wrapper">
            <div></div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center pagination-number">
                <li class="page-item active">
                  <a class="page-link" href="#">1</a>
                </li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">...</a></li>
                <li class="page-item"><a class="page-link" href="#">6</a></li>
              </ul>
            </nav>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item disabled">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span>Previous</span>
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Next">
                    <span>Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div> -->
        </div>

        <div class="table-wrapper">
          <div class="table-header">Persentase Sebaran Kabupaten - Kota</div>
          <div class="table-responsive">
            <table class="table">
              <thead class="bordered">
                <tr class="up">
                  <th>No.</th>
                  <th>Kabupaten / Kota</th>
                  <th>Jumlah</th>
                  <th>Persentase</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td class="text-uppercase">Jakarta Timur</td>
                  <td>22</td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td class="text-uppercase">Jakarta Selatan</td>
                  <td>52</td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td class="text-uppercase">Kota Bogor</td>
                  <td>120</td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td class="text-uppercase">Kota Bandung</td>
                  <td>146</td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td class="text-uppercase">Kabupaten Majalengka</td>
                  <td>291</td>
                  <td>0%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="pagination-wrapper">
            <div></div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center pagination-number">
                <li class="page-item active">
                  <a class="page-link" href="#">1</a>
                </li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">...</a></li>
                <li class="page-item"><a class="page-link" href="#">6</a></li>
              </ul>
            </nav>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item disabled">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span>Previous</span>
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Next">
                    <span>Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      
    </div>
  </div>
  <!-- <div
    class="tab-pane fade"
    id="pembayaran"
    role="tabpanel"
    aria-labelledby="pembayaran-tab"
  >
    <div class="tabs-title">Pembayaran Registrasi</div>
  </div> -->
</div>

<ng-template #sendWhatsapp let-modal>
  <div class="modal-body">
      <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
      <div class="modal-form">
          <h1 class="text-center">Whatsapp</h1>
          
          <form [formGroup]="formSendWhatsapp" (ngSubmit)="onSubmitFormSendWhatsapp()">
              <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">Tahapan Proses Pendaftaran</label>
                  <input
                      formControlName="tahapan"
                      class="form-control"
                      type="text" readonly
                  />

              </div>
              <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">Pesan</label>
                  <textarea type="text"
                      formControlName="messages"
                      [ngClass]="{ 'is-invalid': submitted && f['messages'].errors }"
                      class="form-control" name="messages"
                      id="messages" placeholder=""></textarea>
        
              </div>
              <button type="submit" class="btn btn-primary mt-3 my-btn-save"><img src="./assets/images/icon-send.png" alt="Whatsapp"> Send</button>
              
          </form>
      </div>
  </div>
</ng-template>
