<div class="content-item mb-5">
    <div class="card">
        <div class="card-header">
            DATA PERMISSION
            <a href="#" [routerLink]="['/role/']" class="btn btn-warning pull-right">
                Back
            </a>
        </div>
        <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div class="row">
                            <div class="col-6 col-lg-5 mb-3">
                                <label for="name" class="form-label">Permission Name</label>
                                <div *ngIf="f['permission_name'].touched && f['permission_name'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['permission_name'].errors && f['permission_name'].errors['required']">Role Name is required.</div>
                                </div>
                                <input
                                    formControlName="permission_name"
                                    type="text"
                                    class="form-control highlight"
                                    placeholder="Can Edit or etc"
                                    >
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-12">
                        <div class="row">
                            <div class="col-6 col-lg-4 mb-3">
                                <label for="editable" class="form-label">Editable</label>
                                <select class="form-select highlight" formControlName="editable">
                                    <option [ngValue]="null" disabled>-- Choose One --</option>
                                    <option *ngFor="let edit of editable" [ngValue]="edit.value">
                                        {{edit.label}}
                                    </option>
                                </select>
                                <div *ngIf="f['editable'].touched && f['editable'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['editable'].errors && f['editable'].errors['required']">Editable is required.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-12">
                        <div class="row">
                            <div class="col-6 col-lg-4 mb-3">
                                <div class="col-6 col-lg-2 d-flex justify-content-end">
                                    <button class="btn btn-primary" type="submit" [disabled]="!form.valid">
                                        Kirim
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>