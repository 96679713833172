<div class="tab-content">
    <div class="tab-pane fade show active">
        <div class="tabs-title">Tagihan Siswa</div>
        <div class="row">
            <div class="col-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        Info Siswa
                    </div>
                    <div class="card-body">
                        <div class="card-text">
                            <div class="row">
                                <div class="col-3 fw-bold">
                                    <p>Nomor Induk</p>
                                    <p>Nama</p>
                                    <p>Ayah</p>
                                    <p>Ibu</p>
                                    <p>Kelas</p>                                    
                                </div>
                                <div class="col-9 grey-text">                                    
                                    <p>{{ currentSiswa.no_induk }}</p>
                                    <p>{{ currentSiswa.nama_lengkap }}</p>
                                    <p>{{ currentSiswa.nama_ayah }}</p>
                                    <p>{{ currentSiswa.nama_ibu }}</p>
                                    <p>{{ currentKelas }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row"><br /></div>

<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
        <button (click)="changeTab('info-tagihan')" class="nav-link" [class.active]="isInfoTagihan" id="input-guru-tab" data-bs-toggle="tab" data-bs-target="#inputGuru" type="button" role="tab" aria-controls="list" aria-selected="false">Info Tagihan</button>
    </li>
    <li class="nav-item" role="presentation">
        <button (click)="changeTab('biaya-khusus')" class="nav-link" [class.active]="isBiayaKhusus" id="catatan-siswa-tab" data-bs-toggle="tab" data-bs-target="#catatanSiswa" type="button" role="tab" aria-controls="nilai" aria-selected="false">Biaya Khusus</button>
    </li>
    <li class="nav-item" role="presentation">
        <button (click)="changeTab('catatan')" class="nav-link" [class.active]="isCatatan" id="catatan-siswa-tab" data-bs-toggle="tab" data-bs-target="#catatanSiswa" type="button" role="tab" aria-controls="nilai" aria-selected="false">Catatan</button>
    </li>
    <li class="nav-item" role="presentation">
        <button (click)="changeTab('log')" class="nav-link" [class.active]="isLog" id="catatan-siswa-tab" data-bs-toggle="tab" data-bs-target="#catatanSiswa" type="button" role="tab" aria-controls="nilai" aria-selected="false">Log</button>
    </li>
</ul>

<div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="guru-input"
      role="tabpanel"
      aria-labelledby="guru-input-tab"
    >
      <div class="tabs-title">{{ currentTabTitle }}</div>
        
      <div class="list-options" *ngIf="isInfoTagihan">
        <div class="form-select-warapper">
            <form [formGroup]="formAction">
              <select formControlName="id_tahun_ajaran" (change)="changeTahunAjaran($event)" class="form-select" name="id_tahun_ajaran" id="id_tahun_ajaran">
                <option value="" selected>Semua Tahun</option>
                <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
              </select>
            </form>
        </div>
    
        <div class="form-select-warapper">
            <form [formGroup]="formAction">
                <select formControlName="tipe_tagihan" class="form-select" name="tipe_tagihan" id="tipe_tagihan">
                <option value="sisa" selected>Sisa Tagihan</option>                
                </select>
            </form>
        </div>                        
      </div>

      <div class="table-wrapper" *ngIf="isInfoTagihan">
        
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th class="align-middle">Group Biaya</th>                
                <th class="align-middle" *ngFor="let bln of listEfectiveMonth; let i=index">{{ bln.bln }}</th>
                <th class="align-middle">Jumlah</th>
                <th></th>
            </thead>
            <tbody>
              <tr *ngFor="let tagihan of listDataTagihan | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
                <td>{{ tagihan.groupBiayaName }}</td>
                <td *ngFor="let nominal of tagihan.listNominalBulan; let x=index">{{ nominal }}</td>
                <td>{{ tagihan.totalAmount }}</td>
              </tr>
              <tr *ngIf="listTotalBulan.length > 0" class="summary">
                <td>TOTAL TAGIHAN</td>
                <td *ngFor="let nominal2 of listTotalBulan; let y=index">{{ nominal2 }}</td>
                <td>{{ listGrandTotal }}</td>
              </tr>
              <tr class="warning">
                <td>
                  SUDAH MASUK TANGGAL DITAGIH
                </td>
                <td *ngFor="let nominal3 of listTotalBulanTagih; let y=index"><b>{{ nominal3 }}</b></td>
                <td><b>{{ listGrandTotalTagih }}</b></td>
              </tr>
              <tr *ngIf="listDataTagihan.length === 0" class="empty">
                <td colspan="16">Tidak terdapat data atau data tidak ditemukan.</td>
              </tr>
            </tbody>
          </table>
        </div>
      
        <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
          <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
            <div></div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center pagination-number">
                <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                  <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                    {{ page.label }}
                  </a>
                  <a class="page-link" *ngIf="p.getCurrent() === page.value">
                    {{ page.label }}
                  </a>
                </li>
              </ul>
            </nav>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="p.isFirstPage()">
                  <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                    <span>Previous</span>
                  </a>
                </li>
                <li class="page-item" [class.disabled]="p.isLastPage()">
                  <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                    <span>Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </pagination-template>
      
        <ng-template #emptyPagination>
          <div class="pagination-wrapper">
            <div>&nbsp;</div>
          </div>
        </ng-template>
      </div>

      <div class="list-options" *ngIf="isBiayaKhusus">
        <button class="btn btn-blue-grey" (click)="openModalFormBiayaKhusus(formModalGroupBiaya)">
          + Tambah
        </button>             
      </div>

      <div class="table-wrapper" *ngIf="isBiayaKhusus">
        
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>                
                <th>No</th>
                <th>Group Biaya</th>
                <th>Tagihan</th>
                <th>Aktif</th>
                <th>Item Biaya</th>                
                <th></th>
                
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of listBiayaKhusus | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
                <td>{{ i + 1 + (page - 1) * pageSize }}</td>                
                <td>{{ data.GroupBiaya.name }}</td>
                <td>{{ data.jumlah }}</td>
                <td>
                  <div class="form-switch">
                    <input class="form-check-input" 
                        type="checkbox"
                        (change)="updateStatus($event, data)" 
                        [checked]="data.active === true"
                        role="switch" id="flexSwitchCheckDefault">                
                  </div>
                </td>
                <td>
                  <div (click)="openModalItemBiaya(addItemBiayaKhususModal, data)" class="tagihan secondary">
                    + Item Biaya
                  </div>
                </td>
                
                <td class="settings">                  
                    <a
                      href="#"
                      role="button"
                      id="dropdownMenuLink{{ i }}"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src="./assets/images/icon-setting.svg" alt="" />
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">                      
                      <li><a (click)="confirmDelete(data.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                    </ul>
                </td>
              </tr>
              <tr *ngIf="listBiayaKhusus.length === 0" class="empty">
                <td [colSpan]="6">
                  Tidak terdapat data atau data tidak ditemukan.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      
        <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
          <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
            <div></div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center pagination-number">
                <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                  <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                    {{ page.label }}
                  </a>
                  <a class="page-link" *ngIf="p.getCurrent() === page.value">
                    {{ page.label }}
                  </a>
                </li>
              </ul>
            </nav>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="p.isFirstPage()">
                  <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                    <span>Previous</span>
                  </a>
                </li>
                <li class="page-item" [class.disabled]="p.isLastPage()">
                  <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                    <span>Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </pagination-template>
      
        <ng-template #emptyPagination>
          <div class="pagination-wrapper">
            <div>&nbsp;</div>
          </div>
        </ng-template>
      </div>

    </div>
</div>

<ng-template #formModalGroupBiaya let-modal>
  <div class="modal-body">
      <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
      <div class="modal-form">
          <h1 class="text-center">Tambah Group Biaya</h1>
          
          <form [formGroup]="formGroupBiaya" (ngSubmit)="onSubmitFormBiayaKhusus(modal)">            

            <div class="mb-3 mt-4 form-group row">
              <label for="kategori" class="col-sm-3 control-label">Group Biaya</label>
              <div class="col-sm-9">
                <select
                    formControlName="id_group_biaya"
                    [ngClass]="{ 'is-invalid': submitted && f['id_group_biaya'].errors }"
                    class="form-select highlight"
                  >
                    <option value="" selected disabled>Pilih</option>
                    <option [value]="data.id" *ngFor="let data of listGroupBiaya">{{data.name}}</option>
                </select>
              </div>
            </div>

            <button type="submit" class="btn btn-primary mt-3 my-btn-save">CREATE</button>
            
          </form>
      </div>
  </div>
</ng-template>

<ng-template #addItemBiayaKhususModal let-modal>
  <div class="modal-body">
      <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
      <div class="modal-form">
          <h1 class="text-center">Item Biaya</h1>
          
          <form [formGroup]="formAddItemBiayaKhusus" (ngSubmit)="onSubmitItemBiaya(modal)">            
            <div class="row">
              <div class="col-md-2 mb-3 mt-4">
                  
                  <label for="exampleInputEmail1" class="form-label">Item Biaya</label>
              </div>
              <div class="col-md-4 mb-3 mt-4">
                  <select 
                      formControlName="id_item_biaya"
                      [ngClass]="{ 'is-invalid': submitted && h['id_item_biaya'].errors }"
                      class="form-select">
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="data.id" *ngFor="let data of listItemBiaya">{{data.item_name}}</option>
                  </select>
                  <div *ngIf="submitted && h['id_item_biaya'].errors" class="invalid-feedback">
                      <div *ngIf="h['id_item_biaya'].errors['required']">Item Biaya is required</div>
                  </div>
              </div>
              <div class="col-md-3 mb-3 mt-4">
                  <input
                      type="text"
                      formControlName="jumlah"
                      placeholder="0"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && h['jumlah'].errors }"
                  /> 
                  <div *ngIf="submitted && h['jumlah'].errors" class="invalid-feedback">
                      <div *ngIf="h['jumlah'].errors['required']">Nominal is required</div>
                  </div>                   
              </div>
              <div class="col-md-3 mb-3 mt-4">
                <button type="submit" class="btn btn-primary mt-3 my-btn-save">Simpan</button>
              </div>
            </div>
            
          </form>

          <div class="row">
            <div class="table-responsive">
              <table class="table table-borderless">
                  <thead>
                      <tr>
                          <th>No.</th>
                          <th>Item Biaya</th>                  
                          <th>Nominal</th>
                          <th></th>
                      </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor=" let listItem of listItemBiayaKhusus let i = index">
                      <td>1</td>
                      <td>{{ listItem.ItemBiaya.item_name }}</td>
                      <td>{{ listItem.jumlah }}</td>
                      <td style="cursor: pointer;" (click)="confirmDeleteItemBiayaKhusus(listItem.id)">[ X ]</td>
                    </tr>
                  </tbody>
              </table>
            </div>  
          </div>
      </div>
  </div>
</ng-template>
