import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tugas-monitoring',
  templateUrl: './tugas-monitoring.component.html',
  styleUrls: ['./tugas-monitoring.component.css']
})
export class TugasMonitoringComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
