<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="content-item mb-5">
      
      <div class="item-header">
        Template Print Tagihan
      </div>
      
      <div class="card">
        <div class="card-header">
          Content
        </div>
        <div class="card-body">
            
                <div class="row">                    
                    <div class="col-12 col-lg-12">
                        <div class="row">
                          <div class="col-12 mb-3">
                            <angular-editor formControlName="content" [config]="config"></angular-editor>
                          </div>
                        </div>
                    </div>
                </div>
            
        </div>
      </div>
    
      <div class="row align-items-center">
          <div class="col-6 col-lg-4 offset-lg-6">
              
          </div>
          <div class="col-6 col-lg-2 d-flex justify-content-end">
              <button type="submit" class="btn btn-primary">Save</button>
          </div>
      </div>
      <div class="mt-4">
        <ul>
          <li>Nama siswa : #namaSiswa#</li>
          <li>Nomor Induk : #nomorInduk#</li>
          <li>Nama Kelas : #namaKelas#</li>
          <li>Nama Kamar : #namaKamar$</li>
          <li>Nama Program : #namaProgram#</li>
          
          </ul>
      </div>
    </div>
  </form>
  