import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';
import { environment } from 'src/environments/environment';
import { HttpEvent } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private netService: NetworkService) { }

  upload(file: File, tipe: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    return this.netService.fileRequest(formData, `/v1/upload?type=${tipe}`);

  }

  removePhotoStaff(name: string): Observable<any> {
    console.log('Delete the file', name);
    return this.netService.getRequest('/v1/removestafffile/' + name);
  }

  removePhotoBrt(name: string): Observable<any> {
    console.log('Delete the file', name);
    return this.netService.getRequest('/v1/removebrtfile/' + name);
  }  

  removeDokumenFile(name: string): Observable<any> {
    console.log('Delete the file', name);
    return this.netService.getRequest('/v1/removedokumenfile/' + name);
  }  

  getImageUrl(f: string) {
    const domain = this.netService.getCurrentDomain().toLowerCase();
    return `${environment.imageUrl}/${domain}/${f}`; 
  }

  getDomainImageUrl() {
    const domain = this.netService.getCurrentDomain().toLowerCase();
    return `${environment.imageUrl}/${domain}`; 
  }
}
