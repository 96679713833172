<div class="contentfull" *ngIf="selectedSiswa != null">
  <a href="javascript:void(0)" class="closebtn" (click)="closeButton()">&times;</a>

  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li *ngFor="let tab of listTabs" class="nav-item" role="presentation">
      <button
        [class]="tabSelected == tab.id ? 'nav-link active' : 'nav-link'"
        [id]="tab.id + '-tab'"
        role="tab"
        type="button"
        data-bs-toggle="tab"
        [attr.data-bs-target]="'#' + tab.id"
        [attr.aria-controls]="tab.id"
        [attr.aria-selected]="tabSelected == tab.id"
        (click)="onClickTab(tab)"
      >
        {{ tab.name }}
      </button>
    </li>
  </ul>
  <div class="tab-content" id="myDetailTabContent">
    <div
      [class]="tabSelected == 'biodata' ? 'tab-pane fade show active' : 'tab-pane fade'"
      id="biodata"
      role="tabpanel"
      aria-labelledby="biodata-tab"
    >
      <div class="tabs-title">
        {{ selectedSiswa.nama_lengkap }}
      </div>
      <app-siswa-detail-biodata [selectedData]="selectedSiswa"></app-siswa-detail-biodata>
    </div>
    <div
      [class]="tabSelected == 'kesehatan' ? 'tab-pane fade show active' : 'tab-pane fade'"
      id="kesehatan"
      role="tabpanel"
      aria-labelledby="kesehatan-tab"
    >
      <div class="tabs-title">Kesehatan</div>
      <app-siswa-detail-kesehatan [selectedData]="selectedSiswa"></app-siswa-detail-kesehatan>
    </div>
    <div
      [class]="tabSelected == 'pelanggaran' ? 'tab-pane fade show active' : 'tab-pane fade'"
      id="pelanggaran"
      role="tabpanel"
      aria-labelledby="pelanggaran-tab"
    >
      <div class="tabs-title">Pelanggaran</div>
      <app-siswa-detail-pelanggaran [listData]="listPelanggaran"></app-siswa-detail-pelanggaran>
    </div>
    <div
      [class]="tabSelected == 'kamar' ? 'tab-pane fade show active' : 'tab-pane fade'"
      id="kamar"
      role="tabpanel"
      aria-labelledby="kamar-tab"
    >
      <div class="tabs-title">Kamar</div>
      <app-siswa-detail-kamar [listData]="listKamar"></app-siswa-detail-kamar>
    </div>
    <div
      [class]="tabSelected == 'absen-kamar' ? 'tab-pane fade show active' : 'tab-pane fade'"
      id="absen-kamar"
      role="tabpanel"
      aria-labelledby="absen-kamar-tab"
    >
      <div class="tabs-title">Absen Kamar</div>
      <app-siswa-detail-absen-kamar [listData]="listAbsenKamar"></app-siswa-detail-absen-kamar>
    </div>
    <div
      [class]="tabSelected == 'izin' ? 'tab-pane fade show active' : 'tab-pane fade'"
      id="izin"
      role="tabpanel"
      aria-labelledby="izin-tab"
    >
      <div class="tabs-title">Izin Siswa</div>
      <app-siswa-detail-izin [listData]="listIzinSiswa"></app-siswa-detail-izin>
    </div>
    <div
      [class]="tabSelected == 'transaksi' ? 'tab-pane fade show active' : 'tab-pane fade'"
      id="transaksi"
      role="tabpanel"
      aria-labelledby="transaksi-tab"
    >
      <div class="tabs-title">Transaksi Keuangan</div>
      <app-siswa-detail-transaksi></app-siswa-detail-transaksi>
    </div>
    <div
      [class]="tabSelected == 'deposit' ? 'tab-pane fade show active' : 'tab-pane fade'"
      id="deposit"
      role="tabpanel"
      aria-labelledby="deposit-tab"
    >
      <div class="tabs-title">Mutasi Deposit</div>
      <app-siswa-detail-deposit [listData]="listDeposit"></app-siswa-detail-deposit>
    </div>
    <div
      [class]="tabSelected == 'tagihan' ? 'tab-pane fade show active' : 'tab-pane fade'"
      class="tab-pane fade"
      id="tagihan"
      role="tabpanel"
      aria-labelledby="tagihan-tab"
    >
      <div class="tabs-title">Tagihan Belum Dibayar</div>
      <app-siswa-detail-tagihan [listData]=""></app-siswa-detail-tagihan>
    </div>
    <div
      [class]="tabSelected == 'kelas' ? 'tab-pane fade show active' : 'tab-pane fade'"
      class="tab-pane fade"
      id="kelas"
      role="tabpanel"
      aria-labelledby="kelas-tab"
    >
      <div class="tabs-title">Kelas</div>
      <app-siswa-detail-kelas [listData]="listKelas"></app-siswa-detail-kelas>
    </div>
    <div
      [class]="tabSelected == 'prestasi' ? 'tab-pane fade show active' : 'tab-pane fade'"
      class="tab-pane fade"
      id="prestasi"
      role="tabpanel"
      aria-labelledby="prestasi-tab"
    >
      <div class="tabs-title">Prestasi</div>
      <app-siswa-detail-prestasi [listData]="listPrestasi"></app-siswa-detail-prestasi>
    </div>
    <div
      [class]="tabSelected == 'catatan-guru' ? 'tab-pane fade show active' : 'tab-pane fade'"
      id="catatan-guru"
      role="tabpanel"
      aria-labelledby="catatan-guru-tab"
    >
      <div class="tabs-title">Catatan Guru</div>
      <app-siswa-detail-catatan-guru [listData]="listCatatanGuru"></app-siswa-detail-catatan-guru>
    </div>
    <div
      [class]="tabSelected == 'log' ? 'tab-pane fade show active' : 'tab-pane fade'"
      class="tab-pane fade"
      id="log"
      role="tabpanel"
      aria-labelledby="log-tab"
    >
      <div class="tabs-title">Log</div>
      <app-siswa-detail-log [listData]="listLog"></app-siswa-detail-log>
    </div>
    <div
      [class]="tabSelected == 'log-va' ? 'tab-pane fade show active' : 'tab-pane fade'"
      id="log-va"
      role="tabpanel"
      aria-labelledby="log-va-tab"
    >
      <div class="tabs-title">Log VA</div>
      <app-siswa-detail-log-va [listData]="listLogVa"></app-siswa-detail-log-va>
    </div>
  </div>
</div>
