
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="lembaga-list" role="tabpanel" aria-labelledby="list-tab">
      <div class="tabs-title">
          LIST GROUP BIAYA
      </div>
      <div class="list-options">
          
          <div class="search">
              <input type="text" class="form-control" placeholder="Search.." name="search-list" id="search-list" [(ngModel)]="nama">
              <div class="icon" (click)="search()">
                  <img src="./assets/images/icon-search.png" alt="Search">
              </div>
          </div>
          
          <a (click)="openModal(addGroupBiaya)" class="btn btn-blue-grey">
              + Group Biaya
          </a>
      </div>
      <div class="table-wrapper">
          <div class="table-options">
              <div class="show-table">
                  Show <select class="form-control" name="show" id="show" (change)="handlePageSizeChange($event)">
                      <option *ngFor="let size of pageSizes" [ngValue]="size">
                        {{ size }}
                      </option>
                  </select> entries
                  <!-- Show<input type="number" class="form-control" name="show" id="show" value="10">entries -->
              </div>
          </div>
          <div class="table-responsive">
              <table class="table table-borderless">
                  <thead>
                      <tr>
                          <th>No.</th>
                          <th>Group Biaya</th>
                          <th>Nomor Akum</th>
                          <th></th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody *ngFor="
                  let groupbiaya of listGroupBiaya.data | paginate : {
                          id: pageid,
                          itemsPerPage: pageSize,
                          currentPage: page,
                          totalItems: count
                      };
                  let i = index
                  ">
                      <tr>
                          <td>{{ i + 1 + ((page - 1) * pageSize) }}</td>
                          <td>{{ groupbiaya.name }}</td>
                          <td>{{ groupbiaya.akun.account_code }}</td>
                          <td>
                            <div (click)="openModalItemBiaya(addItemBiaya, groupbiaya)" class="tagihan secondary">
                              + Item Biaya
                            </div>
                          </td>
                          <td class="settings">
                              <div class="dropend">
                                  <a href="#" role="button" id="dropdownMenuLink"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      <img src="./assets/images/icon-setting.svg" alt="">
                                  </a>
                                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                      <li><a (click)="openModalEdit(addGroupBiaya, groupbiaya)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>                            
                                      <li><a (click)="confirmDelete(groupbiaya.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                                  </ul>
                              </div>
                          </td>
                          
                      </tr>
                  </tbody>
              </table>
          </div>

          <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
              <div class="pagination-wrapper">
                  <div></div>
                  <nav aria-label="Page navigation">
                      <ul class="pagination justify-content-center pagination-number">
                          <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                              <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ page.label }}</a>
                              
                              <a class="page-link" *ngIf="p.getCurrent() === page.value">{{ page.label }}</a>

                          </li>
                      </ul>
                  </nav>
                  <nav aria-label="Page navigation">
                      <ul class="pagination justify-content-center">
                          <li class="page-item" [class.disabled]="p.isFirstPage()">
                              <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                                  <span>Previous</span>
                              </a>
                          </li>
                          <li class="page-item" [class.disabled]="p.isLastPage()">
                              <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                                  <span>Next</span>
                              </a>
                          </li>
                      </ul>
                  </nav>
              </div>
          </pagination-template>

      </div>

      
  </div>
  
</div>


<ng-template #addGroupBiaya let-modal>
  <div class="modal-body">
      <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
      <div class="modal-form">
          <h1 class="text-center" *ngIf="isNew">Tambah Group Biaya</h1>
          <h1 class="text-center" *ngIf="!isNew">Edit Group Biaya</h1>
          <form [formGroup]="formAddGroupBiaya" (ngSubmit)="onSubmitGroupBiaya()">
              <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">Group Biaya</label>
                  <input
                      type="text"
                      formControlName="name"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f['name'].errors }"
                  />
                  <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                      <div *ngIf="f['name'].errors['required']">Group Biaya is required</div>
                  </div>
              </div>
              <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">Nomor Akun</label>
                  <select 
                      formControlName="id_akun"
                      [ngClass]="{ 'is-invalid': submitted && f['id_akun'].errors }"
                      class="form-select">
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="data.id" *ngFor="let data of listChartMaster.data">{{data.account_code}} - {{data.account_name}}</option>
                  </select>
                  <div *ngIf="submitted && f['id_akun'].errors" class="invalid-feedback">
                      <div *ngIf="f['id_akun'].errors['required']">Nomor Akun is required</div>
                  </div>
              </div>
              <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isNew">Add</button>
              <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="!isNew">Update</button>
          </form>
      </div>
  </div>
</ng-template>

<ng-template #addItemBiaya let-modal>
  <div class="modal-body">
      <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
      <div class="modal-form">
          <h1 class="text-center">Tambah Item Biaya</h1>
          
          <form [formGroup]="formAddItemGroupBiaya" (ngSubmit)="onSubmitItemGroupBiaya()">
            <div class="row">
              <div class="col-md-6 mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">Group Biaya</label>
                  <input
                      readonly
                      [disabled]="true"
                      type="text"
                      formControlName="group_name"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && g['group_name'].errors }"
                  />
                  <div *ngIf="submitted && g['group_name'].errors" class="invalid-feedback">
                      <div *ngIf="g['group_name'].errors['required']">Group Biaya is required</div>
                  </div>
              </div>
            </div>
            <div class="row">

              <div class="col-md-6 mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">Item Biaya</label>
                  <select 
                      formControlName="id_item_biaya"
                      [ngClass]="{ 'is-invalid': submitted && g['id_item_biaya'].errors }"
                      class="form-select">
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="data.id" *ngFor="let data of listItemBiaya.data">{{data.item_name}}</option>
                  </select>
                  <div *ngIf="submitted && g['id_item_biaya'].errors" class="invalid-feedback">
                      <div *ngIf="g['id_item_biaya'].errors['required']">Item Biaya is required</div>
                  </div>
              </div>
              <div class="col-md-4 mb-3 mt-4">
                <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isNew">Tambah Item Biaya</button>
              </div>
            </div>
            
          </form>

          <div class="row">
            <div class="table-responsive">
              <table class="table table-borderless">
                  <thead>
                      <tr>
                          <th>No.</th>
                          <th>Item Biaya</th>                  
                          <th></th>
                      </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor=" let listItem of listGroupBiaya.biayaitems let i = index">
                      <td>1</td>
                      <td>{{ listItem.item_name }}</td>
                      <td style="cursor: pointer;" (click)="confirmDeleteItemGroup(listItem.id, listGroupBiaya.id)">[ X ]</td>
                    </tr>
                  </tbody>
              </table>
            </div>  
          </div>
      </div>
  </div>
</ng-template>