import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class TahunAjaranService {

  constructor(private netService: NetworkService) { }

  getAllTahunAjaran(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/tahunajaran', params);
  }

  getTahunAjaranActive(): Observable<any> {
    return this.netService.getRequest('/v1/tahunajaran/active');
  }
}
