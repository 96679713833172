import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { RayonService } from 'src/app/services/rayon.service';
import { SdmService } from 'src/app/services/sdm.service';
import { KamarService } from 'src/app/services/kamar.service';
import { UiService } from 'src/app/helper/ui.service';
import { Router } from '@angular/router';
import { BrtService } from 'src/app/services/brt.service';

@Component({
  selector: 'app-kategori-alat',
  templateUrl: './kategori-alat.component.html',
  styleUrls: ['./kategori-alat.component.css']
})
export class KategoriAlatComponent implements OnInit {

  isNew = true;
  currentIdEdit = "";
  errorMessage = '';
  submitted = false;
  isShowLoader: Boolean = false;
  isShowLoaderPenguji: Boolean = false;
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  pageid = "kamar";
  searchMusyrifahTerm = "";

  listRayon: any = [];
  listKategoriAlat: any = [];
  listNilaiMataUji: any = [];
  listHasilPpsb: any = [];
  listMusyrifah: any = [];
  listSiswa: any = [];
  listGelombang: any = [];

  formAddKategori: FormGroup = new FormGroup({
    nama: new FormControl(''),    
    active: new FormControl(''),
  });

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private rayonService: RayonService,
    private sdmService: SdmService,
    private kamarService: KamarService,
    private brtService: BrtService,
    private router: Router,
    private uiService: UiService
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {    
    this.initForm();
    this.getAllKategoriAlat();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {
    this.formAddKategori = this.formBuilder.group(
      {
        nama: ['', Validators.required],        
        active: ['t'],
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAddKategori.controls;
  }

  openFormKategoriModal(content: any) {  
    this.isNew = true;  
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' });
  }

  openEditFormKamarModal(content: any, kamar: any) {
    console.log(kamar);
    this.currentIdEdit = kamar.id;
    
    this.formAddKategori.patchValue(kamar);
    const isActive = kamar.active ? 't' : 'f';
    this.formAddKategori.controls['active'].setValue(isActive);    

    this.isNew = false;
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' });
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  getAllKategoriAlat() {
    this.uiService.showLoader();

    const params = this.getRequestParams("", this.page, this.pageSize);

    this.brtService.getListKategoriAlat(params).subscribe({
      next: resdata => {
        console.log('Kategori ALat', resdata);        

        //this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listKategoriAlat = resdata;        
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onSubmitKategori(modal: any): void {

    console.log(JSON.stringify(this.formAddKategori.value, null, 2));
    
    this.submitted = true;
    
    if (this.formAddKategori.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    } else {
      modal.dismiss('Cross click');
    }

    this.uiService.showLoader();

    if (this.isNew) {
      this.brtService.addkategorialat(this.formAddKategori.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.formAddKategori.reset();
          this.submitted = false;
          this.initForm();

          this.getAllKategoriAlat();
          
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          this.router.navigate(['/master/kamar']);
  
        },
        error: err => {
          console.log(err);
          this.formAddKategori.reset();
          this.submitted = false;
          this.initForm();
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    } else {
      this.kamarService.update(this.currentIdEdit, this.formAddKategori.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.formAddKategori.reset();
          this.submitted = false;
          this.initForm();

          this.getAllKategoriAlat();
          
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          this.router.navigate(['/master/kamar']);
  
        },
        error: err => {
          console.log(err);
          this.formAddKategori.reset();
          this.submitted = false;
          this.initForm();
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }        

  }

  searchSiswa(): void {
    // this.searchSiswaTerm = this.formAddKategori.value.nama;

    // this.listSiswa = [];
    // if (this.searchSiswaTerm != "") {
    //   this.isShowLoader = true;
    //   const params = this.getRequestParams(this.searchSiswaTerm, this.page, this.pageSize);

    //   console.log("PARAMS", params);
      
  
    //   this.siswaService.getAllSiswaBaru(params).subscribe({
    //     next: resdata => {
    //       console.log(resdata);
    //       const { data, totalrows } = resdata;
  
    //       this.isShowPagination == totalrows > this.pageSize ? true : false;
    //       this.listSiswa = data;
    //       this.count = totalrows;
  
    //       console.log("SISWA", this.listSiswa);
  
    //       console.log('COUNT', this.count);
  
  
    //       this.isShowLoader = false;
    //       //this.uiService.hideLoader();
  
    //     },
    //     error: err => {
    //       this.isShowLoader = false;
    //       this.errorMessage = err.error.message;
    //       console.log(this.errorMessage);
  
    //       //this.uiService.hideLoader();
  
    //       //this.uiService.showError(this.errorMessage);
    //     }
    //   });
    // }
  }

  chooseSiswa(siswa: any) {
    // this.listSiswa = [];
    // console.log(siswa);
    // this.formAddKategori.controls['id_siswa'].setValue(siswa.id);
    // this.formAddKategori.controls['nama'].setValue(siswa.nama_lengkap);    
  }

  chooseMusyrifah(data: any) {
    this.listMusyrifah = [];
    console.log(data);
    this.formAddKategori.controls['musyrifah_name'].setValue(data.userProfile.real_name);
    this.formAddKategori.controls['id_musyrifah'].setValue(data.userProfile.id_user);
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  searchMusyrifah(): void {
    this.searchMusyrifahTerm = this.formAddKategori.value.musyrifah_name;

    this.listMusyrifah = [];
    if (this.searchMusyrifahTerm != "") {
      this.isShowLoaderPenguji = true;
      const params = this.getRequestParams(this.searchMusyrifahTerm, this.page, this.pageSize);

      console.log("PARAMS", params);
      
      this.sdmService.getAll(params).subscribe({
        next: resdata => {
          console.log(resdata);
          const { data, totalrows } = resdata;
  
          this.isShowPagination == totalrows > this.pageSize ? true : false;
          this.listMusyrifah = data;
          this.count = totalrows;
  
          console.log('COUNT', this.count);
          this.isShowLoaderPenguji = false;
        
          this.uiService.hideLoader();
  
        },
        error: err => {
          this.isShowLoaderPenguji = false;
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  changeStatus(e: any) {
    console.log(e.target.value);
    this.formAddKategori.controls['active'].setValue(e.target.value);
  }

  changeKategori(e: any) {
    console.log(e.target.value);
    this.formAddKategori.controls['kategori'].setValue(e.target.value);
  }

  changeTempatTidur(e: any) {
    console.log(e.target.value);
    this.formAddKategori.controls['tempat_tidur'].setValue(e.target.value);
  }

  confirmDelete(id: String) {
    this.uiService.showConfirmDelete(this, id);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.kamarService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getAllKategoriAlat();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

}
