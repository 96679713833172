<div class="contentfull">
    <a href="javascript:void(0)" class="closebtn" (click)="closeButton()">&times;</a>
    
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="statistik"
        role="tabpanel"
        aria-labelledby="statistik-tab"
      >
    
        
        <div class="list-options">
          <hr />
          <!-- <button type="button" class="btn btn-warning" (click)="closeButton()">
            Close
          </button> -->
        </div>
  
        <div style="text-align: center;">
  
          <h3>Tanggal : {{ tanggal }}, Kamar : {{ kamar }}</h3>
          <h3>Di absen oleh : <b>{{ currentNamaGuru }}</b></h3>
          
  
        </div>
        
        <div class="table-wrapper">
          <div class="table-options">
    
          </div>
          <div class="table-responsive">
            <table class="table">
                <thead class="bordered">
                    <tr class="below">
                        <th>#</th>
                        <th>Nama</th>                        
                        <th>Absen</th>
                    </tr>
                    
                </thead>
                <tbody>
                    <tr *ngFor="let key of listDetailAbsen let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ key.nama_lengkap }}</td>                        
                        <td>{{ key.absen }}</td>               
                    </tr>
                    
                </tbody>
            </table>
  
          </div>
        </div>
      </div>
    </div>
  </div>