<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button routerLink="/sdm/izin-mengajar/input" class="nav-link" [class.active]="isInput" id="input-tab" data-bs-toggle="tab" data-bs-target="#input" type="button" role="tab" aria-controls="input" aria-selected="false">List</button>
  </li>
  <li class="nav-item" role="presentation">
    <button routerLink="/sdm/izin-mengajar/laporan" class="nav-link" [class.active]="isLaporan" id="laporan-tab" data-bs-toggle="tab" data-bs-target="#laporan" type="button" role="tab" aria-controls="laporan" aria-selected="false">Setup Jabatan</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane" [class.active]="isInput" id="input" role="tabpanel" aria-labelledby="input-tab">
    <div class="tabs-title">Data Guru -> Izin Mengajar</div>
  </div>
  <div class="tab-pane" [class.active]="isLaporan" id="laporan" role="tabpanel" aria-labelledby="laporan-tab">
    <div class="tabs-title">Laporan Izin Mengajar</div>
  </div>
</div>
<router-outlet></router-outlet>
