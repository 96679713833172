<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <div class="content-item mb-5">
    <div class="back-button" (click)="onBack()">
      <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
    </div>
    <div class="item-header" *ngIf="isAddMode">
      Tambah Profil Siswa
    </div>
    <div class="item-header" *ngIf="!isAddMode">
      Edit Profil Siswa
    </div>
    <div class="card">
        <div class="card-header">
            DATA CALON SISWA
        </div>
        <div class="card-body">
            
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <div class="row">
                            <div class="col-6 col-lg-5 mb-3">
                                <label for="name" class="form-label">Nama Lengkap</label>
                                
                                <input
                                  type="text"
                                  formControlName="nama_lengkap"
                                  class="form-control highlight"
                                  [ngClass]="{ 'is-invalid': submitted && f['nama_lengkap'].errors }"
                                  placeholder=""
                                />
                            </div>
                            <div class="col-6 col-lg-4 mb-3">
                                <label for="name" class="form-label">Nama (Arab)</label>
                                <input
                                  type="text"
                                  formControlName="nama_arab"
                                  class="form-control"
                                  placeholder=""
                                />
                            </div>
                            <div class="col-12 col-lg-3 mb-3">
                                <label for="gender" class="form-label highlight">Jenis Kelamin</label>
                                <select
                                    formControlName="gender"
                                    [ngClass]="{ 'is-invalid': submitted && f['gender'].errors }"
                                    class="form-select highlight">
                                    <option value="" selected disabled>Pilih</option>
                                    <option value="L">Laki-laki</option>
                                    <option value="P">Perempuan</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 mb-3">
                                <label for="name" class="form-label ">Nama Ayah</label>
                                <input
                                  type="text"
                                  formControlName="nama_ayah"
                                  class="form-control highlight"
                                  [ngClass]="{ 'is-invalid': submitted && f['nama_ayah'].errors }"
                                  placeholder=""
                                />
                            </div>
                            <div class="col-6 mb-3">
                                <label for="name" class="form-label ">Nama Ibu</label>
                                <input
                                  type="text"
                                  formControlName="nama_ibu"
                                  class="form-control highlight"
                                  [ngClass]="{ 'is-invalid': submitted && f['nama_ibu'].errors }"
                                  placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-lg-4 mb-3">
                                <label for="tahun" class="form-label ">Tahun Masuk</label>
                                <select
                                    formControlName="tahun_masuk"
                                    [ngClass]="{ 'is-invalid': submitted && f['tahun_masuk'].errors }"
                                    class="form-select highlight">
                                    <option value="" selected disabled>Pilih</option>
                                    <option 
                                      [value]="data.name"
                                      [selected]="data.active === true"
                                      *ngFor="let data of listsTahunAjaran">{{data.name}}</option>
                                </select>
                            </div>
                            <div class="col-6 col-lg-5 mb-3">
                                <label for="lembaga" class="form-label ">Lembaga Tujuan</label>
                                <select
                                    formControlName="lembaga_tujuan"
                                    [ngClass]="{ 'is-invalid': submitted && f['lembaga_tujuan'].errors }"
                                    class="form-select highlight"
                                    (change)="changeLembaga($event)">
                                    <option value="" selected disabled>Pilih</option>
                                    <option [value]="data.id" *ngFor="let data of listsLembaga">{{data.nama_lembaga}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-lg-3 mb-3">
                                <label for="tingkatan" class="form-label ">Tingkatan</label>
                                <select
                                    formControlName="tingkat_id"
                                    [ngClass]="{ 'is-invalid': submitted && f['tingkat_id'].errors }"
                                    class="form-select highlight">
                                    <option value="" selected disabled>Pilih</option>
                                    <option [value]="data.id" *ngFor="let data of listsTingkat">{{data.nama_tingkat}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-8 mb-3">
                                <label for="nik" class="form-label">Nomor Induk Kependudukan (NIK)</label>
                                <input type="text" class="form-control" formControlName="nik" id="nik" placeholder="">
                            </div>
                            <div class="col-6 col-lg-4 mb-3">
                                <label for="ukuran" class="form-label ">Ukuran Baju</label>
                                <select
                                    formControlName="ukuran_baju"
                                    [ngClass]="{ 'is-invalid': submitted && f['ukuran_baju'].errors }"
                                    class="form-select highlight">
                                    <option value="" selected disabled>Pilih</option>
                                    <option value="S">S</option>
                                    <option value="M">M</option>
                                    <option value="L">L</option>
                                    <option value="XL">XL</option>
                                    <option value="XXL">XXL</option>
                                </select>
                            </div>
                            <div class="col-6 col-lg-5 mb-3">
                                <label for="username" class="form-label">Username</label>
                                <input
                                  type="text"
                                  formControlName="username"
                                  class="form-control"
                                  placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-5">
                        <div class="row">
                            <div class="col-6 mb-3">
                                <label for="birthplace" class="form-label ">Tempat Lahir</label>
                                <input
                                  type="text"
                                  formControlName="pob"
                                  class="form-control highlight"
                                  [ngClass]="{ 'is-invalid': submitted && f['pob'].errors }"
                                  placeholder=""
                                />
                            </div>
                            <div class="col-6 mb-3">
                                <label for="birthdate" class="form-label">Tanggal Lahir</label>
                                <input
                                  type="text"
                                  autocomplete="off"
                                  id="birtdhdate"
                                  (blur)="changeDobField($event)"                                  
                                  formControlName="dob"
                                  class="form-control highlight"
                                  [ngClass]="{ 'is-invalid': submitted && f['dob'].errors }"
                                  placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <label for="address" class="form-label">Alamat (Jalan - Kampung - Nomor
                                    Rumah)</label>
                                <textarea type="text"
                                    formControlName="alamat"
                                    [ngClass]="{ 'is-invalid': submitted && f['alamat'].errors }"
                                    class="form-control highlight" name="address"
                                    id="address" placeholder=""></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 mb-3">
                                <label for="province" class="form-label">Provinsi</label>
                                <select 
                                    formControlName="provinsi"
                                    [ngClass]="{ 'is-invalid': submitted && f['provinsi'].errors }"
                                    class="form-select highlight"
                                    (change)="changeKabupaten($event)">
                                    <option value="" selected disabled>Pilih</option>
                                    <option [value]="data.provinsi" *ngFor="let data of listsProvinsi">{{data.provinsi}}</option>
                                </select>
                            </div>
                            <div class="col-6 mb-3">
                                <label for="city" class="form-label">Kota</label>
                                <select 
                                    formControlName="kota_kab"
                                    [ngClass]="{ 'is-invalid': submitted && f['kota_kab'].errors }"
                                    class="form-select highlight"
                                    (change)="changeKecamatan($event)">
                                    <option value="" selected disabled>Pilih</option>
                                    <option [value]="data.kabupaten" *ngFor="let data of listsKabupaten">{{data.kabupaten}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-7">
                                <div class="row">
                                    <div class="col-6 mb-3">
                                        <label for="district" class="form-label">Kecamatan</label>
                                        <select 
                                            formControlName="kecamatan"
                                            [ngClass]="{ 'is-invalid': submitted && f['kecamatan'].errors }"
                                            class="form-select highlight"
                                            (change)="changeKelurahan($event)">
                                            <option value="" selected disabled>Pilih</option>
                                            <option [value]="data.kecamatan" *ngFor="let data of listsKecamatan">{{data.kecamatan}}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 mb-3">
                                        <label for="subdistrict" class="form-label">Desa/Kelurahan</label>
                                        <select 
                                            formControlName="desa_kelurahan"
                                            [ngClass]="{ 'is-invalid': submitted && f['desa_kelurahan'].errors }"
                                            class="form-select highlight"
                                            >
                                            <option value="" selected disabled>Pilih</option>
                                            <option [value]="data.kelurahan" *ngFor="let data of listsKelurahan">{{data.kelurahan}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-5">
                                <div class="row">
                                    <div class="col-6 mb-3">
                                        <label for="rt" class="form-label">RT</label>
                                        <input
                                          type="text"
                                          formControlName="rt"
                                          class="form-control highlight"
                                          [ngClass]="{ 'is-invalid': submitted && f['rt'].errors }"
                                          placeholder=""
                                        />
                                    </div>
                                    <div class="col-6 mb-3">
                                        <label for="rw" class="form-label">RW</label>
                                        <input
                                          type="text"
                                          formControlName="rw"
                                          class="form-control highlight"
                                          [ngClass]="{ 'is-invalid': submitted && f['rw'].errors }"
                                          placeholder=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-7 mb-3">
                                <label for="email" class="form-label">Email</label>
                                <input type="email" class="form-control" formControlName="email" id="email"
                                    placeholder="">
                            </div>
                            <div class="col-5 mb-3">
                                <label for="tel" class="form-label">Nomor Telepon</label>
                                <input
                                  type="text"
                                  formControlName="telepon"
                                  class="form-control highlight"
                                  [ngClass]="{ 'is-invalid': submitted && f['telepon'].errors }"
                                  placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 mb-3">
                                <label for="ujian-psb" class="form-label">Tempat Ujian PSB</label>
                                <select
                                    formControlName="lokasi_ujian"
                                    [ngClass]="{'is-invalid': submitted && f['lokasi_ujian'].errors}"
                                    class="form-select rounded-3 py-2"
                                    style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                                    >
                                    <option value="" selected disabled>Pilih</option>
                                    <option [value]="dataLokasi.nama_lokasi" *ngFor="let dataLokasi of listLokasiUjian">
                                        {{ dataLokasi.nama_lokasi }}
                                    </option>
                                
                                </select>
                            </div>
                            <div class="col-6 mb-3">
                                <label for="tgl-ujian" class="form-label">Tanggal Ujian</label>                
                                <select
                                    formControlName="tanggal_ujian"
                                    [ngClass]="{'is-invalid': submitted && f['tanggal_ujian'].errors}"
                                    class="form-select rounded-3 py-2"
                                    style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                                    >
                                    <option value="" selected disabled>Pilih</option>
                                    <option [value]="dataTgl" *ngFor="let dataTgl of listTanggalUjian">
                                        {{ dataTgl }}
                                    </option>
                                
                                </select>
                            </div>
  
                            <script>
                                $(function () {
                                    $("#tgl-ujian").datepicker();
                                });
                            </script>
                        </div>
                    </div>
                </div>
            
        </div>
    </div>
    <div class="card" *ngIf="showFullForm">
        <div class="card-header">
            DATA SISWA TAMBAHAN
        </div>
        <div class="card-body">
            
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <label for="saudara_kandung_aktif" class="form-label">Saudara Kandung (Yang Masih Aktif Di
                                    Sekolah)</label>
                                <input type="text" class="form-control" formControlName="saudara_kandung_aktif" id="saudara_kandung_aktif"
                                    placeholder="">
                            </div>
                            <!-- <div class="col-12 mb-3">
                                <label for="nis" class="form-label">Nomor Induk Sekolah</label>
                                <input type="text" class="form-control" formControlName="nis" id="nis" placeholder="">
                            </div> -->
                        </div>
                    </div>
                    <div class="col-12 col-lg-5">
                        <div class="row">
                            <div class="col-5 mb-3">
                                <label for="anak" class="form-label">Anak Ke-</label>
                                <input type="text" class="form-control" formControlName="anak_ke" id="anak_ke"
                                    placeholder="">
                            </div>
                            <div class="col-7 mb-3">
                                <label for="bersaudara" class="form-label">Dari Berapa Bersaudara</label>
                                <input type="text" class="form-control" formControlName="jumlah_anak" id="jumlah_anak"
                                    placeholder="">
                            </div>
                        </div>                      
                    </div>
                </div>
            
        </div>
    </div>
  
    <div class="card" *ngIf="showFullForm">
      <div class="card-header">
        DATA ASAL SEKOLAH
      </div>
      <div class="card-body">
          
              <div class="row">
                  <div class="col-12 col-lg-7">
                      <div class="row">
                          <div class="col-12 mb-3">
                              <label for="saudara" class="form-label">Nama Sekolah</label>
                              <input type="text" class="form-control" formControlName="nama_sekolah_asal" id="nama_sekolah_asal"
                                  placeholder="">
                          </div>
                          <div class="col-12 mb-3">
                              <label for="nis" class="form-label">Asal Sekolah</label>
                              <select
                                formControlName="asal_sekolah"
                                class="form-select rounded-3 py-2"
                                [ngClass]="{'is-invalid': submitted && f['asal_sekolah'].errors}"
                                style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                                >
                                <option value="" selected disabled>Pilih</option>
                                <option value="MI">MI</option>
                                <option value="SD">SD</option>
                                <option value="MTS">MTs</option>
                                <option value="SMP">SMP</option>
                                <option value="MA/SMA">MA/SMA</option>
                                </select>
                          </div>
                      </div>
                      <!-- <div class="row">
                        <div class="col-12 mb-3">
                            <label for="alamat_sekolah_asal" class="form-label">Alamat Sekolah</label>
                            <textarea class="form-control" formControlName="alamat_sekolah_asal" id="alamat_sekolah_asal" cols="10"
                                rows="3"></textarea>
                        </div>
                      </div> -->
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="nss" class="form-label">Nomor Induk Sekolah</label>
                          <input type="text" class="form-control" formControlName="nss" id="nss"
                              placeholder="">
                        </div>
                      </div>
                  </div>
                  <div class="col-12 col-lg-5">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="no_ijazah" class="form-label">No. Ijazah</label>
                          <input type="text" class="form-control" formControlName="no_ijazah" id="no_ijazah"
                              placeholder="">
                        </div>
                      </div>
                      <!-- <div class="row">
                        <div class="col-12 mb-3">
                          <label for="nss" class="form-label">Nomor Induk Sekolah</label>
                          <input type="text" class="form-control" formControlName="nss" id="nss"
                              placeholder="">
                        </div>
                      </div> -->
                      <div class="row">
                          <div class="col-5 mb-3">
                              <label for="tahun_lulus" class="form-label">Tahun Lulus</label>
                              <select formControlName="tahun_lulus" class="form-select" name="tahun_lulus" id="tahun_lulus">
                                <option value="" selected>Pilih Tahun Lulus</option>
                                <option [value]="tahun" *ngFor="let tahun of listTahunLulus">{{tahun}}</option>
                              </select>
                              
                          </div>
                          <div class="col-7 mb-3">
                              <label for="bersaudara" class="form-label">NISN</label>
                              <input type="text" class="form-control" formControlName="nisn" id="nisn"
                                  placeholder="">
                          </div>
                      </div>
                      <!-- <div class="row">
                          <div class="col-12 mb-3">
                              <label for="tempat_ijazah" class="form-label">Tempat Ijazah</label>
                              <input type="text" class="form-control" formControlName="tempat_ijazah" id="tempat_ijazah"
                                  placeholder="">
                          </div>
                      </div> -->
                      <div class="row">
                          <div class="col-12 mb-3">
                              <label for="province" class="form-label">Nomor Pokok Sekolah Nasional (NPSN)</label>
                              <input type="text" class="form-control" formControlName="npsn" id="npsn"
                                  placeholder="">
                          </div>
                      </div>
                  </div>
              </div>
          
      </div>
    </div>
  
    <div class="card" *ngIf="showFullForm">
      <div class="card-header">
        BIODATA AYAH
      </div>
      <div class="card-body">
          
              <div class="row">
                  <div class="col-12 col-lg-7">
                      <div class="row">
                          <div class="col-12 mb-3">
                              <label for="saudara" class="form-label">Nomor Kartu Keluarga</label>
                              <input type="text" class="form-control" formControlName="no_kk" id="no_kk"
                                  placeholder="">
                          </div>
                          <div class="col-12 mb-3">
                              <label for="nis" class="form-label">Nama Ayah</label>
                              <input type="text" class="form-control" formControlName="nama_ayah" id="nama_ayah" placeholder="">
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                            <label for="saudara" class="form-label">Nama Ayah (Arab)</label>
                            <input type="text" class="form-control" name="nama_ayah_arab" id="nama_ayah_arab"
                                placeholder="">
                        </div>
                        <div class="col-12 mb-3">
                            <label for="nis" class="form-label">NIK Ayah</label>
                            <input type="text" class="form-control" formControlName="nik_ayah" id="nik_ayah" placeholder="">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                            <label for="tahun" class="form-label">Status Ayah</label>
                            <select
                                formControlName="status_ayah"
                                class="form-select rounded-3 py-2"
                                [ngClass]="{'is-invalid': submitted && f['status_ayah'].errors}"
                                style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                                >
                                <option value="" selected disabled>Pilih</option>
                                <option value="KANDUNG">Kandung</option>
                                <option value="TIRI">Tiri</option>
                                <option value="ANGKAT">Angkat</option>
                            </select>
                        </div>
                      </div>
                      <div class="row">
                          <div class="col-6 mb-3">
                              <label for="birthplace" class="form-label">Tempat Lahir</label>
                              <input type="text" class="form-control" formControlName="pob_ayah" id="pob_ayah"
                                  placeholder="">
                          </div>
                          <div class="col-6 mb-3">
                              <label for="birthdate" class="form-label">Tanggal Lahir</label>
                              <input type="text" class="form-control" formControlName="dob_ayah" id="dob_ayah"
                                  placeholder="">
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="tahun" class="form-label">Gelar Akademik</label>
                          <input
                            class="form-control rounded-3 py-2"
                            formControlName="gelar_akademik_ayah"
                            [ngClass]="{'is-invalid': submitted && f['gelar_akademik_ayah'].errors}"
                            placeholder="Gelar Akademik"
                            style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                            />
                        </div>
                        <div class="col-12 mb-3">
                            <label for="status_hidup_ayah" class="form-label">Masih Hidup / Meninggal</label>
                            <select
                                formControlName="status_hidup_ayah"
                                class="form-select rounded-3 py-2"
                                [ngClass]="{'is-invalid': submitted && f['status_hidup_ayah'].errors}"
                                style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                                >
                                <option value="" selected disabled>Pilih</option>
                                <option value="HIDUP">Masih Hidup</option>
                                <option value="MENINGGAL">Sudah Meninggal Dunia</option>
                            </select>
                        </div>
                      </div>
                  </div>
                  <div class="col-12 col-lg-5">
                      <div class="row">
                          <div class="col-12 mb-3">
                              <label for="pendidikan_terkahir_ayah" class="form-label">Pendidikan Terakhir</label>
                              <select
                                formControlName="pendidikan_terkahir_ayah"
                                class="form-select rounded-3 py-2"
                                [ngClass]="{'is-invalid': submitted && f['pendidikan_terkahir_ayah'].errors}"
                                style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                                >
                                <option value="" selected disabled>Pilih</option>
                                <option value="TIDAK_TAMAT">Tidak Tamat SD/MI/Sederajat</option>
                                <option value="SD">SD/MI/Sederajat</option>
                                <option value="SMP">SMP/MTS/Sederajat</option>
                                <option value="SMA">SMA/MA/Sederajat</option>
                                <option value="S1">Sarjana (S1)</option>
                                <option value="S2">Magister (S2)</option>
                                <option value="S3">Doktoral (S3)</option>
                            </select>
                          </div>
                          <div class="col-12 mb-3">
                              <label for="bersaudara" class="form-label">Pekerjaan Bapak</label>
                              <input type="text" class="form-control" formControlName="pekerjaan_ayah" id="pekerjaan_ayah"
                                  placeholder="">
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-12 mb-3">
                              <label for="province" class="form-label">Alamat</label>
                              <textarea class="form-control" formControlName="alamat_ayah" id="alamat_ayah" cols="10"
                                  rows="3"></textarea>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-12 mb-3">
                              <label for="address" class="form-label">Telepon Rumah</label>
                              <input type="text" class="form-control" formControlName="telepon" id="telepon"
                                  placeholder="">
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                            <label for="address" class="form-label">Hanphone</label>
                            <input type="text" class="form-control" formControlName="telp_ayah" id="telp_ayah"
                                placeholder="">
                        </div>
                        <!-- <div class="col-12 mb-3">
                          <label for="address" class="form-label">Hanphone 2</label>
                          <input type="text" class="form-control" name="address" id="address"
                              placeholder="">
                        </div> -->
                      </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="penghasilan_perbulan_ayah" class="form-label">Penghasilan Perbulan</label>
                          <select
                            formControlName="penghasilan_perbulan_ayah"
                            class="form-select rounded-3 py-2"
                            [ngClass]="{'is-invalid': submitted && f['penghasilan_perbulan_ayah'].errors}"
                            style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                            >
                            <option value="" selected disabled>Pilih</option>
                            <option value="1">&#60; Rp 500.000,00</option>
                            <option value="2">Rp 500.001,00 - Rp 1.000.000,00</option>
                            <option value="3">Rp 1.000.001,00 - Rp 2.000.000,00</option>
                            <option value="4">Rp 2.000.001,00 - Rp 3.000.000,00</option>
                            <option value="5">Rp 3.000.001,00 - Rp 4.000.000,00</option>
                            <option value="6">Rp 4.000.001,00 - Rp 5.000.000,00</option>
                            <option value="7">&#62; Rp 5.000.000,00</option>
                        </select>
                        </div>
                    
                      </div>
                  </div>
              </div>
          
      </div>
    </div>
  
    <div class="card" *ngIf="showFullForm">
      <div class="card-header">
        BIODATA IBU
      </div>
      <div class="card-body">
          
              <div class="row">
                  <div class="col-12 col-lg-7">
                      <div class="row">
                          <div class="col-12 mb-3">
                              <label for="nik_ibu" class="form-label">NIK Ibu</label>
                              <input type="text" class="form-control" formControlName="nik_ibu" id="nik_ibu"
                                  placeholder="">
                          </div>
                          <div class="col-12 mb-3">
                              <label for="nis" class="form-label">Nama Ibu</label>
                              <input type="text" class="form-control" formControlName="nama_ibu" id="nama_ibu" placeholder="">
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                            <label for="nama_ibu" class="form-label">Nama Ibu (Arab)</label>
                            <input type="text" class="form-control" name="nama_ibu_arab" id="nama_ibu_arab" placeholder="">                            
                        </div>
                        <div class="col-12 mb-3">
                            <label for="status_ibu" class="form-label">Status Ibu</label>
                            <select
                                formControlName="status_ibu"
                                class="form-select rounded-3 py-2"
                                [ngClass]="{'is-invalid': submitted && f['status_ibu'].errors}"
                                style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                                >
                                <option value="" selected disabled>Pilih</option>
                                <option value="KANDUNG">Kandung</option>
                                <option value="TIRI">Tiri</option>
                                <option value="ANGKAT">Angkat</option>
                            </select>
                        </div>
                      </div>
                      <div class="row">
                          <div class="col-6 mb-3">
                              <label for="pob_ibu" class="form-label">Tempat Lahir</label>
                              <input type="text" class="form-control" formControlName="pob_ibu" id="pob_ibu"
                                  placeholder="">
                          </div>
                          <div class="col-6 mb-3">
                              <label for="dob_ibu" class="form-label">Tanggal Lahir</label>
                              <input type="text" class="form-control" formControlName="dob_ibu" id="dob_ibu"
                                  placeholder="">
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-12 mb-3">
                            <label for="gelar_akademik_ibu" class="form-label">Gelar Akademik</label>
                            <input
                                class="form-control rounded-3 py-2"
                                formControlName="gelar_akademik_ibu"
                                [ngClass]="{'is-invalid': submitted && f['gelar_akademik_ibu'].errors}"
                                placeholder="Gelar Akademik"
                                style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                            />
                          </div>
                          <div class="col-12 mb-3">
                            <label for="status_hidup_ibu" class="form-label">Masih Hidup / Meninggal</label>
                            <select
                                formControlName="status_hidup_ibu"
                                class="form-select rounded-3 py-2"
                                [ngClass]="{'is-invalid': submitted && f['status_hidup_ibu'].errors}"
                                style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                                >
                                <option value="" selected disabled>Pilih</option>
                                <option value="HIDUP">Masih Hidup</option>
                                <option value="MENINGGAL">Sudah Meninggal Dunia</option>
                            </select>
                          </div>
                      </div>
                  </div>
                  <div class="col-12 col-lg-5">
                      <div class="row">
                          <div class="col-12 mb-3">
                            <label for="pendidikan_terkahir_ibu" class="form-label">Pendidikan Terakhir</label>
                            <select
                              formControlName="pendidikan_terkahir_ibu"
                              class="form-select rounded-3 py-2"
                              [ngClass]="{'is-invalid': submitted && f['pendidikan_terkahir_ibu'].errors}"
                              style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                              >
                              <option value="" selected disabled>Pilih</option>
                              <option value="TIDAK_TAMAT">Tidak Tamat SD/MI/Sederajat</option>
                              <option value="SD">SD/MI/Sederajat</option>
                              <option value="SMP">SMP/MTS/Sederajat</option>
                              <option value="SMA">SMA/MA/Sederajat</option>
                              <option value="S1">Sarjana (S1)</option>
                              <option value="S2">Magister (S2)</option>
                              <option value="S3">Doktoral (S3)</option>
                            </select>
                          </div>
                          <div class="col-12 mb-3">
                              <label for="pekerjaan_ibu" class="form-label">Pekerjaan Ibu</label>
                              <input type="text" class="form-control" formControlName="pekerjaan_ibu" id="pekerjaan_ibu"
                                  placeholder="">
                          </div>
                      </div>                    
                      <div class="row">
                          <div class="col-12 mb-3">
                              <label for="alamat_ibu" class="form-label">Alamat</label>
                              <textarea class="form-control" formControlName="alamat_ibu" id="alamat_ibu" cols="10"
                                  rows="3"></textarea>
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                            <label for="address" class="form-label">Hanphone</label>
                            <input type="text" class="form-control" formControlName="telp_ibu" id="telp_ibu"
                                placeholder="">
                        </div>
                        <!-- <div class="col-12 mb-3">
                          <label for="address" class="form-label">Hanphone 2</label>
                          <input type="text" class="form-control" name="address" id="address"
                              placeholder="">
                        </div> -->
                      </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                            <label for="penghasilan_perbulan_ibu" class="form-label">Penghasilan Perbulan</label>
                            <select
                              formControlName="penghasilan_perbulan_ibu"
                              class="form-select rounded-3 py-2"
                              [ngClass]="{'is-invalid': submitted && f['penghasilan_perbulan_ibu'].errors}"
                              style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                              >
                              <option value="" selected disabled>Pilih</option>
                              <option value="1">&#60; Rp 500.000,00</option>
                              <option value="2">Rp 500.001,00 - Rp 1.000.000,00</option>
                              <option value="3">Rp 1.000.001,00 - Rp 2.000.000,00</option>
                              <option value="4">Rp 2.000.001,00 - Rp 3.000.000,00</option>
                              <option value="5">Rp 3.000.001,00 - Rp 4.000.000,00</option>
                              <option value="6">Rp 4.000.001,00 - Rp 5.000.000,00</option>
                              <option value="7">&#62; Rp 5.000.000,00</option>
                            </select>
                        </div>
                      </div>
                  </div>
              </div>
          
      </div>
    </div>
  
    <div class="card" *ngIf="showFullForm">
      <div class="card-header">
        YANG MENANGGUNG BIAYA
      </div>
      <div class="card-body">
          
              <div class="row">
                  <div class="col-12 col-lg-7">
                      <div class="row">
                          <div class="col-12 mb-3">
                              <label for="nama_penanggung_biaya" class="form-label">Nama</label>
                              <input type="text" class="form-control" formControlName="nama_penanggung_biaya" id="nama_penanggung_biaya"
                                  placeholder="">
                          </div>
                          <div class="col-12 mb-3">
                              <label for="hubungan_keluarga_penanggung_biaya" class="form-label">Hubungan Keluarga</label>
                              <input type="text" class="form-control" formControlName="hubungan_keluarga_penanggung_biaya" id="hubungan_keluarga_penanggung_biaya" placeholder="">
                          </div>
                          <div class="col-12 mb-3">
                            <label for="telepon_penanggung_biaya" class="form-label">Telepon</label>
                            <input type="text" class="form-control" formControlName="telepon_penanggung_biaya" id="telepon_penanggung_biaya" placeholder="">
                        </div>
                      </div>
                      
                  </div>
                  <div class="col-12 col-lg-5">
                      <div class="row">
                          <div class="col-12 mb-3">
                              <label for="pekerjaan_penanggung_biaya" class="form-label">Pekerjaan</label>
                              <input type="text" class="form-control" formControlName="pekerjaan_penanggung_biaya" id="pekerjaan_penanggung_biaya"
                                  placeholder="">
                          </div>
                          
                      </div>
                      <div class="row">
                          <div class="col-12 mb-3">
                              <label for="alamat_penanggung_biaya" class="form-label">Alamat</label>
                              <textarea class="form-control" formControlName="alamat_penanggung_biaya" id="alamat_penanggung_biaya" cols="10"
                                  rows="3"></textarea>
                          </div>
                      </div>
                  </div>
              </div>
          
      </div>
    </div>
  
    <div class="card" *ngIf="showFullForm">
      <div class="card-header">
        KESEHATAN
      </div>
      <div class="card-body">
          
              <div class="row">
                  <div class="col-12 col-lg-7">
                      <div class="row">
                          <div class="col-12 mb-3">
                              <label for="golongan_darah" class="form-label">Golongan Darah</label>
                              <select
                                formControlName="golongan_darah"
                                class="form-select rounded-3 py-2"
                                [ngClass]="{'is-invalid': submitted && f['golongan_darah'].errors}"
                                style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                                >
                                <option value="" selected disabled>Pilih</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="AB">AB</option>
                                <option value="O">O</option>
                            </select>
                          </div>
                          <div class="col-12 mb-3">
                              <label for="tinggi_badan" class="form-label">Tinggi Badan</label>
                              <input type="text" class="form-control" formControlName="tinggi_badan" id="tinggi_badan" placeholder="">
                          </div>
                          <div class="col-12 mb-3">
                            <label for="berat_badan" class="form-label">Berat Badan</label>
                            <input type="text" class="form-control" formControlName="berat_badan" id="berat_badan" placeholder="">
                        </div>
                      </div>
                  </div>
                  <div class="col-12 col-lg-5">                    
                      <div class="row">
                          <div class="col-12 mb-3">
                              <label for="riwayat_penyakit" class="form-label">Penyakit Yang Pernah Di Derita</label>
                              <textarea class="form-control" formControlName="riwayat_penyakit" id="riwayat_penyakit" cols="10"
                                  rows="3"></textarea>
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                            <label for="province" class="form-label">Penyakit Yang Sedang Di Derita</label>
                            <textarea class="form-control" name="penyakit_yg_sedang_di_derita" id="penyakit_yg_sedang_di_derita" cols="10"
                                rows="3"></textarea>
                        </div>
                    </div>
                  </div>
              </div>
          
      </div>
    </div>
  
    <div class="row align-items-center">
        <div class="col-6 col-lg-4 offset-lg-6">
            <div class="form-check">
                <input
                  type="checkbox" 
                  class="form-check-input" 
                  [value]="1"
                  (change)="onShowFullFormChanged($event)"
                >
                <label class="form-check-label" for="">
                    Tampilkan Form Lengkap
                </label>
            </div>
        </div>
        <div class="col-6 col-lg-2 d-flex justify-content-end">
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </div>
  </div>
</form>

