import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { JadwalPelajaranService } from 'src/app/services/jadwal-pelajaran.service';
import { CommonService } from 'src/app/helper/common.service';
import { AbsensiService } from 'src/app/services/absensi.service';


@Component({
  selector: 'app-detail-absensi-kamar',
  templateUrl: './detail-absensi-kamar.component.html',
  styleUrls: ['./detail-absensi-kamar.component.css']
})
export class DetailAbsensiKamarComponent implements OnInit {

  sharedDataObject: any = null;
  listDetailAbsen: any = [];
  currentPeriodeId: any = null;
  id_kamar = 0;
  currentGuruId: any = null;
  currentNamaGuru: any = null;
  currentSemester: any = null;
  errorMessage = "";
  listDayPeriod: any = [];
  numOfDayCol = 0;
  tanggal = "";
  kamar = "";
  jamPelajaran = "";
  materi = "";
  id = "";
  absen = "";

  constructor(
    private uiService: UiService,
    private jadwalpelajaranService: JadwalPelajaranService,
    private commonService: CommonService,
    private sharedDataService: SharedDataService,
    private absensiService: AbsensiService,
  ) { }

  ngOnInit(): void {
    this.sharedDataService.currentDataObject.subscribe(data => {
      (this.sharedDataObject = data)
      console.log("SHARED OBJECT", data);
      
      this.tanggal = data.tanggal;
      this.kamar = data.nama_kamar;
      this.jamPelajaran = data.jamke;
      this.materi = data.materi;
      this.currentNamaGuru = data.real_name;
      this.id = data.id;
      this.absen = data.absen;
      this.id_kamar = data.id_kamar;
      this.currentPeriodeId = data.id_tahun_ajaran;

      if (data.absen !== "") {
        this.getListByAbsensi();
      } else {
        this.getAllDetailAbsensi();
      }
    })
  }

  getListByAbsensi() {
    const payload = {
      absensi_guru_input_id: this.id,
      absen: this.absen
    }

    console.log(payload);
    
    this.absensiService.getAbsensiKamarSiswaByAbsen(payload).subscribe({
      next: resdata => {
        console.log('list absen', resdata);

        // this.listDetailAbsen = resdata;

        this.listDetailAbsen = resdata;


        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllDetailAbsensi() {    
    const payload = {
      periode_id:  this.currentPeriodeId,
      id_kamar:  this.id_kamar,
      absensi_guru_input_id: this.id
    }

    this.absensiService.getAbsensiHarianKamar(payload).subscribe({
      next: resdata => {
        console.log('list absen', resdata);

        // this.listDetailAbsen = resdata;

        this.listDetailAbsen = resdata;


        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  closeButton() {
    this.uiService.hideFullScrrenContent();
  }

}
