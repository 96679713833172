import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-permission-edit',
  templateUrl: './permission-edit.component.html',
  styleUrls: ['./permission-edit.component.css']
})
export class PermissionEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
