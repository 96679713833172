<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="content-item mb-5">
    <div class="back-button" (click)="onBack()">
      <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
    </div>
    <div class="item-header">
      ADD Pelajaran
    </div>
    
    <div class="card">
      <div class="card-header">
        DATA PELAJARAN
      </div>
      <div class="card-body">
          
              <div class="row">
            
                  <div class="col-12 col-lg-6">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label for="kode_studi" class="form-label">Kode Pelajaran</label>
                        <input
                          type="text"
                          formControlName="kode_studi"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f['kode_studi'].errors }"
                          placeholder=""
                        />
                      </div>
                    </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="saudara" class="form-label">Nama Pelajaran</label>
                          <input
                            type="text"
                            formControlName="nama_studi"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['nama_studi'].errors }"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="saudara" class="form-label">Nama Pelajaran (Arab)</label>
                          <input
                            type="text"
                            formControlName="nama_studi_ar"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['nama_studi_ar'].errors }"
                            placeholder=""
                          />
                        </div>
                      </div>
                      
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="saudara" class="form-label">Nama Pelajaran (Inggris)</label>
                          <input
                            type="text"
                            formControlName="nama_studi_en"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['nama_studi_en'].errors }"
                            placeholder=""
                          />
                        </div>
                      </div>
                      
                      <div class="row">
                        <div class="col-12 mb-3">                                                    
                          <label for="relasi_nilai" class="form-label">Tipe Penilaian</label>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" formControlName="is_nilai_harian">
                            <label class="form-check-label" for="flexCheckDefault">
                              Harian
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" formControlName="is_nilai_mid">
                            <label class="form-check-label" for="flexCheckChecked">
                              Mid
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" formControlName="is_nilai_semester">
                            <label class="form-check-label" for="flexCheckChecked">
                              Semester
                            </label>
                          </div>
                        </div>
                      </div>
                      
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="relasi_nilai" class="form-label">Relasi Input Harian/Bulanan</label>
                          <select 
                            formControlName="relasi_nilai"
                            [ngClass]="{ 'is-invalid': submitted && f['relasi_nilai'].errors }"
                            class="form-select">
                            <option value="" selected>Pilih</option>
                            <option [value]="data.val" *ngFor="let data of listRelasiNilai">{{data.key}}</option>
                          </select>                        
                        </div>
                      </div>

                  </div>
              </div>
          
      </div>
    </div>
  
    <div class="row align-items-center">
        <div class="col-6 col-lg-4 offset-lg-6">
            
        </div>
        <div class="col-6 col-lg-2 d-flex justify-content-end">
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </div>
  </div>
</form>
