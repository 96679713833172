<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="guru-input" role="tabpanel" aria-labelledby="guru-input-tab">
        <div class="tabs-title">Input Laporan Kerusakan</div>


        <div class="col-12 col-lg-12">
            <div class="card">

                <div class="card-body">
                    <form [formGroup]="formInputBrt" (ngSubmit)="onSubmitformInputBrt()">
                        <div class="row">
                            <div class="col-12 col-lg-12">
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-2 text-end">
                                            <label for="nik" class="form-label">Laporan Kerusakan <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-10">
                                            <select formControlName="id_kategori_alat"
                                                [ngClass]="{ 'is-invalid': submitted && f['id_kategori_alat'].errors }"
                                                class="form-select">
                                                <option value="" selected>Pilih</option>
                                                <option [value]="data.id" *ngFor="let data of listKategoriAlat">
                                                    {{data}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-2 text-end">
                                            <label for="name" class="form-label">Tanggal Lapor</label>
                                        </div>
                                        <div class="col-10">
                                            <input type="text" class="form-control" formControlName="tanggal"
                                                placeholder="">
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-2 text-end">
                                            <label for="name" class="form-label">Ruangan <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-10">
                                            <input formControlName="ruangan" type="text"
                                                [ngClass]="{ 'is-invalid': submitted && f['subject'].errors }"
                                                class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-2 text-end">
                                            <label for="name" class="form-label">Kelas <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-10">
                                            <input formControlName="kelas" type="text"
                                                [ngClass]="{ 'is-invalid': submitted && f['subject'].errors }"
                                                class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-2 text-end">
                                            <label for="name" class="form-label">Alat <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-10">
                                            <select formControlName="id_semester"
                                                [ngClass]="{ 'is-invalid': submitted && f['id_semester'].errors }"
                                                class="form-select">
                                                <option value="" selected disabled>Pilih</option>
                                                <option [value]="data.kode" *ngFor="let data of listAlat">
                                                    {{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-2 text-end">
                                            <label for="detail" class="form-label">Detail Kerusakan <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-10">
                                            <textarea class="form-control" formControlName="detail" rows="3">
                                        </textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <div class="row align-items-center">
                                        <div class="col-2 text-end">
                                            <label for="name" class="form-label">Priority <span
                                                    class="required">*</span></label>
                                        </div>
                                        <div class="col-10">
                                            <select formControlName="id_semester"
                                                [ngClass]="{ 'is-invalid': submitted && f['id_semester'].errors }"
                                                class="form-select">
                                                <option value="" selected disabled>Pilih</option>
                                                <option [value]="data.kode" *ngFor="let data of listPriority">
                                                    {{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-12">
            <p>&nbsp;</p>
        </div>

        <div class="col-12 col-lg-12">
            <div class="card">

                <div class="card-body">
                    
                    <div class="table-wrapper">
                        <div class="table-options">
                            <div class="show-table">
                                File
                                <select class="form-control" name="show" id="show" (change)="handlePageSizeChange($event)">
                                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                                        {{ size }}
                                    </option>
                                </select>
                                file(s)
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Photo</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="
                              let img of listImgSrc
                                | paginate
                                  : {
                                      id: pageid,
                                      itemsPerPage: pageSize,
                                      currentPage: page,
                                      totalItems: count
                                    };
                              let i = index
                            ">
                                        <td>{{ i + 1 + (page - 1) * pageSize }}</td>
                                        <td><img width="200px" [src]="domainUrlImage + img" class="img-thumbnail" (error)="handleMissingImage($event)" /></td>
                                        <td>
                                            <button (click)="removePhoto(img)" class="tagihan secondary btn">
                                               X 
                                            </button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="listRekapInput.length === 0" class="empty">
                                        <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
            
                        <div class="progress progress-striped active">
                            <div class="progress-bar progress-bar-info ng-binding" [style.width.%]="progress">{{progress}}%</div>
                        </div>

                        <div class="mb-3">
                            <div class="row">
                                
                                <div class="col-12">
                                    <input type='file' #photoUpload (click)="photoUpload.value=''" style="display: none;" (change)="uploadPhoto($event);" />
                                    <button class="btn btn-warning mt-2"
                                        style="display:inline; margin-right: 4px;" (click)="photoUpload.click()">
                                        Add Photo
                                    </button>
                                    <button class="btn btn-blue-grey mt-2" style="display:inline;">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>