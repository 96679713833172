<div class="history">
  <div class="card">
    <div class="card-header">Histori Transaksi Keuangan</div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-lg-1 history-desc">
          <div class="history-date">20-11-2020</div>
          <div class="history-time">19:07:01</div>
        </div>
        <div class="col-12 col-lg-11 history-table">
          <div class="table-wrapper">
            <div class="table-responsive">
              <table class="table table-borderless">
                <thead>
                  <tr class="up">
                    <th colspan="2">
                      VATRF 88548223335861201 8D OLIVIA JASMINE | (111.01)
                      VASYS
                    </th>
                  </tr>
                  <tr>
                    <th>Keterangan</th>
                    <th>Nominal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-start">
                      IURAN BULANAN 2021-2022 DEC-2021 OLIVIA JASMINE
                    </td>
                    <td class="text-end">1,475,000</td>
                  </tr>
                  <tr>
                    <td class="text-start">
                      POTONGAN DANA MAKAN SEMESTER 1 2021-2022 DEC-2021
                      OLIVIA JASMINE
                    </td>
                    <td class="text-end">-80,000</td>
                  </tr>
                  <tr>
                    <td class="text-start">TOTAL</td>
                    <td class="text-end">1,385,000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-1 history-desc">
          <div class="history-date">20-11-2020</div>
          <div class="history-time">19:07:01</div>
        </div>
        <div class="col-12 col-lg-11 history-table">
          <div class="table-wrapper">
            <div class="table-responsive">
              <table class="table table-borderless">
                <thead>
                  <tr class="up">
                    <th colspan="2">
                      VATRF 88548223335861201 8D OLIVIA JASMINE | (111.01)
                      VASYS
                    </th>
                  </tr>
                  <tr>
                    <th>Keterangan</th>
                    <th>Nominal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-start">
                      IURAN BULANAN 2021-2022 DEC-2021 OLIVIA JASMINE
                    </td>
                    <td class="text-end">1,475,000</td>
                  </tr>
                  <tr>
                    <td class="text-start">
                      POTONGAN DANA MAKAN SEMESTER 1 2021-2022 DEC-2021
                      OLIVIA JASMINE
                    </td>
                    <td class="text-end">-80,000</td>
                  </tr>
                  <tr>
                    <td class="text-start">TOTAL</td>
                    <td class="text-end">1,385,000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
