import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class BiayaService {

  constructor(private netService: NetworkService) { }

  getAllJenisBayar(): Observable<any> {
    return this.netService.getRequest('/v1/alljenisbayar');
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/biaya');
  }

  getById(id: any): Observable<any> {
    return this.netService.getRequest(`/v1/biayabyid/${id}`);
  }

  getByPeriodeId(id: any): Observable<any> {
    return this.netService.getRequest(`/v1/jenisbayar/${id}`);
  }

  getBiayaRegistrasiByPeriodeId(id: any): Observable<any> {
    return this.netService.getRequest(`/v1/biayaregistrasi/${id}`);
  }

  getCurrentBiayaRegistrasi(): Observable<any> {
    return this.netService.getRequest(`/v1/currentbiayaregistrasi`);
  }

  update(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/biaya/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/biaya/${id}`);
  }
}
