<div class="tab-content" id="myTabContent">
    <div *ngIf="backUrl !== ''" class="back-button" (click)="onBack()">
        <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
    </div>
  <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
      <div class="tabs-title">
          KATEGORI BERKAS
      </div>
      <div class="list-options">
          
  
          
          <a routerLink="/settings/berkas/add" class="btn btn-blue-grey">
              + Tambah Berkas
          </a>
          
      </div>
      <div class="table-wrapper">
          
          <div class="table-responsive">
              <table class="table table-borderless">
                  <thead>
                      <tr>
                          <th>No.</th>
                          <th>Nama Berkas</th>
                          <th>Jumlah</th>
                          <th>Mandatory</th>
                          <th> </th>
                      </tr>
                  </thead>
                  <tbody>                        
                      <tr
                      *ngFor="
                          let berkas of listBerkas 
                          let i = index
                      "
                      >
                          <td>{{ i + 1 + ((page - 1) * pageSize) }}</td>
                          <td>{{ berkas.nama_dokumen }}</td>
                          <td>{{ berkas.jumlah }}</td>
                          <td>{{ berkas.mandatory }}</td>
                          
                          <td class="settings">
                              <div class="dropend">
                                  <a href="#" role="button" id="dropdownMenuLink"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      <img src="./assets/images/icon-setting.svg" alt="">
                                  </a>
                                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                      <li><a routerLink="/settings/berkas/edit/{{berkas.id}}" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>
                                      <li><a (click)="confirmDelete(berkas.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                                  </ul>
                              </div>
                          </td>
                      </tr>
                      <tr *ngIf="listBerkas.length === 0" class="empty">
                          <td colspan="5">Tidak terdapat data atau data tidak ditemukan.</td>
                      </tr>
                      
                  </tbody>
              </table>
          </div>

      </div>

      
  </div>
  
</div>
