import { Component, OnInit } from '@angular/core';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/helper/common.service';
import { KelasService } from 'src/app/services/kelas.service';
import { NilaiHarianService } from 'src/app/services/nilai-harian.service';
import { SettingInsyaDailyService } from 'src/app/services/setting-insya-daily.service';


@Component({
  selector: 'app-input-nilai-harian-daily-composition',
  templateUrl: './input-nilai-harian-daily-composition.component.html',
  styleUrls: ['./input-nilai-harian-daily-composition.component.css']
})
export class InputNilaiHarianDailyCompositionComponent implements OnInit {

  listSemester: any = [];
  listTahunajaran: any = [];
  listNilaiDailyComposition: any = [];
  nilaiDailyComposition: any = null;
  listKelas: any = [];
  listTugasKe: any = [];
  currentPeriodeId: any = null;
  currentTingkatId = "";
  dataToUpload: any = null;
  pageid = "upload-jadwal-mengajar";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  errorMessage = "";
  currentDate = "";
  submitted = false;
  listMonth: any = [];
  currentKelasId: any = null;
  currentSemester = "";
  currentTugasKe = null;
  
  formInputNilaiDailyComposition: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),    
    id_semester: new FormControl(''),
    id_kelas: new FormControl(''),
    tugas_ke: new FormControl(''),
    id_penginput: new FormControl(''),
    nilai_daily_composition_id: new FormControl(''),
    dataFormnilaiDailyComposition: new FormArray([]),
  });

  constructor(
    private uiService: UiService,
    private tahunajaranService: TahunajaranService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private kelasService: KelasService,
    private nilaiharianService: NilaiHarianService,
    private settingInsyaDailyService: SettingInsyaDailyService,
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getKelas();
    this.listMonth = this.commonService.getListMonth();    
    this.getCurrentTahunajaran();
    this.listSemester = this.commonService.getListSemester();
    this.initForm();

    const currentUser = localStorage.getItem('user')?.toString();
    const jsonUser = currentUser ? JSON.parse(currentUser) : "";
    console.log("CURRENT USER", jsonUser.proguser.userProfile.real_name);

    this.formInputNilaiDailyComposition.controls['id_penginput'].setValue(jsonUser.proguser.id);
    this.formInputNilaiDailyComposition.controls['id_tahun_ajaran'].disable();
    this.formInputNilaiDailyComposition.controls['id_semester'].disable();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  disableForm() {
    this.formInputNilaiDailyComposition.controls['id_tahun_ajaran'].disable();
    this.formInputNilaiDailyComposition.controls['id_semester'].disable();
  }

  enableForm() {
    this.formInputNilaiDailyComposition.controls['id_tahun_ajaran'].enable();
    this.formInputNilaiDailyComposition.controls['id_semester'].enable();
  }

  resetForm() {
    this.formInputNilaiDailyComposition.controls['id_kelas'].setValue('');
    this.formInputNilaiDailyComposition.controls['tugas_ke'].setValue('');
    this.listNilaiDailyComposition = [];
  }

  initForm() {
    this.formInputNilaiDailyComposition = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        id_semester: ['', Validators.required],
        id_kelas: ['', Validators.required],
        tugas_ke: ['', Validators.required],
        id_penginput: ['', Validators.required],
        nilai_daily_composition_id: [''],
        dataFormnilaiDailyComposition: new FormArray([]),
      },
    );
  }

  get dataFormnilaiDailyComposition(): FormArray {
    return this.formInputNilaiDailyComposition.get('dataFormnilaiDailyComposition') as FormArray;
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formInputNilaiDailyComposition.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;
        
        for (let i=0; i<resdata.Semester.length; i++) {
          const periodeSemester = this.commonService.getCurrentDateBetweenDateRange(resdata.Semester[i].start_date, resdata.Semester[i].end_date);

          if (periodeSemester) {
            this.currentSemester = resdata.Semester[i].semester;
            this.formInputNilaiDailyComposition.controls['id_semester'].setValue(this.currentSemester);
            // this.formInputNilaiDailyComposition.controls['kelas_id'].enable();
            
            // this.getDayPeriod();          
            
            break;    
          }
        }

        this.uiService.hideLoader();

        this.getAllTahunajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }


  onSubmitformInputNilaiDailyComposition() {
    this.submitted = true;
    this.enableForm();
    console.log(JSON.stringify(this.formInputNilaiDailyComposition.value, null, 2));

    if (this.formInputNilaiDailyComposition.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    this.nilaiharianService.addNilaiDailiComposition(this.formInputNilaiDailyComposition.value).subscribe({
      next: resdata => {
        console.log(resdata);

        this.uiService.hideLoader();
        this.uiService.showSuccess('');

        this.disableForm();
        this.resetForm();
        this.submitted = false;

      },
      error: err => {
        console.log(err);
        this.disableForm();
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });   
    
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formInputNilaiDailyComposition.controls;
  }

  changeTahunAjaran(e: any) {
    this.currentPeriodeId = e.target.value;
    this.formInputNilaiDailyComposition.controls['id_kelas'].setValue("");
    this.formInputNilaiDailyComposition.controls['tugas_ke'].setValue("");
    this.formInputNilaiDailyComposition.controls['tugas_ke'].disable();
    this.listNilaiDailyComposition = [];
  }

  changeSemester(e: any) {
    this.currentSemester = e.target.value;
    this.formInputNilaiDailyComposition.controls['id_kelas'].setValue("");
    this.formInputNilaiDailyComposition.controls['tugas_ke'].setValue("");
    this.formInputNilaiDailyComposition.controls['tugas_ke'].disable();
    this.listNilaiDailyComposition = [];
  }

  changeKelas(e: any) {
    this.currentKelasId = e.target.value;
    this.formInputNilaiDailyComposition.controls['tugas_ke'].setValue("");
    this.formInputNilaiDailyComposition.controls['tugas_ke'].enable();
    this.listNilaiDailyComposition = [];

    for (let s=0;s<this.listKelas.length;s++) {
      if (this.listKelas[s].id === parseInt(this.currentKelasId)) {
        this.currentTingkatId = this.listKelas[s].tingkatkelas.id;
        this.getListTugasKe();
        break;
      }
    }
  }

  changeTugasKe(e: any) {
    this.currentTugasKe = e.target.value;
    console.log("DATA INPUT", this.currentKelasId, this.currentSemester, this.currentTugasKe, this.currentPeriodeId);

    // get nilaiQuran
    const payloadNH = {
      periode_id: this.currentPeriodeId,
      semester: this.currentSemester,
      id_kelas: this.currentKelasId,
      tugas_ke: this.currentTugasKe
    }
    this.nilaiharianService.getInputHarianDailyComposition(payloadNH).subscribe({
      next: resdata => {
        console.log("NH", resdata);

        this.formInputNilaiDailyComposition.controls['nilai_daily_composition_id'].setValue("");
        if (resdata !== null) {
          // this.formInputNilaiDailyComposition.controls['subject'].setValue(resdata.subject);
          this.formInputNilaiDailyComposition.controls['nilai_daily_composition_id'].setValue(resdata.id);
        }

        this.nilaiDailyComposition = resdata;
        
        // get list nilai insya
        this.getListInputHarianInsya();
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });

  }

  private getListInputHarianInsya() {
    const payloadLNH = {
      periode_id: this.currentPeriodeId,
      id_kelas: this.currentKelasId,
      nilai_daily_composition_id: this.nilaiDailyComposition !== null ? this.nilaiDailyComposition.id : null,
    }
    this.nilaiharianService.getListInputHarianDailyComposition(payloadLNH).subscribe({
      next: resdata => {
        console.log("LNH", resdata);
  
        this.listNilaiDailyComposition = resdata;

        // reset form array
        this.dataFormnilaiDailyComposition.clear();

        for (let i = 0; i < this.listNilaiDailyComposition.length; i++) {        
          this.dataFormnilaiDailyComposition.push(this.formBuilder.group({        
            'siswa_id': this.listNilaiDailyComposition[i] && this.listNilaiDailyComposition[i].siswa_id ? this.listNilaiDailyComposition[i].siswa_id : '',
            'nilai': [this.listNilaiDailyComposition[i] && this.listNilaiDailyComposition[i].nilai ? this.listNilaiDailyComposition[i].nilai : '0', Validators.required],
            'keterangan': this.listNilaiDailyComposition[i] && this.listNilaiDailyComposition[i].keterangan ? this.listNilaiDailyComposition[i].keterangan : '',
          }));
        }
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getListTugasKe() {
    this.uiService.showLoader();

    const payload = {
      tipe: "Daily",
      id_tahun_ajaran: this.currentPeriodeId,
      semester: this.currentSemester,
      id_tingkat: this.currentTingkatId
    }

    this.settingInsyaDailyService.getByType(payload).subscribe({
      next: resdata => {
        console.log("LIST TUGAS", resdata);
        this.listTugasKe = [];
        if (resdata !== null) {
          const jumlahTugas = resdata.jumlah_tugas;
          for (let j=1;j<=jumlahTugas;j++) {
            this.listTugasKe.push(j);
          }
        }

        // this.listKelas = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

}
