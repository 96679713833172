import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-print-tagihan',
  templateUrl: './main-print-tagihan.component.html',
  styleUrls: ['./main-print-tagihan.component.css']
})
export class MainPrintTagihanComponent implements OnInit {

  isPrintTagihan = false
  isTemplate = false
  isListInput = false
  isLaporan = false
  isMonitoring = false

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.changeTab(this.router.url)
  }

  changeTab(url: String) {
    switch (url) {
      case '/keuangan/print-tagihan':
        this.isPrintTagihan = true
        this.isTemplate = false
        this.isListInput = false
        this.isLaporan = false
        this.isMonitoring = false
        return;
      case '/keuangan/print-tagihan/template':
        this.isTemplate = true
        this.isPrintTagihan = false
        this.isListInput = false
        this.isLaporan = false
        this.isMonitoring = false
        return;      
    }
  }

}
