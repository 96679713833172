import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { NetworkService } from './helper/network.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { CommonService } from './helper/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  title = 'darunnajah-smart-system';
  
  isUserLoggedIn = false;
  
  constructor(private network: NetworkService,
    private commonService: CommonService, 
    private authService: AuthService, private permissionService: NgxPermissionsService, public datePipe: DatePipe) {
    //
  }

  ngOnInit(): void {
    this.network.setUrlApi();
    const apis = this.network.getUrlApi();
    console.log('selalu panggil : ', apis);

    this.isUserLoggedIn = this.authService.isUserLoggedIn();

    // set permission
    //const perm = ["ADMIN", "EDITOR"]; example of permissions
    const currentUser = this.commonService.getUserDetail();
    const permissions = this.commonService.getUserPermissions(currentUser);
    console.log('CUrrent upermissionser ', permissions);
    
    this.permissionService.loadPermissions(permissions);
  }
  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, 'd-MM-Y');
  }
}
