import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxPermissionsModule } from 'ngx-permissions';
import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthComponent } from './layout/auth/auth.component';
import { MainAppComponent } from './layout/main-app/main-app.component';
import { SidebarNavComponent } from './layout/sidebar-nav/sidebar-nav.component';
import { WINDOW_PROVIDERS } from './window.providers';
import { SdmListComponent } from './pages/sdm/sdm-list/sdm-list.component';
import { SdmAddComponent } from './pages/sdm/sdm-add/sdm-add.component';
import { PagesComponent } from './pages/pages.component';
import { RoleListComponent } from './pages/role/role-list/role-list.component';
import { DatePipe, CommonModule } from '@angular/common';
import { RoleAddComponent } from './pages/role/role-add/role-add.component';
import { RoleEditComponent } from './pages/role/role-edit/role-edit.component';
import { RoleViewComponent } from './pages/role/role-view/role-view.component';
import { PermissionAddComponent } from './pages/permission/permission-add/permission-add.component';
import { PermissionEditComponent } from './pages/permission/permission-edit/permission-edit.component';
import { PermissionViewComponent } from './pages/permission/permission-view/permission-view.component';
import { PermissionDeleteComponent } from './pages/permission/permission-delete/permission-delete.component';
import { UserListComponent } from './pages/user/user-list/user-list.component';
import { UserAddComponent } from './pages/user/user-add/user-add.component';
import { UserViewComponent } from './pages/user/user-view/user-view.component';
import { UserEditComponent } from './pages/user/user-edit/user-edit.component';
import { UserDeleteComponent } from './pages/user/user-delete/user-delete.component';
import { MenuListComponent } from './pages/menu/menu-list/menu-list.component';
import { MenuAddComponent } from './pages/menu/menu-add/menu-add.component';
import { MenuEditComponent } from './pages/menu/menu-edit/menu-edit.component';
import { MenuViewComponent } from './pages/menu/menu-view/menu-view.component';
import { MenuDeleteComponent } from './pages/menu/menu-delete/menu-delete.component';
import { KelasDeleteComponent } from './pages/kelas/kelas-delete/kelas-delete.component';
import { KelasListComponent } from './pages/kelas/kelas-list/kelas-list.component';
import { KelasAddComponent } from './pages/kelas/kelas-add/kelas-add.component';
import { KelasViewComponent } from './pages/kelas/kelas-view/kelas-view.component';
import { KelasEditComponent } from './pages/kelas/kelas-edit/kelas-edit.component';
import { PelajaranEditComponent } from './pages/pelajaran/pelajaran-edit/pelajaran-edit.component';
import { PelajaranListComponent } from './pages/pelajaran/pelajaran-list/pelajaran-list.component';
import { PelajaranAddComponent } from './pages/pelajaran/pelajaran-add/pelajaran-add.component';
import { PelajaranViewComponent } from './pages/pelajaran/pelajaran-view/pelajaran-view.component';
import { PelajaranDeleteComponent } from './pages/pelajaran/pelajaran-delete/pelajaran-delete.component';
import { RayonDeleteComponent } from './pages/rayon/rayon-delete/rayon-delete.component';
import { RayonListComponent } from './pages/rayon/rayon-list/rayon-list.component';
import { RayonAddComponent } from './pages/rayon/rayon-add/rayon-add.component';
import { RayonEditComponent } from './pages/rayon/rayon-edit/rayon-edit.component';
import { RayonViewComponent } from './pages/rayon/rayon-view/rayon-view.component';
import { TingakatanViewComponent } from './pages/tingkatan/tingakatan-view/tingakatan-view.component';
import { TingakatanListComponent } from './pages/tingkatan/tingakatan-list/tingakatan-list.component';
import { TingakatanAddComponent } from './pages/tingkatan/tingakatan-add/tingakatan-add.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LembagaViewComponent } from './pages/lembaga/lembaga-view/lembaga-view.component';
import { LembagaListComponent } from './pages/lembaga/lembaga-list/lembaga-list.component';
import { RolePermissionComponent } from './pages/role/role-permission/role-permission.component';
import { SiswaStatistikComponent } from './pages/siswa/siswa-statistik/siswa-statistik.component';
import { SiswaBaruComponent } from './pages/siswa/siswa-baru/siswa-baru.component';
import { SiswaPembayaranComponent } from './pages/siswa/siswa-pembayaran/siswa-pembayaran.component';
import { NgChartsModule } from 'ng2-charts';
import { TingakatanDeleteComponent } from './pages/tingkatan/tingakatan-delete/tingakatan-delete.component';
import { TingakatanEditComponent } from './pages/tingkatan/tingakatan-edit/tingakatan-edit.component';
import { TahunajaranAddComponent } from './pages/tahunajaran/tahunajaran-add/tahunajaran-add.component';
import { TahunajaranListComponent } from './pages/tahunajaran/tahunajaran-list/tahunajaran-list.component';
import { SiswaAddEditComponent } from './pages/siswa/siswa-add-edit/siswa-add-edit.component';
import { GelombangPendaftaranAddComponent } from './pages/gelombangpendaftaran/gelombang-pendaftaran-add/gelombang-pendaftaran-add.component';
import { GelombangPendaftaranListComponent } from './pages/gelombangpendaftaran/gelombang-pendaftaran-list/gelombang-pendaftaran-list.component';
import { GelombangPendaftaranEditComponent } from './pages/gelombangpendaftaran/gelombang-pendaftaran-edit/gelombang-pendaftaran-edit.component';
import { BerkasAddComponent } from './pages/berkas/berkas-add/berkas-add.component';
import { BerkasListComponent } from './pages/berkas/berkas-list/berkas-list.component';
import { BerkasEditComponent } from './pages/berkas/berkas-edit/berkas-edit.component';
import { TahunajaranEditComponent } from './pages/tahunajaran/tahunajaran-edit/tahunajaran-edit.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { ContentListComponent } from './pages/contentregis/content-list/content-list.component';
import { ContentAddComponent } from './pages/contentregis/content-add/content-add.component';
import { ContentEditComponent } from './pages/contentregis/content-edit/content-edit.component'; // import bn-ng-idle service
import { AngularEditorModule } from '@kolkov/angular-editor';
import { BiayaListComponent } from './pages/biaya/biaya-list/biaya-list.component';
import { BiayaAddComponent } from './pages/biaya/biaya-add/biaya-add.component';
import { BiayaEditComponent } from './pages/biaya/biaya-edit/biaya-edit.component';
import { GroupBiayaComponent } from './pages/keuangan/biaya/group-biaya/group-biaya.component';
import { ItemBiayaComponent } from './pages/keuangan/biaya/item-biaya/item-biaya.component';
import { PaketPembayaranComponent } from './pages/keuangan/biaya/paket-pembayaran/paket-pembayaran.component';
import { SetupTahunanComponent } from './pages/keuangan/biaya/setup-tahunan/setup-tahunan.component';
import { SiswaDokumenComponent } from './pages/siswa/siswa-dokumen/siswa-dokumen.component';
import { SiswaDuplicateNameComponent } from './pages/siswa/siswa-duplicate-name/siswa-duplicate-name.component';
import { SiswaExamDateComponent } from './pages/siswa/siswa-exam-date/siswa-exam-date.component';
import { SiswaExamLocationComponent } from './pages/siswa/siswa-exam-location/siswa-exam-location.component';
import { LokasiujianAddComponent } from './pages/lokasiujian/lokasiujian-add/lokasiujian-add.component';
import { LokasiujianListComponent } from './pages/lokasiujian/lokasiujian-list/lokasiujian-list.component';
import { LokasiujianEditComponent } from './pages/lokasiujian/lokasiujian-edit/lokasiujian-edit.component';
import { PpsbMainComponent } from './pages/ppsb/ppsb-main/ppsb-main.component';
import { PpsbListComponent } from './pages/ppsb/ppsb-list/ppsb-list.component';
import { PpsbNilaiComponent } from './pages/ppsb/ppsb-nilai/ppsb-nilai.component';
import { PpsbDeletedComponent } from './pages/ppsb/ppsb-deleted/ppsb-deleted.component';
import { HamburgerMenuComponent } from './icons/hamburger-menu/hamburger-menu.component';
import { SiswaPenempatanKelasComponent } from './pages/siswa/siswa-penempatan-kelas/siswa-penempatan-kelas.component';
import { SiswaBukuIndukComponent } from './pages/siswa/siswa-buku-induk/siswa-buku-induk.component';
import { SiswaTahunAjaranComponent } from './pages/siswa/siswa-tahun-ajaran/siswa-tahun-ajaran.component';
import { ShowSiswaBerkasPipe } from './pipes/show-siswa-berkas.pipe';
import { SdmAbsensiInsidentilComponent } from './pages/sdm/sdm-absensi-insidentil/sdm-absensi-insidentil.component';
import { SdmIzinMengajarComponent } from './pages/sdm/sdm-izin-mengajar/sdm-izin-mengajar.component';
import { SdmCatatanGuruComponent } from './pages/sdm/sdm-catatan-guru/sdm-catatan-guru.component';
import { SdmWalikelasComponent } from './pages/sdm/sdm-walikelas/sdm-walikelas.component';
import { SdmStrukturComponent } from './pages/sdm/sdm-struktur/sdm-struktur.component';
import { SdmStrukturListComponent } from './pages/sdm/sdm-struktur/sdm-struktur-list/sdm-struktur-list.component';
import { SdmStrukturSetupJabatanComponent } from './pages/sdm/sdm-struktur/sdm-struktur-setup-jabatan/sdm-struktur-setup-jabatan.component';
import { MataujiListComponent } from './pages/matauji/matauji-list/matauji-list.component';
import { MataujiAddComponent } from './pages/matauji/matauji-add/matauji-add.component';
import { MataujiEditComponent } from './pages/matauji/matauji-edit/matauji-edit.component';
import { PendidikanMainComponent } from './pages/pendidikan/pendidikan-main/pendidikan-main.component';
import { PendidikanInputGuruComponent } from './pages/pendidikan/pendidikan-input-guru/pendidikan-input-guru.component';
import { PendidikanCatatanSiswaComponent } from './pages/pendidikan/pendidikan-catatan-siswa/pendidikan-catatan-siswa.component';
import { PendidikanListInputComponent } from './pages/pendidikan/pendidikan-list-input/pendidikan-list-input.component';
import { PendidikanLaporanComponent } from './pages/pendidikan/pendidikan-laporan/pendidikan-laporan.component';
import { PendidikanMonitoringComponent } from './pages/pendidikan/pendidikan-monitoring/pendidikan-monitoring.component';
import { SdmCatatanGuruListComponent } from './pages/sdm/sdm-catatan-guru/sdm-catatan-guru-list/sdm-catatan-guru-list.component';
import { SdmCatatanGuruStatistikComponent } from './pages/sdm/sdm-catatan-guru/sdm-catatan-guru-statistik/sdm-catatan-guru-statistik.component';
import { SdmIzinMengajarInputComponent } from './pages/sdm/sdm-izin-mengajar/sdm-izin-mengajar-input/sdm-izin-mengajar-input.component';
import { SdmIzinMengajarLaporanComponent } from './pages/sdm/sdm-izin-mengajar/sdm-izin-mengajar-laporan/sdm-izin-mengajar-laporan.component';
import { SiswaPenempatanKelasStatistikComponent } from './pages/siswa/siswa-penempatan-kelas/siswa-penempatan-kelas-statistik/siswa-penempatan-kelas-statistik.component';
import { SiswaPenempatanKelasListComponent } from './pages/siswa/siswa-penempatan-kelas/siswa-penempatan-kelas-list/siswa-penempatan-kelas-list.component';
import { SiswaPenempatanKelasPeringkatComponent } from './pages/siswa/siswa-penempatan-kelas/siswa-penempatan-kelas-peringkat/siswa-penempatan-kelas-peringkat.component';
import { TugasComponent } from './pages/tugas/tugas.component';
import { TugasListComponent } from './pages/tugas/tugas-list/tugas-list.component';
import { TugasMonitoringComponent } from './pages/tugas/tugas-monitoring/tugas-monitoring.component';
import { KamarListComponent } from './pages/kamar/kamar-list/kamar-list.component';
import { GedungListComponent } from './pages/gedung/gedung-list/gedung-list.component';
import { BrtInputComponent } from './pages/brt/brt-input/brt-input.component';
import { BrtAlatComponent } from './pages/brt/brt-alat/brt-alat.component';
import { BrtListComponent } from './pages/brt/brt-list/brt-list.component';
import { NewsComponent } from './pages/news/news.component';
import { DailyNewsComponent } from './pages/news/daily-news/daily-news.component';
import { MonthlyNewsComponent } from './pages/news/monthly-news/monthly-news.component';
import { NavTabComponent } from './pages/siswa/nav-tab/nav-tab.component';
import { SiswaPenetuankelasComponent } from './pages/siswa/siswa-penetuankelas/siswa-penetuankelas.component';
import { SiswaDetailComponent } from './pages/siswa/siswa-detail/siswa-detail.component';
import { SiswaDetailKesehatanComponent } from './pages/siswa/siswa-detail/components/kesehatan/siswa-detail-kesehatan.component';
import { SiswaDetailBiodataComponent } from './pages/siswa/siswa-detail/components/biodata/siswa-detail-biodata.component/siswa-detail-biodata.component';
import { SiswaDetailPelanggaranComponent } from './pages/siswa/siswa-detail/components/pelanggaran/siswa-detail-pelanggaran.component';
import { SiswaDetailKamarComponent } from './pages/siswa/siswa-detail/components/kamar/siswa-detail-kamar/siswa-detail-kamar.component';
import { SiswaDetailAbsenKamarComponent } from './pages/siswa/siswa-detail/components/kamar/siswa-detail-absen-kamar/siswa-detail-absen-kamar.component';
import { SiswaDetailDepositComponent } from './pages/siswa/siswa-detail/components/transaksi/siswa-detail-deposit/siswa-detail-deposit.component';
import { SiswaDetailTagihanComponent } from './pages/siswa/siswa-detail/components/transaksi/siswa-detail-tagihan/siswa-detail-tagihan.component';
import { SiswaDetailKelasComponent } from './pages/siswa/siswa-detail/components/kelas/siswa-detail-kelas/siswa-detail-kelas.component';
import { SiswaDetailPrestasiComponent } from './pages/siswa/siswa-detail/components/prestasi/siswa-detail-prestasi/siswa-detail-prestasi.component';
import { SiswaDetailCatatanGuruComponent } from './pages/siswa/siswa-detail/components/catatan-guru/siswa-detail-catatan-guru/siswa-detail-catatan-guru.component';
import { SiswaDetailLogComponent } from './pages/siswa/siswa-detail/components/logs/siswa-detail-log/siswa-detail-log.component';
import { SiswaDetailLogVaComponent } from './pages/siswa/siswa-detail/components/logs/siswa-detail-log-va/siswa-detail-log-va.component';
import { SiswaDetailIzinComponent } from './pages/siswa/siswa-detail/components/kelas/siswa-detail-izin/siswa-detail-izin.component';
import { SiswaDetailTransaksiComponent } from './pages/siswa/siswa-detail/components/transaksi/siswa-detail-transaksi/siswa-detail-transaksi.component';
import { DisplayPresentasePipe } from './pipes/display-presentase.pipe';
import { SiswaPenempatanKelasDetailComponent } from './pages/siswa/siswa-penempatan-kelas/siswa-penempatan-kelas-detail/siswa-penempatan-kelas-detail.component';
import { JadwalMengajarComponent } from './pages/pendidikan/jadwal-mengajar/jadwal-mengajar.component';
import { JadwalMengajarMainComponent } from './pages/pendidikan/jadwal-mengajar-main/jadwal-mengajar-main.component';
import { JadwalMengajarUploadComponent } from './pages/pendidikan/jadwal-mengajar-upload/jadwal-mengajar-upload.component';
import { JadwalMengajarJadwalGuruComponent } from './pages/pendidikan/jadwal-mengajar-jadwal-guru/jadwal-mengajar-jadwal-guru.component';
import { JadwalMengajarRekapInputComponent } from './pages/pendidikan/jadwal-mengajar-rekap-input/jadwal-mengajar-rekap-input.component';
import { JadwalMengajarLiburMengajarComponent } from './pages/pendidikan/jadwal-mengajar-libur-mengajar/jadwal-mengajar-libur-mengajar.component';
import { JadwalMengajarPerKelasComponent } from './pages/pendidikan/jadwal-mengajar-per-kelas/jadwal-mengajar-per-kelas.component';
import { ClassroomNavComponent } from './pages/pendidikan/classroom/classroom-nav/classroom-nav.component';
import { MonitoringSiswaComponent } from './pages/pendidikan/classroom/monitoring-siswa/monitoring-siswa.component';
import { MonitoringGuruComponent } from './pages/pendidikan/classroom/monitoring-guru/monitoring-guru.component';
import { InputNilaiHarianNavComponent } from './pages/pendidikan/input-nilai-harian-nav/input-nilai-harian-nav.component';
import { InputNilaiHarianAlQuranComponent } from './pages/pendidikan/input-nilai-harian-al-quran/input-nilai-harian-al-quran.component';
import { InputNilaiHarianInysaYaumiComponent } from './pages/pendidikan/input-nilai-harian-inysa-yaumi/input-nilai-harian-inysa-yaumi.component';
import { InputNilaiHarianDailyCompositionComponent } from './pages/pendidikan/input-nilai-harian-daily-composition/input-nilai-harian-daily-composition.component';
import { LaporanNilaiAlQuranComponent } from './pages/pendidikan/laporan-nilai-al-quran/laporan-nilai-al-quran.component';
import { LaporanInsyaDailyComponent } from './pages/pendidikan/laporan-insya-daily/laporan-insya-daily.component';
import { LaporanPenginputComponent } from './pages/pendidikan/laporan-penginput/laporan-penginput.component';
import { TahunajaranNavComponent } from './pages/tahunajaran/tahunajaran-nav/tahunajaran-nav.component';
import { SemesterComponent } from './pages/tahunajaran/semester/semester.component';
import { DetailJadwalMengajarPerGuruComponent } from './pages/pendidikan/detail-jadwal-mengajar-per-guru/detail-jadwal-mengajar-per-guru.component';
import { DokumenComponent } from './pages/dokumen/dokumen.component';
import { SimulasiComponent } from './pages/keuangan/simulasi/simulasi.component';
import { SidebarNavTkComponent } from './layout/sidebar-nav-tk/sidebar-nav-tk.component';
import { GenerateComponent } from './pages/keuangan/generate/generate.component';
import { IzinMengajarComponent } from './pages/pendidikan/izin-mengajar/izin-mengajar.component';
import { ParseStatusPipe } from './pipes/parse-status.pipe';
import { KepsekComponent } from './pages/tahunajaran/kepsek/kepsek.component';
import { DetailAbsensiHarianSiswaComponent } from './pages/pendidikan/detail-absensi-harian-siswa/detail-absensi-harian-siswa.component';
import { InputNilaiIbadahAmaliahComponent } from './pages/pendidikan/input-nilai-ibadah-amaliah/input-nilai-ibadah-amaliah.component';
import { InsyaDailyComponent } from './pages/insya-daily/insya-daily.component';
import { InputNilaiRaportHarianComponent } from './pages/pendidikan/input-nilai-raport-harian/input-nilai-raport-harian.component';
import { InputNilaiRaportAkhirComponent } from './pages/pendidikan/input-nilai-raport-akhir/input-nilai-raport-akhir.component';
import { InputNilaiRaportNonNilaiComponent } from './pages/pendidikan/input-nilai-raport-non-nilai/input-nilai-raport-non-nilai.component';
import { PrintTagihanComponent } from './pages/keuangan/print-tagihan/print-tagihan.component';
import { MainPrintTagihanComponent } from './pages/keuangan/main-print-tagihan/main-print-tagihan.component';
import { TemplatePrintTagihanComponent } from './pages/keuangan/template-print-tagihan/template-print-tagihan.component';
import { PdfPrintTagihanComponent } from './pages/keuangan/pdf-print-tagihan/pdf-print-tagihan.component';
import { LaporanTagihanComponent } from './pages/keuangan/laporan-tagihan/laporan-tagihan.component';
import { TagihanSiswaComponent } from './pages/keuangan/tagihan-siswa/tagihan-siswa.component';
import { LogActivityComponent } from './pages/log-activity/log-activity.component';
import { ListkamarComponent } from './pages/pengasuhan/listkamar/listkamar.component';
import { TabListkamarComponent } from './pages/pengasuhan/tab-listkamar/tab-listkamar.component';
import { KamarsiswaComponent } from './pages/pengasuhan/kamarsiswa/kamarsiswa.component';
import { PrintkamarComponent } from './pages/pengasuhan/printkamar/printkamar.component';
import { AbsensiKamarComponent } from './pages/pengasuhan/absensi-kamar/absensi-kamar.component';
import { NavAbsensiKamarComponent } from './pages/pengasuhan/nav-absensi-kamar/nav-absensi-kamar.component';
import { ListAbsenKamarComponent } from './pages/pengasuhan/list-absen-kamar/list-absen-kamar.component';
import { LaporanPersiswaComponent } from './pages/pengasuhan/laporan-persiswa/laporan-persiswa.component';
import { LaporanPerkamarComponent } from './pages/pengasuhan/laporan-perkamar/laporan-perkamar.component';
import { MonitoringAbsenKamarComponent } from './pages/pengasuhan/monitoring-absen-kamar/monitoring-absen-kamar.component';
import { MonitoringPenginputAbsenKamarComponent } from './pages/pengasuhan/monitoring-penginput-absen-kamar/monitoring-penginput-absen-kamar.component';
import { DetailAbsensiKamarComponent } from './pages/pengasuhan/detail-absensi-kamar/detail-absensi-kamar.component';
import { ListIzinSantriComponent } from './pages/pengasuhan/list-izin-santri/list-izin-santri.component';
import { ScanBarcodeComponent } from './pages/pengasuhan/scan-barcode/scan-barcode.component';
import { LaporanIzinPerkelasComponent } from './pages/pengasuhan/laporan-izin-perkelas/laporan-izin-perkelas.component';
import { PelanggaranComponent } from './pages/pengasuhan/pelanggaran/pelanggaran.component';
import { PrestasiComponent } from './pages/pengasuhan/prestasi/prestasi.component';
import { NavIzinSantriComponent } from './pages/pengasuhan/nav-izin-santri/nav-izin-santri.component';
import { KategoriAlatComponent } from './pages/brt/kategori-alat/kategori-alat.component';
import { GenerateSuratJalanComponent } from './pages/pengasuhan/generate-surat-jalan/generate-surat-jalan.component';
import { NavAbsensiKegiatanComponent } from './pages/absensi-kegiatan/nav-absensi-kegiatan/nav-absensi-kegiatan.component';
import { InputAbsensiKegiatanComponent } from './pages/absensi-kegiatan/input-absensi-kegiatan/input-absensi-kegiatan.component';

registerLocaleData(localeId, 'id');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    SidebarNavComponent,
    AuthComponent,
    MainAppComponent,
    SdmListComponent,
    SdmAddComponent,
    PagesComponent,
    RoleListComponent,
    RoleAddComponent,
    RoleEditComponent,
    RoleViewComponent,
    PermissionAddComponent,
    PermissionEditComponent,
    PermissionViewComponent,
    PermissionDeleteComponent,
    UserListComponent,
    UserAddComponent,
    UserViewComponent,
    UserEditComponent,
    UserDeleteComponent,
    MenuListComponent,
    MenuAddComponent,
    MenuEditComponent,
    MenuViewComponent,
    MenuDeleteComponent,
    KelasDeleteComponent,
    KelasListComponent,
    KelasAddComponent,
    KelasViewComponent,
    KelasEditComponent,
    PelajaranEditComponent,
    PelajaranListComponent,
    TingakatanDeleteComponent,
    PelajaranAddComponent,
    PelajaranViewComponent,
    PelajaranDeleteComponent,
    RayonDeleteComponent,
    RayonListComponent,
    RayonAddComponent,
    RayonEditComponent,
    RayonViewComponent,
    TingakatanViewComponent,
    TingakatanListComponent,
    TingakatanAddComponent,
    LembagaViewComponent,
    LembagaListComponent,
    TingakatanEditComponent,
    TingakatanDeleteComponent,
    RolePermissionComponent,
    SiswaStatistikComponent,
    SiswaBaruComponent,
    TahunajaranAddComponent,
    TahunajaranListComponent,
    SiswaAddEditComponent,
    GelombangPendaftaranAddComponent,
    GelombangPendaftaranListComponent,
    GelombangPendaftaranEditComponent,
    BerkasAddComponent,
    BerkasListComponent,
    BerkasEditComponent,
    TahunajaranEditComponent,
    ContentListComponent,
    ContentAddComponent,
    ContentEditComponent,
    BiayaListComponent,
    BiayaAddComponent,
    BiayaEditComponent,
    GroupBiayaComponent,
    ItemBiayaComponent,
    PaketPembayaranComponent,
    SetupTahunanComponent,
    SiswaPembayaranComponent,
    SiswaDokumenComponent,
    SiswaDuplicateNameComponent,
    SiswaExamDateComponent,
    SiswaExamLocationComponent,
    LokasiujianAddComponent,
    LokasiujianListComponent,
    LokasiujianEditComponent,
    PpsbMainComponent,
    PpsbListComponent,
    PpsbNilaiComponent,
    PpsbDeletedComponent,
    HamburgerMenuComponent,
    SiswaPenempatanKelasComponent,
    SiswaBukuIndukComponent,
    SiswaTahunAjaranComponent,
    ShowSiswaBerkasPipe,
    SdmAbsensiInsidentilComponent,
    SdmIzinMengajarComponent,
    SdmCatatanGuruComponent,
    SdmWalikelasComponent,
    SdmStrukturComponent,
    SdmStrukturListComponent,
    SdmStrukturSetupJabatanComponent,
    MataujiListComponent,
    MataujiAddComponent,
    MataujiEditComponent,
    PendidikanMainComponent,
    PendidikanInputGuruComponent,
    PendidikanCatatanSiswaComponent,
    PendidikanListInputComponent,
    PendidikanLaporanComponent,
    PendidikanMonitoringComponent,
    SdmCatatanGuruListComponent,
    SdmCatatanGuruStatistikComponent,
    SdmIzinMengajarInputComponent,
    SdmIzinMengajarLaporanComponent,
    SiswaPenempatanKelasStatistikComponent,
    SiswaPenempatanKelasListComponent,
    SiswaPenempatanKelasPeringkatComponent,
    TugasComponent,
    TugasListComponent,
    TugasMonitoringComponent,
    KamarListComponent,
    GedungListComponent,
    BrtInputComponent,
    BrtAlatComponent,
    BrtListComponent,
    NewsComponent,
    DailyNewsComponent,
    MonthlyNewsComponent,
    NavTabComponent,
    SiswaPenetuankelasComponent,
    SiswaDetailComponent,
    SiswaDetailKesehatanComponent,
    SiswaDetailBiodataComponent,
    SiswaDetailPelanggaranComponent,
    SiswaDetailKamarComponent,
    SiswaDetailAbsenKamarComponent,
    SiswaDetailDepositComponent,
    SiswaDetailTagihanComponent,
    SiswaDetailKelasComponent,
    SiswaDetailPrestasiComponent,
    SiswaDetailCatatanGuruComponent,
    SiswaDetailLogComponent,
    SiswaDetailLogVaComponent,
    SiswaDetailIzinComponent,
    SiswaDetailTransaksiComponent,
    DisplayPresentasePipe,
    SiswaPenempatanKelasDetailComponent,
    JadwalMengajarComponent,
    JadwalMengajarMainComponent,
    JadwalMengajarUploadComponent,
    JadwalMengajarJadwalGuruComponent,
    JadwalMengajarRekapInputComponent,
    JadwalMengajarLiburMengajarComponent,
    JadwalMengajarPerKelasComponent,
    ClassroomNavComponent,
    MonitoringSiswaComponent,
    MonitoringGuruComponent,
    InputNilaiHarianNavComponent,
    InputNilaiHarianAlQuranComponent,
    InputNilaiHarianInysaYaumiComponent,
    InputNilaiHarianDailyCompositionComponent,
    LaporanNilaiAlQuranComponent,
    LaporanInsyaDailyComponent,
    LaporanPenginputComponent,
    TahunajaranNavComponent,
    SemesterComponent,
    DetailJadwalMengajarPerGuruComponent,
    DokumenComponent,
    SimulasiComponent,
    SidebarNavTkComponent,
    GenerateComponent,
    IzinMengajarComponent,
    ParseStatusPipe,
    KepsekComponent,
    DetailAbsensiHarianSiswaComponent,
    InputNilaiIbadahAmaliahComponent,
    InsyaDailyComponent,
    InputNilaiRaportHarianComponent,
    InputNilaiRaportAkhirComponent,
    InputNilaiRaportNonNilaiComponent,
    PrintTagihanComponent,
    MainPrintTagihanComponent,
    TemplatePrintTagihanComponent,
    PdfPrintTagihanComponent,
    LaporanTagihanComponent,
    TagihanSiswaComponent,
    LogActivityComponent,
    ListkamarComponent,
    TabListkamarComponent,
    KamarsiswaComponent,
    PrintkamarComponent,
    AbsensiKamarComponent,
    NavAbsensiKamarComponent,
    ListAbsenKamarComponent,
    LaporanPersiswaComponent,
    LaporanPerkamarComponent,
    MonitoringAbsenKamarComponent,
    MonitoringPenginputAbsenKamarComponent,
    DetailAbsensiKamarComponent,
    ListIzinSantriComponent,
    ScanBarcodeComponent,
    LaporanIzinPerkelasComponent,
    PelanggaranComponent,
    PrestasiComponent,
    NavIzinSantriComponent,
    KategoriAlatComponent,
    GenerateSuratJalanComponent,
    NavAbsensiKegiatanComponent,
    InputAbsensiKegiatanComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    NgbModule,
    NgChartsModule,
    CommonModule,
    AngularEditorModule,
    NgxPermissionsModule.forRoot()
  ],
  providers: [WINDOW_PROVIDERS,DatePipe,BnNgIdleService, { provide: LOCALE_ID, useValue: "id-ID" }],
  bootstrap: [AppComponent]
})
export class AppModule { }
