import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class TingkatService {

  constructor(private netService: NetworkService) { }

  getAllTingkat(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/tingkat', params);
  }

  getTingkatByLembagaId(id: any): Observable<any> {
    return this.netService.getRequest(`/v1/tingkatbylembaga/${id}`);
  }
}
