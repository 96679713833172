import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-siswa-detail-kesehatan',
  templateUrl: './siswa-detail-kesehatan.component.html',
  styleUrls: ['./siswa-detail-kesehatan.component.css']
})
export class SiswaDetailKesehatanComponent implements OnInit {

  @Input() selectedData: any = null;

  constructor() { }

  ngOnInit(): void {

  }

  closeButton() {

  }

  getCurrentSiswaById(siswaId: any) {

  }

  handlePageChange(event: number): void {

  }

}
