import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tab-listkamar',
  templateUrl: './tab-listkamar.component.html',
  styleUrls: ['./tab-listkamar.component.css']
})
export class TabListkamarComponent implements OnInit {

  isKamarPeriode = false;
  isKamarSiswa = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    console.log(this.router.url);
    switch (this.router.url) {
      case '/pengasuhan/listkamar':
        this.isKamarPeriode = true;
        this.isKamarSiswa = false;        
        break;

      case '/pengasuhan/listsiswakamar':
        this.isKamarPeriode = false;
        this.isKamarSiswa = true;        
        break;      

      default:
        this.isKamarPeriode = false;
        this.isKamarSiswa = false;        
        break;
    }
  }

}
