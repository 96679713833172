<div class="list-options pull-right">
    <a href="/permission/create" class="btn btn-warning">
       + PERMISSION
    </a>
</div>
<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
        <div class="tabs-title">
            LIST PERMISSION
        </div>
        <div class="table-wrapper">
            <div class="table-options">
                <div class="show-table">
                    Show<input type="number" class="form-control" name="show" id="show" value="10">entries
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Nama</th>
                            <th>Status</th>
                            <th>Tanggal</th>
                            <th>Aksi</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let permission of permissions; let i = index">
                        <tr>
                            <td>{{ i+1 }}</td>
                            <td>{{ permission.permission_name }}</td>
                            <td>{{ permission.active ? "Aktif" : "Tidak Aktif" }}</td>
                            <td>{{ transformDate(permission.createdAt) }}</td>
                            <td>
                                <!-- <a href="#" [routerLink]="['/permission/', permission.id, 'view']" class="btn btn-info">View</a> -->
                                &nbsp; 
                                <!--a *ngIf="role.editable" href="#" [routerLink]="['/role/', role.id, 'edit']" class="btn btn-primary">Edit</a> 
                                &nbsp;-->
                                <button type="button" (click)="deletePermission(permission.id)" class="btn btn-danger">Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="pagination-wrapper">
                <div></div>
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-center pagination-number">
                        <li class="page-item active">
                            <a class="page-link" href="#">1</a>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item"><a class="page-link" href="#">...</a></li>
                        <li class="page-item"><a class="page-link" href="#">6</a></li>
                    </ul>
                </nav>
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-center">
                        <li class="page-item disabled">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span>Previous</span>
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span>Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        
    </div>
    
</div>
