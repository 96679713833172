<div class="list-options">
  <button class="btn btn-blue-grey">
    + Tambah Jabatan
  </button>
  <div class="search">
    <input
      type="text"
      class="form-control"
      placeholder="Nama Struktur.."
      name="search-list"
      id="search-list"
      [(ngModel)]="searchTerm"
    />
    <div class="icon" (click)="search()">
      <img src="./assets/images/icon-search.png" alt="Search" />
    </div>
  </div>
</div>
