import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LembagaService } from 'src/app/services/lembaga.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-lembaga-list',
  templateUrl: './lembaga-list.component.html',
  styleUrls: ['./lembaga-list.component.css']
})
export class LembagaListComponent implements OnInit {

  listLembaga: any = [];
  errorMessage = '';

  formAddLembaga: FormGroup = new FormGroup({
    nama_lembaga: new FormControl(''),
    urut: new FormControl(''),
  });
  submitted = false;
  isNew = true;
  editLembagaId = null;

  pageid = "lembaga";

  nama = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];

  closeResult: string = '';

  constructor(
    private lembagaService: LembagaService, 
    private uiService: UiService, 
    private datePipe: DatePipe,
    private modalService: NgbModal, 
    private formBuilder: FormBuilder
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getAllLembaga();

    this.formAddLembaga = this.formBuilder.group(
      {
        nama_lembaga: [
          '',
          [
            Validators.required,
          ]
        ],
        urut: ['', Validators.required],
      }
    );
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  openModal(content:any) {
    this.isNew = true;
    this.submitted = false;
    this.formAddLembaga.reset();
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  openModalEdit(content:any, lembaga: any) {
    console.log(lembaga);
    this.editLembagaId = lembaga.id;
    this.isNew = false;
    this.formAddLembaga.patchValue(lembaga)
    this.submitted = false;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
     
  /**
   * Write code on Method
   *
   * @return response()
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getRequestParams(searchNama: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`nama`] = searchNama ? searchNama : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getAllLembaga() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.nama, this.page, this.pageSize);

    this.lembagaService.getAllLembaga(params).subscribe({
      next: resdata => {
        console.log(resdata);
        // const { data, totalrows } = resdata;
        const totalrows = 20;
        this.listLembaga = resdata;
        this.count = totalrows;
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.getAllLembaga();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getAllLembaga();
  }

  search(): void {
    this.page = 1;
    this.getAllLembaga();
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/empty-image.png';
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAddLembaga.controls;
  }

  onSubmitLembaga(): void {
    this.submitted = true;
    
    if (this.formAddLembaga.invalid) {
      return;
    }
    
    this.modalService.dismissAll();
    this.uiService.showLoader();


    if (this.isNew) {
      this.lembagaService.add(this.formAddLembaga.value).subscribe({
        next: resdata => {
          this.submitted = false;
          this.formAddLembaga.reset();
          console.log(resdata);
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
          this.getAllLembaga();
        },
        error: err => {
          console.log(err);
          this.submitted = false;
          this.formAddLembaga.reset();
          
          this.errorMessage = err.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    } else {
      this.lembagaService.update(this.editLembagaId, this.formAddLembaga.value).subscribe({
        next: resdata => {
          this.submitted = false;
          this.formAddLembaga.reset();
          console.log(resdata);
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
          this.getAllLembaga();
        },
        error: err => {
          console.log(err);
          this.submitted = false;
          this.formAddLembaga.reset();
          
          this.errorMessage = err.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }


    console.log(JSON.stringify(this.formAddLembaga.value, null, 2));
  }

  confirmDelete(id: String) {
    this.uiService.showConfirmDelete(this, id);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.lembagaService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getAllLembaga();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, 'd - MM - Y');
  }

}
