<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
      <div class="tabs-title">
          LIST KAMAR
      </div>
  
      <div class="list-options">
        <button class="btn btn-blue-grey" (click)="openFormKamarModal(FormKamarModal)">
          + Tambah
        </button>
        <button class="btn btn-success">
          Excel
        </button>
        
        <div class="search">
          <input
            type="text"
            class="form-control"
            placeholder="Nama Siswa.."
            name="search-list"
            id="search-list"
            [(ngModel)]="searchTerm"
          />
          <div class="icon" (click)="search()">
            <img src="./assets/images/icon-search.png" alt="Search" />
          </div>
        </div>
      </div>
      
      <div class="table-wrapper">
        <div class="table-options">
          <div class="show-table">
            Show
            <select
              class="form-control"
              name="show"
              id="show"
              (change)="handlePageSizeChange($event)"
            >
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
            entries
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th>No.</th>
                <th>Asrama/Rayon</th>
                <th>Nama Kamar</th>
                <th>Musyrif/ah</th>
                <th>Kuota</th>
                <th>Kategori</th>
                <th>Status</th>
                <th></th>          
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let kamar of listKamar
                    | paginate
                      : {
                          id: pageid,
                          itemsPerPage: pageSize,
                          currentPage: page,
                          totalItems: count
                        };
                  let i = index
                "
              >
                <td>{{ i + 1 + (page - 1) * pageSize }}</td>
                <td>{{ kamar.RayonKamar.nama_rayon }}</td>
                <td>{{ kamar.nama_kamar }}</td>
                <td><div *ngIf="kamar.ProguserKamar != null">{{ kamar.ProguserKamar.userProfile.real_name }}</div></td>
                <td>{{ kamar.kuota }}</td>          
                <td>{{ kamar.kategori }}</td>
                <td>{{ kamar.active }}</td>
                <td class="settings">
                  <div class="dropend">
                      <a href="#" role="button" id="dropdownMenuLink"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <img src="./assets/images/icon-setting.svg" alt="">
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          <li><a (click)="openEditFormKamarModal(FormKamarModal, kamar)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>                            
                          <li><a (click)="confirmDelete(kamar.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                      </ul>
                  </div>
              </td>
              </tr>
              <tr *ngIf="listKamar.length === 0" class="empty">
                <td colspan="16">Tidak terdapat data atau data tidak ditemukan.</td>
              </tr>
            </tbody>
          </table>
        </div>
      
        <pagination-template
          #p="paginationApi"
          [id]="pageid"
          (pageChange)="handlePageChange($event)"
        >
          <div
            *ngIf="isShowPagination; else emptyPagination"
            class="pagination-wrapper"
          >
            <div></div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center pagination-number">
                <li
                  class="page-item"
                  *ngFor="let page of p.pages"
                  [class.active]="p.getCurrent() === page.value"
                >
                  <a
                    class="page-link"
                    (click)="p.setCurrent(page.value)"
                    *ngIf="p.getCurrent() !== page.value"
                  >
                    {{ page.label }}
                  </a>
                  <a class="page-link" *ngIf="p.getCurrent() === page.value">
                    {{ page.label }}
                  </a>
                </li>
              </ul>
            </nav>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="p.isFirstPage()">
                  <a
                    class="page-link"
                    *ngIf="!p.isFirstPage()"
                    (click)="p.previous()"
                    aria-label="Previous"
                  >
                    <span>Previous</span>
                  </a>
                </li>
                <li class="page-item" [class.disabled]="p.isLastPage()">
                  <a
                    class="page-link"
                    *ngIf="!p.isLastPage()"
                    (click)="p.next()"
                    aria-label="Next"
                  >
                    <span>Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </pagination-template>
      
        <ng-template #emptyPagination>
          <div class="pagination-wrapper">
            <div>&nbsp;</div>
          </div>
        </ng-template>
      </div>
      
      <ng-template #FormKamarModal let-modal>
        <div class="modal-body">
            <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
            <div class="modal-form">
                <h1 class="text-center" *ngIf="isNew">Tambah Form Kamar</h1>
                <h1 class="text-center" *ngIf="!isNew">Edit Form Kamar</h1>
                <form [formGroup]="formAddKamar" (ngSubmit)="onSubmitKamar(modal)">
      
                  <div class="mb-3 mt-4 form-group row">
                    <label for="input-id-1" class="col-sm-3 control-label">Rayon</label>
                    <div class="col-sm-9">
                      <select
                        formControlName="id_rayon"
                        [ngClass]="{ 'is-invalid': submitted && f['id_rayon'].errors }"
                        class="form-select">
                        <option value="" selected disabled>Pilih</option>
                        <option [value]="data.id" *ngFor="let data of listRayon">{{data.nama_rayon}}</option>
                      </select>
                    </div>
                  </div>
      
                  <div class="row mb-3">
                    <label for="inputNamaSiswa" class="col-sm-3 col-form-label control-label">Nama Kamar</label>
                    <div class="col-sm-9">
                      <input formControlName="nama_kamar" type="text" class="form-control" 
                        [ngClass]="{ 'is-invalid': submitted && f['nama_kamar'].errors }"
                        autocorrect="off" autocomplete="off" id="inputNamaKamar">
                    </div>
                  </div>
      
                  <div class="row mb-3">
                    <label for="inputNamaSiswa" class="col-sm-3 col-form-label control-label">Kuota</label>
                    <div class="col-sm-9">
                      <input formControlName="kuota" type="number" class="form-control" 
                        [ngClass]="{ 'is-invalid': submitted && f['kuota'].errors }" 
                        autocorrect="off" autocomplete="off" id="inputNamaKamar">
                    </div>
                  </div>
      
                  <div class="row mb-3">
                    <label for="inputNamaPenguji" class="col-sm-3 col-form-label control-label">Musyrif/ah</label>
                    <div class="col-sm-9 autocomplete">
                      <input formControlName="musyrifah_name" (keyup)="searchMusyrifah()" type="text" class="form-control" autocapitalize="off" autocorrect="off" autocomplete="off" id="inputNamaPenguji">
      
                      <div id="myInputautocomplete-list-penguji" class="autocomplete-items">
                        <button *ngIf="isShowLoaderPenguji" class="btn btn-primary" type="button" disabled>
                          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                          Searching...
                        </button>
                        <div (click)="chooseMusyrifah(data)" *ngFor="let data of listMusyrifah">
                          {{ data.userProfile.real_name }}
                        </div>
      
                      </div>
                    </div>
                  </div>
      
                  <div class="mb-3 mt-4 form-group row">
                    <label for="input-id-1" class="col-sm-3 control-label">Kategori</label>
                    <div class="col-sm-9">
                      <select class="form-control"
                        formControlName="kategori"
                        [ngClass]="{ 'is-invalid': submitted && f['kategori'].errors }"
                        (change)="changeKategori($event)">
                        <option value="">PILIH</option>
                        <option value="PUTRA">Putra</option>
                        <option value="PUTRI">Putri</option>
                      </select>
                    </div>
                  </div>
      
                  <div class="mb-3 mt-4 form-group row">
                    <label for="input-id-1" class="col-sm-3 control-label">Tempat Tidur</label>
                    <div class="col-sm-9">
                      <select class="form-control"
                        formControlName="tempat_tidur"
                        [ngClass]="{ 'is-invalid': submitted && f['tempat_tidur'].errors }"
                        (change)="changeTempatTidur($event)">
                        <option value="">PILIH</option>
                        <option value="1_TINGKAT">2 Tingkat</option>
                        <option value="2_TINGKAT">1 Tingkat</option>
                      </select>
                    </div>
                  </div>
      
                  <div class="mb-3 mt-4 form-group row">
                    <label for="input-id-1" class="col-sm-3 control-label">Status</label>
                    <div class="col-sm-9">
                      <select class="form-control"
                        formControlName="active"
                        [ngClass]="{ 'is-invalid': submitted && f['active'].errors }"
                        (change)="changeStatus($event)">
                        <option value="">PILIH</option>
                        <option value="t">Aktif</option>
                        <option value="f">Non Aktif</option>
                      </select>
                    </div>
                  </div>
      
                  <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isNew">CREATE</button>
                  <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="!isNew">Update</button>
                </form>
            </div>
        </div>
      </ng-template>

  </div>
</div>