import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-daily-news',
  templateUrl: './daily-news.component.html',
  styleUrls: ['./daily-news.component.css']
})
export class DailyNewsComponent implements OnInit {

  listDailyNews: any = [];
  dailyNewsHeader: any = [];
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  closeResult: string = '';

  constructor() { }

  ngOnInit(): void {
    this.changeBodyClass();
    this.headerColumn();
  }

  headerColumn() {
    const headers = []
    for (let index = 0; index < 31; index++) {
      headers.push(index + 1)
    }
    headers.unshift('Nama / Tanggal');
    headers.push('Total');
    this.dailyNewsHeader = headers;
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add('siswa-baru-list');
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    console.log('handle PAGE', this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  search(): void {
    this.page = 1;
  }

}
