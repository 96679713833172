import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class KamarService {

  constructor(private netService: NetworkService) { }

  getAll(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/kamar', params);
  }

  getByRayon(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/kamarbyrayon', params);
  }

  getByKategori(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/kamarbykategori', params);
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/kamar');
  }

  get(id: string): Observable<any> {
    return this.netService.getRequest(`/v1/kamar/${id}`);
  }

  getByPeriode(payload: any, id: string): Observable<any> {
    return this.netService.postRequest(payload, `/v1/kamarbyperiode/${id}`);
  }

  getPeriodeKamarByKamar(payload: any): Observable<any> {
    return this.netService.postRequest(payload, `/v1/periodekamarbykamar`);
  }

  update(id: string, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/kamar/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/kamar/${id}`);
  }
}
