<!-- Each sheet element should have the class "sheet" -->
<!-- "padding-**mm" is optional: you can set 10, 15, 20 or 25 -->
<button class="noprint" (click)="downloadPdf()">DOWNLOAD</button>
&nbsp;<button class="noprint" (click)="printPage()">PRINT</button>

<section class="sheet padding-10mm">

    <div class="invoice-header"> <img src="./../../../assets/images/Logo-DN.png" alt="Company Logo"
            style="width:80px; max-width:80px;">
        <p> PESANTREN DARUNNAJAH JAKARTA<br> Jl. Ulujami Raya No. 86 Pesanggarahan Jakarta Selatan 12250 </p>
        <br>
    </div>
    <hr>

    <h5 class="text-center">{{ currentPayload.nama_kamar }}</h5>

    <br>
    <div class="mt-3">
        <table>
            <tr>
                <td width="100px"></td>
                <td>MUSYRIFAH</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                <td>{{ currentPayload.musyrifah }}</td>
            </tr>
            <tr>
                <td></td>
                <td>ASAL</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>NO TLP/HP</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                <td>{{ currentPayload.no_hp1 }}</td>
            </tr>
        </table>
    </div>
    <br>
    <div class="table-responsive mt-3">
        <table class="table">
            <thead class="table-light">
                <tr>
                    <th>No.</th>
                    <th>No. Induk</th>
                    <th>Nama</th>
                    <th>Kelas</th>
                    <th>Kota/Kabupaten</th>
                    <th>No. Telp Ayah</th>
                    <th>No. Telp Ibu</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="
                    let kamar of listSiswaPerKamar 
                    let i = index
                ">
                    <td>{{ i + 1 }}</td>
                    <td>{{ kamar.Siswa.no_induk }}</td>
                    <td>{{ kamar.Siswa.nama_lengkap }}</td>
                    <td>{{ kamar.Siswa.siswaKelas !== null ? kamar.Siswa.siswaKelas.Kelas.nama_kelas : "" }}</td>
                    <td>{{ kamar.Siswa.kota_kab }}</td>
                    <td>{{ kamar.Siswa.telp_ayah }}</td>
                    <td>{{ kamar.Siswa.telp_ibu }}</td>
                </tr>

            </tbody>
        </table>
    </div>
    <div class="table-wrapper">


    </div>

</section>