import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LembagaService } from 'src/app/services/lembaga.service';
import { DatePipe } from '@angular/common';
import { ItemBiayaService } from 'src/app/services/item-biaya.service';


@Component({
  selector: 'app-item-biaya',
  templateUrl: './item-biaya.component.html',
  styleUrls: ['./item-biaya.component.css']
})

export class ItemBiayaComponent implements OnInit {

  listsNomorAkun: any = [];
  listItemBiaya: any = [];
  errorMessage = '';

  formAddItemBiaya: FormGroup = new FormGroup({
    item_name: new FormControl('')
  });
  submitted = false;
  isNew = true;
  editLembagaId = null;

  pageid = "lembaga";

  nama = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];

  closeResult: string = '';

  constructor(
    private itemBiayaService: ItemBiayaService,
    private lembagaService: LembagaService, 
    private uiService: UiService, 
    private datePipe: DatePipe,
    private modalService: NgbModal, 
    private formBuilder: FormBuilder
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getAllItemBiaya();

    this.formAddItemBiaya = this.formBuilder.group(
      {
        item_name: [
          '',
          [
            Validators.required,
          ]
        ]
      }
    );
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  openModal(content:any) {
    this.isNew = true;
    this.submitted = false;
    this.formAddItemBiaya.reset();
    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  openModalEdit(content:any, lembaga: any) {
    console.log(lembaga);
    this.editLembagaId = lembaga.id;
    this.isNew = false;
    this.formAddItemBiaya.patchValue(lembaga)
    this.submitted = false;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
     
  /**
   * Write code on Method
   *
   * @return response()
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getRequestParams(searchNama: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`nama`] = searchNama ? searchNama : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getAllItemBiaya() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.nama, this.page, this.pageSize);

    this.itemBiayaService.getAll(params).subscribe({
      next: resdata => {
        console.log(resdata);
        // const { data, totalrows } = resdata;
        const totalrows = 20;
        this.listItemBiaya = resdata;
        this.count = totalrows;
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.getAllItemBiaya();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getAllItemBiaya();
  }

  search(): void {
    this.page = 1;
    this.getAllItemBiaya();
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/empty-image.png';
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAddItemBiaya.controls;
  }

  onSubmitItemBiaya(): void {
    this.submitted = true;
    
    
    if (this.formAddItemBiaya.invalid) {
      return;
    }
    
    this.modalService.dismissAll();
    this.uiService.showLoader();


    if (this.isNew) {
      this.itemBiayaService.add(this.formAddItemBiaya.value).subscribe({
        next: resdata => {
          this.submitted = false;
          this.formAddItemBiaya.reset();
          console.log(resdata);
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
          this.getAllItemBiaya();
        },
        error: err => {
          console.log(err);
          this.submitted = false;
          this.formAddItemBiaya.reset();
          
          this.errorMessage = err.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    } else {
      this.itemBiayaService.update(this.editLembagaId, this.formAddItemBiaya.value).subscribe({
        next: resdata => {
          this.submitted = false;
          this.formAddItemBiaya.reset();
          console.log(resdata);
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
          this.getAllItemBiaya();
        },
        error: err => {
          console.log(err);
          this.submitted = false;
          this.formAddItemBiaya.reset();
          
          this.errorMessage = err.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }


    console.log(JSON.stringify(this.formAddItemBiaya.value, null, 2));
  }

  confirmDelete(id: String) {
    this.uiService.showConfirmDelete(this, id);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    // if (isDelete) {

    //   console.log('DELETE THIS ID : ', isDelete, id);

    //   $SELF.uiService.showLoader();

    //   $SELF.lembagaService.delete(id).subscribe({
    //     next: (resdata: any) => {
    //       console.log(resdata);

    //       $SELF.getAllItemBiaya();
          
    //       $SELF.uiService.hideLoader();
    //       $SELF.uiService.showSuccess('');

    //     },
    //     error: (err: any) => {
    //       console.log(err);
          
    //       $SELF.errorMessage = err.message;
    //       console.log($SELF.errorMessage);
          
    //       $SELF.uiService.hideLoader();
          
    //       $SELF.uiService.showError($SELF.errorMessage);
    //     }
    //   });

    // }
  }

  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, 'd - MM - Y');
  }

}
