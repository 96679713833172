<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
        <div class="tabs-title">
            Kepala Sekolah
        </div>
        <div class="list-options">
            <div class="label">
                Tahun Pelajaran &nbsp;
            </div>
            <div class="form-select-warapper">
            <form [formGroup]="formChangeTahunAjaran">
                <select formControlName="id_tahun_ajaran" (change)="changeTahunAjaran($event)" class="form-select" name="id_tahun_ajaran" id="id_tahun_ajaran">
                <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                </select>
            </form>
            </div>
            <a (click)="openAddModalformKepsek(formKepsekModal)" class="btn btn-blue-grey">
                + Tambah Kepala Sekolah
            </a>
        </div>
        <div class="table-wrapper">
            <div class="table-responsive">
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Tahun Ajaran</th>                            
                            <th>Kepala Sekolah</th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>                        
                        <tr
                        *ngFor="
                            let data of listKepsek | paginate : {
                                    id: pageid,
                                    itemsPerPage: pageSize,
                                    currentPage: page,
                                    totalItems: count
                                };
                            let i = index
                        "
                        >
                            <td>{{ i + 1 + ((page - 1) * pageSize) }}</td>
                            <td>{{ data.TahunAjaran.name }}</td>                            
                            <td>{{ data.Proguser !== null ? data.Proguser.userProfile.real_name : '' }}</td>
                        
                            <td class="settings">
                                <div class="dropend">
                                    <a href="#" role="button" id="dropdownMenuLink"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="./assets/images/icon-setting.svg" alt="">
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li><a (click)="openEditModalformKepsek(formKepsekModal, data)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>                                        
                                        <li><a (click)="confirmDelete(data.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="listKepsek.length === 0" class="empty">
                            <td colspan="5">Tidak terdapat data atau data tidak ditemukan.</td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
            
  
            <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
                <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
                    <div></div>
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center pagination-number">
                            <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                                <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ page.label }}</a>
                                
                                <a class="page-link" *ngIf="p.getCurrent() === page.value">{{ page.label }}</a>
  
                            </li>
                        </ul>
                    </nav>
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" [class.disabled]="p.isFirstPage()">
                                <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                                    <span>Previous</span>
                                </a>
                            </li>
                            <li class="page-item" [class.disabled]="p.isLastPage()">
                                <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                                    <span>Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </pagination-template>
  
            <ng-template #emptyPagination>
                <div class="pagination-wrapper">
                    <div>&nbsp;</div>
                </div>
            </ng-template>
  
        </div>
  
        
    </div>
    
  </div>
  
  <ng-template #formKepsekModal let-modal>
    <div class="modal-body">
        <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
        <div class="modal-form">
            <h1 class="text-center">Edit Form Kepala Sekolah</h1>
            <form [formGroup]="formKepsek" (ngSubmit)="onSubmitformKepsek(modal)">

              <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">Kepala Sekolah</label>
                <div class="col-sm-9">
                  <select formControlName="id_user" [ngClass]="{ 'is-invalid': submitted && f['id_user'].errors }" class="form-select">                    
                    <option value="" selected>Pilih</option>
                    <option [value]="staff.id" *ngFor="let staff of listStaff">{{ staff.userProfile.real_name }}</option>
                  </select>
                </div>
              </div>
  
              
              <button type="submit" class="btn btn-primary mt-3 my-btn-save">{{ buttonText }}</button>
            </form>
        </div>
    </div>
  </ng-template>
  