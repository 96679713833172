import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ppsb-main',
  templateUrl: './ppsb-main.component.html',
  styleUrls: ['./ppsb-main.component.css']
})
export class PpsbMainComponent implements OnInit {

  isList = false
  isNilai = false
  isDeleted = false

  constructor(private router: Router) { }

  ngOnInit(): void {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    if (this.router.url == '/ppsb/list') {
      this.isList = true
      this.isNilai = false
      this.isDeleted = false
    } else if (this.router.url == '/ppsb/nilai') {
      this.isNilai = true
      this.isList = false
      this.isDeleted = false
    } else {
      this.isDeleted = true
      this.isList = false
      this.isNilai = false
    }
  }

  tabClick(): void {
  }

}
