
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="lembaga-list" role="tabpanel" aria-labelledby="list-tab">
      <div class="tabs-title">
          SETUP BIAYA TAHUNAN
      </div>
      <div class="list-options">
          
        <select (change)="onChangeLembaga($event)" class="form-select" name="" id="">
            <option value="0">Untuk Semua Lembaga</option>
            <option 
              [value]="data.id"
              [selected]="data.id === idlembaga"
              *ngFor="let data of listLembaga">{{data.nama_lembaga}}</option>
        </select>

        <select (change)="onChangeTahunAjaran($event)" class="form-select">
            <option value="" selected disabled>Pilih</option>
            <option 
              [value]="data.id"
              [selected]="data.active === true"
              *ngFor="let data of listTahunajaran">{{data.name}}</option>
        </select>

          
      </div>
      <div class="table-wrapper">
          <div class="table-options">
              <div class="show-table">
                
              </div>
          </div>
          <div class="table-responsive">
              <table class="table table-borderless">
                  <thead>
                      <tr>
                          <th>Paket Pembayaran</th>
                          <th *ngFor="let groupbiaya of listGroupBiaya let n = index">{{ groupbiaya.name }}</th>
                          <th>TOTAL</th>
                      </tr>
                  </thead>
                  <tbody *ngFor="let biayapaket of listBiayaPaketPembayaran let i = index">
                      <tr>
                          <td>{{ biayapaket.paket }}</td>
                          <td *ngFor="let groupbiaya of biayapaket.groups let n = index"><a style="cursor: pointer;" (click)="openModal(addSetupTahunanModal, groupbiaya)"> {{ groupbiaya.total }} </a></td>
                          
                          <td>{{ biayapaket.total_paket }}</td>
                      </tr>
                  </tbody>
              </table>
          </div>

          <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
              <div class="pagination-wrapper">
                  <div></div>
                  <nav aria-label="Page navigation">
                      <ul class="pagination justify-content-center pagination-number">
                          <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                              &nbsp;

                          </li>
                      </ul>
                  </nav>
                  
              </div>
          </pagination-template>

      </div>

      
  </div>
  
</div>


<ng-template #addSetupTahunanModal let-modal>
    <div class="modal-body">
        <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
        <div class="modal-form">
            <h1 class="text-center">Setup Tahunan</h1>
            
            <form [formGroup]="formAddSetupTahunan" (ngSubmit)="onSubmitTahunan()">
              <div class="row">
                <div class="col-md-6 mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label">Paket Pembayaran</label>
                    <input
                        readonly
                        [disabled]="true"
                        [value]="currentPaket"
                        type="text"
                        class="form-control"
                    />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label">Group Tagihan</label>
                    <input
                        readonly
                        [disabled]="true"
                        type="text"
                        [value]="currentGroupBiaya"
                        class="form-control"
                    />                    
                </div>
              </div>
              <div class="row">
                <div class="col-md-2 mb-3 mt-4">
                    
                    <label for="exampleInputEmail1" class="form-label">Item Biaya</label>
                </div>
                <div class="col-md-4 mb-3 mt-4">
                    <select 
                        formControlName="id_item_biaya"
                        [ngClass]="{ 'is-invalid': submitted && g['id_item_biaya'].errors }"
                        class="form-select">
                        <option value="" selected disabled>Pilih</option>
                        <option [value]="data.id" *ngFor="let data of listItemBiaya.biayaitems">{{data.item_name}}</option>
                    </select>
                    <div *ngIf="submitted && g['id_item_biaya'].errors" class="invalid-feedback">
                        <div *ngIf="g['id_item_biaya'].errors['required']">Item Biaya is required</div>
                    </div>
                </div>
                <div class="col-md-3 mb-3 mt-4">
                    <input
                        type="text"
                        formControlName="nilai_biaya"
                        placeholder="0"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && g['nilai_biaya'].errors }"
                    /> 
                    <div *ngIf="submitted && g['nilai_biaya'].errors" class="invalid-feedback">
                        <div *ngIf="g['nilai_biaya'].errors['required']">Nominal is required</div>
                    </div>                   
                </div>
                <div class="col-md-3 mb-3 mt-4">
                  <button type="submit" class="btn btn-primary mt-3 my-btn-save" *ngIf="isNew">Simpan</button>
                </div>
              </div>
              
            </form>
  
            <div class="row">
              <div class="table-responsive">
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Item Biaya</th>                  
                            <th>Nominal</th>
                            <th></th>
                        </tr>
                    </thead>
  
                    <tbody>
                      <tr *ngFor=" let listItem of listItemBiayaTahunan.ItemBiayaTahunan let i = index">
                        <td>1</td>
                        <td>{{ listItem.ItemsItemBiayaTahunan.item_name }}</td>
                        <td>{{ listItem.nilai_biaya }}</td>
                        <td style="cursor: pointer;" (click)="confirmDeleteItemPembayaran(listItem.id, idPaketPembayaran)">[ X ]</td>
                      </tr>
                    </tbody>
                </table>
              </div>  
            </div>
        </div>
    </div>
  </ng-template>