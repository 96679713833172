import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nav-tab',
  templateUrl: './nav-tab.component.html',
  styleUrls: ['./nav-tab.component.css']
})
export class NavTabComponent implements OnInit {

  listButtonSelected: boolean = false;
  statisticButtonSelected: Boolean = false;
  pembayaranButtonSelected: Boolean = false;
  penentuanKelasButtonSelected: Boolean = false;

  listActive = "";
  statisticActive = "";
  pembayaranActive = "";
  penentuanKelasActive = "";
  rekapActive = "";

  tabLists: any = [];

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.log("call nav", this.route.snapshot.url[0].path);

    const currentPath = this.route.snapshot.url[0].path;

    switch (currentPath) {
      case "statistik":
        this.listActive = "";
        this.statisticActive = "active";
        this.pembayaranActive = "";
        this.penentuanKelasActive = "";
        this.rekapActive = "";
        break;
      case "list":
        this.listActive = "active";
        this.statisticActive = "";
        this.pembayaranActive = "";
        this.penentuanKelasActive = "";
        this.rekapActive = "";
        break;
      case "pembayaran":
        this.listActive = "";
        this.statisticActive = "";
        this.pembayaranActive = "active";
        this.penentuanKelasActive = "";
        this.rekapActive = "";
        break;
      case "penentuankelas":
        this.listActive = "";
        this.statisticActive = "";
        this.pembayaranActive = "";
        this.penentuanKelasActive = "active";
        this.rekapActive = "";
        break;
      case "duplicate-name":
      case "tanggal-ujian":
      case "upload-dokumen":
      case "lokasi-ujian":
        this.listActive = "";
        this.statisticActive = "";
        this.pembayaranActive = "";
        this.rekapActive = "active";
        break;

      default:
        break;
    }
    
    
  }

}
