import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class KelasService {

  constructor(private netService: NetworkService) { }

  getAllkelas(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/kelas', params);
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/kelas');
  }

  get(id: string): Observable<any> {
    return this.netService.getRequest(`/v1/kelas/${id}`);
  }

  update(id: string, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/kelas/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/kelas/${id}`);
  }
}
