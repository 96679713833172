import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rayon-delete',
  templateUrl: './rayon-delete.component.html',
  styleUrls: ['./rayon-delete.component.css']
})
export class RayonDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
