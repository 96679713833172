import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class PaketPembayaranService {

  constructor(private netService: NetworkService) { }

  getAll(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/paketpembayaran', params);
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/paketpembayaran');
  }

  get(id: string): Observable<any> {
    return this.netService.getRequest(`/v1/paketpembayaran/${id}`);
  }

  getByIdLembaga(idlembaga: any): Observable<any> {
    console.log("IDLEMBAGA",idlembaga);
    
    return this.netService.getRequest(`/v1/paketpembayaranbyidlembaga/${idlembaga}`);
  }

  getByIdLembagaAndPeriod(id: string, idlembaga: any, idperiode: any): Observable<any> {
    return this.netService.getRequest(`/v1/paketpembayaranbyidlembagaandperiode/${id}/${idlembaga}/${idperiode}`);
  }

  getBiayaPendaftaran(idlembaga: any, idperiode: any): Observable<any> {
    return this.netService.getRequest(`/v1/biayaregistrasi/${idlembaga}/${idperiode}`);
  }

  getPaketpembayaranbylembagaandperiode(idlembaga: any, idperiode: any): Observable<any> {
    return this.netService.getRequest(`/v1/paketpembayaranbylembagaandperiode/${idlembaga}/${idperiode}`);
  }

  getPaketpembayaranbylembagaandpaketpembayaranandperiode(idlembaga: any, idpaket: any, idperiode: any): Observable<any> {
    return this.netService.getRequest(`/v1/paketpembayaranbylembagaandpaketpembayaranandperiode/${idlembaga}/${idpaket}/${idperiode}`);
  }

  update(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/paketpembayaran/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/paketpembayaran/${id}`);
  }

  addBiayaTahunan(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/biayatahunan');
  }

  getBiayaTahunanById(id: string, groupid: string): Observable<any> {
    return this.netService.getRequest(`/v1/biayatahunanbygroup/${id}/${groupid}`);
  }

  deleteItemBiayaTahunan(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/biayatahunan/deleteitem/${id}`);
  }

  addItemBiayaTahunan(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/biayatahunan/additem');
  }
}
