import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateSettingService {

  constructor(private netService: NetworkService) { }

  getPrintTagihanTemplate(): Observable<any> {
    return this.netService.getRequest(`/v1/templatesettingbytemplate/tagihan`);
  }

  update(id: string, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/templatesetting/${id}`);
  }

}
