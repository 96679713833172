<div class="table-wrapper">
  <div class="table-options">
    <div class="show-table">
      Show
      <input
        type="number"
        class="form-control"
        name="show"
        id="show"
        value="10"
      />
      entries
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>Tahun Ajaran</th>
          <th>Tanggal</th>
          <th>Inside</th>
          <th>Outside</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="listData.length > 0; else empty_data_prestasi">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <ng-template #empty_data_prestasi>
          <tr class="empty">
            <td colspan="4">Belum terdapat prestasi untuk siswa ini.</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
  <pagination-template
    #p="paginationApi"
    [id]="pageId"
    (pageChange)="handlePageChange($event)"
  >
    <div
      *ngIf="isShowPagination; else emptyPagination"
      class="pagination-wrapper"
    >
      <div></div>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center pagination-number">
          <li
            class="page-item"
            *ngFor="let page of p.pages"
            [class.active]="p.getCurrent() === page.value"
          >
            <a
              class="page-link"
              (click)="p.setCurrent(page.value)"
              *ngIf="p.getCurrent() !== page.value"
            >
              {{ page.label }}
            </a>
            <a class="page-link" *ngIf="p.getCurrent() === page.value">
              {{ page.label }}
            </a>
          </li>
        </ul>
      </nav>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="p.isFirstPage()">
            <a
              class="page-link"
              *ngIf="!p.isFirstPage()"
              (click)="p.previous()"
              aria-label="Previous"
            >
              <span>Previous</span>
            </a>
          </li>
          <li class="page-item" [class.disabled]="p.isLastPage()">
            <a
              class="page-link"
              *ngIf="!p.isLastPage()"
              (click)="p.next()"
              aria-label="Next"
            >
              <span>Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </pagination-template>

  <ng-template #emptyPagination>
    <div class="pagination-wrapper">
      <div>&nbsp;</div>
    </div>
  </ng-template>
</div>
