import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { BiayaService } from 'src/app/services/biaya.service copy';


@Component({
  selector: 'app-biaya-edit',
  templateUrl: './biaya-edit.component.html',
  styleUrls: ['./biaya-edit.component.css']
})

export class BiayaEditComponent implements OnInit {

  listTahunajaran: any = [];
  listJenisBayar: any = [];
  listsLembaga: any = [];
  listsTingkat: any = [];
  errorMessage = '';
  tahunAjaranActive = "";
  id: string = '';
  biayaData: any;

  pageid = "proguser";

  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;

  form: FormGroup = new FormGroup({
    id_periode: new FormControl(''),
    id_jenis_bayar: new FormControl(''),
    description: new FormControl(''),
    jumlah: new FormControl(''),
  });
  submitted = false;

  constructor(private formBuilder: FormBuilder, 
    private uiService: UiService, 
    private tahunajaranService: TahunajaranService, 
    private biayaService: BiayaService,
    private route: ActivatedRoute,
    private router: Router) 
  { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.initForm();
    this.getAllTahunajaran();
    this.getJenisBayar();
    this.getBiayaEdit();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru");
    }
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getBiayaEdit() {
    this.uiService.showLoader();

    this.biayaService.getById(this.id).subscribe({
      next: resdata => {
        console.log('biaya', resdata);
        this.biayaData = resdata;
      
        this.form.patchValue(resdata)
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log('kelas', resdata);
        const { data, totalrows } = resdata;

        this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listTahunajaran = data;
        this.count = totalrows;

        console.log('COUNT', this.count);

      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onBack() {
    this.router.navigate(['/keuangan/biaya/list']);
  }

  getJenisBayar() {
    this.uiService.showLoader();

    this.biayaService.getAllJenisBayar().subscribe({
      next: resdata => {
        console.log('jenis bayar', resdata);
        this.listJenisBayar = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }
  
  initForm() {
    this.form = this.formBuilder.group(
      {
        id_periode: ['', Validators.required],
        id_jenis_bayar: ['', Validators.required],
        description: ['', Validators.required],
        jumlah: ['', Validators.required],
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    
    if (this.form.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    this.biayaService.update(this.id, this.form.value).subscribe({
      next: resdata => {
        console.log(resdata);
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
      
        this.router.navigate(['/keuangan/biaya/list']);

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });

    console.log(JSON.stringify(this.form.value, null, 2));
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

}
