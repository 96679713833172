<div class="content-item mb-5">
    <div class="card">
        <div class="card-header">
            DATA ROLE
            <a [routerLink]="['/role/', id, 'permission']" class="btn btn-warning pull-right">Permission</a>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-lg-12">
                    <div class="row">
                        <div class="col-6 col-lg-5 mb-3">
                            <label for="name" class="form-label">Role Nama</label>
                        </div>
                        <div class="col-6 col-lg-5 mb-3">
                            <strong>{{ role.role_name }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-12">
                    <div class="row">
                        <div class="col-6 col-lg-5 mb-3">
                            <label for="name" class="form-label">Editable</label>
                        </div>
                        <div class="col-6 col-lg-5 mb-3">
                            <strong>{{ role.active ? "Ya" : "Tidak" }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-12">
                    <div class="row">
                        <div class="col-6 col-lg-5 mb-3">
                            <label for="name" class="form-label">Status</label>
                        </div>
                        <div class="col-6 col-lg-5 mb-3">
                            <strong>{{ role.active ? "Aktif" : "Tidak Aktif" }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-12">
                    <div class="row">
                        <div class="col-6 col-lg-5 mb-3">
                            <label for="name" class="form-label">Tanggal Dibuat</label>
                        </div>
                        <div class="col-6 col-lg-5 mb-3">
                            <strong>{{ dateTransform(role.createdAt) }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>