import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/helper/common.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbsensiService } from 'src/app/services/absensi.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { TingkatService } from 'src/app/services/tingkat.service';

declare var Datepicker: any;

@Component({
  selector: 'app-laporan-izin-perkelas',
  templateUrl: './laporan-izin-perkelas.component.html',
  styleUrls: ['./laporan-izin-perkelas.component.css']
})
export class LaporanIzinPerkelasComponent implements OnInit {

  listMonitoring: any = [];
  listsTingkat: any = [];
  listTahunajaran: any = [];
  currentPeriodeId: any = null;
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  closeResult: string = '';
  numOfJamke = 8;
  listJamke = [
    1,2,3,4,5,6,7,8
  ]
  currentTingkatId = "";

  formMonitoringAbsensi: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
    id_tingkat: new FormControl(''),
    tanggal: new FormControl(''),
    day: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private tingkatService: TingkatService,
    private tahunajaranService: TahunajaranService,
    private commonService: CommonService,
    private absensiService: AbsensiService,
    private formBuilder: FormBuilder,
  ) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    const today = this.commonService.convertDate();
    const dayName = this.commonService.getCurrentDayName(today);
    console.log(today,dayName);
    
    this.getCurrentTahunajaran();
    this.initForm();
    this.configureDatePicker();
    this.getTingkatLembaga();

    this.formMonitoringAbsensi.controls['tanggal'].setValue(today);
    this.formMonitoringAbsensi.controls['day'].setValue(dayName);
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add('siswa-baru-list');
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    console.log('handle PAGE', this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  search(): void {
    this.page = 1;
  }

  initForm() {
    this.formMonitoringAbsensi = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        id_tingkat: ['', Validators.required],
        tanggal: ['', Validators.required],
        day: ['', Validators.required],
      },
    );
  }

  changeStartDateField(e: any) {
    console.log(e.target.value);
    const tgl = e.target.value;
    const dayName = this.commonService.getCurrentDayName(tgl);
    this.formMonitoringAbsensi.controls['tanggal'].setValue(tgl);
    this.formMonitoringAbsensi.controls['day'].setValue(dayName);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formMonitoringAbsensi.controls;
  }

  changeTingkat(e: any) {
    console.log(e.target.value);
    this.currentTingkatId = e.target.value === "0" ? "" : e.target.value;
  }

  configureDatePicker() {
    const elem_start_date = document.getElementById('start_date');
    const datepicker_start_date = new Datepicker(elem_start_date, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    });
  }

  private getTingkatLembaga() {
    this.uiService.showLoader();
  
    this.tingkatService.getAllTingkat({}).subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsTingkat = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onSubmit() {
    this.submitted = true;

    console.log(JSON.stringify(this.formMonitoringAbsensi.value, null, 2));

    if (this.formMonitoringAbsensi.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    this.absensiService.getMonitoringAbsensiHarianSiswa(this.formMonitoringAbsensi.value).subscribe({
      next: resdata => {
        console.log("DATA MOITOR", resdata);

        // get kelas first
        const rowCols: any[][] = [];
        let indexListKelas: any[] = [];
        for (let x = 0; x<resdata.length; x++) {
          if (!indexListKelas.includes(resdata[x].nama_kelas)) {
            indexListKelas.push(resdata[x].nama_kelas);

            let cols: any[] = [];
            cols.push(resdata[x].nama_kelas);
            for (let c=1; c<=this.numOfJamke; c++) {
              const col = {
                guru: "",
                pengganti: "",
                keterangan: ""
              }
              cols.push(col);
              
            }
            // console.log(cols);
            rowCols.push(cols);
          }
        }
        
        console.log("ROW COLS", rowCols);
      
        
        for (let s=0; s<resdata.length; s++) {
          const jadwal = resdata[s];
          
          if (jadwal.jamke !== null) {
            
            const ctnColData = {
              guru: jadwal.guru_pengajar,
              pengganti: jadwal.guru_pengganti,
              completed: jadwal.completed,
              keterangan: jadwal.izin,
              is_replacement: jadwal.is_replacement
            }

            const indexJamKe = jadwal.jamke;
            const indexKelas = indexListKelas.indexOf(jadwal.nama_kelas);

            rowCols[indexKelas][indexJamKe] = ctnColData;
            console.log("JAM KE", jadwal.jamke, "KELAS ", jadwal.nama_kelas);
            
          }          
        }

        this.listMonitoring = rowCols;
        console.log('LIST MONITORING', this.listMonitoring);
        
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('');

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });    
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formMonitoringAbsensi.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;        

        this.uiService.hideLoader();
        

        this.getAllTahunajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

}
