import { Component, OnInit } from '@angular/core';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { KelasService } from 'src/app/services/kelas.service';
import { WalikelasService } from 'src/app/services/walikelas.service';
import { JadwalPelajaranService } from 'src/app/services/jadwal-pelajaran.service';
import { CommonService } from 'src/app/helper/common.service';
import { LembagaService } from 'src/app/services/lembaga.service';
import { TingkatService } from 'src/app/services/tingkat.service';
import { PaketPembayaranService } from 'src/app/services/paket-pembayaran.service';
import { SiswaService } from 'src/app/services/siswa.service';
import { PembayaranService } from 'src/app/services/pembayaran.service';
import { GroupBiayaService } from 'src/app/services/group-biaya.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SiswakelasService } from 'src/app/services/siswakelas.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BiayakhususService } from 'src/app/services/biayakhusus.service';
import { ItemBiayaService } from 'src/app/services/item-biaya.service';

@Component({
  selector: 'app-tagihan-siswa',
  templateUrl: './tagihan-siswa.component.html',
  styleUrls: ['./tagihan-siswa.component.css']
})
export class TagihanSiswaComponent implements OnInit {

  isInfoTagihan = true;
  isBiayaKhusus = false;
  isCatatan = false;
  isLog = false;
  currentTabTitle = "Tagihan dan Pembayaran";
  currentSiswaId = "";
  listBiayaKhusus: any = [];
  listItemBiaya: any = [];
  listItemBiayaKhusus: any = [];
  listTahunajaran: any = [];
  listTagihanSiswa: any = [];
  listDataTagihan: any = [];
  listTotalBulan = [0,0,0,0,0,0,0,0,0,0,0,0];
  listGrandTotal = "0";
  listTotalBulanTagih = [0,0,0,0,0,0,0,0,0,0,0,0];
  listGrandTotalTagih = "0";
  listMonth: any = [];
  listEfectiveMonth: any = [];
  listYear: any = [];
  currentBulan = "";
  currentYear = "";
  listGroupBiaya: any = [];
  listBulanEfektif: any = [];
  listDataSiswaPerorangan: any = [];
  tampungDataSiswaPerorangan: any = [];
  selectedGroupBiayaValue: any = [];
  listKelas: any = [];
  listsLembaga: any = [];
  listsTingkat: any = [];
  listMataPelajaran: any = [];
  listPaketPembayaran: any = [];
  listBiayaPaketPembayaran: any = [];
  listSemester: any = [];
  currentPeriodeId: any = null;
  currentPeriodeTagihanId: any = null;
  currentKelasId: any = null;
  currentStudiId: any = null;
  currentcurrentJadwalPelajaranId: any = null;
  currentGuruId: any = null;
  currentGuruName = "";
  currentAbsensiGuruInputId: any = null;
  currentAngkatan = "";
  currentAngkatanTagihan = "";
  currentSemester = "";
  currentPayload = "";
  pageid = "absensi";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  errorMessage = "";
  currentDate = "";
  listDayPeriod: any = [];
  numOfDayCol = 0;
  submitted = false;
  today = "";
  currentJadwalPelajaranId: any = null;
  isAbsenCompleted: boolean = false;
  numOfSiswaActive:number = 0;
  saveGuruInputDate = "";
  isOpenInputNilai: boolean = false;
  listTipe = [
    "Group","Perorangan"
  ]
  currentLembagaId = "";
  currentTingkatId = "";
  isShowLoader: Boolean = false;
  listSiswa: any = [];
  searchSiswaTerm = "";
  isShowPerorangan: Boolean = false;
  idlembagaPembayaran: number = 0;
  idpaket: number = 0;
  groupBiayaName = "";
  totalGroupBiaya = 0;
  currentSiswa: any = {};
  currentKelas = "";
  closeResult: string = '';
  currentEvent: any = null;

  formAction: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
    tipe_tagihan: new FormControl(''),
  });

  formGroupBiaya: FormGroup = new FormGroup({
    id_group_biaya: new FormControl(''),
    id_siswa: new FormControl(''),
  });
  
  formAddBiayaKhusus: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
    tipe_tagihan: new FormControl(''),
  });

  formAddItemBiayaKhusus: FormGroup = new FormGroup({
    id_biaya_khusus: new FormControl(''),
    id_item_biaya: new FormControl(''),
    jumlah: new FormControl(''),
  });

  formSumberDataTagihan: FormGroup = new FormGroup({
    from_beginning_tagihan: new FormControl(''),
    selectAllCheckbox: new FormControl(''),
    from_bulan_tagihan: new FormControl(''),
    from_tahun_tagihan: new FormControl(''),
    end_bulan_tagihan: new FormControl(''),
    end_tahun_tagihan: new FormControl(''),
    selectedGroupBiaya: new FormArray([]),
  });

  formInputInitMatapelajaran: FormGroup = new FormGroup({
    periode_id: new FormControl(''),
    id_semester: new FormControl(''),
    id: new FormControl(''),
    kelas_id: new FormControl(''),
    guru_id: new FormControl(''),
    guru_name: new FormControl(''),
    walikelas_name: new FormControl(''),
    proguser_id: new FormControl(''),
    studi_id: new FormControl(''),  
  });

  formInputTagihanBulanan: FormGroup = new FormGroup({
    selectAllCheckbox: new FormControl(''),
    selectedBulanTagihan: new FormArray([]),
    selectedBulanTertagih: new FormArray([]),
    id_tahun_ajaran: new FormControl(''),
    semester: new FormControl(''),
    tipe: new FormControl(''),
    id_studi: new FormControl(''),
    guru_penginput_id: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private kelasService: KelasService,
    private tahunajaranService: TahunajaranService,
    private walikelasService: WalikelasService,
    private jadwalpelajaranService: JadwalPelajaranService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private groupBiayaService: GroupBiayaService,
    private lembagaService: LembagaService,
    private tingkatService: TingkatService,
    private paketPembayaranService: PaketPembayaranService,
    private pembayaranService: PembayaranService,
    private siswaService: SiswaService,
    private siswakelasService: SiswakelasService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private biayakhususService: BiayakhususService,
    private itemBiayaService: ItemBiayaService,
  ) { 
    this.changeBodyClass();

    this.initForm();

    this.currentDate = this.commonService.getCurentDate();
    
    this.formInputInitMatapelajaran.controls['kelas_id'].disable();
    this.formInputInitMatapelajaran.controls['walikelas_name'].disable();
    this.formInputInitMatapelajaran.controls['guru_name'].disable();
  }

  ngOnInit(): void {
    this.currentSiswaId = this.route.snapshot.params['id'];
    console.log("currentSiswaId", this.currentSiswaId);
    
    this.today = this.commonService.getCurrentDayName();
    console.log("HARI INI", this.today);
    this.listMonth = this.commonService.getListMonth();
    const currentYear = this.commonService.getCurrentYear();
    this.listYear = this.commonService.getListFiveYearsBefore();
    console.log("LIST YEAR", this.listYear);
    
    const currentMonth = this.commonService.getCurrentMonth();
    this.currentBulan = currentMonth;
    
    this.formSumberDataTagihan.controls['from_tahun_tagihan'].setValue(currentYear);
    this.formSumberDataTagihan.controls['from_bulan_tagihan'].setValue(currentMonth);
    this.formSumberDataTagihan.controls['end_tahun_tagihan'].setValue(currentYear);
    this.formSumberDataTagihan.controls['end_bulan_tagihan'].setValue(currentMonth);

    this.listSemester = this.commonService.getListSemester();
    this.getCurrentTahunajaran();
    this.getKelas();
    this.getLembaga();
    this.getDetailTagihanSiswa();
    this.getAllGroupBiaya();
    this.getCurrentSiswa();
    
    const currentUser = localStorage.getItem('user')?.toString();
    const jsonUser = currentUser ? JSON.parse(currentUser) : "";
    console.log("CURRENT USER", jsonUser.proguser.userProfile.real_name);
    this.currentGuruId = jsonUser.proguser.id;
    this.currentGuruName = jsonUser.proguser.userProfile.real_name;

    this.formInputInitMatapelajaran.controls['id_semester'].disable();
    this.formInputInitMatapelajaran.controls['guru_id'].setValue(this.currentGuruId);
    this.formInputInitMatapelajaran.controls['guru_name'].setValue(this.currentGuruName);

    this.formAction.controls['id_tahun_ajaran'].setValue('');
    this.formAction.controls['tipe_tagihan'].setValue('sisa');
    this.formGroupBiaya.controls['id_siswa'].setValue(this.currentSiswaId);
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {
    this.formAction = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        tipe_tagihan: ['', Validators.required],
      }
    );

    this.formGroupBiaya = this.formBuilder.group(
      {
        id_group_biaya: ['', Validators.required],
        id_siswa: ['', Validators.required],
      }
    );

    this.formAddItemBiayaKhusus= this.formBuilder.group(
      {
        id_biaya_khusus: ['', Validators.required],
        id_item_biaya: ['', Validators.required],
        jumlah: ['', Validators.required],
      }
    );

    this.formSumberDataTagihan = this.formBuilder.group(
      {
        from_beginning_tagihan: [false],
        selectAllCheckbox: [false],
        from_bulan_tagihan: [''],
        from_tahun_tagihan: [''],
        end_bulan_tagihan: ['', Validators.required],
        end_tahun_tagihan: ['', Validators.required],
        selectedGroupBiaya: new FormArray([]),
      },
    );
  }

  get selectedGroupBiaya(): FormArray {
    return this.formSumberDataTagihan.get('selectedGroupBiaya') as FormArray;
  }

  get selectedBulanTertagih(): FormArray {
    return this.formInputTagihanBulanan.get('selectedBulanTertagih') as FormArray;
  }

  get dataFormSiswaPerorangan(): FormArray {
    return this.formInputTagihanBulanan.get('dataFormSiswaPerorangan') as FormArray;
  }

  getCurrentSiswa() {
    this.uiService.showLoader();

    this.siswaService.getById(this.currentSiswaId).subscribe({
      next: resdata => {
        console.log("CUrrent siswa",resdata);
        this.currentSiswa = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentKelasSiswa() {
    this.uiService.showLoader();

    const payload = {
      periode_id: this.currentPeriodeId,
      siswa_id: this.currentSiswaId
    }

    this.siswakelasService.getSiswakelasBySiswa(payload).subscribe({
      next: resdata => {
        console.log("currentKelasSiswa",resdata);
        this.currentKelas = resdata.Kelas.nama_kelas;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }
  
  getLembaga() {
    this.uiService.showLoader();

    this.lembagaService.getAllLembaga('').subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsLembaga = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  private getTingkatLembaga() {
    this.currentTingkatId = "";
    
    this.uiService.showLoader();

    this.tingkatService.getTingkatByLembagaId(this.currentLembagaId).subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsTingkat = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getListMataPelajaran() {
    this.uiService.showLoader();

    const payload = {
      periode_id: this.currentPeriodeId,
      semester: this.currentSemester,
      guru_id: this.currentGuruId
    }

    console.log("PAYLOAD getJadwalPelajaran", payload);
    
    
    this.jadwalpelajaranService.getListMataPelajaranByPeriodeSemesterAndGuru(payload).subscribe({
      next: resdata => {
        console.log("JADWAL PELAJARAN : ", resdata);
        
        if (resdata.length > 0) {
          this.listMataPelajaran = resdata;
        }
        
        this.uiService.hideLoader();      

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);            
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value, e.target.options[e.target.selectedIndex].innerHTML);
    this.currentAngkatan = e.target.options[e.target.selectedIndex].innerHTML;
    this.currentAngkatan = this.currentAngkatan === "Semua Tahun" ? "" : this.currentAngkatan;
    this.currentPeriodeId = e.target.value;
    this.getDetailTagihanSiswa();
  }

  changeTahunAjaranTagihan(e: any) {
    console.log(e.target.value, e.target.options[e.target.selectedIndex].innerHTML);
    this.currentAngkatanTagihan = e.target.options[e.target.selectedIndex].innerHTML;
    this.currentPeriodeTagihanId = e.target.value;

    this.listBiayaPaketPembayaran = [];
    this.idlembagaPembayaran = 0;
    this.getDetailTagihanSiswa();
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata.Semester);        

        this.currentPeriodeId = resdata.id;
        this.currentPeriodeTagihanId = resdata.id;
        this.currentAngkatan = resdata.name;
        this.currentAngkatanTagihan = resdata.name;

        this.uiService.hideLoader();        

        this.generateListBulanTahun();
        this.getAllTahunajaran();
        this.getCurrentKelasSiswa();

        this.listEfectiveMonth = this.commonService.getListMonthEfective(this.currentAngkatan);

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onSubmitformInputTagihanBulanan() {
    const routeUrl = this.router.serializeUrl(this.router.createUrlTree(['/download/print-tagihan']));
    const fullUrl = window.location.origin + routeUrl + "?q=" + this.currentPayload;
    window.open(fullUrl, '_blank');
  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changePaketPembayaran(e: any) {
    console.log(e.target.value);
    this.idpaket = e.target.value;
    this.listTagihanSiswa = [];
    this.getAllBiayaPaketPembayaran();
    
  }

  changeKelas(e: any) {

    this.listTagihanSiswa = [];
    this.currentJadwalPelajaranId = null;
    this.formInputInitMatapelajaran.controls['studi_id'].setValue("");
    
    if (e.target.value !== "") {
      const kelas_id = e.target.value;
      this.currentKelasId = kelas_id;
      this.getWalikelasByKelas(kelas_id);

      // get mata pelajran 
      this.getListMataPelajaran(); // TODO: get by guru
    }
  }

  changeSemester(e: any) {
    if (e.target.value !== "") {
      this.formInputInitMatapelajaran.controls['kelas_id'].enable();
    } else {
      this.listDayPeriod = [];
      this.formInputInitMatapelajaran.controls['kelas_id'].setValue("");
      this.formInputInitMatapelajaran.controls['kelas_id'].disable();
    }
  }

  changeTipe(e: any) {
    if (e.target.value === "Group") {
      this.tampungDataSiswaPerorangan = [];
      this.listDataSiswaPerorangan = [];
      this.isShowPerorangan = false;
    } else {
      this.isShowPerorangan = true;
    }
    this.resetFormBulanTagihan();
    this.listTagihanSiswa = [];
  }

  changeLembagaBiaya(e: any) {
    console.log(e.target.value);
    const lembagaBiayaId = e.target.value;
    this.idlembagaPembayaran = lembagaBiayaId;
    // this.formAddNilaiSiswa.controls['id_lembaga_biaya'].setValue(lembagaBiayaId);
    this.listBiayaPaketPembayaran = [];
    this.getDetailTagihanSiswa();
    
  }

  changeLembaga(e: any) {
    console.log("lembagaId", e.target.value);
    
    this.currentLembagaId = e.target.value;
    this.currentTingkatId = "";
    this.getTingkatLembaga();
  }

  changeTingkat(e: any) {
    console.log(e.target.value);
    this.currentTingkatId = e.target.value;

    // this.displayPlotKelas();    
  }

  chooseSiswa(siswa: any) {
    this.listSiswa = [];
    console.log(siswa);
    
    if (!this.tampungDataSiswaPerorangan.includes(siswa.id)) {
      this.tampungDataSiswaPerorangan.push(siswa.id);
      this.listDataSiswaPerorangan.push(siswa);
    }
  }

  removeListSiswaPerorangan(idx: any, siswa_id: any) {
    const index = this.tampungDataSiswaPerorangan.indexOf(siswa_id);
    this.tampungDataSiswaPerorangan.splice(index, 1);
    this.listDataSiswaPerorangan.splice(idx, 1);
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  searchSiswa(): void {
    

    this.listSiswa = [];
    if (this.searchSiswaTerm != "") {
      this.isShowLoader = true;
      const params = this.getRequestParams(this.searchSiswaTerm, this.page, this.pageSize);
      params['angkatan'] = this.currentAngkatan;

      console.log("PARAMS", params);
      
  
      this.siswaService.getAllSiswaBaru(params).subscribe({
        next: resdata => {
          console.log(resdata);
          const { data, totalrows } = resdata;
  
          this.isShowPagination == totalrows > this.pageSize ? true : false;
          this.listSiswa = data;
          this.count = totalrows;
  
          console.log("SISWA", this.listSiswa);
  
          console.log('COUNT', this.count);
  
  
          this.isShowLoader = false;
          //this.uiService.hideLoader();
  
        },
        error: err => {
          this.isShowLoader = false;
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);
  
          //this.uiService.hideLoader();
  
          //this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  getWalikelasByKelas(kelasid: any) {
    this.uiService.showLoader();

    this.walikelasService.getByPeriodeAndKelas(this.currentPeriodeId, kelasid).subscribe({
      next: resdata => {
        console.log("WALIKELAS", resdata);
        this.formInputInitMatapelajaran.controls['walikelas_name'].setValue(resdata.User.userProfile.real_name);
        
        // this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formGroupBiaya.controls;
  }

  get g(): { [key: string]: AbstractControl } {
    return this.formAddBiayaKhusus.controls;
  }

  get h(): { [key: string]: AbstractControl } {
    return this.formAddItemBiayaKhusus.controls;
  }

  getDetailTagihanSiswa() {
    this.uiService.showLoader();
    const payload = {
      tahun: this.currentAngkatan,
      periode_id: this.formAction.value.id_tahun_ajaran,
      tipe_tagihan: this.formAction.value.tipe_tagihan,
      id_siswa: this.currentSiswaId,
    };
    this.pembayaranService.getDetailTagihanBulananSiswa(payload).subscribe({
      next: resdata => {
        console.log("getDetailTagihanSiswa", resdata);

        this.listDataTagihan = resdata.data;
        this.listTotalBulan = resdata.totalBulan;
        this.listGrandTotal = resdata.grandTotal[0].total_amount;
        this.listTotalBulanTagih = resdata.totalBulanTagih;
        this.listGrandTotalTagih = resdata.grandTotalBulanTagih[0].total_amount;
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllBiayaPaketPembayaran() {
    this.paketPembayaranService.getPaketpembayaranbylembagaandpaketpembayaranandperiode(this.idlembagaPembayaran, this.idpaket, this.currentPeriodeTagihanId).subscribe({
      next: resdata => {
        console.log("getPaketpembayaranbylembagaandperiode", this.currentPeriodeId, resdata);
        
        this.listBiayaPaketPembayaran = resdata;
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onCheckboxChange(event: any) {
    
    // const selectedBulanTagihan: FormArray = this.formInputTagihanBulanan.get('selectedBulanTagihan') as FormArray;
    
    if (event.target.checked) {
      this.selectedGroupBiayaValue.push(event.target.value);      
    } else {
      const index = this.selectedGroupBiayaValue
      .findIndex((x: { value: any; }) => {
        console.log("x val", x);
        
        return x === event.target.value
      });
      console.log("UNCHECK IDX", index);
      
      this.selectedGroupBiayaValue.splice(index, 1);
    }

    if (this.selectedGroupBiayaValue.length != this.listGroupBiaya.length) {
      this.formSumberDataTagihan.controls['selectAllCheckbox'].setValue(false);
    } else{
      this.formSumberDataTagihan.controls['selectAllCheckbox'].setValue(true);
    }
    
  }

  onSelectBulanChange(event: any) {
    console.log(event.target.value);

    // const selectedBulanTertagih: FormArray = this.formInputTagihanBulanan.get('selectedBulanTertagih') as FormArray;
    // const index = selectedBulanTertagih.controls
    //   .findIndex(x => {
    //     const xVal = x.value;
    //     const splXVal = xVal.split("==");
    //     const divXVal = splXVal[0];

    //     const xTargetVal = event.target.value;
    //     const splXTargetVal = xTargetVal.split("==");
    //     const divXTargetVal = splXTargetVal[0];

    //     console.log("DIV", divXVal, divXTargetVal);
        
    //     return divXVal === divXTargetVal
    //   });

    // console.log("IDX NYA", index);
    
    
    // if (index > -1) {
    //   selectedBulanTertagih.removeAt(index);
    // }
    // selectedBulanTertagih.push(new FormControl(event.target.value));
    
  }

  generateListBulanTahun() {
    const splTahun = this.currentAngkatan.split("-");
    const thAwal = splTahun[0];
    const thAkhir = splTahun[1];

    this.listBulanEfektif = [
      {
        bulan: "juli", bln: "Jul", blnNumber: 7, tahun: thAwal
      },
      {
        bulan: "agustus", bln: "Agu", blnNumber: 8, tahun: thAwal
      },
      {
        bulan: "september", bln: "Sep", blnNumber: 9, tahun: thAwal
      },
      {
        bulan: "oktober", bln: "Okt", blnNumber: 10, tahun: thAwal
      },
      {
        bulan: "november", bln: "Nov", blnNumber: 11, tahun: thAwal
      },
      {
        bulan: "desember", bln: "Des", blnNumber: 12, tahun: thAwal
      },
      {
        bulan: "januari", bln: "Jan", blnNumber: 1, tahun: thAkhir
      },
      {
        bulan: "februari", bln: "Feb", blnNumber: 2, tahun: thAkhir
      },
      {
        bulan: "maret", bln: "Mar", blnNumber: 3, tahun: thAkhir
      },
      {
        bulan: "april", bln: "Apr", blnNumber: 4, tahun: thAkhir
      },
      {
        bulan: "mei", bln: "Mei", blnNumber: 5, tahun: thAkhir
      },
      {
        bulan: "juni", bln: "Jun", blnNumber: 6, tahun: thAkhir
      },
    ]

    this.resetFormBulanTagihan();
  }

  resetFormBulanTagihan() {
    const selectedBulanTagihan: FormArray = this.formInputTagihanBulanan.get('selectedBulanTagihan') as FormArray;
    
    if (selectedBulanTagihan.length > 0) {
      console.log("ADA ISI", selectedBulanTagihan); 
      selectedBulanTagihan.clear();
    }

    for (let i = 0; i < this.listBulanEfektif.length; i++) {
      selectedBulanTagihan.push(new FormControl(false));      
    }
    
    this.selectedGroupBiayaValue = [];

    const selectedBulanTertagih: FormArray = this.formInputTagihanBulanan.get('selectedBulanTertagih') as FormArray;
    if (selectedBulanTertagih.length > 0) {
      console.log("ADA ISIx", selectedBulanTertagih); 
      selectedBulanTertagih.clear();
    }

    for (let i = 0; i < this.listBulanEfektif.length; i++) {
      selectedBulanTertagih.push(new FormControl(''));      
    }
  }

  resetFormGroupBiaya() {
    const selectedGroupBiaya: FormArray = this.formSumberDataTagihan.get('selectedGroupBiaya') as FormArray;
    
    if (selectedGroupBiaya.length > 0) {
      console.log("ADA ISI", selectedGroupBiaya); 
      selectedGroupBiaya.clear();
    }

    for (let i = 0; i < this.listGroupBiaya.length; i++) {
      selectedGroupBiaya.push(new FormControl(false));      
    }
  }

  onClickDeleteBulanTagihan() {
    this.uiService.showConfirmDelete(this, '');
  }

  onClickPreview() {
    if (this.selectedGroupBiayaValue.length == 0) {
      this.uiService.showError('Group biaya belum dipilih');
      return;
    }
    console.log(this.selectedGroupBiayaValue);
    
    console.log(this.formSumberDataTagihan.value);

    const splTahun = this.currentAngkatan.split("-");
    const thAwal = splTahun[0];
    const thAkhir = splTahun[1];
    const payload = {
      
      id_periode: this.currentPeriodeId,
      list_siswa: this.listDataSiswaPerorangan,
      
      tahun_awal: thAwal,
      tahun_akhir: thAkhir,
      from_beginning_tagihan: this.formSumberDataTagihan.value.from_beginning_tagihan,
      from_bulan_tagihan: this.formSumberDataTagihan.value.from_bulan_tagihan,
      from_tahun_tagihan: this.formSumberDataTagihan.value.from_tahun_tagihan,
      end_bulan_tagihan: this.formSumberDataTagihan.value.end_bulan_tagihan,
      end_tahun_tagihan: this.formSumberDataTagihan.value.end_tahun_tagihan,
      selectedGroupBiayaValue: this.selectedGroupBiayaValue
    }
    
    console.log(payload);
    // Step 2: Convert JSON object to JSON string
    const jsonString = JSON.stringify(payload);

    // Step 3: Convert JSON string to Base64
    this.currentPayload = btoa(jsonString);
    
    this.uiService.showLoader();
    this.pembayaranService.getAllUnpaidTagihanByGroupBiaya(payload).subscribe({
      next: resdata => {
        console.log("getAllTagihanBUlan", resdata.data);
                    
        this.listDataTagihan = resdata.data;
                  
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
    
    
  }

  getAllGroupBiaya() {
    this.uiService.showLoader();

    const params = this.getRequestParams("", this.page, this.pageSize);

    this.groupBiayaService.getAll(params).subscribe({
      next: resdata => {
        console.log("getAllGroupBiaya", resdata.data);
        // const { data, totalrows } = resdata;
        const totalrows = 20;
        this.listGroupBiaya = resdata.data;
        this.count = totalrows;
      
        this.uiService.hideLoader();

        this.resetFormGroupBiaya();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  selectAllGroupBiaya(e: any) {
    let isChecked = false;
    this.selectedGroupBiayaValue = [];
    if (e.target.checked) {
      isChecked = true;
    }
    const selectedGroupBiaya: FormArray = this.formSumberDataTagihan.get('selectedGroupBiaya') as FormArray;
    
    if (selectedGroupBiaya.length > 0) {
      console.log("ADA ISI", selectedGroupBiaya); 
      selectedGroupBiaya.clear();
    }

    for (let i = 0; i < this.listGroupBiaya.length; i++) {
      if (isChecked) {
        this.selectedGroupBiayaValue.push(this.listGroupBiaya[i].id);
      }
      selectedGroupBiaya.push(new FormControl(isChecked));      
    }
  }

  onCheckBoxFromStart(e: any) {
    if (e.target.checked) {
      this.formSumberDataTagihan.controls['from_bulan_tagihan'].disable();
      this.formSumberDataTagihan.controls['from_tahun_tagihan'].disable();
    } else {
      this.formSumberDataTagihan.controls['from_bulan_tagihan'].enable()
      this.formSumberDataTagihan.controls['from_tahun_tagihan'].enable();
    }
  }

  getBiayaKhusus() {
    this.uiService.showLoader();

    this.biayakhususService.getBySiswa(this.currentSiswaId).subscribe({
      next: resdata => {
        console.log("getBiayaKhusus", resdata);
        this.listBiayaKhusus = resdata;
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  openModalItemBiaya(content:any, data: any) {
    console.log(data);
    this.formAddItemBiayaKhusus.controls['id_biaya_khusus'].setValue(data.id);

    this.getListItemBiayaKhusus();
    
    this.submitted = false;
    // this.formAddItemGroupBiaya.reset();
    // this.formAddItemGroupBiaya.controls['group_name'].setValue(groupBiaya.name);
    // this.formAddItemGroupBiaya.controls['id_group_biaya'].setValue(groupBiaya.id);

    // this.getGroupBiayaById(groupBiaya.id);

    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  afterConfirm(isConfirm: Boolean, id: String, $SELF: any) {
    console.log(isConfirm, id);
    
    if (isConfirm) {
      $SELF.uiService.showLoader();

      const payload = {
        "active": $SELF.currentEvent.target.checked ? "Y" : "N"
      }

      $SELF.biayakhususService.setActive(id, payload).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getBiayaKhusus();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });
    } else {
      $SELF.currentEvent.target.checked = !$SELF.currentEvent.target.checked; 
    }

  }
  
  updateStatus(event: any, data: any) {
    console.log(data);
    this.currentEvent = event;
    let action = "Activate";
    if(event.target.checked==true){
      console.log('checkbox is checked');
    }
    else{
      action = "Un Activate";
      console.log('checkbox is unchecked');
    }

    this.uiService.showConfirm(this, data.id, "Yes, "+ action + " it!");
  }

  changeTab(url: String) {
    switch (url) {
      case 'info-tagihan':
        this.isInfoTagihan = true;
        this.isBiayaKhusus = false;
        this.isCatatan = false;
        this.isLog = false;
        this.currentTabTitle = "Tagihan dan Pembayaran";        
        return;
      case 'biaya-khusus':
        this.isInfoTagihan = false;
        this.isBiayaKhusus = true;
        this.isCatatan = false;
        this.isLog = false;
        this.currentTabTitle = "Group Biaya Khusus"; 
        this.getBiayaKhusus();
        this.getAllItemBiaya();
        return;
      case 'catatan':
        this.isInfoTagihan = false;
        this.isBiayaKhusus = false;
        this.isCatatan = true;
        this.isLog = false;
        this.currentTabTitle = "Catatan";        
        return;
      case 'log':
        this.isInfoTagihan = false;
        this.isBiayaKhusus = false;
        this.isCatatan = false;
        this.isLog = true;
        this.currentTabTitle = "Log";        
        return;
        
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  openModalFormBiayaKhusus(content:any) {
        
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    const isOpenModal = this.modalService.hasOpenModals();
    console.log("MODAL", isOpenModal);

  }

  onSubmitFormBiayaKhusus(modal: any): void {
    console.log(JSON.stringify(this.formGroupBiaya.value, null, 2));
    
    this.submitted = true;
    
    if (this.formGroupBiaya.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }
    
    modal.dismiss('Cross click');

    this.uiService.showLoader();

    this.biayakhususService.add(this.formGroupBiaya.value).subscribe({
      next: resdata => {
        console.log(resdata);

        this.submitted = false;
        this.formGroupBiaya.controls['id_group_biaya'].setValue('');

        this.getBiayaKhusus();

        this.uiService.hideLoader();
        this.uiService.showSuccess('');
              
        // this.getAllWalikelasByPeriode();

      },
      error: err => {
        console.log(err);

        // this.resetForm();
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  confirmDelete(id: String) {    
    this.uiService.showConfirmDelete(this, id);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.biayakhususService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getBiayaKhusus();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.error.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  onSubmitItemBiaya(modal: any): void {
    console.log(JSON.stringify(this.formAddItemBiayaKhusus.value, null, 2));
    
    this.submitted = true;
    
    if (this.formAddItemBiayaKhusus.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }
    
    // modal.dismiss('Cross click');

    this.uiService.showLoader();

    this.biayakhususService.addItemBiaya(this.formAddItemBiayaKhusus.value).subscribe({
      next: resdata => {
        console.log(resdata);

        this.submitted = false;
        this.formAddItemBiayaKhusus.controls['id_item_biaya'].setValue('');
        this.formAddItemBiayaKhusus.controls['jumlah'].setValue('');

        this.getBiayaKhusus();
        this.getListItemBiayaKhusus();

        this.uiService.hideLoader();
        this.uiService.showSuccess('');
              
        // this.getAllWalikelasByPeriode();

      },
      error: err => {
        console.log(err);
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getListItemBiayaKhusus() {
    this.uiService.showLoader();

    this.biayakhususService.getListItemBiaya(this.formAddItemBiayaKhusus.value.id_biaya_khusus).subscribe({
      next: resdata => {
        console.log(resdata);

        this.listItemBiayaKhusus = resdata;
        this.uiService.hideLoader();
      },
      error: err => {
        console.log(err);
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  confirmDeleteItemBiayaKhusus(id: any) {
    this.uiService.showLoader();

    this.biayakhususService.deleteItemBiaya(id).subscribe({
      next: resdata => {
        this.getBiayaKhusus();
        this.getListItemBiayaKhusus();        
        this.uiService.hideLoader();
      },
      error: err => {
        console.log(err);
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllItemBiaya() {
    this.uiService.showLoader();

    const params = null;

    this.itemBiayaService.getAll(params).subscribe({
      next: resdata => {
        console.log(resdata);
        // const { data, totalrows } = resdata;
        const totalrows = 20;
        this.listItemBiaya = resdata.data;
        this.count = totalrows;
      
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

}
