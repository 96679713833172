import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.css']
})
export class RoleEditComponent implements OnInit {

  errorMessage = '';
  roleId = "";
  currentRole: any = null;

  form: FormGroup = new FormGroup({
    role_name: new FormControl(''),
  });
  submitted = false;

  constructor(private formBuilder: FormBuilder, 
    private uiService: UiService, 
    private roleService: RoleService,
    private route: ActivatedRoute,
    private router: Router) 
  { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.roleId = this.route.snapshot.params['roleId'];
    this.getCurrentRole();
    this.initForm();
  }

  getCurrentRole() {
    this.uiService.showLoader();

    this.roleService.get(this.roleId).subscribe({
      next: resdata => {
        console.log(resdata);
        this.form.controls['role_name'].setValue(resdata.role_name);
        this.uiService.hideLoader();
      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru");
    }
  }
 
  initForm() {
    this.form = this.formBuilder.group(
      {
        role_name: ['', Validators.required],
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    
    if (this.form.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    this.roleService.update(this.roleId, this.form.value).subscribe({
      next: resdata => {
        console.log(resdata);
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
      
        this.router.navigate(['/settings/role/list']);

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });

    console.log(JSON.stringify(this.form.value, null, 2));
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

}
