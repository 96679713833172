import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  slugify(word: string){
    return word.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
  }

  getUserDetail() {
    const currentUser: string = localStorage.getItem('user')?.toString() || '';

    return currentUser !== '' ? JSON.parse(currentUser) : null;
  }

  getUserPermissions(dataUser: any) {
    console.log("DATAUSER", dataUser);
    
    let permissions = new Array();

    if (dataUser !== null && dataUser !== '') {
      console.log('ADA DATA');
      
      const userPerms = dataUser.proguser.Role.RolePermission;
  
      permissions.push(dataUser.proguser.Role.slug);
  
      for (const k in userPerms) {
        console.log("k is : ", k, userPerms[k]);
        permissions.push(userPerms[k].Permission.slug)          
      }
    } 

    return permissions;
  }

  getListSemester() {
    const listSemester = [
      {
        kode: 'MS1',
        name: 'Murojaah Ammah 1', 
      },
      {
        kode: 'S1',
        name: 'Semester 1', 
      },
      {
        kode: 'MS2',
        name: 'Murojaah Ammah 2', 
      },
      {
        kode: 'S2',
        name: 'Semester 2', 
      },
    ]

    return listSemester;
  }

  getListMonth() {
    const listMonth = [
      "Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"
    ]

    return listMonth;
  }

  getListMonthTahunAjaran() {
    const listMonth = [
      "Juli","Agustus","September","Oktober","November","Desember","Januari","Februari","Maret","April","Mei","Juni"
    ]

    return listMonth;
  }

  getListMonthTahunAjaranWithNumber() {
    const listMonth = [
      {
        bulan: "Juli", bln: "Jul", blnNumber: 7
      },
      {
        bulan: "Agustus", bln: "Agu", blnNumber: 8
      },
      {
        bulan: "September", bln: "Sep", blnNumber: 9
      },
      {
        bulan: "Oktober", bln: "Okt", blnNumber: 10
      },
      {
        bulan: "November", bln: "Nov", blnNumber: 11
      },
      {
        bulan: "Desember", bln: "Des", blnNumber: 12
      },
      {
        bulan: "Januari", bln: "Jan", blnNumber: 1
      },
      {
        bulan: "Februari", bln: "Feb", blnNumber: 2
      },
      {
        bulan: "Maret", bln: "Mar", blnNumber: 3
      },
      {
        bulan: "April", bln: "Apr", blnNumber: 4
      },
      {
        bulan: "Mei", bln: "Mei", blnNumber: 5
      },
      {
        bulan: "Juni", bln: "Jun", blnNumber: 6
      },
    ]

    return listMonth;
  }

  getListTanggalBulan() {
    const listTglBulan = [];

    for (let i=1;i<=31;i++) {
      listTglBulan.push(i);
    }

    return listTglBulan;
  }

  getListMonthEfective(tahun: String): any[] {
    const splTahun = tahun.split("-");
    const thAwal = splTahun[0];
    const thAkhir = splTahun[1];

    const listBulanEfektif = [
      {
        bulan: "juli", bln: "Jul", blnNumber: 7, tahun: thAwal
      },
      {
        bulan: "agustus", bln: "Agu", blnNumber: 8, tahun: thAwal
      },
      {
        bulan: "september", bln: "Sep", blnNumber: 9, tahun: thAwal
      },
      {
        bulan: "oktober", bln: "Okt", blnNumber: 10, tahun: thAwal
      },
      {
        bulan: "november", bln: "Nov", blnNumber: 11, tahun: thAwal
      },
      {
        bulan: "desember", bln: "Des", blnNumber: 12, tahun: thAwal
      },
      {
        bulan: "januari", bln: "Jan", blnNumber: 1, tahun: thAkhir
      },
      {
        bulan: "februari", bln: "Feb", blnNumber: 2, tahun: thAkhir
      },
      {
        bulan: "maret", bln: "Mar", blnNumber: 3, tahun: thAkhir
      },
      {
        bulan: "april", bln: "Apr", blnNumber: 4, tahun: thAkhir
      },
      {
        bulan: "mei", bln: "Mei", blnNumber: 5, tahun: thAkhir
      },
      {
        bulan: "juni", bln: "Jun", blnNumber: 6, tahun: thAkhir
      },
    ]
    return listBulanEfektif;
  }

  getListTugasKe() {
    const listTugasKe = [];
    for (let i=1; i<=8; i++) {
      listTugasKe.push(i);
    }

    return listTugasKe;
  }

  getCurrentDayName(date: any = null) {
    let now = moment();
    
    if (date !== null) {
      now = moment(date);
    }
    return now.format('dddd');
  }

  getCurrentMonthName() {
    const listBulan = this.getListMonth();
    const now = moment();
    const bulanKe = parseInt(now.format('M')) - 1;

    return listBulan[bulanKe];
  }

  getCurrentMonth() {
    const now = moment();
    return now.format('M');
  }

  getCurrentYear() {
    const now = moment();
    const tahun = parseInt(now.format('Y'));

    return tahun;
  }

  getListFiveYearsBefore() {
    let listYears = [];

    const currentYear = this.getCurrentYear();
    console.log("CUREN YEAR", currentYear);
    
    for (let i=currentYear; i>currentYear-5; i--) {
      console.log(i);
      
      listYears.push(i);
    }

    return listYears;
  }

  getCurentDate() {
    let now = moment();
    return now.format('YYYY-MM-DD');

  }

  convertDate(date: any = null, format: any = 'YYYY-MM-DD') {
    console.log("TGL", date);
    
    let mdate = date !== null ? moment(date) : moment();

    return mdate.format(format);
  }

  getCurrentDateBetweenDateRange(start: any, end: any) {
    const now = this.convertDate(moment());
    const sDate = this.convertDate(start);
    const eDate = this.convertDate(end);

    console.log("RANGE", now,sDate,eDate);

    if (now === sDate || now === eDate) return true;

    return moment(now).isBetween(sDate, eDate);
  }

  getListDays(): any[] {
    const listDays = [
      {
        key: "Saturday",
        val: "Saturday"
      },
      {
        key: "Sunday",
        val: "Sunday"
      },
      {
        key: "Monday",
        val: "Monday"
      },
      {
        key: "Tuesday",
        val: "Tuesday"
      },
      {
        key: "Wednesday",
        val: "Wednesday"
      },
      {
        key: "Thursday",
        val: "Thursday"
      },
    ]

    return listDays;
  }
}
