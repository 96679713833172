<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="guru-input"
    role="tabpanel"
    aria-labelledby="guru-input-tab"
  >
    <div class="tabs-title">Input Nilai Al-Quran</div>

    <form [formGroup]="formInputHarianAlQuran" (ngSubmit)="onSubmitformInputHarianAlQuran()">
      <div class="col-12 col-lg-12">
        <div class="card">
            
            <div class="card-body">
                
                    <div class="row">
                        <div class="col-12 col-lg-12">
                            <div class="mb-3">
                                <div class="row align-items-center">
                                    <div class="col-2 text-end">
                                        <label for="nik" class="form-label">Tahun Ajaran <span
                                                class="required">*</span></label>
                                    </div>
                                    <div class="col-10">                            
                                      <select
                                        (change)="changeTahunAjaran($event)" 
                                        formControlName="id_tahun_ajaran" [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }" 
                                        class="form-select">
                                        <option [value]="" selected disabled>Pilih TA</option>
                                        <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                                      </select>
                                    </div>
                                </div>
                            </div>                  
                            <div class="mb-3">
                                <div class="row align-items-center">
                                    <div class="col-2 text-end">
                                        <label for="name" class="form-label">Bulan <span
                                                class="required">*</span></label>
                                    </div>
                                    <div class="col-10">
                                      <select (change)="changeBulan($event)" formControlName="bulan" [ngClass]="{ 'is-invalid': submitted && f['bulan'].errors }" class="form-select">
                                        <option value="" selected>Pilih Bulan</option>
                                        <option [value]="month" *ngFor="let month of listMonth">{{ month }}</option>
                                      </select>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="name" class="form-label">Kelas <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-10">
                                    <select formControlName="id_kelas" (change)="changeKelas($event)" 
                                      [ngClass]="{ 'is-invalid': submitted && f['id_kelas'].errors }" 
                                      class="form-select" name="id_tahun_ajaran" id="id_kelas">
                                      <option value="" selected>Pilih Kelas</option>
                                      <option [value]="data.id" *ngFor="let data of listKelas">{{data.nama_kelas}}</option>
                                    </select>
                                  </div>
                              </div>
                            </div>
                            
                            <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="name" class="form-label">Subject <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-10">
                                    <input formControlName="subject" type="text" [ngClass]="{ 'is-invalid': submitted && f['subject'].errors }" class="form-control">
                                  </div>
                              </div>
                            </div>
  
                            <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="name" class="form-label"></label>
                                  </div>
                                  <div class="col-10">                                  
                                    * JIKA NILAI SANTRI 0 MAKA KETERANGAN HARUS DI ISI!
                                  </div>
                              </div>
                            </div>
  
                            <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="name" class="form-label">&nbsp;</label>
                                  </div>
                                  <div class="col-10">
                                    <!-- <button class="btn btn-blue-grey mt-2" style="display:inline; margin-right: 4px;">
                                      Tampil
                                    </button> -->
                                    <button class="btn btn-blue-grey mt-2" style="display:inline;">
                                      Simpan
                                    </button>
                                  </div>
                              </div>
                            </div>                          
                        </div>                  
                    </div>
                
            </div>
        </div>
      </div>
  
      <div class="table-wrapper">
        <div class="table-options">
          <div class="show-table">
            Show
            <select
              class="form-control"
              name="show"
              id="show"
              (change)="handlePageSizeChange($event)"
            >
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
            entries
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th></th>
                <th>No. Induk</th>
                <th>Nama</th>
                <th width="150px">Nilai</th>
                <th>Keterangan</th>              
              </tr>
            </thead>
            <tbody>
              <ng-container formArrayName="dataFormNilaiQuran">
                <tr
                  [formGroupName]="i"
                  *ngFor="
                    let siswa of listNilaiAlquran
                      | paginate
                        : {
                            id: pageid,
                            itemsPerPage: pageSize,
                            currentPage: page,
                            totalItems: count
                          };
                    let i = index
                  "
                >
                  <td>
                    <input formControlName="siswa_id" type="text" class="form-control d-none">
                    {{ i + 1 + (page - 1) * pageSize }}
                  </td>
                  <td>{{ siswa.no_induk }}</td>
                  <td>{{ siswa.nama_lengkap }}</td>
                  <td>
                    <input 
                      type="number" 
                      class="form-control" 
                      formControlName="nilai" 
                    >
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="keterangan">
                  </td>
                </tr>
              </ng-container>
              <tr *ngIf="listNilaiAlquran.length === 0" class="empty">
                <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
          <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
            <div></div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center pagination-number">
                <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                  <a
                    class="page-link"
                    (click)="p.setCurrent(page.value)"
                    *ngIf="p.getCurrent() !== page.value"
                  >
                    {{ page.label }}
                  </a>
                  <a class="page-link" *ngIf="p.getCurrent() === page.value">
                    {{ page.label }}
                  </a>
                </li>
              </ul>
            </nav>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="p.isFirstPage()">
                  <a
                    class="page-link"
                    *ngIf="!p.isFirstPage()"
                    (click)="p.previous()"
                    aria-label="Previous"
                  >
                    <span>Previous</span>
                  </a>
                </li>
                <li class="page-item" [class.disabled]="p.isLastPage()">
                  <a
                    class="page-link"
                    *ngIf="!p.isLastPage()"
                    (click)="p.next()"
                    aria-label="Next"
                  >
                    <span>Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </pagination-template>
  
        <ng-template #emptyPagination>
          <div class="pagination-wrapper">
            <div>&nbsp;</div>
          </div>
        </ng-template>
      </div>
    </form>
    

  </div>
</div>