import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';


@Injectable({
  providedIn: 'root'
})
export class RayonService {

  constructor(private netService: NetworkService) { }

  getAll(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/rayon', params);
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/rayon');
  }

  get(id: string): Observable<any> {
    return this.netService.getRequest(`/v1/rayon/${id}`);
  }

  getByGedung(id: string): Observable<any> {
    return this.netService.getRequest(`/v1/rayonbygedung/${id}`);
  }  

  update(id: string, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/rayon/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/rayon/${id}`);
  }
}
