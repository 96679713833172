import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdm-catatan-guru-statistik',
  templateUrl: './sdm-catatan-guru-statistik.component.html',
  styleUrls: ['./sdm-catatan-guru-statistik.component.css']
})
export class SdmCatatanGuruStatistikComponent implements OnInit {

  listStatistikCatatanGuru: any = [];
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  isShowLoader: Boolean = false;
  searchStatistikCatatanGuru = "";

  closeResult: string = '';

  constructor() {
    this.changeBodyClass();
  }

  ngOnInit(): void {
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }
}
