import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { BerkasService } from 'src/app/services/berkas.service';

declare var Datepicker: any;

@Component({
  selector: 'app-berkas-add',
  templateUrl: './berkas-add.component.html',
  styleUrls: ['./berkas-add.component.css']
})
export class BerkasAddComponent implements OnInit {

  errorMessage = '';
  currentPeriode: any;

  form: FormGroup = new FormGroup({
    nama_dokumen: new FormControl(''),
    jumlah: new FormControl(''),
    urut: new FormControl(''),
    active: new FormControl(false),
    mandatory: new FormControl(false),
  });
  submitted = false;

  constructor(private formBuilder: FormBuilder, 
    private uiService: UiService, 
    private tahunajaranService: TahunajaranService,
    private berkasService: BerkasService,
    private router: Router) 
  { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.initForm();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru");
    }
  }

  onBack() {
    this.router.navigate(['/settings/berkas/list']);
  }
  
  initForm() {
    this.form = this.formBuilder.group(
      {
        nama_dokumen: ['', Validators.required],
        jumlah: ['', Validators.required],
        urut: ['', Validators.required],
        active: [true],
        mandatory: [true],
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    this.berkasService.add(this.form.value).subscribe({
      next: resdata => {
        console.log(resdata);
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
      
        this.router.navigate(['/settings/berkas/list']);

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });

    console.log(JSON.stringify(this.form.value, null, 2));
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

}
