import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UiService } from 'src/app/helper/ui.service';
import { GeneralService } from 'src/app/services/general.service';
import { PembayaranService } from 'src/app/services/pembayaran.service';
import { CommonService } from 'src/app/helper/common.service';
import { TingkatService } from 'src/app/services/tingkat.service';
import { LembagaService } from 'src/app/services/lembaga.service';
import { ExcelService } from 'src/app/services/excel.service';

declare var Datepicker: any;

@Component({
  selector: 'app-siswa-pembayaran',
  templateUrl: './siswa-pembayaran.component.html',
  styleUrls: ['./siswa-pembayaran.component.css']
})
export class SiswaPembayaranComponent implements OnInit {

  listPembayaran: any = [];
  listsLembaga: any = [];
  currentLembagaId = "";
  listsTingkat: any = [];
  currentTingkatId = "";
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  startDate = null;
  endDate = null;
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  closeResult: string = '';
  isShowPembayaran: Boolean = false;

  form: FormGroup = new FormGroup({
    id_periode: new FormControl(''),
    title: new FormControl(''),
    start_date: new FormControl(''),
    end_date: new FormControl(''),
    ujian_tulis_date: new FormControl(''),
    ujian_lisan_date: new FormControl(''),
    pengumuman_kelulusan_date: new FormControl(''),
    active: new FormControl(false),
    searchTerm: new FormControl(''),
    id_lembaga: new FormControl(''),
    id_tingkat: new FormControl(''),
  });

  constructor(
    private pembayaranService: PembayaranService,
    private tingkatService: TingkatService,
    private lembagaService: LembagaService,
    private generalService: GeneralService,
    private uiService: UiService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private excelService: ExcelService,
    private formBuilder: FormBuilder) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.initForm();
    this.configureDatePicker();
    this.getLembaga();

    const nowDate = this.commonService.convertDate();

    this.form.controls['start_date'].setValue(nowDate);
    this.form.controls['end_date'].setValue(nowDate);

    // this.getAllPembayaranFormulir();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    params['startDate'] = this.form.controls['start_date'].value;
    params['endDate'] = this.form.controls['end_date'].value;
    params['idLembaga'] = this.form.controls['id_lembaga'].value;
    params['idTingkat'] = this.form.controls['id_tingkat'].value;

    console.log("PARAMS", params);
    

    return params;
  }

  initForm() {
    this.form = this.formBuilder.group(
      {
        id_periode: ['', Validators.required],
        title: ['', Validators.required],
        start_date: ['', Validators.required],
        end_date: ['', Validators.required],
        ujian_tulis_date: ['', Validators.required],
        ujian_lisan_date: ['', Validators.required],
        pengumuman_kelulusan_date: ['', Validators.required],
        active: [false],
        searchTerm: [''],
        id_lembaga: [''],
        id_tingkat: [''],
      },
    );
  }

  configureDatePicker() {
    const elem_start_date = document.getElementById('start_date');
    const datepicker_start_date = new Datepicker(elem_start_date, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    });
    
    const elem_end_date = document.getElementById('end_date');
    const datepicker_end_date = new Datepicker(elem_end_date, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    });

  }

  changeStartDateField(e: any) {
    console.log(e.target.value);
    const startDate = e.target.value;
    this.form.controls['start_date'].setValue(startDate);
  }

  changeEndDateField(e: any) {
    console.log(e.target.value);
    const endDate = e.target.value;
    this.form.controls['end_date'].setValue(endDate);
  }

  getAllPembayaranFormulir() {
    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.pembayaranService.getAllPembayaranFormulir(params).subscribe({
      next: resdata => {
        console.log(resdata);
        const { data, totalrows } = resdata;

        this.isShowPagination == totalrows > this.pageSize ? true : false;
        this.listPembayaran = data;
        this.count = totalrows;

        console.log('COUNT', this.count);


        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.getAllPembayaranFormulir();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getAllPembayaranFormulir();
  }

  search(): void {
    this.page = 1;
    this.searchTerm = this.form.controls['searchTerm'].value;
    this.getAllPembayaranFormulir();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit() {

  }

  showPembayaranFormulir() {
    this.isShowPembayaran = true;
    this.getAllPembayaranFormulir();
  }

  getLembaga() {
    this.uiService.showLoader();

    this.lembagaService.getAllLembaga('').subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsLembaga = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeLembaga(e: any) {
    console.log(e.target.value);
    const lembagaId = e.target.value;
    this.currentLembagaId = lembagaId;
    this.currentTingkatId = "";
    this.form.controls['id_tingkat'].setValue('');
    this.getTingkatLembaga(lembagaId);
    this.getAllPembayaranFormulir();
  }

  private getTingkatLembaga(lembagaId: any) {
    this.uiService.showLoader();
  
    this.tingkatService.getTingkatByLembagaId(lembagaId).subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsTingkat = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeTingkat(e: any) {
    console.log(e.target.value);
    this.currentTingkatId = e.target.value;

    this.getAllPembayaranFormulir();    
  }

  exportToExcel(): void {
    let data: any[] = [];

    for (const k in this.listPembayaran) {
      const v = this.listPembayaran[k];
      const row = {
        NO: parseInt(k)+1,
        TGL_TRANSFER: v.waktu_transaksi,
        NAMA: v.pembayaranTagihan.siswaTagihan.nama_lengkap,
        LEMBAGA: v.pembayaranTagihan.siswaTagihan.lembaga.nama_lembaga,
        TINGKATAN: v.pembayaranTagihan.siswaTagihan.tingkat.nama_tingkat,
        NOMINAL: v.jumlah,
      }

      data.push(row);
    }

    this.excelService.exportAsExcelFile(data, "List Pembayaran Formulir.xlsx");
  }

}
