import { Component, OnInit } from '@angular/core';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/helper/common.service';
import { ExcelService } from 'src/app/services/excel.service';
import { KelasService } from 'src/app/services/kelas.service';
import { NilaiHarianService } from 'src/app/services/nilai-harian.service';

@Component({
  selector: 'app-input-nilai-harian-al-quran',
  templateUrl: './input-nilai-harian-al-quran.component.html',
  styleUrls: ['./input-nilai-harian-al-quran.component.css']
})
export class InputNilaiHarianAlQuranComponent implements OnInit {

  listSemester: any = [];
  listTahunajaran: any = [];
  listNilaiAlquran: any = [];
  nilaiQuran: any = null;
  listKelas: any = [];
  currentPeriodeId: any = null;
  dataToUpload: any = null;
  pageid = "upload-jadwal-mengajar";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  errorMessage = "";
  currentDate = "";
  submitted = false;
  listMonth: any = [];
  currentKelasId: any = null;
  currentBulan: any = null;
  
  formInputHarianAlQuran: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
    bulan: new FormControl(''),
    id_kelas: new FormControl(''),
    subject: new FormControl(''),
    id_penginput: new FormControl(''),
    dataFormNilaiQuran: new FormArray([]),
  }); 

  constructor(
    private uiService: UiService,
    private tahunajaranService: TahunajaranService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private kelasService: KelasService,
    private excelService: ExcelService,
    private nilaiharianService: NilaiHarianService,
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getKelas();
    
    this.listMonth = this.commonService.getListMonthTahunAjaran();    
    this.getCurrentTahunajaran();
    this.listSemester = this.commonService.getListSemester();
    this.initForm();
    this.formInputHarianAlQuran.controls['id_tahun_ajaran'].disable();
    
    const currentUser = localStorage.getItem('user')?.toString();
    const jsonUser = currentUser ? JSON.parse(currentUser) : "";
    console.log("CURRENT USER", jsonUser.proguser.userProfile.real_name);
    
    this.formInputHarianAlQuran.controls['id_penginput'].setValue(jsonUser.proguser.id);
    
    const currentMonth = this.commonService.getCurrentMonthName();
    this.currentBulan = currentMonth;
    this.formInputHarianAlQuran.controls['bulan'].setValue(currentMonth);
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  disableForm() {
    this.formInputHarianAlQuran.controls['id_tahun_ajaran'].disable();
  }

  enableForm() {
    this.formInputHarianAlQuran.controls['id_tahun_ajaran'].enable();
  }

  resetForm() {
    this.formInputHarianAlQuran.controls['id_kelas'].setValue('');
    this.formInputHarianAlQuran.controls['subject'].setValue('');
    this.listNilaiAlquran = [];
  }

  initForm() {
    this.formInputHarianAlQuran = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        bulan: ['', Validators.required],
        id_kelas: ['', Validators.required],
        subject: ['', Validators.required],
        nilai_quran_id: [''],
        id_penginput: ['', Validators.required],
        dataFormNilaiQuran : new FormArray([]),
      },
    );

  }

  get dataFormNilaiQuran(): FormArray {
    return this.formInputHarianAlQuran.get('dataFormNilaiQuran') as FormArray;
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  changeTahunAjaran(e: any) {
    this.currentPeriodeId = e.target.value;
    this.formInputHarianAlQuran.controls['id_kelas'].setValue("");
    this.formInputHarianAlQuran.controls['id_kelas'].disable();
    this.formInputHarianAlQuran.controls['bulan'].setValue("");
    this.formInputHarianAlQuran.controls['subject'].setValue("");
    this.listNilaiAlquran = [];
  }

  changeBulan(e: any) {
    this.formInputHarianAlQuran.controls['id_kelas'].enable();
    this.formInputHarianAlQuran.controls['id_kelas'].setValue("");
    this.listNilaiAlquran = [];
    this.currentBulan = e.target.value;
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formInputHarianAlQuran.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;        

        this.uiService.hideLoader();

        this.getAllTahunajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }


  onSubmitformInputHarianAlQuran() {
    this.submitted = true;
    this.enableForm();
    console.log(JSON.stringify(this.formInputHarianAlQuran.value, null, 2));

    if (this.formInputHarianAlQuran.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    this.uiService.showLoader();

    this.nilaiharianService.addNilaiQuran(this.formInputHarianAlQuran.value).subscribe({
      next: resdata => {
        console.log(resdata);

        this.uiService.hideLoader();
        this.uiService.showSuccess('');

        this.disableForm();
        this.resetForm();
        this.submitted = false;

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });   
    
  }

  onFileChange(evt: any) {
    const fdata: FileReader = this.excelService.convertExcelFileToJson(evt.target);

    fdata.onloadend = (e: any) => {
      // console.log("END OF ROAD", this.excelService.dataExcel);
      const fileUpload: any[] = this.excelService.dataExcel;
      const finalFileUpload = fileUpload.slice(1);

      if (finalFileUpload.length > 0) {
        let dataToUpload = [];
        for (const k in finalFileUpload) {
          const v = finalFileUpload[k];
          console.log(k, v);
          // 1. get periode_id
          // 2. get kelas_id
          // 3. get siswaid
          const column = [v[4],v[1],v[5]];
          dataToUpload.push(column)
        }
        console.log("DATA TO UPLOAD", dataToUpload);

        this.dataToUpload = dataToUpload;
        
        // const payload = {
        //   data: dataToUpload,
        //   idperiode: this.currentPeriodeId
        // }
        
        // this.siswakelasService.uploadSiswakelas(payload).subscribe({
        //   next: resdata => {
        //     console.log('RESPONSE', resdata);
    
        //     this.refreshListKelas();
            
        //     this.uiService.hideLoader();
    
        //   },
        //   error: err => {
        //     this.errorMessage = err.error.message;
        //     console.log(this.errorMessage);
    
        //     this.uiService.hideLoader();
    
        //     this.uiService.showError(this.errorMessage);
        //   }
        // });
      } 
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formInputHarianAlQuran.controls;
  }

  changeKelas(e: any) {
    this.currentKelasId = e.target.value;
    console.log("DATA INPUT", this.currentKelasId, this.currentPeriodeId);

    // get nilaiQuran
    const payloadNH = {
      periode_id: this.currentPeriodeId,
      bulan: this.currentBulan,
      id_kelas: this.currentKelasId
    }
    this.nilaiharianService.getInputHarianQuran(payloadNH).subscribe({
      next: resdata => {
        console.log("NH", resdata);

        this.formInputHarianAlQuran.controls['subject'].setValue("");
        this.formInputHarianAlQuran.controls['nilai_quran_id'].setValue("");
        if (resdata !== null) {
          this.formInputHarianAlQuran.controls['subject'].setValue(resdata.subject);
          this.formInputHarianAlQuran.controls['nilai_quran_id'].setValue(resdata.id);
        }

        this.nilaiQuran = resdata;
        
        // get list nilai qurn
        this.getListInputHarianQuran();
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
    
    
  }
  
  private getListInputHarianQuran() {
    const payloadLNH = {
      periode_id: this.currentPeriodeId,
      id_kelas: this.currentKelasId,
      nilai_quran_id: this.nilaiQuran !== null ? this.nilaiQuran.id : null,
    }
    this.nilaiharianService.getListInputHarianQuran(payloadLNH).subscribe({
      next: resdata => {
        console.log("LNH", resdata);
  
        this.listNilaiAlquran = resdata;

        // reset form array
        this.dataFormNilaiQuran.clear();

        for (let i = 0; i < this.listNilaiAlquran.length; i++) {        
          this.dataFormNilaiQuran.push(this.formBuilder.group({            
            // 'nilai_quran_id': this.listNilaiAlquran[i] && this.listNilaiAlquran[i].nilai_quran_id ? this.listNilaiAlquran[i].nilai_quran_id : this.nilaiQuran.id,
            'siswa_id': this.listNilaiAlquran[i] && this.listNilaiAlquran[i].siswa_id ? this.listNilaiAlquran[i].siswa_id : '',
            'nilai': [this.listNilaiAlquran[i] && this.listNilaiAlquran[i].nilai ? this.listNilaiAlquran[i].nilai : '0', Validators.required],
            'keterangan': this.listNilaiAlquran[i] && this.listNilaiAlquran[i].keterangan ? this.listNilaiAlquran[i].keterangan : '',
          }));
        }
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

}
