import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import { Permission } from 'src/app/modules/permission/permission';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.css']
})
export class PermissionListComponent implements OnInit {

  permissions: Permission[] = [];

  constructor(
    public uiService: UiService,
    public permissionService: PermissionService, 
    public datePipe: DatePipe
  ) { }
     
  ngOnInit(): void {
    this.uiService.showLoader();

    this.permissionService.getAll('').subscribe((data: Permission[])=>{
      this.permissions = data;
      this.uiService.hideLoader();
    })  
  }

  deletePermission(id:number){
    if(confirm("Are you sure to delete ??")) {
      this.permissionService.delete(id).subscribe(_res => {
        this.permissions = this.permissions.filter(item => item.id !== id);
        console.log('Permission deleted successfully!');
      });
    }
  }
     
  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, 'd-MM-Y');
  }
}
