import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Validation from 'src/app/helper/validation';
import { SiswaService } from 'src/app/services/siswa.service';
import { GeneralService } from 'src/app/services/general.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { TingkatService } from 'src/app/services/tingkat.service';
import { LembagaService } from 'src/app/services/lembaga.service';
import { SiswakelasService } from 'src/app/services/siswakelas.service';
import { ExcelService } from 'src/app/services/excel.service';
import { KelasService } from 'src/app/services/kelas.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-siswa-penempatan-kelas-list',
  templateUrl: './siswa-penempatan-kelas-list.component.html',
  styleUrls: ['./siswa-penempatan-kelas-list.component.css']
})
export class SiswaPenempatanKelasListComponent implements OnInit {

  activePeriodeId: any = null;
  currentPeriodeId: any = null;
  currentPpsbPeriodeId: any = null;
  currentAngkatan = "";
  listsLembaga: any = [];
  currentLembagaId = "";
  listsTingkat: any = [];
  currentTingkatId = "";
  listPenempatanKelas: any = [];
  listTahunajaran: any = [];
  listKelas: any = [];
  errorMessage = '';
  submitted = false;
  pageid = "proguser";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  isShowLoader: Boolean = false;
  searchPenempatanKelas = "";

  formAction: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
    id_lembaga: new FormControl(''),
    id_tingkat: new FormControl(''),
  });

  constructor(private siswaService: SiswaService,
    private generalService: GeneralService,
    private uiService: UiService,
    private modalService: NgbModal,
    private tingkatService: TingkatService,
    private lembagaService: LembagaService,
    private tahunajaranService: TahunajaranService,
    private siswakelasService: SiswakelasService,
    private excelService: ExcelService,
    private kelasService: KelasService,
    private sharedDataService: SharedDataService,
    private formBuilder: FormBuilder) {
    this.changeBodyClass();
  }

  ngOnInit(): void {

    this.getKelas();
    this.getLembaga();
    this.initDataTahunAjaran();    

    this.formAction = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        id_lembaga: ['0'],
        id_tingkat: ['0'],
      },
    );

  }

  initDataTahunAjaran() {
    this.getCurrentTahunajaran();
    this.getPpsbTahunajaran();    
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAction.controls;
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getLembaga() {
    this.uiService.showLoader();

    this.lembagaService.getAllLembaga('').subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsLembaga = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeLembaga(e: any) {
    console.log(e.target.value);
    const lembagaId = e.target.value;
    this.currentLembagaId = lembagaId === "0" ? "" : lembagaId;
    this.currentTingkatId = "";
    this.getTingkatLembaga(lembagaId);
    this.displayPlotKelas();
  }

  private getTingkatLembaga(lembagaId: any) {
    this.uiService.showLoader();
  
    this.tingkatService.getTingkatByLembagaId(lembagaId).subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsTingkat = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onFileChange(evt: any) {
    const fdata: FileReader = this.excelService.convertExcelFileToJson(evt.target);

    fdata.onloadend = (e: any) => {
      // console.log("END OF ROAD", this.excelService.dataExcel);
      const fileUpload: any[] = this.excelService.dataExcel;
      const finalFileUpload = fileUpload.slice(1);

      if (finalFileUpload.length > 0) {
        let dataToUpload = [];
        for (const k in finalFileUpload) {
          const v = finalFileUpload[k];
          console.log(k, v);
          // 1. get periode_id
          // 2. get kelas_id
          // 3. get siswaid
          if (v.length === 0) {
            break;
          }
          const column = [v[4],v[1],v[5]];
          dataToUpload.push(column)
        }
        console.log("DATA TO UPLOAD", dataToUpload);
        
        const payload = {
          data: dataToUpload,
          idperiode: this.currentPeriodeId
        }
        
        this.uiService.showLoader();

        this.siswakelasService.uploadSiswakelas(payload).subscribe({
          next: resdata => {
            console.log('RESPONSE', resdata);
    
            this.refreshListKelas();
            
            this.uiService.hideLoader();
            this.uiService.showSuccess("");
    
          },
          error: err => {
            this.errorMessage = err.error.message;
            console.log(this.errorMessage);
    
            this.uiService.hideLoader();
    
            this.uiService.showError(this.errorMessage);
          }
        });
      } 
    }
  }

  downloadSampleFormat(): void {
    let data = [];

    for (const k in this.listKelas) {
      const v = this.listKelas[k];
      const row = {
        NO: parseInt(k)+1,
        NO_INDUK: "2.23.xxx",
        NAMA: "",
        KELAS: v.nama_kelas,
        KODE_KELAS: v.id,
        TAHUN_PELAJARAN: this.currentAngkatan
      }

      data.push(row);
    }

    this.excelService.exportAsExcelFile(data, "Format Upload Penempatan Kelas.xlsx");
  }

  changeTingkat(e: any) {
    console.log(e.target.value);
    this.currentTingkatId = e.target.value === "0" ? "" : e.target.value;

    this.displayPlotKelas();    
  }

  displayPlotKelas() {
    //
    if (this.currentPeriodeId !== null && this.currentLembagaId !== "" && this.currentTingkatId !== "") {
      this.getAllPlotKelasByPeriodeAndTingkat();
    } else if (this.currentPeriodeId !== null && this.currentLembagaId !== "") {
      this.getAllPlotKelasByPeriodeAndLembaga();
    } else if (this.currentPeriodeId !== null) {
      this.getAllPlotKelasByPeriode();
    }
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;
        this.activePeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        this.getAllTahunajaran();

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getPpsbTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePpsb().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPpsbPeriodeId = resdata.id;        

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPpsbPeriodeId, this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formAction.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllPlotKelasByPeriode() {
    //
    this.siswakelasService.getSiswakelasByPeriode(this.currentPeriodeId).subscribe({
      next: resdata => {
        console.log('list siswa kelas', resdata);

        this.listPenempatanKelas = resdata;

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllPlotKelasByPeriodeAndLembaga() {
    //
    this.siswakelasService.getSiswakelasByPeriodeAndLembaga(this.currentPeriodeId, this.currentLembagaId).subscribe({
      next: resdata => {
        console.log('list siswa kelas', resdata);

        this.listPenempatanKelas = resdata;

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllPlotKelasByPeriodeAndTingkat() {
    //
    this.siswakelasService.getSiswakelasByPeriodeAndTingkat(this.currentPeriodeId, this.currentTingkatId).subscribe({
      next: resdata => {
        console.log('list siswa kelas', resdata);

        this.listPenempatanKelas = resdata;

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    this.currentPeriodeId = e.target.value;
    console.log("currentAngkatan", this.currentPeriodeId);    

    this.refreshListKelas();
  }

  private refreshListKelas() {
    this.formAction.controls['id_lembaga'].setValue('0');
    this.formAction.controls['id_tingkat'].setValue('0');
    this.currentLembagaId = "";
    this.currentTingkatId = "";
    this.listsTingkat = [];

    this.displayPlotKelas();
  }

  onSubmitChangePassword(): void {
    this.submitted = true;
  }

  showDetailPenempatanKelas(siswa: any) {
    console.log(siswa);
    const object = {
      idperiod: this.currentPeriodeId,
      idkelas: siswa.kelas_id,
      listTahunAjaran: this.listTahunajaran,
      listKelas: this.listKelas
    }
    this.sharedDataService.changeDataObject(object);
    this.sharedDataService.changeComponent('penempatan-kelas');
    this.uiService.showFullScrrenContent();
  }
}
