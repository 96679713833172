<app-nav-tab></app-nav-tab>

  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="statistik"
      role="tabpanel"
      aria-labelledby="statistik-tab"
    >
      <div class="tabs-title">List Rekap Upload Berkas</div>

      <div class="list-options">
        <select class="form-select" name="" id="">
          <option>Upload</option>
        </select>
        <div class="search">
          <input
            type="text"
            class="form-control"
            placeholder="Search Name"
            name="search-list"
            id="search-list"
            [(ngModel)]="searchTerm"
          />
          <div class="icon" (click)="search()">
            <img src="./assets/images/icon-search.png" alt="Search" />
          </div>
        </div>

      </div>
      <div class="table-wrapper">
        <div class="table-options">
          <div class="show-table">
            Show
            <select
              class="form-control"
              name="show"
              id="show"
              (change)="handlePageSizeChange($event)"
            >
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
            entries
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th>No.</th>
                <th>No. Registrasi</th>
                <th>Nama</th>
                <th *ngFor="let berkas of listBerkas; let n = index">{{ berkas.nama_dokumen }}</th>            
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let siswa of listUploadDocuments | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
                <td>{{ i + 1 + (page - 1) * pageSize }}</td>
                <td>{{ siswa.regnumber }}</td>
                <td>{{ siswa.nama_lengkap }}</td>          
                <td *ngFor="let berkas of listBerkas; let n = index">
                  <button (click)="downloadFileBerkas(berkas.id, siswa.SiswaDokumenSyarat)">
                    {{ berkas.id | showSiswaBerkas : siswa.SiswaDokumenSyarat }}
                  </button>
                </td>                
              </tr>
              <tr *ngIf="listUploadDocuments.length === 0" class="empty">
                <td colspan="11">
                  Tidak terdapat data atau data tidak ditemukan.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
          <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
            <div></div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center pagination-number">
                <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                  <a
                    class="page-link"
                    (click)="p.setCurrent(page.value)"
                    *ngIf="p.getCurrent() !== page.value"
                  >
                    {{ page.label }}
                  </a>
                  <a class="page-link" *ngIf="p.getCurrent() === page.value">
                    {{ page.label }}
                  </a>
                </li>
              </ul>
            </nav>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="p.isFirstPage()">
                  <a
                    class="page-link"
                    *ngIf="!p.isFirstPage()"
                    (click)="p.previous()"
                    aria-label="Previous"
                  >
                    <span>Previous</span>
                  </a>
                </li>
                <li class="page-item" [class.disabled]="p.isLastPage()">
                  <a
                    class="page-link"
                    *ngIf="!p.isLastPage()"
                    (click)="p.next()"
                    aria-label="Next"
                  >
                    <span>Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </pagination-template>

        <ng-template #emptyPagination>
          <div class="pagination-wrapper">
            <div>&nbsp;</div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
