import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-siswa-penempatan-kelas',
  templateUrl: './siswa-penempatan-kelas.component.html',
  styleUrls: ['./siswa-penempatan-kelas.component.css']
})
export class SiswaPenempatanKelasComponent implements OnInit {

  isStatistik = false;
  isList = false;
  isPeringkat = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    console.log(this.router.url);
    switch (this.router.url) {
      case '/pendidikan/penempatan-kelas/statistik':
        this.isStatistik = true;
        this.isList = false;
        this.isPeringkat = false;
        break;

      case '/pendidikan/penempatan-kelas/list':
        this.isStatistik = false;
        this.isList = true;
        this.isPeringkat = false;
        break;

      case '/pendidikan/penempatan-kelas/peringkat':
        this.isStatistik = false;
        this.isList = false;
        this.isPeringkat = true;
        break;

      default:
        this.isStatistik = false;
        this.isList = false;
        this.isPeringkat = false;
        break;
    }
  }
}
