import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseStatus'
})
export class ParseStatusPipe implements PipeTransform {

  transform(value: string, def: string): string {
    let result = "";
    switch (value) {
      case 'PENDING':
        return "Pending";
      case 'PERMITTED':
        return "Diizinkan";
      case 'NOT_PERMITTED':
        return "Tidak Diizinkan";
      default:        
        return def;
    }
  }

}
