<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="statistik"
    role="tabpanel"
    aria-labelledby="statistik-tab"
  >
    <div class="tabs-title">Log Activity</div>

    
    <div class="list-options">      

      <div class="form-select-warapper">
        <form [formGroup]="formAction">
          <select formControlName="module" (change)="changeModule($event)" class="form-select">
            <option value="">Pilih Modul</option>
            <option [value]="data" *ngFor="let data of listModule">{{data}}</option>
          </select>
        </form>
      </div>

      <div class="form-select-warapper">
        <form [formGroup]="formAction">
          <select formControlName="id_user" (change)="changeUser($event)" class="form-select">
            <option value="">Pilih Staff</option>
            <option [value]="staff.id" *ngFor="let staff of listStaff">{{ staff.userProfile.real_name }}</option>
          </select>
        </form>
      </div>
            
      
      <button class="btn btn-blue-grey" (click)="getLogActivity()">
        <img src="./assets/images/icon-search.png" alt="Search" /> Lihat Data
      </button>

      <button class="btn btn-success" (click)="resetFilter()">
        Reset Filter
      </button>
      
    </div>
    
    <div class="table-wrapper">
      <div class="table-options">
        <div class="show-table">
          Show
          <select
            class="form-control"
            name="show"
            id="show"
            (change)="handlePageSizeChange($event)"
          >
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }}
            </option>
          </select>
          entries
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th>No.</th>
              <th>Log Level</th>
              <th>Module</th>
              <th>User</th>
              <th>Message</th>
              <th>IP</th>
              <th>Created On</th>              
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let log of listLogActivity
                  | paginate
                    : {
                        id: pageid,
                        itemsPerPage: pageSize,
                        currentPage: page,
                        totalItems: count
                      };
                let i = index
              "
            >
              <td>{{ i + 1 + (page - 1) * pageSize }}</td>              
              <td>{{ log.log_level }}</td>
              <td>{{ log.module }}</td>              
              <td>{{ log.User.userProfile.real_name }}</td>
              <td>{{ log.message }}</td>
              <td>{{ log.ip_address }}</td>              
              <td>{{ log.createdAt | date:'dd-MM-yyyy H:MM:s' }}</td>
            </tr>
            <tr *ngIf="listLogActivity.length === 0" class="empty">
              <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
        <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
          <div></div>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center pagination-number">
              <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                <a
                  class="page-link"
                  (click)="p.setCurrent(page.value)"
                  *ngIf="p.getCurrent() !== page.value"
                >
                  {{ page.label }}
                </a>
                <a class="page-link" *ngIf="p.getCurrent() === page.value">
                  {{ page.label }}
                </a>
              </li>
            </ul>
          </nav>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="p.isFirstPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isFirstPage()"
                  (click)="p.previous()"
                  aria-label="Previous"
                >
                  <span>Previous</span>
                </a>
              </li>
              <li class="page-item" [class.disabled]="p.isLastPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isLastPage()"
                  (click)="p.next()"
                  aria-label="Next"
                >
                  <span>Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </pagination-template>

      <ng-template #emptyPagination>
        <div class="pagination-wrapper">
          <div>&nbsp;</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>