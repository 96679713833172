import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DokumenService } from 'src/app/services/dokumen.service';
import { FileService } from 'src/app/services/file.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';


@Component({
  selector: 'app-dokumen',
  templateUrl: './dokumen.component.html',
  styleUrls: ['./dokumen.component.css']
})
export class DokumenComponent implements OnInit {

  listDokumen: any = [];
  errorMessage = '';
  submitted = false;
  pageid = 'dokumen';
  searchTerm = '';
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  closeResult: string = '';
  isNew = true;
  isShowLoaderGuru: Boolean = false;
  idDokumen = "";
  oldPath = "";
  urlPath = "";
  dokFIle: any = null;

  formUnggahDokumen: FormGroup = new FormGroup({
    id: new FormControl(''),
    path: new FormControl(''),
    deskripsi: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private dokumenService: DokumenService,
    private fileService: FileService,
  ) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.initForm();
    this.getAllDOkumen();
    this.urlPath = this.fileService.getImageUrl("dokumen/");
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add('siswa-baru-list');
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    console.log('handle PAGE', this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  search(): void {
    this.page = 1;
  }

  initForm() {
    this.formUnggahDokumen = this.formBuilder.group(
      {
        id: [''],
        path: [''],
        deskripsi: ['', Validators.required],
      },
    );
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formUnggahDokumen.controls;
  }

  onSubmitformUnggahDokumen(modal: any): void {
    console.log(JSON.stringify(this.formUnggahDokumen.value, null, 2));
    
    this.submitted = true;
    
    if (this.formUnggahDokumen.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }
    
    modal.dismiss('Cross click');
    
    if (this.dokFIle?.length && this.dokFIle !== null) {
      this.uiService.showLoader();
      console.log("MULAI UPLOAD", this.dokFIle);
      
      this.fileService.upload(this.dokFIle[0], 'dokumen').subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
              // progress
          } else if (event instanceof HttpResponse) {
            this.uiService.hideLoader();
            this.uiService.showSuccess(event.body.message);
            // this.currentSaveFileName = event.body.filename;
            console.log("FNAME", event.body.filename);
            console.log("INFO", event.body);
            
            const fileSrc = this.fileService.getImageUrl("dokumen/" + event.body.filename);
            console.log("file src", fileSrc);
            
            // this.savePhotoStaff(event.body.filename);
            this.formUnggahDokumen.controls['path'].setValue(event.body.filename);
            
            if (this.isNew) {
              this.dokumenService.add(this.formUnggahDokumen.value).subscribe({
                next: resdata => {
                  console.log(resdata);
          
                  this.resetForm();
        
                  this.uiService.hideLoader();
                  this.uiService.showSuccess('');
                
                  //this.router.navigate(['/ppsb/list']);
                  this.getAllDOkumen();
          
                },
                error: err => {
                  console.log(err);
        
                  this.resetForm();
                  
                  this.errorMessage = err.error.message;
                  console.log("MESSAGE ERRt", this.errorMessage);
                  
                  this.uiService.hideLoader();
                  
                  this.uiService.showError(this.errorMessage);
                }
              });    
            } else {
              this.removeDokumenFile(this.oldPath);
              this.updateDokumen();
            }
          }
        },
        error: (err: any) => {
          console.log(err);
          this.uiService.hideLoader();
  
          if (err.error && err.error.message) {
            this.uiService.showError(err.error.message);
          } else {
            this.uiService.showError('Could not upload the file!');
          }
        }
      });
    } else {
      if (this.isNew) {
        this.uiService.showError('Pilih file');
        return;
      } else {
        this.updateDokumen();
      }
    }
  }

  private removeDokumenFile(image: string) {
    this.fileService.removeDokumenFile(image).subscribe({
      next: (event: any) => {
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  updateDokumen() {
    this.dokumenService.update(this.idDokumen, this.formUnggahDokumen.value).subscribe({
      next: resdata => {
        console.log(resdata);

        this.resetForm();
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('');
      
        //this.router.navigate(['/ppsb/list']);
        this.getAllDOkumen();

      },
      error: err => {
        console.log(err);
        // this.formUnggahDokumen.reset();
        this.submitted = false;
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllDOkumen() {
    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);
    
    this.dokumenService.getAlldokumen(params).subscribe({
      next: resdata => {
        console.log("DATA DOKUMEN", resdata);
        // const { data, totalrows } = resdata;
        // this.count = totalrows;
        this.listDokumen = resdata;

        this.uiService.hideLoader();
      },
      error: err => {
        console.log(err);
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    }); 
  }

  confirmDelete(data: any) {
    this.oldPath = data.path;
    this.uiService.showConfirmDelete(this, data.id);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.dokumenService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.removeDokumenFile($SELF.oldPath);
          $SELF.getAllDOkumen();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  resetForm() {
    this.formUnggahDokumen.reset();
    this.submitted = false;
    this.initForm();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  openModalformUnggahDokumen(content:any) {
    this.isNew = true;
    this.submitted = false;
    this.formUnggahDokumen.reset();
    
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openEditModalformUnggahDokumen(content:any, dokumen: any) {
    console.log("WALI", dokumen);
    
    this.isNew = false;
    this.submitted = false;
    this.formUnggahDokumen.reset();
    this.idDokumen = dokumen.id;
    this.oldPath = dokumen.path;
    this.formUnggahDokumen.controls['id'].setValue(dokumen.id);
    this.formUnggahDokumen.controls['deskripsi'].setValue(dokumen.deskripsi);

    
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  uploadDokumen(e: Event): void {
    this.dokFIle = (e.target as HTMLInputElement).files;
    
  }

  highlightText(e: any) {
    e.target.select();
  }

}
