<div class="tabs-title">Laporan</div>

<div class="col-12 col-lg-12">
  <div class="card">
      
      <div class="card-body">
          <form [formGroup]="formLaporanAbsensi">
              <div class="row">
                  <div class="col-12 col-lg-12">
                    <div class="mb-3">
                      <div class="row align-items-center">
                          <div class="col-2 text-end">
                              <label for="name" class="form-label">Jenis Laporan <span
                                      class="required">*</span></label>
                          </div>
                          <div class="col-10">
                            <select
                                formControlName="jenis_laporan"
                                [ngClass]="{ 'is-invalid': submitted && f['jenis_laporan'].errors }"
                                class="form-select"
                                (change)="changeJenisLaporan($event)">
                                <option [value]="jenis" *ngFor="let jenis of listJenisLaporan">{{jenis}}</option>
                            </select>
                          </div>
                      </div>
                    </div>
                    <div class="mb-3" *ngIf="!isLaporanPerKelas">
                      <div class="row align-items-center">
                          <div class="col-2 text-end">
                              <label for="name" class="form-label">Kategori <span
                                      class="required">*</span></label>
                          </div>
                          <div class="col-10">
                            <select
                                formControlName="kategori"
                                [ngClass]="{ 'is-invalid': submitted && f['kategori'].errors }"
                                class="form-select"
                                (change)="changeJenisLaporan($event)">
                                <option [value]="data.key" *ngFor="let data of listKategori">{{data.value}}</option>
                            </select>
                          </div>
                      </div>
                    </div>
                    <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="name" class="form-label">Tanggal Awal</label>
                            </div>
                            <div class="col-10">
                              <input
                                type="text"
                                autocomplete="off"
                                id="start_date"
                                (blur)="changeStartDateField($event)"                                  
                                formControlName="start_date"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f['start_date'].errors }"
                                placeholder=""
                              />
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                      <div class="row align-items-center">
                          <div class="col-2 text-end">
                              <label for="name" class="form-label">Tanggal Akhir</label>
                          </div>
                          <div class="col-10">
                            <input
                              type="text"
                              autocomplete="off"
                              id="end_date"
                              (blur)="changeEndDateField($event)"                                  
                              formControlName="end_date"
                              class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f['end_date'].errors }"
                              placeholder=""
                            />
                          </div>
                      </div>
                    </div>
                    <div class="mb-3">
                        <div class="row align-items-center">
                            <div class="col-2 text-end">
                                <label for="nik" class="form-label">Tahun Ajaran <span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-10">                                
                                <select formControlName="id_tahun_ajaran" (change)="changeTahunAjaran($event)" class="form-select" name="id_tahun_ajaran" id="id_tahun_ajaran">
                                  <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                      <div class="row align-items-center">
                          <div class="col-2 text-end">
                              <label for="name" class="form-label">Semester <span
                                      class="required">*</span></label>
                          </div>
                          <div class="col-10">
                            <select
                                formControlName="id_semester"
                                [ngClass]="{ 'is-invalid': submitted && f['id_semester'].errors }"
                                class="form-select"
                                (change)="changeSemester($event)">
                                <option value="" selected disabled>Pilih Semester</option>
                                <option [value]="data.kode" *ngFor="let data of listSemester">{{data.name}}</option>
                            </select>
                          </div>
                      </div>
                    </div>
                    <div class="mb-3" *ngIf="isLaporanPerKelas">
                      <div class="row align-items-center">
                          <div class="col-2 text-end">
                              <label for="name" class="form-label">Kelas <span
                                      class="required">*</span></label>
                          </div>
                          <div class="col-10">
                            <select
                                formControlName="kelas_id"
                                [ngClass]="{ 'is-invalid': submitted && f['kelas_id'].errors }"
                                class="form-select"
                                (change)="changeKelas($event)">
                                <option value="" selected>Pilih</option>
                                <option [value]="data.id" *ngFor="let data of listKelas">{{data.nama_kelas}}</option>
                            </select>
                          </div>
                      </div>
                    </div>                      
                  </div>
                  
              </div>
          </form>
      </div>
  </div>
</div>

<div class="list-options mt-4">
  <button class="btn btn-blue-grey" (click)="onSubmit()">
    + Tampil
  </button>
  <button type="button" class="btn btn-danger">
    <img src="./assets/images/icon-pdf.png" alt="PDF"> Print PDF
  </button>
  <!-- <div class="search">
    <input
      type="text"
      class="form-control"
      placeholder="Nama Siswa.."
      name="search-list"
      id="search-list"
      [(ngModel)]="searchTerm"
    />
    <div class="icon" (click)="search()">
      <img src="./assets/images/icon-search.png" alt="Search" />
    </div>
  </div> -->
</div>

<div class="table-wrapper">
  <div class="table-options">
    <!-- <div class="show-table">
      Show
      <select
        class="form-control"
        name="show"
        id="show"
        (change)="handlePageSizeChange($event)"
      >
        <option *ngFor="let size of pageSizes" [ngValue]="size">
          {{ size }}
        </option>
      </select>
      entries
    </div> -->
  </div>
  <div class="table-responsive" *ngIf="isLaporanPerKelas">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th rowspan="2" class="align-middle">No.</th>
          <th rowspan="2" class="align-middle">No.Induk</th>
          <th rowspan="2" class="align-middle">Nama</th>
          <th rowspan="2" class="align-middle">Status</th>
          <th colspan="7">Absen
          </th>
          <th rowspan="2" class="align-middle">Jumlah</th>
        </tr>
        <tr>
          <td>H</td>
          <td>A</td>
          <td>S</td>
          <td>IP</td>
          <td>IT</td>
          <td>%H</td>
          <td>Status H</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let siswa of listLaporanPerKelas | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
          <td>{{ i + 1 + (page - 1) * pageSize }}</td>
          <td>{{ siswa.no_induk }}</td>
          <td>{{ siswa.nama_lengkap }}</td>
          <td>{{ siswa.status }}</td>
          <td>{{ siswa.h }}</td>
          <td>{{ siswa.a }}</td>
          <td>{{ siswa.s }}</td>
          <td>{{ siswa.ip }}</td>
          <td>{{ siswa.it }}</td>
          <td>{{ siswa.persentase }}</td>
          <td></td>
          <td>{{ siswa.total }}</td>
        </tr>
        <tr *ngIf="listLaporanPerKelas.length === 0" class="empty">
          <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="table-responsive" *ngIf="!isLaporanPerKelas">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th rowspan="2" class="align-middle">No.</th>
          <th rowspan="2" class="align-middle">No.Induk</th>
          <th rowspan="2" class="align-middle">Kelas</th>
          <th rowspan="2" class="align-middle">Nama</th>
          <th rowspan="2" class="align-middle">Status</th>
          <th rowspan="2" class="align-middle">Jumlah</th>
          <th rowspan="2" class="align-middle">Tanggal &amp; Jam Pelajaran</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let siswa of listLaporanPerKategori | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
          <td>{{ i + 1 + (page - 1) * pageSize }}</td>
          <td>{{ siswa.no_induk }}</td>
          <td>{{ siswa.nama_kelas }}</td>
          <td>{{ siswa.nama_lengkap }}</td>
          <td>{{ siswa.status }}</td>
          <td>{{ siswa.total }}</td>
          <td>{{ siswa.tanggalJamke }}</td>
          <td></td>
        </tr>
        <tr *ngIf="listLaporanPerKategori.length === 0" class="empty">
          <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
        </tr>
      </tbody>
    </table>
  </div>

  <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
    <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
      <div></div>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center pagination-number">
          <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
            <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
              {{ page.label }}
            </a>
            <a class="page-link" *ngIf="p.getCurrent() === page.value">
              {{ page.label }}
            </a>
          </li>
        </ul>
      </nav>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="p.isFirstPage()">
            <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
              <span>Previous</span>
            </a>
          </li>
          <li class="page-item" [class.disabled]="p.isLastPage()">
            <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
              <span>Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </pagination-template>

  <ng-template #emptyPagination>
    <div class="pagination-wrapper">
      <div>&nbsp;</div>
    </div>
  </ng-template>
</div>
