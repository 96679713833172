import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brt-alat',
  templateUrl: './brt-alat.component.html',
  styleUrls: ['./brt-alat.component.css']
})
export class BrtAlatComponent implements OnInit {

  listBrtAlat: any = [];
  errorMessage = '';

  pageid = "proguser";

  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;

  constructor() {
    this.changeBodyClass();
  }

  ngOnInit(): void {
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  search(): void {
    this.page = 1;
  }

}
