import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayPresentase'
})
export class DisplayPresentasePipe implements PipeTransform {

  transform(total: number, jumlah: number): string {
    
    const prosentase = total !== 0 ? (jumlah/total) * 100 : 0;

    return prosentase.toString();
  }

}
