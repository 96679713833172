<app-nav-tab></app-nav-tab>

<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="statistik"
    role="tabpanel"
    aria-labelledby="statistik-tab"
  >
    <div class="tabs-title">List Pembayaran Formulir</div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row list-options g-3 align-items-center">
        <!-- <div class="col-auto">
          <label for="formdate" class="col-form-label">Tanggal</label>
        </div> -->
        <div class="col-auto">
          <input
            type="text"
            autocomplete="off"
            id="start_date"
            (blur)="changeStartDateField($event)"                                  
            formControlName="start_date"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f['start_date'].errors }"
            placeholder=""
          />
        </div>
        <div class="col-auto">
          <label for="todate" class="col-form-label"> - </label>
        </div>
        <div class="col-auto">
          <input
            type="text"
            autocomplete="off"
            id="end_date"
            (blur)="changeEndDateField($event)"                                  
            formControlName="end_date"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f['end_date'].errors }"
            placeholder=""
          />
        </div>
        <div class="col-auto">
          <button class="btn btn-blue-grey" (click)="showPembayaranFormulir()">
            Show
          </button>
        </div>
        <div class="col-auto">
          <!-- <button class="btn btn-blue-grey">
            Excel
          </button> -->
          <button type="button" class="btn btn-success" (click)="exportToExcel()">
            <img src="./assets/images/icon-excel.png" alt="Excel"> Excel
          </button>
        </div>
        <!-- <div class="col-auto">
          <label for="lembaga" class="col-form-label">Lembaga</label>
        </div> -->
        <div class="col-auto">
          <select formControlName="id_lembaga" (change)="changeLembaga($event)" class="form-select" name="id_lembaga" id="id_lembaga">
            <option value="" selected disabled>Pilih Lembaga</option>
            <option [value]="data.id" *ngFor="let data of listsLembaga">{{data.nama_lembaga}}</option>
          </select>
        </div>
  
        <!-- <div class="col-auto">
          <label for="tingkatan" class="col-form-label">Tingkatan</label>
        </div> -->
        <div class="col-auto">
          <select (change)="changeTingkat($event)" class="form-select" formControlName="id_tingkat" id="id_tingkat">
            <option value="" selected disabled>Pilih Tingkat</option>
            <option [value]="data.id" *ngFor="let data of listsTingkat">{{data.nama_tingkat}}</option>
          </select>
        </div>
        <div class="col-auto search-ops">
          <input
            type="text"
            class="form-control"
            placeholder="Search.."
            name="search-list"
            id="search-list"
            formControlName="searchTerm"
          />
          <div class="icon" (click)="search()">
            <img src="./assets/images/icon-search.png" alt="Search" />
          </div>
        </div>
        <div class="col-auto">
        </div>  
      </div>
    </form>


    <div class="table-wrapper">
      <div class="table-options">
        <div class="show-table">
          Show
          <select
            class="form-control"
            name="show"
            id="show"
            (change)="handlePageSizeChange($event)"
          >
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }}
            </option>
          </select>
          entries
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th>No.</th>
              <th>Tanggal Transfer</th>
              <th>Nama</th>
              <th>Lembaga</th>
              <th>Tingkatan</th>
              <th>Nominal</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let siswa of listPembayaran | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
              <td>{{ i + 1 + (page - 1) * pageSize }}</td>
              <td>{{ siswa.waktu_transaksi | date: 'EEEE, d MMMM y' }}</td>
              <td>{{ siswa.pembayaranTagihan.siswaTagihan.nama_lengkap }}</td>
              <td>{{ siswa.pembayaranTagihan.siswaTagihan.lembaga.nama_lembaga }}</td>
              <td>{{ siswa.pembayaranTagihan.siswaTagihan.tingkat.nama_tingkat }}</td>
              <td>{{ siswa.jumlah }}</td>
            </tr>
            <tr *ngIf="listPembayaran.length === 0" class="empty">
              <td colspan="11">
                Tidak terdapat data atau data tidak ditemukan.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
        <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
          <div></div>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center pagination-number">
              <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                <a
                  class="page-link"
                  (click)="p.setCurrent(page.value)"
                  *ngIf="p.getCurrent() !== page.value"
                >
                  {{ page.label }}
                </a>
                <a class="page-link" *ngIf="p.getCurrent() === page.value">
                  {{ page.label }}
                </a>
              </li>
            </ul>
          </nav>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="p.isFirstPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isFirstPage()"
                  (click)="p.previous()"
                  aria-label="Previous"
                >
                  <span>Previous</span>
                </a>
              </li>
              <li class="page-item" [class.disabled]="p.isLastPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isLastPage()"
                  (click)="p.next()"
                  aria-label="Next"
                >
                  <span>Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </pagination-template>

      <ng-template #emptyPagination>
        <div class="pagination-wrapper">
          <div>&nbsp;</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
