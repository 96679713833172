import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-siswa-detail-biodata',
  templateUrl: './siswa-detail-biodata.component.html',
  styleUrls: ['./siswa-detail-biodata.component.css']
})
export class SiswaDetailBiodataComponent implements OnInit {

  @Input() selectedData: any = null;

  constructor() { }

  ngOnInit(): void {
  }

  convertGenderStatus(status: String): String {
    if (!status) return "";
    if (status === "L") return "Laki-Laki";
    if (status === "P") return "Perempuan";
    return "unknown";
  }

}
