<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="guru-input"
    role="tabpanel"
    aria-labelledby="guru-input-tab"
  >
    <div class="tabs-title">Upload Jadwal Mengajar</div>

    
    <div class="col-12 col-lg-12">
      <div class="card">
          
          <div class="card-body">
              <form [formGroup]="formUploadJadwal" (ngSubmit)="onSubmitformUploadJadwal()">
                  <div class="row">
                      <div class="col-12 col-lg-12">
                          <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="nik" class="form-label">Tahun Ajaran <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-10">                            
                                    <select formControlName="id_tahun_ajaran" [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }" class="form-select">
                                      <option [value]="" selected disabled>Pilih TA</option>
                                      <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                                    </select>
                                  </div>
                              </div>
                          </div>                  
                          <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="name" class="form-label">Semester <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-10">
                                    <select formControlName="id_semester" [ngClass]="{ 'is-invalid': submitted && f['id_semester'].errors }" class="form-select">
                                      <option [value]="" selected disabled>Pilih Semester</option>
                                      <option [value]="data.kode" *ngFor="let data of listSemester">{{data.name}}</option>
                                    </select>
                                  </div>
                              </div>
                          </div>                                    
                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">Upload</label>
                                </div>
                                <div class="col-10">
                                  <input #excelUpload type="file" (click)="excelUpload.value=''" (change)="onFileChange($event)" multiple="false" />
                                </div>
                            </div>
                          </div>

                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">&nbsp;</label>
                                </div>
                                <div class="col-10">
                                  <button class="btn btn-blue-grey mt-2">
                                    Import
                                  </button>
                                </div>
                            </div>
                          </div>
                          
                      </div>
                      
                  </div>
              </form>
          </div>
      </div>
    </div>

  </div>
</div>