<div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="guru-input"
      role="tabpanel"
      aria-labelledby="guru-input-tab"
    >
      <div class="tabs-title">Data Absen Kamar</div>
  
      
      <div class="table-wrapper">
        <div class="table-options">
          <!-- <div class="show-table">
            Show
            <select
              class="form-control"
              name="show"
              id="show"
              (change)="handlePageSizeChange($event)"
            >
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
            entries
          </div> -->
        </div>
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th rowspan="2" class="align-middle">No.</th>
                <th rowspan="2" class="align-middle">Asrama</th>
                <th rowspan="2" class="align-middle">Rayon</th>
                <th rowspan="2" class="align-middle">Kamar</th>
                <th rowspan="2" class="align-middle">Tanggal</th>
                <th rowspan="2" class="align-middle">Jam</th>
                <th rowspan="2" class="align-middle">Pengabsen</th>
                <th colspan="5">Absen</th>
                <th rowspan="2" class="align-middle">Jumlah</th>
              </tr>
              <tr class="below">
                <td>H</td>
                <td>A</td>
                <td>S</td>
                <td>IP</td>
                <td>IT</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let siswa of listInput | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
                <td>{{ i + 1 + (page - 1) * pageSize }}</td>
                <td>{{ siswa.nama_gedung }}</td>
                <td>{{ siswa.nama_rayon }}</td>
                <td>{{ siswa.nama_kamar }}</td>
                <td>{{ siswa.tanggal | date:'dd-MM-yyyy' }}</td>
                <td>{{ siswa.createdat | date:'HH:mm' }}</td>
                <td>{{ siswa.real_name }}</td>                
                <td (click)="showDetailAbsensiHarianSiswa(siswa,'H')">{{ siswa.total_h }}</td>
                <td (click)="showDetailAbsensiHarianSiswa(siswa,'A')">{{ siswa.total_a }}</td>
                <td (click)="showDetailAbsensiHarianSiswa(siswa,'S')">{{ siswa.total_s }}</td>
                <td (click)="showDetailAbsensiHarianSiswa(siswa,'IP')">{{ siswa.total_ip }}</td>
                <td (click)="showDetailAbsensiHarianSiswa(siswa,'IT')">{{ siswa.total_it }}</td>
                <td (click)="showDetailAbsensiHarianSiswa(siswa,'')">{{ siswa.total_absen }}</td>                
              </tr>
              <tr *ngIf="listInput.length === 0" class="empty">
                <td colspan="14">Tidak terdapat data atau data tidak ditemukan.</td>
              </tr>
            </tbody>
          </table>
        </div>
      
        <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
          <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
            <div></div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center pagination-number">
                <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                  <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                    {{ page.label }}
                  </a>
                  <a class="page-link" *ngIf="p.getCurrent() === page.value">
                    {{ page.label }}
                  </a>
                </li>
              </ul>
            </nav>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="p.isFirstPage()">
                  <a class="page-link" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                    <span>Previous</span>
                  </a>
                </li>
                <li class="page-item" [class.disabled]="p.isLastPage()">
                  <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                    <span>Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </pagination-template>
      
        <ng-template #emptyPagination>
          <div class="pagination-wrapper">
            <div>&nbsp;</div>
          </div>
        </ng-template>
      </div>
    </div>
 </div>