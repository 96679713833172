import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class BiayakhususService {

  constructor(private netService: NetworkService) { }

  getAll(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/biayakhusus', params);
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/biayakhusus');
  }

  addItemBiaya(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/biayakhususAddItemBiaya');
  }  

  get(id: string): Observable<any> {
    return this.netService.getRequest(`/v1/biayakhusus/${id}`);
  }

  getListItemBiaya(id: string): Observable<any> {
    return this.netService.getRequest(`/v1/biayakhususGetListItemBiaya/${id}`);
  }

  getBySiswa(id: string): Observable<any> {
    return this.netService.getRequest(`/v1/biayakhususGetBySiswa/${id}`);
  }  

  getByLembagaAndPeriode(id: any, idPeriode: any): Observable<any> {
    return this.netService.getRequest(`/v1/biayakhususbylembagaandperiode/${id}/${idPeriode}`);
  }

  update(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/biayakhusus/${id}`);
  }

  setActive(id: any, payload: any): Observable<any> {
    return this.netService.putRequest(payload, `/v1/biayakhususSetActive/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/biayakhusus/${id}`);
  }

  deleteItemBiaya(id: string): Observable<any> {
    return this.netService.deleteRequest(`/v1/biayakhususItemBiaya/${id}`);
  }
}
