<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="statistik"
    role="tabpanel"
    aria-labelledby="statistik-tab"
  >
    <div class="tabs-title">Jadwal Guru</div>

    
    <div class="list-options">
      <div class="form-select-warapper">
        <form [formGroup]="formAction">
          <select (change)="changeTahunAjaran($event)" formControlName="id_tahun_ajaran" [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }" class="form-select">
            <option [value]="" selected disabled>Pilih TA</option>
            <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
          </select>
        </form>
      </div>

      <div class="form-select-warapper">
        <form [formGroup]="formAction">
          <select (change)="changeSemester($event)" formControlName="id_semester" [ngClass]="{ 'is-invalid': submitted && f['id_semester'].errors }" class="form-select">
            <option [value]="" selected disabled>Pilih Semester</option>
            <option [value]="data.kode" *ngFor="let data of listSemester">{{data.name}}</option>
          </select>
        </form>
      </div>

      <div class="search">
        <input
        type="text"
        class="form-control"
        placeholder="Search.."
        name="search-list"
        id="search-list"
        [(ngModel)]="searchTerm"
        />
        <div class="icon" (click)="search()">
          <img src="./assets/images/icon-search.png" alt="Search" />
        </div>
      </div>
      
    </div>

    <div class="table-wrapper">
      <div class="table-options">
        <div class="show-table">
          Show
          <select
            class="form-control"
            name="show"
            id="show"
            (change)="handlePageSizeChange($event)"
          >
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }}
            </option>
          </select>
          entries
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th></th>
              <th>ID Asc TimeTables</th>
              <th>SHORT/KODE GURU</th>
              <th>NAMA GURU</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let jadwal of listJadwalGuru
                  | paginate
                    : {
                        id: pageid,
                        itemsPerPage: pageSize,
                        currentPage: page,
                        totalItems: count
                      };
                let i = index
              "
            >
              <td>{{ i + 1 + (page - 1) * pageSize }}</td>
              <td>{{ jadwal.asc_guru_id }}</td>
              <td>{{ jadwal.kode_guru_nip }}</td>
              <td>{{ jadwal.real_name }}</td>
              <td>
                <div (click)="showDetailJadwalPelajaranKelas(jadwal)" class="tagihan secondary btn">
                  View Jadwal
                </div>
              </td>
            </tr>
            <tr *ngIf="listJadwalGuru.length === 0" class="empty">
              <td colspan="13">Tidak terdapat data atau data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
        <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
          <div></div>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center pagination-number">
              <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                <a
                  class="page-link"
                  (click)="p.setCurrent(page.value)"
                  *ngIf="p.getCurrent() !== page.value"
                >
                  {{ page.label }}
                </a>
                <a class="page-link" *ngIf="p.getCurrent() === page.value">
                  {{ page.label }}
                </a>
              </li>
            </ul>
          </nav>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="p.isFirstPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isFirstPage()"
                  (click)="p.previous()"
                  aria-label="Previous"
                >
                  <span>Previous</span>
                </a>
              </li>
              <li class="page-item" [class.disabled]="p.isLastPage()">
                <a
                  class="page-link"
                  *ngIf="!p.isLastPage()"
                  (click)="p.next()"
                  aria-label="Next"
                >
                  <span>Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </pagination-template>

      <ng-template #emptyPagination>
        <div class="pagination-wrapper">
          <div>&nbsp;</div>
        </div>
      </ng-template>
    </div>
    
    
  </div>
</div>