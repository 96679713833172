import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private netService: NetworkService) { }

  getAllProvinsi(): Observable<any> {
    return this.netService.getGeneralRequest('/v1/getProvinsi', "");
  }

  getKabupatenByProvinsi(param: any): Observable<any> {
    return this.netService.getGeneralRequest('/v1/kotabyprovinsi', param);
  }

  getKecamatanByKabupatenProvinsi(param: any): Observable<any> {
    return this.netService.getGeneralRequest('/v1/kecamatanbykota', param);
  }

  getKelurahanByKecamatanKabupatenProvinsi(param: any): Observable<any> {
    return this.netService.getGeneralRequest('/v1/kelurahanbykecamatan', param);
  }

  getCurrentInstansi(payload: any): Observable<any> {
    return this.netService.postGeneralRequest(payload, '/v1/getInstansiByKode');
  }

  changeSiswaBaruPassword(payload: any): Observable<any> {
    return this.netService.putGeneralRequest(payload, '/v1/registrant/changePassword');
  }
  
}
