import { HttpEventType, HttpParams, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from 'src/app/helper/ui.service';
import { GeneralService } from 'src/app/services/general.service';
import { SdmService } from 'src/app/services/sdm.service';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-sdm-add',
  templateUrl: './sdm-add.component.html',
  styleUrls: ['./sdm-add.component.css']
})
export class SdmAddComponent implements OnInit {

  listsProvinsi: any = [];
  listsKabupaten: any = [];
  listsKecamatan: any = [];
  listsKelurahan: any = [];
  listsRoles: any = [];
  errorMessage = '';
  isAddMode: Boolean = false;
  id: string = '';
  sdmEditData: any;
  imgSrc: String = "";
  imgSignSrc: String = "";
  prevPhoto: string = "";
  prevSign: string = "";

  form: FormGroup = new FormGroup({
    nik: new FormControl(''),
    gelar_akademik: new FormControl(''),
    real_name: new FormControl(''),
    name_ar: new FormControl(''),
    pob: new FormControl(''),
    dob: new FormControl(''),
    gender: new FormControl(''),
    marital_status: new FormControl(''),
    no_hp1: new FormControl(''),
    no_hp2: new FormControl(''),
    golongan_darah: new FormControl(''),
    ayah: new FormControl(''),
    ibu: new FormControl(''),
    alamat: new FormControl(''),
    rt: new FormControl(''),
    rw: new FormControl(''),
    provinsi: new FormControl(''),
    kota_kab: new FormControl(''),
    kecamatan: new FormControl(''),
    desa_kelurahan: new FormControl(''),
    kode_guru_nip: new FormControl(''),
    nuptk: new FormControl(''),
    email: new FormControl(''),
    username: new FormControl(''),
    roleId: new FormControl(''),
    aktifitas_luar_pondok: new FormControl(''),
    rekomendasi: new FormControl(''),
    status: new FormControl(''),
    tahun_bertugas: new FormControl(''),
    tanggal_masuk: new FormControl(''),
    photo: new FormControl(''),
    sign: new FormControl(''),

  });
  submitted = false;

  constructor(private formBuilder: FormBuilder, 
    private uiService: UiService, 
    private sdmService: SdmService,
    private router: Router,
    private route: ActivatedRoute,
    private fileService: FileService,
    private generalService: GeneralService) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['proguserId'];
    this.isAddMode = !this.id;

    this.initForm();

    if (!this.isAddMode) {
      this.getSdmEdit();
    } else {
      this.getProvinsi();
    }
    this.getRoles();
  }

  getSdmEdit() {
    this.uiService.showLoader();
    console.log("SDM ID", this.id);
    

    this.sdmService.getById(this.id).subscribe({
      next: resdata => {
        console.log('SDM EDIT', resdata);
        this.sdmEditData = resdata;
        
        this.form.controls['roleId'].setValue(resdata.role_id);

        this.getProvinsi();

        // this.getTingkatLembaga(lembagaId, tingkatId);
        
        this.form.patchValue(resdata);
        this.form.patchValue(resdata.userProfile);
        this.imgSrc = resdata.userProfile.photo != null ? this.fileService.getImageUrl("staff/" + resdata.userProfile.photo) : "";
        this.imgSignSrc = resdata.userProfile.sign != null ? this.fileService.getImageUrl("staff/" + resdata.userProfile.sign) : "";
        
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getProvinsi() {
    this.uiService.showLoader();

    this.generalService.getAllProvinsi().subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsProvinsi = resdata;
        
        if (!this.isAddMode) {
          console.log("this.sdmEditData.provinsi", this.sdmEditData.userProfile.provinsi);
          
          const provinsi = this.sdmEditData.userProfile.provinsi;
          const kabupaten = this.sdmEditData.userProfile.kota_kab;
          
          this.form.controls['provinsi'].setValue(provinsi);
          this.getKabupatenProvinsi(provinsi, kabupaten);
        }

        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getRoles() {
    this.uiService.showLoader();

    this.sdmService.getRoles('').subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsRoles = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeKabupaten(e: any) {
    console.log(e.target.value);
    this.getKabupatenProvinsi(e.target.value);
  }
  
  private getKabupatenProvinsi(provinsi: any, curentKabupaten: any = null) {
    let params = new HttpParams();
    params = params.append("provinsi", provinsi);
    
    this.uiService.showLoader();
    
    this.generalService.getKabupatenByProvinsi(params).subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsKecamatan = [];
        this.listsKelurahan = [];
        this.listsKabupaten = resdata;
        console.log('value', this.form.value.kota_kab);
        if (curentKabupaten !== null) {
          this.form.controls['kota_kab'].setValue(curentKabupaten);
          this.getKecamatanKabupatenProvonsi(provinsi, curentKabupaten, this.sdmEditData.userProfile.kecamatan);
        } else {
          this.form.controls['kota_kab'].setValue('');
        }
        this.form.controls['kecamatan'].setValue('');
        this.form.controls['desa_kelurahan'].setValue('');
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeKecamatan(e: any) {
    console.log(e.target.value);
    const provinsi = this.form.value.provinsi;
    const kabupaten = e.target.value;

    this.getKecamatanKabupatenProvonsi(provinsi, kabupaten);
  }
  
  private getKecamatanKabupatenProvonsi(provinsi: any, kabupaten: any, kecamatan: any = null) {
    this.uiService.showLoader();
  
    let params = new HttpParams();
    
    params = params.append("provinsi", provinsi);
    params = params.append("kabupaten", kabupaten);
  
    this.generalService.getKecamatanByKabupatenProvinsi(params).subscribe({
      next: resdata => {
        console.log('kecamatan', resdata);
        this.listsKelurahan = [];
        if (kecamatan !== null) {
          this.form.controls['kecamatan'].setValue(kecamatan);
          console.log("this.sdmEditData.userProfile.desa_kelurahan", this.sdmEditData.userProfile.desa_kelurahan);
          
          this.getKelurahanKecamatanKabupatenProvinsi(provinsi, kabupaten, kecamatan, this.sdmEditData.userProfile.desa_kelurahan);
        } else {
          this.form.controls['kecamatan'].setValue('');
        }
        this.form.controls['desa_kelurahan'].setValue('');
        this.listsKecamatan = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });

  }

  changeKelurahan(e: any) {
    console.log(e.target.value);
    const provinsi = this.form.value.provinsi;
    const kabupaten = this.form.value.kota_kab;
    const kecamatan = e.target.value;

    this.getKelurahanKecamatanKabupatenProvinsi(provinsi,kabupaten,kecamatan);
  }
  
  private getKelurahanKecamatanKabupatenProvinsi(provinsi: any, kabupaten: any, kecamatan: any, desa_kelurahan: any = null) {
    this.uiService.showLoader();
  
    let params = new HttpParams();
  
    params = params.append("provinsi", provinsi);
    params = params.append("kabupaten", kabupaten);
    params = params.append("kecamatan", kecamatan);
  
    this.generalService.getKelurahanByKecamatanKabupatenProvinsi(params).subscribe({
      next: resdata => {
        this.listsKelurahan = resdata;
        console.log('kelurahan', resdata);
        console.log("KELURAHAN", desa_kelurahan);
        
        if (desa_kelurahan !== null) {
          this.form.controls['desa_kelurahan'].setValue(desa_kelurahan);
        } else {
          this.form.controls['desa_kelurahan'].setValue('');
        }
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }
  
  initForm() {
    this.form = this.formBuilder.group(
      {
        nik: ['', Validators.required],
        gelar_akademik: ['', Validators.required],
        real_name: ['', Validators.required],
        name_ar: [''],
        pob: ['', Validators.required],
        dob: ['', Validators.required],
        gender: ['', Validators.required],
        marital_status: ['', Validators.required],
        no_hp1: ['', Validators.required],
        no_hp2: [''],
        golongan_darah: [''],
        ayah: [''],
        ibu: [''],
        alamat: ['', Validators.required],
        rt: ['', Validators.required],
        rw: ['', Validators.required],
        provinsi: ['', Validators.required],
        kota_kab: ['', Validators.required],
        kecamatan: ['', Validators.required],
        desa_kelurahan: ['', Validators.required],
        kode_guru_nip: [''],
        nuptk: [''],
        email: ['', [Validators.required, Validators.email]],
        username: ['', Validators.required],
        roleId: ['', Validators.required],
        aktifitas_luar_pondok: ['', Validators.required],
        rekomendasi: ['', Validators.required],
        status: [''],
        tahun_bertugas: [''],
        tanggal_masuk: [''],
        photo: [''],
        sign: [''],
      },
    );
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru");
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    
    if (this.form.invalid) {
      return;
    }

    this.uiService.showLoader();

    if (this.isAddMode) {
      this.sdmService.addStaff(this.form.value).subscribe({
        next: resdata => {
          console.log(resdata);
          
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          this.router.navigate(['sdm']);
  
        },
        error: err => {
          console.log(err);
          
          this.errorMessage = err.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    } else {
      this.sdmService.update(this.sdmEditData.id, this.form.value).subscribe({
        next: resdata => {
          console.log(resdata);
          
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          this.router.navigate(['sdm']);
  
        },
        error: err => {
          console.log(err);
          
          this.errorMessage = err.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }

    console.log(JSON.stringify(this.form.value, null, 2));
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

  onBack() {
    this.router.navigate(['/sdm/list']);
  }

  uploadPhoto(e: Event): void {
    const file = (e.target as HTMLInputElement).files;
    if (file?.length && file !== null) {
      this.uiService.showLoader();
      this.fileService.upload(file[0], 'staff').subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            // progress
          } else if (event instanceof HttpResponse) {
            this.uiService.hideLoader();
            this.uiService.showSuccess(event.body.message);
            // this.currentSaveFileName = event.body.filename;
            console.log("FNAME", event.body.filename);
            console.log("INFO", event.body);
            
            this.imgSrc = this.fileService.getImageUrl("staff/" + event.body.filename);
            console.log("img src", this.imgSrc);
            
            if (this.isAddMode) {
              if (this.prevPhoto != "") {
                this.removePhoto(this.prevPhoto);
              }
              this.form.controls['photo'].setValue(event.body.filename);
              this.prevPhoto = event.body.filename;
            } else {
              this.savePhotoStaff(event.body.filename);
            }
          }
        },
        error: (err: any) => {
          console.log(err);
          this.uiService.hideLoader();
  
          if (err.error && err.error.message) {
            this.uiService.showError(err.error.message);
          } else {
            this.uiService.showError('Could not upload the file!');
          }
        }
      });
    }
  }

  private removePhoto(image: string) {
    this.fileService.removePhotoStaff(image).subscribe({
      next: (event: any) => {
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  savePhotoStaff(photo: any) {
    // delete previose file

    if (!this.isAddMode && this.sdmEditData.userProfile.photo != null) {
      console.log("DELETE ", this.sdmEditData.userProfile.photo);
      this.removePhoto(this.sdmEditData.userProfile.photo)
    }
    this.uiService.showLoader();

    const payload = {
      "id": this.sdmEditData.userProfile.id,
      "photo": photo,
    }
    this.sdmService.changeStaffPhoto(payload).subscribe({
      next: data => {
        console.log("AFTER UPLOAD", data);
        this.uiService.hideLoader();
        this.uiService.showSuccess("Photo successfully updated")
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

  uploadSign(e: Event): void {
    const file = (e.target as HTMLInputElement).files;
    if (file?.length && file !== null) {
      this.uiService.showLoader();
      this.fileService.upload(file[0], 'staff').subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            // progress
          } else if (event instanceof HttpResponse) {
            this.uiService.hideLoader();
            this.uiService.showSuccess(event.body.message);
            // this.currentSaveFileName = event.body.filename;
            console.log("FNAME", event.body.filename);
            console.log("INFO", event.body);
            
            this.imgSignSrc = this.fileService.getImageUrl("staff/" + event.body.filename);
            
            if (this.isAddMode) {
              if (this.prevSign != "") {
                this.removePhoto(this.prevSign);
              }
              this.form.controls['sign'].setValue(event.body.filename);
              this.prevSign = event.body.filename;
            } else {
              this.saveSignStaff(event.body.filename);
            }
            //this.fileInfos = this.uploadService.getFiles();
          }
        },
        error: (err: any) => {
          console.log(err);
          this.uiService.hideLoader();
  
          if (err.error && err.error.message) {
            this.uiService.showError(err.error.message);
          } else {
            this.uiService.showError('Could not upload the file!');
          }
        }
      });
    }
  }

  saveSignStaff(photo: any) {

    // delete previose file
    if (!this.isAddMode && this.sdmEditData.userProfile.sign != null) {
      console.log("DELETE ", this.sdmEditData.userProfile.sign);
      this.removePhoto(this.sdmEditData.userProfile.sign)
    }
    this.uiService.showLoader();

    const payload = {
      "id": this.sdmEditData.userProfile.id,
      "sign": photo,
    }
    this.sdmService.changeSignfPhoto(payload).subscribe({
      next: data => {
        console.log("AFTER UPLOAD", data);
        this.uiService.hideLoader();
        this.uiService.showSuccess("Photo successfully updated")
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

}
