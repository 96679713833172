<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="content-item mb-5">
    <div class="back-button" (click)="onBack()">
      <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
    </div>
    <div class="item-header">
      EDIT Tahun Ajaran
    </div>
    
    <div class="card">
      <div class="card-header">
        DATA Tahun Ajaran
      </div>
      <div class="card-body">
          
              <div class="row">
            
                  <div class="col-12 col-lg-6">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="saudara" class="form-label">Tahun Ajaran</label>
                          <input
                            type="text"
                            formControlName="name"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['name'].errors }"
                            placeholder="ex: 2022-2023"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-3">                      
                          <input type="checkbox" formControlName="active" /> Active
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-3">                      
                          <input type="checkbox" formControlName="ppsb" /> Active PPSB
                        </div>
                      </div>
                  </div>
              </div>
          
      </div>
    </div>
  
    <div class="row align-items-center">
        <div class="col-6 col-lg-4 offset-lg-6">
            
        </div>
        <div class="col-6 col-lg-2 d-flex justify-content-end">
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </div>
  </div>
</form>
