import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private API_URI = '';

  constructor(private netService: NetworkService) { }

  getAll(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/permission', params);
  }


  getByRole(params: any, roleid: String): Observable<any> {
    return this.netService.getAllRequest('/v1/permissionrole/' + roleid, params);
  }

  create(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/permission');
  }

  assignPermission(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/rolepermission');
  }
  
  find(id: any): Observable<any> {
    return this.netService.getRequest(`/v1/permission/{id}`);
  }

  update(id: any, data: any): Observable<any> {
    return this.netService.putRequest(`/v1/permission/{id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.netService.deleteRequest(`/v1/permission/${id}`);
  }

}
