import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/helper/ui.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SdmService } from 'src/app/services/sdm.service';
import { IzinMengajarService } from 'src/app/services/izin-mengajar.service';

declare var Datepicker: any;

@Component({
  selector: 'app-izin-mengajar',
  templateUrl: './izin-mengajar.component.html',
  styleUrls: ['./izin-mengajar.component.css']
})
export class IzinMengajarComponent implements OnInit {

  listIzinGuru: any = [];
  listGuruPengganti: any = [];
  errorMessage = '';
  currentApproval = "";
  currentEvent: any = null;
  submitted = false;
  pageid = 'proguser';
  searchTerm = '';
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  closeResult: string = '';
  isNew = false;
  isUpdate = false;
  isApproval = false;
  isShowLoaderGuru: Boolean = false;
  searchGuruTerm = "";
  idIzinMengajar = "";
  currentGuruId: any = null;
  currentGuruName = "";
  listKategori = [
    "Pribadi","Sakit","Tugas Pondok"
  ]

  formIzinMengajar: FormGroup = new FormGroup({
    id: new FormControl(''),
    kategori: new FormControl(''),
    guru_id: new FormControl(''),
    guru_name: new FormControl(''),
    guru_pengganti_id: new FormControl(''),
    guru_pengganti_name: new FormControl(''),
    start_date: new FormControl(''),
    end_date: new FormControl(''),
    keterangan: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private sdmService: SdmService,
    private izinmengajarService: IzinMengajarService,
  ) {
    this.changeBodyClass();
  }
  
  ngOnInit(): void {
    this.initForm();

    const currentUser = localStorage.getItem('user')?.toString();
    const jsonUser = currentUser ? JSON.parse(currentUser) : "";
    console.log('JSON', jsonUser);
    console.log("gur name ", jsonUser.proguser.userProfile.real_name);
    
    this.currentGuruId = jsonUser.proguser.id;
    this.currentGuruName = jsonUser.proguser.userProfile.real_name;

    this.resetForm();
    
    this.getAllIzinGuru();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add('siswa-baru-list');
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    console.log('handle PAGE', this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  search(): void {
    this.page = 1;
  }

  initForm() {
    this.formIzinMengajar = this.formBuilder.group(
      {
        id: [''],
        guru_id: ['', Validators.required],
        guru_name: ['', Validators.required],
        guru_pengganti_id: [''],
        guru_pengganti_name: [''],
        start_date: ['', Validators.required],
        end_date: ['', Validators.required],
        keterangan: [''],
        kategori: ['', Validators.required],
      },
    );
  }

  configureDatePicker() {
    const elem_start_date = document.getElementById('start_date');
    console.log(elem_start_date);
    const datepicker_start_date = new Datepicker(elem_start_date, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    });
    
    const elem_end_date = document.getElementById('end_date');
    const datepicker_end_date = new Datepicker(elem_end_date, {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    });
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  changeStartDateField(e: any) {
    console.log(e.target.value);
    const birthdate = e.target.value;
    this.formIzinMengajar.controls['start_date'].setValue(birthdate);
  }

  changeEndDateField(e: any) {
    console.log(e.target.value);
    const birthdate = e.target.value;
    this.formIzinMengajar.controls['end_date'].setValue(birthdate);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formIzinMengajar.controls;
  }

  onSubmitformAssignIzinMengajar(modal: any): void {
    console.log(JSON.stringify(this.formIzinMengajar.value, null, 2));
    
    this.submitted = true;
    
    if (this.formIzinMengajar.value.guru_pengganti_id === null) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }
    
    modal.dismiss('Cross click');

    this.uiService.showLoader();

    const payload = {
      "guru_pengganti_id": this.formIzinMengajar.value.guru_pengganti_id
    }
    const id = this.formIzinMengajar.value.id;

    this.izinmengajarService.updateGuruPengganti(id, payload).subscribe({
      next: resdata => {
        console.log(resdata);

        this.resetForm();

        this.uiService.hideLoader();
        this.uiService.showSuccess('');
      
        this.getAllIzinGuru();

      },
      error: err => {
        console.log(err);

        this.resetForm();
        
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onSubmitformIzinMengajar(modal: any): void {
    console.log(JSON.stringify(this.formIzinMengajar.value, null, 2));
    
    this.submitted = true;
    
    if (this.formIzinMengajar.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }
    
    modal.dismiss('Cross click');

    this.uiService.showLoader();

    if (this.isNew) {
      this.izinmengajarService.add(this.formIzinMengajar.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.resetForm();

          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          this.getAllIzinGuru();
  
        },
        error: err => {
          console.log(err);

          this.resetForm();
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });    
    } else {
      this.izinmengajarService.update(this.idIzinMengajar, this.formIzinMengajar.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.resetForm();
          
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
        
          this.getAllIzinGuru();
  
        },
        error: err => {
          console.log(err);
          this.submitted = false;
          
          this.errorMessage = err.error.message;
          console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  getAllIzinGuru() {
    let params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);
    
    this.izinmengajarService.getAll(params).subscribe({
      next: resdata => {
        console.log("DATA Guru Izin", resdata);
        const { data, totalrows } = resdata;
        this.count = totalrows;
        this.listIzinGuru = data;

        this.uiService.hideLoader();
      },
      error: err => {
        console.log(err);
        this.errorMessage = err.error.message;
        console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    }); 
  }

  editWalikelas(wali: any) {

  }

  confirmDelete(data: any) {
    console.log('data', data);

    if (data.Guru.id !== this.currentGuruId || data.status === "PERMITTED") {
      this.uiService.showError('Maaf, data sudah diapprove');
      return;
    }
     
    this.uiService.showConfirmDelete(this, data.id);
  }

  afterConfirm(isConfirm: Boolean, id: String, $SELF: any) {
    console.log(isConfirm, id);
    
    if (isConfirm) {
      $SELF.uiService.showLoader();

      const payload = {
        "status": $SELF.currentEvent.target.checked ? "PERMITTED" : "NOT_PERMITTED"
      }

      $SELF.izinmengajarService.updateApproval(id, payload).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getAllIzinGuru();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });
    } else {
      $SELF.currentEvent.target.checked = !$SELF.currentEvent.target.checked; 
    }

  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.izinmengajarService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getAllIzinGuru();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  resetForm() {
    this.formIzinMengajar.reset();
    this.submitted = false;
    this.initForm();
    
    this.formIzinMengajar.controls['guru_name'].setValue(this.currentGuruName);
    this.formIzinMengajar.controls['guru_id'].setValue(this.currentGuruId);
    this.formIzinMengajar.controls['guru_name'].disable();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  openModalformIzinMengajar(content:any) {
    this.isNew = true;
    this.isUpdate = false;
    this.isApproval = false;
    this.submitted = false;

    this.resetForm();
    
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    const isOpenModal = this.modalService.hasOpenModals();
    console.log("MODAL", isOpenModal);

    if (isOpenModal) {
      this.configureDatePicker();
    }
  }

  openEditModalformIzinMengajar(content:any, izin: any) {
    console.log("IZIN", izin);

    if (izin.Guru.id !== this.currentGuruId) {
      this.uiService.showError('Maaf, anda tidak diperbolehkan mengedit');
      return;
    }
    
    this.isNew = false;
    this.isUpdate = true;
    this.isApproval = false;
    this.submitted = false;
    
    this.resetForm();

    this.idIzinMengajar = izin.id;
    this.formIzinMengajar.patchValue(izin);
    // this.formIzinMengajar.controls['id'].setValue(izin.id);
    // this.formIzinMengajar.controls['guru_pengganti_name'].setValue(izin.GuruPengganti.userProfile.real_name);

  
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    const isOpenModal = this.modalService.hasOpenModals();
    console.log("MODAL", isOpenModal);

    if (isOpenModal) {
      this.configureDatePicker();
    }
  }

  openAssignModalformIzinMengajar(content:any, izin: any) {
    console.log("IZIN", izin);

    if (izin.status !== "PERMITTED") {
      this.uiService.showError('Tidak dapat melakukan assigment, izin belum di approve');
      return;
    }
    
    this.submitted = false;
    
    this.resetForm();

    this.idIzinMengajar = izin.id;
    this.formIzinMengajar.patchValue(izin);
    
    this.formIzinMengajar.controls['guru_name'].setValue(izin.Guru.userProfile.real_name);

    if (izin.GuruPengganti !== null) {
      this.formIzinMengajar.controls['guru_pengganti_name'].setValue(izin.GuruPengganti.userProfile.real_name);
      this.formIzinMengajar.controls['guru_pengganti_id'].setValue(izin.GuruPengganti.id);
    }

  
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  openApprovalModalformIzinMengajar(content:any, izin: any) {
    console.log("IZIN", izin);
    
    this.isNew = false;
    this.isUpdate = false;
    this.isApproval = true;
    this.submitted = false;
    
    this.resetForm();

    this.idIzinMengajar = izin.id;
    this.formIzinMengajar.patchValue(izin);
    // this.formIzinMengajar.controls['id'].setValue(izin.id);
    this.formIzinMengajar.controls['guru_pengganti_name'].setValue(izin.GuruPengganti.userProfile.real_name);

  
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    const isOpenModal = this.modalService.hasOpenModals();
    console.log("MODAL", isOpenModal);

    if (isOpenModal) {
      this.configureDatePicker();
    }
  }

  chooseGuru(data: any) {
    this.listGuruPengganti = [];
    console.log(data);
    this.formIzinMengajar.controls['guru_pengganti_name'].setValue(data.userProfile.real_name);
    this.formIzinMengajar.controls['guru_pengganti_id'].setValue(data.userProfile.id_user);
  }

  searchGuru(): void {
    this.searchGuruTerm = this.formIzinMengajar.value.guru_pengganti_name;

    this.listGuruPengganti = [];
    if (this.searchGuruTerm != "") {  
      this.isShowLoaderGuru = true;
      const params = this.getRequestParams(this.searchGuruTerm, this.page, this.pageSize);

      console.log("PARAMS", params);
      
      this.sdmService.getAll(params).subscribe({
        next: resdata => {
          console.log(resdata);
          const { data, totalrows } = resdata;
  
          this.isShowPagination == totalrows > this.pageSize ? true : false;
          this.listGuruPengganti = data;
          this.count = totalrows;
  
          console.log('COUNT', this.count);
          this.isShowLoaderGuru = false;
        
          this.uiService.hideLoader();
  
        },
        error: err => {
          this.isShowLoaderGuru = false;
          this.errorMessage = err.error.message;
          console.log(this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }
  }

  approvalCheck(event: any, data: any) {
    console.log(data);
    this.currentEvent = event;
    if(event.target.checked==true){
      console.log('checkbox is checked');
    }
    else{
      console.log('checkbox is unchecked');
    }

    this.uiService.showConfirm(this, data.id, "Yes, Approve it!");
  }

}
