import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class NilaiRaportService {

  constructor(private netService: NetworkService) { }

  getInputRaportHarian(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getListNilaiRaportHarian');
  }

  getInputRaportSemester(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getListNilaiRaportSemester');
  }

  getInputRaportNonNilai(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getListNilaiRaportNonmark');
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/studentmark');
  }

  addNonNilai(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/studentmarknonnilai');
  }
}
