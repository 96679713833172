<div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="statistik"
      role="tabpanel"
      aria-labelledby="statistik-tab"
    >
      <div class="tabs-title">Setting Insya/Daily</div>
  
      
      <div class="list-options">
        <div class="form-select-warapper">
          <form [formGroup]="formAction">
            <select formControlName="id_tahun_ajaran" (change)="changeTahunAjaran($event)" class="form-select" name="id_tahun_ajaran" id="id_tahun_ajaran">
              <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
            </select>
          </form>
        </div>
  
        <div class="form-select-warapper">
          <form [formGroup]="formAction">
            <select formControlName="semester" (change)="changeSemester($event)" class="form-select" name="semester" id="semester">
              <option value="" selected>Pilih Semester</option>
              <option [value]="data.kode" *ngFor="let data of listSemester">{{data.name}}</option>
            </select>
          </form>
        </div>
        
        <button class="btn btn-blue-grey" (click)="openAddModalFormInsyaDaily(formAddInsyaDailyModal)">
          + Tambah Setting
        </button>
        
      </div>
      
      <div class="table-wrapper">
        <div class="table-options">
          <div class="show-table">
            Show
            <select
              class="form-control"
              name="show"
              id="show"
              (change)="handlePageSizeChange($event)"
            >
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
            entries
          </div>
        </div>
        <div class="table-responsive">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th class="align-middle">No.</th>
                  <th class="align-middle">Tipe</th>
                  <th class="align-middle">Tahun Ajaran</th>
                  <th class="align-middle">Semester</th>
                  <th class="align-middle">Tingkatan</th>
                  <th class="align-middle">Jumlah Tugas</th>
                  <th></th>
              </thead>
              <tbody>
                <tr *ngFor="let data of listSetttingInsyaDaily | paginate : {id: pageid, itemsPerPage: pageSize, currentPage: page, totalItems: count}; let i = index">
                  <td>{{ i + 1 + (page - 1) * pageSize }}</td>
                  <td>{{ data.tipe }}</td>
                  <td>{{ data.TahunAjaran.name }}</td>
                  <td>{{ data.semester }}</td>
                  <td>{{ data.Tingkat.nama_tingkat }}</td>
                  <td>{{ data.jumlah_tugas }}</td>
                  <td class="settings">
                    <div class="dropend">
                        <a href="#" role="button" id="dropdownMenuLink"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="./assets/images/icon-setting.svg" alt="">
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><a (click)="openEditModalFormInsyaDaily(formEditInsyaDailyModal, data)" class="dropdown-item"><img src="./assets/images/dropdown/icon-edit.png" alt=""> Edit</a></li>                                        
                            <!-- <li><a (click)="confirmDelete(data.id)" class="dropdown-item"><img src="./assets/images/dropdown/icon-delete.png" alt="">Hapus</a></li> -->
                        </ul>
                    </div>
                </td>
                </tr>
                <tr *ngIf="listSetttingInsyaDaily.length === 0" class="empty">
                  <td colspan="7">Tidak terdapat data atau data tidak ditemukan.</td>
                </tr>
              </tbody>
            </table>
          </div>
        
        <pagination-template #p="paginationApi" [id]="pageid" (pageChange)="handlePageChange($event)">
          <div *ngIf="isShowPagination; else emptyPagination" class="pagination-wrapper">
            <div></div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center pagination-number">
                <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                  <a
                  class="page-link"
                  (click)="p.setCurrent(page.value)"
                  *ngIf="p.getCurrent() !== page.value"
                  >
                  {{ page.label }}
                  </a>
                  <a class="page-link" *ngIf="p.getCurrent() === page.value">
                    {{ page.label }}
                  </a>
                </li>
              </ul>
            </nav>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="p.isFirstPage()">
                  <a
                  class="page-link"
                  *ngIf="!p.isFirstPage()"
                  (click)="p.previous()"
                  aria-label="Previous"
                  >
                  <span>Previous</span>
                </a>
              </li>
              <li class="page-item" [class.disabled]="p.isLastPage()">
                <a
                class="page-link"
                *ngIf="!p.isLastPage()"
                (click)="p.next()"
                aria-label="Next"
                >
                <span>Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </pagination-template>
    
    <ng-template #emptyPagination>
      <div class="pagination-wrapper">
        <div>&nbsp;</div>
      </div>
    </ng-template>
    
  </div>
  </div>
  </div>

  <ng-template #formAddInsyaDailyModal let-modal>
    <div class="modal-body">
        <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
        <div class="modal-form">
            <h1 class="text-center">Tambah Setting Insya/Daily</h1>
            
            <form [formGroup]="formAction" (ngSubmit)="onSubmitAddFormAction(modal)">
  
              <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">Tipe</label>
                <div class="col-sm-9">
                  <select
                    formControlName="tipe"
                    [ngClass]="{ 'is-invalid': submitted && f['tipe'].errors }"
                    class="form-select">
                    <option value="" selected disabled>Pilih</option>
                    <option [value]="data" *ngFor="let data of listTipe">{{data}}</option>
                  </select>
                </div>
              </div>

              <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">Tahun Pelajaran</label>
                <div class="col-sm-9">
                  <select
                    formControlName="id_tahun_ajaran"                    
                    [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }"
                    class="form-select">
                    <option value="" selected disabled>Pilih</option>
                    <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                  </select>
                </div>
              </div>
  
              <div class="mb-3 mt-4 form-group row">
                <label for="kelas" class="col-sm-3 control-label">Semester</label>
                <div class="col-sm-9">
                  <select
                      formControlName="semester"
                      [ngClass]="{ 'is-invalid': submitted && f['semester'].errors }"
                      class="form-select highlight"
                      (change)="changeKelas($event)">
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="data.kode" *ngFor="let data of listSemester">{{data.name}}</option>
                  </select>
                </div>
              </div>

              <div class="mb-3 mt-4 form-group row">
                <label for="kelas" class="col-sm-3 control-label">Tingkatan</label>
                <div class="col-sm-9">
                  <select
                      formControlName="id_tingkat"
                      [ngClass]="{ 'is-invalid': submitted && f['id_tingkat'].errors }"
                      class="form-select highlight">
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="data.id" *ngFor="let data of listsTingkat">{{data.nama_tingkat}}</option>
                  </select>
                </div>
              </div>

              <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">Jumlah Tugas</label>
                <div class="col-sm-9">
                  <select
                    formControlName="jumlah_tugas"
                    [ngClass]="{ 'is-invalid': submitted && f['jumlah_tugas'].errors }"
                    class="form-select">
                    <option value="" selected disabled>Pilih</option>
                    <option [value]="data" *ngFor="let data of listJumlahTugas">{{data}}</option>
                  </select>
                </div>
              </div>
  
              <button type="submit" class="btn btn-primary mt-3 my-btn-save">CREATE</button>
              
            </form>
        </div>
    </div>
  </ng-template>

  <ng-template #formEditInsyaDailyModal let-modal>
    <div class="modal-body">
        <button type="button" class="my-btn-close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
        <div class="modal-form">
            <h1 class="text-center">Edit Setting Insya/Daily</h1>
            
            <form [formGroup]="formAction" (ngSubmit)="onSubmitEditFormAction(modal)">
  
              <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">Tipe</label>
                <div class="col-sm-9">
                  <select
                    formControlName="tipe"
                    [ngClass]="{ 'is-invalid': submitted && f['tipe'].errors }"
                    class="form-select">
                    <option value="" selected disabled>Pilih</option>
                    <option [value]="data" *ngFor="let data of listTipe">{{data}}</option>
                  </select>
                </div>
              </div>

              <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">Tahun Pelajaran</label>
                <div class="col-sm-9">
                  <select
                    formControlName="id_tahun_ajaran"                    
                    [ngClass]="{ 'is-invalid': submitted && f['id_tahun_ajaran'].errors }"
                    class="form-select">
                    <option value="" selected disabled>Pilih</option>
                    <option [value]="data.id" *ngFor="let data of listTahunajaran">{{data.name}}</option>
                  </select>
                </div>
              </div>
  
              <div class="mb-3 mt-4 form-group row">
                <label for="kelas" class="col-sm-3 control-label">Semester</label>
                <div class="col-sm-9">
                  <select
                      formControlName="semester"
                      [ngClass]="{ 'is-invalid': submitted && f['semester'].errors }"
                      class="form-select highlight"
                      (change)="changeKelas($event)">
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="data.kode" *ngFor="let data of listSemester">{{data.name}}</option>
                  </select>
                </div>
              </div>

              <div class="mb-3 mt-4 form-group row">
                <label for="kelas" class="col-sm-3 control-label">Tingkatan</label>
                <div class="col-sm-9">
                  <select
                      formControlName="id_tingkat"
                      [ngClass]="{ 'is-invalid': submitted && f['id_tingkat'].errors }"
                      class="form-select highlight">
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="data.id" *ngFor="let data of listsTingkat">{{data.nama_tingkat}}</option>
                  </select>
                </div>
              </div>

              <div class="mb-3 mt-4 form-group row">
                <label for="input-id-1" class="col-sm-3 control-label">Jumlah Tugas</label>
                <div class="col-sm-9">
                  <select
                    formControlName="jumlah_tugas"
                    [ngClass]="{ 'is-invalid': submitted && f['jumlah_tugas'].errors }"
                    class="form-select">
                    <option value="" selected disabled>Pilih</option>
                    <option [value]="data" *ngFor="let data of listJumlahTugas">{{data}}</option>
                  </select>
                </div>
              </div>
  
              <button type="submit" class="btn btn-primary mt-3 my-btn-save">UPDATE</button>
              
            </form>
        </div>
    </div>
  </ng-template>