import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

@Injectable({
  providedIn: 'root'
})
export class PembayaranService {

  constructor(private netService: NetworkService) { }

  getAllPembayaranFormulir(params: any): Observable<any> {
    return this.netService.getAllRequest('/v1/getAllPembayaranFormulir', params);
  }

  getAllTagihanBulanan(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getAllTagihanBulanan');
  }

  getDetailTagihanBulananSiswa(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getDetailTagihanBulananSiswa');
  }

  getLaporanTagihanSiswa(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getLaporanTagihan');
  }

  getAllUnpaidTagihanByGroupBiaya(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getAllUnpaidTagihanByGroupBiaya');
  }

  getAllUnpaidTagihanByGroupBiayaPerItem(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/getAllUnpaidTagihanByGroupBiayaPerItem');
  }

  deleteTagihanBulanan(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/deleteTagihanBulanan');
  }

  generateTagihanBulanan(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/generateTagihanBulanan');
  }

  add(payload: any): Observable<any> {
    return this.netService.postRequest(payload, '/v1/v1/makePembayaran');
  }
}
