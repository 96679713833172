<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="content-item mb-5">
    <div class="back-button" (click)="onBack()">
      <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
    </div>
    <div class="item-header">
      ADD Rayon
    </div>
    
    <div class="card">
      <div class="card-header">
        DATA RAYON
      </div>
      <div class="card-body">
          
              <div class="row">
            
                  <div class="col-12 col-lg-6">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label for="saudara" class="form-label">Nama Rayon</label>
                        <input
                          type="text"
                          formControlName="nama_rayon"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f['nama_rayon'].errors }"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label for="saudara" class="form-label">Murobbi</label>
                        <div class="col-sm-9 autocomplete">
                          <input formControlName="nama_murobbi" (keyup)="searchMurobbi()" type="text" class="form-control" autocapitalize="off" autocorrect="off" autocomplete="off" id="MurobbiPenguji">
    
                          <div id="myInputautocomplete-list-penguji" class="autocomplete-items">
                            <button *ngIf="isShowLoaderMurobbi" class="btn btn-primary" type="button" disabled>
                              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                              Searching...
                            </button>
                            <div (click)="chooseMurobbi(data)" *ngFor="let data of listMurobbi">
                              {{ data.userProfile.real_name }}
                            </div>
    
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  
              </div>
          
      </div>
    </div>
  
    <div class="row align-items-center">
        <div class="col-6 col-lg-4 offset-lg-6">
            
        </div>
        <div class="col-6 col-lg-2 d-flex justify-content-end">
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </div>
  </div>
</form>
