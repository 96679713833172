<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="content-item mb-5">
    <div class="back-button" (click)="onBack()">
      <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
    </div>
    <div class="item-header">
      Edit Content
    </div>
    
    <div class="card">
      <div class="card-header">
        {{ ctnName }}
      </div>
      <div class="card-body">
        
        
              <div class="row">
            
                  <div class="col-12 col-lg-12">
                      <div class="row">
                        <angular-editor formControlName="body" [config]="config"></angular-editor>                
                      </div>
                      
                  </div>
              </div>
          
      </div>
    </div>
  
    <div class="row align-items-center">
        <div class="col-6 col-lg-4 offset-lg-6">
            
        </div>
        <div class="col-6 col-lg-2 d-flex justify-content-end">
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </div>
  </div>
</form>
