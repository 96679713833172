import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { AuthComponent } from './layout/auth/auth.component';
import { MainAppComponent } from './layout/main-app/main-app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { GedungListComponent } from './pages/gedung/gedung-list/gedung-list.component';
import { KamarListComponent } from './pages/kamar/kamar-list/kamar-list.component';
import { LembagaViewComponent } from './pages/lembaga/lembaga-view/lembaga-view.component';
import { PermissionAddComponent } from './pages/permission/permission-add/permission-add.component';
import { PermissionListComponent } from './pages/permission/permission-list/permission-list.component';
import { RoleAddComponent } from './pages/role/role-add/role-add.component';
import { RoleEditComponent } from './pages/role/role-edit/role-edit.component';
import { RoleListComponent } from './pages/role/role-list/role-list.component';
import { RolePermissionComponent } from './pages/role/role-permission/role-permission.component';
import { RoleViewComponent } from './pages/role/role-view/role-view.component';
import { TahunajaranListComponent } from './pages/tahunajaran/tahunajaran-list/tahunajaran-list.component';
import { DokumenComponent } from './pages/dokumen/dokumen.component';
import { InsyaDailyComponent } from './pages/insya-daily/insya-daily.component';
import { PdfPrintTagihanComponent } from './pages/keuangan/pdf-print-tagihan/pdf-print-tagihan.component';
import { LogActivityComponent } from './pages/log-activity/log-activity.component';
import { PrintkamarComponent } from './pages/pengasuhan/printkamar/printkamar.component';
import { KategoriAlatComponent } from './pages/brt/kategori-alat/kategori-alat.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        loadChildren: () => import('./auth/auth-routing.module').then(m => m.AuthRoutingModule)
      }
    ]
  },
  {
    path: 'download/print-tagihan',
    component: PdfPrintTagihanComponent,
    canActivate:[AuthGuard],
  },
  {
    path: 'pengasuhan/printkamar',
    component: PrintkamarComponent,
    canActivate:[AuthGuard],
  },
  {
    path: '',
    component: MainAppComponent,
    canActivate:[AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        //loadChildren: () => import('./main-app/main-app-routing.module').then(m => m.MainAppRoutingModule),
        component: DashboardComponent
      },
      {
        path: 'sdm',
        loadChildren: () => import('./modules/sdm/sdm-routing.module').then(m => m.SdmRoutingModule),
      },
      {
        path: 'pengasuhan',
        loadChildren: () => import('./modules/pengasuhan/pengasuhan-routing.module').then(m => m.PengasuhanRoutingModule),
      },
      {
        path: 'absen-kegiatan',
        loadChildren: () => import('./modules/absenkegiatan/absenkegiatan-routing.module').then(m => m.AbsenkegiatanRoutingModule),
      },
      {
        path: 'siswa',
        loadChildren: () => import('./modules/siswa/siswa-routing.module').then(m => m.SiswaRoutingModule),
      },
      {
        path: 'ppsb',
        loadChildren: () => import('./modules/ppsb/ppsb-routing.module').then(m => m.PpsbRoutingModule),
      },
      {
        path: 'pendidikan',
        loadChildren: () => import('./modules/pendidikan/pendidikan-routing.module').then(m => m.PendidikanRoutingModule),
      },
      {
        path: 'keuangan',
        loadChildren: () => import('./modules/keuangan/keuangan-routing.module').then(m => m.KeuanganRoutingModule),
      },
      {
        path: 'master/lembaga',
        loadChildren: () => import('./modules/lembaga/lembaga-routing.module').then(m => m.LembagaRoutingModule),
      },
      {
        path: 'master/tingkatan',
        loadChildren: () => import('./modules/tingkatan/tingkatan-routing.module').then(m => m.TingkatanRoutingModule),
      },
      {
        path: 'master/kelas',
        loadChildren: () => import('./modules/kelas/kelas-routing.module').then(m => m.KelasRoutingModule),
      },
      {
        path: 'master/pelajaran',
        loadChildren: () => import('./modules/pelajaran/pelajaran-routing.module').then(m => m.PelajaranRoutingModule),
      },
      {
        path: 'master/rayon',
        loadChildren: () => import('./modules/rayon/rayon-routing.module').then(m => m.RayonRoutingModule),
      },
      {
        path: 'master/gedung',
        component: GedungListComponent
      },
      {
        path: 'master/kamar',
        component: KamarListComponent
      },
      {
        path: 'master/kategorialat',
        component: KategoriAlatComponent
      },
      {
        path: 'brt',
        loadChildren: () => import('./modules/brt/brt-routing.module').then(m => m.BrtRoutingModule)
      },
      {
        path: 'settings/role',
        loadChildren: () => import('./modules/role/role-routing.module').then(m => m.RoleRoutingModule),
      },
      {
        path: 'settings/tahunajaran',
        loadChildren: () => import('./modules/tahunajaran/tahunajaran-routing.module').then(m => m.TahunajaranRoutingModule),
      },
      {
        path: 'settings/gelombangpendaftaran',
        loadChildren: () => import('./modules/gelombangpendaftaran/gelombangpendaftaran-routing.module').then(m => m.GelombangpendaftaranRoutingModule),
      },
      {
        path: 'settings/berkas',
        loadChildren: () => import('./modules/berkas/berkas-routing.module').then(m => m.BerkasRoutingModule),
      },
      {
        path: 'settings/contentregis',
        loadChildren: () => import('./modules/content-regis/content-regis-routing.module').then(m => m.ContentRegisRoutingModule),
      },
      {
        path: 'settings/lokasiujian',
        loadChildren: () => import('./modules/lokasiujian/lokasiujian-routing.module').then(m => m.LokasiujianRoutingModule),
      },
      {
        path: 'settings/matauji',
        loadChildren: () => import('./modules/matauji/matauji-routing.module').then(m => m.MataujiRoutingModule),
      },
      {
        path: 'settings/dokumen',
        component: DokumenComponent
      },
      {
        path: 'settings/insyadaily',
        component: InsyaDailyComponent
      },
      {
        path: 'settings/log',
        component: LogActivityComponent
      },
      {
        path: 'keuangan/biaya',
        loadChildren: () => import('./modules/biaya/biaya-routing.module').then(m => m.BiayaRoutingModule),
      },
      {
        path: 'tugas',
        loadChildren: () => import('./modules/tugas/tugas-routing.module').then(m => m.TugasRoutingModule),
      },
      {
        path: 'news',
        loadChildren: () => import('./modules/news/news-routing.module').then(m => m.NewsRoutingModule)
      },
      {
        path: 'role/:roleId/edit',
        component: RoleEditComponent
      },
      {
        path: 'role/:roleId/view',
        component: RoleViewComponent
      },
      {
        path: 'role/:roleId/permission',
        component: PermissionAddComponent
      },
      {
        path: 'permission',
        component: PermissionListComponent
      },
      {
        path: 'permission/create',
        component: PermissionAddComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
