<div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="guru-input"
      role="tabpanel"
      aria-labelledby="guru-input-tab"
    >
      <div class="tabs-title">Form Absensi Kegiatan</div>
  
      
      <div class="col-12 col-lg-12">
        <div class="card">
            
            <div class="card-body">
                <form [formGroup]="formInputInitGuru">
                    <div class="row">
                        <div class="col-12 col-lg-12">
                            <div class="mb-3">
                                <div class="row align-items-center">
                                    <div class="col-2 text-end">
                                        <label for="nik" class="form-label">Tahun Ajaran <span
                                                class="required">*</span></label>
                                    </div>
                                    <div class="col-10">
                                        <input type="text" 
                                          formControlName="tahun_ajaran"
                                          readonly
                                          class="form-control btn-secondary" placeholder="">
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="row align-items-center">
                                    <div class="col-2 text-end">
                                        <label for="name" class="form-label">Tanggal</label>
                                    </div>
                                    <div class="col-10">
                                        <input type="text" class="form-control" formControlName="tanggal"
                                            placeholder="">
                                    </div>
                                </div>
                            </div>
                            
                          <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="name" class="form-label">Jenis Absen <span
                                              class="required">*</span></label>
                                  </div>
                                  <div class="col-10">
                                    <select
                                        formControlName="id_gedung"
                                        [ngClass]="{ 'is-invalid': submitted && f['id_gedung'].errors }"
                                        class="form-select"
                                        (change)="changeGedung($event)">
                                        <option value="" selected>Pilih</option>
                                        <option [value]="gedung.id" *ngFor="let gedung of listRayon">{{ gedung.nama_gedung }}</option>
                                    </select>
                                  </div>
                              </div>
                          </div> 
                          
                          <div class="mb-3">
                            <div class="row align-items-center">
                                <div class="col-2 text-end">
                                    <label for="name" class="form-label">Kelompok/Kelas <span
                                            class="required">*</span></label>
                                </div>
                                <div class="col-10">
                                  <select
                                  formControlName="id_rayon" class="form-select"
                                      [ngClass]="{ 'is-invalid': submitted && f['id_rayon'].errors }"
                                      class="form-select"
                                      (change)="changeRayon($event)">
                                      <option value="" selected>Pilih</option>
                                      <option [value]="rayon.id" *ngFor="let rayon of listRayon">{{ rayon.nama_rayon }}</option>
                                  </select>
                                </div>
                            </div>
                        </div> 
                          
                            
                            <div class="mb-3">
                              <div class="row align-items-center">
                                  <div class="col-2 text-end">
                                      <label for="name" class="form-label">Keterangan</label>
                                  </div>
                                  <div class="col-10">
                                      <input type="text" class="form-control" formControlName="keterangan"
                                          placeholder="">
                                  </div>
                              </div>
                            </div>
                            
                          
                        </div>
                        
                    </div>
                </form>
            </div>
        </div>
      </div>
  
      <div class="mt-4 list-options">
                      
        
      </div>
      
      <div class="table-wrapper">
        <div class="table-options">
          <div class="show-table">
            
          </div>
        </div>
        
        
      </div>
    </div>
  </div>