<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="content-item mb-5">
    <div class="back-button" (click)="onBack()">
      <img class="w-100" src="./assets/images/icon-back.png" alt=""> BACK
    </div>
    <div class="item-header">
      EDIT Kelas
    </div>
    
    <div class="card">
      <div class="card-header">
        DATA KELAS
      </div>
      <div class="card-body">
          
              <div class="row">
                  <div class="col-12 col-lg-5">
                      <div class="row">                          
                          <div class="col-12 mb-3">
                              <label for="nis" class="form-label">Lembaga</label>
                              <select
                              formControlName="id_lembaga"                              
                                  class="form-select"
                                  (change)="changeLembaga($event)">
                                  <option value="" selected disabled>Pilih</option>
                                  <option [value]="data.id" *ngFor="let data of listsLembaga">{{data.nama_lembaga}}</option>
                              </select>
                          </div>
                          <div class="col-12 mb-3">
                            <label for="nis" class="form-label">Tingkatan</label>
                            <select
                                formControlName="id_tingkat"
                                [ngClass]="{ 'is-invalid': submitted && f['id_tingkat'].errors }"
                                class="form-select">
                                <option value="" selected disabled>Pilih</option>
                                <option [value]="data.id" *ngFor="let data of listsTingkat">{{data.nama_tingkat}}</option>
                            </select>
                        </div>
                      </div>
                  </div>
                  <div class="col-12 col-lg-7">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="saudara" class="form-label">Nama Kelas</label>
                          <input
                            type="text"
                            formControlName="nama_kelas"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['nama_kelas'].errors }"
                            placeholder="ex: 1 A"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="saudara" class="form-label">Nama Kelas (Arab)</label>
                          <input
                            type="text"
                            formControlName="nama_kelas_ar"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['nama_kelas_ar'].errors }"
                            placeholder=""
                          />
                        </div>
                      </div>
                  </div>
                  <div class="col-12 col-lg-5">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label for="saudara" class="form-label">Kode Kelas</label>
                        <input
                          type="text"
                          formControlName="kode_kelas"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f['kode_kelas'].errors }"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
              </div>
          
      </div>
    </div>
  
    <div class="row align-items-center">
        <div class="col-6 col-lg-4 offset-lg-6">
            
        </div>
        <div class="col-6 col-lg-2 d-flex justify-content-end">
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </div>
  </div>
</form>

