import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Validation from 'src/app/helper/validation';
import { SiswaService } from 'src/app/services/siswa.service';
import { GeneralService } from 'src/app/services/general.service';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { PembayaranService } from 'src/app/services/pembayaran.service';


@Component({
  selector: 'app-laporan-tagihan',
  templateUrl: './laporan-tagihan.component.html',
  styleUrls: ['./laporan-tagihan.component.css']
})
export class LaporanTagihanComponent implements OnInit {
[x: string]: any;

  currentPeriodeId: any = null;
  currentPpsbPeriodeId: any = null;
  currentAngkatan = "";
  listTahunajaran: any = [];
  listDataTagihan: any = [];
  listGroupBiayaTagihan: any = [];
  listTahapanPendaftaran: any = ["REGISTRATION","REGISTRATION_FEE","FILL_REGISTRATION_DATA","DOCUMENT_VALIDATION","ENTRANCE_EXAMINATION","ENTRY_TUITION_FEE","INFORMATION","ACCEPTED"];
  errorMessage = '';
  siswaId = "BLEHHH";
  colspan = 7;

  formFilterLaporanTagihan: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),
    nama_no_induk: new FormControl(''),
  });

  formChangePassword: FormGroup = new FormGroup({
    id: new FormControl(''),
    password: new FormControl(''),
    confirmPassword: new FormControl(''),
  });
  formChangeTahapanPendaftaran: FormGroup = new FormGroup({
    id: new FormControl(''),
    nama_lengkap: new FormControl(''),
    current_status: new FormControl(''),
  });
  submitted = false;

  pageid = "proguser";

  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;

  isColumnNoShow = true
  isColumnRegNumberShow = true
  isColumnNamaShow = true
  isColumnVaNumberShow = true
  isColumnTagihanShow = true
  isColumnLembagaShow = true
  isColumnTingkatanShow = true
  isColumnJenisKelaminShow = true
  isColumnTahapanRegistrasiShow = true
  isColumnRegistrasiUlangShow = true

  closeResult: string = '';

  constructor(private siswaService: SiswaService,
    private generalService: GeneralService,
    private uiService: UiService,
    private modalService: NgbModal,
    private sharedDataService: SharedDataService,
    private tahunajaranService: TahunajaranService,
    private pembayaranService: PembayaranService,
    private formBuilder: FormBuilder) {
    this.changeBodyClass();
  }

  ngOnInit() {
    this.getCurrentTahunajaran();
    this.getPpsbTahunajaran();        

    this.formFilterLaporanTagihan = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],
        nama_no_induk: [''],
      }
    );

    this.formChangePassword = this.formBuilder.group(
      {
        id: [''],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40)
          ]
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: [Validation.match('password', 'confirmPassword')]
      }
    );

    this.formChangeTahapanPendaftaran = this.formBuilder.group(
      {
        id: [''],
        nama_lengkap: ['', Validators.required],
        current_status: ['', Validators.required],
      }
    );
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  getLaporanTagihanSiswa() {
    const payload = {
      nama_or_no_induk: this.formFilterLaporanTagihan.value.nama_no_induk,
      id_periode: this.formFilterLaporanTagihan.value.id_tahun_ajaran,
      // list_siswa: this.listDataSiswaPerorangan,
      // id_lembaga: this.formSumberDataSiswa.value.id_lembaga,
      // id_tingkat: this.formSumberDataSiswa.value.id_tingkat,
      // id_group_biaya: this.formSumberDataTagihan.value.selectedGroupBiaya,
      // tahun_awal: thAwal,
      // tahun_akhir: thAkhir
    }
    console.log("PAYLOAD laporan", payload);
    
    this.uiService.showLoader();
    this.pembayaranService.getLaporanTagihanSiswa(payload).subscribe({
      next: resdata => {
        console.log("getAllTagihanBUlan", resdata.data);
           
        this.listDataTagihan = resdata.data.result;
        console.log("listDataTagihan", this.listDataTagihan);
        
        this.listGroupBiayaTagihan = resdata.data.groupBiayaNameList;

        this.colspan = this.listGroupBiayaTagihan.length + 7;
        
        console.log("listGroupBiayaTagihan", this.listGroupBiayaTagihan);
        
        // const rowCols: any[][] = [];

        // for (let i=0; i<resdata.data['juli'].length; i++) {
        //   let cols: any[] = [];
        //   cols.push(
        //     {
        //       val: resdata.data['juli'][i].nama_lengkap
        //     }                
        //   );
        //   cols.push(
        //     {
        //       val: resdata.data['juli'][i].nama_kelas
        //     }                
        //   );           

        //   for (let c=0; c<this.listBulanEfektif.length; c++) {
        //     const sBulan = this.listBulanEfektif[c].bulan;
        //     const nilTagihan = resdata.data[sBulan][i].amount ? resdata.data[sBulan][i].amount : 0;
        //     const col = {
        //       idsiswa: "x",
        //       val: nilTagihan
        //     }
        //     cols.push(col);
        //   }
        //   rowCols.push(cols);
        // }
        
        // console.log("rpwCols", rowCols);
        // this.listTagihanBulan = rowCols;

                  
        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
   openModal(content:any, id: any) {
    this.submitted = false;
    this.formChangePassword.reset();
    this.formChangePassword.controls['id'].setValue(id);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalChangeStepPendaftaran(content:any, student: any) {
    console.log(student);
    this.submitted = false;
    this.formChangeTahapanPendaftaran.reset();
    this.formChangeTahapanPendaftaran.controls['id'].setValue(student.id);
    this.formChangeTahapanPendaftaran.controls['nama_lengkap'].setValue(student.nama_lengkap);
    this.formChangeTahapanPendaftaran.controls['current_status'].setValue(student.current_status);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  async getCurrentTahunajaran() {
    this.uiService.showLoader();

    await this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;
        this.getAllTahunajaran();        

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  async getPpsbTahunajaran() {
    this.uiService.showLoader();

    await this.tahunajaranService.getAktivePpsb().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPpsbPeriodeId = resdata.id;
        this.currentAngkatan = resdata.name;

        this.uiService.hideLoader();

        this.getLaporanTagihanSiswa();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPpsbPeriodeId, this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formFilterLaporanTagihan.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;
        this.getLaporanTagihanSiswa();

        this.uiService.hideLoader();        

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeTahunAjaran(e: any) {
    console.log(e.target.value);
    this.currentAngkatan = e.target.value;

    this.getLaporanTagihanSiswa();
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.getLaporanTagihanSiswa();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getLaporanTagihanSiswa();
  }

  search(): void {
    this.page = 1;
    this.getLaporanTagihanSiswa();
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/empty-image.png';
  }


  get h(): { [key: string]: AbstractControl } {
    return this.formFilterLaporanTagihan.controls;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formChangePassword.controls;
  }

  get g(): { [key: string]: AbstractControl } {
    return this.formChangeTahapanPendaftaran.controls;
  }

  onSubmitChangePassword(): void {
    this.submitted = true;

    if (this.formChangePassword.invalid) {
      return;
    }

    this.modalService.dismissAll();

    this.uiService.showLoader();

    this.generalService.changeSiswaBaruPassword(this.formChangePassword.value).subscribe({
      next: resdata => {
        console.log(resdata);

        this.uiService.hideLoader();
        this.uiService.showSuccess('');

      },
      error: err => {
        console.log(err);

        this.errorMessage = err.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });

    console.log(JSON.stringify(this.formChangePassword.value, null, 2));
  }

  onSubmitChangeTahapanPendaftaran(): void {
    this.submitted = true;

    if (this.formChangeTahapanPendaftaran.invalid) {
      return;
    }

    this.modalService.dismissAll();

    this.uiService.showLoader();

    const payload = {
      id: this.formChangeTahapanPendaftaran.value['id'],
      step: this.formChangeTahapanPendaftaran.value['current_status'],
    }

    this.siswaService.changeTahapanPendaftaran(payload).subscribe({
      next: resdata => {
        console.log(resdata);
        this.getLaporanTagihanSiswa();

        this.uiService.hideLoader();
        this.uiService.showSuccess('');

      },
      error: err => {
        console.log(err);

        this.errorMessage = err.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });

    console.log(payload, JSON.stringify(this.formChangeTahapanPendaftaran.value, null, 2));
  }

  confirmDelete(id: String) {

    this.uiService.showConfirmDelete(this, id);
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.siswaService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getLaporanTagihanSiswa();

          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);

          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);

          $SELF.uiService.hideLoader();

          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  showDetailSiswa(siswa: any) {
    console.log(siswa);
    this.sharedDataService.changeSiswa(siswa);
    this.sharedDataService.changeComponent('siswa');
    this.uiService.showFullScrrenContent();
  }

  closeButton() {

  }

}
