import { Component, OnInit } from '@angular/core';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/helper/common.service';
import { ExcelService } from 'src/app/services/excel.service';
import { KelasService } from 'src/app/services/kelas.service';
import { NilaiHarianService } from 'src/app/services/nilai-harian.service';

@Component({
  selector: 'app-laporan-nilai-al-quran',
  templateUrl: './laporan-nilai-al-quran.component.html',
  styleUrls: ['./laporan-nilai-al-quran.component.css']
})
export class LaporanNilaiAlQuranComponent implements OnInit {

  listSemester: any = [];
  listTahunajaran: any = [];
  listNilaiAlquran: any = [];
  listKelas: any = [];
  listTugasKe: any = [];
  currentPeriodeId: any = null;
  dataToUpload: any = null;
  pageid = "upload-jadwal-mengajar";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  errorMessage = "";
  currentDate = "";
  submitted = false;
  listMonth: any = [];
  currentKelasId: any = null;
  currentSemester = "";
  
  formDownloadLaporan: FormGroup = new FormGroup({
    id_tahun_ajaran: new FormControl(''),    
    id_kelas: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private tahunajaranService: TahunajaranService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private kelasService: KelasService,
    private excelService: ExcelService,
    private nilaiharianService: NilaiHarianService,
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    this.getKelas();
    this.listMonth = this.commonService.getListMonth();
    this.listTugasKe = this.commonService.getListTugasKe();    
    this.getCurrentTahunajaran();
    this.listSemester = this.commonService.getListSemester();
    this.initForm();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {
    this.formDownloadLaporan = this.formBuilder.group(
      {
        id_tahun_ajaran: ['', Validators.required],        
        id_kelas: ['', Validators.required],
      },
    );
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getAllTahunajaran() {

    this.uiService.showLoader();

    const params = this.getRequestParams(this.searchTerm, this.page, this.pageSize);

    this.tahunajaranService.getAll(params).subscribe({
      next: resdata => {
        console.log("PERIOD", this.currentPeriodeId);
        console.log('list tahun ajaran', resdata);
        this.formDownloadLaporan.controls['id_tahun_ajaran'].setValue(this.currentPeriodeId);
        const { data, totalrows } = resdata;

        this.listTahunajaran = data;

        this.uiService.hideLoader();

        // this.getAllPlotKelasByPeriode();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getCurrentTahunajaran() {
    this.uiService.showLoader();

    this.tahunajaranService.getAktivePeriode().subscribe({
      next: resdata => {
        console.log('current periode', resdata);

        //this.getAllBiayaTahunan(resdata.id);

        this.currentPeriodeId = resdata.id;        

        this.uiService.hideLoader();

        this.getAllTahunajaran();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }


  onSubmitFormDownloadLaporan() {
    this.submitted = true;
    console.log(JSON.stringify(this.formDownloadLaporan.value, null, 2));

    if (this.formDownloadLaporan.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    // let data = [];

    this.uiService.showLoader();

    this.nilaiharianService.getLaporanNilaiQuran(this.formDownloadLaporan.value).subscribe({
      next: resdata => {
        console.log("REPORT", resdata);

        if (resdata !== null) {
          // get num of siswa
          const totalSiswa = resdata[0].NilaiQuranSiswa.length;
          console.log("jmlah siswa", totalSiswa);

          // 2d array is row x coll = totalSiswa x 15
          let rowCols = [];
          for (let i=0; i<totalSiswa; i++) {
            rowCols[i] = new Array(15)
          }

          console.log("2D array", rowCols);
          
          
          const listBulan = this.commonService.getListMonthTahunAjaran();


          for (const n in resdata) {
            const idxBln = listBulan.indexOf(resdata[n].bulan) + 3;
            // console.log("data ke", n, "bulan ke", idxBln, resdata[n]);

            
            for (const r in resdata[n].NilaiQuranSiswa) {
              console.log("data ke", n, r, resdata[n].NilaiQuranSiswa[r]);
              
              rowCols[parseInt(r)][idxBln] = parseInt(resdata[n].NilaiQuranSiswa[r].nilai) === 0 ? resdata[n].NilaiQuranSiswa[r].nilai + ", " + resdata[n].NilaiQuranSiswa[r].keterangan : resdata[n].NilaiQuranSiswa[r].nilai

              // parsing NO, NO_INDUK, NAMA
              if (parseInt(n) === 0) {
                rowCols[parseInt(r)][0] = parseInt(r) + 1;
                rowCols[parseInt(r)][1] = resdata[n].NilaiQuranSiswa[r].Siswa.no_induk;
                rowCols[parseInt(r)][2] = resdata[n].NilaiQuranSiswa[r].Siswa.nama_lengkap;
              }
            }            

          }
          console.log("NEW 2D array", rowCols);

          console.log("nilai 0, 7", rowCols[0][7]);
          
          let data = [];

          for (let i=0; i<totalSiswa; i++) {
            const row = {
              NO: rowCols[i][0],
              NO_INDUK: rowCols[i][1],
              NAMA: rowCols[i][2],              
              Juli: rowCols[i][3],
              Agustus: rowCols[i][4],
              September: rowCols[i][5],
              Oktober: rowCols[i][6],
              November: rowCols[i][7],
              Desember: rowCols[i][8],
              Januari: rowCols[i][9],
              Februari: rowCols[i][10],
              Maret: rowCols[i][11],
              April: rowCols[i][12],
              Mei: rowCols[i][13],
              Juni: rowCols[i][14]
            }

            data.push(row);
          }

          this.excelService.exportAsExcelFile(data, "Laporan NIlai Quran.xlsx");

        }
        
        // this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });

    // for (const k in this.listKelas) {
    //   const v = this.listKelas[k];
    //   const row = {
    //     NO: parseInt(k)+1,
    //     NO_INDUK: "2.23.xxx",
    //     NAMA: "",
    //     KELAS: v.nama_kelas,
    //     KODE_KELAS: v.id,
    //     TAHUN_PELAJARAN: this.currentAngkatan,
    //     SEMESTER: "MS1/S1/MS2/S2 (Note: MS = Mid Semester, S= Semester)",
    //     NO_URUT: parseInt(k)+1
    //   }

    //   data.push(row);
    // }

    // this.excelService.exportAsExcelFile(data, "Format Upload Peringkat Kelas.xlsx");
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formDownloadLaporan.controls;
  }

  changeTahunAjaran(e: any) {
    console.log(e);
     
  }

  changeKelas(e: any) {
    this.currentKelasId = e.target.value;
  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeSemester(e: any) {
    this.currentSemester = e.target.value;
  }

}
