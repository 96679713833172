import { Component, OnInit } from '@angular/core';
import { TahunajaranService } from 'src/app/services/tahunajaran.service';
import { UiService } from 'src/app/helper/ui.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/helper/common.service';
import { ExcelService } from 'src/app/services/excel.service';
import { KelasService } from 'src/app/services/kelas.service';
import { LiburMengajarService } from 'src/app/services/libur-mengajar.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare var Datepicker: any;

@Component({
  selector: 'app-jadwal-mengajar-libur-mengajar',
  templateUrl: './jadwal-mengajar-libur-mengajar.component.html',
  styleUrls: ['./jadwal-mengajar-libur-mengajar.component.css']
})
export class JadwalMengajarLiburMengajarComponent implements OnInit {

  listKelas: any = [];
  listJamke: any = [];
  listLiburMengajar: any = [];
  dataToUpload: any = null;
  pageid = "upload-jadwal-mengajar";
  searchTerm = "";
  page = 1;
  count = 0;
  pageSize = 50;
  pageSizes = [10, 50, 100];
  isShowPagination: Boolean = false;
  errorMessage = "";
  currentDate = "";
  submitted = false;
  isNew = false;
  isUpdate = false;
  closeResult: string = '';
  currentPenginputId = "";
  idEditLiburMengajar = "";
  
  formLiburMengajar: FormGroup = new FormGroup({
    tanggal: new FormControl(''),
    keterangan: new FormControl(''),
    kelas_id: new FormControl(''),
    jamke: new FormControl(''),
    id_penginput: new FormControl(''),
  });

  constructor(
    private uiService: UiService,
    private tahunajaranService: TahunajaranService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private modalService: NgbModal,
    private kelasService: KelasService,
    private liburmengajarService: LiburMengajarService,
  ) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {    
    const today = this.commonService.convertDate();
    this.getKelas();
    
    this.listJamke = [1,2,3,4,5,6,7,8];
    this.initForm();

    const currentUser = localStorage.getItem('user')?.toString();
    const jsonUser = currentUser ? JSON.parse(currentUser) : "";
    this.currentPenginputId = jsonUser.proguser.id;
    this.formLiburMengajar.controls['id_penginput'].setValue(this.currentPenginputId);
    this.formLiburMengajar.controls['tanggal'].setValue(today);
    this.getLiburMengajar();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("siswa-baru-list");
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    // this.getAllSiswaBaru();
    console.log("handle PAGE", this.page);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  search(): void {
    this.page = 1;
    // this.getAllSiswaBaru();
  }

  initForm() {
    this.formLiburMengajar = this.formBuilder.group(
      {
        tanggal: ['', Validators.required],
        keterangan: ['', Validators.required],
        kelas_id: ['', Validators.required],
        jamke: ['', Validators.required],
        id_penginput: [''],
      },
    );
  }

  getRequestParams(requestSearchTerm: string, page: number, pageSize: number): any {
    let params: any = {};

    params[`searchTerm`] = requestSearchTerm ? requestSearchTerm : "";

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getLiburMengajar() {
    this.uiService.showLoader();

    this.liburmengajarService.getAllliburmengajar({}).subscribe({
      next: resdata => {
        console.log("LIBUR : ", resdata);

        this.listLiburMengajar = resdata;

        this.uiService.hideLoader();

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.error.message ? err.error.message : "ERROR";
        // console.log("MESSAGE ERRt", this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  onSubmitLiburMengajar(modal: any): void {
    this.submitted = true;
    console.log(JSON.stringify(this.formLiburMengajar.value, null, 2));

    if (this.formLiburMengajar.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }
    modal.dismiss('Cross click');

    this.uiService.showLoader();

    if (this.isNew) {
      this.liburmengajarService.add(this.formLiburMengajar.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
  
          this.submitted = false;

          this.getLiburMengajar();
  
        },
        error: err => {
          console.log(err);
          
          this.errorMessage = err.error.message ? err.error.message : "ERROR";
          // console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    } else if (this.isUpdate) {
      this.liburmengajarService.update(this.idEditLiburMengajar, this.formLiburMengajar.value).subscribe({
        next: resdata => {
          console.log(resdata);
  
          this.uiService.hideLoader();
          this.uiService.showSuccess('');
  
          this.submitted = false;

          this.getLiburMengajar();
  
        },
        error: err => {
          console.log(err);
          
          this.errorMessage = err.error.message ? err.error.message : "ERROR";
          // console.log("MESSAGE ERRt", this.errorMessage);
          
          this.uiService.hideLoader();
          
          this.uiService.showError(this.errorMessage);
        }
      });
    }
    
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formLiburMengajar.controls;
  }

  changeDateField(e: any, field: any) {
    console.log(e.target.value);
    const choosenDate = e.target.value;
    this.formLiburMengajar.controls[field].setValue(choosenDate);
  }

  configureDatePicker() {
    console.log("DATEFIKER");
    
    const dateConfig = {
      clearBtn: true,
      orientation: 'auto',
      autohide: false,
      format: 'yyyy-mm-dd',
    }
    const elem_start_date = document.getElementById('tanggal');
    
    const datepicker_start_date = new Datepicker(elem_start_date, dateConfig);
  }

  changeKelas(e: any) {

    // this.listAbsensiSiswa = [];
    // this.formInputInitGuru.controls['jam_pelajaran'].setValue("");
    // this.currentJadwalPelajaranId = null;
    // this.formInputInitGuru.controls['mata_pelajaran'].setValue("");
    // this.formInputInitGuru.controls['studi_id'].setValue("");
    // this.formInputInitGuru.controls['judul_materi'].setValue("");
    
    // if (e.target.value !== "") {
    //   this.formInputInitGuru.controls['jam_pelajaran'].enable();
    //   const kelas_id = e.target.value;
    //   this.currentKelasId = kelas_id;
    //   this.getWalikelasByKelas(kelas_id);
    // } else {
    //   this.formInputInitGuru.controls['jam_pelajaran'].disable();
    // }

  }

  getKelas() {
    this.uiService.showLoader();

    this.kelasService.getAllkelas('').subscribe({
      next: resdata => {
        console.log("KELAS", resdata);
        const { data, totalrows } = resdata;
        this.listKelas = data;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  resetForm() {
    const today = this.commonService.convertDate();
    this.formLiburMengajar.reset();
    this.formLiburMengajar.controls['id_penginput'].setValue(this.currentPenginputId);
    this.formLiburMengajar.controls['tanggal'].setValue(today);
  }

  editFormLiburMengajar(content:any, data: any) {
    console.log("Libur", data);
    
    this.isNew = false;
    this.isUpdate = true;
    this.submitted = false;
    
    this.resetForm();

    data.kelas_id = [];
    data.jamke = [];

    if (data) {
      for (let i=0; i<data.Kelas.length; i++) {
        if (i === 0) {
          for (let c=0; c<data.Kelas[i].Jamke.length; c++) {
            data.jamke.push(data.Kelas[i].Jamke[c].jamke)
          }
        }
        data.kelas_id.push(data.Kelas[i].id_kelas)
      }
    }
    
    this.idEditLiburMengajar = data.id;

    this.formLiburMengajar.patchValue(data);
    // this.formIzinMengajar.controls['id'].setValue(izin.id);
    // this.formIzinMengajar.controls['guru_pengganti_name'].setValue(izin.GuruPengganti.userProfile.real_name);

  
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    const isOpenModal = this.modalService.hasOpenModals();
    console.log("MODAL", isOpenModal);

    if (isOpenModal) {
      this.configureDatePicker();
    }
  }

  confirmDelete(data: any) {
    console.log('data', data);
     
    this.uiService.showConfirmDelete(this, data.id);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  delete(isDelete: Boolean, id: String, $SELF: any) {
    if (isDelete) {

      console.log('DELETE THIS ID : ', isDelete, id);

      $SELF.uiService.showLoader();

      $SELF.liburmengajarService.delete(id).subscribe({
        next: (resdata: any) => {
          console.log(resdata);

          $SELF.getLiburMengajar();
          
          $SELF.uiService.hideLoader();
          $SELF.uiService.showSuccess('');

        },
        error: (err: any) => {
          console.log(err);
          
          $SELF.errorMessage = err.message;
          console.log($SELF.errorMessage);
          
          $SELF.uiService.hideLoader();
          
          $SELF.uiService.showError($SELF.errorMessage);
        }
      });

    }
  }

  openModalformLiburMengajar(content:any) {
    this.isNew = true;
    this.isUpdate = false;
    this.submitted = false;

    this.resetForm();
    
    this.modalService.open(content, {size: 'lg',ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    const isOpenModal = this.modalService.hasOpenModals();
    console.log("MODAL", isOpenModal);

    if (isOpenModal) {
      this.configureDatePicker();
    }
  }

}
